import React from 'react'
import { collectionUtils as C } from '@/core/utils'
import { callWithModalBugFix } from '@/core/utils/components'
import { ModalStack, PopModal, PushModal } from './types'

interface ModalPresenterState {
  modals: ModalStack
}

const initialState: ModalPresenterState = {
  modals: [],
}

export const useModalPresenterState = () => {
  const [{ modals }, setState] = React.useState<ModalPresenterState>(initialState)

  const popModal = React.useCallback<PopModal>(() => {
    callWithModalBugFix(() =>
      setState(state => ({
        ...state,
        modals: C.init(state.modals),
      })),
    )
  }, [setState])

  const pushModal = React.useCallback<PushModal>(
    (name, params, bgColor) => {
      callWithModalBugFix(() =>
        setState(state => ({
          modals: [...state.modals, { bgColor, name, params }],
        })),
      )
    },
    [setState],
  )

  return {
    modals,
    popModal,
    pushModal,
  }
}
