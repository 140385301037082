import React from 'react'
import { Gap } from '@/types/components'
import Box from './Box'

interface CenteredLayoutProps {
  leftContent?: React.ReactNode
  centerContent?: React.ReactNode
  rightContent?: React.ReactNode
  gapVertical?: Gap
  gapHorizontal?: Gap
}

function CenteredLayout({
  leftContent,
  centerContent,
  rightContent,
  gapVertical = 'large',
  gapHorizontal = 'medium',
}: CenteredLayoutProps) {
  return (
    <Box
      alignItems="center"
      direction="row"
      gapHorizontal={gapHorizontal}
      gapVertical={gapVertical}
    >
      <Box alignItems="center" direction="row" flex={1} justifyContent="flex-start">
        {leftContent}
      </Box>
      <Box alignItems="center" direction="row">
        {centerContent}
      </Box>
      <Box alignItems="center" direction="row" flex={1} justifyContent="flex-end">
        {rightContent}
      </Box>
    </Box>
  )
}

export default React.memo(CenteredLayout)
