import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { useUserName } from '@/hooks/users'

interface ActivityLabelProps {
  isFromMe: boolean
  spaceId: number
  creatorId: number
  action: string
}

function ActivityLabel({ isFromMe, creatorId, spaceId, action }: ActivityLabelProps) {
  const userName = useUserName(spaceId, creatorId, { meSubstitute: 'You' })

  return (
    <Box alignItems="center" direction="row" gapBottom="xsmall" gapTop="none">
      {isFromMe ? <Box flex={1} /> : null}
      <Text color="anchor" ellipsizeMode="tail" numberOfLines={1} size="h6" weight="medium">
        {userName} {action}
      </Text>
    </Box>
  )
}

export default React.memo(ActivityLabel)
