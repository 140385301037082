import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { getColor } from '@/core/constants/colors'
import { HitSlop } from '@/types/components'
import Icon from './Icon'
import Text from './Text'
import Touchable from './Touchable'

export interface CheckboxProps {
  checked: boolean
  onChange?: (value: boolean, state: string) => void
  disabled?: boolean
  shape?: 'circle' | 'square'
  hitSlop?: HitSlop
  state?: string
  size?: number
  label?: string
}

function Checkbox({
  checked,
  onChange,
  shape = 'circle',
  disabled = false,
  label,
  hitSlop,
  state,
  size = 22,
}: CheckboxProps) {
  const handleChange = React.useCallback(
    () => !disabled && onChange && onChange(!checked, state || ''),
    [disabled, onChange, checked, state],
  )

  const boxStyle = React.useMemo<StyleProp<ViewStyle>>(() => {
    const computed: ViewStyle[] = [styles.box, { height: size, width: size }]

    if (shape === 'circle') {
      computed.push({ borderRadius: size / 2 })
    } else {
      computed.push(styles.square)
    }
    if (checked) {
      computed.push(styles.checked)
    } else {
      computed.push(styles.unchecked)
    }
    if (disabled) {
      computed.push(styles.disabled)
    }

    return computed
  }, [shape, checked, disabled, size])

  const checkmark = checked ? (
    <Icon color={disabled ? 'obsidian' : 'ghost'} kind="solid" name="check" size={size * 0.75} />
  ) : (
    <View />
  )

  if (!label) {
    return (
      <Touchable
        disabled={disabled}
        feedback="opacity"
        hitSlop={hitSlop}
        onPress={onChange ? handleChange : undefined}
        style={boxStyle}
      >
        {checkmark}
      </Touchable>
    )
  }

  return (
    <Touchable
      disabled={disabled}
      feedback="opacity"
      hitSlop={hitSlop}
      onPress={handleChange}
      style={styles.container}
    >
      <View style={[boxStyle, styles.labelBox]}>{checkmark}</View>
      <Text>{label}</Text>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  box: {
    alignItems: 'center',
    borderColor: getColor('anchor'),
    borderWidth: 1,
    justifyContent: 'center',
  },
  checked: {
    backgroundColor: getColor('emmre-green+9'),
    borderColor: getColor('emmre-green+9'),
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  disabled: {
    backgroundColor: getColor('heather'),
    borderColor: getColor('anchor'),
  },
  labelBox: {
    marginRight: 10,
  },
  square: {
    borderRadius: 3,
  },
  unchecked: {
    backgroundColor: getColor('ghost'),
  },
})

export default React.memo(Checkbox)
