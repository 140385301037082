import React from 'react'
import { StyleSheet, View } from 'react-native'
import FadeInOutContainer from '@/components/animated/FadeInOutContainer'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import { ColorName } from '@/core/constants/colors'
import { getFontSize } from '@/core/constants/typography'

type ZeroMode = 'HIDE' | 'DEMPHASIZE' | 'SHOW'

interface MessagesBadgeProps {
  count: number
  hide?: boolean
  zeroMode?: ZeroMode
}

function MessagesBadge({ count, hide = false, zeroMode = 'HIDE' }: MessagesBadgeProps) {
  const badge = (
    <View style={styles.container}>
      <Icon color={countToColor(zeroMode, count)} kind="solid" name="message" size={22} />
      <View style={styles.textContainer}>
        {/* set the lineHeight equal to the size of the font to ensure it is vertically centered */}
        <Text color="ghost" lineHeight={getFontSize('h6')} size="h6">
          {countToString(zeroMode, count)}
        </Text>
      </View>
    </View>
  )

  if (zeroMode === 'HIDE') {
    return <FadeInOutContainer open={count !== 0 && !hide}>{badge}</FadeInOutContainer>
  }
  return badge
}

const countToString = (zeroMode: ZeroMode, count: number): string => {
  if (count === 0) {
    if (zeroMode === 'DEMPHASIZE') {
      return ' '
    }
    return '0'
  }
  if (count > 99) {
    return '99+'
  }
  return count.toString()
}

const countToColor = (zeroMode: ZeroMode, count: number): ColorName => {
  if (count !== 0) {
    return 'emmre-blue'
  }
  if (zeroMode === 'DEMPHASIZE') {
    return 'azureish+1'
  }
  return 'emmre-blue'
}

/**
 *  These styles make the text container fully
 *  overlay the comment bubble icon and then use
 *  flexbox to center the contents (the actual number count).
 *  Then we use a 4px bottom pad to "cutoff" the little arrow
 *  at the bottom of the bubble to ensure the text is always
 *  centered in the bubble of the icon
 */
const styles = StyleSheet.create({
  container: {
    height: 20,
    marginBottom: 4,
  },
  textContainer: {
    alignItems: 'center',
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default React.memo(MessagesBadge)
