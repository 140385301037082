import { Errors } from '@/types/api'

export const getGenericErrors = (errors: Errors): string => errors[''].join(', ')

export const getGeneralError = (errors: Errors): string | undefined => {
  const generals = errors['']
  if (!generals) {
    return undefined
  }
  return generals[0]
}
