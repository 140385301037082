import React from 'react'
import { fnUtils } from '@/core/utils'
import { useActions } from '@/hooks/actions'
import { useHasChanges } from './formFields'

const cancelTaskActionArgs = { title: 'Task Actions' }
const cancelQuestionActionArgs = { title: 'Question Actions' }

export const useTaskConfirmCancel = (
  formName: string,
  action: 'create' | 'edit',
  onSave: () => void,
  onClose: () => void,
) => {
  const hasChanges = useHasChanges(formName)

  const showActions = useActions<void>(
    React.useMemo(
      () => [
        {
          handler: () => onSave(),
          label: 'Save & Close',
        },
        {
          handler: () => fnUtils.noOp,
          label: `Keep ${action === 'create' ? 'Creating' : 'Editing'}`,
        },
        {
          handler: onClose,
          label: action === 'create' ? 'Discard Task' : 'Discard Changes',
          type: 'destructive',
        },
      ],
      [onSave, onClose, action],
    ),
    cancelTaskActionArgs,
  )

  return React.useCallback(
    (clear = true) => {
      if (!hasChanges || !clear) {
        onClose()
      } else {
        showActions()
      }
    },
    [hasChanges, onClose, showActions],
  )
}

export const useQuestionConfirmCancel = (
  hasChanges: boolean,
  onAnswer: () => void,
  onClose: () => void,
) => {
  const showActions = useActions<void>(
    React.useMemo(
      () => [
        {
          handler: () => onAnswer(),
          label: 'Answer & Close',
        },
        {
          handler: () => fnUtils.noOp,
          label: `Keep Editing`,
        },
        {
          handler: onClose,
          label: 'Discard Changes',
          type: 'destructive',
        },
      ],
      [onAnswer, onClose],
    ),
    cancelQuestionActionArgs,
  )

  return React.useCallback(() => {
    if (!hasChanges) {
      onClose()
    } else {
      showActions()
    }
  }, [hasChanges, onClose, showActions])
}
