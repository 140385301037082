import React from 'react'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'
import { AmplitudeProps } from '@/types/amplitude'
import { HitSlop } from '@/types/components'
import Button, { ButtonKind } from './Button'
import IconButton from './IconButton'
import Link from './Link'

type ActionType =
  | 'linkPrimary'
  | 'linkSecondary'
  | 'linkSuccess'
  | 'linkError'
  | 'linkWarning'
  | 'buttonPrimary'
  | 'buttonSecondary'
  | 'buttonSuccess'
  | 'buttonError'
  | 'buttonWarning'

const actionButtonKind = new Map<ActionType, ButtonKind>([
  ['buttonPrimary', 'primary'],
  ['buttonSecondary', 'secondary'],
  ['buttonSuccess', 'success'],
  ['buttonError', 'error'],
  ['buttonWarning', 'warning'],
])
const actionLinkKind = new Map<ActionType, ColorName>([
  ['linkPrimary', 'emmre-blue'],
  ['linkSecondary', 'emmre-purple'],
  ['linkSuccess', 'emmre-green'],
  ['linkError', 'red'],
  ['linkWarning', 'cheddar'],
])

interface BaseActionProps extends AmplitudeProps {
  hitSlop?: HitSlop
  onAction: () => void
  onActionDisabled?: () => void
  disabled?: boolean
}

interface ActionLabelProps extends BaseActionProps {
  type: ActionType
  label: string
}

interface ActionIconProps extends BaseActionProps {
  type: ButtonKind
  icon: IconName
}

export type ActionProps = ActionLabelProps | ActionIconProps

function Action(props: ActionProps) {
  if ('label' in props) {
    const {
      type,
      onAction,
      disabled,
      hitSlop = 'medium',
      label,
      onActionDisabled,
      amplitudeEventName,
      amplitudeEventProperties,
    } = props

    if (actionLinkKind.has(type)) {
      const enabledColor = actionLinkKind.get(type) as ColorName

      return (
        <Link
          amplitudeEventName={amplitudeEventName}
          amplitudeEventProperties={amplitudeEventProperties}
          color={disabled ? 'koala' : enabledColor}
          disabled={disabled}
          hitSlop={hitSlop}
          label={label}
          numberOfLines={1}
          onPress={onAction}
        />
      )
    }

    if (actionButtonKind.has(type)) {
      const buttonKind = actionButtonKind.get(type) as ButtonKind

      return (
        <Button
          amplitudeEventName={amplitudeEventName}
          amplitudeEventProperties={amplitudeEventProperties}
          disabled={disabled}
          hitSlop={hitSlop}
          kind={buttonKind}
          label={label}
          onPress={onAction}
          onPressDisabled={onActionDisabled}
          size="small"
        />
      )
    }

    throw new Error(`Invalid action type: ${type}`)
  }
  const {
    type = 'primary',
    onAction,
    disabled,
    hitSlop = 'medium',
    icon,
    onActionDisabled,
    amplitudeEventName,
    amplitudeEventProperties,
  } = props

  return (
    <IconButton
      amplitudeEventName={amplitudeEventName}
      amplitudeEventProperties={amplitudeEventProperties}
      disabled={disabled}
      hitSlop={hitSlop}
      kind={type}
      name={icon}
      onPress={onAction}
      onPressDisabled={onActionDisabled}
    />
  )
}

export default React.memo(Action)
