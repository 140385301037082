import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

interface UntouchableProps {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
  testID?: string
}

function Untouchable({ children, style, testID }: UntouchableProps) {
  return style ? (
    <View style={style} testID={testID}>
      {children}
    </View>
  ) : (
    <>children</>
  )
}

export default React.memo(Untouchable)
