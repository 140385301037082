import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { EmojiGroup } from '@/types/emoji'
import { headerHeight } from './constants'

interface EmojiListHeaderProps {
  emojiGroup: EmojiGroup
}

function EmojiListHeader({ emojiGroup }: EmojiListHeaderProps) {
  return (
    <Background color="ghost">
      <Box alignItems="center" direction="row" height={headerHeight}>
        <Text weight="medium">{emojiGroup.name}</Text>
      </Box>
    </Background>
  )
}

export default React.memo(EmojiListHeader)
