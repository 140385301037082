import React from 'react'
import { spaceUtils, userUtils } from '@/core/utils'
import { useAppDispatch, useCachedSelector } from '@/hooks'
import { useAlertActionsAsync } from '@/hooks/actions'
import { userSelectors } from '@/store/selectors'
import { errorThunks, internalThunks } from '@/thunks'
import { ActionArgs, ActionOptionAsync } from '@/types/actions'
import { ProductVersion } from '@/types/entities'

type DowngradeResponse = 'CANCEL' | 'REMOVE'

const removeActionArgs: ActionArgs = {
  message: "You'll need to remove others from your workspace in order to downgrade to this plan.",
  title: "Can't downgrade with more than one person in the workspace.",
}

const removeActionOptions: ActionOptionAsync<DowngradeResponse, null>[] = [
  { label: 'Cancel', value: 'CANCEL' },
  { label: 'Remove', value: 'REMOVE' },
]

export const useRemoveUsers = (
  spaceId: number,
  onCancel: () => void,
  onRemove: (_spaceId: number) => void,
) => {
  const allUsers = useCachedSelector(userSelectors.bySpaceIdSelector, [spaceId, false])
  const activeUsersCount = React.useMemo(
    () =>
      allUsers.filter(
        user => userUtils.isActive(user.membershipType) || userUtils.isInvited(user.membershipType),
      ).length,
    [allUsers],
  )
  const showActions = useAlertActionsAsync(removeActionOptions, removeActionArgs)

  const mustRemoveUsers = React.useCallback(
    (productVersion: ProductVersion) =>
      activeUsersCount > 1 && !spaceUtils.canHaveMultipleUsers(productVersion),
    [activeUsersCount],
  )

  const handleRemoveUsers = React.useCallback(async () => {
    const response = await showActions(null)

    if (response === 'CANCEL') {
      onCancel()
    } else {
      onRemove(spaceId)
    }
  }, [showActions, onCancel, onRemove, spaceId])

  const handleCheckSelect = React.useCallback(
    (productVersion: ProductVersion) => {
      if (mustRemoveUsers(productVersion)) {
        handleRemoveUsers()
        return false
      }
      return true
    },
    [mustRemoveUsers, handleRemoveUsers],
  )

  return handleCheckSelect
}

export const usePay = (spaceId: number) => {
  const dispatch = useAppDispatch()
  const [isPending, setIsPending] = React.useState(false)

  const makePayment = React.useCallback(
    async (productVersion: ProductVersion, paymentIntentId: string | null) => {
      if (isPending || !productVersion) {
        return false
      }

      setIsPending(true)

      const response = await dispatch(
        internalThunks.selectProductVersion(spaceId, { paymentIntentId, productVersion }),
      )

      setIsPending(false)

      if (response.ok) {
        return true
      }
      dispatch(
        errorThunks.notifyErrors(
          'An error occurred when selecting the product version',
          response.errors,
          -1,
        ),
      )

      return false
    },
    [spaceId, dispatch, setIsPending, isPending],
  )
  return { isPending, makePayment }
}
