import React from 'react'
import { dateUtils as D, offsetUtils as O } from '@/core/utils'
import { useToggle } from '@/hooks'
import { ReminderOffset } from '@/types/entities'
import { Action } from '@/types/generics'
import { OffsetUnit, offsetUnits, unitAmounts, unitLabels } from './constants'
import { offsetToCustom } from './utils'

export const useReminderSelector = (
  onClose: () => void,
  onRemindAtChange: Action<ReminderOffset | null>,
  selectedDate: Date | null,
  selectedRemindAt: Date | null,
) => {
  const [showCustom, toggleShowCustom] = useToggle(false)
  const handleDone = React.useCallback(() => onClose(), [onClose])

  const handleSetRemindAtAndClose = React.useCallback(
    (remindAtOffset: ReminderOffset | null) => {
      onRemindAtChange(remindAtOffset)
      if (remindAtOffset && selectedDate) {
        const remindAt = O.applyOffset(remindAtOffset, selectedDate)
        if (remindAt.getTime() > D.nowMilliseconds()) {
          onClose()
        }
      } else {
        onClose()
      }
    },
    [onRemindAtChange, onClose, selectedDate],
  )

  const handleClear = React.useCallback(
    () => handleSetRemindAtAndClose(null),
    [handleSetRemindAtAndClose],
  )

  const isValidRemindAt = React.useMemo(() => {
    if (!selectedRemindAt) {
      return true
    }
    return selectedRemindAt.getTime() > D.nowMilliseconds()
  }, [selectedRemindAt])

  return {
    handleClear,
    handleDone,
    handleSetRemindAtAndClose,
    isValidRemindAt,
    showCustom,
    toggleShowCustom,
  }
}

type PickerOption<T> = { label: string; value: T }

export const useCustomOffset = (
  offset: ReminderOffset | null,
  onOffsetChange: (newOffset: ReminderOffset | null) => void,
) => {
  const { unit, amount } = React.useMemo<{ unit: OffsetUnit; amount: number }>(
    () => offsetToCustom(offset),
    [offset],
  )

  const setUnit = React.useCallback(
    (newUnit: OffsetUnit) => {
      onOffsetChange({ [newUnit]: unitAmounts[newUnit][1] })
    },
    [onOffsetChange],
  )

  const setAmount = React.useCallback(
    (newAmount: string) =>
      onOffsetChange({
        [unit]: parseInt(newAmount, 10),
      }),
    [onOffsetChange, unit],
  )

  const unitOptions = React.useMemo<PickerOption<OffsetUnit>[]>(
    () =>
      offsetUnits.map(u => ({
        label: amount === 1 ? unitLabels[u].singular : unitLabels[u].plural,
        value: u,
      })),
    [amount],
  )
  const amountOptions = React.useMemo<PickerOption<number>[]>(
    () => unitAmounts[unit].map(a => ({ label: (-a).toString(), value: a })),
    [unit],
  )

  const handleOffsetTimeChange = React.useCallback(
    (date: Date | null) => {
      if (!offset) {
        return
      }

      if (date) {
        onOffsetChange({ ...offset, atHour: date.getHours(), atMinute: date.getMinutes() })
      } else {
        onOffsetChange(O.withoutTime(offset))
      }
    },
    [onOffsetChange, offset],
  )

  return {
    amount,
    amountOptions,
    handleOffsetTimeChange,
    setAmount,
    setUnit,
    unit,
    unitOptions,
  }
}
