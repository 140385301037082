import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { Board } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, boardId: number): string => `${spaceId}:${boardId}`

const adapter = createEntityAdapter<Board>({
  selectId: board => buildId(board.spaceId, board.id),
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/boards',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    setOne: adapter.setOne,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.boards)
const getId = adapter.selectId

const selectById = (state: RootState, spaceId: number, boardId: number) =>
  state.entities.boards.entities[buildId(spaceId, boardId)]
const byIdSelector = (spaceId: number, boardId: number) =>
  createSelector(sliceSelectors.selectEntities, boards => boards[buildId(spaceId, boardId)])
const byChannelIdSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectAll, boards =>
    boards.filter(board => board.spaceId === spaceId && board.channelId === channelId),
  )
const groupedByNameSelector = (spaceId: number, channelId: number | null) =>
  createSelector(sliceSelectors.selectAll, boards =>
    boards.reduce((groups, board) => {
      if (board.spaceId !== spaceId) {
        return groups
      }

      if (channelId && board.channelId !== channelId) {
        return groups
      }

      if (groups.has(board.name)) {
        groups.get(board.name)?.push(board)
      } else {
        groups.set(board.name, [board])
      }
      return groups
    }, new Map<string, Board[]>()),
  )

const selectors = {
  all: sliceSelectors.selectAll,
  byChannelIdSelector,
  byId: selectById,
  byIdSelector,
  entities: sliceSelectors.selectEntities,
  groupedByNameSelector,
}

export { actions, selectors, buildId, getId }
export default reducer
