import React from 'react'
import { DragBeginHandler, DragEndHandler, WebDraggableFlatListContextType } from './types'

type State = {
  fromIndex: number | null
  toIndex: number | null
}

type Action =
  | { type: 'start'; payload: { index: number } }
  | { type: 'end' }
  | { type: 'over'; payload: { index: number } }

const reducer = (state: State, action: Action): State => {
  const { type } = action

  if (type === 'start') {
    const { index } = action.payload

    return {
      ...state,
      fromIndex: index,
      toIndex: index,
    }
  }

  if (type === 'end') {
    return {
      ...state,
      fromIndex: null,
      toIndex: null,
    }
  }

  if (type === 'over') {
    const { index } = action.payload

    return {
      ...state,
      toIndex: index,
    }
  }

  return state
}

type HookArgs = {
  onDragBegin: DragBeginHandler
  onDragEnd: DragEndHandler
}

const defaultState: State = { fromIndex: null, toIndex: null }

export const useWebDraggableFlatListContextValue = ({
  onDragBegin,
  onDragEnd,
}: HookArgs): WebDraggableFlatListContextType => {
  const [{ fromIndex, toIndex }, dispatch] = React.useReducer(reducer, defaultState)

  const dragStart = React.useCallback(
    (index: number) => {
      dispatch({ payload: { index }, type: 'start' })
      onDragBegin(index)
    },
    [dispatch, onDragBegin],
  )

  const dragEnd = React.useCallback(() => {
    dispatch({ type: 'end' })
  }, [dispatch])

  const drop = React.useCallback(
    (index: number) => {
      const from = fromIndex as number

      if (from < index) {
        // If it's being drug further down the list we must adjust the index by -1 in order to
        // place it before the new item. If it's coming up the list it will just use the index
        // it's being placed on
        onDragEnd({ from, to: index - 1 })
      } else {
        onDragEnd({ from, to: index })
      }
      dispatch({ type: 'end' })
    },
    [dispatch, fromIndex, onDragEnd],
  )

  const dragOver = React.useCallback(
    (index: number) => {
      dispatch({ payload: { index }, type: 'over' })
    },
    [dispatch],
  )

  return {
    dragEnd,
    dragOver,
    dragStart,
    drop,
    fromIndex,
    toIndex,
  }
}
