import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { Attachment } from '@/types/api'
import { RootState } from '../index'

const adapter = createEntityAdapter<Attachment>({
  selectId: attachment => attachment.temporaryId,
})

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'attachments',
  reducers: {
    updateOne: adapter.updateOne,
    upsertOne: adapter.upsertOne,
  },
})

const sliceSelectors = adapter.getSelectors((state: RootState) => state.attachments)

const byIdSelector = (attachmentId: string) =>
  createSelector(sliceSelectors.selectEntities, attachments => attachments[attachmentId])

const byIdsSelector = (attachmentIds: string[]) =>
  createSelector(
    sliceSelectors.selectEntities,
    attachments =>
      attachmentIds
        .map(attachmentId => attachments[attachmentId])
        .filter(attachment => !!attachment) as Attachment[],
  )

const pendingSelector = (attachmentIds: string[]) =>
  createSelector(byIdsSelector(attachmentIds), attachments =>
    attachments.filter(({ status }) => status !== 'SUCCESS'),
  )

const { actions, reducer } = slice

const selectors = {
  byIdSelector,
  byIdsSelector,
  pendingSelector,
}

export { actions, selectors }
export default reducer
