import React from 'react'
import Action from '@/components/core/Action'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { useCurrentUserId } from '@/hooks/auth'

function EditQuestionActions() {
  const { interactionMode, cancel, canClose, close, submit, canSubmit, task } = useTaskFormContext()
  const currentUserId = useCurrentUserId()
  const eventProperties = React.useMemo(
    () => ({
      'created for': task?.assignedUserId === currentUserId ? 'self' : 'other',
    }),
    [task?.assignedUserId, currentUserId],
  )
  const disabled = !canSubmit

  return (
    <Box alignItems="center" direction="row">
      {canClose ? (
        <Action label="Close" onAction={close} type="linkSecondary" />
      ) : (
        <Action label="Cancel" onAction={cancel} type="linkSecondary" />
      )}
      <Box flex={1} />
      {interactionMode === 'create' ? (
        <Action
          amplitudeEventName="ask question"
          amplitudeEventProperties={eventProperties}
          disabled={disabled}
          label="Ask"
          onAction={submit}
          type="buttonSecondary"
        />
      ) : (
        <Action
          amplitudeEventName="update question"
          disabled={disabled}
          label="Update"
          onAction={submit}
          type="linkSecondary"
        />
      )}
    </Box>
  )
}

export default React.memo(EditQuestionActions)
