import React from 'react'
import { StyleSheet } from 'react-native'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'

interface ReactionMenuItemProps {
  emojiSymbol: string
  onReact: (emoji: string) => void
}

function ReactionMenuItem({ onReact, emojiSymbol }: ReactionMenuItemProps) {
  const handleReact = React.useCallback(() => onReact(emojiSymbol), [onReact, emojiSymbol])

  return (
    <Touchable feedback="opacity" onPress={handleReact} style={styles.container}>
      <Text size="h2">{emojiSymbol}</Text>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
})

export default React.memo(ReactionMenuItem)
