export const spaceActions = {
  deleteSpace: 'DELETE_SPACE',
  editAdministrators: 'EDIT_ADMINISTRATORS',
  editRole: 'EDIT_ROLE',
  editSpace: 'EDIT_SPACE',
  inviteMemberToSpace: 'INVITE_MEMBER_TO_SPACE',
  leaveSpace: 'LEAVE_SPACE',
  removeMemberFromSpace: 'REMOVE_MEMBER_FROM_SPACE',
}

export const userActions = {
  inviteToSpace: 'INVITE_TO_SPACE',
  setupAccount: 'SETUP_ACCOUNT',
  updateAccount: 'UPDATE_ACCOUNT',
}
