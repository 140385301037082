import React from 'react'
import EmojiPicker from '@/components/core/EmojiPicker'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { platformUtils } from '@/core/utils'
import { Emoji } from '@/types/emoji'

interface EmojiPickerModalProps {
  onClose: () => void
  onPick: (emoji: Emoji | null) => void
}

function EmojiPickerModal({ onClose, onPick }: EmojiPickerModalProps) {
  const handleCancel = React.useCallback(() => {
    onPick(null)
    onClose()
  }, [onPick, onClose])
  const handlePick = React.useCallback(
    (emoji: Emoji) => {
      onPick(emoji)
      onClose()
    },
    [onPick, onClose],
  )

  return (
    <ModalLayout
      insetBottom={false}
      leftAction={{
        label: 'Cancel',
        onAction: handleCancel,
        type: 'linkPrimary',
      }}
      maxWidth={400}
      onCancel={handleCancel}
      title="Pick Emoji"
    >
      <Box gapHorizontal="medium">
        <EmojiPicker
          height={platformUtils.isWeb ? 400 : undefined}
          onCancel={handleCancel}
          onPick={handlePick}
        />
      </Box>
    </ModalLayout>
  )
}

export default React.memo(EmojiPickerModal)
