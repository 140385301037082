import React from 'react'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { Task } from '@/types/entities'

interface TaskListItemAssignedToFocusProps {
  task: Task
}

function TaskListItemAssignedToFocus({ task }: TaskListItemAssignedToFocusProps) {
  return <UserProfilePicture spaceId={task.spaceId} userId={task.assignedUserId} />
}

export default React.memo(TaskListItemAssignedToFocus)
