import React from 'react'
import { Animated } from 'react-native'

interface FadeInOutContainerProps {
  children: React.ReactNode
  open: boolean
  duration?: number
  animateInitialRender?: boolean
}

function FadeInOutContainer({
  children,
  open,
  animateInitialRender = false,
  duration = 300,
}: FadeInOutContainerProps) {
  const firstRenderRef = React.useRef(true)
  const opacityAnimationRef = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    let timingDuration = duration

    // On the first render we don't want to animate, just show it how it is suppsoed to be
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      if (!animateInitialRender) {
        timingDuration = 0
      }
    }

    const toValue = open ? 1 : 0

    // If the duration is 0, just set the value directly instead of animating
    if (timingDuration === 0) {
      opacityAnimationRef.current.setValue(toValue)
    } else {
      Animated.timing(opacityAnimationRef.current, {
        duration: timingDuration,
        toValue,
        useNativeDriver: true,
      }).start()
    }
  }, [duration, open, animateInitialRender])

  return <Animated.View style={{ opacity: opacityAnimationRef.current }}>{children}</Animated.View>
}

export default React.memo(FadeInOutContainer)
