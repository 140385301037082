import { Linking } from 'react-native'
import { integrationAPI } from '@/api/requests'
import { config } from '@/core'
import { fnUtils, platformUtils, urlUtils } from '@/core/utils'
import { Thunk } from '@/types/store'
import { showToast } from './notifications'
import { makeEnhancedRequest, makeRawRequest } from './utils'

export const generateSlackConnectionURL =
  (spaceId: number): Thunk<string | null> =>
  async dispatch => {
    const request = integrationAPI.generateSlackConnectionURL(spaceId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return response.data.url
    }

    return null
  }

const createChargebeePortalSession =
  (spaceId: number): Thunk<any> =>
  async dispatch => {
    const request = integrationAPI.createChargebeePortalSession(spaceId)
    const response = await dispatch(makeRawRequest(request))

    if (response.ok) {
      return response.json()
    }
    return null
  }

export const showAccount =
  (spaceId: number, onShow?: (sid: number) => void): Thunk<void> =>
  async dispatch => {
    if (platformUtils.isWeb) {
      if (onShow) {
        onShow(spaceId)
      } else {
        await dispatch(launchChargebeePortal(spaceId))
      }
    } else {
      const message = 'Please verify your email and password to access your account'
      const redirect = `/account-management/${spaceId}/finances`
      const queryString = urlUtils.toQueryString([
        ['message', message],
        ['redirect', redirect],
      ])
      const url = urlUtils.appendQueryString(`${config.appURLRoot}/auth/sign-in?`, queryString)
      Linking.openURL(url)
    }
  }

export const launchChargebeePortal =
  (spaceId: number): Thunk<void> =>
  async dispatch => {
    const data = await dispatch(createChargebeePortalSession(spaceId))

    if (!data) {
      dispatch(showToast({ title: 'Unable to launch Payment Portal', type: 'error' }))
      return
    }

    Chargebee.init({
      iframeOnly: true,
      site: config.chargebeeSite,
    })

    const instance = Chargebee.getInstance()
    instance.setPortalSession(fnUtils.alwaysAsync(data.portal_session))

    const portal = instance.createChargebeePortal()
    portal.open({})
  }

export const initializeChargebee = () => {
  Chargebee.init({
    publishableKey: config.chargebeePublishableKey,
    site: config.chargebeeSite,
  })
  return true
}
