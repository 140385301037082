import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { QuestionResponseType } from '@/types/entities'

interface QuestionResponseTypeSelectorProps {
  responseType: QuestionResponseType
  setResponseType: (checked: boolean, responseType: string) => void
}

function QuestionResponseTypeSelector({
  responseType,
  setResponseType,
}: QuestionResponseTypeSelectorProps) {
  return (
    <Box direction="row" gapTop="small" justifyContent="space-around">
      <Box alignItems="center" direction="row" justifyContent="center">
        <Checkbox
          checked={responseType === 'SINGLE'}
          hitSlop="medium"
          onChange={setResponseType}
          state="SINGLE"
        />
        <Box gapLeft="small">
          <Text size="h6">Pick one</Text>
        </Box>
      </Box>
      <Box alignItems="center" direction="row" justifyContent="center">
        <Checkbox
          checked={responseType === 'MULTIPLE'}
          hitSlop="medium"
          onChange={setResponseType}
          state="MULTIPLE"
        />
        <Box gapLeft="small">
          <Text size="h6">Pick one or more</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(QuestionResponseTypeSelector)
