import React from 'react'
import { StyleSheet, View } from 'react-native'
import { getColor } from '@/core/constants/colors'
import { Measure } from '@/types/components'
import Label from '../Label'
import Choice from './Choice'
import Pill from './Pill'

export interface SelectorProps<T extends string> {
  choices: { value: T; label: string }[]
  disabled?: boolean
  onChange: (value: T) => void
  value: T | null
  label?: string
}

const duration = 300

function Selector<T extends string = string>({
  choices,
  value,
  label,
  onChange,
  disabled = false,
}: SelectorProps<T>) {
  const trackRef = React.useRef<View | null>(null)
  const [pillMeasure, setPillMeasure] = React.useState<Measure | null>(null)
  const measuresRef = React.useRef<{ [key: string]: Measure }>({})
  const handlePosition = React.useCallback(
    (choice: string, measure: Measure) => {
      const oldMeasure = measuresRef.current[choice]
      measuresRef.current[choice] = measure

      if (choice !== value) {
        return
      }
      if (
        pillMeasure === null ||
        oldMeasure.width !== measure.width ||
        oldMeasure.x !== measure.x
      ) {
        setPillMeasure(measure)
      }
    },
    [pillMeasure, setPillMeasure, value],
  )
  const handleSelect = React.useCallback(
    (choice: T, measure: Measure) => {
      if (value !== choice) {
        onChange(choice)
      }
      handlePosition(choice, measure)
      setPillMeasure(measure)
    },
    [handlePosition, onChange, value],
  )

  const selector = (
    <View ref={trackRef} style={styles.track}>
      {pillMeasure ? (
        <Pill duration={duration} left={pillMeasure.x} width={pillMeasure.width} />
      ) : null}
      {choices.map(choice => (
        <Choice
          key={choice.value}
          disabled={disabled}
          label={choice.label}
          measureRelativeToComponentRef={trackRef}
          onRender={handlePosition}
          onSelect={handleSelect}
          value={choice.value}
        />
      ))}
    </View>
  )

  if (label) {
    return (
      <View>
        <Label position="top">{label}</Label>
        {selector}
      </View>
    )
  }

  return selector
}

const styles = StyleSheet.create({
  track: {
    backgroundColor: getColor('heather'),
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
})

export default React.memo(Selector) as typeof Selector
