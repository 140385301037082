import React from 'react'
import { fnUtils } from '@/core/utils'
import { PromptArgs } from '@/types/prompt'
import PromptModal from './PromptModal'

interface PromptContainerModalProps {
  promptArgs: PromptArgs | null
  onClose: () => void
}

function PromptContainerModal({ promptArgs, onClose }: PromptContainerModalProps) {
  if (promptArgs) {
    return <PromptModal {...promptArgs} isOpen={!!promptArgs} onClose={onClose} />
  }
  return <PromptModal isOpen={false} onClose={onClose} onSubmit={fnUtils.noOp} title="" />
}

export default React.memo(PromptContainerModal)
