import React from 'react'
import Text from '@/components/core/Text'

export function AssistantProDescription() {
  return (
    <Text>
      Perfect for the Assistant wanting to provide more value to their executive and remove
      roadblocks to getting things done.
    </Text>
  )
}

export function PartnershipDescription() {
  return (
    <Text>
      Perfect for the <Text weight="bold">Assistant & Executive</Text> wanting to fully leverage
      their strategic partnership.
    </Text>
  )
}
