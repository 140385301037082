import React from 'react'
import { ColorName } from '@/core/constants/colors'

type ThemeOption = 'light' | 'dark'

type ThemeConstructorValue = {
  background: ColorName
  border: ColorName
  foreground: ColorName
  headerForeground: ColorName
  hoveredBackground: ColorName
  hoveredForeground: ColorName
  primary: ColorName
  secondary: ColorName
  selectedBackground: ColorName
  selectedForeground: ColorName
  theme: ThemeOption
}

type GetColorArgs = { selected?: boolean; hovering?: boolean; transparent?: boolean }

class Theme {
  background: ColorName
  border: ColorName
  foreground: ColorName
  headerForeground: ColorName
  hoveredBackground: ColorName
  hoveredForeground: ColorName
  primary: ColorName
  secondary: ColorName
  selectedBackground: ColorName
  selectedForeground: ColorName
  theme: ThemeOption

  constructor({
    background,
    border,
    foreground,
    headerForeground,
    hoveredBackground,
    hoveredForeground,
    primary,
    secondary,
    selectedBackground,
    selectedForeground,
    theme,
  }: ThemeConstructorValue) {
    this.background = background
    this.border = border
    this.foreground = foreground
    this.headerForeground = headerForeground
    this.hoveredBackground = hoveredBackground
    this.hoveredForeground = hoveredForeground
    this.primary = primary
    this.secondary = secondary
    this.selectedBackground = selectedBackground
    this.selectedForeground = selectedForeground
    this.theme = theme
  }

  apply<T>(whenLight: T, whenDark: T): T {
    return this.theme === 'light' ? whenLight : whenDark
  }

  getBackground({ selected, hovering, transparent }: GetColorArgs) {
    if (hovering) {
      return this.hoveredBackground
    }
    if (selected) {
      return this.selectedBackground
    }
    return transparent ? 'transparent' : this.background
  }

  getForeground({ selected, hovering }: GetColorArgs) {
    if (hovering) {
      return this.hoveredForeground
    }
    if (selected) {
      return this.selectedForeground
    }
    return this.foreground
  }
}

const defaultThemeContextValue = new Theme({
  background: 'ghost',
  border: 'heather',
  foreground: 'obsidian',
  headerForeground: 'obsidian',
  hoveredBackground: 'koala',
  hoveredForeground: 'ghost',
  primary: 'emmre-blue',
  secondary: 'emmre-purple',
  selectedBackground: 'heather',
  selectedForeground: 'obsidian',
  theme: 'light',
})

const ThemeContext = React.createContext<Theme>(defaultThemeContextValue)

export const useThemeContext = () => React.useContext(ThemeContext)
