import React from 'react'
import { ScrollView } from 'react-native'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import EmmreLogo from '@/components/images/EmmreLogo'
import Box from '@/components/layouts/Box'
import SpaceSelector from '@/components/spaces/SpaceSelector'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { Space } from '@/types/entities'
import { Action } from '@/types/generics'
import AppDrawerLink from './AppDrawerLink'

interface AppDrawerProps {
  onSelectSpace: Action<Space>
  onShowProfile: () => void
  onSignOut: () => void
  onShowSpace: Action<Space>
  onCreateSpace: () => void
  onShowHelp: () => void
}

function AppDrawer({
  onSelectSpace,
  onShowProfile,
  onSignOut,
  onShowSpace,
  onCreateSpace,
  onShowHelp,
}: AppDrawerProps) {
  return (
    <>
      <Background borderBottomColor="heather" borderBottomWidth={1}>
        <Box alignItems="center" direction="row" gapAll="large">
          <EmmreLogo size={24} />
          <Box gapLeft="small">
            <Text color="emmre-blue" size="h3" weight="bold">
              emmre
            </Text>
          </Box>
          <Box flex={1} />
          <Box>
            <Touchable feedback="opacity" onPress={onShowProfile}>
              <UserProfilePicture currentUser size="large" />
            </Touchable>
          </Box>
        </Box>
      </Background>
      <Box flex={1}>
        <ScrollView>
          <Box gapAll="large" gapBottom="small">
            <Text weight="bold">Spaces</Text>
          </Box>
          <SpaceSelector
            ListEmptyComponent={<ActivityIndicator color="obsidian" size="small" />}
            onMore={onShowSpace}
            onSelectionChange={onSelectSpace}
          />
        </ScrollView>
      </Box>
      <Background borderColor="heather" borderTopWidth={1}>
        <AppDrawerLink iconName="circle-plus" label="Create Space" onPress={onCreateSpace} />
        <AppDrawerLink iconName="arrow-right-from-bracket" label="Sign Out" onPress={onSignOut} />
        <AppDrawerLink iconName="circle-question" label="Help" onPress={onShowHelp} />
      </Background>
    </>
  )
}

export default React.memo(AppDrawer)
