import React from 'react'
import { apiUtils } from '@/core/utils'
import { useAppDispatch, useAppSelector, useCachedSelector } from '@/hooks'
import { useRTMAuthenticated } from '@/hooks/rtm'
import { useThrottleLock } from '@/hooks/sync'
import { summaryItemSelectors, summarySelectors } from '@/store/selectors'
import { summaryThunks } from '@/thunks'
import { APIStatus } from '@/types/api'
import { Summary, SummaryStatus } from '@/types/entities'
import { Pair } from '@/types/generics'

export const useSummary = (spaceId: number, summaryId: number) => {
  const dispatch = useAppDispatch()
  const [status, setStatus] = React.useState<APIStatus>('READY')
  const [message, setMessage] = React.useState<string | null>(null)
  const summary = useAppSelector(summarySelectors.byId, spaceId, summaryId)

  const loadSummary = React.useCallback(async () => {
    setStatus('PENDING')
    const response = await dispatch(summaryThunks.getSummary(spaceId, summaryId))
    if (response.ok) {
      setStatus('SUCCESS')
    } else {
      setStatus('ERROR')
      setMessage(apiUtils.getGeneralError(response.errors) || null)
    }
  }, [setStatus, dispatch, spaceId, summaryId])

  React.useEffect(() => {
    if (status === 'READY') {
      loadSummary()
    }
  }, [loadSummary, status])

  return { message, status, summary }
}

export const useSummaryItems = (summary: Summary) => {
  const { spaceId, id: summaryId } = summary

  const dispatch = useAppDispatch()
  const loadedRef = React.useRef(false)
  const [isLoading, setIsLoading] = React.useState(true)

  const summaryItems = useCachedSelector(summaryItemSelectors.bySummaryIdSelector, [
    spaceId,
    summaryId,
  ])

  const loadSummaryItems = React.useCallback(async () => {
    setIsLoading(true)
    await dispatch(summaryThunks.getSummary(spaceId, summaryId))
    setIsLoading(false)
  }, [setIsLoading, dispatch, spaceId, summaryId])

  React.useEffect(() => {
    if (loadedRef.current) {
      return
    }
    loadedRef.current = true
    loadSummaryItems()
  }, [loadSummaryItems])

  return {
    isLoading,
    summaryItems,
  }
}

export const useSummaryTaskIds = (summary: Summary) => {
  const { spaceId, id: summaryId } = summary
  const summaryItems = useCachedSelector(summaryItemSelectors.bySummaryIdSelector, [
    spaceId,
    summaryId,
  ])

  return React.useMemo<Pair<number, number>[]>(
    () =>
      summaryItems.filter(item => !!item.taskId).map(item => [spaceId, item.taskId]) as Pair<
        number,
        number
      >[],
    [summaryItems, spaceId],
  )
}

export const useLoadSummaries = (spaceId: number, channelId: number, statuses: SummaryStatus[]) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = React.useState(false)
  const loadSummaries = React.useCallback(async () => {
    setIsLoading(true)
    await dispatch(summaryThunks.getSummariesList(spaceId, channelId, statuses, { page: 1 }))
    setIsLoading(false)
  }, [dispatch, spaceId, channelId, statuses, setIsLoading])
  const loadSummariesThrotled = useThrottleLock(loadSummaries, 15)

  React.useEffect(() => {
    loadSummariesThrotled()
  }, [loadSummariesThrotled])
  useRTMAuthenticated(loadSummariesThrotled)

  return {
    isLoading,
  }
}

export const useReviewSummaryActions = () => {
  const dispatch = useAppDispatch()
  const [isUpdating, setIsUpdating] = React.useState(false)

  const markSummaryReviewed = React.useCallback(
    async (summary: Summary, notify: boolean) => {
      setIsUpdating(true)
      const response = await dispatch(
        summaryThunks.reviewSummary(summary.spaceId, summary.id, { notify, status: 'REVIEWED' }),
      )
      setIsUpdating(false)
      return response.ok
    },
    [setIsUpdating, dispatch],
  )

  const markSummaryCompleted = React.useCallback(
    async (summary: Summary, notify: boolean) => {
      setIsUpdating(true)
      const response = await dispatch(
        summaryThunks.reviewSummary(summary.spaceId, summary.id, { notify, status: 'COMPLETED' }),
      )
      setIsUpdating(false)
      return response.ok
    },
    [setIsUpdating, dispatch],
  )

  return { isUpdating, markSummaryCompleted, markSummaryReviewed }
}
