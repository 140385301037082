import { setStatusBarHidden } from 'expo-status-bar'
import React from 'react'
import { Modal, StyleSheet, View, useWindowDimensions } from 'react-native'
import { Directions, FlingGestureHandler } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PinchPanContainer from '@/components/animated/PinchPanContainer'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import CachedImage from '@/components/core/CachedImage'
import CachedVideo from '@/components/core/CachedVideo'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import { FullScreenMediaProps } from '@/contexts/FullScreenMediaContext'
import { getColor } from '@/core/constants/colors'
import { platformUtils, urlUtils } from '@/core/utils'
import * as D from '@/core/utils/dates'
import { shareFile } from '@/core/utils/files'
import { isWeb } from '@/core/utils/platform'
import { useOpenURL } from '@/hooks/linking'
import { useUser } from '@/hooks/users'

interface FullScreenMediaModalProps extends FullScreenMediaProps {
  onClose: () => void
  isOpen: boolean
}

function FullScreenMediaModal({
  duration = 'short',
  isOpen,
  mediaType,
  message,
  file,
  onClose,
  uri,
}: FullScreenMediaModalProps) {
  const openInNewWindow = useOpenURL(uri)
  const { width: screenWidth, height: screenHeight } = useWindowDimensions()
  const size = {
    height: screenHeight - 100,
    width: screenWidth - 100,
  }
  const insets = useSafeAreaInsets()
  const messageUser = useUser(message?.spaceId ?? 0, message?.creatorId ?? 0)

  React.useEffect(() => {
    setStatusBarHidden(isOpen, 'fade')
  }, [isOpen])

  const handleShareFile = React.useCallback(() => shareFile(uri), [uri])
  const handleSaveMedia = React.useCallback(() => urlUtils.openURL(uri), [uri])

  const fileInfo = React.useMemo(() => {
    if (!file || !message || !messageUser) {
      return null
    }
    return (
      <FloatContainer bottom={0} left={0} right={0}>
        <Background color="ModalBG">
          <Box gapAll="xlarge">
            <Text align="center" color="white" size="h3">
              {file.filename}
            </Text>
            <Text align="center" color="white">
              Added {D.formatDateTime(D.parse(file.createdAt))} by {messageUser.firstName}{' '}
              {messageUser.lastName}
            </Text>
          </Box>
        </Background>
      </FloatContainer>
    )
  }, [file, message, messageUser])

  return (
    <Modal animationType="slide" transparent visible={isOpen}>
      <FlingGestureHandler direction={Directions.DOWN} onActivated={onClose}>
        <View style={{ flex: 1 }}>
          <Touchable
            feedback="opacity"
            onPress={isWeb ? onClose : undefined}
            style={styles.background}
          >
            <Box alignItems="center" flex={1} justifyContent="center">
              <PinchPanContainer>
                {mediaType === 'VIDEO' ? (
                  <CachedVideo size={size} uri={uri} useNativeControls />
                ) : (
                  <CachedImage duration={duration} size={size} uri={uri} />
                )}
              </PinchPanContainer>
            </Box>
            <FloatContainer right={insets.left + 5} top={insets.top + 5}>
              <BaseIconButton
                backgroundColor="ModalBG"
                color="ghost"
                name="xmark"
                onPress={onClose}
                size="h2"
              />
              <Box gapTop="medium">
                <BaseIconButton
                  backgroundColor="ModalBG"
                  color="ghost"
                  name="arrow-up-right-from-square"
                  onPress={openInNewWindow}
                  size="h2"
                />
              </Box>
              {isWeb ? (
                <Box gapTop="medium">
                  <BaseIconButton
                    backgroundColor="ModalBG"
                    color="ghost"
                    name="arrow-down-to-line"
                    onPress={handleSaveMedia}
                    size="h2"
                  />
                </Box>
              ) : (
                <Box gapTop="medium">
                  <BaseIconButton
                    backgroundColor="ModalBG"
                    color="ghost"
                    name="arrow-up-from-square"
                    onPress={handleShareFile}
                    size="h2"
                  />
                </Box>
              )}
            </FloatContainer>
            {fileInfo}
          </Touchable>
        </View>
      </FlingGestureHandler>
    </Modal>
  )
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: platformUtils.isWeb ? getColor('ModalBG') : getColor('black'),
    flex: 1,
    flexDirection: 'column',
  },
})

export default React.memo(FullScreenMediaModal)
