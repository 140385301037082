import { ColorName } from '@/core/constants/colors'

const fromMeBackground: ColorName = 'emmre-blue+2'
const fromMeForeground: ColorName = 'silver'

const fromOtherBackground: ColorName = 'heather'
const fromOtherForeground: ColorName = 'obsidian'

const completedFromMeBackground: ColorName = 'koala'
const completedFromMeForeground: ColorName = 'obsidian'

const completedFromOtherBackground: ColorName = 'koala'
const completedFromOtherForeground: ColorName = 'obsidian'

const myMessageWidth = 268
export const otherMessageWidth = 232
export const maxEmojiForSpecialPresentation = 3

type MessageColors = { foreground: ColorName; background: ColorName }

export const getMessageColors = (isFromMe: boolean): MessageColors => {
  if (isFromMe) {
    return { background: fromMeBackground, foreground: fromMeForeground }
  }
  return { background: fromOtherBackground, foreground: fromOtherForeground }
}

export const getTaskColors = (isFromMe: boolean, isCompleted: boolean): MessageColors => {
  if (isCompleted) {
    if (isFromMe) {
      return { background: completedFromMeBackground, foreground: completedFromMeForeground }
    }
    return { background: completedFromOtherBackground, foreground: completedFromOtherForeground }
  }
  return { background: fromOtherBackground, foreground: fromOtherForeground }
}

export const getMessageWidth = (isFromMe: boolean): number =>
  isFromMe ? myMessageWidth : otherMessageWidth
