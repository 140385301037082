import React from 'react'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { RegisteredModals } from '@/contexts/ModalPresenterContext/types'
import { usePromptContext } from '@/contexts/PromptContext'
import { actions } from '@/core/constants'
import { entityUtils, spaceUtils, userUtils } from '@/core/utils'
import { notificationThunks, spaceThunks } from '@/thunks'
import { ResolvedChannel, SpaceUser } from '@/types/entities'
import { useAppDispatch } from './redux'
import { useSpace } from './spaces'

type UpgradeAlertArgs = {
  upgradeAction: string
  onUpgraded: (spaceId: number) => void
  spaceId: number
}

type CreateChannelArgs = {
  onCreate?: (channel: ResolvedChannel) => void
  spaceId: number
}

export const useUpgradePrompt = ({ spaceId, upgradeAction, onUpgraded }: UpgradeAlertArgs) => {
  const space = useSpace(spaceId)
  const modalPresenter = useModalPresenterContext()
  return React.useCallback(async () => {
    if (!space) {
      return
    }

    if (!spaceUtils.canHaveMultipleUsers(space.productVersion)) {
      modalPresenter.push('selectPlan', {
        mode: 'UPGRADE_TO_PARTNERSHIP',
        onUpgraded,
        spaceId: space.id,
        upgradeAction,
      })
    } else {
      onUpgraded(space.id)
    }
  }, [onUpgraded, space, modalPresenter, upgradeAction])
}

export const useCreateChannel = ({ spaceId, onCreate }: CreateChannelArgs) => {
  const modalPresenter = useModalPresenterContext()

  return useUpgradePrompt({
    onUpgraded: React.useCallback(
      () => modalPresenter.push('createChannel', { onCreate, spaceId }),
      [onCreate, spaceId, modalPresenter],
    ),
    spaceId,
    upgradeAction: 'create a channel',
  })
}

export const useInviteUser = (user?: SpaceUser, onInvited?: (u: SpaceUser) => void) => {
  const dispatch = useAppDispatch()
  const showPrompt = usePromptContext()

  const sendInvite = React.useCallback(
    async (emailAddress: string | null) => {
      if (!user) {
        return
      }

      const response = await dispatch(
        spaceThunks.inviteUserById(
          user.spaceId,
          user.id,
          { createHub: false, emailAddress: emailAddress || undefined, role: user.role },
          false,
        ),
      )

      if (response.ok) {
        dispatch(
          notificationThunks.showToast({
            body: `Invitation sent to ${userUtils.getFullName(user)}!`,
            title: 'Notification Sent',
            type: 'success',
          }),
        )
        onInvited?.(user)
      } else {
        const { errors } = response

        if ('emailAddress' in errors) {
          dispatch(
            notificationThunks.showToast({
              body: `This email address ${errors.emailAddress[0]}`,
              title: 'Invitation Error',
              type: 'error',
            }),
          )
        }
      }
    },
    [dispatch, user, onInvited],
  )

  const getEmailAddress = React.useCallback(() => {
    showPrompt({
      message: 'Enter their email address',
      onSubmit: emailAddress => sendInvite(emailAddress),
      submitText: 'Send',
      title: `Send ${user?.firstName || ''} an Invitation`,
    })
  }, [showPrompt, sendInvite, user])

  const inviteUser = React.useCallback(() => {
    if (!user) {
      return
    }
    if (entityUtils.hasAction(actions.userActions.updateAccount, user)) {
      getEmailAddress()
    } else {
      sendInvite(null)
    }
  }, [getEmailAddress, sendInvite, user])

  return useUpgradePrompt({
    onUpgraded: () => inviteUser(),
    spaceId: user?.spaceId || 0,
    upgradeAction: 'invite additional users',
  })
}

export const useShowInviteUserModal = ({ spaceId, onCreated }: RegisteredModals['inviteUser']) => {
  const modalPresenter = useModalPresenterContext()
  const showModal = React.useCallback(
    () => modalPresenter.push('inviteUser', { onCreated, spaceId }),
    [modalPresenter, spaceId, onCreated],
  )

  return useUpgradePrompt({
    onUpgraded: () => showModal(),
    spaceId,
    upgradeAction: 'invite additional users',
  })
}
