import { Task } from '@/types/entities'

// if you can see a task you can edit it
export const canEditTask = (): boolean => true

// if you can see a task you can complete it
export const canCompleteTask = (): boolean => true

// if you can see a task you can archive it
export const canArchiveTask = (): boolean => true

// if you can see a task you can archive it
export const canDeleteTask = (): boolean => true

// if you are assigned a task you can accept it
export const canAcceptTask = (task: Task, userId: number): boolean =>
  task.assignedUserId === userId && !task.completedAt

export const canWatchTask = (task: Task, userId: number): boolean => {
  // if you can see a task you can watch it

  // In the client we don't want to let assigned users toggle however
  if (task.assignedUserId === userId) {
    return false
  }

  return true
}
