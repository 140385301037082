import React from 'react'
import { componentUtils } from '@/core/utils'
import { useCurrentUserId } from '@/hooks'
import { ComponentProp } from '@/types/components'
import { Channel } from '@/types/entities'
import MultipleUsersTypingIndicator from './MultipleUsersTypingIndicator'
import SingleUserTypingIndicator from './SingleUserTypingIndicator'

interface TypingIndicatorProps {
  channel: Channel
  FallbackComponent?: ComponentProp
  isActive: boolean
  mode?: 'SINGLE' | 'MULTIPLE' | 'AUTO'
}

function TypingIndicator({
  mode = 'AUTO',
  channel,
  isActive,
  FallbackComponent,
}: TypingIndicatorProps) {
  const myId = useCurrentUserId()
  const userIds = React.useMemo(
    () => channel.userIds.filter(userId => userId !== myId),
    [channel, myId],
  )

  if (!isActive || userIds.length === 0) {
    // Self-direct message
    return FallbackComponent ? componentUtils.renderComponent(FallbackComponent) : null
  }

  if (mode === 'SINGLE' || (mode === 'AUTO' && userIds.length === 1)) {
    // Direct message
    return <SingleUserTypingIndicator spaceId={channel.spaceId} userId={userIds[0]} />
  }

  return (
    <MultipleUsersTypingIndicator
      FallbackComponent={FallbackComponent}
      spaceId={channel.spaceId}
      userIds={userIds}
    />
  )
}

export default React.memo(TypingIndicator)
