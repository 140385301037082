import React from 'react'
import Background from '@/components/core/Background'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { ColorName } from '@/core/constants/colors'
import { taskUtils } from '@/core/utils'
import { useAppSelector } from '@/hooks'
import { taskSelectors } from '@/store/selectors'
import { QuestionTask } from '@/types/entities'
import QuestionContent from './QuestionContent'
import TaskContent from './TaskContent'

interface SummaryTaskItemProps {
  spaceId: number
  taskId: number
  canOpen?: boolean
  editing: boolean
}

function SummaryTaskItem({ editing, spaceId, taskId, canOpen = false }: SummaryTaskItemProps) {
  const task = useAppSelector(taskSelectors.byId, spaceId, taskId)
  const { showTaskForm } = useShowTaskFormContext()
  const handleShowTask = React.useCallback(
    () =>
      showTaskForm({
        defaultSpaceId: spaceId,
        formContext: 'summaries',
        taskId,
      }),
    [spaceId, taskId, showTaskForm],
  )

  if (!task) {
    return null
  }

  const isQuestion = taskUtils.isQuestion(task)
  const color = ((): ColorName => {
    if (editing) {
      return 'ghost'
    }
    return isQuestion ? 'emmre-purple-9' : 'emmre-blue-9'
  })()

  return (
    <Touchable disabled={!canOpen} feedback="opacity" onPress={handleShowTask}>
      <Box direction="row">
        <Background
          borderColor="heather"
          borderRadius={8}
          borderWidth={editing ? 1 : 0}
          color={color}
          flex={1}
        >
          {isQuestion ? (
            <QuestionContent task={task as QuestionTask} />
          ) : (
            <TaskContent task={task} />
          )}
        </Background>
      </Box>
    </Touchable>
  )
}

export default React.memo(SummaryTaskItem)
