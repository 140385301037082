import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { TaskList, TaskListItem, TaskListType } from '@/types/entities'

interface TaskListsResponse {
  taskLists: TaskList[]
}

interface TaskListResponse {
  taskList: TaskList
}

interface TaskListItemsResponse {
  taskListItems: TaskListItem[]
}

interface TaskListItemResponse {
  taskListItem: TaskListItem
}

interface SetTaskListResponse {
  taskList: TaskList
  taskListItems: TaskListItem[]
}

export type CreateTaskList = Pick<TaskList, 'listType' | 'name'>
export type CreateTaskListItem = Pick<TaskListItem, 'spaceId' | 'messageId'>
export type IgnoreTaskListItem = Pick<TaskListItem, 'spaceId' | 'messageId' | 'ignoreUntil'>

export const getTaskLists = (paging: PagingRequest) =>
  new Request<TaskListsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/task-lists`,
  })

export const getTaskListById = (taskListId: number) =>
  new Request<TaskListResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    url: `v1/task-lists/${taskListId}`,
  })

export const getTaskListByType = (userId: number, listType: TaskListType) =>
  new Request<TaskListResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    url: `v1/users/${userId}/task-lists/${listType}`,
  })

export const createTaskList = (taskList: CreateTaskList) =>
  new Request<TaskListResponse>({
    authentication: 'PRIVATE',
    body: taskList,
    method: 'POST',
    url: `v1/task-lists`,
  })

export const createTaskListItem = (
  taskListId: number,
  item: CreateTaskListItem,
  beforeItemId: number | null,
) =>
  new Request<TaskListItemResponse>({
    authentication: 'PRIVATE',
    body: { ...item, beforeItemId },
    method: 'POST',
    url: `v1/task-lists/${taskListId}/items`,
  })

export const ignoreTaskListItem = (taskListId: number, item: IgnoreTaskListItem) =>
  new Request<TaskListItemResponse>({
    authentication: 'PRIVATE',
    body: { ...item },
    method: 'POST',
    url: `v1/task-lists/${taskListId}/ignore`,
  })

export const deleteTaskListItem = (taskListItemId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/task-list-items/${taskListItemId}`,
  })

export const getTaskListItems = (taskListId: number, paging: PagingRequest) =>
  new Request<TaskListItemsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/task-lists/${taskListId}/items`,
  })

export const setTaskListItems = (taskListId: number, taskListItems: CreateTaskListItem[]) =>
  new Request<SetTaskListResponse>({
    authentication: 'PRIVATE',
    body: {
      taskListItems,
    },
    method: 'PUT',
    url: `v1/task-lists/${taskListId}/items`,
  })

export const updateTaskListItem = (taskListItemId: number, beforeItemId: number | null) =>
  new Request<TaskListItemResponse>({
    authentication: 'PRIVATE',
    body: { beforeItemId },
    method: 'PUT',
    url: `v1/task-list-items/${taskListItemId}`,
  })
