import React from 'react'
import Box from '@/components/layouts/Box'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'
import { taskUtils } from '@/core/utils'
import { QuestionResponse, QuestionTask } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'
import SelectQuestionResponseButton from './SelectQuestionResponseButton'

interface YesNoResponseProps {
  disabled: boolean
  onSubmit: (response: QuestionResponseForm) => void
  submitOnChange: boolean
  task: QuestionTask
}

function YesNoResponse({ disabled, onSubmit, submitOnChange, task }: YesNoResponseProps) {
  const { question } = task
  const { responses } = question
  const isAnswered = taskUtils.isAnswered(task)

  const { responseForm, setResponseSelected } = useQuestionResponseContext()

  const handleSelectResponse = React.useCallback(
    (res: QuestionResponse) => {
      setResponseSelected(res.id, true)
      if (submitOnChange) {
        onSubmit({ openEndedResponse: null, otherSelected: false, selectedResponseIds: [res.id] })
      }
    },
    [onSubmit, setResponseSelected, submitOnChange],
  )

  const selectedResponseId = responseForm.selectedResponseIds[0]

  return (
    <Box direction="row" justifyContent="center">
      <SelectQuestionResponseButton
        answered={isAnswered}
        disabled={disabled}
        isSelected={responses[0].id === selectedResponseId}
        isTemporaryAnswered={!isAnswered && !!selectedResponseId}
        onPress={handleSelectResponse}
        response={responses[0]}
      />
      <Box width={30} />
      <SelectQuestionResponseButton
        answered={isAnswered}
        disabled={disabled}
        isSelected={responses[1].id === selectedResponseId}
        isTemporaryAnswered={!isAnswered && !!selectedResponseId}
        onPress={handleSelectResponse}
        response={responses[1]}
      />
    </Box>
  )
}

export default React.memo(YesNoResponse)
