import React from 'react'
import { useCachedSelector } from '@/hooks'
import { attachmentSelectors } from '@/store/selectors'
import { Attachment } from '@/types/api'
import { Measurement, RenderListItemContainer } from '@/types/components'
import AttachmentListItem from './AttachmentListItem'

export interface AttachmentsListProps {
  attachmentIds: string[]
  onRemove: (attachment: Attachment) => void
  size: Measurement
  renderItemContainer?: RenderListItemContainer
}

function AttachmentsList({
  attachmentIds,
  onRemove,
  size,
  renderItemContainer,
}: AttachmentsListProps) {
  const attachments = useCachedSelector(attachmentSelectors.byIdsSelector, [attachmentIds])

  if (attachments.length === 0) {
    return null
  }

  return (
    <>
      {attachments.map((attachment, index) => (
        <AttachmentListItem
          key={attachment.temporaryId.toString()}
          attachment={attachment}
          index={index}
          onRemove={onRemove}
          renderContainer={renderItemContainer}
          size={size}
        />
      ))}
    </>
  )
}

export default React.memo(AttachmentsList)
