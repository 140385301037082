import { config } from '@/core'
import { SpaceUser } from '@/types/entities'
import { guidesIndex } from './constants'
import * as storage from './storage'
import { ShowGuideOptions, StonlyGuideKey, StonlyProxyInterface } from './types'

const isStonlyEnabled = () => !!config.stonlyWidgetId

const initialize = async () => {
  if (!isStonlyEnabled()) {
    return
  }

  stonlyTrack('init', config.stonlyWidgetId)

  if (config.stonlyDebug) {
    stonlyTrack('enableDebug')
    StonlyWidget('enableDebug')
  }
}

const setUser = async (user: SpaceUser) => {
  if (!isStonlyEnabled()) {
    return
  }

  stonlyTrack('identify', user.id.toString(), {
    role: user.role,
  })
}

const hasBeenShown = async (key: StonlyGuideKey) => storage.hasBeenShown(key)

const showGuide = async (key: StonlyGuideKey, { forceShow, asTour }: ShowGuideOptions) => {
  if (!isStonlyEnabled()) {
    return false
  }

  if (!forceShow) {
    const alreadyShown = await hasBeenShown(key)

    if (alreadyShown) {
      return false
    }
  }

  await storage.setShown(key)

  const { guideId } = guidesIndex[key]

  if (asTour) {
    StonlyWidget('openGuidedTour', { guideId })
  } else {
    StonlyWidget('openGuide', { guideId })
  }

  return true
}

const resetGuide = async (key: StonlyGuideKey) => {
  await storage.resetGuide(key)
}

const stonlyProxy: StonlyProxyInterface = {
  hasBeenShown,
  initialize,
  isAvailable: true,
  resetGuide,
  setUser,
  showGuide,
}

// ts-unused-exports:disable-next-line
export default stonlyProxy
