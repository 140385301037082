import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import Checkbox from '@/components/core/Checkbox'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import { noOp } from '@/core/utils/fns'
import { isWeb } from '@/core/utils/platform'
import { QuestionResponseType } from '@/types/entities'

interface ChoiceItemProps {
  index: number
  isLast: boolean
  onRemove: (index: number) => void
  onSubmitEditing?: () => void
  onUpdate: (index: number, value: string) => void
  questionResponseType: QuestionResponseType
  value: string
}

function ChoiceItem({
  index,
  isLast,
  onRemove,
  onSubmitEditing,
  onUpdate,
  questionResponseType,
  value,
}: ChoiceItemProps) {
  const handleRemove = React.useCallback(() => {
    onRemove(index)
  }, [onRemove, index])
  const handleUpdate = React.useCallback(
    (newValue: string) => {
      onUpdate(index, newValue)
    },
    [onUpdate, index],
  )
  return (
    <Box
      key={index}
      alignItems="flex-start"
      direction="row"
      gapBottom="small"
      justifyContent="center"
    >
      <Box gapRight="medium">
        <BaseIconButton
          color="cheddar"
          hitSlop="small"
          kind="solid"
          name="circle-x"
          onPress={handleRemove}
          padding={3}
          size="h4"
        />
      </Box>
      <Checkbox
        checked={false}
        disabled
        onChange={noOp}
        shape={questionResponseType === 'SINGLE' ? 'circle' : 'square'}
      />
      <Box flex={1} gapLeft="small">
        <TextInput
          autoFocus={isLast && !value}
          blurOnSubmit={isWeb}
          expandable
          kind="bare"
          multiline
          onChangeText={handleUpdate}
          onSubmitEditing={onSubmitEditing}
          value={value}
        />
      </Box>
    </Box>
  )
}

export default React.memo(ChoiceItem)
