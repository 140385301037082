import React from 'react'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { useCurrentUserId } from '@/hooks'
import { useHubUser, useHubUsers } from '@/hooks/channels'
import { Channel } from '@/types/entities'

type HubPictureSize = 'SMALL' | 'MEDIUM' | 'LARGE'

interface HubUserPicturesProps {
  hub: Channel
  mode?: 'BOTH' | 'OTHER'
  size: HubPictureSize
}

type InnerProps = Omit<HubUserPicturesProps, 'mode'>

interface PictureSize {
  primary: number
  secondary: number
  offset: number
}

const pictureSizes: Record<HubPictureSize, PictureSize> = {
  LARGE: { offset: -15, primary: 60, secondary: 30 },
  MEDIUM: { offset: -12, primary: 48, secondary: 24 },
  SMALL: { offset: -10, primary: 40, secondary: 20 },
}

function BothHubUserPictures({ hub, size }: InnerProps) {
  const myId = useCurrentUserId() || 0
  const hubUsers = useHubUsers(hub)

  if (!hubUsers) {
    return null
  }

  const [firstUser, secondUser] = hubUsers

  const isPersonal = !secondUser && myId === firstUser.id

  const { primary, secondary, offset } = pictureSizes[size]

  return (
    <Box>
      <UserProfilePicture
        backgroundColor={isPersonal ? 'cyan' : 'emmre-blue+2'}
        foregroundColor="ghost"
        size={primary}
        user={firstUser}
      />
      {secondUser ? (
        <FloatContainer bottom={-4} right={offset}>
          <UserProfilePicture
            backgroundColor="rust"
            borderColor="ghost"
            borderWidth={1}
            foregroundColor="ghost"
            size={secondary}
            user={secondUser}
          />
        </FloatContainer>
      ) : null}
    </Box>
  )
}

function OtherHubUserPicture({ hub, size }: InnerProps) {
  const user = useHubUser(hub.spaceId, hub.userIds)
  const isPersonal = hub.userIds.length === 1
  const { primary } = pictureSizes[size]

  return (
    <UserProfilePicture
      backgroundColor={isPersonal ? 'cyan' : 'emmre-blue+2'}
      foregroundColor="ghost"
      size={primary}
      user={user}
    />
  )
}

function HubUserPictures({ mode = 'BOTH', ...props }: HubUserPicturesProps) {
  return mode === 'BOTH' ? <BothHubUserPictures {...props} /> : <OtherHubUserPicture {...props} />
}

export default React.memo(HubUserPictures)
