import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { stringUtils } from '@/core/utils'
import { RootState } from '@/store'
import { Thread } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, messageId: number): string => `${spaceId}:${messageId}`

const adapter = createEntityAdapter<Thread>({
  selectId: thread => buildId(thread.spaceId, thread.messageId),
  sortComparer: (a, b) => stringUtils.sortDescending(a.lastReplyAt, b.lastReplyAt),
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/threads',
  reducers: {
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.threads)

const selectById = (state: RootState, spaceId: number, messageId: number) =>
  state.entities.threads.entities[buildId(spaceId, messageId)]

const byIdSelector = (spaceId: number, messageId: number) =>
  createSelector(
    sliceSelectors.selectEntities,
    threadsIndex => threadsIndex[buildId(spaceId, messageId)],
  )

const byIdsSelector = (spaceId: number, threadIds: number[]) =>
  createSelector(
    sliceSelectors.selectEntities,
    threadsIndex =>
      threadIds
        .map(threadId => threadsIndex[buildId(spaceId, threadId)])
        .filter(thread => !!thread) as Thread[],
  )

const bySpaceIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, threads =>
    threads.filter(thread => thread.spaceId === spaceId),
  )

const selectors = {
  byId: selectById,
  byIdSelector,
  byIdsSelector,
  bySpaceIdSelector,
}

export { actions, selectors }
export default reducer
