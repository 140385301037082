import React from 'react'
import Button from '@/components/core/Button'
import Box from '@/components/layouts/Box'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'
import { ColorName } from '@/core/constants/colors'
import { taskUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'
import MultipleChoiceResponse from './MultipleChoiceResponse'
import { useSubmitResponse } from './hooks'
import { QuestionDisabled } from './types'

interface MultipleChoiceResponseContainerProps {
  disabled: QuestionDisabled
  foregroundColor: ColorName
  onAnswered?: () => void
  task: QuestionTask
}

function MultipleChoiceResponseContainer({
  disabled,
  foregroundColor,
  onAnswered,
  task,
}: MultipleChoiceResponseContainerProps) {
  const { isChanged, isComplete } = useQuestionResponseContext()
  const { handleSubmitResponse, isSubmitting } = useSubmitResponse(task, onAnswered)
  const isAnswered = taskUtils.isAnswered(task)
  const isDisabled =
    disabled === true || isSubmitting || (isAnswered && disabled === 'WHEN_ANSWERED')
  const showButton = !isDisabled && (!isAnswered || isChanged)

  return (
    <Box gapLeft="small">
      <MultipleChoiceResponse disabled={isDisabled} foregroundColor={foregroundColor} task={task} />
      {showButton ? (
        <Box direction="row" gapTop="small" justifyContent="flex-end">
          <Button
            disabled={!isComplete}
            kind="primary"
            label={isAnswered ? 'Change Answer' : 'Answer'}
            onPress={handleSubmitResponse}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(MultipleChoiceResponseContainer)
