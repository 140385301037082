import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { phoneNumbersAPI } from '@/api/requests'
import { formActions, notificationActions, phoneNumberActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'
import { PhoneNumber } from '@/types/entities'
import { Thunk } from '@/types/store'
import { notifyErrors, setErrors } from './errors'
import { showToast } from './notifications'
import { makeEnhancedRequest } from './utils'

export const getPhoneNumbersList =
  (spaceId: number, page = 1): Thunk<boolean> =>
  async dispatch => {
    const request = phoneNumbersAPI.getPhoneNumbersList(spaceId, { page })
    const response = await dispatch(makeEnhancedRequest(request))

    if (!response.ok) {
      dispatch(
        showToast({
          body: 'An error occurred when getting your list of phone numbers',
          title: 'Error',
          type: 'error',
        }),
      )
      return false
    }

    dispatch(phoneNumberActions.upsertMany(response.data.phoneNumbers))
    return true
  }

export const createPhoneNumber =
  (
    spaceId: number,
    userPhoneNumber: string,
  ): RequestThunk<typeof phoneNumbersAPI.createPhoneNumber> =>
  async dispatch => {
    const request = phoneNumbersAPI.createPhoneNumber(spaceId, userPhoneNumber)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(phoneNumberActions.upsertOne(response.data.phoneNumber))
    }

    return response
  }

const successNotification = notificationActions.push({
  body: 'We have sent a verification code to this phone number. Please enter it to verify this phone number.',
  title: 'Success!',
  type: 'success',
})

export const createPhoneNumberForm =
  (spaceId: number, formName: string): Thunk<PhoneNumber | null> =>
  async (dispatch, getState) => {
    const { userPhoneNumber } = formSelectors.values(getState(), formName)

    const response = await dispatch(createPhoneNumber(spaceId, userPhoneNumber))

    if (response.ok) {
      batch(() => {
        dispatch(formActions.clear(formName))
        dispatch(successNotification)
      })
      return response.data.phoneNumber
    }

    dispatch(setErrors(formName, response.errors, 'Error Creating Phone Number'))
    return null
  }

export const sendPhoneNumberVerificationCode =
  (phoneNumberId: number): Thunk<boolean> =>
  async dispatch => {
    const request = phoneNumbersAPI.sendPhoneNumberVerificationCode(phoneNumberId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(successNotification)
    } else {
      dispatch(notifyErrors('Error Sending Phone Number Verification Code', response.errors))
    }

    return response.ok
  }

export const verifyPhoneNumber =
  (
    phoneNumberId: number,
    verificationCode: string,
  ): RequestThunk<typeof phoneNumbersAPI.verifyPhoneNumber> =>
  async dispatch => {
    const request = phoneNumbersAPI.verifyPhoneNumber(phoneNumberId, verificationCode)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(phoneNumberActions.upsertOne(response.data.phoneNumber))
    }

    return response
  }

export const verifyPhoneNumberForm =
  (phoneNumberId: number, formName: string): Thunk<boolean> =>
  async (dispatch, getState) => {
    const { verificationCode } = formSelectors.values(getState(), formName)
    const response = await dispatch(verifyPhoneNumber(phoneNumberId, verificationCode))

    if (response.ok) {
      batch(() => {
        dispatch(formActions.clear(formName))
        dispatch(
          showToast({
            body: 'Your number has been verified',
            title: 'Success!',
            type: 'success',
          }),
        )
      })
    } else {
      dispatch(setErrors(formName, response.errors, 'Error Verifying Phone Number'))
    }

    return response.ok
  }

export const deletePhoneNumber =
  (phoneNumberId: number): Thunk<boolean> =>
  async dispatch => {
    const request = phoneNumbersAPI.deletePhoneNumber(phoneNumberId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(phoneNumberActions.removeOne(phoneNumberId))
    } else {
      dispatch(notifyErrors('Error Deleting Phone Number', response.errors))
    }

    return response.ok
  }
