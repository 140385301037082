import { batch } from 'react-redux'
import WebSocketManager from '@/api/rtm/WebSocketManager'
import { eventTypes } from '@/core/constants'
import { emitter } from '@/core/pubsub'
import { messageUtils } from '@/core/utils'
import {
  activityActions,
  boardActions,
  channelActions,
  channelUserActions,
  spaceActions,
  summaryActions,
  tagActions,
  taskListItemActions,
  threadActions,
  userActions,
} from '@/store/actions'
import { buildUserId, getBoardId, getChannelId, getTagId } from '@/store/entityIds'
import { authSelectors, threadSelectors } from '@/store/selectors'
import { Channel, SentMessage } from '@/types/entities'
import type { RTMEvent } from '@/types/rtm'
import { Dispatch, GetState, SyncThunk, Thunk } from '@/types/store'
import { incrementUnreadMessageCount, updateChannelStats } from './channels'
import { initializeSpace } from './initializers'
import { addSentMessage } from './messages'
import { setBadge } from './notifications'
import { getAllSpacesList, spaceDeleted } from './spaces'
import { addTask } from './store'
import { storeTaskList, storeTaskListItem } from './taskLists'
import { getAllUpdatedTasks } from './tasks'
import { handleNotification } from './webNotifications'

export const channelFocused =
  (manager: WebSocketManager, spaceId: number, channelId: number): SyncThunk<void> =>
  () => {
    manager.send({
      payload: {
        channelId,
        spaceId,
      },
      type: 'activity/channel_focused',
    })
  }

export const channelUnfocused =
  (manager: WebSocketManager, spaceId: number): SyncThunk<void> =>
  () => {
    manager.send({
      payload: {
        channelId: null,
        spaceId,
      },
      type: 'activity/channel_focused',
    })
  }

export const typing =
  (manager: WebSocketManager, channel?: Channel): SyncThunk<void> =>
  () => {
    if (!channel) {
      return
    }
    manager.send({
      payload: {
        channelId: channel.id,
        spaceId: channel.spaceId,
      },
      type: 'activity/typing',
    })
  }

export const messageHandler =
  (event: RTMEvent): Thunk<boolean> =>
  async (dispatch, getState) => {
    switch (event.type) {
      case 'authenticated':
        emitter.emit(eventTypes.RTM_AUTHENTICATED)
        break
      case 'activity/arrived': {
        const { spaceId, userId } = event.payload
        dispatch(activityActions.arrived({ userId: buildUserId(spaceId, userId) }))
        break
      }
      case 'activity/departed': {
        const { spaceId, userId } = event.payload
        dispatch(activityActions.departed({ userId: buildUserId(spaceId, userId) }))
        break
      }
      case 'activity/typing': {
        if (!('userId' in event.payload)) {
          break
        }
        const { spaceId, userId } = event.payload
        dispatch(activityActions.startTyping({ userId: buildUserId(spaceId, userId) }))
        break
      }
      case 'space/user_added': {
        const { user } = event.payload
        const myId = authSelectors.myId(getState())

        if (user.id === myId) {
          await dispatch(getAllSpacesList())
          await dispatch(initializeSpace(user.spaceId))
        }

        dispatch(userActions.upsertOne(user))
        break
      }
      case 'space/user_removed': {
        const { user } = event.payload
        const myId = authSelectors.myId(getState())

        if (user.id === myId) {
          await dispatch(spaceDeleted(user.spaceId))
        }

        dispatch(userActions.upsertOne(user))
        break
      }
      case 'space/created': {
        const { space } = event.payload
        dispatch(spaceActions.upsertOne(space))
        await dispatch(initializeSpace(space.id))
        break
      }
      case 'space/deleted': {
        const { spaceId } = event.payload
        dispatch(spaceDeleted(spaceId))
        break
      }
      case 'space/updated': {
        const { space } = event.payload
        dispatch(spaceActions.upsertOne(space))
        break
      }
      case 'channel/updated': {
        const { channel } = event.payload
        dispatch(channelActions.upsertOne(channel))
        break
      }
      case 'channel/user_added': {
        const { channel, channelUser } = event.payload
        const myId = authSelectors.myId(getState())

        batch(() => {
          dispatch(channelActions.upsertOne(channel))
          dispatch(channelUserActions.upsertOne(channelUser))
          if (myId === channelUser.userId) {
            dispatch(getAllUpdatedTasks(channelUser.spaceId))
          }
        })
        break
      }
      case 'channel/user_removed': {
        const { channel, userId } = event.payload
        dispatch(channelActions.upsertOne(channel))
        const myId = authSelectors.myId(getState())
        if (myId === userId) {
          dispatch(channelActions.removeOne(getChannelId(channel)))
        }
        break
      }
      case 'channel/read_updated':
      case 'channel_user/updated': {
        const { channelUser } = event.payload
        dispatch(channelUserActions.upsertOne(channelUser))
        dispatch(setBadge())
        break
      }
      case 'message/created':
      case 'message/updated': {
        const { message } = event.payload
        const { spaceId, creatorId } = message
        const userId = buildUserId(spaceId, creatorId)
        batch(() => {
          dispatch(activityActions.stopTyping({ userId }))
          dispatch(addSentMessage(message))
          if (event.type !== 'message/updated') {
            updateUnread(dispatch, getState, message)
            dispatch(updateChannelStats(message))
          }
        })
        break
      }
      case 'task/created':
      case 'task/updated': {
        const { message, task } = event.payload
        const { spaceId, creatorId } = message
        const userId = buildUserId(spaceId, creatorId)
        batch(() => {
          dispatch(activityActions.stopTyping({ userId }))
          dispatch(addSentMessage(message))
          dispatch(addTask(task))
          if (event.type !== 'task/updated') {
            updateUnread(dispatch, getState, message)
            dispatch(updateChannelStats(message))
          }
        })
        break
      }
      case 'task/deleted': {
        const { task } = event.payload
        dispatch(addTask(task))
        break
      }
      case 'thread/updated': {
        const { thread } = event.payload
        dispatch(threadActions.upsertOne(thread))
        break
      }
      case 'task_list_item/created':
      case 'task_list_item/updated': {
        const { taskListItem: item } = event.payload
        dispatch(storeTaskListItem(item))
        break
      }
      case 'task_list_item/deleted': {
        const { taskListItem: item } = event.payload
        dispatch(taskListItemActions.removeItem({ itemId: item.id }))
        break
      }
      case 'task_list/set': {
        const { taskList } = event.payload
        dispatch(storeTaskList(taskList))
        break
      }
      case 'board/created': {
        const { board } = event.payload
        dispatch(boardActions.upsertOne(board))
        dispatch(getAllUpdatedTasks(board.spaceId))
        break
      }
      case 'board/updated': {
        const { board } = event.payload
        dispatch(boardActions.setOne(board))
        break
      }
      case 'board/deleted': {
        const { board } = event.payload
        dispatch(boardActions.removeOne(getBoardId(board)))
        dispatch(getAllUpdatedTasks(board.spaceId))
        break
      }
      case 'tag/created':
        // Ignore tag creates. We only care about them if they're on a task we can see and
        // we will get them that way
        break
      case 'tag/updated': {
        const { tag } = event.payload
        dispatch(tagActions.setOne(tag))
        break
      }
      case 'tag/deleted': {
        const { tag } = event.payload
        dispatch(tagActions.removeOne(getTagId(tag)))
        dispatch(getAllUpdatedTasks(tag.spaceId))
        break
      }
      case 'summary/created':
      case 'summary/updated': {
        const { summary } = event.payload
        dispatch(summaryActions.upsertOne(summary))
        break
      }
      default:
        return false
    }

    await dispatch(handleNotification(event))

    return true
  }

const updateUnread = (dispatch: Dispatch, getState: GetState, message: SentMessage) => {
  const { spaceId, channelId, creatorId } = message
  const threadId = messageUtils.getThreadId(message)
  const thread = threadSelectors.byId(getState(), spaceId, threadId)

  // If the message is not supposed to show in chat, do not update counts
  if (!messageUtils.showInChat(message)) {
    return
  }

  // If the thread is ignored do not update the unread count
  //
  // The backend still sends the mssage so we can update thread info, but does not increment
  // unread counts.
  if (thread?.isIgnored) {
    return
  }

  const currentUserId = authSelectors.myId(getState())
  if (creatorId !== currentUserId) {
    dispatch(incrementUnreadMessageCount(spaceId, channelId, 1))
    dispatch(setBadge())
  }
}
