import { Linking } from 'react-native'
import { config } from '@/core'
import { isWeb } from '../platform'

export const openURL = (partialURL: string) => {
  const url = toURL(partialURL)?.toString()

  if (!url) {
    return
  }

  if (isLocalURL(url)) {
    if (isWeb) {
      // For local urls on web, replace current window's location with nwe one
      window.location.replace(url)
    } else {
      Linking.openURL(url)
    }
  } else if (isEmailURL(url)) {
    Linking.openURL(url)
  } else {
    openURLAsExternalPage(url)
  }
}

// Check to see if the string starts with what looks like a protocol
const isURL = (partialURL: string): URL | null => {
  try {
    const url = new URL(partialURL)
    return url
  } catch (ex) {
    return null
  }
}

const convertToURL = (partialURL: string): URL => {
  const url = isURL(partialURL)

  if (url) {
    return url
  }

  // If the partial URL starts with a '/' then consider it to be a relative path
  if (partialURL.startsWith('/')) {
    return new URL(partialURL, config.appURLRoot)
  }

  // Otherwise it's not a URL and it doesn't start with a slash, assume it starts
  // with a hostname
  return new URL(`http://${partialURL}`)
}

const toURL = (partialURL: string): URL | null => {
  try {
    return convertToURL(partialURL)
  } catch (ex) {
    return null
  }
}

const isLocalURL = (partialURL: string) => {
  const url = toURL(partialURL)
  return url && url.toString().startsWith(config.appURLRoot)
}

const isEmailURL = (url: string) => url.startsWith('mailto:')

const openURLAsExternalPage = (url: string) => {
  if (!isWeb) {
    Linking.openURL(url)
  } else {
    openURLWithVirtualAnchor(url)
  }
}

/**
 * Safari always opens window.open() in
 * a new window, we like opening new tabs.
 * This creates an anchor element and clicks
 * it to trick safari to opening up a tab
 * instead of a new window.
 */
const openURLWithVirtualAnchor = (url: string) => {
  if (!isWeb) {
    throw new Error('openURLWithVirtualAnchor should only be used for web')
  }
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.setAttribute('target', '_blank')
  anchor.click()
}
