import React from 'react'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import { ColorName } from '@/core/constants/colors'
import { spaceUtils } from '@/core/utils'
import { getInvoiceFromEstimate, removeEmmrePrefix } from '@/core/utils/chargebee'
import { noOp } from '@/core/utils/fns'
import { centsToDollars } from '@/core/utils/numbers'
import { useAppDispatch } from '@/hooks'
import { integrationThunks } from '@/thunks'
import { ChargebeeEstimatesMap } from '@/types/chargebee'
import { ProductVersion, Space } from '@/types/entities'

interface ProductCardProps {
  color?: ColorName
  description: React.ReactElement
  disabled: boolean
  estimates: ChargebeeEstimatesMap
  onAddPayment?: () => void
  onSelect: (productVersion: ProductVersion) => void
  productVersion: ProductVersion
  space: Space
  supportsMultipleUsers: boolean
  flex?: boolean
}

const radius = 8

function ProductCard({
  color,
  description,
  disabled,
  estimates,
  onAddPayment,
  onSelect,
  productVersion,
  space,
  supportsMultipleUsers,
  flex = true,
}: ProductCardProps) {
  const dispatch = useAppDispatch()
  const { id: spaceId } = space
  const handleSelect = React.useCallback(() => onSelect(productVersion), [onSelect, productVersion])
  const handleLaunchPortal = React.useCallback(() => {
    dispatch(integrationThunks.showAccount(spaceId))
  }, [dispatch, spaceId])

  const estimate = estimates[productVersion]
  const showPaymentLink = !!onAddPayment

  if (!estimate) {
    return null
  }

  const invoice = getInvoiceFromEstimate(estimate)
  const plan = invoice.lineItems[0]
  const isSelected = space.productVersion === productVersion
  const isTrial = spaceUtils.isUnpaidTrial(space.accountType)
  const unitLabel = supportsMultipleUsers ? '/ month per user' : '/ month'

  if (!plan) {
    return null
  }

  const planName = removeEmmrePrefix(plan.description)
  const unitAmountDollars = centsToDollars(plan.unitAmount)

  return (
    <Background
      borderColor={color}
      borderRadius={radius}
      borderWidth={2}
      maxWidth={340}
      width="100%"
    >
      <Background color={color}>
        <Box alignItems="center" gapVertical="large">
          <Text color="ghost" size="h1" weight="medium">
            {planName}
          </Text>
        </Box>
      </Background>
      {isSelected ? (
        <Box>
          <FloatContainer
            style={{ flexDirection: 'row', justifyContent: 'center', width: '100%' }}
            top={-15}
          >
            <Background borderColor="ghost" borderRadius={20} borderWidth={2} color={color}>
              <Box gapHorizontal="small" gapVertical="xsmall">
                <Text color="ghost" size="h5">
                  Current Plan
                </Text>
              </Box>
            </Background>
          </FloatContainer>
        </Box>
      ) : null}
      <Box flex={flex ? 1 : undefined} gapAll="large">
        <Text align="center" size="h2" weight="medium">
          ${unitAmountDollars} <Text size="h3">{unitLabel}</Text>
        </Text>
        <Text align="center" italic size="h6">
          {supportsMultipleUsers ? '(unlimited users)' : '(single user)'}
        </Text>
        <Box flex={flex ? 1 : undefined} gapVertical={40}>
          {description}
        </Box>
        {isSelected ? (
          <>
            <Button disabled={disabled} kind="primary" label="✔" onPress={noOp} />
            <Box alignItems="center" gapTop="medium">
              {(() => {
                if (!showPaymentLink) {
                  return null
                }
                if (isTrial) {
                  return <Link label="Add Payment Information" onPress={onAddPayment} />
                }
                return (
                  <Link
                    label="Update Payment Information"
                    onPress={handleLaunchPortal}
                    weight="medium"
                  />
                )
              })()}
            </Box>
          </>
        ) : (
          <>
            <Button
              disabled={disabled}
              kind="warning"
              label={`Switch to ${planName}`}
              onPress={handleSelect}
              weight="medium"
            />
            {showPaymentLink ? (
              <Box gapTop="medium">
                <Text> </Text>
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </Background>
  )
}

export default React.memo(ProductCard)
