import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { spaceAPI } from '@/api/requests'
import { fileUtils, userUtils } from '@/core/utils'
import {
  appProfileActions,
  boardActions,
  channelActions,
  channelStatActions,
  channelUserActions,
  formActions,
  messageActions,
  phoneNumberActions,
  slackConnectionActions,
  spaceActions,
  tagActions,
  taskActions,
  taskListItemActions,
  threadActions,
  userActions,
} from '@/store/actions'
import {
  appProfileSelectors,
  authSelectors,
  formSelectors,
  spaceSelectors,
} from '@/store/selectors'
import { Space, SpaceEntity } from '@/types/entities'
import { SyncThunk, Thunk } from '@/types/store'
import { AuthInitializer, AuthResponse, handleSignIn } from './auth'
import { notifyErrors, setErrors } from './errors'
import { uploadLocalFile } from './files'
import { showToast } from './notifications'
import { getAllPages, makeEnhancedRequest } from './utils'

interface UpdateSpace {
  name: string
  iconImageUrl?: string | null
  iconImageName?: string | null
}

// When refreshing spaces we have to always get all spaces since when a space is deleted we can't
// know that unless we get the full list of spaces and consider that the new list.
export const getAllSpacesList = () => getAllPages(page => getSpacesList(page))

export const getSpacesList =
  (page = 1, perPage = 100): RequestThunk<typeof spaceAPI.getSpacesList> =>
  async dispatch => {
    const request = spaceAPI.getSpacesList(null, { page, perPage })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { spaces } = response.data

      if (page === 1) {
        dispatch(setSpaces(spaces))
      } else {
        dispatch(spaceActions.upsertMany(spaces))
      }

      fileUtils.cacheFiles(
        spaces.filter(space => !!space.iconImageUrl).map(space => space.iconImageUrl as string),
        'long',
      )
    }

    return response
  }

export const createSpace =
  (create: spaceAPI.CreateSpace): RequestThunk<typeof spaceAPI.createSpace> =>
  async dispatch => {
    const request = spaceAPI.createSpace(create)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { space } = response.data
      dispatch(spaceActions.upsertOne(space))
    }

    return response
  }

export const getSpace =
  (spaceId: number): Thunk<Space | undefined> =>
  async dispatch => {
    const request = spaceAPI.getSpace(spaceId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { space } = response.data
      // NOTE: Not updating redux
      return space
    }

    return undefined
  }

export const updateSpace =
  (spaceId: number, updates: UpdateSpace): RequestThunk<typeof spaceAPI.updateSpace> =>
  async dispatch => {
    const body: spaceAPI.UpdateSpace = {
      name: updates.name,
    }

    if (updates.iconImageUrl) {
      const attachment = fileUtils.buildAttachment(
        updates.iconImageUrl,
        updates.iconImageName || null,
      )
      const newFile = await dispatch(uploadLocalFile(attachment))
      if (newFile) {
        body.iconImageFileId = newFile.id
      }
    }

    const request = spaceAPI.updateSpace(spaceId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { space } = response.data
      dispatch(spaceActions.upsertOne(space))
    }

    return response
  }

export const getInvitee =
  (joinToken: string): RequestThunk<typeof spaceAPI.getInvitee> =>
  async dispatch => {
    const request = spaceAPI.getInvitee(joinToken)
    const response = await dispatch(makeEnhancedRequest(request))

    return response
  }

export const inviteUserById =
  (
    spaceId: number,
    userId: number,
    body: spaceAPI.InviteUserById,
    notify: boolean,
  ): RequestThunk<typeof spaceAPI.inviteUserById> =>
  async dispatch => {
    const request = spaceAPI.inviteUserById(spaceId, userId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { user, channels, channelsUsers } = response.data

      batch(() => {
        dispatch(userActions.upsertOne(user))
        dispatch(channelUserActions.upsertMany(channelsUsers))
        dispatch(channelActions.upsertMany(channels))
      })

      if (notify) {
        dispatch(
          showToast({
            body: `Invitation sent to ${userUtils.getFullName(user)}`,
            title: 'Collaborator Incoming!',
            type: 'success',
          }),
        )
      }
    } else if (notify) {
      dispatch(notifyErrors('Error Inviting User to Space', response.errors))
    }

    return response
  }

export const createUser =
  (spaceId: number, body: spaceAPI.InviteUser): RequestThunk<typeof spaceAPI.createUser> =>
  async dispatch => {
    const request = spaceAPI.createUser(spaceId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { user, channels, channelsUsers, space } = response.data

      batch(() => {
        dispatch(userActions.upsertOne(user))
        dispatch(channelUserActions.upsertMany(channelsUsers))
        dispatch(channelActions.upsertMany(channels))
        dispatch(spaceActions.upsertOne(space))
      })
    } else {
      dispatch(notifyErrors('Error Creating User', response.errors))
    }

    return response
  }

export const joinSpaceForm =
  <I>(
    joinToken: string,
    formName: string,
    onInitialize: AuthInitializer<I>,
  ): Thunk<AuthResponse<spaceAPI.JoinResponse, I>> =>
  async (dispatch, getState) => {
    const { firstName, lastName, emailAddress, password, timezoneName } = formSelectors.values(
      getState(),
      formName,
    )
    const body = {
      emailAddress,
      firstName,
      lastName,
      password,
      timezoneName,
      tosAccepted: true,
    }

    const request = spaceAPI.joinSpace(joinToken, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      batch(() => {
        dispatch(spaceActions.upsertOne(response.data.space))
        dispatch(channelActions.upsertMany(response.data.channels))
      })

      const initializationResult = await dispatch(handleSignIn(response.data, onInitialize))
      return { initializationResult, ok: true, response }
    }

    dispatch(formActions.setErrors({ errors: response.errors, formName }))

    return { ok: false, response }
  }

export const updateSpaceForm =
  (spaceId: number, formName: string): Thunk<boolean> =>
  async (dispatch, getState) => {
    const updates = formSelectors.values(getState(), formName)
    const response = await dispatch(updateSpace(spaceId, updates as UpdateSpace))

    if (response.ok) {
      dispatch(formActions.clear(formName))
      return true
    }

    dispatch(setErrors(formName, response.errors, 'Error Updating Space'))
    return false
  }

export const createSpaceForm =
  (formName: string): RequestThunk<typeof spaceAPI.createSpace> =>
  async (dispatch, getState) => {
    const create = formSelectors.values(getState(), formName)
    const response = await dispatch(createSpace(create as spaceAPI.CreateSpace))

    if (response.ok) {
      dispatch(formActions.clear(formName))
    } else {
      dispatch(setErrors(formName, response.errors, 'Error Creating Space'))
    }
    return response
  }

export const leaveSpace =
  (spaceId: number, onSuccess: () => void | Promise<void>): Thunk<boolean> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState())

    if (!myId) {
      return false
    }

    const request = spaceAPI.removeUserFromSpace(spaceId, myId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      await onSuccess()
      dispatch(spaceDeleted(spaceId))
      return true
    }

    return false
  }

export const removeUserFromSpace =
  (spaceId: number, userId: number): Thunk<boolean> =>
  async dispatch => {
    const request = spaceAPI.removeUserFromSpace(spaceId, userId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(userActions.upsertOne(response.data.user))
      return true
    }

    return false
  }

export const addSpaceAdministrator =
  (spaceId: number, userId: number): Thunk<boolean> =>
  async dispatch => {
    const request = spaceAPI.addSpaceAdministrator(spaceId, userId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(userActions.upsertOne(response.data.user))
      return true
    }

    return false
  }

export const removeSpaceAdministrator =
  (spaceId: number, userId: number): RequestThunk<typeof spaceAPI.removeSpaceAdministrator> =>
  async dispatch => {
    const request = spaceAPI.removeSpaceAdministrator(spaceId, userId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(userActions.upsertOne(response.data.user))
    }
    return response
  }

export const updateRoleInSpace =
  (spaceId: number, userId: number, role: string): Thunk<boolean> =>
  async dispatch => {
    const request = spaceAPI.updateRoleInSpace(spaceId, userId, role)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(userActions.upsertOne(response.data.user))
      return true
    }

    return false
  }

export const deleteSpace =
  (spaceId: number, onSuccess: () => void | Promise<void>): Thunk<boolean> =>
  async dispatch => {
    const request = spaceAPI.deleteSpace(spaceId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (!response.ok) {
      return false
    }

    await dispatch(spaceDeleted(spaceId))
    await onSuccess()

    return true
  }

// When a space is deleted all kinds of things get weird. Put all of those things in this (messy)
// function
export const spaceDeleted =
  (spaceId: number): SyncThunk<void> =>
  (dispatch, getState) => {
    const focusedSpaceId = appProfileSelectors.focusedSpaceId(getState())
    if (focusedSpaceId === spaceId) {
      const spaces = spaceSelectors.all(getState())
      const remaining = spaces.filter(space => space.id !== spaceId)
      const nextSpace = remaining.length === 0 ? undefined : remaining[0]
      if (nextSpace) {
        dispatch(appProfileActions.focusOnSpace({ spaceId: nextSpace.id }))
      }
    }

    batch(() => {
      dispatch(spaceActions.removeOne(spaceId))
      dispatch(removeSpaceEntities(spaceId))
    })
  }

// Given the new set of spaces, delete any old spaces that aren't included now.
const setSpaces =
  (spaces: Space[]): SyncThunk<void> =>
  (dispatch, getState) => {
    const currentSpaces = spaceSelectors.all(getState())
    const nextSpaces = new Set(spaces.map(space => space.id))

    dispatch(spaceActions.upsertMany(spaces))

    batch(() => {
      currentSpaces.forEach(async space => {
        if (!nextSpaces.has(space.id)) {
          await dispatch(spaceDeleted(space.id))
        }
      })
    })
  }

const removeSpaceEntities =
  (spaceId: number): SyncThunk<void> =>
  dispatch => {
    const predicate = (entity: SpaceEntity) => entity.spaceId === spaceId

    dispatch(channelActions.removeWhere({ predicate }))
    dispatch(channelStatActions.removeWhere({ predicate }))
    dispatch(channelUserActions.removeWhere({ predicate }))
    dispatch(messageActions.removeWhere({ predicate }))
    dispatch(phoneNumberActions.removeWhere({ predicate }))
    dispatch(boardActions.removeWhere({ predicate }))
    dispatch(slackConnectionActions.removeWhere({ predicate }))
    dispatch(tagActions.removeWhere({ predicate }))
    dispatch(taskActions.removeWhere({ predicate }))
    dispatch(taskListItemActions.removeWhere({ predicate }))
    dispatch(threadActions.removeWhere({ predicate }))
    dispatch(userActions.removeWhere({ predicate }))
  }

export const getDefaultSpace =
  (fallbackSpaceId: number | null = null): Thunk<Space | null> =>
  async (dispatch, getState) => {
    if (fallbackSpaceId) {
      const fallbackSpace = spaceSelectors.byId(getState(), fallbackSpaceId)
      if (fallbackSpace) {
        return fallbackSpace
      }
    }

    const firstSpace = spaceSelectors.first(getState())

    if (firstSpace) {
      return firstSpace
    }

    const response = await dispatch(getSpacesList())

    if (response.ok) {
      return response.data.spaces[0] || null
    }

    return null
  }
