import React from 'react'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { ColorName } from '@/core/constants/colors'
import { taskUtils } from '@/core/utils'
import { Task } from '@/types/entities'
import { TaskDetail } from '@/types/tasks'
import MyDayIndicator from './MyDayIndicator'
import TaskDetailListItem from './TaskDetailListItem'

interface TaskDetailsListProps {
  task: Task
  maxItems?: number
  showAssignee?: boolean
  color?: ColorName
  showMyDay?: boolean
}

const details: TaskDetail[] = [
  'HIGH_PRIORITY',
  'LOW_PRIORITY',
  'NOTES',
  'DUE_DATE',
  'RECURRENCE',
  'REMINDER',
  'BOARD',
  'TAGS',
  'CHECKLIST_ITEMS',
  'ATTACHMENTS',
]
const size = 16

function TaskDetailsList({
  task,
  maxItems = 3,
  showAssignee,
  color = 'koala',
  showMyDay = false,
}: TaskDetailsListProps) {
  const [activeDetails, hasMore] = React.useMemo(() => {
    const active = details.filter(detail => taskUtils.isDetailActive(detail, task))
    return [active.slice(0, maxItems), active.length > maxItems]
  }, [task, maxItems])

  return (
    <Box alignItems="center" direction="row" gapTop="small">
      {showAssignee ? (
        <Box gapRight="small">
          <UserProfilePicture size="medium" spaceId={task.spaceId} userId={task.assignedUserId} />
        </Box>
      ) : null}
      {showMyDay ? <MyDayIndicator color={color} size={size} task={task} /> : null}
      {activeDetails.map(detail => (
        <Box key={detail} gapRight="small">
          <TaskDetailListItem color={color} size={size} task={task} taskDetail={detail} />
        </Box>
      ))}
      {hasMore ? <Icon color={color} kind="solid" name="ellipsis-h" size={size} /> : null}
    </Box>
  )
}

export default React.memo(TaskDetailsList)
