import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import { dateUtils as D, taskUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import { useAppDispatch } from '@/hooks'
import { useCheckNotify } from '@/hooks/tasks'
import { taskThunks } from '@/thunks'
import { Task } from '@/types/entities'

interface TaskAcceptButtonProps {
  task: Task
  disabled?: boolean
  size?: 'medium' | 'large'
}

function TaskAcceptButton({ task, disabled, size = 'medium' }: TaskAcceptButtonProps) {
  const dispatch = useAppDispatch()
  const checkNotify = useCheckNotify()
  const isAccepted = taskUtils.isAccepted(task)
  const handleAcceptTask = React.useCallback(async () => {
    if (disabled || isAccepted) {
      return
    }
    const notify = await checkNotify({ changes: { acceptedAt: D.nowISO() }, task })
    easeInOut()
    dispatch(taskThunks.acceptTask(task.spaceId, task.id, notify))
  }, [dispatch, task, disabled, isAccepted, checkNotify])
  const iconSize = size === 'medium' ? 12 : 20
  const iconPadding = size === 'medium' ? 6 : 8

  if (isAccepted) {
    return (
      <BaseIconButton
        amplitudeEventName={isAccepted ? undefined : 'accept task'}
        backgroundColor="transparent"
        borderColor="emmre-green"
        borderWidth={1}
        color="emmre-green"
        disabled={disabled}
        kind="solid"
        name="thumbs-up"
        onPress={handleAcceptTask}
        padding={iconPadding}
        shape="circle"
        size={iconSize}
      />
    )
  }

  return (
    <BaseIconButton
      backgroundColor="transparent"
      borderColor="koala"
      borderStyle="dashed"
      borderWidth={1}
      color="koala"
      disabled={disabled}
      kind="light"
      name="thumbs-up"
      onPress={handleAcceptTask}
      padding={iconPadding}
      shape="circle"
      size={iconSize}
    />
  )
}

export default React.memo(TaskAcceptButton)
