import React from 'react'
import TextInput, { RefType, TextInputProps } from '@/components/core/TextInput'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { formActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'

export interface FormTextInputProps extends Omit<TextInputProps, 'value' | 'error'> {
  formName: string
  fieldName: string
}

const FormTextInput = React.forwardRef(
  ({ formName, fieldName, onChangeText, ...textInputProps }: FormTextInputProps, ref: RefType) => {
    const dispatch = useAppDispatch()
    const field = useAppSelector(formSelectors.field, formName, fieldName)
    const handleChangeText = React.useCallback(
      value => {
        dispatch(formActions.setValue({ fieldName, formName, value }))
        if (onChangeText) {
          onChangeText(value)
        }
      },
      [fieldName, formName, dispatch, onChangeText],
    )

    const value = field.value === null ? '' : field.value.toString()

    return (
      <TextInput
        {...textInputProps}
        ref={ref}
        error={field.error}
        onChangeText={handleChangeText}
        value={value}
      />
    )
  },
)

export default React.memo(FormTextInput)

export type { RefType }
