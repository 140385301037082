import { httpStatus } from '@/core/constants'
import { ErrorResponse } from '@/types/api'

export const appJSON = 'application/json'
export const commonHeaders = { Accept: appJSON }

export const notAuthenticatedResponse: ErrorResponse = {
  errors: {},
  ok: false,
  rawBody: '',
  status: httpStatus.NOT_AUTHENTICATED,
  type: 'ERROR',
}

export const requestExceptionResponse: ErrorResponse = {
  errors: {},
  ok: false,
  rawBody: '',
  status: httpStatus.REQUEST_EXCEPTION,
  type: 'ERROR',
}
