import React from 'react'
import { InteractionManager } from 'react-native'
import AlertContext from '@/contexts/AlertContext'
import { platformUtils } from '@/core/utils'
import { rnAlert } from '@/core/utils/actions'
import { AlertButton } from '@/types/actions'
import AlertModal from './AlertModal'
import { AlertArgs, CloseHandler } from './types'

interface AlertContainerProps {
  children: React.ReactNode
}

function AlertContainer({ children }: AlertContainerProps) {
  const [alertArgs, setAlertArgs] = React.useState<AlertArgs | null>(null)
  const showAlert = React.useCallback(
    (title: string, message: string | undefined, buttons: AlertButton[]) => {
      if (platformUtils.isIOS) {
        rnAlert(title, message, buttons)
      } else {
        setAlertArgs({ buttons, message, title })
      }
    },
    [setAlertArgs],
  )
  const handleOnClose = React.useCallback<CloseHandler>(
    callback => {
      setAlertArgs(null)
      // If the alert triggers another alert, we need to wait for it to close before opening
      // another one. This runAfterInteractions accomplishes that.
      if (callback) {
        InteractionManager.runAfterInteractions(callback)
      }
    },
    [setAlertArgs],
  )

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alertArgs ? <AlertModal alertArgs={alertArgs} onClose={handleOnClose} /> : null}
    </AlertContext.Provider>
  )
}

export default React.memo(AlertContainer)
