import React from 'react'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { useAppDispatch } from '@/hooks'
import { messageSelectors, taskSelectors } from '@/store/selectors'
import { entityThunks } from '@/thunks'

export const useShowMessageThread = () => {
  const dispatch = useAppDispatch()
  const modalPresenter = useModalPresenterContext()
  const { showTaskForm } = useShowTaskFormContext()

  return React.useCallback(
    (spaceId: number, messageId: number) => {
      const message = dispatch(entityThunks.select(messageSelectors.byId, spaceId, messageId))

      if (!message) {
        return
      }

      if (!message.threadId) {
        modalPresenter.push('openThread', {
          messageId: message.id,
          spaceId: message.spaceId,
        })
      } else {
        const parentTask = dispatch(
          entityThunks.select(taskSelectors.byId, message.spaceId, message.threadId),
        )

        if (parentTask) {
          showTaskForm({
            defaultSpaceId: parentTask.spaceId,
            formContext: 'messages',
            initialTaskFormBody: 'COMMENTS',
            taskId: parentTask.id,
          })
        } else {
          modalPresenter.push('openThread', {
            messageId: message.threadId,
            spaceId: message.spaceId,
          })
        }
      }
    },
    [dispatch, modalPresenter, showTaskForm],
  )
}
