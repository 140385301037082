import React from 'react'
import { ActionableFlatList, RenderActionableItem } from '@/components/core/ActionableList'
import ActionsButton from '@/components/core/ActionsButton'
import Icon from '@/components/core/Icon'
import SelectListItem from '@/components/core/SelectListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { fnUtils } from '@/core/utils'
import { usePromptAsync } from '@/hooks/prompts'
import { Board } from '@/types/entities'
import { CreateSegmentAction, CreateSegmentItem } from '../TaskSegmentModal'

interface BoardsListProps {
  createBoardAction: CreateSegmentAction
  onDelete: (board: Board) => void
  onEdit: (board: Board) => void
  onSelect: (board: Board, isSelected: boolean) => void
  boards: Board[]
  selectedBoardId: number | null
  createBoardLabel: string
}

const getKey = (board: Board) => board.id.toString()
const actionArgs = { title: 'Project Actions' }

function BoardsList({
  createBoardAction,
  onDelete,
  onEdit,
  onSelect,
  boards,
  selectedBoardId,
  createBoardLabel,
}: BoardsListProps) {
  const showPrompt = usePromptAsync()
  const handleEdit = React.useCallback(
    async (editBoard: Board) => {
      const newName = await showPrompt({
        initialValue: editBoard.name,
        submitText: 'Update',
        title: 'Enter New Project Name',
      })
      if (newName) {
        await onEdit({ ...editBoard, name: newName })
      }
    },
    [showPrompt, onEdit],
  )
  const handleSelect = React.useCallback(
    (board: Board) => onSelect(board, board.id !== selectedBoardId),
    [onSelect, selectedBoardId],
  )

  const renderItem = React.useCallback<RenderActionableItem<Board>>(
    ({ item: board, actions }) => (
      <SelectListItem isSelected={board.id === selectedBoardId} swipeable>
        <Icon name="th-list" size="h4" />
        <Box flex={1} gapLeft="medium">
          <Text color="shadow">{board.name}</Text>
        </Box>
        <ActionsButton actionArgs={actionArgs} actions={actions} item={board} webOnly />
      </SelectListItem>
    ),
    [selectedBoardId],
  )

  return (
    <ActionableFlatList
      ListFooterComponent={
        createBoardAction.canCreate ? (
          <CreateSegmentItem createSegmentAction={createBoardAction} label={createBoardLabel} />
        ) : null
      }
      actions={React.useMemo(
        () => [
          { handler: handleEdit, icon: 'pencil', label: 'Edit' },
          { handler: onDelete, icon: 'trash-alt', label: 'Delete', type: 'destructive' },
          { handler: fnUtils.noOp, icon: 'xmark', label: 'Cancel', type: 'cancel' },
        ],
        [onDelete, handleEdit],
      )}
      data={boards}
      extraData={renderItem}
      keyExtractor={getKey}
      keyboardShouldPersistTaps="handled"
      onItemPress={handleSelect}
      renderItem={renderItem}
    />
  )
}

export default React.memo(BoardsList)
