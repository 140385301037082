import * as ImagePicker from 'expo-image-picker'
import * as MediaLibrary from 'expo-media-library'
import { rnAlert, showNoActionAlert } from './actions'

export const requestMediaLibraryPermissions = async (
  actionDescription: string,
  canAlertDenied: boolean,
): Promise<boolean> => {
  let granted: boolean
  let shouldAlertDenied = false

  const existingPermission = await MediaLibrary.getPermissionsAsync()

  switch (existingPermission.status) {
    case 'undetermined': {
      const requestPermission = await MediaLibrary.requestPermissionsAsync()
      granted = requestPermission.granted
      break
    }
    case 'denied':
      granted = false
      shouldAlertDenied = true
      break
    case 'granted':
      granted = true
      break
    default:
      throw new Error(`Unhandled permission status: ${existingPermission.status}`)
  }

  if (shouldAlertDenied && canAlertDenied) {
    showNoActionAlert(
      rnAlert,
      'Media Library Permissions Required',
      "It looks like you denied Emmre media library permissions. We'll need those to " +
        `${actionDescription}. You will have to go to your device's settings to enable those ` +
        'permissions',
    )
  }

  return granted
}

export const requestCameraPermissions = async (
  actionDescription: string,
  canAlertDenied: boolean,
): Promise<boolean> => {
  let granted: boolean
  let shouldAlertDenied = false

  const existingPermission = await ImagePicker.getCameraPermissionsAsync()

  switch (existingPermission.status) {
    case 'undetermined': {
      const requestPermission = await ImagePicker.requestCameraPermissionsAsync()
      granted = requestPermission.granted
      break
    }
    case 'denied':
      granted = false
      shouldAlertDenied = true
      break
    case 'granted':
      granted = true
      break
    default:
      throw new Error(`Unhandled permission status: ${existingPermission.status}`)
  }

  if (shouldAlertDenied && canAlertDenied) {
    showNoActionAlert(
      rnAlert,
      'Camera Permissions Required',
      "It looks like you denied Emmre camera permissions. We'll need those to " +
        `${actionDescription}. You will have to go to your device's settings to enable those  ` +
        'permissions',
    )
  }

  return granted
}
