import React from 'react'
import { ScrollView } from 'react-native'
import Button from '@/components/core/Button'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import TaskChecklistItemsList from '@/components/tasks/TaskForm/TaskChecklistItemsList'
import { useChecklist } from './hooks'

interface TaskChecklistModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskChecklistModal({ isOpen, onClose, localModal = false }: TaskChecklistModalProps) {
  const {
    scrollViewRef,
    items,
    handleAddItem,
    handleRemoveItem,
    handleUpdateItem,
    handleCancel,
    handleDone,
  } = useChecklist(onClose, isOpen)

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Checklist"
    >
      <Box direction="column" flex={1}>
        <ScrollView ref={scrollViewRef} keyboardShouldPersistTaps="handled">
          <Box gapHorizontal="medium">
            <TaskChecklistItemsList
              canCompleteItem
              canDeleteItem
              canEditItem
              items={items}
              onDeleteItem={handleRemoveItem}
              onEditItem={handleUpdateItem}
              onSubmitEditing={handleAddItem}
            />
          </Box>
        </ScrollView>
        <Box gapAll="medium">
          <Button kind="primary" label="Add Item" onPress={handleAddItem} />
        </Box>
      </Box>
    </StandardModal>
  )
}

export default React.memo(TaskChecklistModal)
