import React from 'react'
import { StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import ActionsMenu from '@/components/core/ActionsMenu'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import MessageListItemBody from '@/components/messages/MessagesList/MessageListItem/MessageListItemBody'
import ReactionMenu from '@/components/messages/ReactionMenu'
import { useCurrentUserId } from '@/hooks'
import { useMessageActions } from '@/hooks/messages/actions'
import { MessageListItemData } from '@/types/messaging'

interface MessageActionsModalProps {
  item: MessageListItemData
  onClose: () => void
}

const gap = 16

function MessageActionsModal({ item, onClose }: MessageActionsModalProps) {
  const isDisplayedInThread = false
  const myId = useCurrentUserId()
  const isFromMe = item.message.creatorId === myId
  const { messageActions } = useMessageActions({
    isDisplayedInThread,
    onAction: onClose,
    showCancel: false,
  })
  const { top, bottom } = useSafeAreaInsets()

  return (
    <Touchable feedback="none" onPress={onClose} style={styles.container}>
      <Box
        direction="row"
        gapTop={Math.max(top, gap)}
        justifyContent={isFromMe ? 'flex-end' : 'flex-start'}
      >
        <ReactionMenu message={item.message} onAction={onClose} />
      </Box>
      <Box gapTop={gap}>
        <MessageListItemBody
          disabled
          isDisplayedInThread={isDisplayedInThread}
          isFromMe={isFromMe}
          item={item}
        />
      </Box>
      <Box
        direction="row"
        gapBottom={Math.max(bottom, gap)}
        gapTop={gap}
        justifyContent={isFromMe ? 'flex-end' : 'flex-start'}
      >
        <ActionsMenu minWidth="65%" options={messageActions} subject={item.message} />
      </Box>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    paddingLeft: gap,
    paddingRight: gap,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default React.memo(MessageActionsModal)
