import React from 'react'
import Box from '@/components/layouts/Box'
import { InputMode } from '@/types/messaging'
import MQTToggleButton from './MQTToggleButton'

interface MQTToggleProps {
  inputMode: InputMode
  onSelectInputMode: (mode: InputMode) => void
}

function MQTToggle({ inputMode, onSelectInputMode }: MQTToggleProps) {
  return (
    <Box alignItems="flex-start" direction="row">
      <MQTToggleButton
        inputMode="message"
        isSelected={inputMode === 'message'}
        onSelect={onSelectInputMode}
      />
      <Box gapLeft="large">
        <MQTToggleButton
          inputMode="task"
          isSelected={inputMode === 'task'}
          onSelect={onSelectInputMode}
        />
      </Box>
      <Box gapLeft="large">
        <MQTToggleButton
          inputMode="question"
          isSelected={inputMode === 'question'}
          onSelect={onSelectInputMode}
        />
      </Box>
    </Box>
  )
}

export default React.memo(MQTToggle)
