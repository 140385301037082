import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { CatchUpResponse, catchUp as catchUpAPI } from '@/api/requests/catchUp'
import { log } from '@/core/logging'
import { collectionUtils as C, dateUtils as D, stringUtils as S, fileUtils } from '@/core/utils'
import { formatISO } from '@/core/utils/dates'
import {
  boardActions,
  channelActions,
  channelStatActions,
  channelUserActions,
  summaryActions,
  tagActions,
  userActions,
} from '@/store/actions'
import { SpaceEntity } from '@/types/entities'
import { AppDispatch, Thunk } from '@/types/store'
import { setBadge } from './notifications'
import { APIStatusResult, callWithStatus, makeEnhancedRequest } from './utils'

const ALL_ENTITIES = [
  'boards',
  'channel_stats',
  'channels',
  'channels_users',
  'summaries',
  'tags',
  'users',
]

export const catchUpRecent =
  (spaceId: number, entities = ALL_ENTITIES): Thunk<boolean> =>
  async dispatch => {
    const entitiesKey = C.sort(S.sortAscending, entities).join('&')
    const endpointId = `spaces/${spaceId}/catchUp/${entitiesKey}`

    const result = await dispatch(
      callWithStatus(
        endpointId,
        timestamp => catchUpAll(spaceId, entities, timestamp ? formatISO(timestamp) : null),
        D.toMilliseconds.days(6),
      ),
    )
    return result
  }

const MAX_PAGES = 10

export const catchUpAll =
  (spaceId: number, entities: string[], updatedSince: string | null): Thunk<APIStatusResult> =>
  async dispatch => {
    let page = 1
    let nextEntities = entities

    while (nextEntities.length !== 0) {
      const response = await dispatch(catchUpPage(spaceId, nextEntities, updatedSince, page))

      if (!response.ok) {
        return { success: false }
      }

      if (updatedSince || page > 1) {
        mergeEntities(dispatch, response.data)
      } else {
        setEntities(dispatch, spaceId, response.data)
      }

      nextEntities = getNextEntities(response.data)
      page += 1

      if (page > MAX_PAGES) {
        log('warning', 'max page number for getAllPages exceeded')
        break
      }
    }

    return { success: true }
  }

const catchUpPage =
  (
    spaceId: number,
    entities: string[],
    updatedSince: string | null,
    page: number,
  ): RequestThunk<typeof catchUpAPI> =>
  async dispatch => {
    const request = catchUpAPI(spaceId, entities, updatedSince, {
      page,
      perPage: 100,
    })
    const response = await dispatch(makeEnhancedRequest(request))
    return response
  }

const getNextEntities = (response: CatchUpResponse) =>
  Object.entries(response)
    .filter(pair => pair[1]?.hasMore)
    .map(([key]) => key)

const setEntities = (dispatch: AppDispatch, spaceId: number, response: CatchUpResponse) => {
  const predicate = (entity: SpaceEntity) => entity.spaceId === spaceId

  batch(() => {
    if (response.boards) {
      dispatch(
        boardActions.replaceWhere({
          entities: response.boards.data,
          predicate,
        }),
      )
    }
    if (response.channels) {
      dispatch(
        channelActions.replaceWhere({
          entities: response.channels.data,
          predicate,
        }),
      )
    }
    if (response.channelStats) {
      dispatch(
        channelStatActions.replaceWhere({
          entities: response.channelStats.data,
          predicate,
        }),
      )
    }
    if (response.channelsUsers) {
      dispatch(
        channelUserActions.replaceWhere({
          entities: response.channelsUsers.data,
          predicate,
        }),
      )
      dispatch(setBadge())
    }
    if (response.summaries) {
      dispatch(summaryActions.upsertMany(response.summaries.data))
    }
    if (response.tags) {
      dispatch(
        tagActions.replaceWhere({
          entities: response.tags.data,
          predicate,
        }),
      )
    }
    if (response.users) {
      const users = response.users.data

      dispatch(
        userActions.replaceWhere({
          entities: users,
          predicate,
        }),
      )
      fileUtils.cacheFiles(
        users
          .filter(user => !!user.profilePictureUrl)
          .map(user => user.profilePictureUrl as string),
        'long',
      )
    }
  })
}

const mergeEntities = (dispatch: AppDispatch, response: CatchUpResponse) => {
  batch(() => {
    if (response.boards) {
      dispatch(boardActions.upsertMany(response.boards.data))
    }
    if (response.channels) {
      dispatch(channelActions.upsertMany(response.channels.data))
    }
    if (response.channelStats) {
      dispatch(channelStatActions.upsertMany(response.channelStats.data))
    }
    if (response.channelsUsers) {
      dispatch(channelUserActions.upsertMany(response.channelsUsers.data))
      dispatch(setBadge())
    }
    if (response.summaries) {
      dispatch(summaryActions.upsertMany(response.summaries.data))
    }
    if (response.tags) {
      dispatch(tagActions.upsertMany(response.tags.data))
    }
    if (response.users) {
      dispatch(userActions.upsertMany(response.users.data))
    }
  })
}
