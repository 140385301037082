import { Scope } from '@/types/entities'

export const scopeMatches = (
  scope: Scope,
  subject: string,
  action: string,
  subjectId: string | null,
): boolean =>
  (scope.subject === '*' || scope.subject === subject) &&
  (scope.action === '*' || scope.action === action) &&
  (!subjectId || scope.subjectId === '*' || scope.subjectId === subjectId)

export const scopeMatcher =
  (subject: string, action: string, subjectId: string | null) => (scope: Scope) =>
    scopeMatches(scope, subject, action, subjectId)
