import React from 'react'
import { CreateSummaryItem, UpdateSummary, UpdateSummaryItem } from '@/api/requests/summaries'
import { easeInOut } from '@/core/utils/animations'
import { includeList, mergeFilters } from '@/core/utils/tasks/filtering'
import { buildChannelTasksFilter } from '@/core/utils/tasks/filters'
import { useAppDispatch } from '@/hooks'
import { useActionsAsync } from '@/hooks/actions'
import { useSummaryTaskIds } from '@/hooks/summaries'
import { summaryItemSelectors } from '@/store/selectors'
import { summaryThunks } from '@/thunks'
import { select } from '@/thunks/entities'
import { ActionOptionAsync } from '@/types/actions'
import { Summary, SummaryItem } from '@/types/entities'
import { SelectTasks, TaskType } from '@/types/tasks'
import { EditSummaryContextType } from '../EditSummaryContext'
import { AddItemArgs, EditItemId, PromptAddItemType, SelectTasksArgs } from '../types'

const addItemArgs = { title: 'Add Item to Executive Brief...' }
const addItemOptions: ActionOptionAsync<PromptAddItemType, null>[] = [
  { label: 'Header', value: 'HEADER' },
  { label: 'Message', value: 'MESSAGE' },
  { label: 'Task', value: 'TASK' },
  { label: 'Question', value: 'QUESTION' },
  { label: 'Cancel', type: 'cancel', value: 'CANCEL' },
]

export const useEditSummaryContextValue = (
  summary: Summary,
  onSelectTasks: SelectTasks,
): EditSummaryContextType => {
  const { spaceId, channelId, id: summaryId } = summary
  const [editingItemId, setEditingItemId] = React.useState<EditItemId | null>(null)
  const [editingItemIndex, setEditingItemIndex] = React.useState<number | null>(null)
  const dispatch = useAppDispatch()
  const showAddItemOptions = useActionsAsync(addItemOptions, addItemArgs)
  const includedTaskIds = useSummaryTaskIds(summary)

  const updateSummary = React.useCallback(
    async (updates: UpdateSummary) => {
      const response = await dispatch(summaryThunks.updateSummary(spaceId, summaryId, updates))
      return response.ok
    },
    [dispatch, spaceId, summaryId],
  )

  const updateSummaryItem = React.useCallback(
    async (summaryItemId: number, updates: UpdateSummaryItem) => {
      const response = await dispatch(
        summaryThunks.updateSummaryItem(spaceId, summaryItemId, updates),
      )
      return response.ok
    },
    [dispatch, spaceId],
  )

  const deleteSummaryItem = React.useCallback(
    async (summaryItemId: number) => {
      easeInOut()
      const response = await dispatch(summaryThunks.deleteSummaryItem(spaceId, summaryItemId))
      return response.ok
    },
    [dispatch, spaceId],
  )

  const createItem = React.useCallback(
    async (item: CreateSummaryItem) => {
      easeInOut()
      const response = await dispatch(summaryThunks.createSummaryItem(spaceId, summaryId, item))
      if (response.ok) {
        return response.data.summaryItem
      }
      return null
    },
    [dispatch, spaceId, summaryId],
  )

  const selectTasks = React.useCallback(
    ({ taskType, multiple, currentTaskId }: SelectTasksArgs) => {
      const channelFilter = buildChannelTasksFilter({ id: channelId, spaceId })
      const filter = mergeFilters(channelFilter, {
        taskIds: includeList(
          includedTaskIds.filter(([, taskId]) => taskId !== currentTaskId),
          'exclude',
        ),
      })
      return onSelectTasks(filter, taskType, multiple)
    },
    [channelId, spaceId, onSelectTasks, includedTaskIds],
  )

  const addItem = React.useCallback(
    async ({ itemType, beforeItemId, index }: AddItemArgs) => {
      const addType = itemType === 'PROMPT' ? await showAddItemOptions(null) : itemType
      let editCreated: SummaryItem | null = null

      if (addType === 'HEADER') {
        editCreated = await createItem({
          beforeItemId,
          content: 'Ex: "Critical Questions!"',
          itemType: 'HEADER',
        })
      } else if (addType === 'MESSAGE') {
        editCreated = await createItem({
          beforeItemId,
          canAcknowledge: true,
          canComment: true,
          content: 'Write comment / description / note / etc...',
          itemType: 'ITEM',
        })
      } else if (addType === 'TASK' || addType === 'QUESTION') {
        const taskIds = await selectTasks({
          currentTaskId: null,
          multiple: true,
          taskType: addType,
        })

        for (const taskId of taskIds) {
          const created = await createItem({
            beforeItemId,
            canAcknowledge: addType === 'TASK',
            canComment: false,
            itemType: 'ITEM',
            taskId,
          })
          if (created && !editCreated) {
            editCreated = created
          }
        }
      } else if (addType === 'CANCEL') {
        return
      } else {
        throw new Error(`item type not implemented ${addType}`)
      }

      if (editCreated) {
        setEditingItemId(editCreated.id)
        setEditingItemIndex(index)
      }
    },
    [showAddItemOptions, createItem, selectTasks, setEditingItemId],
  )

  const onEditItem = React.useCallback((arg: null | { itemId: EditItemId; index: number }) => {
    if (arg === null) {
      setEditingItemId(null)
      setEditingItemIndex(null)
    } else {
      setEditingItemId(arg.itemId)
      setEditingItemIndex(arg.index)
    }
  }, [])

  const { draggingHeaderId, dragBegin, dragEnd } = useDrag(summary)

  return React.useMemo(
    () => ({
      addItem,
      deleteSummaryItem,
      dragBegin,
      dragEnd,
      draggingHeaderId,
      editingItemId,
      editingItemIndex,
      selectTasks,
      setEditingItemId: onEditItem,
      updateSummary,
      updateSummaryItem,
    }),
    [
      addItem,
      deleteSummaryItem,
      dragBegin,
      dragEnd,
      draggingHeaderId,
      editingItemId,
      editingItemIndex,
      selectTasks,
      onEditItem,
      updateSummary,
      updateSummaryItem,
    ],
  )
}

const ITEM_OFFSET = 2

const useDrag = (summary: Summary) => {
  const { spaceId, id: summaryId } = summary
  const dispatch = useAppDispatch()
  const [draggingHeaderId, setDraggingHeaderId] = React.useState<number | null>(null)

  const dragBegin = React.useCallback(
    (index: number) => {
      const item = dispatch(
        select(summaryItemSelectors.byIndexSelector(spaceId, summaryId, index - ITEM_OFFSET)),
      )

      if (!item) {
        return
      }

      if (item.itemType === 'HEADER') {
        setTimeout(() => setDraggingHeaderId(item.id))
      }
    },
    [dispatch, spaceId, summaryId, setDraggingHeaderId],
  )

  const dragEnd = React.useCallback(
    ({ from: fromRaw, to: toRaw }: { from: number; to: number }) => {
      setDraggingHeaderId(null)

      if (fromRaw === toRaw) {
        return
      }

      // ITEM_OFFSET accounts for fixed items at the beginning (title, greeting)
      const fromItemIndex = fromRaw - ITEM_OFFSET

      // If we're moving an item from higher in the list to lower (from lower index to higher)
      // we have to adjust our index by 1
      const toItemIndex = Math.max(0, toRaw - ITEM_OFFSET + (fromRaw < toRaw ? 1 : 0))

      const items = dispatch(
        select(
          summaryItemSelectors.byIndicesSelector(spaceId, summaryId, [fromItemIndex, toItemIndex]),
        ),
      )

      const moveItem = items[fromItemIndex]
      const toItem = items[toItemIndex]

      if (!moveItem) {
        return // This shouldn't happen
      }

      const beforeItemId = toItem ? toItem.id : null

      dispatch(
        summaryThunks.optimisticallyMoveSummaryItems(spaceId, summaryId, moveItem, beforeItemId),
      )
    },
    [setDraggingHeaderId, spaceId, summaryId, dispatch],
  )

  return {
    dragBegin,
    dragEnd,
    draggingHeaderId,
  }
}
