import { ReceivedTask } from '@/types/apiEntities'
import { Board, Tag, Task, TaskListItem, TaskUrgency, Thread } from '@/types/entities'
import { TaskType } from '@/types/tasks'
import { isQuestion } from './questions'

export {
  getQuestionMode,
  isAnswered,
  responsesAreDifferent,
  responseIsComplete,
  isQuestion,
} from './questions'

export { getCompletedItemsRatio } from './checklistItems'

export { getTaskDefaults } from './creating'
export { isEditDetailActive, isDetailActive } from './details'
export { compileTaskFilter, countIncludedTasks } from './filtering'
export { RelatedTaskData } from './related'
export { compileTaskSorts } from './sorting'
export { isPastDue } from './dates'
export { isGiphyLink } from './giphy'

export {
  isAccepted,
  isActive,
  isArchived,
  isAssignedToUser,
  isCompleted,
  isCreatedByUser,
  isDeleted,
  isMoved,
} from './status'
export {
  canEditTask,
  canCompleteTask,
  canArchiveTask,
  canDeleteTask,
  canAcceptTask,
  canWatchTask,
} from './permissions'

export const getTaskType = (task: Task): TaskType => (isQuestion(task) ? 'QUESTION' : 'TASK')

export const taskUrgencyLabel = (taskUrgency: TaskUrgency): string => {
  if (taskUrgency === 'LOW') {
    return 'Low'
  }
  if (taskUrgency === 'MEDIUM') {
    return 'Medium'
  }
  return 'High'
}

type NormalizedTask = {
  task: Task
  tags?: Tag[]
  board?: Board
  thread?: Thread
  taskListItems?: TaskListItem[]
}

export const normalizeTask = (task: ReceivedTask): NormalizedTask => {
  const { tags, board, thread, taskListItems } = task

  const tagIds = tags ? tags.map(tag => tag.id) : undefined

  const copy = { ...task }

  if (tagIds !== undefined) {
    copy.tagIds = tagIds
  }

  delete copy.tags
  delete copy.board
  delete copy.thread
  delete copy.taskListItems

  return {
    board,
    tags,
    task: copy,
    taskListItems,
    thread,
  }
}

export const copyTask = (task: Task): Task => ({
  ...task,
  acceptedAt: null,
  checklistItems: task.checklistItems
    ? task.checklistItems.map(item => ({ completed: false, description: item.description }))
    : [],
  completedAt: null,
  status: 'ACTIVE',
})
