import React from 'react'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Text from '@/components/core/Text'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import { useCurrentUserId } from '@/hooks'
import { useSummaryItems } from '@/hooks/summaries'
import { Summary } from '@/types/entities'
import EditSummaryButtons from '../EditSummaryButtons'
import ReviewSummaryButtons from './ReviewSummaryButtons'
import ViewSummaryListItem from './ViewSummaryListItem'

interface ViewSummaryViewProps {
  summary: Summary
  onEditSummary: (summary: Summary) => void
  showTitle?: boolean
}

function ViewSummaryView({ summary, onEditSummary, showTitle = false }: ViewSummaryViewProps) {
  const { status, creatorId } = summary
  const { summaryItems } = useSummaryItems(summary)
  const myId = useCurrentUserId() || 0
  const canEdit = creatorId === myId

  return (
    <>
      <KeyboardAwareScrollView extraScrollHeight={50} keyboardShouldPersistTaps="handled">
        <Box gapAll="medium">
          {showTitle ? (
            <Box gapTop="medium">
              <Text size="h2">{summary.title}</Text>
            </Box>
          ) : null}
          {summary.greeting ? (
            <TextWithLinks color="shadow">{summary.greeting}</TextWithLinks>
          ) : null}
          {summaryItems.map(item => (
            <ViewSummaryListItem key={item.id} summaryItem={item} />
          ))}
          {summary.closing ? (
            <Box gapTop="large">
              <TextWithLinks color="shadow">{summary.closing}</TextWithLinks>
            </Box>
          ) : null}
          {status !== 'OPEN' ? <ReviewSummaryButtons summary={summary} /> : null}
          {canEdit ? <Box height={75} /> : null}
        </Box>
      </KeyboardAwareScrollView>
      {canEdit ? <EditSummaryButtons onEditSummary={onEditSummary} summary={summary} /> : null}
    </>
  )
}

export default React.memo(ViewSummaryView)
