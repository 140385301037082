import React from 'react'
import Background from '@/components/core/Background'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import { SummaryItem } from '@/types/entities'
import EditItemHeader from '../EditItemHeader'
import { useEditHeader } from './hooks'

interface EditItemProps {
  summaryItem: SummaryItem
}

function EditItem({ summaryItem }: EditItemProps) {
  const {
    handleKeyPress,
    handleCancel,
    handleUpdate,
    newContent,
    setNewContent,
    newDescription,
    setNewDescription,
  } = useEditHeader(summaryItem)

  return (
    <>
      <EditItemHeader disabled={false} handleCancel={handleCancel} handleUpdate={handleUpdate} />
      <Background borderRadius={8} color="ghost">
        <TextInput
          autoFocus
          blurOnSubmit
          expandable
          multiline
          onChangeText={setNewContent}
          onKeyPress={handleKeyPress}
          onSubmitEditing={handleUpdate}
          selectTextOnFocus
          value={newContent}
        />
      </Background>
      <Box gapTop="medium">
        <Background borderRadius={8} color="ghost">
          <TextInput
            blurOnSubmit
            expandable
            multiline
            onChangeText={setNewDescription}
            onKeyPress={handleKeyPress}
            onSubmitEditing={handleUpdate}
            placeholder="Optional Description..."
            value={newDescription}
          />
        </Background>
      </Box>
    </>
  )
}

export default React.memo(EditItem)
