import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'

interface TaskFormExpandButtonProps {
  isExpanded: boolean
  onToggle: () => void
}

function TaskFormExpandButton({ isExpanded, onToggle }: TaskFormExpandButtonProps) {
  return (
    <BaseIconButton
      backgroundColor="transparent"
      color="anchor"
      hitSlop="medium"
      name={isExpanded ? 'compress-alt' : 'expand-alt'}
      onPress={onToggle}
      padding={0}
      size="h4"
      testID="expand_to_full_task_modal"
    />
  )
}

export default React.memo(TaskFormExpandButton)
