import React from 'react'
import { Keyboard } from 'react-native'
import { platformUtils } from '@/core/utils'

// I prefer the `WillShow` animation but it's not available on Android for expo due to the
// windowSoftInputMode setting
const didShow = 'keyboardDidShow'
const didHide = 'keyboardDidHide'
const willShow = 'keyboardWillShow'
const willHide = 'keyboardWillHide'
const show = platformUtils.isAndroid ? didShow : willShow
const hide = platformUtils.isAndroid ? didHide : willHide

export const useIsKeyboardVisible = () => {
  const [visible, setVisible] = React.useState<boolean>(false)
  const handleShow = React.useCallback(() => setVisible(true), [setVisible])
  const handleHide = React.useCallback(() => setVisible(false), [setVisible])

  React.useEffect(() => {
    const showHandle = Keyboard.addListener(show, handleShow)
    const hideHandle = Keyboard.addListener(hide, handleHide)

    return () => {
      showHandle.remove()
      hideHandle.remove()
    }
  }, [handleShow, handleHide])

  return visible
}

export const useKeyboardVisibilityChange = (callback: (visible: boolean) => void) => {
  const visible = useIsKeyboardVisible()

  React.useEffect(() => {
    callback(visible)
  }, [callback, visible])
}
