import React from 'react'
import Touchable from '../Touchable'
import WebDragView from './WebDragView'
import { DragViewProps } from './types'

export { default as PreventDragView } from './PreventDragView'

function DragView({ children, style, onDragStart, onPress }: DragViewProps) {
  if (onDragStart) {
    return (
      <WebDragView onDragStart={onDragStart} onPress={onPress} style={style}>
        {children}
      </WebDragView>
    )
  }
  return (
    <Touchable feedback="opacity" onPress={onPress} style={style}>
      {children}
    </Touchable>
  )
}

// ts-unused-exports:disable-next-line
export default React.memo(DragView)
