import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { FilterOption } from '@/types/tasks'

interface FilterOptionListItemProps {
  option: FilterOption
  isSelected: boolean
  onSelect: (optionGroup: FilterOption) => void
  isFirst: boolean
}

function FilterOptionListItem({
  option,
  isSelected,
  onSelect,
  isFirst,
}: FilterOptionListItemProps) {
  const handleSelect = React.useCallback(() => onSelect(option), [onSelect, option])
  const count = option?.count || 0

  return (
    <Box gapLeft={isFirst ? 'medium' : 'none'} gapRight="medium">
      <Touchable feedback="opacity" onPress={handleSelect}>
        <Background
          borderColor={isSelected ? 'obsidian' : 'iron'}
          borderRadius={8}
          borderWidth={1}
          color={isSelected ? 'heather' : 'ghost'}
        >
          <Box alignItems="center" direction="row" gapHorizontal="small" gapVertical="xsmall">
            <Text size="h5" weight={isSelected ? 'medium' : 'regular'}>
              {option.label}
            </Text>
            <Box direction="row" gapLeft="small" justifyContent="center" minWidth={25}>
              <Text color="emmre-blue" size="h5">
                {count}
              </Text>
            </Box>
          </Box>
        </Background>
      </Touchable>
    </Box>
  )
}

export default React.memo(FilterOptionListItem)
