import React from 'react'
import RevealContainer from '@/components/animated/RevealContainer'
import Handle from '@/components/core/Handle'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { TaskChannel, TaskFormExpandButton } from '@/components/tasks/TaskForm'

interface TaskInputHeaderProps {
  isExpanded: boolean
  onToggleExpand: () => void
  onShowTaskForm: () => void
}

function TaskInputHeader({ isExpanded, onToggleExpand, onShowTaskForm }: TaskInputHeaderProps) {
  return (
    <Box direction="column">
      <Touchable
        feedback="none"
        hitSlop="small"
        onPress={onToggleExpand}
        testID="expand_to_medium_task_modal"
      >
        <Box direction="row" gapVertical="small" justifyContent="center">
          <Handle color="iron" />
        </Box>
      </Touchable>
      <RevealContainer open={isExpanded}>
        <Box alignItems="center" direction="row" gapHorizontal="medium">
          <Box flex={1}>
            <TaskChannel />
          </Box>
          <TaskFormExpandButton isExpanded={false} onToggle={onShowTaskForm} />
        </Box>
      </RevealContainer>
    </Box>
  )
}

export default React.memo(TaskInputHeader)
