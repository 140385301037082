import React from 'react'
import { TaskDetail } from '@/types/tasks'

export const useTaskModals = () => {
  const [taskDetail, setTaskDetail] = React.useState<TaskDetail | null>(null)
  const showTaskDetail = React.useCallback(
    (value: TaskDetail) => setTaskDetail(value),
    [setTaskDetail],
  )
  const closeTaskDetail = React.useCallback(() => setTaskDetail(null), [setTaskDetail])

  return {
    closeTaskDetail,
    showTaskDetail,
    taskDetail,
  }
}
