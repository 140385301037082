import React from 'react'
import Text from '@/components/core/Text'
import { dateUtils as D } from '@/core/utils'
import { Task } from '@/types/entities'

interface TaskListItemCreatedDateFocusProps {
  task: Task
}

function TaskListItemCreatedDateFocus({ task }: TaskListItemCreatedDateFocusProps) {
  const createdAt = D.parse(task.createdAt)
  return <Text size="h6">{D.formatShort(createdAt, false)}</Text>
}

export default React.memo(TaskListItemCreatedDateFocus)
