import React from 'react'
import { RRule } from 'rrule'
import RecurrenceRuleListItem from './RecurrenceRuleListItem'
import { defaultRRules } from './constants'

interface RecurrenceRulesListProps {
  recurrenceRule: string | null
  onChange: (newRecurrenceRule: string | null) => void
}

function RecurrenceRulesList({ recurrenceRule, onChange }: RecurrenceRulesListProps) {
  const handleSelect = React.useCallback(
    (rrule: RRule | null) => onChange(rrule ? rrule.toString() : null),
    [onChange],
  )
  return (
    <>
      {defaultRRules.map(rrule => (
        <RecurrenceRuleListItem
          key={rrule.key || ''}
          isSelected={rrule.key === recurrenceRule}
          onSelect={handleSelect}
          rrule={rrule.rrule}
        />
      ))}
    </>
  )
}

export default React.memo(RecurrenceRulesList)
