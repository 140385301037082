import React from 'react'
import { ScrollView } from 'react-native'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import { fieldNames, leftIconWidth } from '@/components/tasks/TaskForm/constants'
import { useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import TaskBoardItem from './TaskBoardItem'
import TaskTagItem from './TaskTagItem'

function TaskSegments() {
  const { showDetail } = useTaskFormContext()
  const spaceId: number = useTaskFormValue(fieldNames.spaceId)
  const boardId: number | null = useTaskFormValue(fieldNames.boardId)
  const tagIds: number[] | undefined = useTaskFormValue(fieldNames.tagIds)
  const showBoards = React.useCallback(() => showDetail('BOARD'), [showDetail])
  const showTags = React.useCallback(() => showDetail('TAGS'), [showDetail])

  if (!boardId && (!tagIds || tagIds.length === 0)) {
    return null
  }

  return (
    <Box gapTop="large">
      <ScrollView
        horizontal
        keyboardShouldPersistTaps="handled"
        showsHorizontalScrollIndicator={false}
      >
        <Box gapLeft="large" gapTop={6} width={leftIconWidth + 16}>
          <Icon color="shadow" kind="light" name="bookmark" size="h4" />
        </Box>
        {boardId ? (
          <TaskBoardItem boardId={boardId} onPress={showBoards} spaceId={spaceId} />
        ) : null}
        {tagIds
          ? tagIds.map(tagId => (
              <TaskTagItem
                key={tagId.toString()}
                onPress={showTags}
                spaceId={spaceId}
                tagId={tagId}
              />
            ))
          : null}
      </ScrollView>
    </Box>
  )
}

export default React.memo(TaskSegments)
