import React from 'react'
import { StyleSheet, View } from 'react-native'
import { getColor } from '@/core/constants/colors'

interface ModalOverlayProps {
  children: React.ReactNode
}

function ModalOverlay({ children }: ModalOverlayProps) {
  return <View style={styles.overlay}>{children}</View>
}

const styles = StyleSheet.create({
  overlay: {
    alignItems: 'center',
    backgroundColor: getColor('ModalBG'),
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default React.memo(ModalOverlay)
