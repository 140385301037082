import React from 'react'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { useInviteUser } from '@/hooks/restrictedActions'
import { SpaceUser } from '@/types/entities'

interface InvitePartnerDisabledInputProps {
  inputMode: string
  partner: SpaceUser
}

const InvitePartnerDisabledInput = ({ inputMode, partner }: InvitePartnerDisabledInputProps) => {
  const handleInviteUser = useInviteUser(partner)
  const { firstName } = partner

  const placeholderPromp = (() => {
    switch (inputMode) {
      case 'task':
        return `To assign a task to ${firstName}`
      case 'question':
        return `To ask ${firstName} a question`
      default:
        return `To message ${firstName}`
    }
  })()

  return (
    <Box gapVertical="small">
      <Text color="iron" size="h5">
        {placeholderPromp} invite{' '}
        <Link color="emmre-blue" label="here" onPress={handleInviteUser} size="h5" />.
      </Text>
    </Box>
  )
}

export default React.memo(InvitePartnerDisabledInput)
