import React from 'react'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import { SummaryItem } from '@/types/entities'

interface HeaderItemProps {
  summaryItem: SummaryItem
}

function HeaderItem({ summaryItem }: HeaderItemProps) {
  const { content, description } = summaryItem

  return (
    <Box gapTop="large">
      <TextWithLinks color="shadow" size="h3">
        {content}
      </TextWithLinks>
      {description ? (
        <Box gapTop="small">
          <TextWithLinks color="shadow" size="h5">
            {description}
          </TextWithLinks>
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(HeaderItem)
