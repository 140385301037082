import React from 'react'
import { ColorName } from '@/core/constants/colors'
import { alwaysAsyncWarn, noOpWarn } from '@/core/logging'
import { Task } from '@/types/entities'
import { FilterGroup, FilterOption, TaskDetail, TaskRelationship } from '@/types/tasks'

export type TasksListViewContextType = {
  changeTask: (spaceId: number, taskId: number, changes: Partial<Task>) => Promise<boolean>
  editTask: (task: Task, taskDetail: TaskDetail) => void
  setFilterOption: (group: FilterGroup, option: FilterOption) => void
  taskRelationship: TaskRelationship
  taskBackground: ColorName
  questionBackground: ColorName
}

const warningMessage = 'tasks list view context not present'
const noOp = noOpWarn(warningMessage)

const defaultTasksListViewContextValue: TasksListViewContextType = {
  changeTask: alwaysAsyncWarn(false, warningMessage),
  editTask: noOp,
  questionBackground: 'emmre-purple-9',
  setFilterOption: noOp,
  taskBackground: 'heather',
  taskRelationship: 'MINE',
}

const TasksListViewContext = React.createContext<TasksListViewContextType>(
  defaultTasksListViewContextValue,
)

export const useTasksListViewContext = () => React.useContext(TasksListViewContext)

export default TasksListViewContext
