import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { getMessageWidth } from '@/components/messages/MessagesList/constants'
import { Measurement } from '@/types/components'
import ActionableImage from './ActionableImage'

interface AttributedGiphyImageProps {
  isFromMe: boolean
  uri: string
}

function AttributedGiphyImage({ isFromMe, uri }: AttributedGiphyImageProps) {
  const size = React.useMemo<Measurement>(() => {
    const width = getMessageWidth(isFromMe)
    return { height: width, width }
  }, [isFromMe])
  return (
    <View>
      <ActionableImage duration="short" size={size} uri={uri} />
      <View style={styles.attribution}>
        <Image source={require('assets/giphy-attribution.png')} style={styles.image} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  attribution: {
    backgroundColor: '#fffa',
    bottom: 0,
    padding: 5,
    position: 'absolute',
    right: 0,
  },
  image: {
    height: 11,
    width: 100,
  },
})
export default React.memo(AttributedGiphyImage)
