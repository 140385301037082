import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Button from '@/components/core/Button'
import ListItem from '@/components/core/ListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { CreateSegmentAction } from './types'

interface CreateSegmentItemProps {
  label: string
  createSegmentAction: CreateSegmentAction
}

function CreateSegmentItem({
  label,
  createSegmentAction: { isCreating, onCreate, segmentName },
}: CreateSegmentItemProps) {
  return (
    <ListItem disabled={isCreating} onPress={onCreate}>
      <Box alignItems="center" direction="row" flex={1} justifyContent="space-between">
        <Text color={isCreating ? 'heather' : 'shadow'}>
          {label}:{' '}
          <Text color={isCreating ? 'heather' : 'shadow'} weight="bold">
            {segmentName}
          </Text>
        </Text>
        <Box gapRight="medium">
          {isCreating ? (
            <ActivityIndicator color="obsidian" />
          ) : (
            <Button kind="success" label="Create" onPress={onCreate} size="small" />
          )}
        </Box>
      </Box>
    </ListItem>
  )
}

export default React.memo(CreateSegmentItem)
