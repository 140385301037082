import React from 'react'
import { dateUtils as D } from '@/core/utils'
import { useAppForegrounded } from './appState'

export const useCurrentTimestamp = () => {
  const [currentTimestamp, setCurrentTimestamp] = React.useState(D.now())

  useAppForegrounded(React.useCallback(() => setCurrentTimestamp(D.now()), [setCurrentTimestamp]))

  return currentTimestamp
}
