import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { dateUtils } from '@/core/utils'
import { RootState } from '../index'
import { buildId as buildUserId } from './entities/users'

interface UserState {
  isPresent: boolean
  lastTypingTime: number
}

interface ActivityState {
  users: Record<string, UserState>
}

const initialUserState = () => ({ isPresent: false, lastTypingTime: 0 })
const initialState: ActivityState = { users: {} }

const activitySlice = createSlice({
  initialState,
  name: 'activity',
  /* eslint-disable no-param-reassign */
  reducers: {
    arrived: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload
      if (!(userId in state)) {
        state.users[userId] = initialUserState()
      }
      state.users[userId].isPresent = true
    },
    departed: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload
      if (!(userId in state)) {
        state.users[userId] = initialUserState()
      }
      state.users[userId].isPresent = false
    },
    startTyping: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload
      if (!(userId in state)) {
        state.users[userId] = initialUserState()
      }
      state.users[userId].lastTypingTime = dateUtils.nowMilliseconds()
    },
    stopTyping: (state, action: PayloadAction<{ userId: string }>) => {
      const { userId } = action.payload
      if (!(userId in state)) {
        state.users[userId] = initialUserState()
      }
      state.users[userId].lastTypingTime = 0
    },
  },
  /* eslint-enable */
})

const isUserPresentSelector = (spaceId: number, userId: number) => (state: RootState) => {
  const user = state.activity.users[buildUserId(spaceId, userId)]
  if (!user) {
    return false
  }
  return user.isPresent
}

const isUserTypingSelector =
  (spaceId: number, userId: number, timestamp: number) => (state: RootState) => {
    const user = state.activity.users[buildUserId(spaceId, userId)]
    return user ? timestamp <= user.lastTypingTime : false
  }

const areUsersTypingSelector =
  (spaceId: number, userIds: number[], timestamp: number) => (state: RootState) =>
    userIds.map(userId => {
      const user = state.activity.users[buildUserId(spaceId, userId)]
      return user ? timestamp <= user.lastTypingTime : false
    })

const selectors = {
  areUsersTypingSelector,
  isUserPresentSelector,
  isUserTypingSelector,
}

const { actions, reducer } = activitySlice

export { actions, selectors }
export default reducer
