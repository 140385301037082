import React from 'react'
import { Channel } from '@/types/entities'
import MessageListBeginningIntro from './MessageListBeginningIntro'
import MessageListLoadMoreButton from './MessageListLoadMoreButton'
import MessagesListLoadError from './MessagesListLoadError'

interface MessageListHeaderProps {
  channel: Channel
  hasError: boolean
  hasMore: boolean
  isLoading: boolean
  onLoadMore: () => void
  showIntro: boolean
}

function MessageListHeader({
  channel,
  hasError,
  hasMore,
  isLoading,
  onLoadMore,
  showIntro,
}: MessageListHeaderProps) {
  if (hasError) {
    return <MessagesListLoadError isLoading={isLoading} onLoadMore={onLoadMore} />
  }

  if (hasMore) {
    return (
      <MessageListLoadMoreButton hasMore={hasMore} isLoading={isLoading} onLoadMore={onLoadMore} />
    )
  }

  if (showIntro) {
    return <MessageListBeginningIntro channel={channel} />
  }

  return null
}

export default React.memo(MessageListHeader)
