import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import {
  Board,
  Channel,
  ChannelStats,
  ChannelUser,
  SpaceUser,
  Summary,
  Tag,
} from '@/types/entities'

type CatchUpEntity<T> = {
  data: T[]
  hasMore: boolean
}

export interface CatchUpResponse {
  boards?: CatchUpEntity<Board>
  channels?: CatchUpEntity<Channel>
  channelStats?: CatchUpEntity<ChannelStats>
  channelsUsers?: CatchUpEntity<ChannelUser>
  summaries?: CatchUpEntity<Summary>
  tags?: CatchUpEntity<Tag>
  users?: CatchUpEntity<SpaceUser>
}

export const catchUp = (
  spaceId: number,
  entities: string[],
  updatedSince: string | null,
  paging?: PagingRequest,
) =>
  new Request<CatchUpResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [
      ['updatedSince', updatedSince],
      ['entities', entities],
    ],
    url: `v1/spaces/${spaceId}/catch-up`,
  })
