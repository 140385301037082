import React from 'react'
import { collectionUtils as C } from '@/core/utils'
import { ChecklistItem } from '@/types/entities'
import ChecklistItemItem from './ChecklistItemItem'

interface TaskChecklistItemsListProps {
  canCompleteItem?: boolean
  canDeleteItem?: boolean
  canEditItem?: boolean
  hideCompleted?: boolean
  items: ChecklistItem[]
  onDeleteItem?: (index: number) => void
  onEditItem?: (index: number, item: ChecklistItem) => void
  onSubmitEditing?: (index: number, item: ChecklistItem) => void
}

function TaskChecklistItemsList({
  canCompleteItem = false,
  canDeleteItem = false,
  canEditItem = false,
  hideCompleted = false,
  items,
  onDeleteItem,
  onEditItem,
  onSubmitEditing,
}: TaskChecklistItemsListProps) {
  const filteredItems = hideCompleted ? items.filter(item => !item.completed) : items
  return (
    <>
      {filteredItems.map((item, index) => (
        <ChecklistItemItem
          key={item.id ?? item.clientTemporaryId ?? index}
          canComplete={canCompleteItem && !!onEditItem}
          canDelete={canDeleteItem && !!onDeleteItem}
          canEdit={canEditItem && !!onEditItem}
          index={index}
          isLast={C.isLast(items, index)}
          item={item}
          onDelete={onDeleteItem}
          onEdit={onEditItem}
          onSubmitEditing={onSubmitEditing}
        />
      ))}
    </>
  )
}

export default React.memo(TaskChecklistItemsList)
