import React from 'react'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import SummaryTaskItem from '@/components/summaries/SummaryTaskItem'
import { SummaryItem } from '@/types/entities'
import CommentsList from './CommentsList'
import MessageInteractions from './MessageInteractions'

interface MessageItemProps {
  summaryItem: SummaryItem
}

function MessageItem({ summaryItem }: MessageItemProps) {
  const { content, canAcknowledge, canComment, taskId, spaceId, threadId } = summaryItem

  return (
    <Box gapLeft="large" gapTop="medium">
      {content ? (
        <TextWithLinks color="obsidian" linkColor="emmre-blue">
          {taskId ? '' : '• '}
          {content}
        </TextWithLinks>
      ) : null}
      {taskId ? (
        <Box gapTop={content ? 'medium' : undefined}>
          <SummaryTaskItem canOpen editing={false} spaceId={spaceId} taskId={taskId} />
        </Box>
      ) : null}
      {canAcknowledge || canComment ? <MessageInteractions summaryItem={summaryItem} /> : null}
      {threadId ? <CommentsList summaryItem={summaryItem} /> : null}
    </Box>
  )
}

export default React.memo(MessageItem)
