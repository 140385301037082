import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { questionAPI } from '@/api/requests'
import { channelUserActions } from '@/store/actions'
import { addMessages, addTask } from './store'
import { makeEnhancedRequest } from './utils'

export const answerQuestion =
  (
    spaceId: number,
    questionId: number,
    answer: questionAPI.AnswerQuestion,
  ): RequestThunk<typeof questionAPI.answerQuestion> =>
  async dispatch => {
    const request = questionAPI.answerQuestion(spaceId, questionId, answer)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { task, activityMessages, channelUser } = response.data
      batch(() => {
        dispatch(addTask(task))
        if (activityMessages) {
          dispatch(addMessages(activityMessages))
        }

        if (channelUser) {
          dispatch(channelUserActions.upsertOne(channelUser))
        }
      })
    }

    return response
  }
