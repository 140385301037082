import React from 'react'
import { Modal, ScrollView, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Action, { ActionProps } from '@/components/core/Action'
import LocalModal from '@/components/core/LocalModal'
import Text from '@/components/core/Text'
import { getColor } from '@/core/constants/colors'
import { typeUtils } from '@/core/utils'
import Box from './Box'
import CenteredLayout from './CenteredLayout'
import ContentContainer from './ContentContainer'

interface StandardModalProps {
  animationType?: 'none' | 'slide' | 'fade'
  children: React.ReactNode
  depth?: number
  isOpen: boolean
  leftAction?: ActionProps
  localModal?: boolean
  rightAction?: ActionProps
  minHeight?: number
  title: string | React.ReactNode
  width?: number
  onRequestClose?: () => void
}

const defaultMinHeight = 300
const defaultWidth = 400

function StandardModal({
  animationType = 'fade',
  children,
  depth = 1,
  isOpen,
  leftAction,
  localModal = false,
  rightAction,
  minHeight = defaultMinHeight,
  title,
  width = defaultWidth,
  onRequestClose,
}: StandardModalProps) {
  const { top } = useSafeAreaInsets()

  const content = (() => {
    if (!isOpen) {
      return null
    }
    return children
  })()

  const header = (
    <CenteredLayout
      centerContent={typeUtils.isString(title) ? <Text>{title}</Text> : title}
      leftContent={leftAction ? <Action {...leftAction} /> : null}
      rightContent={rightAction ? <Action {...rightAction} /> : null}
    />
  )

  if (localModal) {
    // In order for local modals to work right on web we have to wrap the header and the body in
    // a scroll view
    return (
      <LocalModal onRequestClose={onRequestClose} visible={isOpen}>
        <ContentContainer
          avoidKeyboard
          backgroundStyle={[styles.backgroundLocal]}
          context="MODAL"
          foregroundStyle={[styles.foreground, styles.foregroundLocal, { width }]}
        >
          <ScrollView keyboardShouldPersistTaps="handled">
            {header}
            {content}
            <Box height={10} />
          </ScrollView>
        </ContentContainer>
      </LocalModal>
    )
  }

  return (
    <Modal animationType={animationType} transparent visible={isOpen}>
      <ContentContainer
        avoidKeyboard
        backgroundStyle={[
          styles.background,
          { padding: depth * 10, paddingTop: Math.max(top, depth * 10) },
        ]}
        context="MODAL"
        foregroundStyle={[styles.foreground, { minHeight, width }]}
      >
        {header}
        {content}
      </ContentContainer>
    </Modal>
  )
}

const styles = StyleSheet.create({
  background: {
    alignItems: 'center',
    backgroundColor: '#0004',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backgroundLocal: { maxHeight: '100%', maxWidth: '100%' },
  foreground: {
    backgroundColor: getColor('ghost'),
    borderRadius: 7,
    maxHeight: '100%',
    maxWidth: '100%',
  },
  foregroundLocal: {
    flex: 1,
  },
})
export default React.memo(StandardModal)
