import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import Text from '../Text'
import Touchable from '../Touchable'

export type CellStatus = 'normal' | 'today' | 'selected' | 'otherMonth'

interface CellProps {
  children: string
  value?: any
  status?: CellStatus
  onPress?: (value: any) => void
}

function Cell({ children, value, status = 'normal', onPress }: CellProps) {
  const handlePress = React.useCallback(() => {
    if (onPress) {
      onPress(value)
    }
  }, [onPress, value])

  return (
    <Touchable feedback="highlight" onPress={handlePress} style={styles.container}>
      <View style={styles[status]}>
        <Text color={statusColors[status]}>{children}</Text>
      </View>
    </Touchable>
  )
}

const statusColors: Record<CellStatus, ColorName> = {
  normal: 'obsidian',
  otherMonth: 'iron',
  selected: 'ghost',
  today: 'obsidian',
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  normal: {
    padding: 10,
  },
  otherMonth: {
    padding: 10,
  },
  selected: {
    alignItems: 'center',
    backgroundColor: getColor('obsidian'),
    borderRadius: 5,
    justifyContent: 'center',
    padding: 10,
  },
  today: {
    alignItems: 'center',
    backgroundColor: getColor('heather'),
    borderRadius: 5,
    justifyContent: 'center',
    padding: 10,
  },
})

export default React.memo(Cell)
