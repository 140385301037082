import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { dateUtils as D } from '@/core/utils'

interface ReminderPastWarningProps {
  remindAt: Date | null
}

function ReminderPastWarning({ remindAt }: ReminderPastWarningProps) {
  if (!remindAt) {
    return null
  }
  if (remindAt.getTime() > D.nowMilliseconds()) {
    return null
  }
  return (
    <Box direction="row" gapTop="medium" justifyContent="center">
      <Text color="red" size="h5">
        Invalid Reminder. The date/time has passed.
      </Text>
    </Box>
  )
}

export default React.memo(ReminderPastWarning)
