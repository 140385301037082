import React from 'react'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import TaskFormAttachmentsList from '@/components/tasks/TaskForm/TaskFormAttachmentsList'
import { fieldNames, leftIconWidth } from '@/components/tasks/TaskForm/constants'
import { useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { useTaskFormContext } from '@/contexts/TaskFormContext'

function TaskAttachments() {
  const { task } = useTaskFormContext()
  const attachmentIds = useTaskFormValue(fieldNames.attachmentIds)
  const count = (attachmentIds?.length || 0) + (task?.files?.length || 0)

  if (count === 0) {
    return null
  }

  return (
    <Box alignItems="center" direction="row" gapHorizontal="large" gapTop="large">
      <Box alignSelf="flex-start" width={leftIconWidth}>
        <Icon color="shadow" name="paperclip" size="h4" />
      </Box>
      <Box>
        <TaskFormAttachmentsList canAdd />
      </Box>
    </Box>
  )
}

export default React.memo(TaskAttachments)
