import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Direction } from '@/components/layouts/Box'
import { ColorName, getColor } from '@/core/constants/colors'

type BorderStyle = 'dashed' | 'solid'
type Shadow = 'top' | 'right' | 'bottom' | 'left'

interface BackgroundProps {
  borderBottomColor?: ColorName
  borderBottomLeftRadius?: number
  borderBottomRightRadius?: number
  borderBottomStyle?: BorderStyle
  borderBottomWidth?: number
  borderColor?: ColorName
  borderLeftColor?: ColorName
  borderLeftStyle?: BorderStyle
  borderLeftWidth?: number
  borderRadius?: number
  borderRightColor?: ColorName
  borderRightStyle?: BorderStyle
  borderRightWidth?: number
  borderStyle?: BorderStyle
  borderTopColor?: ColorName
  borderTopLeftRadius?: number
  borderTopRightRadius?: number
  borderTopStyle?: BorderStyle
  borderTopWidth?: number
  borderWidth?: number
  children?: React.ReactNode
  color?: ColorName
  flex?: number
  flexDirection?: Direction
  height?: number | string
  maxHeight?: number | string
  maxWidth?: number | string
  minHeight?: number | string
  minWidth?: number | string
  width?: number | string
  testID?: string
  shadow?: Shadow
  shadowRadius?: number
}

function Background({
  borderBottomColor,
  borderColor,
  borderLeftColor,
  borderRightColor,
  borderTopColor,
  children,
  color,
  flexDirection,
  testID,
  shadow,
  shadowRadius = 3,
  ...otherStyles
}: BackgroundProps) {
  const style = React.useMemo(() => {
    const base = {
      backgroundColor: getColor(color),
      borderBottomColor: getColor(borderBottomColor),
      borderColor: getColor(borderColor),
      borderLeftColor: getColor(borderLeftColor),
      borderRightColor: getColor(borderRightColor),
      borderTopColor: getColor(borderTopColor),
      flexDirection,
      ...otherStyles,
      margin: 0,
      padding: 0,
    }

    return shadow ? { ...base, ...shadowToViewStyle(shadow, shadowRadius) } : base
  }, [
    color,
    borderTopColor,
    borderRightColor,
    borderLeftColor,
    borderBottomColor,
    borderColor,
    flexDirection,
    otherStyles,
    shadow,
    shadowRadius,
  ])

  return (
    <View style={style} testID={testID}>
      {children}
    </View>
  )
}

const shadowToViewStyle = (shadow: Shadow, radius: number): ViewStyle => {
  const base: ViewStyle = {
    elevation: radius,
    shadowColor: '#000',
    shadowOpacity: 0.15,
    shadowRadius: radius,
  }

  if (shadow === 'top') {
    return { ...base, shadowOffset: { height: -radius, width: 0 } }
  }
  if (shadow === 'right') {
    return { ...base, shadowOffset: { height: 0, width: radius } }
  }
  if (shadow === 'bottom') {
    return { ...base, shadowOffset: { height: radius, width: 0 } }
  }
  if (shadow === 'left') {
    return { ...base, shadowOffset: { height: 0, width: -radius } }
  }

  throw new Error(`Unrecognized shadow optino: ${shadow}`)
}

export default React.memo(Background)
