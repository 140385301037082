import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Checkbox from '@/components/core/Checkbox'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { noOp } from '@/core/utils/fns'
import { QuestionResponseType } from '@/types/entities'

interface OpenEndedResponseToggleProps {
  isOpenEnded: boolean
  toggleIsOpenEnded: () => void
  responseType: QuestionResponseType
}

function OpenEndedResponseToggle({
  isOpenEnded,
  toggleIsOpenEnded,
  responseType,
}: OpenEndedResponseToggleProps) {
  return (
    <Box alignItems="center" direction="row" gapBottom="small">
      <Box gapRight="xsmall">
        <BaseIconButton
          color={isOpenEnded ? 'cheddar' : 'iron'}
          kind="solid"
          name={isOpenEnded ? 'circle-x' : 'plus'}
          onPress={toggleIsOpenEnded}
          padding={3}
          size="h4"
        />
      </Box>
      {isOpenEnded ? (
        <Box gapRight="small">
          <Checkbox
            checked={false}
            disabled
            onChange={noOp}
            shape={responseType === 'SINGLE' ? 'circle' : 'square'}
          />
        </Box>
      ) : null}
      <Box flex={1}>
        <Background borderRadius={12} color={isOpenEnded ? 'light-purple' : 'koala'} maxWidth={200}>
          <Box gapAll="small" gapLeft="small">
            <Text color={isOpenEnded ? 'obsidian' : 'anchor'} size="h5">
              Other
            </Text>
          </Box>
        </Background>
      </Box>
    </Box>
  )
}

export default React.memo(OpenEndedResponseToggle)
