import React from 'react'
import SelectListItem from '@/components/core/SelectListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { SpaceUser } from '@/types/entities'
import UserProfilePicture from '../UserProfilePicture'

interface UserListItemProps {
  user: SpaceUser
  selected?: boolean
  onSetSelection?: (user: SpaceUser, selected: boolean) => void
  disabled?: boolean
  isPartition: boolean
}

function UserListItem({
  disabled = false,
  user,
  selected = false,
  onSetSelection,
  isPartition,
}: UserListItemProps) {
  const handleOnPress = () => (onSetSelection ? onSetSelection(user, !selected) : null)

  return (
    <SelectListItem
      borderColor={isPartition ? 'obsidian' : undefined}
      disabled={disabled || !onSetSelection}
      isSelected={selected}
      onPress={handleOnPress}
    >
      <UserProfilePicture user={user} />
      <Box alignItems="center" direction="row" flex={1} gapLeft="medium">
        <Text>
          {user.firstName} {user.lastName}
        </Text>
        {user.membershipType === 'INACTIVE' ? (
          <Text color="red" size="h5">
            &nbsp;(Inactive)
          </Text>
        ) : null}
      </Box>
    </SelectListItem>
  )
}

export default React.memo(UserListItem)
