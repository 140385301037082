import { log } from '@/core/logging'
import { urlUtils } from '@/core/utils'
import { PagingResponse, RequestDescriptor, RequestHeaders } from '@/types/api'
import { Optional, Pair } from '@/types/generics'
import { LogLevel } from '@/types/logging'

export function buildUrl(urlBase: string, request: RequestDescriptor): string {
  const url = mapURL(urlBase, request.url)

  const query: Pair<string, any>[] = request.query || []

  if (request.paging) {
    query.push(['page', request.paging.page || 1], ['perPage', request.paging.perPage || 100])
  }

  if (query.length === 0) {
    return url
  }

  const queryString = urlUtils.toQueryString(query)
  return urlUtils.appendQueryString(url, queryString)
}

const mapURL = (urlBase: string, url: string) => {
  if (url.startsWith('/')) {
    return url
  }

  if (/^https?\/\//.test(url)) {
    return url
  }

  return `${urlBase}/${url}`
}

export function getPaging(headers: Headers): PagingResponse | null {
  const page = getHeaderNumber(headers, 'page')
  const perPage = getHeaderNumber(headers, 'per-page')
  const totalPages = getHeaderNumber(headers, 'total-pages')
  const totalRecords = getHeaderNumber(headers, 'total-records')

  if (page === null || perPage === null || totalPages === null || totalRecords === null) {
    return null
  }

  return {
    page,
    perPage,
    totalPages,
    totalRecords,
  }
}

function getHeaderNumber(headers: Headers, key: string): number | null {
  const strVal = headers.get(key)
  if (strVal === null) {
    return null
  }
  const numVal = parseInt(strVal, 10)
  return Number.isNaN(numVal) ? null : numVal
}

export const logRequestException = ({ method, url }: RequestDescriptor, ex: any) =>
  log('error', `API Call Error: ${ex}`, { context: { method, url } })

type LogRequestErrorArgs = {
  method: string
  url: string
  status: number
  responseBody: string
}

export const logRequestError = ({ method, url, status, responseBody }: LogRequestErrorArgs) => {
  let logLevel: LogLevel
  let message: string

  if (status >= 500) {
    logLevel = 'error'
    message = 'API request failed'
  } else {
    logLevel = 'warning'
    message = 'API request error'
  }

  log(logLevel, `${message}: ${status}`, { context: { method, responseBody, status, url } })
}

type LongRunningRequestArgs = {
  method: string
  url: string
  durationMS: number
}

export const logLongRunningRequest = ({ method, url, durationMS }: LongRunningRequestArgs) => {
  log('warning', 'Long running request', { context: { durationMS, method, url } })
}

export const mergeHeaders = (
  base: Optional<RequestHeaders>,
  extensions: Optional<RequestHeaders>,
): RequestHeaders => {
  if (!base) {
    if (!extensions) {
      return {}
    }
    return { ...extensions }
  }
  if (!extensions) {
    return { ...base }
  }
  return { ...base, ...extensions }
}

export const parseRawHeaders = (headers: string): Headers =>
  new Headers(
    Object.fromEntries(
      headers
        .split('\n')
        .filter(line => !!line)
        .map(line => line.split(':')),
    ),
  )
