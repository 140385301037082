import { Request } from '@/api/call'

export interface SlackResponse {
  url: string
}

export const generateSlackConnectionURL = (spaceId: number) =>
  new Request<SlackResponse>({
    authentication: 'PRIVATE',
    method: 'POST',
    url: `v1/spaces/${spaceId}/integrations/slack`,
  })

export const createChargebeePortalSession = (spaceId: number) =>
  new Request<unknown>({
    authentication: 'PRIVATE',
    method: 'POST',
    url: `v1/spaces/${spaceId}/integrations/chargebee-portal-session`,
  })
