import React from 'react'
import SpaceUserListItem from '@/components/spaces/SpaceUsersList/SpaceUserListItem'
import { Channel } from '@/types/entities'
import { useChannelUsersList } from './hooks'

interface ChannelUsersListProps {
  channel: Channel
  onEditUser: (userId: number) => void
  compact?: boolean
}

function ChannelUsersList({ channel, compact, onEditUser }: ChannelUsersListProps) {
  const { users } = useChannelUsersList(channel)

  return (
    <>
      {users.map(user => (
        <SpaceUserListItem key={user.id} compact={compact} onSelect={onEditUser} user={user} />
      ))}
    </>
  )
}

export default React.memo(ChannelUsersList)
