import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

interface FloatContainerProps {
  children: React.ReactNode
  top?: number
  right?: number
  bottom?: number
  left?: number
  kind?: 'absolute' | 'relative'
  zIndex?: number
  style?: StyleProp<ViewStyle>
}

function FloatContainer({
  children,
  kind = 'absolute',
  top,
  right,
  bottom,
  left,
  zIndex = 1,
  style,
}: FloatContainerProps) {
  const floatStyle = {
    bottom,
    left,
    position: kind,
    right,
    top,
    zIndex,
  }

  return <View style={style ? [style, floatStyle] : floatStyle}>{children}</View>
}

export default React.memo(FloatContainer)
