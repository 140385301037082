import { APIThunk, MappedResponse, RequestDescriptor, RequestHeaders } from '@/types/api'
import { makeRequest } from './makeRequest'
import { mergeHeaders } from './utils'

export class Request<T> {
  descriptor: RequestDescriptor
  response?: MappedResponse<T>

  constructor(descriptor: RequestDescriptor) {
    this.descriptor = descriptor
    this.response = undefined
  }

  mergeHeaders(headers: RequestHeaders): Request<T> {
    this.descriptor = {
      ...this.descriptor,
      headers: mergeHeaders(this.descriptor.headers, headers),
    }
    return this
  }

  reset(): Request<T> {
    this.response = undefined
    return this
  }

  async getResponse(): Promise<MappedResponse<T>> {
    if (this.response === undefined) {
      this.response = await makeRequest(this.descriptor)
    }
    return this.response
  }
}

type ResponseType<S> = S extends Request<infer T> ? T : never

export type RequestThunk<S extends (...args: any) => Request<any>> = APIThunk<
  ResponseType<ReturnType<S>>
>
