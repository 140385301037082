import React from 'react'
import MouseView from '../MouseView'
import { DragViewProps } from './types'

function WebDragView({ children, style, onDragStart, onPress }: DragViewProps) {
  const isMouseDownRef = React.useRef(false)
  const movedRef = React.useRef(false)

  const handleMouseDown = React.useCallback(() => {
    isMouseDownRef.current = true
    movedRef.current = false
  }, [])

  const handleMouseMove = React.useCallback(() => {
    if (!onDragStart) {
      return
    }

    if (!isMouseDownRef.current) {
      return
    }

    if (!movedRef.current) {
      movedRef.current = true
      onDragStart()
    }
  }, [onDragStart])

  const handleMouseUp = React.useCallback(() => {
    isMouseDownRef.current = false
    if (!movedRef.current && onPress) {
      onPress()
    }
  }, [onPress])

  return (
    <MouseView
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={style}
    >
      {children}
    </MouseView>
  )
}

// ts-unused-exports:disable-next-line
export default React.memo(WebDragView)
