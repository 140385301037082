import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { forms } from '@/core/constants'
import { Summary } from '@/types/entities'
import { useCreateSummaryModal } from './hooks'

const formName = forms.CREATE_SUMMARY

interface CreateSummaryModalProps {
  channelId: number
  onClose: () => void
  onSummaryCreated: (summary: Summary) => void
  spaceId: number
}

function CreateSummaryModal({
  onSummaryCreated,
  onClose,
  spaceId,
  channelId,
}: CreateSummaryModalProps) {
  const hooks = useCreateSummaryModal({ channelId, formName, onClose, onSummaryCreated, spaceId })
  const { isCreating, isComplete, handleCreate, hasSummaries } = hooks
  const canCreate = isComplete && !isCreating

  return (
    <ModalLayout
      leftAction={{ label: 'Cancel', onAction: onClose, type: 'linkPrimary' }}
      maxWidth={500}
      onCancel={onClose}
      title="Create Executive Brief"
    >
      <Box gapAll="medium">
        <FormTextInput
          autoFocus
          fieldName="title"
          formName={formName}
          label="Executive Brief Title"
        />
        {hasSummaries === null ? (
          <Box alignItems="center" gapAll="medium" justifyContent="center">
            <ActivityIndicator color="obsidian" size="large" />
          </Box>
        ) : null}
        {hasSummaries === true ? (
          <>
            <Box gapTop="large">
              <Text>Copy from last Executive Brief...</Text>
            </Box>
            <Box gapLeft="large" gapTop="medium">
              <FormCheckbox
                fieldName="copyHeaders"
                formName={formName}
                label="Headers"
                shape="square"
              />
            </Box>
            <Box gapLeft="large" gapTop="medium">
              <FormCheckbox
                fieldName="copyUnacknowledged"
                formName={formName}
                label="Unacknowledged"
                shape="square"
              />
            </Box>
            <Box gapLeft="large" gapTop="medium">
              <FormCheckbox
                fieldName="copyUnansweredQuestions"
                formName={formName}
                label="Unanswered Questions"
                shape="square"
              />
            </Box>
            <Box gapLeft="large" gapTop="medium">
              <FormCheckbox
                fieldName="copyUncompletedTasks"
                formName={formName}
                label="Uncompleted Tasks"
                shape="square"
              />
            </Box>
          </>
        ) : null}
        <Box direction="row" gapTop="large" justifyContent="center">
          <Button
            disabled={!canCreate}
            kind="primary"
            label="Create Executive Brief"
            onPress={handleCreate}
            testID="create_summary_modal_button"
          />
        </Box>
      </Box>
    </ModalLayout>
  )
}

export default React.memo(CreateSummaryModal)
