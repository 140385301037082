import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Text from '@/components/core/Text'
import CenteredLayout from '@/components/layouts/CenteredLayout'
import { dateUtils as D } from '@/core/utils'
import { TaskDueDateType } from '@/types/entities'

interface DueDateIndicatorProps {
  dueDateType: TaskDueDateType
  dueDate: Date | null
  onRemove: () => void
}

function DueDateIndicator({ dueDateType, dueDate, onRemove }: DueDateIndicatorProps) {
  if (dueDateType === 'NONE') {
    return null
  }

  let label: string

  if (dueDateType === 'SOMEDAY') {
    label = 'Someday'
  } else {
    if (!dueDate) {
      // This should never happen
      return null
    }
    label = D.formatShort(dueDate)
  }

  return (
    <Background borderRadius={10} color="heather">
      <CenteredLayout
        centerContent={<Text>{label}</Text>}
        rightContent={
          <BaseIconButton
            backgroundColor="anchor"
            color="heather"
            kind="solid"
            name="xmark"
            onPress={onRemove}
            padding={2}
            shape="circle"
            size="h5"
          />
        }
      />
    </Background>
  )
}

export default React.memo(DueDateIndicator)
