import React from 'react'
import WebSocketManager, {
  CloseHandler,
  ErrorHandler,
  MessageHandler,
  OpenHandler,
} from '@/api/rtm/WebSocketManager'
import { config } from '@/core'
import { eventTypes } from '@/core/constants'
import { log } from '@/core/logging'
import { dateUtils as D } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { useAppForegrounded } from '@/hooks/appState'
import { useCouldBeSignedIn, useHasScope } from '@/hooks/auth'
import { usePublisher } from '@/hooks/pubsub'
import { useTimeout } from '@/hooks/timeout'
import { authThunks, rtmThunks } from '@/thunks'
import type { RTMEvent } from '@/types/rtm'

type ConnectionState = 'ready' | 'connecting' | 'connected' | 'waiting'

const rtmURI = `${config.rtmURLRoot}/rtm`
const rtmReconnect = D.toMilliseconds.seconds(5)

export const useRTM = () => {
  const dispatch = useAppDispatch()
  const publisher = usePublisher()
  const webSocketManagerRef = React.useRef<WebSocketManager>(new WebSocketManager(rtmURI))
  const [connectionStatus, setConnectionStatus] = React.useState<ConnectionState>('ready')
  const { timeout: reconnectTimeout } = useTimeout(rtmReconnect)
  const couldBeSignedIn = useCouldBeSignedIn()
  const hasScope = useHasScope('rtm', 'open')

  const disconnected = React.useCallback(
    (delayReconnect = true) => {
      if (delayReconnect) {
        setConnectionStatus('waiting')
        reconnectTimeout(() => setConnectionStatus('ready'))
      } else {
        setConnectionStatus('ready')
      }
    },
    [setConnectionStatus, reconnectTimeout],
  )

  const handleOpen = React.useCallback<OpenHandler>(async () => {
    log('debug', 'RTM Opened')
    // TODO: What if access token is null?
    const accessToken = await dispatch(authThunks.getAccessToken('IF_NEEDED'))
    webSocketManagerRef.current.send({
      payload: { token: accessToken },
      type: 'authenticate',
    })
    publisher.emit(eventTypes.RTM_CONNECTED, null)
  }, [dispatch, publisher])

  const handleMessage = React.useCallback<MessageHandler>(
    async event => {
      const handled = await dispatch(rtmThunks.messageHandler(event as RTMEvent))

      if (!handled) {
        log('debug', 'RTM Unhandled', { context: event })
      }
    },
    [dispatch],
  )

  const handleError = React.useCallback<ErrorHandler>(arg => {
    log('debug', 'RTM Error', { context: arg })
  }, [])

  const handleClose = React.useCallback<CloseHandler>(
    arg => {
      log('debug', 'RTM Closed', { context: arg })
      publisher.emit(eventTypes.RTM_DISCONNECTED, null)
      disconnected()
    },
    [disconnected, publisher],
  )

  const attachHandlers = React.useCallback(() => {
    const ws = webSocketManagerRef.current

    ws.addEventListener('open', handleOpen)
    ws.addEventListener('message', handleMessage)
    ws.addEventListener('error', handleError)
    ws.addEventListener('close', handleClose)
  }, [handleOpen, handleMessage, handleError, handleClose])

  const detachHandlers = React.useCallback(() => {
    const ws = webSocketManagerRef.current

    ws.removeEventListener('open', handleOpen)
    ws.removeEventListener('message', handleMessage)
    ws.removeEventListener('error', handleError)
    ws.removeEventListener('close', handleClose)
  }, [handleOpen, handleMessage, handleError, handleClose])

  const connect = React.useCallback(async () => {
    if (!couldBeSignedIn) {
      return
    }

    if (!hasScope) {
      return
    }

    if (connectionStatus !== 'ready') {
      return
    }

    const ws = webSocketManagerRef.current

    setConnectionStatus('connecting')

    try {
      await ws.open()
    } catch (ex) {
      log('debug', "Couldn't connect to RTM")
      disconnected()
      return
    }

    setConnectionStatus('connected')
  }, [couldBeSignedIn, connectionStatus, setConnectionStatus, disconnected, hasScope])

  const disconnect = React.useCallback(() => {
    webSocketManagerRef.current.close()
  }, [])

  const ping = React.useCallback(() => {
    const ws = webSocketManagerRef.current
    const status = ws.status()

    if (status === 'OPEN' || status === 'CONNECTING') {
      ws.send({ payload: {}, type: 'ping' })
    } else {
      disconnected(false)
    }
  }, [disconnected])

  // Every time the app is foregrounded ping the websocket to make sure it's still connected
  useAppForegrounded(ping)

  // Attach handlers to the WSM once on mount. Even as websockets come and go the same handlers
  // will be used
  // Disconnect the WSM once on unmount
  React.useEffect(() => {
    attachHandlers()
    return () => {
      disconnect()
      detachHandlers()
    }
  }, [attachHandlers, detachHandlers, disconnect])

  // Every time the connectionStatus field changes connect will be called, but it will connect when
  // the user has a refresh token and the connectionStatus is in the ready state
  React.useEffect(() => {
    connect()
  }, [connect])

  const shouldLogOut = !couldBeSignedIn && connectionStatus === 'connected'

  React.useEffect(() => {
    if (shouldLogOut) {
      disconnect()
    }
  }, [shouldLogOut, disconnect])

  return { webSocketManager: webSocketManagerRef.current }
}
