import React from 'react'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { clipboardUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { notificationThunks, taskThunks } from '@/thunks'

export const useCopyTaskToClipboard = () => {
  const dispatch = useAppDispatch()
  const { formName } = useTaskFormContext()
  return React.useCallback(async () => {
    const taskString = await dispatch(taskThunks.taskToString(formName))
    const success = await clipboardUtils.writeToClipboard(taskString)
    if (!success) {
      return
    }
    dispatch(
      notificationThunks.showToast({
        title: 'Task copied to clipboard',
        type: 'info',
      }),
    )
  }, [dispatch, formName])
}
