import React from 'react'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { collectionUtils as C } from '@/core/utils'
import { mergeFilters } from '@/core/utils/tasks/filtering'
import { groupTasks, toGroupedTask } from '@/core/utils/tasks/grouping'
import { useCachedSelector, useToggle } from '@/hooks'
import { getTaskId } from '@/store/entityIds'
import { entitySelectors } from '@/store/selectors'
import { Task } from '@/types/entities'
import { TaskFilter, TaskGrouping, TaskQuery } from '@/types/tasks'

type TasksListHookArgs = {
  taskQuery: TaskQuery
  taskGrouping: TaskGrouping | null
  rememberPreviousItems: boolean
  fastCreate: boolean
}

const toggleOpts = { animate: true } as const

export const useTasksList = ({
  taskQuery,
  taskGrouping,
  rememberPreviousItems,
  fastCreate,
}: TasksListHookArgs) => {
  const { filter: baseFilter } = taskQuery

  const [showCompleted, toggleShowCompleted] = useToggle(false, toggleOpts)
  const shownFilter = React.useMemo(
    () => mergeFilters(baseFilter, { completed: showCompleted }),
    [baseFilter, showCompleted],
  )
  const hiddenFilter = React.useMemo(
    () => mergeFilters(baseFilter, { completed: !showCompleted }),
    [baseFilter, showCompleted],
  )

  const includeKeysRef = React.useRef<Set<string>>(new Set())
  const filterRef = React.useRef<TaskFilter>(shownFilter)

  if (filterRef.current !== shownFilter) {
    includeKeysRef.current = new Set()
    filterRef.current = shownFilter
  }

  const includedTasks = useCachedSelector(entitySelectors.tasksByFilterAndInclusionSetSelector, [
    shownFilter,
    taskQuery.sorts,
    includeKeysRef.current,
  ])
  const groupingContext = useCachedSelector(entitySelectors.taskGroupingContextSelector, [])
  const groupedTasks = React.useMemo(
    () =>
      taskGrouping
        ? groupTasks(taskGrouping, includedTasks, groupingContext)
        : includedTasks.map(toGroupedTask(null)),
    [taskGrouping, includedTasks, groupingContext],
  )

  const { showTaskForm } = useShowTaskFormContext()

  const handleShowTask = React.useCallback(
    (task: Task) =>
      showTaskForm({
        defaultSpaceId: task.spaceId,
        fastCreate,
        formContext: 'tasks',
        taskId: task.id,
      }),
    [showTaskForm, fastCreate],
  )

  const flatListRef = React.useRef<any>(null)

  // When tasks change, add any new ids to the included keys ref
  React.useEffect(() => {
    if (rememberPreviousItems) {
      C.setMerge(
        includeKeysRef.current,
        groupedTasks.map(item => getTaskId(item.task)),
      )
    }
  }, [groupedTasks, rememberPreviousItems])

  React.useEffect(() => {
    toggleShowCompleted(false)
  }, [toggleShowCompleted, baseFilter])

  React.useEffect(() => {
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0 })
  }, [shownFilter])

  return {
    flatListRef,
    groupedTasks,
    handleShowTask,
    hiddenFilter,
    showCompleted,
    shownFilter,
    toggleShowCompleted,
  }
}
