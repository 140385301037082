import React from 'react'
import { collectionUtils as C, messageUtils, readReceiptUtils } from '@/core/utils'
import { useAppSelector, useCachedSelector } from '@/hooks'
import { messageSelectors, taskSelectors, threadSelectors } from '@/store/selectors'
import { Channel, ChannelUser, Message } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'
import { useReadReceipts } from './readReceipts'

interface MessageListItemsArgs {
  channel: Channel
  channelUser: ChannelUser
  hideTaskActivity: boolean
  showThreadStart: boolean
  threadId: number | undefined
}

const getCommentKey = (message: Message) => `${message.threadId}:${message.createdAt}`
const getCommentParentKey = (message: Message) => `${message.id}:${message.createdAt}`

export const useMessageListItems = ({
  channel,
  channelUser,
  hideTaskActivity,
  showThreadStart,
  threadId,
}: MessageListItemsArgs) => {
  // This is a very complex function that pulls together all the data needed for the messages
  // list. The idea is that we have all the data together and can compile it in a way that is
  // more efficient than having to query all the data for each item in turn as its rendered out

  const selector = React.useMemo(
    () =>
      threadId
        ? messageSelectors.byThreadIdSelector(channel.spaceId, threadId, showThreadStart)
        : messageSelectors.byChannelIdSelector(channel.spaceId, channel.id),
    [channel, threadId, showThreadStart],
  )
  const messages = useAppSelector(selector)
  const readReceipts = useReadReceipts(channel)
  const taskIds = React.useMemo(
    () =>
      messages.reduce((acc: number[], msg: Message) => {
        acc.push(msg.id)
        if (msg.threadId) {
          acc.push(msg.threadId)
        }
        return acc
      }, []),
    [messages],
  )
  const tasks = useCachedSelector(taskSelectors.byIdsSelector, [channel.spaceId, taskIds])
  const threadIds = React.useMemo(
    () => messages.map(message => messageUtils.getThreadId(message)),
    [messages],
  )
  const threads = useCachedSelector(threadSelectors.byIdsSelector, [channel.spaceId, threadIds])

  const messagesIndex = React.useMemo(() => C.index(message => message.id, messages), [messages])
  const tasksIndex = React.useMemo(() => C.index(task => task.id, tasks), [tasks])
  const readReceiptIndex = React.useMemo(
    () => readReceiptUtils.indexReadReceiptsByMessageId(messages, readReceipts),
    [messages, readReceipts],
  )
  const threadsIndex = React.useMemo(() => C.index(thread => thread.messageId, threads), [threads])
  const taskActivityThreadIndex = React.useMemo(
    () =>
      messages.reduce((idx, message) => {
        const task = tasksIndex[message.id]
        const isTaskActivity = task || messageUtils.isTaskActivity(message)
        if (isTaskActivity) {
          const messageThreadId = messageUtils.getThreadId(message)
          const max = idx[messageThreadId]

          if (!max || max < message.id) {
            idx[messageThreadId] = message.id // eslint-disable-line no-param-reassign
          }
        }
        return idx
      }, {} as Record<number, number>),
    [messages, tasksIndex],
  )

  return React.useMemo(() => {
    const output: MessageListItemData[] = []
    const previousMessageInThreadIndex: Record<number, Message> = {}
    let previous: MessageListItemData | undefined

    // Messages are stored in descending ID order. We need to iterate over them in ascending ID
    // order so that comments will be encountered after the parent message. So we reverse the
    // messages for iterating, and then we unshift the items into the output array to return them
    // to the descending ID order. The last item (greatest ID) will be the first item on the output
    // array
    C.reversed(messages).forEach(message => {
      // A comment is a message that is created along with a task and is displayed specially. Due
      // to database transactions they will have the exact same timestamp, which shouldn't happen
      // otherwise. If a previous message was a task and the task was deleted we don't want to group
      // this message up as a comment

      const messageThreadId = messageUtils.getThreadId(message)

      if (
        previous &&
        previous.task &&
        getCommentKey(message) === getCommentParentKey(previous.message)
      ) {
        previous.comments.push(message)
      } else {
        const task = tasksIndex[message.id]

        // For tasks and task activity, only show the most recent one in a thread, unless it is
        // unread
        const isHiddenTaskActivity =
          hideTaskActivity &&
          (task || messageUtils.isTaskActivity(message)) &&
          taskActivityThreadIndex[messageThreadId] &&
          taskActivityThreadIndex[messageThreadId] > message.id &&
          message.id <= channelUser.maxReadMessageId

        previous = {
          comments: [],
          message,
          parentMessage: message.threadId ? messagesIndex[message.threadId] : undefined,
          parentTask: message.threadId ? tasksIndex[message.threadId] : undefined,
          previousMessage: previous?.message,

          // This doesn't take into consideration hidden task activity
          previousMessageInThread: previousMessageInThreadIndex[messageThreadId],
          readReceipts: readReceiptIndex[message.id],
          showInChat: !isHiddenTaskActivity && messageUtils.showInChat(message),
          task,
          thread: threadsIndex[messageThreadId],
        }
        output.unshift(previous)
        previousMessageInThreadIndex[messageThreadId] = previous.message
      }
    })

    return output
  }, [
    channelUser.maxReadMessageId,
    hideTaskActivity,
    messages,
    messagesIndex,
    readReceiptIndex,
    taskActivityThreadIndex,
    tasksIndex,
    threadsIndex,
  ])
}
