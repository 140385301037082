import React from 'react'
import { useSentinelEffect } from '@/hooks/state'
import { Channel } from '@/types/entities'
import { useCanSubmit } from './canSubmit'
import { revertToMessageOnSubmit } from './constants'
import { useFormState } from './state'
import { useSubmitHandler } from './submit'
import { useTaskForm } from './taskForm'

export const useForm = (channel: Channel, onClose: () => void, threadId?: number) => {
  const { isExpanded, setIsExpanded, inputMode, setInputMode, toggleIsExpanded } =
    useFormState('message')

  const onMessageSent = React.useCallback(() => {
    if (revertToMessageOnSubmit) {
      setInputMode('message')
    }
  }, [setInputMode])

  const {
    canSubmitTask,
    formName,
    handleCancel,
    handleSubmitTask,
    handleSubmitTaskDisabled,
    questionMode,
    resetForm,
    setQuestionMode,
    showTaskModal,
  } = useTaskForm(inputMode === 'question' ? 'QUESTION' : 'TASK', channel, onClose, onMessageSent)

  const handleSubmit = useSubmitHandler({
    channel,
    formName,
    handleSubmitTask,
    inputMode,
    resetForm,
    setInputMode,
    threadId,
  })

  const canSubmit = useCanSubmit(inputMode, canSubmitTask, formName)

  // Reset the form when the channel changes
  useSentinelEffect(resetForm, channel.id)

  return {
    canSubmit,
    formName,
    handleCancel,
    handleSubmit,
    handleSubmitDisabled: handleSubmitTaskDisabled,
    inputMode,
    isExpanded,
    questionMode,
    resetForm,
    setInputMode,
    setIsExpanded,
    setQuestionMode,
    showTaskModal,
    toggleIsExpanded,
  }
}
