import React from 'react'
import { ScrollView } from 'react-native'
import IntrospectedScrollList from '@/components/core/IntrospectedScrollList'
import Box from '@/components/layouts/Box'
import { MessageListItemData } from '@/types/messaging'
import MessageActionsContainer from '../MessageActionsContainer'
import MessageListHeader from './MessageListHeader'
import MessageListItem from './MessageListItem'
import NewMessagesIndicator from './NewMessagesIndicator'
import { useMessagesList } from './hooks'
import {
  useScrollListVisibleItemsChanged,
  useScrollViewScrollOnMessageSend,
} from './hooks/scrollView'
import { MessagesListProps } from './types'
import { defaultShowOtherAvatar } from './utils'

const getMessageId = (item: MessageListItemData) => item.message.id.toString()

function MessagesList({
  channel,
  channelUser,
  threadId,
  showThreadStart = true,
  showOtherAvatar: overrideShowOtherAvatar,
  showIntro = false,
}: MessagesListProps) {
  const scrollViewRef = React.useRef<ScrollView | null>(null)
  const hooks = useMessagesList({ channel, channelUser, showThreadStart, threadId })

  const handleVisibleItemsChanged = useScrollListVisibleItemsChanged(
    hooks.visibleItemsChanged,
    hooks.firstUnreadMessageId,
  )
  useScrollViewScrollOnMessageSend(scrollViewRef)

  const isDisplayedInThread = !!threadId
  const showOtherAvatar =
    overrideShowOtherAvatar === undefined
      ? defaultShowOtherAvatar(channel)
      : overrideShowOtherAvatar

  const renderItem = React.useCallback(
    (item: MessageListItemData) => (
      <MessageListItem
        channelUser={channelUser}
        isDisplayedInThread={isDisplayedInThread}
        isFirstUnreadMessage={item.message.id === hooks.firstUnreadMessageId}
        item={item}
        showOtherAvatar={showOtherAvatar}
      />
    ),
    [channelUser, isDisplayedInThread, showOtherAvatar, hooks.firstUnreadMessageId],
  )

  React.useEffect(() => {
    scrollViewRef.current?.scrollTo({ animated: false, y: 0 })
  }, [channel.id])

  return (
    <MessageActionsContainer isDisplayedInThread={isDisplayedInThread}>
      <Box flex={1}>
        <IntrospectedScrollList
          HeaderComponent={
            <MessageListHeader
              channel={channel}
              hasError={hooks.hasError}
              hasMore={hooks.hasMore}
              isLoading={hooks.isLoading}
              onLoadMore={hooks.loadMore}
              showIntro={showIntro}
            />
          }
          data={hooks.messageItems}
          inverted
          keyExtractor={getMessageId}
          onVisibleItemsChanged={handleVisibleItemsChanged}
          renderItem={renderItem}
          scrollViewRef={scrollViewRef}
        />
        <NewMessagesIndicator show={hooks.hasUnreadAbove} />
      </Box>
    </MessageActionsContainer>
  )
}

export default React.memo(MessagesList)
