import amplitude from 'amplitude-js'
import { config } from '@/core'
import { User } from '@/types/entities'
import { AmplitudeProxyInterface } from './types'
import { userToProperties } from './utils'

const initialize = async () => {
  if (!config.amplitudeApiKey) {
    return
  }

  // NOTE: These are opposite of how the expo sdk configures tracking options
  amplitude.getInstance().init(config.amplitudeApiKey, undefined, {
    trackingOptions: {
      carrier: false,
      city: false,
      country: false,
      device_manufacturer: true,
      device_model: true,
      dma: false,
      ip_address: false,
      language: true,
      os_name: true,
      os_version: true,
      platform: true,
      region: true,
      version_name: true,
    },
  })
}

const setUser = async (user: User) => {
  amplitude.getInstance().setUserId(`${user.id}`)
  amplitude.getInstance().setUserProperties(userToProperties(user))
}

const clearUser = async () => {
  amplitude.getInstance().setUserId(null)
}

const logEvent = async (eventName: string, eventProperties: Record<string, any> = {}) => {
  amplitude.getInstance().logEvent(eventName, eventProperties)
}

const amplitudeProxy: AmplitudeProxyInterface = {
  clearUser,
  initialize,
  logEvent,
  setUser,
}

// ts-unused-exports:disable-next-line
export default amplitudeProxy
