import React from 'react'
import FormTextInput from '@/components/form/FormTextInput'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { formActions } from '@/store/actions'
import { messageSelectors } from '@/store/selectors'

interface EditMessageFormProps {
  spaceId: number
  messageId?: number
  formName: string
  autoFocus: boolean
}

function EditMessageForm({ spaceId, messageId, formName, autoFocus }: EditMessageFormProps) {
  const dispatch = useAppDispatch()
  const message = useAppSelector(messageSelectors.byId, spaceId, messageId || 0)

  React.useEffect(() => {
    if (message) {
      dispatch(formActions.initialize({ fields: { content: message.content }, formName }))
    }
  }, [dispatch, message, formName])

  if (!message) {
    return null
  }

  return (
    <FormTextInput
      autoFocus={autoFocus}
      fieldName="content"
      formName={formName}
      kind="inset"
      maxHeight={350}
      multiline
      numberOfLines={5}
      placeholder="Message..."
      textAlignVertical="top"
    />
  )
}

export default React.memo(EditMessageForm)
