import React from 'react'
import { componentUtils } from '@/core/utils'
import { useAppSelector } from '@/hooks'
import { useCurrentAppFocus } from '@/hooks/appProfile'
import { spaceSelectors } from '@/store/selectors'
import { ComponentProp } from '@/types/components'
import { Space } from '@/types/entities'
import SpaceSelectorItem from './SpaceSelectorItem'

interface SpaceSelectorProps {
  onSelectionChange: (space: Space) => void
  onMore?: (space: Space) => void
  ListEmptyComponent?: ComponentProp | null | undefined
  excludeSpaceId?: number
}

function SpaceSelector({
  onSelectionChange,
  onMore,
  ListEmptyComponent,
  excludeSpaceId,
}: SpaceSelectorProps) {
  const { spaceId: focusedSpaceId } = useCurrentAppFocus()
  const allSpaces = useAppSelector(spaceSelectors.all)
  const spaces = React.useMemo(
    () => allSpaces.filter(space => space.id !== excludeSpaceId),
    [allSpaces, excludeSpaceId],
  )
  const handleSelectionChange = React.useCallback(
    (space: Space) => {
      onSelectionChange(space)
    },
    [onSelectionChange],
  )

  if (spaces.length === 0 && ListEmptyComponent) {
    return componentUtils.renderComponent(ListEmptyComponent)
  }

  return (
    <>
      {spaces.map((space, index) => (
        <SpaceSelectorItem
          key={space.id.toString()}
          isLast={index === spaces.length - 1}
          onMore={onMore}
          onSelectionChange={handleSelectionChange}
          selected={space.id === focusedSpaceId}
          space={space}
        />
      ))}
    </>
  )
}

export default React.memo(SpaceSelector)
