import React from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet } from 'react-native'
import HorizontalList from '@/components/core/HorizontalList'
import { ColorName } from '@/core/constants/colors'
import { fnUtils } from '@/core/utils'
import { FilterGroup, FilterOption } from '@/types/tasks'
import CreateOptionButton from './CreateOptionButton'
import FilterOptionListItem from './FilterOptionListItem'

interface FilterOptionsListProps {
  buttonColor: ColorName
  group?: FilterGroup
  onSelectOption: (option: FilterOption) => void
  selectedOptionKey: string
}

type Item = ListRenderItemInfo<FilterOption>

const isSelected = (key: string, option: FilterOption) => key === option.key
const keyExtractor = (option: FilterOption) => option.label

function FilterOptionsList({
  buttonColor,
  group,
  onSelectOption,
  selectedOptionKey,
}: FilterOptionsListProps) {
  const options = group ? group.options : undefined
  const renderItem = React.useCallback(
    ({ item: option, index }: Item) => (
      <FilterOptionListItem
        isFirst={index === 0}
        isSelected={isSelected(selectedOptionKey, option)}
        onSelect={onSelectOption}
        option={option}
      />
    ),
    [selectedOptionKey, onSelectOption],
  )

  const flatListRef = React.useRef<FlatList | null>(null)
  const lastSelectedOptionRef = React.useRef(selectedOptionKey)
  React.useEffect(() => {
    if (!options) {
      return
    }

    if (selectedOptionKey === lastSelectedOptionRef.current) {
      return
    }

    lastSelectedOptionRef.current = selectedOptionKey
    const selectedOption = options.find(option => isSelected(selectedOptionKey, option))
    flatListRef.current?.scrollToItem({
      animated: true,
      item: selectedOption,
      viewPosition: 0.5,
    })
  }, [selectedOptionKey, options])

  if (!group || group.options.length === 0) {
    return null
  }
  if (group.focus === 'NONE' && group.options.length <= 1) {
    return null
  }

  return (
    <HorizontalList
      ListFooterComponent={<CreateOptionButton group={group} />}
      buttonBackgroundColor={buttonColor}
      buttonIconColor="anchor"
      contentContainerStyle={styles.flatListContentContainer}
      data={options}
      extraData={selectedOptionKey}
      flatListRef={flatListRef}
      horizontal
      keyExtractor={keyExtractor}
      onScrollToIndexFailed={fnUtils.noOp}
      renderItem={renderItem}
      showsHorizontalScrollIndicator={false}
    />
  )
}

const styles = StyleSheet.create({
  flatListContentContainer: {
    alignItems: 'center',
  },
})

export default React.memo(FilterOptionsList)
