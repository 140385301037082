export const iconMap = {
  'alarm-snooze': '\uf845',
  'align-left': '\uf036',
  'archive': '\uf187',
  'arrow-down': '\uf063',
  'arrow-down-alt': '\uf354',
  'arrow-down-to-line': '\uf33d',
  'arrow-left': '\uf060',
  'arrow-right': '\uf061',
  'arrow-right-from-bracket': '\uf08b',
  'arrow-up': '\uf062',
  'arrow-up-from-bracket': '\ue09a',
  'arrow-up-from-square': '\ue09c',
  'arrow-up-right-from-square': '\uf08e',
  'arrows-alt-h': '\uf337',
  'at': '\uf1fa',
  'balloons': '\ue2e4',
  'bars': '\uf0c9',
  'bell': '\uf0f3',
  'bell-slash': '\uf1f6',
  'bookmark': '\uf02e',
  'box-check': '\uf467',
  'brake-warning': '\ue0c7',
  'calendar': '\uf133',
  'calendar-check': '\uf274',
  'calendar-day': '\uf783',
  'calendar-exclamation': '\uf334',
  'calendar-plus': '\uf271',
  'camera': '\uf030',
  'caret-down': '\uf0d7',
  'check': '\uf00c',
  'chevron-double-left': '\uf323',
  'chevron-double-right': '\uf324',
  'chevron-down': '\uf078',
  'chevron-left': '\uf053',
  'chevron-right': '\uf054',
  'chevron-up': '\uf077',
  'circle': '\uf111',
  'circle-plus': '\uf055',
  'circle-question': '\uf059',
  'circle-user': '\uf2bd',
  'circle-x': '\ue12e',
  'circle-xmark': '\uf057',
  'clipboard-check': '\uf46c',
  'clipboard-list-check': '\uf737',
  'clock': '\uf017',
  'clock-nine': '\ue34c',
  'clone': '\uf24d',
  'cog': '\uf013',
  'comments-question-check': '\ue14f',
  'compress-alt': '\uf422',
  'credit-card-front': '\uf38a',
  'eclipse': '\uf749',
  'ellipsis-h': '\uf141',
  'ellipsis-v': '\uf142',
  'envelope': '\uf0e0',
  'envelope-open-text': '\uf658',
  'exclamation': '\uf12a',
  'expand-alt': '\uf424',
  'eye': '\uf06e',
  'eye-slash': '\uf070',
  'face-smile': '\uf118',
  'file-alt': '\uf15c',
  'file-lines': '\uf15c',
  'flag': '\uf024',
  'folder': '\uf07b',
  'football': '\uf44e',
  'gif': '\ue190',
  'glasses': '\uf530',
  'h1': '\uf313',
  'hashtag': '\uf292',
  'image': '\uf03e',
  'inbox': '\uf01c',
  'list': '\uf03a',
  'list-ul': '\uf0ca',
  'medal': '\uf5a2',
  'megaphone': '\uf675',
  'message': '\uf27a',
  'message-check': '\uf4a2',
  'message-dots': '\uf4a3',
  'message-lines': '\uf4a6',
  'message-plus': '\uf4a8',
  'message-question': '\ue1e3',
  'messages': '\uf4b6',
  'minus': '\uf068',
  'note-sticky': '\uf249',
  'paperclip': '\uf0c6',
  'party-face': '\ue383',
  'party-horn': '\ue31b',
  'pen-to-square': '\uf044',
  'pencil': '\uf040',
  'people-arrows': '\ue068',
  'person': '\uf183',
  'phone': '\uf095',
  'phone-plus': '\uf4d2',
  'pizza-slice': '\uf818',
  'plane': '\uf072',
  'play': '\uf04b',
  'plus': '\uf067',
  'question': '\u003f',
  'recycle': '\uf1b8',
  'repeat': '\uf363',
  'reply': '\uf3e5',
  'share-nodes': '\uf1e0',
  'shuffle': '\uf074',
  'slack': '\uf198',
  'sliders': '\uf1de',
  'smile-wink': '\uf4da',
  'sms': '\uf7cd',
  'square-check': '\uf14a',
  'square-plus': '\uf0fe',
  'square-question': '\uf2fd',
  'star': '\uf005',
  'sun': '\uf185',
  'sun-bright': '\ue28f',
  'sunglasses': '\uf892',
  'tags': '\uf02c',
  'th-list': '\uf00b',
  'thumbs-up': '\uf164',
  'trash-alt': '\uf2ed',
  'tree': '\uf1bb',
  'triangle-exclamation': '\uf071',
  'user': '\uf007',
  'user-friends': '\uf500',
  'user-lock': '\uf502',
  'user-plus': '\uf234',
  'user-tag': '\uf507',
  'users': '\uf0c0',
  'volume-mute': '\uf6a9',
  'xmark': '\uf00d',
}

export type IconName = keyof typeof iconMap
export type IconKind = 'light' | 'regular' | 'solid' | 'brands'
