import React from 'react'
import TimeInput from '@/components/core/TimeInput'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import { dateUtils as D } from '@/core/utils'
import { Action } from '@/types/generics'

interface TaskTimeModalProps {
  selectedDate: Date | null
  onDateChange: Action<Date>
  isOpen: boolean
  onClose: () => void
  localModal: boolean
}

function TaskTimeModal({
  selectedDate,
  onDateChange,
  isOpen,
  onClose,
  localModal,
}: TaskTimeModalProps) {
  const date = React.useMemo(() => selectedDate || D.setTime(D.now(), 9), [selectedDate])

  return (
    <StandardModal
      depth={2}
      isOpen={isOpen}
      localModal={localModal}
      minHeight={0}
      rightAction={{ label: 'Done', onAction: onClose, type: 'linkPrimary' }}
      title="Due Time"
    >
      <Box direction="row" gapAll="medium" justifyContent="center">
        <TimeInput autoFocus onChange={onDateChange} value={date} />
      </Box>
    </StandardModal>
  )
}

export default React.memo(TaskTimeModal)
