import { entitySelectors } from '@/store/selectors'
import { Task } from '@/types/entities'
import { SyncThunk } from '@/types/store'
import { TaskFilter } from '@/types/tasks'

// TODO: use thunks/entities/select?
export const getFilteredTasks =
  (filter: TaskFilter): SyncThunk<Task[]> =>
  (dispatch, getState) =>
    entitySelectors.tasksByFilterSelector(filter)(getState())
