import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { dateUtils } from '@/core/utils'
import { Message, ReadReceipt } from '@/types/entities'

interface MessageTimestampProps {
  readReceipts?: ReadReceipt[]
  message: Message
  isFromMe: boolean
}

function MessageTimestamp({ message, isFromMe, readReceipts }: MessageTimestampProps) {
  let text: string | null = null

  const sent = dateUtils.formatTime(dateUtils.parse(message.createdAt))

  if (isFromMe && readReceipts && readReceipts.length !== 0) {
    text = `Sent ${sent} (Read)`
  } else {
    text = sent
  }

  return (
    <Box alignItems={isFromMe ? 'flex-end' : 'flex-start'} gapTop="xsmall">
      <Text color="anchor" size="h6">
        {text}
      </Text>
    </Box>
  )
}

export default React.memo(MessageTimestamp)
