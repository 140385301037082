import React from 'react'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { dateUtils as D } from '@/core/utils'
import TaskDate from './TaskDate'

function TaskReminderDate() {
  const remindAt = useTaskFormValue(fieldNames.remindAt)
  const date = D.tryParse(remindAt)
  const hasReminder = !!date

  return (
    <Box alignItems="center" direction="row">
      <Icon
        color={hasReminder ? 'shadow' : 'koala'}
        kind="light"
        name={hasReminder ? 'bell' : 'bell-slash'}
        size="h4"
      />
      <Box direction="column" gapLeft="small">
        {hasReminder ? (
          <TaskDate date={date} />
        ) : (
          <Text color="anchor" size="h6">
            No Reminder
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default React.memo(TaskReminderDate)
