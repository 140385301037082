import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'
import { InputMode } from '@/types/messaging'

interface MQTToggleButtonProps {
  inputMode: InputMode
  isSelected: boolean
  onSelect: (mode: InputMode) => void
}

function MQTToggleButton({ inputMode, isSelected, onSelect }: MQTToggleButtonProps) {
  const handleSelect = React.useCallback(() => onSelect(inputMode), [onSelect, inputMode])

  return (
    <Background borderBottomWidth={1} borderColor={isSelected ? COLORS[inputMode] : 'transparent'}>
      <Box gapBottom="xsmall">
        <BaseIconButton
          color={isSelected ? COLORS[inputMode] : 'iron'}
          hitSlop="xsmall"
          name={ICONS[inputMode]}
          onPress={handleSelect}
          padding={0}
          shape="square"
          size="h3"
        />
      </Box>
    </Background>
  )
}

const ICONS: Record<InputMode, IconName> = {
  message: 'message-lines',
  question: 'message-question',
  task: 'message-check',
}

const COLORS: Record<InputMode, ColorName> = {
  message: 'emmre-blue+2',
  question: 'emmre-purple',
  task: 'emmre-green',
}

export default React.memo(MQTToggleButton)
