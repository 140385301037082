import React from 'react'
import { Animated } from 'react-native'
import {
  PanGestureHandler,
  PanGestureHandlerStateChangeEvent,
  PinchGestureHandler,
  PinchGestureHandlerStateChangeEvent,
  State,
} from 'react-native-gesture-handler'

interface PinchPanContainerProps {
  children: React.ReactNode
}

function PinchPanContainer({ children }: PinchPanContainerProps) {
  const panGestureHandler = React.useRef()
  const pinchGestureHandler = React.useRef()

  const lastScale = React.useRef(1)
  const baseScale = React.useRef(new Animated.Value(1))
  const pinchScale = React.useRef(new Animated.Value(1))
  const scale = React.useRef(Animated.multiply(baseScale.current, pinchScale.current))

  const lastXY = React.useRef({ x: 0, y: 0 })
  const baseXY = React.useRef(new Animated.ValueXY())
  const panXY = React.useRef(new Animated.ValueXY())
  const panX = React.useRef(Animated.add(baseXY.current.x, panXY.current.x))
  const panY = React.useRef(Animated.add(baseXY.current.y, panXY.current.y))

  const handlePinchEvent = React.useRef(
    Animated.event([{ nativeEvent: { scale: pinchScale.current } }], { useNativeDriver: false }),
  ).current

  const handlePinchHandlerStateChange = React.useCallback(
    ({ nativeEvent }: PinchGestureHandlerStateChangeEvent) => {
      if (nativeEvent.oldState === State.ACTIVE) {
        lastScale.current *= nativeEvent.scale
        baseScale.current.setValue(lastScale.current)
        pinchScale.current.setValue(1)
      }
    },
    [],
  )

  const handlePanEvent = React.useRef(
    Animated.event(
      [{ nativeEvent: { translationX: panXY.current.x, translationY: panXY.current.y } }],
      {
        useNativeDriver: false,
      },
    ),
  ).current

  const handlePanHandlerStateChange = React.useCallback(
    ({ nativeEvent }: PanGestureHandlerStateChangeEvent) => {
      if (nativeEvent.oldState === State.ACTIVE) {
        lastXY.current.x += nativeEvent.translationX
        lastXY.current.y += nativeEvent.translationY
        baseXY.current.setValue(lastXY.current)
        panXY.current.setValue({ x: 0, y: 0 })
      }
    },
    [],
  )

  return (
    <PanGestureHandler
      ref={panGestureHandler}
      onGestureEvent={handlePanEvent}
      onHandlerStateChange={handlePanHandlerStateChange}
      simultaneousHandlers={pinchGestureHandler}
    >
      <PinchGestureHandler
        ref={pinchGestureHandler}
        onGestureEvent={handlePinchEvent}
        onHandlerStateChange={handlePinchHandlerStateChange}
        simultaneousHandlers={panGestureHandler}
      >
        <Animated.View
          style={{
            transform: [
              { scale: scale.current },
              { translateX: panX.current },
              { translateY: panY.current },
            ],
          }}
        >
          {children}
        </Animated.View>
      </PinchGestureHandler>
    </PanGestureHandler>
  )
}

export default React.memo(PinchPanContainer)
