import { TaskDefaults, UnaryTaskFilter } from '@/types/tasks'
import * as C from '../collections'
import * as D from '../dates'
import { isString } from '../types'
import { getFutureDateBounds } from './dates'

export const getTaskDefaults = (defaultSpaceId: number, filter: UnaryTaskFilter): TaskDefaults => {
  const task: Partial<TaskDefaults> = {}

  if (
    filter.spaceIds &&
    filter.spaceIds.mode === 'include' &&
    filter.spaceIds.values.length === 1
  ) {
    task.spaceId = C.head(filter.spaceIds.values)
  }

  if (
    filter.channelIds &&
    filter.channelIds.mode === 'include' &&
    filter.channelIds.values.length === 1
  ) {
    const [spaceId, channelId] = C.head(filter.channelIds.values)

    if (!task.spaceId || task.spaceId === spaceId) {
      task.spaceId = spaceId
      task.channelId = channelId
    }
  }

  if (
    filter.dueDateTypes &&
    filter.dueDateTypes.mode === 'include' &&
    filter.dueDateTypes.values.length === 1
  ) {
    const dueDateType = C.head(filter.dueDateTypes.values)

    task.dueDateType = dueDateType

    if (dueDateType === 'DATE') {
      if (filter.dueDateRange) {
        const dateRange = isString(filter.dueDateRange)
          ? getFutureDateBounds(filter.dueDateRange)
          : filter.dueDateRange
        task.dueDate = dateRange.lbound || dateRange.ubound
      } else {
        task.dueDate = D.formatISO(D.ceil(D.now(), 'day'))
      }
    } else {
      task.dueDate = null
    }
  }

  if (task.dueDate) {
    task.dueDate = D.formatISO(D.setTime(D.parse(task.dueDate), 9))
  }

  if (
    filter.urgencies &&
    filter.urgencies.mode === 'include' &&
    filter.urgencies.values.length === 1
  ) {
    task.urgency = C.head(filter.urgencies.values)
  }

  if (
    filter.boardIds &&
    filter.boardIds.mode === 'include' &&
    filter.boardIds.values.length === 1
  ) {
    const [spaceId, boardId] = C.head(filter.boardIds.values)

    if (!task.spaceId || task.spaceId === spaceId) {
      task.spaceId = spaceId
      task.boardId = boardId
    }
  }

  if (filter.tagIds && filter.tagIds.mode === 'include' && filter.tagIds.values.length === 1) {
    const [spaceId, tagId] = C.head(filter.tagIds.values)

    if (!task.spaceId || task.spaceId === spaceId) {
      task.spaceId = spaceId
      task.tagIds = [tagId]
    }
  }

  if (
    filter.assignedUserIds &&
    filter.assignedUserIds.mode === 'include' &&
    filter.assignedUserIds.values.length === 1
  ) {
    task.assignedUserId = C.head(filter.assignedUserIds.values)
  }

  if (filter.taskList && filter.taskList.relationship === 'IN_LIST') {
    task.taskListId = filter.taskList.taskListId
  }

  return { spaceId: defaultSpaceId, ...task }
}
