import React from 'react'
import { StyleSheet } from 'react-native'
import { ColorName } from '@/core/constants/colors'
import Text, { TextProps } from './Text'
import Touchable from './Touchable'

type LabelKind = 'error' | 'light' | 'dark'

interface LabelProps extends Omit<TextProps, 'color'> {
  kind?: LabelKind
  onPress?: () => void
  position: 'top' | 'bottom'
}

function Label({
  children,
  kind = 'dark',
  size = 'h5',
  onPress,
  position,
  weight = 'medium',
  ...textProps
}: LabelProps) {
  if (!children) {
    return null
  }

  return (
    <Touchable
      feedback="none"
      onPress={onPress}
      style={position === 'top' ? styles.top : styles.bottom}
    >
      <Text {...textProps} color={getLabelColor(kind)} size={size} weight={weight}>
        {children}
      </Text>
    </Touchable>
  )
}

const getLabelColor = (kind: LabelKind): ColorName => {
  switch (kind) {
    case 'error':
      return 'red'
    case 'light':
      return 'anchor'
    case 'dark':
    default:
      return 'obsidian'
  }
}

const styles = StyleSheet.create({
  bottom: {
    marginTop: 5,
  },
  top: {
    marginBottom: 5,
  },
})

export default React.memo(Label)
