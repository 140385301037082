import React from 'react'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { Task } from '@/types/entities'

interface TaskListItemCreatedByFocusProps {
  task: Task
}

function TaskListItemCreatedByFocus({ task }: TaskListItemCreatedByFocusProps) {
  return <UserProfilePicture spaceId={task.spaceId} userId={task.creatorId} />
}

export default React.memo(TaskListItemCreatedByFocus)
