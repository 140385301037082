import React from 'react'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { buildBaseFilter } from '@/core/utils/tasks/filters'
import { useAppDispatch, useAppSelector, useCurrentUserId } from '@/hooks'
import { authSelectors } from '@/store/selectors'
import { taskThunks } from '@/thunks'
import { Task, UserAttribute } from '@/types/entities'
import {
  TaskFormContext,
  TaskFormSize,
  TaskRelationship,
  TaskType,
  UnaryTaskFilter,
} from '@/types/tasks'

type CreateTaskHookArgs = {
  createTaskType: TaskType
  fastCreate: boolean
  filter?: UnaryTaskFilter
  formContext: TaskFormContext
  initialTaskFormSize: TaskFormSize
  onSuccess?: (task: Task) => void
  spaceId: number
  taskRelationship?: TaskRelationship
}

export const useCreateTaskButton = ({
  createTaskType,
  fastCreate,
  filter,
  formContext,
  initialTaskFormSize,
  onSuccess,
  spaceId,
  taskRelationship,
}: CreateTaskHookArgs) => {
  const dispatch = useAppDispatch()

  const [disableButton, setDisableButton] = React.useState(false)

  const currentUserId = useCurrentUserId()

  const { showTaskForm } = useShowTaskFormContext()
  const showModal = React.useCallback(async () => {
    setDisableButton(true)
    const taskDefaults = await dispatch(
      taskThunks.getTaskDefaults(spaceId, filter || buildBaseFilter(spaceId, 'TASK')),
    )
    if (taskRelationship === 'ALL') {
      taskDefaults.assignedUserId = currentUserId
    }
    showTaskForm({
      createTaskType,
      defaultSpaceId: taskDefaults.spaceId,
      fastCreate,
      formContext,
      initialSize: initialTaskFormSize,
      onSuccess,
      taskDefaults,
    })
    setDisableButton(false)
  }, [
    createTaskType,
    currentUserId,
    dispatch,
    fastCreate,
    filter,
    formContext,
    initialTaskFormSize,
    onSuccess,
    showTaskForm,
    spaceId,
    taskRelationship,
  ])
  const label = useCreateTaskLabel(createTaskType, taskRelationship)

  return {
    disableButton,
    label,
    showModal,
  }
}

const useCreateTaskLabel = (
  taskType: TaskType,
  taskRelationship: TaskRelationship | undefined,
): string | null => {
  const values = useAppSelector(authSelectors.attributeValues)

  const value = React.useMemo(() => {
    const { attributes, label } = getUserAttributes(taskType, taskRelationship)

    if (attributes.some(attr => !!values[attr])) {
      return null
    }
    return label
  }, [taskType, taskRelationship, values])

  return value
}

const getUserAttributes = (
  taskType: TaskType,
  taskRelationship: TaskRelationship | undefined,
): { attributes: UserAttribute[]; label: string | null } => {
  // When there's no task relationship we can't know for sure the context they're creating a
  // task from, it will use the channel defaults which could either default it to the user
  // or to another
  if (!taskRelationship) {
    return { attributes: [], label: null }
  }

  if (taskType === 'QUESTION') {
    return { attributes: ['CREATED_QUESTION'], label: 'Ask First Question' }
  }
  if (taskRelationship === 'MINE') {
    return { attributes: ['CREATED_TASK'], label: 'Create First Task' }
  }
  if (taskRelationship === 'WATCHING') {
    return { attributes: ['ASSIGNED_TASK'], label: 'Assign First Task' }
  }
  return { attributes: ['CREATED_TASK', 'ASSIGNED_TASK'], label: 'Create First Task' }
}
