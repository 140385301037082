import React from 'react'
import { useCurrentUserId, useUser } from '@/hooks'
import { useChannel } from '@/hooks/channels'

type WatchingLabelArgs = {
  spaceId?: number | null
  channelId?: number | null
  defaultLabel: string
}

export const useWatchingTabLabel = ({
  spaceId,
  channelId,
  defaultLabel,
}: WatchingLabelArgs): string => {
  const channel = useChannel(spaceId || 0, channelId || 0)
  const myId = useCurrentUserId()
  const otherUserId = React.useMemo(() => {
    if (!channel) {
      return undefined
    }
    const otherUserIds = channel.userIds.filter(userId => userId !== myId)
    if (otherUserIds.length === 1) {
      return otherUserIds[0]
    }
    return undefined
  }, [channel, myId])
  const otherUser = useUser(spaceId || 0, otherUserId || 0)

  return otherUser ? otherUser.firstName : defaultLabel
}
