import React from 'react'
import Box from '@/components/layouts/Box'
import TaskAcceptedIndicator from './TaskAcceptedIndicator'
import TaskAssignee from './TaskAssignee'
import TaskMyDayToggleButton from './TaskMyDayToggleButton'

// This component can be rendered in several different contexts so we need a few
// controls over how it gaps itself. I don't like this very much
interface TaskMyDayAssigneeLineProps {
  wrapped?: boolean
  gap?: boolean
}

function TaskMyDayAssigneeLine({ wrapped = false, gap = true }: TaskMyDayAssigneeLineProps) {
  return (
    <Box
      alignItems="center"
      direction="row"
      flex={wrapped ? 1 : undefined}
      gapHorizontal={wrapped || !gap ? undefined : 'large'}
      gapTop="xsmall"
    >
      <TaskMyDayToggleButton />
      <Box flex={1} gapLeft="medium">
        <TaskAssignee />
      </Box>
      <TaskAcceptedIndicator />
    </Box>
  )
}

export default React.memo(TaskMyDayAssigneeLine)
