import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import BaseIconButton from '@/components/core/BaseIconButton'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import { ColorName, getColor } from '@/core/constants/colors'
import { Task } from '@/types/entities'
import {
  TaskFormContext,
  TaskFormSize,
  TaskRelationship,
  TaskType,
  UnaryTaskFilter,
} from '@/types/tasks'
import { useCreateTaskButton } from './hooks'

const padding = 10

interface CreateTaskButtonProps {
  createTaskType?: TaskType
  fastCreate?: boolean
  filter?: UnaryTaskFilter
  formContext: TaskFormContext
  initialTaskFormSize?: TaskFormSize
  onSuccess?: (task: Task) => void
  spaceId: number
  taskRelationship?: TaskRelationship
  foregroundColor: ColorName
}

function CreateTaskButton({
  createTaskType = 'TASK',
  fastCreate = false,
  filter,
  foregroundColor,
  formContext,
  initialTaskFormSize = 'SMALL',
  onSuccess,
  spaceId,
  taskRelationship,
}: CreateTaskButtonProps) {
  const { disableButton, showModal, label } = useCreateTaskButton({
    createTaskType,
    fastCreate,
    filter,
    formContext,
    initialTaskFormSize,
    onSuccess,
    spaceId,
    taskRelationship,
  })

  const insets = useSafeAreaInsets()
  const containerStyle = React.useMemo<ViewStyle>(
    () => ({
      bottom: Math.max(insets.bottom, 20),
      position: 'absolute',
      right: 20,
    }),
    [insets.bottom],
  )

  if (!spaceId) {
    return null
  }

  if (label) {
    return (
      <Touchable feedback="opacity" onPress={showModal} style={[containerStyle, styles.touchable]}>
        <Text color={foregroundColor} size={24}>
          {label}
        </Text>
        <View style={styles.iconContainer}>
          <Icon color={foregroundColor} name="plus" size={24} />
        </View>
      </Touchable>
    )
  }

  return (
    <View style={containerStyle}>
      <BaseIconButton
        amplitudeEventName={
          createTaskType === 'TASK' ? 'open task creation modal' : 'open question creation modal'
        }
        backgroundColor="ghost"
        color={foregroundColor}
        disabled={disableButton}
        dropShadow
        kind="solid"
        name="plus"
        onPress={showModal}
        padding={padding}
        shape="circle"
        size={36}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    paddingLeft: 10,
  },
  touchable: {
    alignItems: 'center',
    backgroundColor: getColor('ghost'),
    borderRadius: 24,
    elevation: 5,
    flexDirection: 'row',
    paddingBottom: padding,
    paddingLeft: padding * 1.5,
    paddingRight: padding * 1.5,
    paddingTop: padding,
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.6,
    shadowRadius: 5,
  },
})

export default React.memo(CreateTaskButton)
