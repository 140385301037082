import { collectionUtils as C, stringUtils as S } from '@/core/utils'
import { Comparer, Filter, Map } from '@/types/generics'
import {
  FilterGroup,
  FilterOption,
  GroupFilterOption,
  TaskGroup,
  TaskGrouping,
} from '@/types/tasks'

export const locateFilterOption = (groups: FilterGroup[], queryKey?: string) => {
  const [groupKey, optionKey] = queryKey ? S.splitOnce(':', queryKey) : [undefined, undefined]

  let group = groups.find(g => g.key === groupKey)
  if (!group || group.options.length === 0) {
    group = groups.find(g => g.options.length !== 0)
  }
  if (!group) {
    throw new Error(`Unable to find a filtering group with any options`)
  }
  const option = group.options.find(o => o.key === optionKey) || group.options[0]

  return {
    group,
    option,
  }
}

export const parseFilterOptionSelection = (group: FilterGroup, option: FilterOption) => {
  let taskGrouping: TaskGrouping | null = null
  const isGroupingOption = option.type === 'GROUPING'

  if (isGroupingOption) {
    const groups = getTaskGroups(group.options)

    if (groups.length !== 0) {
      taskGrouping = {
        groupingType: option.groupingType,
        groups,
      }
    }
  }

  return {
    filter: option.filter,
    focus: group.focus,
    grouping: taskGrouping,
    sorts: option.sorts,
  }
}

const compareFilterOptions =
  (subCompare: Comparer<FilterOption>): Comparer<FilterOption> =>
  (left, right) => {
    // Dynamic group options get sorted compared to each other, but everything else stays in the
    // static order
    if (isDynamicGroupOption(left) && isDynamicGroupOption(right)) {
      return subCompare(left, right)
    }
    return 0
  }

type PrepareFilterOptionsArgs = {
  filterOptions?: Filter<FilterOption>
  mapOption?: Map<FilterOption>
  compareOptions?: Comparer<FilterOption>
}

export const prepareFilterOptions = (
  group: FilterGroup,
  { filterOptions, mapOption, compareOptions = compareCountsThenLabels }: PrepareFilterOptionsArgs,
) => {
  // First map all the options, for instance to add task counts etc.
  const mappedOptions = mapOption ? group.options.map(mapOption) : group.options
  const filteredOptions = filterOptions ? filterOptions(mappedOptions) : mappedOptions

  // Sort dynamic options by count descending, then label ascending. So things with higher counts
  // are on the left, and then options with the same count are compared alphabetically
  const sortedOptions = C.sort(compareFilterOptions(compareOptions), filteredOptions)

  return {
    ...group,
    options: sortedOptions,
  }
}

// Sort options by counts desc
const compareCounts: Comparer<FilterOption> = (left, right) =>
  (right.count || 0) - (left.count || 0)

// Sort options by label alphabetically
const compareLabels: Comparer<FilterOption> = (left, right) =>
  S.sortAscending(left.label, right.label)

const compareCountsThenLabels = C.combineComparers(compareCounts, compareLabels)

const isStaticGroupOption = (option: FilterOption) => option.type === 'STATIC_GROUP'
const isDynamicGroupOption = (option: FilterOption) => option.type === 'DYNAMIC_GROUP'
export const isGroupOption = (option: FilterOption): option is GroupFilterOption =>
  isStaticGroupOption(option) || isDynamicGroupOption(option)

const getTaskGroups = (options: FilterOption[]): TaskGroup[] =>
  options.filter(isGroupOption).map((option: GroupFilterOption) => ({
    key: option.groupKey,
    label: option.allListLabel || option.label,
  }))
