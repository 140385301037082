import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { useAmplitude } from '@/hooks'
import { AmplitudeProps } from '@/types/amplitude'

interface RoleButtonProps extends AmplitudeProps {
  label: string
  onSelect: (value: string) => void
  selectedValue: string | null
  value: string
  likely?: boolean
}

function RoleButton({
  amplitudeEventName,
  amplitudeEventProperties,
  label,
  value,
  onSelect,
  selectedValue,
  likely = false,
}: RoleButtonProps) {
  const handlePress = useAmplitude(
    React.useCallback(() => onSelect(value), [onSelect, value]),
    amplitudeEventName,
    amplitudeEventProperties,
  )

  let borderColor: ColorName
  let foregroundColor: ColorName
  let backgroundColor: ColorName

  if (!selectedValue) {
    borderColor = 'emmre-blue'
    backgroundColor = 'ghost'
    foregroundColor = 'emmre-blue'
  } else if (selectedValue === value) {
    borderColor = 'emmre-blue'
    backgroundColor = 'emmre-blue'
    foregroundColor = 'ghost'
  } else {
    borderColor = 'iron'
    backgroundColor = 'ghost'
    foregroundColor = 'iron'
  }

  return (
    <Touchable feedback="opacity" onPress={handlePress}>
      <Background
        borderColor={borderColor}
        borderRadius={8}
        borderWidth={likely ? 2 : 1}
        color={backgroundColor}
      >
        <Box alignItems="center" gapVertical="small" justifyContent="center" width={200}>
          <Text color={foregroundColor} size="h5">
            {label}
          </Text>
        </Box>
      </Background>
    </Touchable>
  )
}

export default React.memo(RoleButton)
