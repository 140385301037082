import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import EmmreLogo from '@/components/images/EmmreLogo'
import Box from '@/components/layouts/Box'
import { Summary } from '@/types/entities'
import { useReviewSummaryButtons } from './hooks'

interface ReviewSummaryButtonsProps {
  summary: Summary
}

function ReviewSummaryButtons({ summary }: ReviewSummaryButtonsProps) {
  const {
    feedbackVisible,
    isCompleted,
    handleReviewSummary,
    handleCompleteSummary,
    notificationName,
  } = useReviewSummaryButtons(summary)

  return (
    <>
      <Box gapTop="xlarge">
        <Checkbox
          checked={isCompleted}
          disabled={isCompleted}
          label="Mark as Complete"
          onChange={handleCompleteSummary}
          shape="square"
        />
        <Box gapTop="medium">
          <Link
            label={`Let ${notificationName} know I've made some updates`}
            onPress={handleReviewSummary}
            size="h5"
          />
        </Box>
      </Box>
      <Box direction="row" gapTop="medium" justifyContent="center">
        <Text color="shadow">
          {feedbackVisible ? `Thanks, we let ${notificationName} know.` : ' '}
        </Text>
      </Box>
      <Box alignItems="center" gapVertical="xlarge">
        <Text size="h5">Powered by</Text>
        <Box alignItems="center" direction="row" gapTop="small">
          <EmmreLogo size={24} />
          <Box gapLeft="small">
            <Text color="emmre-blue" size="h3" weight="medium">
              emmre
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default React.memo(ReviewSummaryButtons)
