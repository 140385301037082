import React from 'react'
import { noOpWarn } from '@/core/logging'
import type { ShowTaskFormContextType } from './types'

const defaultValue: ShowTaskFormContextType = {
  showTaskForm: noOpWarn('show task form context not present'),
}

const ShowTaskFormContext = React.createContext<ShowTaskFormContextType>(defaultValue)

export const useShowTaskFormContext = () => React.useContext(ShowTaskFormContext)

export default ShowTaskFormContext
