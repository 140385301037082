import React from 'react'
import { StyleSheet, View } from 'react-native'

function DropIndicator() {
  return <View style={styles.container} />
}

const styles = StyleSheet.create({
  container: {
    borderColor: 'blue',
    borderTopWidth: 2,
    marginBottom: 3,
    marginTop: 3,
  },
})

export default React.memo(DropIndicator)
