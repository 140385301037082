import { platformUtils } from '@/core/utils'
import FlatListImplementation from './FlatListImplementation'
import ScrollViewImplementation from './ScrollViewImplementation'

// The scroll view implementation doesn't seem to work right on mobile, but the flat list
// implementation is janky on and doesn't work right with viewability on web, so for now at least
// I must maintain two versions.
//
// On web the flat list implementation  messes up when inverted in the following ways:
// 1) viewability info is incorrect
// 2) scroll goes crazy once you load enough items
const useScrollViewImplementationForMobile = false
const useScrollViewImplementation = platformUtils.isWeb || useScrollViewImplementationForMobile

export default useScrollViewImplementation ? ScrollViewImplementation : FlatListImplementation
