import AsyncStorage from '@react-native-async-storage/async-storage'
import React from 'react'
import { collectionUtils as C } from '@/core/utils'
import { useIsMounted } from '@/hooks/components'
import { Emoji, EmojiGroup } from '@/types/emoji'
import { FrequencyCounter } from '@/types/generics'

const storageKey = 'emmre/emoji'
const maxHistoryLength = 100

const getList = async (): Promise<Emoji[]> => {
  const value = await AsyncStorage.getItem(storageKey)
  if (value) {
    return JSON.parse(value) as Emoji[]
  }
  return []
}

const addToList = async (emoji: Emoji): Promise<Emoji[]> => {
  const list = await getList()
  list.unshift(emoji)

  if (list.length > maxHistoryLength) {
    list.pop()
  }

  await AsyncStorage.setItem(storageKey, JSON.stringify(list))

  return list
}

const getGroup = async (maxItems: number, rowWidth: number): Promise<EmojiGroup> => {
  const list = await getList()

  const frequency = list.reduce((acc, emoji) => {
    const key = emoji.symbol

    if (!(key in acc)) {
      acc[key] = { count: 0, data: emoji }
    }

    acc[key].count += 1

    return acc
  }, {} as FrequencyCounter<Emoji>)

  const allItems = Object.values(frequency)
  allItems.sort((left, right) => right.count - left.count)

  const items = allItems.slice(0, maxItems)

  return {
    data: C.chunk(
      rowWidth,
      items.map(item => item.data),
    ),
    key: 'frequently_used',
    name: 'Frequently Used',
  }
}

export const useFrequentlyUsedGroup = (maxItems: number, rowWidth: number) => {
  const [group, setGroup] = React.useState<EmojiGroup | null>(null)
  const isMountedRef = useIsMounted()

  const addToGroup = React.useCallback(
    async (emoji: Emoji) => {
      await addToList(emoji)

      if (isMountedRef.current) {
        setGroup(await getGroup(maxItems, rowWidth))
      }
    },
    [setGroup, maxItems, rowWidth, isMountedRef],
  )

  const initializeGroup = React.useCallback(
    async () => setGroup(await getGroup(maxItems, rowWidth)),
    [setGroup, maxItems, rowWidth],
  )

  React.useEffect(() => {
    initializeGroup()
  }, [initializeGroup])

  return {
    addToFrequentlyUsed: addToGroup,
    frequentlyUsedGroup: group,
  }
}
