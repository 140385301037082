import React from 'react'
import { ScrollView } from 'react-native'
import StackHeader from '@/components/headers/StackHeader'
import Box from '@/components/layouts/Box'
import CenterWideContainer from '@/components/layouts/CenterWideContainer'
import ContentContainer from '@/components/layouts/ContentContainer'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { sizes } from '@/core/constants'
import { useNavigateToChannel } from '@/hooks/channels'
import {
  RootNavigationProp as NavigationProp,
  RootRouteProp as RouteProp,
} from '@/screens/NarrowScreen/routes'
import { Channel } from '@/types/entities'
import AccountManagementItem from '../AccountManagement/AccountManagementItem'

interface ArchivedItemsScreenProps {
  navigation: NavigationProp<'archived-items'>
  route: RouteProp<'archived-items'>
}

function ArchivedItemsScreen({ navigation, route }: ArchivedItemsScreenProps) {
  const { spaceId } = route.params
  const navigateToChannel = useNavigateToChannel(navigation)
  const modalPresenter = useModalPresenterContext()
  const handleBack = React.useCallback(() => navigation.goBack(), [navigation])
  const handleShowArchivedHubs = React.useCallback(() => {
    modalPresenter.push('archivedHubs', { spaceId })
  }, [spaceId, modalPresenter])
  const handleShowArchivedChannels = React.useCallback(() => {
    modalPresenter.push('archivedChannels', {
      onShowMessages(channel: Channel) {
        navigateToChannel(channel)
      },
      spaceId,
    })
  }, [spaceId, modalPresenter, navigateToChannel])

  return (
    <ContentContainer avoidKeyboard context="SCREEN" insetBottom>
      <StackHeader
        maxContentWidth={sizes.maxFormWidth}
        onBack={handleBack}
        theme="blue"
        title="Archived Items"
      />
      <CenterWideContainer highlightContent maxWidth={sizes.maxFormWidth}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <Box gapAll="medium">
            <AccountManagementItem label="Executive Hubs" onPress={handleShowArchivedHubs} />
            <AccountManagementItem label="Channels" onPress={handleShowArchivedChannels} />
          </Box>
        </ScrollView>
      </CenterWideContainer>
    </ContentContainer>
  )
}

export default React.memo(ArchivedItemsScreen)
