import { Linking } from 'react-native'
import { config } from '@/core'
import { platformUtils } from '@/core/utils'
import { linking } from './linking'
import { linkingConfig } from './linkingConfig'
import {
  AccountManagementRouteParams,
  AuthRouteParams,
  RootRouteParams,
  SignUpRouteParams,
  SpaceRouteParams,
} from './params'
import {
  AccountManagementNavigationProp,
  AuthNavigationProp,
  IntegrationsManagementNavigationProp,
  RootNavigationProp,
  SignUpNavigationProp,
  SpaceNavigationProp,
} from './types'

export const goToSignIn = <T extends keyof RootRouteParams>(
  navigation: RootNavigationProp<T> | null,
  message?: string,
) => navigation?.navigate('auth', { params: { message }, screen: 'sign-in' })

export const goToSignUp = <T extends keyof RootRouteParams>(
  navigation: RootNavigationProp<T> | null,
) => {
  if (platformUtils.isWeb) {
    navigation?.navigate('sign-up', { params: {}, screen: 'email-step' })
  } else {
    const url = `${config.appURLRoot}/sign-up`
    Linking.openURL(url)
  }
}

export const goToSpace = <T extends keyof RootRouteParams>(
  navigation: RootNavigationProp<T> | null,
  spaceId: string | number | null,
) => {
  navigation?.navigate('spaces', { spaceId })
}

export const goToNoSpaces = <T extends keyof RootRouteParams>(
  navigation: RootNavigationProp<T> | null,
) => navigation?.navigate('no-spaces')

export function getParentNavigation<
  T extends keyof RootRouteParams,
  U extends keyof AuthRouteParams,
>(childNavigation: AuthNavigationProp<U>): RootNavigationProp<T>
export function getParentNavigation<
  T extends keyof RootRouteParams,
  U extends keyof AccountManagementRouteParams,
>(childNavigation: AccountManagementNavigationProp<U>): RootNavigationProp<T>
export function getParentNavigation<
  T extends keyof RootRouteParams,
  U extends keyof SignUpRouteParams,
>(childNavigation: SignUpNavigationProp<U>): RootNavigationProp<T>
export function getParentNavigation<
  T extends keyof RootRouteParams,
  U extends keyof SpaceRouteParams,
>(childNavigation: SpaceNavigationProp<U>): RootNavigationProp<T>
export function getParentNavigation<T extends keyof RootRouteParams>(
  childNavigation:
    | AuthNavigationProp<any>
    | AccountManagementNavigationProp<any>
    | IntegrationsManagementNavigationProp<any>
    | SignUpNavigationProp<any>
    | SpaceNavigationProp<any>,
) {
  const parent = childNavigation.getParent()

  if (parent) {
    return parent as RootNavigationProp<T>
  }

  return null
}

export const redirect = <T extends keyof RootRouteParams>(
  navigation: RootNavigationProp<T>,
  url: string,
): boolean => {
  const state = linking.getStateFromPath(url, linkingConfig)

  if (!state) {
    return false
  }

  navigation.reset(state as any)
  return true
}
