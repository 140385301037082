import React from 'react'
import Icon from '@/components/core/Icon'
import { Task } from '@/types/entities'

interface TaskListItemSourceFocusProps {
  task: Task
}

function TaskListItemSourceFocus({ task }: TaskListItemSourceFocusProps) {
  if (task.creationSource === 'SLACK') {
    return <Icon color="iron" kind="brands" name="slack" size="h3" />
  }
  if (task.creationSource === 'SMS') {
    return <Icon color="iron" kind="solid" name="sms" size="h3" />
  }
  if (task.creationSource === 'EMAIL') {
    return <Icon color="iron" kind="solid" name="at" size="h3" />
  }
  return null
}

export default React.memo(TaskListItemSourceFocus)
