import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface TaskSectionHeaderProps {
  label: string
}

function TaskSectionHeader({ label }: TaskSectionHeaderProps) {
  return (
    <Box gapBottom="xsmall">
      <Text size="h3">{label}</Text>
    </Box>
  )
}

export default React.memo(TaskSectionHeader)
