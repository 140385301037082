import React from 'react'
import {
  ActivityIndicator as CoreIndicator,
  ActivityIndicatorProps as CoreProps,
} from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { useIsMounted } from '@/hooks/components'

interface ActivityIndicatorProps extends Omit<CoreProps, 'color'> {
  color: ColorName
  delay?: number
}

function ActivityIndicator({ color, delay, ...coreProps }: ActivityIndicatorProps) {
  const [show, setShow] = React.useState(!delay)
  const isMountedRef = useIsMounted()
  const delayRef = React.useRef(delay)

  React.useEffect(() => {
    if (delayRef.current) {
      setTimeout(() => {
        if (isMountedRef.current) {
          setShow(true)
        }
      }, delayRef.current)
      delayRef.current = undefined
    }
  }, [setShow, isMountedRef])

  if (show) {
    return <CoreIndicator {...coreProps} color={getColor(color)} />
  }
  return null
}

export default React.memo(ActivityIndicator)
