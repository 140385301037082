import { ProductVersion, Space, SpaceAccountType, User } from '@/types/entities'
import * as D from './dates'

export const getInitials = (space: Space): string => {
  const words = space.name.split(' ')
  if (words.length > 1) {
    return `${words[0][0]}${words[1][0]}`
  }
  return space.name.slice(0, 2)
}

export const isOwner = (space: Space, user: User): boolean => space.ownerId === user.id

export const getAccountStatus = (accountType: SpaceAccountType): string => {
  switch (accountType) {
    case 'FREE_TRIAL':
    case 'PAID_FREE_TRIAL':
      return 'Free Trial'
    case 'CUSTOMER':
      return 'Active'
    case 'TESTING':
      return 'Test Account'
    case 'DEMO':
      return 'Demo Account'
    case 'INTERNAL':
      return 'Internal Account'
    default:
      throw new Error(`Unrecognized account type: ${accountType}`)
  }
}

export const isUnpaidTrial = (accountType: SpaceAccountType) => accountType === 'FREE_TRIAL'

export const isTrial = (accountType: SpaceAccountType) =>
  accountType === 'FREE_TRIAL' || accountType === 'PAID_FREE_TRIAL'

export const canHaveMultipleUsers = (productVersion: ProductVersion) =>
  productVersion !== 'ASSISTANT_PRO'

export const daysRemaining = (paidThroughDate: string) => {
  const { days } = D.getTimeInterval(D.now(), D.ceil(D.parse(paidThroughDate), 'day'))
  return days
}
