import React from 'react'
import RevealContainer from '@/components/animated/RevealContainer'
import { eventTypes } from '@/core/constants'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { usePublisher } from '@/hooks/pubsub'
import { notificationActions } from '@/store/actions'
import { notificationSelectors } from '@/store/selectors'
import { Notification } from '@/types/entities'
import NotificationToast from './NotificationToast'

interface NotificationToastContainerProps {
  location: 'top' | 'bottom'
  enabled: boolean
}

function NotificationToastContainer({ location, enabled }: NotificationToastContainerProps) {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = React.useState(false)
  const notification = useAppSelector(notificationSelectors.first)
  const lastNotificationRef = React.useRef(notification)
  const publisher = usePublisher()

  const handleDismiss = React.useCallback(
    (note: Notification) => {
      dispatch(notificationActions.remove(note.id))
    },
    [dispatch],
  )

  const handleAction = React.useCallback(
    (note: Notification, action: string) => {
      publisher.emit(eventTypes.NOTIFICATION_ACTION, { action, data: note.data })
    },
    [publisher],
  )

  if (notification) {
    lastNotificationRef.current = notification
  }

  React.useEffect(() => {
    setIsOpen(!!notification)
  }, [setIsOpen, notification])

  if (!enabled) {
    return null
  }

  return (
    <RevealContainer open={isOpen}>
      {lastNotificationRef.current ? (
        <NotificationToast
          location={location}
          notification={lastNotificationRef.current}
          onAction={handleAction}
          onDismiss={handleDismiss}
        />
      ) : null}
    </RevealContainer>
  )
}

export default React.memo(NotificationToastContainer)
