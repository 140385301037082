import { Task, TaskListItem } from '@/types/entities'
import { IndexKey, TaskOrderIndex } from '@/types/taskLists'
import * as D from './dates'

export const buildIndexKey = (spaceId: number, messageId: number): IndexKey =>
  `${spaceId}:${messageId}`
const itemToIndexKey = (item: TaskListItem): IndexKey => buildIndexKey(item.spaceId, item.messageId)
export const taskToIndexKey = (task: Task): IndexKey => buildIndexKey(task.spaceId, task.id)

export const addItemToIndex = (index: TaskOrderIndex, item: TaskListItem): TaskOrderIndex => {
  const key = itemToIndexKey(item)
  const entry = index[key]

  if (!entry) {
    return { ...index, [key]: { [item.taskListId]: item } }
  }

  const nextEntry = { ...entry, [item.taskListId]: item }

  return { ...index, [key]: nextEntry }
}

export const removeItemFromIndex = (index: TaskOrderIndex, item: TaskListItem): TaskOrderIndex => {
  const key = itemToIndexKey(item)
  const entry = index[key]

  if (!entry) {
    return index
  }

  const nextEntry = { ...entry }
  delete nextEntry[item.taskListId]

  return {
    ...index,
    [key]: nextEntry,
  }
}

export const indexItems = (items: TaskListItem[]): TaskOrderIndex =>
  items.reduce((index: TaskOrderIndex, item: TaskListItem) => {
    const key = itemToIndexKey(item)
    const entry = index[key]

    if (!entry) {
      return {
        ...index,
        [key]: { [item.taskListId]: item },
      }
    }

    entry[item.taskListId] = item
    return index
  }, {})

export const isInList = (item: TaskListItem) => !!item && item.orderingValue !== null
export const isIgnoredInList = (item?: TaskListItem, basisTimestamp?: string) =>
  !!item && !!item.ignoreUntil && item.ignoreUntil > (basisTimestamp || D.nowISO())

export const isTaskInList = (taskOrderIndex: TaskOrderIndex, taskListId: number, task: Task) => {
  const key = taskToIndexKey(task)
  const entry = taskOrderIndex[key]?.[taskListId]
  return isInList(entry)
}

export const isTaskIgnoredForList = (
  taskOrderIndex: TaskOrderIndex,
  taskListId: number,
  task: Task,
  basisTimestamp?: string,
) => {
  const key = taskToIndexKey(task)
  const entry = taskOrderIndex[key]?.[taskListId]

  return isIgnoredInList(entry, basisTimestamp)
}
