import React from 'react'
import PromptContext from '@/contexts/PromptContext'
import { PromptArgs } from '@/types/prompt'
import PromptContainerModal from './PromptContainerModal'

interface PromptContainerProps {
  children: React.ReactNode
}

function PromptContainer({ children }: PromptContainerProps) {
  const [promptArgs, setPromptArgs] = React.useState<PromptArgs | null>(null)
  const showPrompt = React.useCallback((args: PromptArgs) => setPromptArgs(args), [setPromptArgs])
  const handleClose = React.useCallback(() => setPromptArgs(null), [setPromptArgs])

  return (
    <PromptContext.Provider value={showPrompt}>
      {children}
      <PromptContainerModal onClose={handleClose} promptArgs={promptArgs} />
    </PromptContext.Provider>
  )
}

export default React.memo(PromptContainer)
