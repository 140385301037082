import React from 'react'
import { noOpWarn } from '@/core/logging'
import { Message } from '@/types/entities'
import { Action } from '@/types/generics'

export type MessageActionsContextType = {
  convertToTask: Action<Message>
  copyText: Action<Message>
  deleteMessage: Action<Message>
  editMessage: Action<Message>
  isDisplayedInThread: boolean
  showActions: Action<Message>
  showThread: Action<Message>
}

const noOp = noOpWarn('message actions context not present')

const defaultValue: MessageActionsContextType = {
  convertToTask: noOp,
  copyText: noOp,
  deleteMessage: noOp,
  editMessage: noOp,
  isDisplayedInThread: false,
  showActions: noOp,
  showThread: noOp,
}

const MessageActionsContext = React.createContext<MessageActionsContextType>(defaultValue)

export const useMessageActionsContext = () => React.useContext(MessageActionsContext)

export default MessageActionsContext
