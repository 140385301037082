import { OrderingBounds } from '@/types/ordering'
import { rangeCount } from './collections'

export const spreadEvenly = (bounds: OrderingBounds, count: number): number[] => {
  const [lbound, ubound] = bounds

  if (lbound === null) {
    return rangeCount(count, (ubound as number) - count)
  }

  if (ubound === null) {
    return rangeCount(count, lbound + 1)
  }

  const step = (ubound - lbound) / (count + 1)

  return rangeCount(count, lbound + step, step)
}
