import React from 'react'
import { StyleSheet, View } from 'react-native'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import { getColor } from '@/core/constants/colors'
import { fileUtils } from '@/core/utils'
import { Measurement } from '@/types/components'
import { File } from '@/types/entities'
import { GenericFileMode } from './types'

interface GenericFileListItemProps {
  file: File
  size: Measurement
  mode: GenericFileMode
}

function GenericFileListItem({ file, size, mode }: GenericFileListItemProps) {
  const handlePress = React.useCallback(() => fileUtils.shareOrDownloadFile(file.url), [file])

  if (mode === 'SQUARE') {
    return (
      <Touchable feedback="opacity" onPress={handlePress}>
        <View style={[size, styles.container, styles.square]}>
          <Text>{fileUtils.getFilename(file.filename)}</Text>
        </View>
      </Touchable>
    )
  }

  return (
    <Touchable feedback="opacity" onPress={handlePress}>
      <View style={[{ maxWidth: size.width, width: size.width }, styles.container, styles.compact]}>
        <Icon name="file-alt" size="h4" />
        <View style={styles.text}>
          <Text ellipsizeMode="tail" numberOfLines={1}>
            {fileUtils.getFilename(file.filename)}
          </Text>
        </View>
      </View>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  compact: {
    flexDirection: 'row',
    padding: 10,
  },
  container: {
    backgroundColor: getColor('heather'),
    borderRadius: 5,
  },
  square: {
    justifyContent: 'flex-start',
    padding: 5,
  },
  text: { flex: 1, marginLeft: 10 },
})

export default React.memo(GenericFileListItem)
