import React from 'react'
import { Text as CoreText, TextProps as CoreTextProps } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { getFontFamily, getFontSize, getLineHeight } from '@/core/constants/typography'
import {
  FontSize,
  FontWeight,
  TextAlign,
  TextDecorationLine,
  TextTransform,
} from '@/types/typography'

export interface TextProps extends Omit<CoreTextProps, 'style'> {
  align?: TextAlign
  children: React.ReactNode
  color?: ColorName
  decoration?: TextDecorationLine
  italic?: boolean
  letterSpacing?: number
  lineHeight?: number
  size?: FontSize | number
  textTransform?: TextTransform
  weight?: FontWeight
}

function Text({
  align = 'left',
  children,
  color = 'obsidian',
  decoration = 'none',
  italic = false,
  letterSpacing,
  lineHeight,
  size = 'h4',
  textTransform,
  weight = 'regular',
  ...textProps
}: TextProps) {
  const style = React.useMemo(
    () =>
      getStyle({
        colorName: color,
        italic,
        letterSpacing,
        lineHeightOverride: lineHeight,
        size,
        textAlign: align,
        textDecorationLine: decoration,
        textTransform,
        weight,
      }),
    [weight, italic, size, color, align, decoration, lineHeight, textTransform, letterSpacing],
  )

  return (
    <CoreText style={style} {...textProps}>
      {children}
    </CoreText>
  )
}

type StyleProps = {
  textAlign: TextAlign
  colorName: ColorName
  italic: boolean
  letterSpacing?: number
  lineHeightOverride?: number
  size: FontSize | number
  textDecorationLine: TextDecorationLine
  textTransform?: TextTransform
  weight: FontWeight
}

const getStyle = ({
  textAlign,
  colorName,
  italic,
  letterSpacing,
  lineHeightOverride,
  size,
  textDecorationLine,
  textTransform,
  weight,
}: StyleProps) => {
  const fontFamily = getFontFamily(weight, italic)
  const fontSize = getFontSize(size)
  const lineHeight = lineHeightOverride ?? getLineHeight(size)
  const color = getColor(colorName)

  return {
    color,
    fontFamily,
    fontSize,
    letterSpacing,
    lineHeight,
    textAlign,
    textDecorationLine,
    textTransform,
  }
}

export default React.memo(Text)
