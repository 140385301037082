import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { dateUtils, scopeUtils } from '@/core/utils'
import { Myself, Scope, UserAttribute } from '@/types/entities'
import { RootState } from '../index'

type UserAttributes = Record<string, string | undefined>

interface AuthState {
  accessToken: string | null
  expiresAt: number | null
  refreshToken: string | null
  me: Myself | null
  myAttributes: UserAttributes
  hasExpoDeviceToken: boolean
  initialized: boolean
  scopes: Scope[] | null
}

const initialState: AuthState = {
  accessToken: null,
  expiresAt: null,
  hasExpoDeviceToken: false,
  initialized: false,
  me: null,
  myAttributes: {},
  refreshToken: null,
  scopes: null,
}

const mapAttributes = (me: Myself): UserAttributes => {
  const { attributes } = me

  if (!attributes) {
    return {}
  }
  return Object.fromEntries(attributes.map(attr => [attr.attribute, attr.value]))
}

const authSlice = createSlice({
  initialState,
  name: 'auth',
  /* eslint-disable no-param-reassign */
  reducers: {
    clear: state => {
      state.accessToken = null
      state.expiresAt = null
      state.refreshToken = null
      state.me = null
      state.hasExpoDeviceToken = false
      state.initialized = false
    },
    setAuth: (
      state,
      action: PayloadAction<{
        accessToken: string
        expiresAt: number
        refreshToken: string
        user: Myself
        scopes: Scope[] | null
      }>,
    ) => {
      const { accessToken, expiresAt, refreshToken, user, scopes } = action.payload

      state.accessToken = accessToken
      state.expiresAt = expiresAt
      state.me = user
      state.myAttributes = mapAttributes(user)
      state.refreshToken = refreshToken
      state.scopes = scopes
    },
    setHasExpoDeviceToken: (state, action: PayloadAction<boolean>) => {
      state.hasExpoDeviceToken = action.payload
    },
    setInitialized: state => {
      state.initialized = true
    },
    setMe: (state, action: PayloadAction<Myself>) => {
      state.me = action.payload
      state.myAttributes = mapAttributes(action.payload)
    },
  },
  /* eslint-enable */
})

const selectAccessToken = (state: RootState) => state.auth.accessToken
const selectRefreshToken = (state: RootState) => state.auth.refreshToken
const selectMe = (state: RootState) => state.auth.me
const selectScopes = (state: RootState) => state.auth.scopes
const selectMyId = (state: RootState) => state.auth.me?.id
const selectIsSignedIn = (state: RootState) => {
  if (!state.auth.accessToken || !state.auth.expiresAt) {
    return false
  }
  return state.auth.expiresAt > dateUtils.nowSeconds()
}

const selectCanRefresh = (state: RootState) => !!state.auth.refreshToken
const selectIsInitialized = (state: RootState) => state.auth.initialized && selectCanRefresh(state)
const selectNeedsRefresh = (state: RootState) => {
  const { expiresAt } = state.auth
  if (expiresAt === null) {
    return false
  }
  return expiresAt - dateUtils.nowSeconds() < 60
}
const selectHasExpoDeviceToken = (state: RootState) => !!state.auth.hasExpoDeviceToken
const hasScopeSelector = (subject: string, action: string, subjectId: string | null = null) =>
  createSelector(selectScopes, scopes => {
    // If we don't have any scope information assume they have permission
    if (!scopes) {
      return true
    }
    const matcher = scopeUtils.scopeMatcher(subject, action, subjectId)
    return scopes.some(matcher)
  })
const selectAttributeValues = (state: RootState) => state.auth.myAttributes || {}
const selectAttributeValue = (state: RootState, attribute: UserAttribute) =>
  state.auth.myAttributes?.[attribute] || null

const selectors = {
  accessToken: selectAccessToken,
  attributeValue: selectAttributeValue,
  attributeValues: selectAttributeValues,
  couldBeSignedIn: selectCanRefresh,
  hasExpoDeviceToken: selectHasExpoDeviceToken,
  hasScopeSelector,
  isInitialized: selectIsInitialized,
  isSignedIn: selectIsSignedIn,
  me: selectMe,
  myId: selectMyId,
  needsRefresh: selectNeedsRefresh,
  refreshToken: selectRefreshToken,
}

const { actions, reducer } = authSlice

export { actions, selectors }
export default reducer
