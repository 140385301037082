import React from 'react'
import { StyleSheet } from 'react-native'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { isWeb } from '@/core/utils/platform'
import { AlignItems, Gap, JustifyContent } from '@/types/components'
import Background from './Background'
import Hoverable from './Hoverable'
import Touchable from './Touchable'

export interface ListItemProps {
  alignItems?: AlignItems
  justifyContent?: JustifyContent
  backgroundColor?: ColorName
  borderColor?: ColorName
  hoverColor?: ColorName
  children: React.ReactNode
  disabled?: boolean
  gap?: Gap
  gapHorizontal?: Gap
  gapVertical?: Gap
  inset?: Gap
  onPress?: () => void
  // Due to how swipeable item renders items we need to flex the background
  swipeable?: boolean
  borderTopRadius?: number | undefined
  borderBottomRadius?: number | undefined
}

const getInset = (
  inset: Gap | undefined,
  gapHorizontal: Gap | undefined,
  gap: Gap | undefined,
): Gap | undefined => {
  if (inset !== undefined) {
    return inset
  }
  if (gapHorizontal !== undefined) {
    return gapHorizontal
  }
  return gap
}

function ListItem({
  alignItems = 'center',
  justifyContent = 'flex-start',
  backgroundColor = 'ghost',
  borderColor = 'heather',
  hoverColor = 'heather',
  borderBottomRadius,
  borderTopRadius,
  children,
  disabled = false,
  gap = 'medium',
  gapHorizontal,
  gapVertical,
  inset,
  onPress,
  swipeable = false,
}: ListItemProps) {
  return (
    <Hoverable style={isWeb ? styles.hoverable : undefined}>
      {hovering => (
        <Background
          borderBottomLeftRadius={borderBottomRadius}
          borderBottomRightRadius={borderBottomRadius}
          borderTopLeftRadius={borderTopRadius}
          borderTopRightRadius={borderTopRadius}
          color={hovering ? hoverColor : backgroundColor}
          flex={swipeable ? 1 : undefined}
        >
          <Touchable disabled={disabled} feedback="highlight" onPress={onPress}>
            <Box gapLeft={getInset(inset, gapHorizontal, gap)}>
              <Background borderBottomColor={borderColor} borderBottomWidth={1}>
                <Box
                  alignItems={alignItems}
                  direction="row"
                  gapAll={gap}
                  gapHorizontal={gapHorizontal}
                  gapLeft={inset === 'none' || inset === 0 ? undefined : 0}
                  gapVertical={gapVertical}
                  justifyContent={justifyContent}
                >
                  {children}
                </Box>
              </Background>
            </Box>
          </Touchable>
        </Background>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  hoverable: {
    flex: 1,
  },
})

export default React.memo(ListItem)
