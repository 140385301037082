import React from 'react'
import { useAlertContext } from '@/contexts/AlertContext'
import { actionUtils } from '@/core/utils'
import { useToggle } from '@/hooks'
import { useActionsAsync } from '@/hooks/actions'
import { useUnmount } from '@/hooks/state'
import { useIsQuestion } from '@/hooks/tasks'
import { ActionOptionAsync } from '@/types/actions'
import { SummaryItem } from '@/types/entities'
import { useEditSummaryContext } from '../../EditSummaryContext'
import { AttachToMessageType } from '../../types'

const attachItemArgs = { title: 'Attach to message...' }
const attachItemOptions: ActionOptionAsync<AttachToMessageType, null>[] = [
  // { label: 'Header', value: 'HEADER' },
  { label: 'Task', value: 'TASK' },
  { label: 'Question', value: 'QUESTION' },
  { label: 'Cancel', type: 'cancel', value: 'CANCEL' },
]

export const useSummaryItem = (
  summaryItem: SummaryItem,
  headerId: number | null,
  index: number,
) => {
  const { id: summaryItemId } = summaryItem
  const { setEditingItemId, editingItemId, draggingHeaderId } = useEditSummaryContext()
  const isEditing = editingItemId === summaryItemId
  const headerIsDragging = draggingHeaderId && draggingHeaderId === headerId

  const handleStartEditing = React.useCallback(() => {
    setEditingItemId({ index, itemId: summaryItemId })
  }, [setEditingItemId, summaryItemId, index])

  return { handleStartEditing, headerIsDragging, isEditing }
}

export const useViewSummaryItem = (summaryItem: SummaryItem) => {
  const { id: summaryItemId } = summaryItem
  const { deleteSummaryItem } = useEditSummaryContext()
  const handleDelete = React.useCallback(async () => {
    deleteSummaryItem(summaryItemId)
  }, [summaryItemId, deleteSummaryItem])

  return { handleDelete }
}

export const useEditSummaryItem = (summaryItem: SummaryItem) => {
  const { id: summaryItemId, spaceId, taskId: currentTaskId } = summaryItem
  const { setEditingItemId, updateSummaryItem, deleteSummaryItem, selectTasks } =
    useEditSummaryContext()

  const [content, setContent] = React.useState(summaryItem.content)
  const showAttachItemOptions = useActionsAsync(attachItemOptions, attachItemArgs)

  const [canAcknowledge, toggleCanAcknowledge] = useToggle(summaryItem.canAcknowledge)
  const [canComment, setCanComment] = React.useState(summaryItem.canComment)
  const [taskId, setTaskId] = React.useState(summaryItem.taskId)
  const isQuestion = useIsQuestion(spaceId, taskId || 0)

  const hasContentRef = React.useRef(!!content)
  React.useEffect(() => {
    if (!hasContentRef.current && !!content) {
      setCanComment(true)
    } else if (hasContentRef.current && !content) {
      setCanComment(false)
    }
    hasContentRef.current = !!content
  }, [setCanComment, content])

  const showAlert = useAlertContext()
  const toggleCanComment = React.useCallback(() => {
    if (hasContentRef.current) {
      setCanComment(val => !val)
    } else {
      actionUtils.showNoActionAlert(
        showAlert,
        'Cannot Enable Commenting',
        'You must write a description to enable commenting',
      )
    }
  }, [setCanComment, showAlert])

  const hasChanges =
    summaryItem.content !== content ||
    summaryItem.taskId !== taskId ||
    summaryItem.canAcknowledge !== canAcknowledge ||
    canComment !== summaryItem.canComment

  const canUpdate = !!content || !!taskId

  const syncState = React.useCallback(() => {
    setContent(summaryItem.content)
    setTaskId(summaryItem.taskId)
    toggleCanAcknowledge(summaryItem.canAcknowledge)
    setCanComment(summaryItem.canComment)
  }, [summaryItem, setContent, toggleCanAcknowledge, setCanComment])

  const handleUpdate = React.useCallback(async () => {
    setEditingItemId(null)
  }, [setEditingItemId])

  const handleDelete = React.useCallback(async () => {
    deleteSummaryItem(summaryItemId)
    setEditingItemId(null)
  }, [summaryItemId, deleteSummaryItem, setEditingItemId])

  const handleCancel = React.useCallback(() => {
    syncState()
    setTimeout(() => setEditingItemId(null))
  }, [syncState, setEditingItemId])

  const handleAttachTask = React.useCallback(async () => {
    const taskIds = await selectTasks({ currentTaskId, multiple: false, taskType: 'TASK' })

    setTaskId(taskIds[0] || null)
  }, [selectTasks, setTaskId, currentTaskId])

  const handleAttachQuestion = React.useCallback(async () => {
    const taskIds = await selectTasks({ currentTaskId, multiple: false, taskType: 'QUESTION' })

    if (taskIds.length === 0) {
      setTaskId(null)
    } else {
      setTaskId(taskIds[0])
      toggleCanAcknowledge(false)
    }
  }, [selectTasks, setTaskId, toggleCanAcknowledge, currentTaskId])

  const handlePromptAttach = React.useCallback(async () => {
    const addType = await showAttachItemOptions(null)

    if (addType === 'TASK') {
      handleAttachTask()
    }
    if (addType === 'QUESTION') {
      handleAttachQuestion()
    }
  }, [showAttachItemOptions, handleAttachTask, handleAttachQuestion])

  const handleRemoveTask = React.useCallback(() => {
    if (isQuestion) {
      toggleCanAcknowledge(true)
    }
    setTaskId(null)
  }, [isQuestion, toggleCanAcknowledge, setTaskId])

  const doUpdate = React.useCallback(() => {
    if (!canUpdate) {
      return
    }

    const turnOffCommenting = canComment && !content

    if (hasChanges || turnOffCommenting) {
      updateSummaryItem(summaryItemId, {
        canAcknowledge,
        canComment: turnOffCommenting ? false : canComment,
        content,
        taskId,
      })
      if (turnOffCommenting) {
        setCanComment(false)
      }
    }
  }, [
    canAcknowledge,
    canComment,
    canUpdate,
    content,
    hasChanges,
    summaryItemId,
    taskId,
    updateSummaryItem,
  ])

  useUnmount(doUpdate)

  return {
    canAcknowledge,
    canComment,
    canUpdate,
    content,
    handleAttachQuestion,
    handleAttachTask,
    handleCancel,
    handleDelete,
    handlePromptAttach,
    handleRemoveTask,
    handleUpdate,
    hasQuestionAttached: isQuestion,
    hasTaskAttached: !!taskId,
    setContent,
    taskId,
    toggleCanAcknowledge,
    toggleCanComment,
  }
}
