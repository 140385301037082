import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Background from '@/components/core/Background'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import StackContextContainer from '@/components/screens/StackContextContainer'
import TagsList from './TagsList'
import { useTagSelector } from './hooks'

interface TaskTagsModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskTagsModal({ isOpen, onClose, localModal = false }: TaskTagsModalProps) {
  const {
    createTagAction,
    handleCancel,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isSearching,
    searchText,
    selectedIds: selectedTagIds,
    setSearchText,
    tags,
  } = useTagSelector(onClose, isOpen)

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Tags"
    >
      <StackContextContainer>
        <Box direction="column" flex={1}>
          <Background
            borderBottomColor="heather"
            borderBottomWidth={1}
            borderTopColor="heather"
            borderTopWidth={1}
          >
            <Box alignItems="center" direction="row" gapAll="medium">
              <Box flex={1}>
                <TextInput
                  kind="bare"
                  onChangeText={setSearchText}
                  placeholder={
                    tags.length === 0 ? 'Type a new tag name' : 'Search tags or type a new tag name'
                  }
                  value={searchText || undefined}
                />
              </Box>
              {isSearching ? <ActivityIndicator color="obsidian" /> : null}
            </Box>
          </Background>
          <Box flex={1} gapBottom="medium">
            <TagsList
              createTagAction={createTagAction}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onSelect={handleSelect}
              selectedTagIds={selectedTagIds}
              tags={tags}
            />
          </Box>
        </Box>
      </StackContextContainer>
    </StandardModal>
  )
}

export default React.memo(TaskTagsModal)
