import React from 'react'
import ProfilePicture, { ProfilePictureProps } from '@/components/core/ProfilePicture'
import { userUtils } from '@/core/utils'
import { useCurrentUser } from '@/hooks/auth'
import { useUser } from '@/hooks/users'
import { User } from '@/types/entities'

type BaseProps = Omit<ProfilePictureProps, 'url' | 'initials'>
interface UserProps extends BaseProps {
  user: User | undefined | null
}
interface UserIdProps extends BaseProps {
  spaceId: number
  userId: number
}
interface CurrentUserProps extends BaseProps {
  currentUser: true
}
type UserProfilePictureProps = UserProps | UserIdProps | CurrentUserProps

function UserPicture({ user, ...profilePictureProps }: UserProps) {
  return (
    <ProfilePicture
      {...profilePictureProps}
      initials={user ? userUtils.getInitials(user) : '?'}
      url={user ? user.profilePictureUrl : null}
    />
  )
}

function UserIdPicture({ spaceId, userId, ...profilePictureProps }: UserIdProps) {
  const user = useUser(spaceId, userId)
  return <UserPicture user={user} {...profilePictureProps} />
}

function CurrentUserPicture(profilePictureProps: CurrentUserProps) {
  const user = useCurrentUser()
  return <UserPicture user={user} {...profilePictureProps} />
}

function UserProfilePicture(props: UserProfilePictureProps) {
  if ('user' in props) {
    return <UserPicture {...props} />
  }
  if ('currentUser' in props) {
    return <CurrentUserPicture {...props} />
  }
  return <UserIdPicture {...props} />
}

export default React.memo(UserProfilePicture)
