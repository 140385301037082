const palette = {
  'CompletedSummaryCyan': '#2795B1',
  'ModalBG': '#00000080',
  'PrivateTasksHeader': '#62965a',
  'SummaryButtonFill': '#FFFAED',
  'SummaryHeader': '#BFD4AD',
  'SummaryListItemBackground': '#eff6f9',
  'TheirDayBackground': '#fde9b9',
  'anchor': '#6B7F99',
  'azureish': '#D6E1F1',
  'azureish+1': '#C9D5E7',
  'black': '#000000',
  'blue': '#225CFF',
  'cheddar': '#FFC107',
  'cinnamon': '#D2691E',
  'cinnamon-1': '#DD9C63',
  'cinnamon-2': '#E5B58B',
  'cinnamon-3': '#EFD3B9',
  'cyan': '#00BCD4',
  'dark-blue': '#1944BF',
  'dark-cheddar': '#FB9005',
  'dark-cyan': '#008C9E',
  'dark-green': '#0B8F24',
  'dark-indigo': '#2F3C87',
  'dark-magenta': '#A22292',
  'dark-orange': '#BF7100',
  'dark-purple': '#592BA2',
  'dark-red': '#BF3441',
  'dark-rust': '#B44D28',
  'dark-teal': '#007065',
  'dark-yellow': '#BFAD15',
  'emmre-blue': '#1A8EFF',
  'emmre-blue+1': '#177FE5',
  'emmre-blue+2': '#0262c4',
  'emmre-blue+3': '#1263B2',
  'emmre-blue+4': '#0F5599',
  'emmre-blue+5': '#0C467F',
  'emmre-blue+6': '#0A3866',
  'emmre-blue+7': '#072A4C',
  'emmre-blue+8': '#051C33',
  'emmre-blue+9': '#020E19',
  'emmre-blue-1': '#3099FF',
  'emmre-blue-2': '#47A4FF',
  'emmre-blue-3': '#5EAFFF',
  'emmre-blue-4': '#75BBFF',
  'emmre-blue-5': '#8CC6FF',
  'emmre-blue-6': '#A3D1FF',
  'emmre-blue-7': '#BADDFF',
  'emmre-blue-8': '#D1E8FF',
  'emmre-blue-9': '#E8F3FF',
  'emmre-green': '#4FB240',
  'emmre-green+9': '#479040',
  'emmre-green-8': '#D7EBD2',
  'emmre-green-9': '#EBF1EE',
  'emmre-purple': '#6B3FB2',
  'emmre-purple+3': '#5E3B9E',
  'emmre-purple+4': '#370B7D',
  'emmre-purple-9': '#ECE8F7',
  'emmre-yellow': '#FFD367',
  'ghost': '#FBFCFFFF',
  'ghost-15%': 'rgba(251, 252, 255, 0.15)',
  'ghost-25%': 'rgba(251, 252, 255, 0.25)',
  'ghost-50%': 'rgba(251, 252, 255, 0.50)',
  'ghost-75%': 'rgba(251, 252, 255, 0.75)',
  'ghost-90%': 'rgba(251, 252, 255, 0.90)',
  'ghost-95%': 'rgba(251, 252, 255, 0.95)',
  'green': '#10BF31',
  'heather': '#E8EAF1',
  'indigo': '#3F51B5',
  'iron': '#959DB2',
  'koala': '#B9BED0',
  'light-blue': '#C7D6FF',
  'light-border': '#F0F1F2',
  'light-cheddar': '#FFEFC0',
  'light-cyan': '#E2F6FB',
  'light-green': '#C3EECB',
  'light-indigo': '#CED3EC',
  'light-magenta': '#F5CAF0',
  'light-orange': '#FFE5BF',
  'light-purple': '#DCCDF5',
  'light-red': '#FFD0D5',
  'light-rust': '#FBD8CC',
  'light-teal': '#BFE4E1',
  'light-yellow': '#FDF6E3',
  'magenta': '#D92EC4',
  'medium-indigo': '#7487F4',
  'obsidian': '#131E40',
  'obsidian-10%': '#131E4019',
  'obsidian-20%': '#131E4032',
  'orange': '#FF9800',
  'pastel-blue': '#E9EFFF',
  'pastel-cheddar': '#FFF8E6',
  'pastel-cyan': '#E6F8FA',
  'pastel-green': '#E7F8EA',
  'pastel-indigo': '#ECEDF7',
  'pastel-magenta': '#FBEAF9',
  'pastel-orange': '#FFF4E6',
  'pastel-purple': '#F1EBFB',
  'pastel-red': '#FFECEE',
  'pastel-rust': '#FDF0EB',
  'pastel-teal': '#E6F4F3',
  'pastel-yellow': '#FFFCE8',
  'purple': '#773AD9',
  'red': '#FF4658',
  'rust': '#F16736',
  'shadow': '#364962',
  'silver': '#F5F6FA',
  'slack': '#3b1940',
  'teal': '#009688',
  'trail': '#B2A43F',
  'translucent': '#FFF6',
  'translucent-emmre-blue': '#64aaff',
  'transparent': '#FFFFFF00',
  'white': '#FFFFFF',
  'yellow': '#FFE81D',
}

export type ColorName = keyof typeof palette

function getColor(color: ColorName | undefined): string | undefined {
  return color ? palette[color] : undefined
}

export { getColor }
