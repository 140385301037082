import { Message, ReadReceipt } from '@/types/entities'

export const indexReadReceiptsByMessageId = (
  messages: Message[],
  readReceipts: ReadReceipt[],
): Record<number, ReadReceipt[]> => {
  const readReceiptIndex: Record<number, ReadReceipt[]> = {}

  readReceipts.forEach(readReceipt => {
    // Messages must be in descending ID order
    // TODO: Is this a problem for tasks converted from messages where the creator IDs do not match?
    const readMessages = messages.filter(
      message =>
        message.creatorId !== readReceipt.userId && message.id <= readReceipt.maxReadMessageId,
    )
    if (readMessages.length === 0) {
      return
    }
    const { id } = readMessages[0]
    if (!(id in readReceiptIndex)) {
      readReceiptIndex[id] = []
    }
    readReceiptIndex[id].push(readReceipt)
  })

  return readReceiptIndex
}
