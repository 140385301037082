import { Task } from '@/types/entities'
import { TaskOrderIndex } from '@/types/taskLists'
import * as D from '../dates'
import * as taskListUtils from '../taskLists'

type RelatedTaskDataArgs = {
  taskOrderIndex: TaskOrderIndex
}

export class RelatedTaskData {
  data: RelatedTaskDataArgs
  timestamp: string

  constructor(data: RelatedTaskDataArgs) {
    this.data = data
    this.timestamp = D.nowISO()
  }

  isTaskInList(taskListId: number, task: Task): boolean {
    return taskListUtils.isTaskInList(this.data.taskOrderIndex, taskListId, task)
  }

  isTaskIgnoredForList(taskListId: number, task: Task): boolean {
    return taskListUtils.isTaskIgnoredForList(
      this.data.taskOrderIndex,
      taskListId,
      task,
      this.timestamp,
    )
  }
}
