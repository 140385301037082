import React from 'react'
import { easeInOut } from '@/core/utils/animations'
import { InputMode } from '@/types/messaging'

interface MessageInputState {
  inputMode: InputMode
  isExpanded: boolean
}

const nextInputMode: Record<InputMode, InputMode> = {
  message: 'task',
  question: 'message',
  task: 'question',
}

type MessageInputAction =
  | { type: 'toggleMode' }
  | { type: 'setMode'; payload: InputMode }
  | { type: 'toggleExpanded' }
  | { type: 'setExpanded'; payload: boolean }

const messageInputReducer = (
  state: MessageInputState,
  action: MessageInputAction,
): MessageInputState => {
  const { type } = action

  if (type === 'toggleMode') {
    const inputMode = nextInputMode[state.inputMode]
    return { ...state, inputMode, isExpanded: false }
  }

  if (type === 'setMode') {
    return { ...state, inputMode: action.payload }
  }

  if (type === 'toggleExpanded') {
    const isExpanded = !state.isExpanded
    return { ...state, isExpanded }
  }

  if (type === 'setExpanded') {
    const isExpanded = action.payload

    if (state.isExpanded === isExpanded) {
      return state
    }

    return { ...state, isExpanded }
  }

  return state
}

export const useFormState = (initialMode: InputMode) => {
  const [{ isExpanded, inputMode }, dispatch] = React.useReducer(messageInputReducer, {
    inputMode: initialMode,
    isExpanded: false,
  })

  const toggleInputMode = React.useCallback(() => {
    easeInOut()
    dispatch({ type: 'toggleMode' })
  }, [dispatch])
  const setInputMode = React.useCallback(
    (newMode: InputMode) => {
      easeInOut()
      dispatch({ payload: newMode, type: 'setMode' })
    },
    [dispatch],
  )
  const setIsExpanded = React.useCallback(
    (payload: boolean) => dispatch({ payload, type: 'setExpanded' }),
    [dispatch],
  )
  const toggleIsExpanded = React.useCallback(() => {
    easeInOut()
    dispatch({ type: 'toggleExpanded' })
  }, [dispatch])

  return {
    inputMode,
    isExpanded,
    setInputMode,
    setIsExpanded,
    toggleInputMode,
    toggleIsExpanded,
  }
}
