import React from 'react'
import { StyleSheet, View } from 'react-native'
import { fnUtils } from '@/core/utils'
import { Measure } from '@/types/components'
import Text from '../Text'
import Touchable from '../Touchable'

type Report<T> = (value: T, measure: Measure) => void

interface ChoiceProps<T extends string> {
  disabled: boolean
  label: string
  measureRelativeToComponentRef: React.RefObject<any>
  onRender: Report<T>
  onSelect: Report<T>
  value: T
}

function Choice<T extends string = string>({
  value,
  label,
  onSelect,
  onRender,
  measureRelativeToComponentRef,
  disabled,
}: ChoiceProps<T>) {
  const choiceRef = React.useRef<View | null>(null)
  const handleReport = React.useCallback(
    (callback: Report<T>) => {
      if (!measureRelativeToComponentRef.current) {
        return
      }

      choiceRef.current?.measureLayout(
        measureRelativeToComponentRef.current,
        (x, y, width, height) => callback(value, { height, width, x, y }),
        fnUtils.noOp,
      )
    },
    [measureRelativeToComponentRef, choiceRef, value],
  )
  const handlePress = React.useCallback(() => handleReport(onSelect), [handleReport, onSelect])

  React.useEffect(() => handleReport(onRender))

  return (
    <Touchable disabled={disabled} feedback="none" onPress={handlePress}>
      <View ref={choiceRef} style={styles.container}>
        <Text color={disabled ? 'iron' : 'obsidian'} size="h5">
          {label}
        </Text>
      </View>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
})

export default React.memo(Choice) as typeof Choice
