import React from 'react'
import FileDropArea from '@/components/files/FileDropArea'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormChat, useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { TaskFormInteractionMode, useTaskFormContext } from '@/contexts/TaskFormContext'
import { platformUtils } from '@/core/utils'
import { fileToDataURI } from '@/core/utils/files/utils'
import { useAttachments } from '@/hooks/messages'
import { TaskFormBody as TaskFormBodyType, TaskFormSize } from '@/types/tasks'
import TaskChannelLine from '../TaskChannelLine'
import TaskDetailButtons from '../TaskDetailButtons'
import TaskFormAcceptLine from '../TaskFormAcceptLine'
import TaskFormActionsLine from '../TaskFormActionsLine'
import TaskFormBody from '../TaskFormBody'
import TaskFormChat from '../TaskFormChat'
import TaskFormHandle from '../TaskFormHandle'
import TaskFormSizeContainer from '../TaskFormSizeContainer'
import TaskFormSizeSwipeContainer from '../TaskFormSizeSwipeContainer'
import TaskFormSubmitLine from '../TaskFormSubmitLine'
import TaskMyDayAssigneeLine from '../TaskMyDayAssigneeLine'
import TaskTitleInput from '../TaskTitleInput'
import { TaskFormMode } from '../types'

interface TaskFormContentProps {
  initialTaskFormBody: TaskFormBodyType
  interactionMode: TaskFormInteractionMode
  mode: TaskFormMode
  taskFormSize: TaskFormSize
}

function TaskFormContent({
  mode,
  initialTaskFormBody,
  taskFormSize,
  interactionMode,
}: TaskFormContentProps) {
  const { handleFocus, handleToggleChat, showChat } = useTaskFormChat(initialTaskFormBody)
  const spaceId = useTaskFormValue(fieldNames.spaceId)
  const { formName } = useTaskFormContext()
  const { handleAddAttachment } = useAttachments(spaceId, formName, fieldNames.attachmentIds)

  const handleFilesDropped = React.useCallback(
    async (files: File[]) => {
      const dataUris = await Promise.all(files.map(fileToDataURI))
      await Promise.all(dataUris.map(dataUri => handleAddAttachment(dataUri)))
    },
    [handleAddAttachment],
  )

  return (
    <FileDropArea onFilesDropped={handleFilesDropped}>
      <TaskFormSizeSwipeContainer>
        {mode !== 'SCREEN' ? (
          <>
            <TaskFormHandle />
            <TaskFormSizeContainer large={taskFormActionsLine} />
          </>
        ) : null}
        <TaskFormSizeContainer mediumLarge={taskChannelLine} />
        <TaskTitleInput fastMode={platformUtils.isWeb && taskFormSize !== 'LARGE'} />
        <TaskFormSizeContainer large={taskFormAcceptLine} />
      </TaskFormSizeSwipeContainer>
      <TaskFormSizeContainer
        mediumLarge={taskMyDayAssigneeLine}
        small={taskMyDayAssigneeSubmitLine}
      />
      <TaskFormSizeContainer large={taskDetailButtons} medium={taskDetailSubmitButtons} />
      <TaskFormSizeContainer large={taskFormBody(interactionMode === 'create' || !showChat)} />
      <TaskFormSizeContainer
        large={taskFormChat(
          initialTaskFormBody === 'COMMENTS',
          showChat,
          handleFocus,
          handleToggleChat,
        )}
      />
    </FileDropArea>
  )
}

const taskFormActionsLine = () => <TaskFormActionsLine />
const taskChannelLine = () => <TaskChannelLine />
const taskFormAcceptLine = () => <TaskFormAcceptLine />
const taskMyDayAssigneeLine = () => <TaskMyDayAssigneeLine />
const taskMyDayAssigneeSubmitLine = () => (
  <TaskFormSubmitLine alignSubmit="flex-end" gapTop={false}>
    <TaskMyDayAssigneeLine wrapped />
  </TaskFormSubmitLine>
)
const taskDetailButtons = () => <TaskDetailButtons isModal showAttachments showMore />
const taskDetailSubmitButtons = () => (
  <TaskFormSubmitLine>
    <TaskDetailButtons showAttachments wrapped />
  </TaskFormSubmitLine>
)
const taskFormBody = (isExpanded: boolean) => () => <TaskFormBody isExpanded={isExpanded} />
const taskFormChat =
  (autoFocus: boolean, isExpanded: boolean, onFocus: () => void, onToggleIsExpanded: () => void) =>
  () =>
    (
      <TaskFormChat
        autoFocus={autoFocus}
        isExpanded={isExpanded}
        onFocus={onFocus}
        onToggleIsExpanded={onToggleIsExpanded}
      />
    )

export default React.memo(TaskFormContent)
