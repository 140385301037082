import React from 'react'
import { apiUtils } from '@/core/utils'
import { useAppDispatch, useCurrentUserId } from '@/hooks'
import { useChannel } from '@/hooks/channels'
import { summaryThunks } from '@/thunks'
import { Summary } from '@/types/entities'
import { ShareStatus } from './types'

export const useShareSummaryModal = (summary: Summary) => {
  const { spaceId, id: summaryId, channelId } = summary

  const [url, setUrl] = React.useState<string | null>(null)
  const [status, setStatus] = React.useState<ShareStatus>('INITIALIZING')
  const [message, setMessage] = React.useState<string | null>(null)
  const [selectedUserId, setSelectedUserId] = React.useState<number | null>(null)

  const dispatch = useAppDispatch()
  const myId = useCurrentUserId()
  const channel = useChannel(spaceId, channelId)

  const createSummaryShare = React.useCallback(
    async (withUserId: number) => {
      setStatus('PENDING')

      const response = await dispatch(
        summaryThunks.createSummaryShare(spaceId, summaryId, { withUserId }),
      )

      if (response.ok) {
        setStatus('SUCCESS')
        setUrl(response.data.url)
      } else {
        setStatus('ERROR')
        setMessage(apiUtils.getGeneralError(response.errors) || null)
      }
    },
    [dispatch, spaceId, summaryId, setStatus, setUrl, setMessage],
  )
  const initialize = React.useCallback(() => {
    if (!channel) {
      setStatus('ERROR')
      setMessage('Invalid hub')
    } else {
      setStatus('SELECT_USER')
      if (channel.userIds.length === 2) {
        const otherUserId = channel.userIds.find(userId => userId !== myId)
        if (otherUserId) {
          setSelectedUserId(otherUserId)
          createSummaryShare(otherUserId)
        }
      }
    }
  }, [setStatus, setMessage, setSelectedUserId, channel, createSummaryShare, myId])

  const handleCreateSummaryShare = React.useCallback(() => {
    if (!selectedUserId) {
      return
    }
    createSummaryShare(selectedUserId)
  }, [selectedUserId, createSummaryShare])

  React.useEffect(() => {
    initialize()
  }, [initialize])

  return {
    handleCreateSummaryShare,
    message,
    selectedUserId,
    setSelectedUserId,
    status,
    url,
  }
}
