import React from 'react'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import EditMessageForm from '@/components/messages/EditMessageForm'
import { forms } from '@/core/constants'
import { useAppDispatch } from '@/hooks'
import { messageThunks } from '@/thunks'
import { SpaceEntityIds } from '@/types/entities'

interface EditMessageModalProps {
  messageIds?: SpaceEntityIds
  onClose: () => void
}

const formName = forms.EDIT_MESSAGE

function EditMessageModal({ messageIds, onClose }: EditMessageModalProps) {
  const dispatch = useAppDispatch()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleSubmit = React.useCallback(async () => {
    if (!messageIds) {
      return
    }

    setIsSubmitting(true)

    const ok = await dispatch(
      messageThunks.editMessageForm(messageIds.spaceId, messageIds.id, formName),
    )

    setIsSubmitting(false)

    if (ok) {
      onClose()
    }
  }, [messageIds, dispatch, onClose])

  return (
    <ModalLayout
      leftAction={React.useMemo(
        () => ({ disabled: isSubmitting, label: 'Cancel', onAction: onClose, type: 'linkPrimary' }),
        [onClose, isSubmitting],
      )}
      rightAction={React.useMemo(
        () => ({
          disabled: isSubmitting,
          label: 'Update',
          onAction: handleSubmit,
          type: 'linkPrimary',
        }),
        [handleSubmit, isSubmitting],
      )}
      title="Edit Message"
    >
      <Box gapAll="medium">
        <EditMessageForm
          autoFocus
          formName={formName}
          messageId={messageIds?.id || 0}
          spaceId={messageIds?.spaceId || 0}
        />
      </Box>
    </ModalLayout>
  )
}

export default React.memo(EditMessageModal)
