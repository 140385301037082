import React from 'react'
import { useUnmount } from '@/hooks/state'
import type { OnKeyPress } from '@/types/components'
import { useEditSummaryContext } from '../../EditSummaryContext'
import { SummaryFieldName } from '../../types'

export const useField = (fieldName: SummaryFieldName, index: number) => {
  const { setEditingItemId, editingItemId } = useEditSummaryContext()
  const isEditing = editingItemId === fieldName

  const handleStartEditing = React.useCallback(() => {
    setEditingItemId({ index, itemId: fieldName })
  }, [setEditingItemId, fieldName, index])

  return { handleStartEditing, isEditing }
}

export const useEditField = (fieldName: SummaryFieldName, content: string | null) => {
  const { setEditingItemId, updateSummary } = useEditSummaryContext()
  const [newContent, setNewContent] = React.useState(content)
  const hasChanges = content !== newContent

  const doUpdate = React.useCallback(() => {
    if (!hasChanges) {
      return
    }

    if (fieldName === 'title' && !newContent) {
      return
    }

    updateSummary({ [fieldName]: newContent })
  }, [fieldName, newContent, updateSummary, hasChanges])

  const handleDoneEditing = React.useCallback(() => {
    setEditingItemId(null)
  }, [setEditingItemId])

  const handleKeyPress = React.useCallback<OnKeyPress>(
    ({ nativeEvent }) => {
      if (nativeEvent.key === 'Escape') {
        setNewContent(content)
        setEditingItemId(null)
      }
    },
    [setNewContent, setEditingItemId, content],
  )

  useUnmount(doUpdate)

  React.useEffect(() => {
    setNewContent(content)
  }, [setNewContent, content])

  return {
    handleDoneEditing,
    handleKeyPress,
    newContent,
    setNewContent,
  }
}
