import { taskListActions, taskListItemActions } from '@/store/actions'
import { authSelectors, taskListSelectors } from '@/store/selectors'
import { TaskList, TaskListItem } from '@/types/entities'
import { Thunk } from '@/types/store'
import { getAllTaskListItems } from './api'

export const storeTaskList =
  (taskList: TaskList, force = false): Thunk<boolean> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState()) || 0
    const existingTaskList = taskListSelectors.byId(getState(), taskList.id)

    const store = force || taskList.userId === myId || !!existingTaskList

    if (!store) {
      return false
    }

    dispatch(taskListActions.upsertOne(taskList))

    if (!existingTaskList || existingTaskList.updatedAt < taskList.updatedAt) {
      await dispatch(getAllTaskListItems(taskList.id))
    }

    return true
  }

export const storeTaskListItem =
  (item: TaskListItem): Thunk<boolean> =>
  async (dispatch, getState) => {
    const { taskListId } = item
    const taskList = taskListSelectors.byId(getState(), taskListId)

    if (taskList) {
      dispatch(taskListItemActions.addItem({ item }))
      return true
    }

    return false
  }
