import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'

type SpaceConfig = {
  lastSelectedChannelId: number | null
}

export type SpacesScreenConfig = {
  messagesPanePosition: 'LEFT' | 'RIGHT'
  showMessagesPane: 'UNSET' | 'YES' | 'NO'
  fixedColumnWidth: number
}

export type SummariesListScreenConfig = {
  showCompleted: boolean
}

interface AppProfileState {
  focusedChannelId: number | null
  focusedSpaceId: number | null
  spacesConfig: Record<number, SpaceConfig>
  spacesScreenConfig: SpacesScreenConfig
  summariesListScreenConfig: SummariesListScreenConfig
}

const initialState: AppProfileState = {
  focusedChannelId: null,
  focusedSpaceId: null,
  spacesConfig: {},
  spacesScreenConfig: {
    fixedColumnWidth: 400,
    messagesPanePosition: 'RIGHT',
    showMessagesPane: 'UNSET',
  },
  summariesListScreenConfig: {
    showCompleted: false,
  },
}

const appProfileSlice = createSlice({
  initialState,
  name: 'appProfile',
  /* eslint-disable no-param-reassign */
  reducers: {
    focusOnChannel: (
      state,
      action: PayloadAction<{ spaceId: number; channelId: number | null }>,
    ) => {
      const { channelId, spaceId } = action.payload

      if (spaceId === state.focusedSpaceId) {
        state.focusedChannelId = channelId
      }

      if (channelId) {
        const config = state.spacesConfig[spaceId]

        if (!config) {
          state.spacesConfig[spaceId] = { lastSelectedChannelId: channelId }
        } else {
          config.lastSelectedChannelId = channelId
        }
      }
    },
    focusOnSpace: (state, action: PayloadAction<{ spaceId: number }>) => {
      state.focusedSpaceId = action.payload.spaceId
    },
    reset: state => {
      state.focusedSpaceId = null
      state.spacesConfig = {}
    },
    selectChannel: (
      state,
      action: PayloadAction<{ spaceId: number; channelId: number | null }>,
    ) => {
      const { channelId, spaceId } = action.payload

      if (spaceId === state.focusedSpaceId) {
        state.focusedChannelId = channelId
      }

      const config = state.spacesConfig[spaceId]

      if (!config) {
        state.spacesConfig[spaceId] = { lastSelectedChannelId: channelId }
      } else {
        config.lastSelectedChannelId = channelId
      }
    },
    switchPanes: state => {
      const config = state.spacesScreenConfig
      const position = config.messagesPanePosition

      return {
        ...state,
        spacesScreenConfig: {
          ...config,
          messagesPanePosition: position === 'LEFT' ? 'RIGHT' : 'LEFT',
        },
      }
    },
    updateSpacesScreenConfig: (state, action: PayloadAction<Partial<SpacesScreenConfig>>) => {
      state.spacesScreenConfig = { ...state.spacesScreenConfig, ...action.payload }
    },
    updateSummariesListScreenConfig: (
      state,
      action: PayloadAction<Partial<SummariesListScreenConfig>>,
    ) => {
      state.summariesListScreenConfig = { ...state.summariesListScreenConfig, ...action.payload }
    },
  },
  /* eslint-enable */
})

type AppFocus = {
  spaceId: number
  channelId: number | null
}

const selectFocusedSpaceId = (state: RootState) => state.appProfile.focusedSpaceId || null
const selectLastFocus = (state: RootState, spaceId: number): AppFocus => {
  const {
    appProfile: { spacesConfig },
  } = state

  const config = spacesConfig[spaceId]

  return config
    ? { channelId: config.lastSelectedChannelId, spaceId }
    : { channelId: null, spaceId }
}
const selectCurrentFocus = (state: RootState): AppFocus => {
  const {
    appProfile: { focusedSpaceId, focusedChannelId },
  } = state

  return { channelId: focusedChannelId, spaceId: focusedSpaceId || 0 }
}

const selectedSpacesScreenConfig = (state: RootState) => state.appProfile.spacesScreenConfig
const selectSummariesListScreenConfig = (state: RootState) =>
  state.appProfile.summariesListScreenConfig

const selectors = {
  currentFocus: selectCurrentFocus,
  focusedSpaceId: selectFocusedSpaceId,
  lastFocus: selectLastFocus,
  spacesScreenConfig: selectedSpacesScreenConfig,
  summariesListScreenConfig: selectSummariesListScreenConfig,
}

const { actions, reducer } = appProfileSlice

export { actions, selectors }
export default reducer
