import React from 'react'
import { useAppDispatch } from '@/hooks'
import { useHubs } from '@/hooks/channels'
import { channelThunks } from '@/thunks'

export const useArchivedHubsModal = (spaceId: number, onClose: () => void) => {
  const archivedHubs = useHubs(spaceId, 'ARCHIVED', false)
  const dispatch = useAppDispatch()

  const handleUnarchiveHub = React.useCallback(
    (channelId: number) => {
      dispatch(channelThunks.archiveChannel(spaceId, channelId))
    },
    [spaceId, dispatch],
  )

  const archivedHubsCount = archivedHubs.length

  React.useEffect(() => {
    if (archivedHubsCount === 0) {
      onClose()
    }
  }, [archivedHubsCount, onClose])

  return {
    archivedHubs,
    handleUnarchiveHub,
  }
}
