import React from 'react'
import { TaskFormInteractionMode } from '@/contexts/TaskFormContext'
import { taskUtils } from '@/core/utils'
import { getQuestionMode } from '@/core/utils/tasks/questions'
import {
  useAppDispatch,
  useAppSelector,
  useCachedSelector,
  useCurrentUserId,
  useFormExists,
} from '@/hooks'
import { usePersonalDefaultChannel } from '@/hooks/channels'
import { useSentinelEffect } from '@/hooks/state'
import { formActions } from '@/store/actions'
import { channelSelectors, entitySelectors, taskSelectors } from '@/store/selectors'
import { taskThunks } from '@/thunks'
import { QuestionMode, TaskDefaults, TaskFormContext } from '@/types/tasks'
import { useQuestionMode } from './question'
import { getDefaultQuestionFields, getFormName, taskToFormFields } from './utils'

interface TaskFormHookArgs {
  formContext: TaskFormContext
  initialQuestionMode?: QuestionMode
  shouldInitializeForm?: boolean
  spaceId: number
  taskDefaults?: TaskDefaults
  taskId?: number
}

export const useTaskForm = ({
  formContext,
  initialQuestionMode = 'YES_NO',
  shouldInitializeForm = true,
  spaceId,
  taskDefaults,
  taskId,
}: TaskFormHookArgs) => {
  const dispatch = useAppDispatch()
  const currentUserId = useCurrentUserId()
  const [formInitialized, setFormInitialized] = React.useState(false)

  const defaultSpaceId = spaceId
  const defaultChannelId = taskDefaults?.channelId || 0
  const defaultChannel = useAppSelector(channelSelectors.byId, defaultSpaceId, defaultChannelId)
  const defaultUserId = (() => {
    if (defaultChannel && defaultChannel.userIds.length === 2) {
      const [first, second] = defaultChannel.userIds

      return first !== currentUserId ? first : second
    }
    return currentUserId
  })()
  const personalDefaultChannel = usePersonalDefaultChannel(spaceId)

  const editTask = useAppSelector(taskSelectors.byId, spaceId, taskId || 0)
  const isMyDay = useCachedSelector(entitySelectors.isInMyDaySelector, [
    editTask
      ? { task: editTask, type: 'task' }
      : { taskListId: taskDefaults?.taskListId, type: 'defaults' },
  ])

  let interactionMode: TaskFormInteractionMode

  if (editTask) {
    if (taskUtils.canEditTask()) {
      interactionMode = 'edit'
    } else {
      interactionMode = 'view'
    }
  } else {
    interactionMode = 'create'
  }

  const formName = getFormName({
    channel: defaultChannel,
    context: formContext,
    spaceId,
    task: editTask,
  })
  const [questionMode, setQuestionMode] = useQuestionMode(
    editTask && editTask.question ? getQuestionMode(editTask.question) : initialQuestionMode,
    formName,
    false,
  )
  const formExists = useFormExists(formName)
  const initializeForm = shouldInitializeForm || !formExists

  const emptyTask = React.useMemo(
    () => ({
      acceptedAt: null,
      assignedUserId: defaultUserId,
      attachmentIds: [],
      boardId: null,
      channelId: defaultChannelId || personalDefaultChannel?.id,
      checklistItems: [],
      completedAt: null,
      content: null,
      dueDate: null,
      dueDateType: 'NONE',
      isMyDay,
      notes: null,
      remindAt: null,
      remindAtOffset: null,
      spaceId: defaultSpaceId,
      status: 'ACTIVE',
      tagIds: [],
      urgency: 'MEDIUM',
      ...getDefaultQuestionFields(questionMode),
    }),
    [
      defaultUserId,
      defaultSpaceId,
      personalDefaultChannel,
      defaultChannelId,
      isMyDay,
      questionMode,
    ],
  )

  const resetForm = React.useCallback(
    (overrideFields: Record<string, any> = {}) => {
      if (editTask) {
        dispatch(
          formActions.initialize({
            fields: { ...taskToFormFields(editTask), isMyDay, ...overrideFields },
            formName,
          }),
        )
      } else if (taskDefaults) {
        dispatch(
          formActions.initialize({
            fields: {
              ...emptyTask,
              ...taskDefaults,
              ...overrideFields,
            },
            formName,
          }),
        )
      } else {
        dispatch(
          formActions.initialize({
            fields: { ...emptyTask, ...overrideFields },
            formName,
          }),
        )
      }
    },
    [dispatch, editTask, emptyTask, taskDefaults, formName, isMyDay],
  )

  React.useEffect(() => {
    if (!formInitialized && initializeForm) {
      resetForm()
    }
    if (!formInitialized) {
      setFormInitialized(true)
    }
  }, [initializeForm, formInitialized, resetForm, setFormInitialized])

  const mergeUpdatedTaskToForm = React.useCallback(() => {
    if (editTask) {
      dispatch(taskThunks.mergeUpdatedTaskToForm(editTask, formName))
    }
  }, [editTask, formName, dispatch])

  useSentinelEffect(mergeUpdatedTaskToForm, editTask?.updatedAt)

  return {
    formInitialized,
    formName,
    interactionMode,
    questionMode,
    resetForm,
    setQuestionMode,
    task: editTask,
  }
}
