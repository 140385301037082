import React from 'react'
import Icon from '@/components/core/Icon'
import { ColorName } from '@/core/constants/colors'
import { IconKind, IconName } from '@/core/constants/icons'
import { Task } from '@/types/entities'
import { Pair } from '@/types/generics'
import { TaskDetail } from '@/types/tasks'
import TaskChecklistDetailItem from './TaskChecklistDetailItem'

interface TaskDetailListItemProps {
  color?: ColorName
  size?: number
  task: Task
  taskDetail: TaskDetail
}

const iconNames: Record<TaskDetail, Pair<IconName, IconKind> | undefined> = {
  ASSIGNED_USER: undefined,
  ATTACHMENTS: ['paperclip', 'regular'],
  BOARD: ['th-list', 'solid'],
  CHANNEL: undefined,
  CHECKLIST_ITEMS: ['list-ul', 'regular'],
  DUE_DATE: ['calendar-check', 'regular'],
  HIGH_PRIORITY: ['star', 'regular'],
  LOW_PRIORITY: ['arrow-down-alt', 'regular'],
  NOTES: ['align-left', 'regular'],
  RECURRENCE: ['repeat', 'regular'],
  REMINDER: ['bell', 'regular'],
  TAGS: ['hashtag', 'regular'],
}

function TaskDetailListItem({
  task,
  taskDetail,
  color = 'koala',
  size = 16,
}: TaskDetailListItemProps) {
  if (taskDetail === 'CHECKLIST_ITEMS') {
    return <TaskChecklistDetailItem color={color} size={size} task={task} />
  }
  const pair = iconNames[taskDetail]
  if (!pair) {
    return null
  }
  const [iconName, iconKind] = pair
  return <Icon color={color} kind={iconKind} name={iconName} size={size} />
}

export default React.memo(TaskDetailListItem)
