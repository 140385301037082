import React from 'react'
import { ColorName } from '@/core/constants/colors'
import { taskUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'
import MultipleChoiceResponseContainer from './MultipleChoiceResponseContainer'
import OpenEndedResponseContainer from './OpenEndedResponseContainer'
import YesNoResponseContainer from './YesNoResponseContainer'
import { QuestionDisabled } from './types'

interface QuestionResponseFormProps {
  disabled: QuestionDisabled
  foregroundColor: ColorName
  onAnswered?: () => void
  task: QuestionTask
}

function QuestionResponseForm({
  task,
  foregroundColor,
  disabled,
  onAnswered,
}: QuestionResponseFormProps) {
  const questionMode = taskUtils.getQuestionMode(task.question)

  if (questionMode === 'YES_NO') {
    return <YesNoResponseContainer disabled={disabled} onAnswered={onAnswered} task={task} />
  }
  if (questionMode === 'MULTIPLE_CHOICE') {
    return (
      <MultipleChoiceResponseContainer
        disabled={disabled}
        foregroundColor={foregroundColor}
        onAnswered={onAnswered}
        task={task}
      />
    )
  }
  if (questionMode === 'OPEN_ENDED') {
    return <OpenEndedResponseContainer disabled={disabled} onAnswered={onAnswered} task={task} />
  }
  return null
}

export default React.memo(QuestionResponseForm)
