import React from 'react'
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import Background from '@/components/core/Background'
import { getColor } from '@/core/constants/colors'
import { LayoutPosition } from '@/types/components'
import Box from './Box'

interface MenuLayoutProps {
  children: React.ReactNode
  layout: LayoutPosition
  width?: number
  onClose: () => void
}

function MenuLayout({ children, layout, width, onClose }: MenuLayoutProps) {
  const { topStyle, leftStyle, rightStyle, bottomStyle } = React.useMemo(
    () => getDynamicStyles(layout),
    [layout],
  )

  // 2022-06-07: Setting animationType to "none" messes up text input on web for some reason
  return (
    <Pressable onPress={onClose} style={styles.background}>
      <View style={topStyle} />
      <View style={styles.contentRow}>
        <View style={leftStyle} />
        <Background
          borderColor="koala"
          borderRadius={8}
          borderWidth={1}
          color="ghost"
          width={width}
        >
          <Box gapAll="medium">{children}</Box>
        </Background>
        <View style={rightStyle} />
      </View>
      <View style={bottomStyle} />
    </Pressable>
  )
}

const getDynamicStyles = (layout: LayoutPosition) => {
  const topStyle: ViewStyle = {}
  const bottomStyle: ViewStyle = {}
  const leftStyle: ViewStyle = {}
  const rightStyle: ViewStyle = {}

  if (layout.vertical.pin === 'TOP') {
    topStyle.height = layout.vertical.offset
    bottomStyle.flex = 1
  } else {
    topStyle.flex = 1
    bottomStyle.height = layout.vertical.offset
  }

  if (layout.horizontal.pin === 'LEFT') {
    leftStyle.width = layout.horizontal.offset
    rightStyle.flex = 1
  } else {
    leftStyle.flex = 1
    rightStyle.width = layout.horizontal.offset
  }

  return {
    bottomStyle,
    leftStyle,
    rightStyle,
    topStyle,
  }
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: getColor('transparent'),
    flex: 1,
  },
  contentRow: {
    flexDirection: 'row',
  },
})

export default React.memo(MenuLayout)
