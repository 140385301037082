import { batch } from 'react-redux'
import { messageUtils, taskUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import {
  boardActions,
  messageActions,
  tagActions,
  taskActions,
  threadActions,
} from '@/store/actions'
import { ReceivedMessage, ReceivedTask } from '@/types/apiEntities'
import { Board, SentMessage, Tag, Task, TaskListItem, Thread } from '@/types/entities'
import { SyncThunk } from '@/types/store'

export const addMessage = (apiMessage: ReceivedMessage): SyncThunk<void> =>
  addMessages([apiMessage])

export const addMessages =
  (receivedMessages: ReceivedMessage[]): SyncThunk<void> =>
  dispatch => {
    if (receivedMessages.length === 0) {
      return
    }

    easeInOut()
    const threads: Thread[] = []
    const messages: SentMessage[] = []

    receivedMessages.forEach(apiMessage => {
      const { thread, message } = messageUtils.normalizeMessage(apiMessage)
      if (thread) {
        threads.push(thread)
      }
      messages.push(message)
    })

    batch(() => {
      dispatch(messageActions.upsertMany(messages))
      if (threads.length !== 0) {
        dispatch(threadActions.upsertMany(threads))
      }
    })
  }

export const addTask = (receivedTask: ReceivedTask): SyncThunk<void> => addTasks([receivedTask])

export const addTasks =
  (receivedTasks: ReceivedTask[]): SyncThunk<void> =>
  dispatch => {
    if (receivedTasks.length === 0) {
      return
    }

    easeInOut()
    const addTags: Tag[] = []
    const addBoards: Board[] = []
    const addThreads: Thread[] = []
    const addTaskListItems: { task: Task; items: TaskListItem[] }[] = []
    const tasks: Task[] = []

    receivedTasks.forEach(receivedTask => {
      const { task, tags, board, thread, taskListItems } = taskUtils.normalizeTask(receivedTask)

      tasks.push(task)

      if (tags) {
        addTags.push(...tags)
      }
      if (board) {
        addBoards.push(board)
      }
      if (thread) {
        addThreads.push(thread)
      }
      if (taskListItems) {
        addTaskListItems.push({ items: taskListItems, task })
      }
    })

    batch(() => {
      dispatch(taskActions.upsertMany(tasks))

      if (addTags.length !== 0) {
        dispatch(tagActions.upsertMany(addTags))
      }
      if (addBoards.length !== 0) {
        dispatch(boardActions.upsertMany(addBoards))
      }
      if (addThreads.length !== 0) {
        dispatch(threadActions.upsertMany(addThreads))
      }

      // TODO: This doesn't work right now that we have other people's lists
      //       Also I'm not sure we need it.
      //
      //       To make it work we'd need to only clear out items from the user's
      //       own task list
      // addTaskListItems.map(({ task, items }) =>
      //   dispatch(
      //     taskListItemActions.setItemsForTask({
      //       items,
      //       messageId: task.id,
      //       spaceId: task.spaceId,
      //     }),
      //   ),
      // )
    })
  }
