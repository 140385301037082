import React from 'react'
import { dateUtils } from '@/core/utils'

type ThrottleLockResult<T> = { status: 'THROTTLED' } | { status: 'EXECUTED'; result: T }

export const useThrottleLock = <T>(
  callback: (secondsSinceLastRun: number) => Promise<T>,
  throttleSeconds: number,
): (() => Promise<ThrottleLockResult<T>>) => {
  const lastRunTimestamp = React.useRef(0)
  const isRunningRef = React.useRef(false)

  return React.useCallback(async () => {
    const now = dateUtils.nowSeconds()
    const deltaSeconds = now - lastRunTimestamp.current

    if (deltaSeconds <= throttleSeconds) {
      return { status: 'THROTTLED' }
    }

    if (isRunningRef.current) {
      return { status: 'THROTTLED' }
    }

    lastRunTimestamp.current = now
    try {
      isRunningRef.current = true
      const result = await callback(deltaSeconds)
      return { result, status: 'EXECUTED' }
    } finally {
      isRunningRef.current = false
    }
  }, [callback, throttleSeconds])
}
