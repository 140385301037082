import React from 'react'
import { useEmojiPicker } from '@/hooks/emoji'
import { messageThunks } from '@/thunks'
import { Message } from '@/types/entities'
import { useAppDispatch } from '../redux'

export const useMessageReactions = () => {
  const dispatch = useAppDispatch()
  const pickEmoji = useEmojiPicker()

  const setMessageReaction = React.useCallback(
    (message: Message, reaction: string | null) => {
      const { spaceId, id: messageId } = message
      if (reaction) {
        dispatch(messageThunks.reactToMessage(spaceId, messageId, reaction))
      } else {
        dispatch(messageThunks.removeMessageReaction(spaceId, messageId))
      }
    },
    [dispatch],
  )

  const pickMessageReaction = React.useCallback(
    async (message: Message) => {
      const emoji = await pickEmoji()

      if (emoji) {
        setMessageReaction(message, emoji.symbol)
      }
    },
    [pickEmoji, setMessageReaction],
  )

  return { pickMessageReaction, setMessageReaction }
}
