import React from 'react'
import { collectionUtils } from '@/core/utils'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { formActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'
import { User } from '@/types/entities'
import UsersSelector, { UsersSelectorProps } from './UsersSelector'

interface FormUsersSelectorProps
  extends Omit<UsersSelectorProps, 'selectedUserIds' | 'onSetSelection' | 'onClear'> {
  formName: string
  fieldName: string
  onChange?: (user: User, selected: boolean) => void
}

function FormUsersSelector({
  formName,
  fieldName,
  onChange,
  ...usersSelectorProps
}: FormUsersSelectorProps) {
  const dispatch = useAppDispatch()
  const userIdsField = useAppSelector(formSelectors.field, formName, fieldName)
  const [searchText, setSearchText] = React.useState('')
  const handleSetSelection = React.useCallback(
    (user: User, selected: boolean) => {
      if (selected) {
        dispatch(formActions.addMember({ fieldName, formName, value: user.id }))
      } else {
        dispatch(formActions.removeMember({ fieldName, formName, value: user.id }))
      }
      if (onChange) {
        onChange(user, selected)
      }
    },
    [dispatch, formName, fieldName, onChange],
  )

  return (
    <UsersSelector
      {...usersSelectorProps}
      onChangeSearchText={setSearchText}
      onSetSelection={handleSetSelection}
      searchText={searchText}
      selectedUserIds={collectionUtils.toArray<number>(userIdsField.value)}
    />
  )
}

export default React.memo(FormUsersSelector)
