import React from 'react'
import SimpleSelector, { ChoiceType } from '@/components/core/SimpleSelector'
import {
  buildBaseFilter,
  buildChannelTasksFilter,
  buildRelationshipFilter,
} from '@/core/utils/tasks/filters'
import { useCurrentUserId } from '@/hooks'
import { useHubUser } from '@/hooks/channels'
import { useFilteredTasksCount } from '@/hooks/tasks/filtering'
import { Channel } from '@/types/entities'
import { TaskRelationship, TaskType } from '@/types/tasks'
import Choice from './Choice'

interface TaskRelationshipSelectorProps {
  channel?: Channel
  taskRelationship: TaskRelationship
  taskType: TaskType
  onSetTaskRelationship: (relationship: TaskRelationship) => void
}

function TaskRelationshipSelector({
  channel,
  taskType,
  taskRelationship,
  onSetTaskRelationship,
}: TaskRelationshipSelectorProps) {
  const channelId = channel?.id || 0
  const spaceId = channel?.spaceId || 0
  const myId = useCurrentUserId() || 0
  const hubUser = useHubUser(spaceId, channel?.userIds || [])
  const subject = taskType === 'TASK' ? 'Tasks' : 'Questions'
  const filter = React.useMemo(
    () =>
      buildRelationshipFilter(
        'MINE',
        myId,
        buildChannelTasksFilter({ id: channelId, spaceId }, buildBaseFilter(spaceId, taskType)),
      ),
    [spaceId, channelId, taskType, myId],
  )
  const count = useFilteredTasksCount(filter)

  const choices = React.useMemo<ChoiceType<TaskRelationship>[]>(
    () => [
      {
        label: <Choice count={count} icon="square-check" label={`My ${subject}`} />,
        value: 'MINE',
      },
      {
        label: (
          <Choice label={hubUser ? `${subject} for ${hubUser.firstName}` : `Watching ${subject}`} />
        ),
        value: 'WATCHING',
      },
      { label: <Choice label={`All ${subject}`} />, value: 'ALL' },
    ],
    [hubUser, subject, count],
  )

  return (
    <SimpleSelector choices={choices} onChange={onSetTaskRelationship} value={taskRelationship} />
  )
}

export default React.memo(TaskRelationshipSelector)
