import { ColorName } from '@/core/constants/colors'
import {
  NewSpaceMemberRole,
  SpaceMemberRole,
  SpaceMembershipType,
  SpaceUser,
  User,
} from '@/types/entities'

export const buildUserId = (spaceId: number, userId: number): string => `${spaceId}:${userId}`

export const getFullName = (user: User): string => {
  const { firstName, lastName } = user
  if (lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  return firstName
}

export const getFirstName = (user: User, plural = false) => {
  const { firstName } = user

  if (plural) {
    if (firstName.endsWith('s')) {
      return `${firstName}'`
    }
    return `${firstName}'s`
  }
  return firstName
}

export const getInitials = (user: User): string => {
  const { firstName, lastName } = user

  return lastName ? `${firstName[0]}${lastName[0]}` : firstName[0]
}

export const getMembershipTypeLabel = (membershipType: SpaceMembershipType): string => {
  switch (membershipType) {
    case 'ACTIVE':
      return 'Active'
    case 'INACTIVE':
      return 'Inactive'
    case 'INVITED':
      return 'Invited'
    case 'NOT_INVITED':
      return 'Not Invited'
    default:
      throw new Error(`Unrecotgnized space membership type: ${membershipType}`)
  }
}

export const membershipTypeToColor = (membershipType: SpaceMembershipType): ColorName => {
  switch (membershipType) {
    case 'ACTIVE':
      return 'obsidian'
    case 'INACTIVE':
      return 'red'
    case 'INVITED':
      return 'green'
    case 'NOT_INVITED':
      return 'emmre-purple'
    default:
      throw new Error(`Unrecotgnized space membership type: ${membershipType}`)
  }
}

export const canBeInvited = (user: SpaceUser) => user.membershipType !== 'ACTIVE'

export const flipRole = (role: string | null): SpaceMemberRole | null => {
  if (!role) {
    return null
  }
  return isExecutiveAssistant(role as SpaceMemberRole) ? 'LEADER' : 'ASSISTANT'
}

export const getMemberRoleLabel = (role: SpaceMemberRole) => {
  if (isExecutive(role)) {
    return 'Executive'
  }
  if (isExecutiveAssistant(role)) {
    return 'Executive Assistant'
  }
  return 'Team Member'
}

export const isRole = (check: NewSpaceMemberRole, role: SpaceMemberRole) =>
  equivalents.some(s => s.has(check) && s.has(role))

export const isExecutive = (role: SpaceMemberRole) => isRole('EXECUTIVE', role)
export const isExecutiveAssistant = (role: SpaceMemberRole) => isRole('EXECUTIVE_ASSISTANT', role)
export const isTeamMember = (role: SpaceMemberRole) => isRole('TEAM_MEMBER', role)
export const normalizeRole = (role: SpaceMemberRole): NewSpaceMemberRole => {
  if (role === 'ASSISTANT') {
    return 'EXECUTIVE_ASSISTANT'
  }
  if (role === 'LEADER') {
    return 'EXECUTIVE'
  }
  if (role === 'OTHER') {
    return 'TEAM_MEMBER'
  }
  return role
}

const equivalents: Set<SpaceMemberRole>[] = [
  new Set(['ASSISTANT', 'EXECUTIVE_ASSISTANT']),
  new Set(['LEADER', 'EXECUTIVE']),
  new Set(['OTHER', 'TEAM_MEMBER']),
]

export const isActive = (membershipType: SpaceMembershipType) => membershipType === 'ACTIVE'

export const isInvited = (membershipType: SpaceMembershipType) => membershipType === 'INVITED'
