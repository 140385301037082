import Constants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import { camelizeKeys } from 'humps'
import { platformUtils } from '@/core/utils'
import { addMessage, addTask } from '@/thunks/store'
import {
  MessageNotificationData,
  NotificationData,
  NotificationRouting,
  TaskNotificationData,
  TaskReminderNotificationData,
} from '@/types/pushNotifications'
import { AppDispatch } from '@/types/store'

// There's a circumstance where you receive multiple tasks, tap on the push notification for one,
// and now it's the latest task in your list and you will never load the task data for other
// tasks because they're older.
const storeTaskData = false

const isMessageNotification = (
  notification: NotificationData,
): notification is MessageNotificationData => notification.type === 'message'

const isTaskNotify = (notification: NotificationData): notification is TaskNotificationData =>
  notification.type === 'task'

const isTaskReminderNotification = (
  notification: NotificationData,
): notification is TaskReminderNotificationData => notification.type === 'reminder'

const getNotificationData = (notification: Notifications.Notification): NotificationData =>
  camelizeKeys(notification.request.content.data)

export const getNotificationRouting = (
  notification: Notifications.Notification,
): NotificationRouting | null => {
  const data = getNotificationData(notification)
  if (isMessageNotification(data)) {
    const {
      message: { id: messageId, spaceId, channelId },
    } = data
    return { channelId, messageId, spaceId, subject: 'message', type: 'channel' }
  }
  if (isTaskNotify(data)) {
    const {
      task: { spaceId, channelId, id: messageId },
    } = data
    return { channelId, messageId, spaceId, subject: 'task', type: 'channel' }
  }
  if (isTaskReminderNotification(data)) {
    const {
      task: { spaceId, id: taskId },
    } = data
    return { spaceId, taskId, type: 'task' }
  }
  return null
}

// Storing notification data from taps can mess up our caching with tasks and messages. We
// load tasks based on the latest updated_at value in the store, but if three tasks come in and
// the last one is tapped then the updated_at will be after the previous two that aren't in
// storage, and we'll never load them. A more robust solution is to store timestamps for updating
// data and use that instead of timestamps. In the meantime I'm going to turn off storing data
// from notifications
const DISABLE_STORE_NOTIFICATION_DATA = true

export const storeNotificationData = (
  dispatch: AppDispatch,
  notification: Notifications.Notification,
) => {
  if (DISABLE_STORE_NOTIFICATION_DATA) {
    return
  }

  const data = getNotificationData(notification)
  if (isMessageNotification(data)) {
    // TODO: Channel Stats?
    dispatch(addMessage(data.message))
  } else if (isTaskNotify(data) && storeTaskData) {
    // TODO: Channel Stats?
    dispatch(addTask(data.task))
  }
}

export const canUsePushNotifications = () => {
  // Don't register for push notifications on the web (yet)
  if (platformUtils.isWeb) {
    return false
  }

  // Don't register for push notifications if this is an emulator
  if (!Constants.isDevice) {
    return false
  }

  return true
}
