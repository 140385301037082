import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import { amplitudeProxy } from '@/core/instrumentation'
import { dateUtils as D, taskUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import { useAppDispatch } from '@/hooks'
import { useCheckNotify } from '@/hooks/tasks'
import { taskThunks } from '@/thunks'
import { Task } from '@/types/entities'

interface TaskCompleteCheckboxProps {
  task: Task
  disabled?: boolean
}

function TaskCompleteCheckbox({ task, disabled }: TaskCompleteCheckboxProps) {
  const dispatch = useAppDispatch()
  const isCompleted = taskUtils.isCompleted(task)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const checkNotify = useCheckNotify()

  const handleTaskCompleted = React.useCallback(async () => {
    if (disabled) {
      return
    }
    setIsSubmitting(true)
    if (!isCompleted) {
      amplitudeProxy.logEvent('complete task')
    }
    const notify = await checkNotify({
      changes: { completedAt: isCompleted ? null : D.nowISO() },
      task,
    })
    easeInOut()
    const { spaceId, id: taskId } = task
    if (isCompleted) {
      await dispatch(taskThunks.uncompleteTask(spaceId, taskId, notify))
    } else {
      await dispatch(taskThunks.completeTask(spaceId, taskId, notify))
    }
    setIsSubmitting(false)
  }, [dispatch, task, disabled, isCompleted, checkNotify])

  return (
    <Checkbox
      checked={isSubmitting ? !isCompleted : isCompleted}
      disabled={disabled || isSubmitting}
      hitSlop="medium"
      onChange={handleTaskCompleted}
      shape="square"
    />
  )
}

export default React.memo(TaskCompleteCheckbox)
