import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { numberUtils as N } from '@/core/utils'
import { APIStatus } from '@/types/api'
import { ChargebeePaymentIntent } from '@/types/chargebee'
import CreditCard from './CreditCard'
import { CreditCardProps } from './CreditCard/types'

interface PaymentIntentProps extends CreditCardProps {
  status: APIStatus
  paymentIntent: ChargebeePaymentIntent | null
  errorMessage: string | null
  label?: string
}

function PaymentIntent({
  status,
  paymentIntent,
  errorMessage,
  label,
  ...creditCardProps
}: PaymentIntentProps) {
  if (status === 'READY' || status === 'PENDING') {
    return (
      <Box alignItems="center" direction="row">
        <ActivityIndicator color="obsidian" size="large" />
      </Box>
    )
  }

  if (status === 'ERROR') {
    return (
      <Text align="center" color="red">
        An error occurred when getting your payment amount
      </Text>
    )
  }

  if (paymentIntent) {
    return (
      <>
        <Text size="h5">
          {label ||
            `Payment amount: ${N.centsToDollars(paymentIntent.amount)} ${
              paymentIntent.currencyCode
            }`}
        </Text>
        <Box gapTop="small">
          <CreditCard {...creditCardProps} />
        </Box>
        {errorMessage ? (
          <Box gapTop="medium">
            <Text color="red" size="h5">
              {errorMessage}
            </Text>
          </Box>
        ) : null}
      </>
    )
  }

  return null
}

export default React.memo(PaymentIntent)
