import React from 'react'
import FloatContainer from '@/components/layouts/FloatContainer'
import NotificationContext, { useNotificationContext } from '@/contexts//NotificationContext'
import NotificationToastContainer from './NotificationToastContainer'

interface NotificationsContainerProps {
  children: React.ReactNode
  location?: 'top' | 'bottom'
}

function NotificationsContainer({ children, location = 'top' }: NotificationsContainerProps) {
  const [hasDescendant, setHasDescendant] = React.useState(false)
  const top = location === 'top' ? 0 : undefined
  const bottom = location === 'top' ? undefined : 0
  const notifyAncestor = useNotificationContext()

  // There should only be a single notification container active at a given time, so we don't get
  // weird effects. So we use context so that descendant containers can alert their next ancestor
  // that they are present (on mount / unmount) and then they also expose a function for other
  // descendants that may appear below them to let this component know that they're present and
  // will be handling notifications
  React.useEffect(() => {
    notifyAncestor(true)
    return () => notifyAncestor(false)
  }, [notifyAncestor])

  return (
    <NotificationContext.Provider value={setHasDescendant}>
      {children}
      <FloatContainer bottom={bottom} left={0} right={0} top={top}>
        <NotificationToastContainer enabled={!hasDescendant} location={location} />
      </FloatContainer>
    </NotificationContext.Provider>
  )
}

export default React.memo(NotificationsContainer)
