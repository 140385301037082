import { IconName } from '@/core/constants/icons'
import { getLineHeight } from '@/core/constants/typography'

export const fontSize = 32
export const lineHeight = getLineHeight(fontSize)
export const headerHeight = 30

export const categoryIcons: Record<string, IconName> = {
  activities: 'football',
  animals_nature: 'tree',
  flags: 'flag',
  food_drink: 'pizza-slice',
  frequently_used: 'clock-nine',
  objects: 'sunglasses',
  people_body: 'person',
  smileys_emotion: 'face-smile',
  symbols: 'recycle',
  travel_places: 'plane',
}
