import React from 'react'
import { ScrollView } from 'react-native'
import TaskAttachments from './TaskAttachments'
import TaskChecklistItems from './TaskChecklistItems'
import TaskDates from './TaskDates'
import TaskNotes from './TaskNotes'
import TaskSegments from './TaskSegments'
import TaskSlackLink from './TaskSlackLink'

interface TaskFormBodyProps {
  isExpanded: boolean
}

function TaskFormBody({ isExpanded }: TaskFormBodyProps) {
  if (!isExpanded) {
    return null
  }
  return (
    <ScrollView keyboardShouldPersistTaps="handled">
      <TaskNotes />
      <TaskDates />
      <TaskSegments />
      <TaskChecklistItems />
      <TaskAttachments />
      <TaskSlackLink />
    </ScrollView>
  )
}

export default React.memo(TaskFormBody)
