import React from 'react'
import { usePromptContext } from '@/contexts/PromptContext'
import { PromptArgs } from '@/types/prompt'

type PromptHookArgs = Omit<PromptArgs, 'onSubmit' | 'onCancel'>

export const usePromptAsync = (): ((
  args: Omit<PromptHookArgs, 'onCancel' | 'onSubmit'>,
) => Promise<string | null>) => {
  const showPrompt = usePromptContext()

  return React.useCallback(
    (args: PromptHookArgs) =>
      new Promise(resolve => {
        showPrompt({
          ...args,
          onCancel: () => resolve(null),
          onSubmit: (value: string) => resolve(value),
        })
      }),
    [showPrompt],
  )
}
