import React from 'react'
import { ScrollView } from 'react-native'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import PromptContainer from '@/components/notifications/PromptContainer'
import { forms } from '@/core/constants'
import { renderComponent } from '@/core/utils/components'
import { ComponentProp } from '@/types/components'
import { Channel } from '@/types/entities'
import ChannelUsersList from './ChannelUsersList'
import { useEditChannelModal } from './hooks'

interface EditChannelModalProps {
  spaceId: number
  channelId: number
  onClose: () => void
  onLeave: (channel: Channel) => void
  onEditUser: (userId: number) => void
  OtherControls?: ComponentProp
}

const formName = forms.EDIT_CHANNEL

function EditChannelModal({
  channelId,
  spaceId,
  onClose,
  onLeave,
  onEditUser,
  OtherControls,
}: EditChannelModalProps) {
  const {
    canEdit,
    channel,
    handleUpdate,
    hasChanges,
    isSubmitting,
    handleArchiveChannel,
    handleEditUser,
  } = useEditChannelModal(channelId, spaceId, onClose, onEditUser, onLeave, formName)

  if (!channel) {
    return null
  }

  return (
    <ModalLayout
      leftAction={
        canEdit && hasChanges
          ? { label: 'Cancel', onAction: onClose, type: 'linkPrimary' }
          : undefined
      }
      maxWidth={500}
      onCancel={onClose}
      rightAction={{
        disabled: isSubmitting,
        label: canEdit && hasChanges ? 'Update' : 'Done',
        onAction: handleUpdate,
        type: 'linkPrimary',
      }}
      title={channel.name}
    >
      {canEdit ? (
        <Box gapAll="medium">
          <FormTextInput
            autoCapitalize="words"
            autoCorrect={false}
            fieldName="name"
            formName={formName}
            label="Name"
          />
        </Box>
      ) : null}
      <Box gapAll="medium">
        <Text size="h3">Members</Text>
      </Box>
      <ScrollView keyboardShouldPersistTaps="handled">
        <PromptContainer>
          <ChannelUsersList channel={channel} compact onEditUser={handleEditUser} />
        </PromptContainer>
      </ScrollView>
      {OtherControls ? renderComponent(OtherControls) : null}
      <Background borderTopColor="heather" borderTopWidth={1}>
        <Box direction="row" gapAll="medium" justifyContent="center">
          <Button
            disabled={isSubmitting}
            kind="primary"
            label={channel.archivedAt ? 'Unarchive' : 'Archive'}
            onPress={handleArchiveChannel}
          />
        </Box>
      </Background>
    </ModalLayout>
  )
}

export default React.memo(EditChannelModal)
