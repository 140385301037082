import { userUtils } from '@/core/utils'
import { showActionAlertAsync } from '@/core/utils/actions'
import { authSelectors, channelSelectors, userSelectors } from '@/store/selectors'
import { ActionOptionAsync, ShowAlert } from '@/types/actions'
import { Task } from '@/types/entities'
import { SyncThunk, Thunk } from '@/types/store'
import { TaskNotify, TaskType } from '@/types/tasks'

type TaskChanges = Partial<Task>

const actionOptions: ActionOptionAsync<TaskNotify, null>[] = [
  { label: 'No', value: 'SILENT' },
  { label: 'Yes', value: 'NOTIFY' },
]

export const checkNotifyUpdate =
  (showAlert: ShowAlert, task: Task, changes: TaskChanges): Thunk<TaskNotify> =>
  async dispatch => {
    const canCheck = dispatch(canCheckNotify(task.spaceId, task.channelId, changes.channelId))

    if (canCheck !== 'CHECK') {
      return canCheck
    }

    const check = shouldCheck(task, changes)
    if (!check.check) {
      return 'NOTIFY'
    }

    // Otherwise ask the user if they want to notify
    return showActionAlertAsync<TaskNotify, null>(
      showAlert,
      actionOptions,
      { message: check.title, title: 'Send Notification?' },
      null,
    )
  }

export const checkNotifyCreate =
  (
    showAlert: ShowAlert,
    spaceId: number,
    channelId: number,
    taskType: TaskType,
  ): Thunk<TaskNotify> =>
  async dispatch => {
    const canCheck = dispatch(canCheckNotify(spaceId, channelId, undefined))

    if (canCheck !== 'CHECK') {
      return canCheck
    }

    const verb = taskType === 'QUESTION' ? 'asking' : 'creating'
    const subject = taskType === 'QUESTION' ? 'Question' : 'Task'

    return showActionAlertAsync<TaskNotify, null>(
      showAlert,
      actionOptions,
      { message: `For ${verb} this ${subject}`, title: 'Send Notification?' },
      null,
    )
  }

const canCheckNotify =
  (
    spaceId: number,
    channelId: number,
    nextChannelId: number | undefined,
  ): SyncThunk<TaskNotify | 'CHECK'> =>
  (dispatch, getState) => {
    const myId = authSelectors.myId(getState()) || 0
    const myUser = userSelectors.byId(getState(), spaceId, myId)
    const channel = channelSelectors.byId(getState(), spaceId, channelId)
    const nextChannel = nextChannelId
      ? channelSelectors.byId(getState(), spaceId, nextChannelId)
      : null

    if (!myUser || !channel) {
      // This shouldn't happen.
      return 'NOTIFY'
    }

    // If you're an executive then tasks always go in chat
    if (userUtils.isExecutive(myUser.role)) {
      return 'NOTIFY'
    }

    // If the task is in a channel with just you and you're not moving it or you're moving
    // it to a channel with just you, always notify
    if (channel.userIds.length <= 1 && (!nextChannel || nextChannel.userIds.length <= 1)) {
      return 'NOTIFY'
    }

    const userIds = nextChannel ? [...channel.userIds, ...nextChannel.userIds] : channel.userIds
    const users = userSelectors.byIdsSelector(spaceId, userIds)(getState())
    const othersActive = users.some(user => user.id !== myId && user.membershipType === 'ACTIVE')

    // If there are no other active users in the channel then do not put the task / question in
    // chat
    return othersActive ? 'CHECK' : 'SILENT'
  }

const shouldCheck = (
  task: Task,
  changes: TaskChanges,
): { check: true; title: string } | { check: false } => {
  if ('completedAt' in changes) {
    const willBeCompleted = !!changes.completedAt
    const isCompleted = !!task.completedAt

    if (willBeCompleted !== isCompleted) {
      return {
        check: true,
        title: willBeCompleted ? 'For completing this Task' : 'For uncompleting this Task',
      }
    }
  }

  if ('acceptedAt' in changes) {
    const willBeAccepted = !!changes.acceptedAt
    const isAccepted = !!task.acceptedAt

    if (willBeAccepted !== isAccepted) {
      return {
        check: true,
        title: willBeAccepted ? 'For accepting this Task' : 'For unaccepting this Task',
      }
    }
  }

  if ('channelId' in changes && task.channelId !== changes.channelId) {
    return { check: true, title: 'For moving this Task' }
  }

  if ('assignedUserId' in changes && task.assignedUserId !== changes.assignedUserId) {
    return { check: true, title: 'For reassigning this Task' }
  }

  if ('status' in changes && changes.status !== task.status) {
    if (task.status === 'ACTIVE') {
      switch (changes.status) {
        case 'ARCHIVED':
          return { check: true, title: 'For archiving this Task' }
        case 'DELETED':
          return { check: true, title: 'For deleting this Task' }
        default:
        // pass
      }
    } else if (changes.status === 'ACTIVE') {
      switch (task.status) {
        case 'ARCHIVED':
          return { check: true, title: 'For unarchiving this Task' }
        case 'DELETED':
          return { check: true, title: 'For undeleting this Task' }
        default:
        // pass
      }
    }
  }

  return { check: false }
}
