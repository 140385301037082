import React from 'react'
import Box from '@/components/layouts/Box'
import TaskFormSubmitLine from '../TaskFormSubmitLine'
import TaskFormCancelButton from './TaskFormCancelButton'

function TaskFormActionsLine() {
  return (
    <TaskFormSubmitLine gapTop={false}>
      <>
        <TaskFormCancelButton />
        <Box flex={1} />
      </>
    </TaskFormSubmitLine>
  )
}

export default React.memo(TaskFormActionsLine)
