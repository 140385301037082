import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { TaskList, TaskListType } from '@/types/entities'

const adapter = createEntityAdapter<TaskList>({
  selectId: taskList => taskList.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/taskLists',
  reducers: {
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.taskLists)

const selectByListType = (state: RootState, listType: TaskListType) =>
  sliceSelectors.selectAll(state).filter(taskList => taskList.listType === listType)

const taskListSelector = (userId: number, listType: TaskListType) =>
  createSelector(
    sliceSelectors.selectAll,
    (taskLists: TaskList[]): TaskList | undefined =>
      taskLists.filter(taskList => taskList.userId === userId && taskList.listType === listType)[0],
  )

const selectors = {
  all: sliceSelectors.selectAll,
  byId: sliceSelectors.selectById,
  byListType: selectByListType,
  taskListSelector,
}

export { actions, selectors }
export default reducer
