import React from 'react'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { ManualPromise } from '@/core/ManualPromise'
import { TaskType, UnaryTaskFilter } from '@/types/tasks'

export const useSelectTasks = (spaceId: number) => {
  const modalPresenter = useModalPresenterContext()

  return React.useCallback(
    (contextFilter: UnaryTaskFilter, taskType: TaskType, multiple: boolean) => {
      const promise = new ManualPromise<number[]>()

      modalPresenter.push('selectTasks', {
        contextFilter,
        mode: multiple ? 'MULTIPLE' : 'SINGLE_QUICK',
        onSelectTasks: (taskIds: number[]) => {
          promise.resolve(taskIds)
        },
        queryKey: 'ALL:ALL',
        spaceId,
        taskType,
      })

      return promise
    },
    [modalPresenter, spaceId],
  )
}
