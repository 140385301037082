import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { SummaryItem } from '@/types/entities'

interface ViewItemProps {
  summaryItem: SummaryItem
}

function ViewItem({ summaryItem }: ViewItemProps) {
  const { content, description } = summaryItem

  return (
    <Box>
      <Box alignItems="stretch" direction="row">
        <Box flex={1}>
          <Text size="h4">{content}</Text>
        </Box>
      </Box>
      {description ? (
        <Box gapTop="medium">
          <Text size="h5">{description}</Text>
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(ViewItem)
