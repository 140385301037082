import React from 'react'
import Text from '@/components/core/Text'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import { getTaskColors } from '@/components/messages/MessagesList/constants'
import QuestionResponseForm from '@/components/questions/QuestionResponseForm'
import QuestionResponseContext, {
  useQuestionResponseForm,
} from '@/contexts/QuestionResponseContext'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { taskUtils } from '@/core/utils'
import { Message, QuestionTask } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'
import TaskCommentsList from './TaskCommentsList'
import TaskContainer from './TaskContainer'

interface QuestionContentProps {
  disabled?: boolean
  comments?: Message[]
  isFromMe: boolean
  item: MessageListItemData
  task: QuestionTask
  simplified?: boolean
}

function QuestionContent({
  comments,
  disabled = false,
  isFromMe,
  simplified = false,
  task,
  item,
}: QuestionContentProps) {
  const isAnswered = taskUtils.isAnswered(task)
  const { background, foreground } = getTaskColors(isFromMe, isAnswered)
  const questionResponseContextValue = useQuestionResponseForm(task)
  const isDeleted = taskUtils.isDeleted(task)
  const italic = isAnswered || simplified

  const { showTaskForm } = useShowTaskFormContext()
  const { spaceId, id: taskId } = task
  const handleOpenQuestion = React.useCallback(
    () =>
      showTaskForm({
        defaultSpaceId: spaceId,
        formContext: 'task',
        initialQuestionResponse: questionResponseContextValue.responseForm,
        taskId,
      }),
    [showTaskForm, spaceId, taskId, questionResponseContextValue.responseForm],
  )

  const content = isDeleted ? (
    <Text color={foreground} size="h5">
      Deleted Question
    </Text>
  ) : (
    <>
      <Box gapBottom="medium">
        <TextWithLinks color={foreground} italic={italic} size="h5">
          {task.content}
        </TextWithLinks>
      </Box>
      {simplified ? null : (
        <QuestionResponseContext.Provider value={questionResponseContextValue}>
          <QuestionResponseForm
            disabled="WHEN_ANSWERED"
            foregroundColor={isAnswered ? 'anchor' : foreground}
            task={task}
          />
        </QuestionResponseContext.Provider>
      )}
    </>
  )
  return (
    <TaskContainer
      backgroundColor={background}
      isFromMe={isFromMe}
      item={item}
      onPress={disabled ? undefined : handleOpenQuestion}
      tabColor={isAnswered ? 'emmre-purple+3' : 'emmre-purple'}
      task={task}
    >
      <Box gapAll="medium">{content}</Box>
      <TaskCommentsList isFromMe={isFromMe} messages={comments} task={task} />
    </TaskContainer>
  )
}

export default React.memo(QuestionContent)
