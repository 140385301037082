import config from '@/core/config'
import { randBetween } from '@/core/utils/numbers'
import * as urls from '@/core/utils/urls'
import { GiphyGif, GiphyResult } from '@/types/giphy'

export const fetchGiphyResults = async (search: string, limit: number): Promise<GiphyGif[]> => {
  const queryString = urls.toQueryString([
    ['api_key', config.giphyApiKey],
    ['limit', limit],
    ['offset', 0],
    ['rating', 'g'],
    ['lang', 'en'],
    ['q', search],
  ])

  const result = await fetch(`https://api.giphy.com/v1/gifs/search?${queryString}`)
  if (result.ok) {
    const { data }: GiphyResult = await result.json()
    return data
  }
  return []
}

export const fetchRandomGiphyResult = async (
  search: string,
  size: number,
): Promise<string | undefined> => {
  const gifs = await fetchGiphyResults(search, size)
  const { length } = gifs

  if (length !== 0) {
    const index = randBetween(0, length - 1)
    return gifs[index].images.original.url
  }

  return undefined
}
