import { stonlyProxy } from '@/core/instrumentation'
import { guidesIndex } from '@/core/instrumentation/stonly/constants'
import { ShowGuideOptions, StonlyGuideKey } from '@/core/instrumentation/stonly/types'
import { authSelectors } from '@/store/selectors'
import { Thunk } from '@/types/store'
import { flagUserAttribute, removeUserAttribute } from './userAttributes'

export const showGuide =
  (key: StonlyGuideKey, opts: ShowGuideOptions): Thunk<boolean> =>
  async (dispatch, getState) => {
    if (!stonlyProxy.isAvailable) {
      return false
    }

    const guide = guidesIndex[key]

    // If the guide has no associated user attribute, pass through to proxy
    if (!guide.userAttribute) {
      return stonlyProxy.showGuide(key, opts)
    }

    // Otherwise check the attribute
    const attributeValue = authSelectors.attributeValue(getState(), guide.userAttribute)

    // If the attribute is present then skip
    if (attributeValue) {
      return false
    }

    // Check to see if the guide is logged as having been shown in local storage. If so we need to
    // update the user attribute. This needs to be here to migrate guides that are attached to a
    // user attribute after they have may have already been shown
    const alreadyShown = await stonlyProxy.hasBeenShown(key)

    if (alreadyShown) {
      await dispatch(flagUserAttribute(guide.userAttribute))
      return false
    }

    const success = await stonlyProxy.showGuide(key, opts)

    if (success) {
      await dispatch(flagUserAttribute(guide.userAttribute))
    }

    return success
  }

export const resetGuide =
  (key: StonlyGuideKey): Thunk<void> =>
  async dispatch => {
    if (!stonlyProxy.isAvailable) {
      return
    }

    const guide = guidesIndex[key]

    // Remove the user attribute if the guide has an associated attribute
    if (guide.userAttribute) {
      await dispatch(removeUserAttribute(guide.userAttribute))
    }

    stonlyProxy.resetGuide(key)
  }
