import React from 'react'
import Background from '@/components/core/Background'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'
import Overlay from './Overlay'
import Tab from './Tab'
import { getTabWidth, tabRadius } from './constants'

export interface TabbedContainerProps {
  backgroundColor: ColorName
  children: React.ReactNode
  hasOverlay: boolean
  isFromMe: boolean
  tabColor: ColorName
  tabIcon?: IconName
}

const maxWidth = 600

function TabbedContainer({
  backgroundColor,
  children,
  hasOverlay,
  isFromMe,
  tabColor,
  tabIcon,
}: TabbedContainerProps) {
  return (
    <Box direction="row" flex={1} justifyContent={isFromMe ? 'flex-end' : 'flex-start'}>
      {isFromMe ? (
        <Box width={getTabWidth(tabIcon)} />
      ) : (
        <Tab color={tabColor} hasOverlay={hasOverlay} icon={tabIcon} side="left" />
      )}
      <Background
        borderBottomLeftRadius={isFromMe ? tabRadius : 0}
        borderBottomRightRadius={isFromMe ? 0 : tabRadius}
        borderColor="koala"
        borderTopLeftRadius={isFromMe ? tabRadius : 0}
        borderTopRightRadius={isFromMe ? 0 : tabRadius}
        borderWidth={1}
        color={backgroundColor}
        flex={1}
        maxWidth={maxWidth}
      >
        {children}
        {hasOverlay ? <Overlay radiusSide={isFromMe ? 'left' : 'right'} /> : null}
      </Background>
      {isFromMe ? (
        <Tab color={tabColor} hasOverlay={hasOverlay} icon={tabIcon} side="right" />
      ) : (
        <Box width={getTabWidth(tabIcon)} />
      )}
    </Box>
  )
}

export default React.memo(TabbedContainer)
