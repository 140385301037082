import React from 'react'
import { rrulestr } from 'rrule'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormValue } from '@/components/tasks/TaskForm/hooks'

function TaskRecurrence() {
  const recurrenceRule = useTaskFormValue(fieldNames.recurrenceRule)
  const recurs = !!recurrenceRule

  const label = React.useMemo(() => {
    if (recurrenceRule) {
      return rrulestr(recurrenceRule).toText()
    }
    return 'Does Not Recur'
  }, [recurrenceRule])

  return (
    <Box alignItems="center" direction="row">
      <Icon color={recurs ? 'shadow' : 'koala'} kind="light" name="repeat" size="h4" />
      <Box direction="column" gapLeft="small">
        {recurs ? (
          <Text color="shadow" size="h6">
            Recurs
          </Text>
        ) : null}
        <Text color={recurs ? 'shadow' : 'anchor'} size="h6">
          {label}
        </Text>
      </Box>
    </Box>
  )
}

export default React.memo(TaskRecurrence)
