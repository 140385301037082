import React from 'react'
import { FlatList, ListRenderItemInfo } from 'react-native'
import Box from '@/components/layouts/Box'
import { GroupedTask, TaskGrouping, TaskQuery, TaskSelector, TaskType } from '@/types/tasks'
import TaskListItem, { RenderCustomFocus } from './TaskListItem'
import TaskSectionHeader from './TaskSectionHeader'
import ToggleCompletedButton from './ToggleCompletedButton'
import { useTasksList } from './hooks'

interface StaticTasksListProps extends TaskSelector {
  fastCreate: boolean
  renderCustomFocus?: RenderCustomFocus
  showAssignee: boolean
  showMyDay: boolean
  taskGrouping: TaskGrouping | null
  taskQuery: TaskQuery
  taskType: TaskType
  emptyComponent?: React.ComponentType<any> | React.ReactElement | null
}

const keyExtractor = (item: GroupedTask) => `${item.task.spaceId}:${item.task.id}:${item.label}`

function StaticTasksList({
  fastCreate,
  onSelectTask,
  renderCustomFocus,
  selectedTaskIds,
  showAssignee,
  showMyDay,
  taskGrouping,
  taskQuery,
  taskType,
  emptyComponent,
}: StaticTasksListProps) {
  const {
    flatListRef,
    groupedTasks,
    handleShowTask,
    hiddenFilter,
    showCompleted,
    toggleShowCompleted,
  } = useTasksList({ fastCreate, rememberPreviousItems: false, taskGrouping, taskQuery })
  const { focus } = taskQuery

  const renderGroupedTask = React.useCallback(
    ({ item, index }: ListRenderItemInfo<GroupedTask>) => {
      const { task, label } = item

      const listItem = (
        <TaskListItem
          focus={focus}
          isSelected={!!selectedTaskIds && selectedTaskIds.includes(task.id)}
          onPress={handleShowTask}
          onSelectTask={onSelectTask}
          renderCustomFocus={renderCustomFocus}
          showAssignee={showAssignee}
          showMyDay={showMyDay}
          task={task}
        />
      )

      const previous = !index ? undefined : groupedTasks[index - 1]
      const showLabel = label && previous?.label !== label

      return (
        <Box gapBottom="xsmall" gapHorizontal="medium">
          {showLabel ? <TaskSectionHeader label={label} /> : null}
          {listItem}
        </Box>
      )
    },
    [
      focus,
      handleShowTask,
      groupedTasks,
      showAssignee,
      showMyDay,
      renderCustomFocus,
      onSelectTask,
      selectedTaskIds,
    ],
  )

  const toggleButton = (
    <ToggleCompletedButton
      hiddenFilter={hiddenFilter}
      onToggle={toggleShowCompleted}
      showCompleted={showCompleted}
      taskType={taskType}
    />
  )

  const position = showCompleted || groupedTasks.length === 0 ? 'top' : 'bottom'

  // Turning this on was causing missing data in some circumstances with smaller lists.
  const removeClippedSubviews = groupedTasks.length >= 20

  return (
    <FlatList
      ref={flatListRef}
      ListEmptyComponent={emptyComponent}
      ListFooterComponent={position === 'bottom' ? <Box minHeight={100}>{toggleButton}</Box> : null}
      ListHeaderComponent={position === 'top' ? toggleButton : null}
      data={groupedTasks}
      extraData={renderGroupedTask}
      keyExtractor={keyExtractor}
      keyboardShouldPersistTaps="handled"
      removeClippedSubviews={removeClippedSubviews}
      renderItem={renderGroupedTask}
      windowSize={3}
    />
  )
}

export default React.memo(StaticTasksList)
