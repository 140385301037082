import React from 'react'
import { StyleSheet } from 'react-native'
import Background from '@/components/core/Background'
import Hoverable from '@/components/core/Hoverable'
import Text from '@/components/core/Text'
import TextWithLinks from '@/components/core/TextWithLinks'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import AttributedGiphyImage from '@/components/media/AttributedGiphyImage'
import {
  getMessageColors,
  maxEmojiForSpecialPresentation,
} from '@/components/messages/MessagesList/constants'
import { useMessagesListContext } from '@/contexts/MessagesListContext'
import { emojiUtils, platformUtils, taskUtils } from '@/core/utils'
import { MessageListItemData } from '@/types/messaging'
import { FontSize } from '@/types/typography'
import MessageReactionsList from '../MessageReactionsList'
import HoverMenu from './HoverMenu'

interface MessageBubbleProps {
  disabled?: boolean
  canShowThread: boolean
  isFromMe: boolean
  item: MessageListItemData
  onLongPress?: () => void
}

const { isWeb } = platformUtils

function MessageBubble({
  disabled = false,
  canShowThread,
  item,
  onLongPress,
  isFromMe,
}: MessageBubbleProps) {
  const { message } = item
  const { spaceId, id: messageId, content } = message
  const isGiphyLink = taskUtils.isGiphyLink(content)
  const asEmoji = React.useMemo(
    () =>
      content &&
      emojiUtils.isOnlyEmoji(content) &&
      emojiUtils.countEmoji(content) <= maxEmojiForSpecialPresentation,
    [content],
  )
  const { showThread } = useMessagesListContext()
  const handleShowThread = React.useCallback(
    () => showThread(spaceId, messageId),
    [showThread, spaceId, messageId],
  )

  const { background, foreground } = getMessageColors(isFromMe)
  let backgroundColor = background
  let textSize: FontSize = 'h4'

  if (asEmoji) {
    backgroundColor = 'transparent'
    textSize = 'emoji'
  }

  if (isGiphyLink) {
    backgroundColor = 'transparent'
  }

  const edited = message.createdAt !== message.updatedAt

  return (
    <Hoverable style={isFromMe ? styles.hoverableFromMe : styles.hoverableFromThem}>
      {hovering => (
        <>
          {hovering && isFromMe && !disabled ? (
            <HoverMenu
              isFromMe={isFromMe}
              message={item.message}
              showConvertToTask
              showMoreActions
            />
          ) : null}
          <Touchable
            disabled={disabled}
            feedback="opacity"
            onLongPress={isWeb ? undefined : onLongPress}
            onPress={isWeb || !canShowThread ? undefined : handleShowThread}
            style={styles.touchable}
          >
            <Background borderRadius={8} color={backgroundColor}>
              {isGiphyLink ? (
                <AttributedGiphyImage isFromMe={isFromMe} uri={content} />
              ) : (
                <Box gapAll="medium">
                  <TextWithLinks
                    color={foreground}
                    letterSpacing={asEmoji ? 3 : undefined}
                    size={textSize}
                  >
                    {content}
                  </TextWithLinks>
                  {edited ? (
                    <Box gapTop="xsmall">
                      <Text color={foreground} size="h6">
                        (edited)
                      </Text>
                    </Box>
                  ) : null}
                </Box>
              )}
              {message.reactions ? (
                <FloatContainer
                  left={isFromMe ? -7 : undefined}
                  right={isFromMe ? undefined : -7}
                  top={-14}
                >
                  <MessageReactionsList message={message} />
                </FloatContainer>
              ) : null}
            </Background>
          </Touchable>
          {hovering && !isFromMe && !disabled ? (
            <HoverMenu
              isFromMe={isFromMe}
              message={item.message}
              showConvertToTask
              showMoreActions
            />
          ) : null}
        </>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  hoverableFromMe: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  hoverableFromThem: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  touchable: {
    maxWidth: platformUtils.isWeb ? 'min(768px, calc(100% - 130px))' : '80%',
  },
})

export default React.memo(MessageBubble)
