import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import TaskDetailButton from './TaskDetailButton'
import { useTaskFormMoreActions } from './hooks'

// This component can be rendered in several different contexts so we need a few
// controls over how it gaps itself. I don't like this very much
interface TaskDetailButtonsProps {
  wrapped?: boolean
  showAttachments?: boolean
  showMore?: boolean
  isModal?: boolean
}

function TaskDetailButtons({
  wrapped = false,
  showAttachments = false,
  showMore = false,
  isModal,
}: TaskDetailButtonsProps) {
  const { showDetail } = useTaskFormContext()
  const { handleShowActions } = useTaskFormMoreActions()

  // Gap bottom keeps the submit button lining up properly when you change from small
  // to medium
  return (
    <Box
      alignItems="center"
      direction="row"
      gapBottom="xsmall"
      gapHorizontal={wrapped ? -10 : 8}
      gapTop={wrapped ? undefined : 'medium'}
      justifyContent="flex-start"
      testID={isModal ? 'task_content_options_modal' : 'task_content_options_chat'}
    >
      <TaskDetailButton
        iconKind="light"
        iconName="align-left"
        onPress={showDetail}
        taskDetail="NOTES"
      />
      <TaskDetailButton
        iconKind="light"
        iconName="calendar-plus"
        onPress={showDetail}
        taskDetail="DUE_DATE"
      />
      <TaskDetailButton
        iconKind="light"
        iconName="th-list"
        onPress={showDetail}
        taskDetail="BOARD"
      />
      <TaskDetailButton
        iconKind="light"
        iconName="hashtag"
        onPress={showDetail}
        taskDetail="TAGS"
      />
      <TaskDetailButton
        iconKind="light"
        iconName="list-ul"
        onPress={showDetail}
        taskDetail="CHECKLIST_ITEMS"
      />
      {showAttachments ? (
        <TaskDetailButton
          iconKind="light"
          iconName="paperclip"
          onPress={showDetail}
          taskDetail="ATTACHMENTS"
        />
      ) : null}
      {showMore ? (
        <Box direction="row" flex={1} justifyContent="flex-end">
          <BaseIconButton
            color="anchor"
            hitSlop="none"
            name="ellipsis-h"
            onPress={handleShowActions}
            padding={10}
            shape="short-square"
            size="h3"
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(TaskDetailButtons)
