import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Background from '@/components/core/Background'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import StackContextContainer from '@/components/screens/StackContextContainer'
import BoardsList from './BoardsList'
import { useBoardSelector } from './hooks'

interface TaskBoardModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskBoardModal({ isOpen, onClose, localModal = false }: TaskBoardModalProps) {
  const {
    boards,
    createBoardAction,
    createErrors,
    handleCancel,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isSearching,
    searchMatchesNonActiveBoard,
    searchText,
    selectedId: selectedBoardId,
    setSearchText,
  } = useBoardSelector(onClose, isOpen)

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Projects"
    >
      <StackContextContainer>
        <Box direction="column" flex={1}>
          <Background
            borderBottomColor="heather"
            borderBottomWidth={1}
            borderTopColor="heather"
            borderTopWidth={1}
          >
            <Box alignItems="center" direction="row" gapAll="medium">
              <Box flex={1}>
                <TextInput
                  error={createErrors.name?.[0]}
                  kind="bare"
                  onChangeText={setSearchText}
                  placeholder={
                    boards.length === 0
                      ? 'Type a new project name'
                      : 'Search projects or type a new project name'
                  }
                  value={searchText || undefined}
                />
              </Box>
              {isSearching ? <ActivityIndicator color="obsidian" /> : null}
            </Box>
          </Background>
          <Box flex={1} gapBottom="medium">
            <BoardsList
              boards={boards}
              createBoardAction={createBoardAction}
              createBoardLabel={searchMatchesNonActiveBoard ? 'Add Project to Hub' : 'Add to Hub'}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onSelect={handleSelect}
              selectedBoardId={selectedBoardId}
            />
          </Box>
        </Box>
      </StackContextContainer>
    </StandardModal>
  )
}

export default React.memo(TaskBoardModal)
