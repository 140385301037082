import React from 'react'
import { Switch as RNSwitch, SwitchProps as RNSwitchProps } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'

interface SwitchProps extends Omit<RNSwitchProps, 'thumbColor' | 'trackColor'> {
  thumbColor?: ColorName
  trackColorOn?: ColorName
  trackColorOff?: ColorName
}

function Switch({
  thumbColor = 'ghost',
  trackColorOn = 'emmre-green',
  trackColorOff = 'heather',
  ...rnSwitchProps
}: SwitchProps) {
  const { disabled } = rnSwitchProps

  const otherProps = {
    activeThumbColor: getColor(disabled ? 'heather' : thumbColor),
    activeTrackColor: getColor(trackColorOn),
    thumbColor: getColor(disabled ? 'heather' : thumbColor),
    trackColor: getColor(trackColorOff),
  }

  // @ts-ignore
  return <RNSwitch {...rnSwitchProps} {...otherProps} />
}

// ts-unused-exports:disable-next-line
export default React.memo(Switch)
