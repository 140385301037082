import React from 'react'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { fnUtils, spaceUtils } from '@/core/utils'
import { pluralize } from '@/core/utils/strings'
import { useAppDispatch } from '@/hooks'
import { notificationActions } from '@/store/actions'
import { PlanType, Space } from '@/types/entities'
import EnterPayment from './EnterPayment'
import ProductInformationCard from './ProductInformationCard'
import WithEstimates from './WithEstimates'
import { usePay, useRemoveUsers } from './hooks'

interface MakePaymentBodyProps {
  space: Space
  onClose: () => void
  onManageUsers: (spaceId: number) => void
}

function MakePaymentBody({ space, onClose, onManageUsers }: MakePaymentBodyProps) {
  const { id: spaceId } = space
  const daysRemaining = spaceUtils.daysRemaining(space.paidThroughDate)

  const [productVersion, setProductVersion] = React.useState(space.productVersion)
  const [canSelect, setCanSelect] = React.useState(true)
  const dispatch = useAppDispatch()

  const checkCanSelect = useRemoveUsers(
    spaceId,
    fnUtils.noOp,
    React.useCallback(() => {
      onClose()
      onManageUsers(spaceId)
    }, [onClose, spaceId, onManageUsers]),
  )

  const toggleProductVersion = React.useCallback(async () => {
    const nextProductVersion = productVersion === 'ASSISTANT_PRO' ? 'PARTNERSHIP' : 'ASSISTANT_PRO'
    setProductVersion(nextProductVersion)
    setCanSelect(await checkCanSelect(nextProductVersion))
  }, [productVersion, checkCanSelect])

  const { makePayment, isPending } = usePay(spaceId)

  const handlePay = React.useCallback(
    async (paymentIntentId: string | null) => {
      const ok = await makePayment(productVersion, paymentIntentId)

      if (ok) {
        dispatch(
          notificationActions.push({
            body: 'Welcome to the Emmre team. We are honored you signed up.',
            title: 'Thank you for your purchase!',
            type: 'success',
          }),
        )
        onClose()
      }
    },
    [makePayment, dispatch, onClose, productVersion],
  )

  return (
    <WithEstimates spaceId={spaceId}>
      {estimates => (
        <>
          {daysRemaining > 0 ? (
            <Box gapBottom="large">
              <Text align="center">
                We&apos;ll still honor the remaining {pluralize(daysRemaining, 'day')} in your free
                trial.
              </Text>
            </Box>
          ) : null}
          <ProductInformationCard
            color={productVersion === 'ASSISTANT_PRO' ? 'emmre-blue' : 'indigo'}
            estimate={estimates[productVersion as PlanType]}
            isSelected={space.productVersion === productVersion}
            unitLabel={productVersion === 'ASSISTANT_PRO' ? '/ month' : '/ month per user'}
          />
          <Box gapTop="medium">
            <Link
              label={`Switch to ${
                productVersion === 'ASSISTANT_PRO' ? 'Partnership' : 'Assistant Pro'
              } Plan`}
              onPress={toggleProductVersion}
            />
          </Box>
          {canSelect ? (
            <Box gapTop="xlarge">
              <EnterPayment isPending={isPending} onSelect={handlePay} spaceId={spaceId} />
            </Box>
          ) : (
            <Box alignItems="center" direction="row" gapTop="medium" justifyContent="space-between">
              <Text color="red">You are not able to select this plan at this time.</Text>
              <Link label="Go Back" onPress={onClose} />
            </Box>
          )}
        </>
      )}
    </WithEstimates>
  )
}

export default React.memo(MakePaymentBody)
