import { Request } from '@/api/call'
import { ReceivedMessage, ReceivedTask } from '@/types/apiEntities'
import { ChannelUser, SentMessage } from '@/types/entities'

export interface MessagesResponse {
  messages: ReceivedMessage[]
  channelUser: ChannelUser
  tasks: ReceivedTask[]
  threadHeads?: ReceivedMessage[]
}

export interface UpdateMessageResponse {
  message: ReceivedMessage
}

export interface CreateMessageResponse {
  message: ReceivedMessage
  channelUser: ChannelUser
}

export interface CreateMessage
  extends Pick<SentMessage, 'threadId' | 'content' | 'clientTemporaryId'> {
  fileIds?: number[]
}

export type EditMessage = Partial<Pick<SentMessage, 'threadId' | 'content'>>

export interface MessageParams {
  beforeMessageId?: number
  afterMessageId?: number
  limit?: number
  threadId?: number
}

export const getMessages = (spaceId: number, channelId: number, params: MessageParams) =>
  new Request<MessagesResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    query: Object.entries(params),
    url: `v1/spaces/${spaceId}/channels/${channelId}/messages`,
  })

export const createMessage = (spaceId: number, channelId: number, message: CreateMessage) =>
  new Request<CreateMessageResponse>({
    authentication: 'PRIVATE',
    body: message,
    method: 'POST',
    url: `v1/spaces/${spaceId}/channels/${channelId}/messages`,
  })

export const editMessage = (spaceId: number, messageId: number, message: EditMessage) =>
  new Request<UpdateMessageResponse>({
    authentication: 'PRIVATE',
    body: message,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/messages/${messageId}`,
  })

export const deleteMessage = (spaceId: number, messageId: number) =>
  new Request<UpdateMessageResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/messages/${messageId}`,
  })

export const removeFileFromMessage = (spaceId: number, messageId: number, fileId: number) =>
  new Request<UpdateMessageResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/messages/${messageId}/files/${fileId}`,
  })

export const reactToMessage = (spaceId: number, messageId: number, reaction: string) =>
  new Request<UpdateMessageResponse>({
    authentication: 'PRIVATE',
    body: { reaction },
    method: 'PUT',
    url: `v1/spaces/${spaceId}/messages/${messageId}/reactions`,
  })

export const removeMessageReaction = (spaceId: number, messageId: number) =>
  new Request<UpdateMessageResponse>({
    authentication: 'PRIVATE',
    body: { reaction: null },
    method: 'PUT',
    url: `v1/spaces/${spaceId}/messages/${messageId}/reactions`,
  })
