import React from 'react'
import TaskReminderModal from '@/components/tasks/TaskForm/Modals/TaskReminderModal'
import { dateUtils as D } from '@/core/utils'
import { useToggle } from '@/hooks'
import { ReminderOffset } from '@/types/entities'
import DateOptionItem from './DateOptionItem'

interface ReminderOptionItemProps {
  selectedDate: Date | null
  onRemindAtChange: (remindAtOffset: ReminderOffset | null) => void
  selectedRemindAt: Date | null
  selectedRemindAtOffset: ReminderOffset | null
  localModal: boolean
}

function ReminderOptionItem({
  selectedDate,
  onRemindAtChange,
  selectedRemindAt,
  selectedRemindAtOffset,
  localModal,
}: ReminderOptionItemProps) {
  const [open, toggleOpen] = useToggle(false)

  return (
    <>
      <DateOptionItem
        disabled={!selectedDate}
        icon="bell"
        onPress={toggleOpen}
        title={selectedDate ? 'Reminder' : 'Reminder (Requires Due Date)'}
        value={selectedRemindAt ? D.formatShort(selectedRemindAt) : 'None'}
      />
      <TaskReminderModal
        isOpen={open}
        localModal={localModal}
        onClose={toggleOpen}
        onRemindAtChange={onRemindAtChange}
        selectedDate={selectedDate}
        selectedRemindAt={selectedRemindAt}
        selectedRemindAtOffset={selectedRemindAtOffset}
      />
    </>
  )
}

export default React.memo(ReminderOptionItem)
