import React from 'react'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { IconName } from '@/core/constants/icons'

interface DateOptionItemProps {
  icon: IconName
  title: string
  value: string
  onPress: () => void
  disabled?: boolean
}

function DateOptionItem({ icon, title, value, onPress, disabled = false }: DateOptionItemProps) {
  return (
    <Touchable disabled={disabled} feedback="highlight" onPress={onPress}>
      <Box alignItems="center" direction="row" gapAll="medium">
        <Icon color="anchor" name={icon} size="h4" />
        <Box flex={1} gapHorizontal="medium">
          <Text color="anchor">{title}</Text>
        </Box>
        <Box gapRight="medium">
          <Text color="anchor">{value}</Text>
        </Box>
        <Icon color="anchor" name="chevron-right" size="h4" />
      </Box>
    </Touchable>
  )
}

export default React.memo(DateOptionItem)
