import React from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import ModalOverlay from '@/components/core/ModalOverlay'
import Text from '@/components/core/Text'
import { getColor } from '@/core/constants/colors'
import AlertModalButton from './AlertModalButton'
import { AlertArgs } from './types'

interface AlertModalProps {
  alertArgs: AlertArgs | null
  onClose: () => void
}

function AlertModal({ alertArgs, onClose }: AlertModalProps) {
  return (
    <Modal animationType="fade" transparent visible={!!alertArgs}>
      {alertArgs ? (
        <ModalOverlay>
          <View style={styles.container}>
            <View style={styles.title}>
              <Text align="center" weight="medium">
                {alertArgs.title}
              </Text>
              {alertArgs.message ? (
                <View style={styles.message}>
                  <Text align="center" size="h5">
                    {alertArgs.message}
                  </Text>
                </View>
              ) : null}
            </View>
            {alertArgs.buttons.map(button => (
              <AlertModalButton key={button.text} button={button} onClose={onClose} />
            ))}
          </View>
        </ModalOverlay>
      ) : null}
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: getColor('ghost'),
    borderRadius: 20,
    width: 300,
  },
  message: {
    marginTop: 10,
  },
  title: {
    margin: 20,
  },
})

export default React.memo(AlertModal)
