import React from 'react'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import Box from '@/components/layouts/Box'
import QuestionEditor from '@/components/questions/QuestionEditor'
import QuestionModeChooser from '@/components/questions/QuestionModeChooser'
import { useTaskFormContext } from '@/contexts/TaskFormContext'

function QuestionInputFooter() {
  const { canSubmit, submit, submitDisabled, questionMode } = useTaskFormContext()

  const disabled = !canSubmit

  return (
    <Box gapBottom="medium" gapHorizontal="medium">
      <Box gapBottom="medium">
        <QuestionEditor questionMode={questionMode} />
      </Box>
      <Background borderTopColor="koala" borderTopWidth={1} />
      <Box alignItems="flex-end" direction="row" gapTop="medium" justifyContent="space-between">
        <QuestionModeChooser />
        <Button
          amplitudeEventName="create question"
          disabled={disabled}
          hitSlop="none"
          kind="secondary"
          label="Ask"
          onPress={submit}
          onPressDisabled={submitDisabled}
          size="small"
        />
      </Box>
    </Box>
  )
}

export default React.memo(QuestionInputFooter)
