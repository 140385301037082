import React from 'react'
import { noOpWarn } from '@/core/logging'
import { Message } from '@/types/entities'
import { CacheDuration } from '@/types/files'
import { Action } from '@/types/generics'

export interface FileActionArg {
  uri: string
  duration: CacheDuration
  message?: Message
}
type FileActionsContextType = Action<FileActionArg>

const FileActionsContext = React.createContext<FileActionsContextType>(
  noOpWarn('file actions context not present'),
)

export const useFileActionsContext = () => React.useContext(FileActionsContext)

export default FileActionsContext
