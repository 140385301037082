import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import Box from '@/components/layouts/Box'
import { JustifyContent } from '@/types/components'

interface ModalBackdropProps {
  onPress?: () => void
  children?: React.ReactNode
  justifyContent?: JustifyContent
}

function ModalBackdrop({ onPress, children, justifyContent = 'center' }: ModalBackdropProps) {
  return (
    <Box alignItems="center" flex={1} justifyContent={justifyContent}>
      <Pressable onPress={onPress} style={styles.backdrop} />
      {children}
    </Box>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default React.memo(ModalBackdrop)
