import { camelizeKeys } from 'humps'
import React from 'react'
import { useAppDispatch } from '@/hooks'
import { internalThunks } from '@/thunks'
import { initializeChargebee } from '@/thunks/integrations'
import { APIStatus } from '@/types/api'
import { ChargebeeEstimatesMap, ChargebeePaymentIntent } from '@/types/chargebee'

export const useChargebeeEstimates = (spaceId: number) => {
  const dispatch = useAppDispatch()
  const [status, setStatus] = React.useState<APIStatus>('READY')
  const [estimates, setEstimates] = React.useState<ChargebeeEstimatesMap | null>(null)

  const getEstimates = React.useCallback(async () => {
    if (status !== 'READY') {
      return
    }
    setStatus('PENDING')

    const data = await dispatch(internalThunks.getChargebeeEstimates(spaceId))

    if (data) {
      setStatus('SUCCESS')
      setEstimates(data)
    } else {
      setStatus('ERROR')
    }
  }, [status, setStatus, dispatch, spaceId, setEstimates])

  React.useEffect(() => {
    getEstimates()
  }, [getEstimates])

  return { estimates, status }
}

export const useChargebeePayment = () => {
  const cardRef = React.useRef<any>(null)
  const [status, setStatus] = React.useState<APIStatus>('READY')
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  React.useEffect(() => {
    initializeChargebee()
  }, [])

  // testing payment info at:
  // https://stripe.com/docs/payments/3d-secure#three-ds-cards
  // https://www.chargebee.com/docs/2.0/chargebee-test-gateway.html#test-card-numbers_3ds-cards
  const submitPayment = React.useCallback(
    async (paymentIntent: ChargebeePaymentIntent): Promise<ChargebeePaymentIntent | null> => {
      setStatus('PENDING')
      setErrorMessage(null)

      try {
        const response: any = await cardRef.current.authorizeWith3ds(paymentIntent)
        setStatus('SUCCESS')
        return camelizeKeys(response) as unknown as ChargebeePaymentIntent
      } catch (ex: any) {
        setStatus('ERROR')
        setErrorMessage('An error occurred when validating your payment information')
        reportError(ex)
      }

      return null
    },
    [setErrorMessage, setStatus],
  )

  return {
    cardRef,
    errorMessage,
    status,
    submitPayment,
  }
}

export const usePaymentIntent = (spaceId: number) => {
  const dispatch = useAppDispatch()
  const [paymentIntent, setPaymentIntent] = React.useState<ChargebeePaymentIntent | null>(null)
  const [status, setStatus] = React.useState<APIStatus>('READY')

  const fetchPaymentIntent = React.useCallback(async () => {
    setStatus('PENDING')

    const response = await dispatch(internalThunks.createChargebeePaymentIntent(spaceId))

    if (response) {
      setPaymentIntent(response.paymentIntent)
      setStatus('SUCCESS')
    } else {
      setStatus('ERROR')
    }
  }, [setStatus, dispatch, spaceId, setPaymentIntent])

  React.useEffect(() => {
    if (status === 'READY') {
      fetchPaymentIntent()
    }
  }, [status, fetchPaymentIntent])

  return {
    paymentIntent,
    setPaymentIntent,
    status,
  }
}
