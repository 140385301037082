import { DrawerContentComponentProps } from '@react-navigation/drawer'
import React from 'react'
import ContentContainer from '@/components/layouts/ContentContainer'
import CoreDrawer from '@/components/screens/AppDrawer'
import { useDrawerNavigation } from './hooks'

function AppDrawer(props: DrawerContentComponentProps) {
  const {
    handleSpaceSelectionChange,
    handleNavigateProfile,
    handleSignOut,
    handleNavigateSpace,
    handleCreateSpace,
    handleShowHelp,
  } = useDrawerNavigation(props)

  return (
    <ContentContainer avoidKeyboard context="SCREEN" insetBottom insetTop>
      <CoreDrawer
        onCreateSpace={handleCreateSpace}
        onSelectSpace={handleSpaceSelectionChange}
        onShowHelp={handleShowHelp}
        onShowProfile={handleNavigateProfile}
        onShowSpace={handleNavigateSpace}
        onSignOut={handleSignOut}
      />
    </ContentContainer>
  )
}

export default React.memo(AppDrawer)
