import React from 'react'

export const useCreateHubModal = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const close = React.useCallback(() => setIsOpen(false), [setIsOpen])

  return {
    close,
    isOpen,
    open,
  }
}
