import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { buildUserId as buildId } from '@/core/utils/users'
import { RootState } from '@/store'
import { SpaceUser } from '@/types/entities'
import { selectors as authSelectors } from '../auth'
import { createEntityReducers } from './entityReducers'

const adapter = createEntityAdapter<SpaceUser>({
  selectId: user => buildId(user.spaceId, user.id),
  sortComparer: (a, b) => {
    if (a.lastName && b.lastName) {
      const lastName = a.lastName.localeCompare(b.lastName)
      if (lastName !== 0) {
        return lastName
      }
    }
    if (a.lastName && !b.lastName) {
      return -1
    }
    if (!a.lastName && b.lastName) {
      return 1
    }
    return a.firstName.localeCompare(b.firstName)
  },
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/users',
  reducers: {
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    updateMany: adapter.updateMany,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.users)

const selectById = (state: RootState, spaceId: number, userId: number) =>
  state.entities.users.entities[buildId(spaceId, userId)]

const byIdSelector = (spaceId: number, userId: number) =>
  createSelector(sliceSelectors.selectEntities, users => users[buildId(spaceId, userId)])

const byIdsSelector = (spaceId: number, userIds: number[]) =>
  createSelector(
    sliceSelectors.selectEntities,
    users =>
      userIds.map(userId => users[buildId(spaceId, userId)]).filter(user => !!user) as SpaceUser[],
  )

const allPresentSelector = (spaceId: number, userIds: number[]) =>
  createSelector(byIdsSelector(spaceId, userIds), users => users.length === userIds.length)

const bySpaceIdSelector = (spaceId: number, excludeMe = false) =>
  createSelector(authSelectors.myId, sliceSelectors.selectAll, (myId, users) =>
    excludeMe
      ? users.filter(
          user =>
            user.spaceId === spaceId && user.membershipType !== 'INACTIVE' && user.id !== myId,
        )
      : users.filter(user => user.spaceId === spaceId && user.membershipType !== 'INACTIVE'),
  )

const countBySpaceIdSelector = (spaceId: number, excludeMe = false) =>
  createSelector(bySpaceIdSelector(spaceId, excludeMe), users => users.length)

const selectors = {
  all: sliceSelectors.selectAll,
  allPresentSelector,
  byId: selectById,
  byIdSelector,
  byIdsSelector,
  bySpaceIdSelector,
  countBySpaceIdSelector,
  entities: sliceSelectors.selectEntities,
}
export { actions, selectors, buildId }
export default reducer
