import React from 'react'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import QuestionModeButton from './QuestionModeButton'

function QuestionModeChooser() {
  const { questionMode, setQuestionMode } = useTaskFormContext()

  return (
    <>
      <QuestionModeButton
        isSelected={questionMode === 'YES_NO'}
        mode="YES_NO"
        onSelect={setQuestionMode}
      />
      <QuestionModeButton
        isSelected={questionMode === 'MULTIPLE_CHOICE'}
        mode="MULTIPLE_CHOICE"
        onSelect={setQuestionMode}
      />
      <QuestionModeButton
        isSelected={questionMode === 'OPEN_ENDED'}
        mode="OPEN_ENDED"
        onSelect={setQuestionMode}
      />
    </>
  )
}

export default React.memo(QuestionModeChooser)
