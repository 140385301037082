import * as Notifications from 'expo-notifications'
import { notificationActions } from '@/store/actions'
import { authSelectors, channelUserSelectors } from '@/store/selectors'
import { CreateNotification } from '@/store/slices/notifications'
import { SyncThunk, Thunk } from '@/types/store'

export const setBadge = (): Thunk<boolean> => (dispatch, getState) => {
  const myId = authSelectors.myId(getState()) as number
  const unreadMessagesCount = channelUserSelectors.unreadMessagesCountSelector(myId, {
    mode: 'full_count',
  })(getState())

  return manuallySetBadge(unreadMessagesCount)
}

export const clearBadge = () => manuallySetBadge(0)

const manuallySetBadge = async (count: number): Promise<boolean> => {
  try {
    return await Notifications.setBadgeCountAsync(count, {
      web: {
        favicon: {
          backgroundColor: 'red',
          color: 'white',
          horizontalPadding: 2,
          indicator: '!',
          radius: 4,
          size: 8,
          verticalPadding: 2,
        },
        method: 'Favicon',
        title: {
          indicator: '!',
        },
      },
    })
  } catch {
    return false
  }
}

export const showToast =
  (notification: CreateNotification): SyncThunk<void> =>
  async dispatch => {
    dispatch(notificationActions.push(notification))
  }
