import {
  Request,
  makeRequest,
  mapRequest,
  notAuthenticatedResponse,
  resolveResponse,
} from '@/api/call'
import { httpStatus } from '@/core/constants'
import { getAccessToken } from '@/thunks/auth/refreshAccessToken'
import { APIThunk, ResolvedResponse } from '@/types/api'
import { Thunk } from '@/types/store'

export const makeRawRequest =
  <T>(request: Request<T>): Thunk<Response> =>
  async dispatch => {
    if (request.descriptor.authentication === 'PRIVATE') {
      const accessToken = await dispatch(getAccessToken('IF_NEEDED'))
      request.mergeHeaders({ Authorization: `Bearer ${accessToken}` })
    }
    const { url, ...opts } = mapRequest(request.descriptor)
    return fetch(url, opts)
  }

export const makeEnhancedRequest =
  <T>(request: Request<T>): APIThunk<T> =>
  async dispatch => {
    if (request.descriptor.authentication === 'PRIVATE') {
      return dispatch(makeAuthenticatedRequest(request))
    }

    const response = await request.getResponse()
    const resolvedResponse = resolveResponse(request.descriptor, response)
    return resolvedResponse
  }

const makeAuthenticatedRequest =
  <T>(request: Request<T>): APIThunk<T> =>
  async dispatch => {
    let accessToken = await dispatch(getAccessToken('IF_NEEDED'))

    if (!accessToken) {
      return notAuthenticatedResponse
    }

    const response = await getAuthenticatedResponse(accessToken, request)

    if (response.type === 'SUCCESS') {
      return response
    }

    if (response.status !== httpStatus.NOT_AUTHENTICATED) {
      return response
    }

    accessToken = await dispatch(getAccessToken('FORCE'))

    if (!accessToken) {
      return notAuthenticatedResponse
    }

    return getAuthenticatedResponse(accessToken, request)
  }

const getAuthenticatedResponse = async <T>(
  accessToken: string,
  request: Request<T>,
): Promise<ResolvedResponse<T>> => {
  request.mergeHeaders({ Authorization: `Bearer ${accessToken}` })

  const response = await makeRequest<T>(request.descriptor)
  return resolveResponse(request.descriptor, response)
}
