import React from 'react'
import { log } from '@/core/logging'
import { collectionUtils as C } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { messageThunks } from '@/thunks'

interface LoadMessageArgs {
  channelId: number
  spaceId: number
  threadId?: number
  limit: number
}

type State = {
  isLoading: boolean
  hasMore: boolean
  hasError: boolean
}

type Action =
  | { type: 'start' }
  | { type: 'error' }
  | { type: 'success'; payload: { hasMore: boolean } }

const reducer = (state: State, action: Action): State => {
  const { type } = action

  if (type === 'start') {
    return {
      ...state,
      hasError: false,
      hasMore: true,
      isLoading: true,
    }
  }

  if (type === 'error') {
    return {
      ...state,
      hasError: true,
      hasMore: true,
      isLoading: false,
    }
  }

  if (type === 'success') {
    const { hasMore } = action.payload

    return {
      ...state,
      hasError: false,
      hasMore,
      isLoading: false,
    }
  }

  return state
}

const defaultState: State = {
  hasError: false,
  hasMore: true,
  isLoading: true,
}

export const useLoadMessages = (args: LoadMessageArgs) => {
  const dispatchRedux = useAppDispatch()

  const [{ hasMore, hasError, isLoading }, dispatch] = React.useReducer(reducer, defaultState)
  const beforeMessageId = React.useRef<number | undefined>(undefined)

  const loadMore = React.useCallback(async () => {
    const { spaceId, channelId, threadId } = args
    dispatch({ type: 'start' })

    const response = await dispatchRedux(
      messageThunks.getMessages(spaceId, channelId, {
        beforeMessageId: beforeMessageId.current,
        limit: args.limit,
        threadId,
      }),
    )

    if (!response.ok) {
      log('error', 'Failed to load messages', {
        context: { channelId, response, spaceId, threadId },
      })
      dispatch({ type: 'error' })
      return
    }

    const { messages } = response.data
    const nextHasMore = messages.length >= args.limit

    dispatch({ payload: { hasMore: nextHasMore }, type: 'success' })

    // The messages are in descending id order, so the last message in the list is the earliest
    // one loaded.
    if (messages.length) {
      const last = C.last(messages)
      beforeMessageId.current = last.id
    }
  }, [args, dispatchRedux])

  const reload = React.useCallback(() => {
    beforeMessageId.current = undefined
    loadMore()
  }, [loadMore])

  // Whenever the space, channel, thread, etc changes we want to reload the messages list
  React.useEffect(reload, [reload])

  return {
    hasError,
    hasMore,
    isLoading,
    loadMore,
  }
}
