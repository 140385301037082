import React from 'react'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'
import ContainedIcon from './ContainedIcon'
import Text from './Text'

interface BlankSlateProps {
  title?: string
  subtitle?: string
  icon: IconName
  iconColor?: ColorName
}

function BlankSlate({ title, subtitle, icon, iconColor = 'emmre-blue' }: BlankSlateProps) {
  return (
    <Box alignItems="center" gapHorizontal="large">
      <Box gapVertical="xlarge">
        <ContainedIcon
          backgroundColor="heather"
          color={iconColor}
          name={icon}
          padding={64}
          size={100}
        />
      </Box>
      {title ? (
        <Text size="h3" weight="medium">
          {title}
        </Text>
      ) : null}
      {subtitle ? (
        <Box gapAll="small">
          <Text align="center" color="iron" size="h3">
            {subtitle}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(BlankSlate)
