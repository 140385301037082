import { getStateFromPath } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { associatedDomains } from '@/core/constants/linking'
import { stringUtils as S } from '@/core/utils'
import { linkingConfig } from './linkingConfig'

// Map old routes to new routes
const pathMaps: Record<string, string> = {
  'app/work/tasks': 'spaces/task',
  'reset-password': 'auth/reset-password',
}

const getMappedPath = (path: string): string | undefined => {
  if (path[0] === '/') {
    const mappedPath = getMappedPath(path.slice(1))
    if (mappedPath) {
      return `/${mappedPath}`
    }
  }
  const mappedPath = pathMaps[path]
  return mappedPath
}

export const linking = {
  config: linkingConfig,
  getStateFromPath: (url: string, options: any) => {
    const [path, params] = S.splitOnce('?', url)

    const mappedPath = getMappedPath(path)

    // Handle some old paths
    if (mappedPath) {
      return getStateFromPath(`${mappedPath}?${params}`, options)
    }

    return getStateFromPath(url, options)
  },
  prefixes: [...associatedDomains, Linking.createURL('/')],
}
