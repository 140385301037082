import React from 'react'
import { ScrollView } from 'react-native'
import { DragEndParams } from 'react-native-draggable-flatlist'
import { platformUtils } from '@/core/utils'
import { GroupedTask, TaskGrouping, TaskQuery, TaskSelector, TaskType } from '@/types/tasks'
import DraggableTaskList from './DraggableTaskList'
import StaticTaskList from './StaticTaskList'
import { RenderCustomFocus } from './TaskListItem'

interface TasksListProps extends TaskSelector {
  fastCreate: boolean
  onDragEnd?: (params: DragEndParams<GroupedTask>) => void
  renderCustomFocus?: RenderCustomFocus
  showAssignee?: boolean
  showMyDay?: boolean
  taskGrouping: TaskGrouping | null
  taskQuery: TaskQuery
  taskType: TaskType
  emptyComponent?: React.ComponentType<any> | React.ReactElement | null
}

function TasksList({
  fastCreate,
  onDragEnd,
  onSelectTask,
  renderCustomFocus,
  selectedTaskIds,
  showAssignee = false,
  showMyDay = false,
  taskGrouping,
  taskQuery,
  taskType,
  emptyComponent,
}: TasksListProps) {
  // Draggable flatlist seems slower for lots of inputs, so I use flatlist when we don't
  // need dragging

  if (onDragEnd) {
    const list = (
      <DraggableTaskList
        emptyComponent={emptyComponent}
        fastCreate={fastCreate}
        onDragEnd={onDragEnd}
        onSelectTask={onSelectTask}
        renderCustomFocus={renderCustomFocus}
        selectedTaskIds={selectedTaskIds}
        showAssignee={showAssignee}
        showMyDay={showMyDay}
        taskGrouping={taskGrouping}
        taskQuery={taskQuery}
        taskType={taskType}
      />
    )

    // TODO: Draggable flat list doesn't seem to scroll properly on web so I have to wrap it in
    //       a scroll view. When the library is updated check this again, because normally it's
    //       bad to wrap a flat list in a scroll view
    if (platformUtils.isWeb) {
      return <ScrollView>{list}</ScrollView>
    }
    return list
  }
  return (
    <StaticTaskList
      emptyComponent={emptyComponent}
      fastCreate={fastCreate}
      onSelectTask={onSelectTask}
      renderCustomFocus={renderCustomFocus}
      selectedTaskIds={selectedTaskIds}
      showAssignee={showAssignee}
      showMyDay={showMyDay}
      taskGrouping={taskGrouping}
      taskQuery={taskQuery}
      taskType={taskType}
    />
  )
}

export default React.memo(TasksList)
