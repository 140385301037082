import React from 'react'
import { FileActionArg } from '@/contexts/FileActionsContext'
import { fileUtils, fnUtils, platformUtils } from '@/core/utils'
import { useActions } from '@/hooks/actions'
import { useMessageReactions } from '@/hooks/messages/reactions'
import { ActionOption } from '@/types/actions'

const actionArgs = { title: 'File Actions' }

export const useFileActions = () => {
  const { pickMessageReaction } = useMessageReactions()
  const actionOptions = React.useMemo<ActionOption<FileActionArg>[]>(
    () => [
      {
        handler: ({ message }) => {
          if (message) {
            pickMessageReaction(message)
          }
        },
        isAvailable: ({ message }) => !!message,
        label: 'React',
      },
      {
        handler: ({ uri }) => {
          fileUtils.shareOrDownloadFile(uri)
        },
        label: platformUtils.isWeb ? 'Download' : 'Share',
      },
      {
        handler: ({ uri }) => {
          fileUtils.saveMedia(uri)
        },
        isAvailable: ({ uri }) => !platformUtils.isWeb && fileUtils.canSaveMedia(uri),
        label: 'Save',
      },
      {
        handler: fnUtils.noOp,
        label: 'Cancel',
        type: 'cancel',
      },
    ],
    [pickMessageReaction],
  )

  const handleShowActions = useActions<FileActionArg>(actionOptions, actionArgs)

  return { handleShowActions }
}
