import React from 'react'
import Button from '@/components/core/Button'
import Checkbox from '@/components/core/Checkbox'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { Channel } from '@/types/entities'
import { useCreateHub } from './hooks'

interface CreateJustYouHubContentProps {
  spaceId: number
  onClose: () => void
  onCreated: (channel: Channel) => void
}

function CreateJustYouHubContent({ spaceId, onClose, onCreated }: CreateJustYouHubContentProps) {
  const [setAsDefault, setSetAsDefault] = React.useState(false)
  const { isCreating, create } = useCreateHub(spaceId, onClose, onCreated)
  const handleCreate = React.useCallback(() => create(null, setAsDefault), [create, setAsDefault])
  const disabled = isCreating

  return (
    <Box gapBottom="medium" gapHorizontal="medium">
      <Text weight="medium">Create a Hub that only has YOU in it.</Text>
      <Box gapTop="large">
        <Text size="h5">
          The (You) Hub is a Hub made specially for Executive Assistants. It is often used to manage
          tasks that are work related but are not related to any of the executives that you support.
        </Text>
      </Box>
      <Box alignItems="center" direction="row" gapTop="large">
        <Checkbox checked={setAsDefault} onChange={setSetAsDefault} />
        <Box gapLeft="medium">
          <Text size="h5">Make this my integrations destination</Text>
        </Box>
      </Box>
      <Box alignItems="center" gapAll="large">
        <Button disabled={disabled} kind="primary" label="Create Hub" onPress={handleCreate} />
      </Box>
    </Box>
  )
}

export default React.memo(CreateJustYouHubContent)
