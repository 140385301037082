import { RequestThunk } from '@/api/call'
import { internalAPI } from '@/api/requests'
import { spaceActions } from '@/store/actions'
import { ChargebeeEstimate, ChargebeeEstimatesMap } from '@/types/chargebee'
import { TimezoneInfo } from '@/types/entities'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

export const getTimezonesList = (): Thunk<TimezoneInfo[]> => async dispatch => {
  const request = internalAPI.getTimezonesList()
  const response = await dispatch(makeEnhancedRequest(request))

  if (response.ok) {
    return response.data.timezones
  }
  return []
}

export const createSignUpChargebeePaymentIntent =
  (
    body: internalAPI.CreateSignUpPaymentIntentBody,
  ): Thunk<internalAPI.CreateSignUpPaymentIntentResponse | null> =>
  async dispatch => {
    const request = internalAPI.createSignUpChargebeePaymentIntent(body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return response.data
    }

    return null
  }

export const getChargebeeEstimates =
  (spaceId: number): Thunk<ChargebeeEstimatesMap | null> =>
  async dispatch => {
    const request = internalAPI.getChargebeeEstimates(spaceId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return Object.fromEntries(
        response.data.estimates.map(({ planType, estimate }) => [planType, estimate]),
      ) as ChargebeeEstimatesMap
    }

    return null
  }

export const getChargebeeEstimate =
  (spaceId: number): Thunk<ChargebeeEstimate | null> =>
  async dispatch => {
    const request = internalAPI.getChargebeeEstimate(spaceId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return response.data.estimate
    }

    return null
  }

export const createChargebeePaymentIntent =
  (spaceId: number): Thunk<internalAPI.CreatePaymentIntentResponse | null> =>
  async dispatch => {
    const request = internalAPI.createChargebeePaymentIntent(spaceId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return response.data
    }

    return null
  }

export const createChargebeePaymentSource =
  (
    spaceId: number,
    paymentIntentId: string,
  ): Thunk<internalAPI.CreateChargebeePaymentSourceResponse | null> =>
  async dispatch => {
    const request = internalAPI.createChargebeePaymentSource(spaceId, paymentIntentId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { space } = response.data
      dispatch(spaceActions.upsertOne(space))

      return response.data
    }

    return null
  }

export const selectProductVersion =
  (
    spaceId: number,
    body: internalAPI.SelectProductVersionBody,
  ): RequestThunk<typeof internalAPI.selectProductVersion> =>
  async dispatch => {
    const request = internalAPI.selectProductVersion(spaceId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { space } = response.data
      dispatch(spaceActions.upsertOne(space))
    }

    return response
  }
