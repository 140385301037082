import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { Channel, ChannelUser, Invitee, Space, SpaceMemberRole, SpaceUser } from '@/types/entities'
import { AccessResponse } from './auth'
import { UserResponse } from './users'

export interface SpacesResponse {
  spaces: Space[]
}

export interface SpaceResponse {
  space: Space
}

export interface InviteResponse {
  user: SpaceUser
  channels: Channel[]
  channelsUsers: ChannelUser[]
  space: Space
}

export interface InviteeResponse {
  space: Space
  user: Invitee
}

export interface JoinResponse extends AccessResponse {
  space: Space
  channels: Channel[]
}

export interface UpdateSpace {
  name: string
  iconImageFileId?: number | null
}

export interface CreateSpace {
  spaceName: string
  partnerFirstName: string
  partnerLastName: string
  partnerEmailAddress?: string
  role: string
}

type InviteeFields = {
  firstName: string
  lastName: string
  emailAddress: string
  timezoneName: string | null
}

export type JoinFields = Partial<
  InviteeFields & {
    password: string
    tosAccepted: boolean
  }
>

export type InviteFields = {
  role: SpaceMemberRole
  createHub: boolean
}

export type InviteUser = InviteFields & InviteeFields & { inviteNote?: string }
export type InviteUserById = InviteFields & { emailAddress?: string }

export const createSpace = (create: CreateSpace) =>
  new Request<SpaceResponse>({
    authentication: 'PRIVATE',
    body: create,
    method: 'POST',
    url: 'v2/spaces',
  })

export const getSpacesList = (updatedSince: string | null, paging: PagingRequest) =>
  new Request<SpacesResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['updatedSince', updatedSince]],
    url: 'v1/spaces',
  })

export const getSpace = (spaceId: number) =>
  new Request<SpaceResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    url: `v1/spaces/${spaceId}`,
  })

export const updateSpace = (spaceId: number, updates: UpdateSpace) =>
  new Request<SpaceResponse>({
    authentication: 'PRIVATE',
    body: updates,
    method: 'PUT',
    url: `v1/spaces/${spaceId}`,
  })

export const inviteUser = (spaceId: number, body: InviteUser) =>
  new Request<InviteResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v2/spaces/${spaceId}/invite`,
  })

export const inviteUserById = (spaceId: number, userId: number, body: InviteUserById) =>
  new Request<InviteResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v2/spaces/${spaceId}/users/${userId}/invite`,
  })

export const createUser = (spaceId: number, body: InviteUser) =>
  new Request<InviteResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v1/spaces/${spaceId}/users`,
  })

export const getInvitee = (joinToken: string) =>
  new Request<InviteeResponse>({
    authentication: 'PUBLIC',
    method: 'GET',
    url: `v1/space-invitations/${joinToken}`,
  })

export const joinSpace = (joinToken: string, body: JoinFields) =>
  new Request<JoinResponse>({
    authentication: 'PUBLIC',
    body: { ...body, joinToken },
    method: 'POST',
    url: 'v2/spaces/join',
  })

export const removeUserFromSpace = (spaceId: number, userId: number) =>
  new Request<UserResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/users/${userId}`,
  })

export const deleteSpace = (spaceId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}`,
  })

export const addSpaceAdministrator = (spaceId: number, userId: number) =>
  new Request<UserResponse>({
    authentication: 'PRIVATE',
    method: 'POST',
    url: `v1/spaces/${spaceId}/administrators/${userId}`,
  })

export const removeSpaceAdministrator = (spaceId: number, userId: number) =>
  new Request<UserResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/administrators/${userId}`,
  })

export const updateRoleInSpace = (spaceId: number, userId: number, role: string) =>
  new Request<UserResponse>({
    authentication: 'PRIVATE',
    body: { role },
    method: 'PUT',
    url: `v1/spaces/${spaceId}/users/${userId}/role`,
  })
