import React, { DragEventHandler } from 'react'
import { View, ViewStyle } from 'react-native'
import Icon from '@/components/core/Icon'
import { getColor } from '@/core/constants/colors'
import { FileDropAreaProps } from './types'

function FileDropArea({ children, onFilesDropped }: FileDropAreaProps) {
  const [dragAreaVisible, setDragAreaVisible] = React.useState(false)

  const showDragArea = React.useCallback<DragEventHandler>(event => {
    event.preventDefault()
    setDragAreaVisible(true)
  }, [])

  const hideDragArea = React.useCallback<DragEventHandler>(event => {
    event.preventDefault()
    setDragAreaVisible(false)
  }, [])

  const handleDrop = React.useCallback<DragEventHandler>(
    event => {
      hideDragArea(event)
      onFilesDropped(Array.from(event.dataTransfer.files))
    },
    [hideDragArea, onFilesDropped],
  )

  const dropOverlayStyle = React.useMemo<ViewStyle>(
    () => ({
      alignItems: 'center',
      backgroundColor: dragAreaVisible ? getColor('ModalBG') : 'transparent',
      bottom: 0,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
    }),
    [dragAreaVisible],
  )

  return (
    <div
      onDragEnter={showDragArea}
      onDragLeave={hideDragArea}
      onDragOver={showDragArea}
      onDrop={handleDrop}
      style={styles.contentWrapper}
    >
      {children}
      <View style={dropOverlayStyle}>
        {dragAreaVisible ? <Icon color="white" name="paperclip" size={128} /> : null}
      </View>
    </div>
  )
}

const styles = {
  contentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as const,
    minHeight: 0,
  },
}

// ts-unused-exports:disable-next-line
export default React.memo(FileDropArea)
