import { taskUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import { authSelectors } from '@/store/selectors'
import { Task } from '@/types/entities'
import { Thunk } from '@/types/store'
import { flagUserAttribute } from '../userAttributes'

export const flagTaskAttribute =
  (task: Task): Thunk<boolean> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState())

    easeInOut()

    if (taskUtils.isQuestion(task)) {
      return dispatch(flagUserAttribute('CREATED_QUESTION'))
    }

    if (task.assignedUserId === myId) {
      return dispatch(flagUserAttribute('CREATED_TASK'))
    }
    return dispatch(flagUserAttribute('ASSIGNED_TASK'))
  }
