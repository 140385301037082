import React from 'react'
import FileActionsContainer from '@/components/files/FileActionsContainer'
import FullScreenMediaContainer from '@/components/media/FullScreenMediaContainer'
import AlertContainer from '@/components/notifications/AlertContainer'
import NotificationsContainer from '@/components/notifications/NotificationsContainer'
import PromptContainer from '@/components/notifications/PromptContainer'

interface StackContextContainerProps {
  children: React.ReactNode
}

function StackContextContainer({ children }: StackContextContainerProps) {
  return (
    <NotificationsContainer>
      <AlertContainer>
        <PromptContainer>
          <FileActionsContainer>
            <FullScreenMediaContainer>{children}</FullScreenMediaContainer>
          </FileActionsContainer>
        </PromptContainer>
      </AlertContainer>
    </NotificationsContainer>
  )
}

export default React.memo(StackContextContainer)
