import { AppState } from 'react-native'
import { eventTypes } from '@/core/constants'
import { emitter } from '@/core/pubsub'
import { dateUtils as D, taskUtils, webNotificationUtils } from '@/core/utils'
import { notificationActions } from '@/store/actions'
import {
  appProfileSelectors,
  authSelectors,
  notificationSelectors,
  userSelectors,
} from '@/store/selectors'
import { SentMessage } from '@/types/entities'
import { AppDispatch, SyncThunk, Thunk } from '@/types/store'
import { showToast } from '../notifications'
import type { HandleNotification } from './types'

const ENABLE_ACTION = 'Enable'
const IGNORE_ACTION = 'Ignore'

// ts-unused-exports:disable-next-line
export const handleNotification: HandleNotification = event => async (dispatch, getState) => {
  if (!webNotificationUtils.hasNotifications()) {
    return
  }

  switch (event.type) {
    case 'message/created': {
      const { message } = event.payload
      const creator = userSelectors.byId(getState(), message.spaceId, message.creatorId)
      const shouldShow = dispatch(shouldShowNotification(message))

      if (shouldShow) {
        await dispatch(
          maybeShowNotification({
            body: message.content ?? '',
            icon: creator?.profilePictureUrl,
            title: `Message from ${creator?.firstName}`,
          }),
        )
      }
      break
    }
    case 'task/created': {
      const { task } = event.payload
      const myId = authSelectors.myId(getState())
      const shouldShow = dispatch(shouldShowNotification(task))

      if (shouldShow && myId === task.assignedUserId) {
        const creator = userSelectors.byId(getState(), task.spaceId, task.creatorId)
        const action = taskUtils.isQuestion(task)
          ? 'asked you a question'
          : 'assigned a task to you'
        await dispatch(
          maybeShowNotification({
            body: task.content ?? '',
            icon: creator?.profilePictureUrl,
            title: `${creator?.firstName} ${action}`,
          }),
        )
      }
      break
    }
    default:
  }
}

type NotificationData = {
  title: string
  body: string
  icon?: string | null
}

const maybeShowNotification =
  (notification: NotificationData): Thunk<void> =>
  async dispatch => {
    const permission = webNotificationUtils.getPermission()

    if (permission === 'granted') {
      showNotification(notification)
      return
    }

    if (permission === 'denied') {
      return
    }

    if (dispatch(shouldRequestPermission())) {
      dispatch(requestPermission(notification))
    }
  }

const shouldRequestPermission = (): SyncThunk<boolean> => (dispatch, getState) => {
  if (webNotificationUtils.getPermission() !== 'default') {
    return false
  }

  const ignoreWebNotifications = notificationSelectors.ignoreWebNotifications(getState())

  return ignoreWebNotifications === false
}

const requestPermission =
  (notification: NotificationData): Thunk<void> =>
  async dispatch => {
    dispatch(
      showToast({
        actions: [ENABLE_ACTION, IGNORE_ACTION],
        data: { event: eventTypes.ENABLE_DESKTOP_NOTIFICATIONS },
        duration: D.toMilliseconds.seconds(10),
        title: 'Would you like to enable desktop notifications?',
        type: 'info',
      }),
    )

    emitter.once(
      eventTypes.NOTIFICATION_ACTION,
      dispatch(handleNotificationAction(() => showNotification(notification))),
    )
  }

const handleNotificationAction =
  (grantedCallback: () => void) =>
  (dispatch: AppDispatch) =>
  async ({ action, data }: { action: string; data?: Record<string, any> }) => {
    if (data?.event === eventTypes.ENABLE_DESKTOP_NOTIFICATIONS) {
      if (action === ENABLE_ACTION) {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
          grantedCallback()
        }
      } else if (action === IGNORE_ACTION) {
        dispatch(notificationActions.setIgnoreWebNotifications())
      }
    }
  }

const shouldShowNotification =
  ({ spaceId, channelId, creatorId }: SentMessage): SyncThunk<boolean> =>
  (dispatch, getState) => {
    const myId = authSelectors.myId(getState())

    // Never show messages from self
    if (myId === creatorId) {
      return false
    }

    // Always show notifications if the app is backgrounded
    if (AppState.currentState !== 'active') {
      return true
    }

    if (dispatch(shouldRequestPermission())) {
      return true
    }

    const { channelId: focusedChannelId, spaceId: focusedSpaceId } =
      appProfileSelectors.currentFocus(getState())

    // Don't show notifications for the focused channel
    if (spaceId === focusedSpaceId && channelId === focusedChannelId) {
      return false
    }

    return true
  }

const showNotification = ({ title, body, icon }: NotificationData): Notification | null => {
  if (webNotificationUtils.getPermission() !== 'granted') {
    return null
  }
  return new Notification(title, { body, icon: icon || '/favicon.ico' })
}
