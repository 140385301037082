import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { getTaskColors } from '@/components/messages/MessagesList/constants'
import { taskUtils } from '@/core/utils'
import { Message, Task } from '@/types/entities'

interface TaskCommentsListProps {
  isFromMe: boolean
  messages?: Message[]
  task: Task
}

function TaskCommentsList({ task, messages, isFromMe }: TaskCommentsListProps) {
  if (!messages || messages.length === 0) {
    return null
  }

  const { foreground } = getTaskColors(isFromMe, taskUtils.isCompleted(task))

  return (
    <Box direction="column">
      {messages.map(message => (
        <Background
          key={message.id}
          borderTopColor={isFromMe ? 'silver' : 'koala'}
          borderTopWidth={1}
        >
          <Box gapAll="small">
            <Text color={foreground} size="h5">
              {message.content}
            </Text>
          </Box>
        </Background>
      ))}
    </Box>
  )
}

export default React.memo(TaskCommentsList)
