import React from 'react'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { fnUtils } from '@/core/utils'
import { ChargebeeInvoice } from '@/types/chargebee'
import Row from './Row'

interface InvoiceProps {
  color?: ColorName
  invoice: ChargebeeInvoice
  mapDescription?: (val: string) => string
}

function Invoice({ color = 'obsidian', invoice, mapDescription = fnUtils.identity }: InvoiceProps) {
  const { total, discounts: discountsRaw, lineItems } = invoice
  const discounts = discountsRaw || []

  return (
    <Box direction="column">
      {lineItems.map(({ entityId, description, quantity, amount }) => (
        <Row
          key={entityId}
          amount={amount}
          color={color}
          label={`${mapDescription(description)} x ${quantity}`}
        />
      ))}
      {discounts.map(({ entityId, amount, description }) => (
        <Row
          key={entityId}
          amount={amount}
          color={color}
          label={mapDescription(description)}
          negate
        />
      ))}
      <Row key="total" amount={total} border color={color} label="Total Monthly" />
    </Box>
  )
}

export default React.memo(Invoice)
