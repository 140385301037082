import React from 'react'
import { Animated, View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import BaseIconButton, { BaseIconButtonProps } from './BaseIconButton'
import Hoverable from './Hoverable'

interface DropButtonProps extends Omit<BaseIconButtonProps, 'name' | 'onPress' | 'size'> {
  open: boolean
  onPress?: (open: boolean) => void
  size?: number
  duration?: number
  hoveredColor?: ColorName
}

function DropButton({
  color = 'obsidian',
  duration = 300,
  hoveredColor,
  kind = 'solid',
  onPress,
  open,
  padding = 0,
  shape = 'square',
  size = 16,
  ...iconButtonProps
}: DropButtonProps) {
  const handlePress = React.useCallback(() => {
    if (onPress) {
      onPress(!open)
    }
  }, [onPress, open])
  const firstRenderRef = React.useRef(true)
  const rotateZAnimation = React.useRef(new Animated.Value(0))
  const rotateZInterpolationRef = React.useRef(
    rotateZAnimation.current.interpolate({ inputRange: [0, 90], outputRange: ['0deg', '90deg'] }),
  )

  React.useEffect(() => {
    const toValue = open ? 90 : 0
    let timingDuration = duration

    if (firstRenderRef.current) {
      firstRenderRef.current = false
      if (open) {
        timingDuration = 0
      }
    }

    Animated.timing(rotateZAnimation.current, {
      duration: timingDuration,
      toValue,
      useNativeDriver: true,
    }).start()
  }, [open, duration])

  return (
    <Hoverable>
      {hovered => (
        <View
          style={{
            backgroundColor: hovered ? getColor('heather') : 'transparent',
            borderRadius: 8,
          }}
        >
          <Animated.View
            style={{
              position: 'relative',
              transform: [{ rotateZ: rotateZInterpolationRef.current }],
            }}
          >
            <BaseIconButton
              color={hovered && hoveredColor ? hoveredColor : color}
              kind={kind}
              name="chevron-right"
              onPress={onPress ? handlePress : undefined}
              padding={padding}
              shape={shape}
              size={size}
              {...iconButtonProps}
            />
          </Animated.View>
        </View>
      )}
    </Hoverable>
  )
}

export default React.memo(DropButton)
