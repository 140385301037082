import React from 'react'
import { StyleSheet } from 'react-native'
import Background from '@/components/core/Background'
import TextWithLinks from '@/components/core/TextWithLinks'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import QuestionResponseForm from '@/components/questions/QuestionResponseForm'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import QuestionResponseContext, {
  useQuestionResponseForm,
} from '@/contexts/QuestionResponseContext'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { taskUtils } from '@/core/utils'
import { QuestionTask, Task } from '@/types/entities'
import { Action } from '@/types/generics'
import { SelectTask } from '@/types/tasks'
import TaskSelectCheckbox from './TaskSelectCheckbox'

interface QuestionContentProps {
  task: QuestionTask
  onPress: Action<Task>
  isSelected: boolean
  onSelectTask?: SelectTask
}

function QuestionContent({ task, onPress, onSelectTask, isSelected }: QuestionContentProps) {
  const handlePress = React.useCallback(() => onPress(task), [onPress, task])
  const { taskRelationship, questionBackground } = useTasksListViewContext()
  const questionResponseContextValue = useQuestionResponseForm(task)
  const completed = taskUtils.isCompleted(task)

  return (
    <Touchable feedback="opacity" onPress={handlePress} style={styles.container}>
      {onSelectTask ? (
        <Box gapRight="medium" justifyContent="center">
          <TaskSelectCheckbox isSelected={isSelected} onSelect={onSelectTask} taskId={task.id} />
        </Box>
      ) : null}
      <Background borderRadius={10} color={completed ? 'silver' : questionBackground} flex={1}>
        <Box direction="row" gapAll="medium">
          {taskRelationship === 'MINE' ? (
            <Box gapRight="medium">
              <UserProfilePicture size="large" spaceId={task.spaceId} userId={task.creatorId} />
            </Box>
          ) : null}
          <Box flex={1}>
            <Box gapBottom="small">
              <TextWithLinks size="h5">{task.content}</TextWithLinks>
            </Box>
            <QuestionResponseContext.Provider value={questionResponseContextValue}>
              <QuestionResponseForm
                disabled="WHEN_ANSWERED"
                foregroundColor="obsidian"
                task={task}
              />
            </QuestionResponseContext.Provider>
          </Box>
        </Box>
      </Background>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})

export default React.memo(QuestionContent)
