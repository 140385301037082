import { Configuration, configuration as baseConfig } from './base'

const apiServer = 'api.staging.emmre.com'
const appServer = 'app.staging.emmre.com'
const rtmServer = 'rtm.staging.emmre.com'

export const getConfiguration = (): Configuration => ({
  ...baseConfig,
  amplitudeApiKey: '70da567782d1808705bf6ea35ac16749',
  apiURLRoot: `https://${apiServer}`,
  appURLRoot: `https://${appServer}`,
  chargebeePublishableKey: 'live_tHJyuO1YycdhXhHLB6B8gs1DikTd2PV8p',
  chargebeeSite: 'emmre',
  checkForUpdates: true,
  features: {
    ...baseConfig.features,
    channels: false,
    giphy: true,
    paidSignUp: false,
  },
  giphyApiKey: 'clAlNSjlLlS0jboQnTWmW9bHNIeEvd09',
  logLevel: 'off',
  logToSentryLevel: 'warning',
  rtmURLRoot: `wss://${rtmServer}`,
  sentryDSN: 'https://130f2cdb8baf4862b46e17c8102d7163@o315213.ingest.sentry.io/5893434',
  sentryDebug: false,
  stonlyWidgetId: 'b5ed7a22-7ef7-11ec-82f1-064cee365e5a',
  versionSuffix: 'S',
})
