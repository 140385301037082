import * as Updates from 'expo-updates'
import { isWeb } from './platform'

export const reloadApp = async () => {
  if (isWeb) {
    global.location.reload()
  } else {
    await Updates.reloadAsync()
  }
}
