import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useAmplitude } from '@/hooks'
import { FilterGroup } from '@/types/tasks'

interface FilterGroupListItemProps {
  optionGroup: FilterGroup
  isSelected: boolean
  onSelect: (optionGroup: FilterGroup) => void
}

function FilterGroupListItem({ optionGroup, isSelected, onSelect }: FilterGroupListItemProps) {
  const handleSelect = React.useCallback(() => onSelect(optionGroup), [onSelect, optionGroup])

  const eventProperties = React.useMemo(
    () => ({ 'filter by': optionGroup.label }),
    [optionGroup.label],
  )

  const wrappedHandleSelect = useAmplitude(handleSelect, 'filter task list', eventProperties)

  return (
    <Touchable feedback="highlight" onPress={wrappedHandleSelect}>
      <Box gapHorizontal="medium" gapVertical="small">
        <Text
          color={isSelected ? 'obsidian' : 'anchor'}
          size="h4"
          weight={isSelected ? 'medium' : 'regular'}
        >
          {optionGroup.label}
        </Text>
      </Box>
    </Touchable>
  )
}

export default React.memo(FilterGroupListItem)
