import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { SummaryFieldName } from '../../types'
import { getSummaryFieldConfiguration } from '../../utils'

interface ViewFieldProps {
  content: string | null
  fieldName: SummaryFieldName
}

function ViewField({ content, fieldName }: ViewFieldProps) {
  const hasContent = !!content
  const config = getSummaryFieldConfiguration(fieldName)
  const center = config.textAlign === 'center'

  return (
    <Box alignItems="flex-start" direction="row">
      {center ? <Box flex={1} /> : null}
      <Box flex={center ? undefined : 1}>
        <Text
          align={config.textAlign}
          color={hasContent ? 'obsidian' : 'iron'}
          size={config.fontSize}
          weight={config.fontWeight}
        >
          {content || config.placeholder}
        </Text>
      </Box>
      {center ? <Box flex={1} /> : null}
    </Box>
  )
}

export default React.memo(ViewField)
