import { useCachedSelector } from '@/hooks'
import { userSelectors } from '@/store/selectors'
import { Channel } from '@/types/entities'

export const useChannelUsersList = (channel: Channel) => {
  const users = useCachedSelector(userSelectors.byIdsSelector, [channel.spaceId, channel.userIds])

  return {
    users,
  }
}
