import {
  useAlertActions,
  useAlertActionsAsync,
  useDestructiveAlert,
  useDestructiveAlertAsync,
} from './alerts'

// On android and web we always use alerts
export const useActions = useAlertActions
export const useActionsAsync = useAlertActionsAsync

export { useAlertActionsAsync, useDestructiveAlert, useDestructiveAlertAsync }
