import * as FileSystem from 'expo-file-system'
import { getType } from 'mime'
import { Attachment } from '@/types/api'
import { last } from '../collections'
import { isIOS, isWeb } from '../platform'
import { getRandomUUID } from '../uuid'

const LOCAL_PREFIX = 'file://'

export const isLocalFile = (uri: string) => uri.startsWith(LOCAL_PREFIX)

export const joinPath = (root: string, stem: string): string =>
  root.endsWith('/') ? `${root}${stem}` : `${root}/${stem}`

export const getExtension = (uri: string) => {
  const filename = getFilename(uri)
  if (filename.includes('.')) {
    return last(filename.split('.'))
  }
  return ''
}

export const getContentType = (uri: string): string => {
  if (isDataURI(uri)) {
    return getDataURIContentType(uri)
  }

  const ext = getExtension(uri)

  const mimeType = getType(ext)

  if (mimeType === null) {
    return 'application/octet-stream'
  }

  return mimeType
}

export const isDataURI = (uri: string): boolean => uri.startsWith('data:')

const getDataURIContentType = (uri: string): string => {
  const matches = /^data:(.*?);/.exec(uri)

  if (matches) {
    const contentType = matches[1]
    return contentType
  }

  throw new Error(`Unable to extract mime type from data uri: ${uri.slice(0, 50)}...`)
}

export const dataURIToFile = (dataURI: string, filename: string): File => {
  if (!isDataURI(dataURI)) {
    throw new Error('Invalid Data URI')
  }
  // data URI is data:mime/type;base64,b64data
  const b64data = dataURI.split(',')[1]
  const bstr = atob(b64data)
  const u8s = new Uint8Array(bstr.length)

  for (let i = 0; i < bstr.length; i += 1) {
    u8s[i] = bstr.charCodeAt(i)
  }

  return new File([u8s], filename, { type: getDataURIContentType(dataURI) })
}

// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
export const fileToDataURI = (blob: Blob): Promise<string> =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(blob)
  })

export const getContentGroup = (uri: string): string => {
  const contentType = getContentType(uri)
  return contentType.split('/', 1)[0]
}

export const isMedia = (uri: string): boolean => {
  const fileType = getContentGroup(uri)
  return !!fileType && (fileType === 'image' || fileType === 'video')
}

export const getFilename = (uri: string) => {
  const [path] = uri.split('?')
  return last(path.split('/'))
}

export const mapURIForUploading = (uri: string) => {
  if (isIOS) {
    return removeLocalPrefix(uri)
  }
  return uri
}

export const buildAttachment = (uri: string, filename: string | null = null): Attachment => ({
  filename: filename || getFilename(uri),
  status: 'PENDING',
  temporaryId: getRandomUUID(),
  uri,
})

export const walk = async (
  root: string,
  fileCallback: (metadata: FileSystem.FileInfo) => Promise<void>,
) => {
  if (isWeb) {
    return
  }

  const rootMeta = await FileSystem.getInfoAsync(root)

  if (!rootMeta.isDirectory) {
    return
  }

  const entries = await FileSystem.readDirectoryAsync(root)

  for (const entry of entries) {
    const entryPath = joinPath(root, entry)
    const entryMeta = await FileSystem.getInfoAsync(entryPath)

    if (entryMeta.exists) {
      if (entryMeta.isDirectory) {
        await walk(entryPath, fileCallback)
      } else {
        await fileCallback(entryMeta)
      }
    }
  }
}

const removeLocalPrefix = (uri: string) => uri.slice(LOCAL_PREFIX.length)
