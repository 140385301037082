import React from 'react'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import { fieldNames } from '../constants'
import { useTaskFormField } from '../hooks'

interface TaskNotesModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskNotesModal({ isOpen, onClose, localModal = false }: TaskNotesModalProps) {
  const { value, setValue } = useTaskFormField<string>(fieldNames.notes)
  const [newNotes, setNewNotes] = React.useState(value || '')
  const handleCancel = React.useCallback(() => {
    onClose(true)
  }, [onClose])

  const handleDone = React.useCallback(() => {
    setValue(newNotes)
    onClose(false)
  }, [setValue, newNotes, onClose])

  React.useEffect(() => {
    if (!isOpen) {
      setNewNotes(value)
    }
  }, [isOpen, setNewNotes, value])

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      minHeight={0}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Notes"
    >
      <Box gapAll="medium">
        <TextInput
          autoFocus
          kind="inset"
          maxHeight={350}
          multiline
          numberOfLines={10}
          onChangeText={setNewNotes}
          textAlignVertical="top"
          value={newNotes}
        />
      </Box>
    </StandardModal>
  )
}

export default React.memo(TaskNotesModal)
