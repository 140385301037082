import { Task } from '@/types/entities'

export const isActive = (task: Task) => task.status === 'ACTIVE'
export const isArchived = (task: Task) => task.status === 'ARCHIVED'
export const isDeleted = (task: Task) => task.status === 'DELETED'
export const isMoved = (task: Task) => task.status === 'MOVED'
export const isAccepted = (task: Task) => !!task.acceptedAt
export const isCompleted = (task: Task) => !!task.completedAt
export const isAssignedToUser = (task: Task, userId: number) => task.assignedUserId === userId
export const isCreatedByUser = (task: Task, userId: number) => task.creatorId === userId
