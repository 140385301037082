import React from 'react'
import DropIndicator from './DropIndicator'
import { useWebDraggableFlatListContext } from './WebDraggableFlatListContext'

interface WebDraggableListItemProps {
  children: React.ReactNode
  index: number
  draggable: boolean
}

const handleDragEnter = (e: any) => e.preventDefault()

function WebDraggableListItem({ children, index, draggable }: WebDraggableListItemProps) {
  const [demphasize, setDemphasize] = React.useState(false)

  const { fromIndex, dragEnd, dragOver, dragStart, drop, toIndex } =
    useWebDraggableFlatListContext()
  const isDragOver = toIndex === index
  const isDragging = fromIndex === index

  const handleDragStart = React.useCallback(
    (e: any) => {
      if (!draggable) {
        e.preventDefault()
        return
      }
      dragStart(index)
      setTimeout(() => setDemphasize(true))
    },
    [dragStart, index, setDemphasize, draggable],
  )

  const handleDragEnd = React.useCallback(() => {
    dragEnd()
    setDemphasize(false)
  }, [dragEnd, setDemphasize])

  const handleDragOver = React.useCallback(
    (e: any) => {
      e.preventDefault() // Must call preventDefault to indicate this is a valid drop target
      dragOver(index)
    },
    [dragOver, index],
  )

  const handleDrop = React.useCallback(() => drop(index), [drop, index])

  return (
    <div
      draggable={draggable ? 'true' : 'false'}
      onDragEnd={handleDragEnd}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      style={{ display: demphasize ? 'none' : undefined }}
    >
      {isDragOver && !isDragging ? <DropIndicator /> : null}
      {children}
    </div>
  )
}

export default React.memo(WebDraggableListItem)
