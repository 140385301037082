import React from 'react'
import Box from '@/components/layouts/Box'
import BaseHeader, { BaseHeaderProps } from './BaseHeader'

interface CenteredHeaderProps extends Omit<BaseHeaderProps, 'children'> {
  centerContent?: React.ReactNode
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  sideWidth?: number
}

function CenteredHeader({
  centerContent,
  leftContent,
  rightContent,
  sideWidth,
  ...baseHeaderProps
}: CenteredHeaderProps) {
  return (
    <BaseHeader {...baseHeaderProps}>
      <Box
        alignItems="center"
        direction="row"
        flex={sideWidth ? undefined : 1}
        height="100%"
        justifyContent="flex-start"
        width={sideWidth || undefined}
      >
        {leftContent}
      </Box>
      <Box
        alignItems="center"
        flexGrow={sideWidth ? 1 : 0}
        flexShrink={1}
        gapHorizontal="medium"
        height="100%"
        justifyContent="center"
      >
        {centerContent}
      </Box>
      <Box
        alignItems="center"
        direction="row"
        flex={sideWidth ? undefined : 1}
        height="100%"
        justifyContent="flex-end"
        width={sideWidth || undefined}
      >
        {rightContent}
      </Box>
    </BaseHeader>
  )
}

export default React.memo(CenteredHeader)
