import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { stringUtils as S } from '@/core/utils'
import { RootState } from '@/store'
import { Summary, SummaryStatus } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, summaryId: number): string => `${spaceId}:${summaryId}`

const adapter = createEntityAdapter<Summary>({
  selectId: summary => buildId(summary.spaceId, summary.id),
  sortComparer: (a, b) => S.sortDescending(a.createdAt, b.createdAt),
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/summaries',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    setOne: adapter.setOne,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.summaries)

const selectById = (state: RootState, spaceId: number, summaryId: number) =>
  state.entities.summaries.entities[buildId(spaceId, summaryId)]

const byChannelIdSelector = (
  spaceId: number,
  channelId: number,
  status: SummaryStatus | SummaryStatus[] | null = null,
) =>
  createSelector(sliceSelectors.selectAll, summaries => {
    const statusSet = status === null ? null : toStatusSet(status)
    return summaries.filter(
      summary =>
        summary.spaceId === spaceId &&
        summary.channelId === channelId &&
        (statusSet === null || statusSet.has(summary.status)),
    )
  })

const toStatusSet = (status: SummaryStatus | SummaryStatus[]): Set<SummaryStatus> =>
  Array.isArray(status) ? new Set(status) : new Set([status])

const hasSummariesSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectAll, summaries =>
    summaries.some(summary => summary.spaceId === spaceId && summary.channelId === channelId),
  )

const selectors = {
  byChannelIdSelector,
  byId: selectById,
  hasSummariesSelector,
}

export { actions, selectors }
export default reducer
