import React from 'react'
import { channelUtils } from '@/core/utils'
import { useAppDispatch, useCachedSelector } from '@/hooks'
import { useResolvedChannel } from '@/hooks/channels'
import { formActions } from '@/store/actions'
import { entitySelectors, formSelectors } from '@/store/selectors'
import { channelThunks } from '@/thunks'
import { Channel } from '@/types/entities'
import { Action } from '@/types/generics'

export const useEditChannelModal = (
  channelId: number,
  spaceId: number,
  onClose: () => void,
  onEditUser: (userId: number) => void,
  onLeave: Action<Channel>,
  formName: string,
) => {
  const dispatch = useAppDispatch()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const channel = useResolvedChannel(spaceId, channelId)
  const channelUser = useCachedSelector(entitySelectors.myChannelUserSelector, [spaceId, channelId])
  const hasChanges = useCachedSelector(formSelectors.hasChangesSelector, [formName])

  const handleUpdate = React.useCallback(async () => {
    if (!hasChanges) {
      onClose()
      return
    }

    setIsSubmitting(true)
    const updatedChannel = await dispatch(
      channelThunks.updateChannelForm(spaceId, channelId, formName),
    )
    setIsSubmitting(false)
    if (updatedChannel) {
      onClose()
    }
  }, [spaceId, channelId, hasChanges, dispatch, onClose, formName])

  const handleArchiveChannel = React.useCallback(async () => {
    setIsSubmitting(true)
    await dispatch(channelThunks.archiveChannel(spaceId, channelId))
    setIsSubmitting(false)
  }, [spaceId, channelId, dispatch])

  const handleEditUser = React.useCallback(
    (userId: number) => {
      onClose()
      onEditUser(userId)
    },
    [onClose, onEditUser],
  )

  React.useEffect(() => {
    dispatch(
      formActions.initialize({
        fields: { name: channel?.name || null, userIds: channel?.userIds || [] },
        formName,
      }),
    )
  }, [dispatch, channel, formName])

  // TODO: Make these channel actions instead of logic on client
  const canEdit = !!channel && channelUtils.isGroup(channel)
  const canLeave = !!channel && channelUtils.isGroup(channel) && channel.userIds.length > 1

  return {
    canEdit,
    canLeave,
    channel,
    channelUser,
    handleArchiveChannel,
    handleEditUser,
    handleUpdate,
    hasChanges,
    isSubmitting,
  }
}
