import React from 'react'
import IconButton from '@/components/core/IconButton'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import EmojiPickerButton from '@/components/messages/EmojiPickerButton'
import ContentInput, { RefType } from '@/components/messages/MessageTextInput/ContentInput'
import { useAlertContext } from '@/contexts/AlertContext'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { actionUtils, platformUtils } from '@/core/utils'
import { useAppendEmojiToFormField } from '@/hooks/emoji'
import { useInviteUser } from '@/hooks/restrictedActions'
import { useFocusTextInput } from '@/hooks/textInput'
import { useChannelPartner } from '@/hooks/users'
import { ResolvedChannel } from '@/types/entities'
import InputBorder from './InputBorder'

const EVENT_PROPERTIES = { 'message type': 'comment' }

interface TaskChatInputProps {
  channel?: ResolvedChannel
  formName: string
  onFocus: () => void
  handleSubmit: () => void
  disabled: boolean
  disabledReason?: string
  placeholder: string
  autoFocus: boolean
}

const TaskChatInput = React.forwardRef(
  (
    {
      channel,
      formName,
      onFocus,
      handleSubmit,
      disabled,
      disabledReason,
      placeholder,
      autoFocus,
    }: TaskChatInputProps,
    ref: RefType,
  ) => {
    const showAlert = useAlertContext()
    const { interactionMode } = useTaskFormContext()
    const showDisabledReason = React.useCallback(
      () =>
        disabledReason && actionUtils.showNoActionAlert(showAlert, 'Cannot Send', disabledReason),
      [showAlert, disabledReason],
    )
    const partner = useChannelPartner(channel)
    const handleInviteUser = useInviteUser(partner)
    const sendDisabled =
      partner?.membershipType === 'INVITED' || partner?.membershipType === 'NOT_INVITED'

    const inviteText =
      partner?.membershipType === 'INVITED'
        ? `You can have a conversation here with ${partner?.firstName} once your invite has been accepted. Invite again `
        : `To have a conversation with ${partner?.firstName} in Emmre, invite `

    const fieldName = interactionMode === 'create' ? 'comment' : 'content'
    const refocusInput = useFocusTextInput(ref as any)
    const handlePickEmoji = useAppendEmojiToFormField(formName, fieldName, refocusInput)

    return (
      <InputBorder>
        <Box flex={1} gapAll="small">
          {sendDisabled ? (
            <Text color="iron" size="h5">
              {inviteText}
              <Link color="emmre-blue" label="here" onPress={handleInviteUser} size="h5" />.
            </Text>
          ) : (
            <ContentInput
              ref={ref}
              autoFocus={autoFocus}
              channel={channel}
              fieldName={fieldName}
              formName={formName}
              inputMode="message"
              onFocus={onFocus}
              onSubmit={platformUtils.isWeb ? handleSubmit : undefined}
              placeholder={placeholder}
            />
          )}
        </Box>
        <Box alignItems="center" alignSelf="flex-end" direction="row">
          <Box gapHorizontal="small">
            <EmojiPickerButton onPick={handlePickEmoji} size="h2" />
          </Box>
          {sendDisabled ? (
            <IconButton disabled name="arrow-up" />
          ) : (
            <IconButton
              amplitudeEventName="send message"
              amplitudeEventProperties={EVENT_PROPERTIES}
              disabled={disabled}
              name="arrow-up"
              onPress={handleSubmit}
              onPressDisabled={disabledReason ? showDisabledReason : undefined}
            />
          )}
        </Box>
      </InputBorder>
    )
  },
)

export default React.memo(TaskChatInput)
