import React from 'react'
import Button from '@/components/core/Button'

interface NotificationActionProps {
  onAction: (action: string) => void
  action: string
}

function NotificationAction({ action, onAction }: NotificationActionProps) {
  const handleAction = React.useCallback(() => onAction(action), [onAction, action])

  return <Button kind="transparentDark" label={action} onPress={handleAction} />
}

export default React.memo(NotificationAction)
