import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

function OpenEndedQuestionEditor() {
  return (
    <Background borderRadius={8} color="pastel-purple" flexDirection="row">
      <Box flex={1} gapAll="medium" height={60}>
        <Text color="anchor">Response to be entered here...</Text>
      </Box>
    </Background>
  )
}

export default React.memo(OpenEndedQuestionEditor)
