import React from 'react'
import ErrorBoundary from '@/components/core/ErrorBoundary'
import { Measurement, RenderListItemContainer } from '@/types/components'
import { File, Message } from '@/types/entities'
import FileListItemError from './FileListItemError'
import GenericFileListItem from './GenericFileListItem'
import ImageFileListItem from './ImageFileListItem'
import VideoFileListItem from './VideoFileListItem'
import { GenericFileMode } from './types'

interface FileListItemProps {
  file: File
  genericFileMode: GenericFileMode
  index: number
  renderContainer?: RenderListItemContainer
  size: Measurement
  playVideoInline: boolean
  message?: Message
}

function FileListItem({
  file,
  size,
  renderContainer,
  genericFileMode,
  index,
  playVideoInline,
  message,
}: FileListItemProps) {
  let content: JSX.Element

  if (file.contentType.startsWith('image/')) {
    content = (
      <ErrorBoundary fallback={<FileListItemError label="image" />}>
        <ImageFileListItem file={file} message={message} size={size} />
      </ErrorBoundary>
    )
  } else if (file.contentType.startsWith('video/')) {
    content = (
      <ErrorBoundary fallback={<FileListItemError label="video" />}>
        <VideoFileListItem
          file={file}
          message={message}
          playVideoInline={playVideoInline}
          size={size}
        />
      </ErrorBoundary>
    )
  } else {
    content = <GenericFileListItem file={file} mode={genericFileMode} size={size} />
  }

  return renderContainer ? renderContainer(content, index, size) : content
}

export default React.memo(FileListItem)
