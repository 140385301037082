import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { getFontSize } from '@/core/constants/typography'
import Icon, { IconProps } from './Icon'

export type ContainedIconShape = 'circle' | 'square'

export interface ContainedIconProps extends IconProps {
  backgroundColor?: ColorName
  padding?: number
  shape?: ContainedIconShape
  borderStyle?: 'dashed' | 'solid'
  borderRadius?: number
  borderWidth?: number
  borderColor?: ColorName
  dropShadow?: boolean
  bumpVerticalAlignUp?: boolean
}

function ContainedIcon({
  size = 'h4',
  shape = 'circle',
  backgroundColor = 'transparent',
  padding = 10,
  color,
  borderStyle,
  borderWidth,
  borderColor,
  dropShadow = false,
  bumpVerticalAlignUp,
  borderRadius,
  ...iconProps
}: ContainedIconProps) {
  const bgColor = backgroundColor
  const fgColor = color

  const style = React.useMemo<ViewStyle>(() => {
    if (shape === 'circle') {
      const diameter = (size ? getFontSize(size) : 0) + padding * 2 + (borderWidth || 0) * 2
      return {
        alignItems: 'center',
        backgroundColor: getColor(bgColor),
        borderColor: borderColor ? getColor(borderColor) : undefined,
        borderRadius: diameter / 2,
        borderStyle,
        borderWidth,
        height: diameter,
        justifyContent: 'center',
        position: 'relative',
        top: bumpVerticalAlignUp ? -1 : 0,
        width: diameter,
      }
    }
    return {
      alignItems: 'center',
      backgroundColor: getColor(bgColor),
      borderColor: borderColor ? getColor(borderColor) : undefined,
      borderRadius,
      borderStyle,
      borderWidth,
      justifyContent: 'center',
      padding,
      position: 'relative',
      top: bumpVerticalAlignUp ? -1 : 0,
    }
  }, [
    bumpVerticalAlignUp,
    shape,
    size,
    bgColor,
    padding,
    borderStyle,
    borderWidth,
    borderColor,
    borderRadius,
  ])

  return (
    <View style={[style, dropShadow ? styles.dropShadow : undefined]}>
      <Icon {...iconProps} color={fgColor} size={size} />
    </View>
  )
}

const styles = StyleSheet.create({
  dropShadow: {
    elevation: 5,
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.6,
    shadowRadius: 5,
  },
})
export default React.memo(ContainedIcon)
