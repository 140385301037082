import React from 'react'
import { StyleSheet } from 'react-native'
import ActionableImage from '@/components/media/ActionableImage'
import { Measurement } from '@/types/components'
import { File, Message } from '@/types/entities'
import FileListItemError from './FileListItemError'

interface ImageFileListItemProps {
  file: File
  size: Measurement
  message?: Message
}

const duration = 'short'

function ImageFileListItem({ file, size, message }: ImageFileListItemProps) {
  const uri = file.url

  return (
    <ActionableImage
      ErrorComponent={<FileListItemError label="image" />}
      duration={duration}
      file={file}
      message={message}
      size={size}
      style={styles.image}
      uri={uri}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    borderRadius: 5,
  },
})

export default React.memo(ImageFileListItem)
