import React from 'react'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import TaskChannel from './TaskChannel'
import TaskFormExpandButton from './TaskFormExpandButton'

function TaskChannelLine() {
  const { taskFormSize, setTaskFormSize, canClose, close } = useTaskFormContext()
  const isOpen = taskFormSize === 'LARGE'
  const handleToggle = React.useCallback(() => {
    if (setTaskFormSize) {
      if (isOpen) {
        setTaskFormSize('MEDIUM')
      } else {
        setTaskFormSize('LARGE')
      }
    } else if (canClose) {
      close()
    }
  }, [setTaskFormSize, isOpen, canClose, close])

  const canChangeSize = !!setTaskFormSize
  const showButton = canClose || canChangeSize

  return (
    <Box alignItems="center" direction="row" gapHorizontal="large" gapTop="medium">
      <Box flex={1}>
        <TaskChannel />
      </Box>
      {showButton ? <TaskFormExpandButton isExpanded={isOpen} onToggle={handleToggle} /> : null}
    </Box>
  )
}

export default React.memo(TaskChannelLine)
