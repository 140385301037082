import React from 'react'
import Button from '@/components/core/Button'
import Link from '@/components/core/Link'
import Box from '@/components/layouts/Box'

interface EditItemHeaderProps {
  handleCancel: () => void
  handleUpdate: () => void
  disabled: boolean
}

function EditItemHeader({ handleCancel, handleUpdate, disabled }: EditItemHeaderProps) {
  return (
    <Box alignItems="center" direction="row" gapBottom="medium" justifyContent="space-between">
      <Link label="Cancel" onPress={handleCancel} size="h5" />
      <Box alignItems="center" direction="row">
        <Button
          disabled={disabled}
          kind="primary"
          label="Done"
          onPress={handleUpdate}
          size="xsmall"
        />
      </Box>
    </Box>
  )
}

export default React.memo(EditItemHeader)
