import React from 'react'
import { channelSelectors, channelUserSelectors } from '@/store/selectors'
import { CountUnreadOptions } from '@/store/slices/entities/channelsUsers'
import { ChannelType } from '@/types/entities'
import { useCurrentUserId } from '../auth'
import { useCachedSelector } from '../redux'

const useUnreadMessagesCount = (opts: CountUnreadOptions) => {
  const userId = useCurrentUserId() || 0
  const unreadMessagesCount = useCachedSelector(channelUserSelectors.unreadMessagesCountSelector, [
    userId,
    opts,
  ])
  return unreadMessagesCount
}

const fullCountOpts = { mode: 'full_count' } as const
export const useAllUnreadMessagesCount = () => useUnreadMessagesCount(fullCountOpts)

export const useUnreadMessagesCountInSelectedSpace = (spaceId: number) =>
  useUnreadMessagesCount(
    React.useMemo<CountUnreadOptions>(() => ({ mode: 'selected_space', spaceId }), [spaceId]),
  )

export const useUnreadMessagesCountInSelectedChannel = (spaceId: number, channelId: number) =>
  useUnreadMessagesCount(
    React.useMemo<CountUnreadOptions>(
      () => ({ channelId, mode: 'selected_channel', spaceId }),
      [spaceId, channelId],
    ),
  )

export const useUnreadMessagesCountInNonHubChannels = (spaceId: number) => {
  const channelIds = useCachedSelector(channelSelectors.idsByFilterSelector, [
    React.useMemo(
      () => ({
        channelTypes: ['PUBLIC_GROUP', 'PRIVATE_GROUP', 'DIRECT_MESSAGE'] as ChannelType[],
        spaceId,
      }),
      [spaceId],
    ),
  ])

  return useUnreadMessagesCount(
    React.useMemo<CountUnreadOptions>(
      () => ({
        channelIds,
        mode: 'selected_channels',
        spaceId,
      }),
      [spaceId, channelIds],
    ),
  )
}

export const useUnreadMessagesCountInOtherSpaces = (spaceId: number) =>
  useUnreadMessagesCount(
    React.useMemo<CountUnreadOptions>(() => ({ mode: 'other_spaces', spaceId }), [spaceId]),
  )

export const useUnreadMessagesCountInOtherChannels = (spaceId: number, channelId: number) =>
  useUnreadMessagesCount(
    React.useMemo<CountUnreadOptions>(
      () => ({ channelId, mode: 'other_channels', spaceId }),
      [spaceId, channelId],
    ),
  )
