import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { useChargebeeEstimates } from '@/hooks/chargebee'
import type { ChargebeeEstimatesMap } from '@/types/chargebee'

export { ChargebeeEstimatesMap }

interface WithEstimatesProps {
  spaceId: number
  children: (estimates: ChargebeeEstimatesMap) => React.ReactElement
}

function WithEstimates({ spaceId, children }: WithEstimatesProps) {
  const { estimates, status: estimatesStatus } = useChargebeeEstimates(spaceId)

  if (estimatesStatus === 'READY' || estimatesStatus === 'PENDING') {
    return (
      <Box direction="row" gapAll="large" justifyContent="center">
        <ActivityIndicator color="obsidian" size="large" />
      </Box>
    )
  }

  if (estimatesStatus === 'ERROR') {
    return (
      <Box direction="row" gapAll="large" justifyContent="center">
        <Text color="red">An error occurred when fetching plan information</Text>
      </Box>
    )
  }

  if (!estimates) {
    return null
  }

  return children(estimates)
}

export default React.memo(WithEstimates)
