import React from 'react'
import Icon from '@/components/core/Icon'
import Touchable from '@/components/core/Touchable'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { Task } from '@/types/entities'

interface TaskListItemBoardFocusProps {
  task: Task
}

function TaskListItemBoardFocus({ task }: TaskListItemBoardFocusProps) {
  const { editTask } = useTasksListViewContext()
  const handlePress = React.useCallback(() => editTask(task, 'BOARD'), [editTask, task])

  return (
    <Touchable feedback="opacity" hitSlop="large" onPress={handlePress}>
      <Icon color="iron" kind="solid" name="th-list" size="h3" />
    </Touchable>
  )
}

export default React.memo(TaskListItemBoardFocus)
