import React from 'react'
import { summaryUtils } from '@/core/utils'
import { useAppDispatch, useFormValues } from '@/hooks'
import { formActions } from '@/store/actions'
import { summaryThunks } from '@/thunks'
import { Summary } from '@/types/entities'

type CreateSummaryModalArgs = {
  channelId: number
  formName: string
  onClose: () => void
  onSummaryCreated: (summary: Summary) => void
  spaceId: number
}

export const useCreateSummaryModal = ({
  channelId,
  formName,
  onClose,
  onSummaryCreated,
  spaceId,
}: CreateSummaryModalArgs) => {
  const dispatch = useAppDispatch()

  const formValues = useFormValues(formName)
  const [isCreating, setIsCreating] = React.useState(false)
  const [hasSummaries, setHasSummaries] = React.useState<boolean | null>(null)
  const isComplete = !!formValues.title

  const handleCreate = React.useCallback(async () => {
    setIsCreating(true)
    const result = await dispatch(
      summaryThunks.createSummary(spaceId, channelId, {
        copyHeaders: !!formValues.copyHeaders,
        copyUnacknowledged: !!formValues.copyUnacknowledged,
        copyUnansweredQuestions: !!formValues.copyUnansweredQuestions,
        copyUncompletedTasks: !!formValues.copyUncompletedTasks,
        title: formValues.title,
      }),
    )
    setIsCreating(false)

    if (result.ok) {
      const { summary } = result.data
      onClose()
      onSummaryCreated(summary)
    }
  }, [channelId, dispatch, onClose, onSummaryCreated, setIsCreating, formValues, spaceId])

  const initializeForm = React.useCallback(() => {
    dispatch(
      formActions.initialize({
        fields: {
          copyHeaders: true,
          copyUnacknowledged: true,
          copyUnansweredQuestions: true,
          copyUncompletedTasks: true,
          title: summaryUtils.getSummaryDefaults().title,
        },
        formName,
      }),
    )
  }, [formName, dispatch])

  const checkHasSummaries = React.useCallback(async () => {
    const value = await dispatch(summaryThunks.hasSummaries(spaceId, channelId))
    setHasSummaries(value)
  }, [spaceId, channelId, setHasSummaries, dispatch])

  React.useEffect(initializeForm, [initializeForm])
  React.useEffect(() => {
    checkHasSummaries()
  }, [checkHasSummaries])

  return {
    handleCreate,
    hasSummaries,
    isComplete,
    isCreating,
  }
}
