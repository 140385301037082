import React from 'react'
import { filterActions } from '@/core/utils/actions/utils'
import { ActionOption } from '@/types/actions'
import Background from '../Background'
import ActionsMenuItem from './ActionsMenuItem'
import { borderRadius } from './constants'

interface ActionsMenuProps<S> {
  options: ActionOption<S>[]
  subject: S
  minWidth?: number | string
}

function ActionsMenu<S>({ options, subject, minWidth }: ActionsMenuProps<S>) {
  const activeOptions = React.useMemo(() => filterActions(options, subject), [options, subject])

  return (
    <Background
      borderColor="heather"
      borderRadius={borderRadius}
      borderWidth={1}
      color="ghost"
      minWidth={minWidth}
    >
      {activeOptions.map((option, index) => (
        <ActionsMenuItem
          key={option.label}
          option={option}
          position={getPosition(activeOptions.length, index)}
          subject={subject}
        />
      ))}
    </Background>
  )
}

const getPosition = (length: number, index: number) => {
  if (index === 0) {
    return 'first'
  }
  if (index === length - 1) {
    return 'last'
  }
  return 'middle'
}

export default React.memo(ActionsMenu) as typeof ActionsMenu
