import React from 'react'
import NotificationModal from './NotificationModal'
import { useNotificationHandler, useNotificationStatus, useResponseListener } from './hooks'

function PushNotificationManager() {
  useNotificationHandler()
  useResponseListener()

  const { requestPermission, handleRequestPermission } = useNotificationStatus()

  return (
    <NotificationModal isOpen={requestPermission} onRequestPermission={handleRequestPermission} />
  )
}

export default React.memo(PushNotificationManager)
