import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { Space } from '@/types/entities'

const adapter = createEntityAdapter<Space>({
  selectId: space => space.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/spaces',
  reducers: {
    removeOne: adapter.removeOne,
    setAll: adapter.setAll,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.spaces)

const byIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectEntities, spaces => spaces[spaceId])
const selectSpacesCount = (store: RootState) => store.entities.spaces.ids.length
const selectFirst = (store: RootState) => {
  const { ids, entities } = store.entities.spaces

  if (ids.length === 0) {
    return null
  }

  return entities[ids[0]] || null
}

const selectors = {
  all: sliceSelectors.selectAll,
  byId: sliceSelectors.selectById,
  byIdSelector,
  entities: sliceSelectors.selectEntities,
  first: selectFirst,
  spacesCount: selectSpacesCount,
}

export { actions, selectors }
export default reducer
