import React from 'react'
import Box from '@/components/layouts/Box'
import { offsetUtils as O } from '@/core/utils'
import { ReminderOffset } from '@/types/entities'
import OffsetListItem from './OffsetListItem'
import { defaultOffsetChoices } from './constants'

interface OffsetsListProps {
  selectedOffset: ReminderOffset | null
  onOffsetChange: (offset: ReminderOffset) => void
}

function OffsetsList({ selectedOffset, onOffsetChange }: OffsetsListProps) {
  return (
    <Box direction="column" gapTop="medium">
      {defaultOffsetChoices.map((offset, index) => (
        <OffsetListItem
          key={index.toString()}
          isSelected={!!selectedOffset && O.equals(offset, selectedOffset)}
          offset={offset}
          onSelect={onOffsetChange}
        />
      ))}
    </Box>
  )
}

export default React.memo(OffsetsList)
