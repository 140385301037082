import PN from 'awesome-phonenumber'
import { PhoneNumber } from '@/types/entities'

export const isVerified = (phoneNumber: PhoneNumber): boolean => !!phoneNumber.verifiedAt
export const isUnverified = (phoneNumber: PhoneNumber): boolean => !isVerified(phoneNumber)

// Try to parse a number. If it can't be parsed as is parse it as a US number.
// TODO: use locale to determine default parsing country
export const parse = (value: string) => {
  const rawNumber = new PN(value)
  if (rawNumber.isValid()) {
    return rawNumber
  }
  return PN(value, 'US')
}
