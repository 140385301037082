// A very basic URL regex
// A url is anything that starts with http[s]://
// or any string of non-spaces that has a . and 2-3 a-z characters
const embeddedURLsRegex = /\bhttps?:\/\/\S*|[^\s.]+\.[a-z]{2,3}\S*/gi
const punctuation = '.?!,:;'

type TextURLPair = {
  url: string | null
  text: string | null
}

export const splitURLs = (value: string): TextURLPair[] => {
  const regex = new RegExp(embeddedURLsRegex)
  const pairs: TextURLPair[] = []

  let match = regex.exec(value)
  let rounds = 0 // rounds is a circuit breaker to prevent unexpected infinite loops
  let sliceStart = 0
  let previousURLPunctuation = ''

  if (!match) {
    return [{ text: value, url: null }]
  }

  while (match !== null) {
    const [rawURL] = match
    const rawText = value.slice(sliceStart, match.index)

    const text = `${previousURLPunctuation}${rawText}`
    previousURLPunctuation = ''

    let url = rawURL

    if (url) {
      const last = url[url.length - 1]

      if (punctuation.includes(last)) {
        previousURLPunctuation = last
        url = url.slice(0, -1)
      }
    }

    pairs.push({ text: text || null, url: url || null })

    sliceStart = regex.lastIndex

    rounds += 1
    match = regex.exec(value)

    if (rounds >= 100) {
      break
    }
  }

  if (sliceStart < value.length || previousURLPunctuation) {
    const rest = value.slice(sliceStart)
    const text = `${previousURLPunctuation}${rest}`
    pairs.push({ text, url: null })
  }

  return pairs
}
