import React from 'react'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { fnUtils } from '@/core/utils'
import { useActions } from '@/hooks/actions'
import {
  useCopyTaskToClipboard,
  useDeleteTask,
  useDuplicateTask,
  useTaskChannelsCount,
} from '../hooks'

const actionArgs = { title: 'Task Actions' }

export const useTaskFormMoreActions = () => {
  const { showDetail, task, close } = useTaskFormContext()
  const { isDeleting, handleDelete } = useDeleteTask(task, close)
  const handleMove = React.useCallback(() => showDetail('CHANNEL'), [showDetail])
  const copyTaskToClipboard = useCopyTaskToClipboard()
  const handleDuplicateTask = useDuplicateTask()
  const canMove = useTaskChannelsCount() > 1
  const hasTask = !!task

  const handleShowActions = useActions<void>(
    React.useMemo(
      () => [
        {
          handler: handleDuplicateTask,
          isAvailable: () => hasTask,
          label: 'Duplicate',
        },
        {
          handler: copyTaskToClipboard,
          label: 'Copy to Clipboard',
        },
        {
          handler: handleMove,
          isAvailable: () => hasTask && canMove,
          label: 'Move',
        },
        {
          handler: handleDelete,
          isAvailable: () => hasTask,
          label: 'Delete',
          type: 'destructive',
        },
        {
          handler: fnUtils.noOp,
          label: 'Cancel',
          type: 'cancel',
        },
      ],
      [handleDuplicateTask, copyTaskToClipboard, hasTask, handleDelete, handleMove, canMove],
    ),
    actionArgs,
  )

  return {
    handleShowActions,
    isDeleting,
  }
}
