import { EntityState, EntityStateAdapter, PayloadAction } from '@reduxjs/toolkit'
import { original } from 'immer'
import { Predicate } from '@/types/generics'

export const createEntityReducers = <T>(adapter: EntityStateAdapter<T>) => {
  const removeWhere = (
    state: EntityState<T>,
    action: PayloadAction<{
      predicate: Predicate<T>
    }>,
  ): EntityState<T> => {
    const removeIds = Object.entries(state.entities)
      .filter(pair => {
        const entity = original(pair[1])
        return entity && action.payload.predicate(entity)
      })
      .map(([key]) => key)
    return adapter.removeMany(state, removeIds)
  }

  const replaceWhere = (
    state: EntityState<T>,
    action: PayloadAction<{
      predicate: Predicate<T>
      entities: T[]
    }>,
  ): EntityState<T> => adapter.upsertMany(removeWhere(state, action), action.payload.entities)

  return {
    removeWhere,
    replaceWhere,
  }
}
