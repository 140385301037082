import React from 'react'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import { getTaskColors } from '@/components/messages/MessagesList/constants'
import TaskCompleteCheckbox from '@/components/tasks/TaskCompleteCheckbox'
import TaskDetailsList from '@/components/tasks/TaskDetailsList'
import { taskUtils } from '@/core/utils'
import { Task } from '@/types/entities'
import TaskActionButton from './TaskActionButton'

interface TaskContentDescriptionProps {
  canEdit: boolean
  task: Task
  isFromMe: boolean
  simplified: boolean
}

function TaskContentDescription({
  canEdit,
  task,
  isFromMe,
  simplified,
}: TaskContentDescriptionProps) {
  const isCompleted = taskUtils.isCompleted(task)
  const isDeleted = taskUtils.isDeleted(task)
  const isMoved = taskUtils.isMoved(task)
  const isDeletedOrMoved = isDeleted || isMoved
  const { foreground } = getTaskColors(isFromMe, isCompleted || simplified)

  const showCheckbox = canEdit || !isDeletedOrMoved
  const checkboxDisabled = !canEdit
  const showActionButton = !(simplified || isDeletedOrMoved)
  const showDetails = !(simplified || isDeletedOrMoved)
  const italic = isCompleted || isDeletedOrMoved || simplified

  return (
    <Box alignItems="center" direction="row" gapAll="small">
      <Box direction="column" flex={1} minHeight={40}>
        <Box alignItems="flex-start" direction="row">
          {showCheckbox ? (
            <Box gapRight="small">
              <TaskCompleteCheckbox disabled={checkboxDisabled} task={task} />
            </Box>
          ) : null}
          <Box flex={1}>
            <TextWithLinks color={foreground} italic={italic}>
              {isDeleted ? 'Deleted Task' : task.content}
            </TextWithLinks>
          </Box>
        </Box>
        {showDetails ? (
          <TaskDetailsList
            color={isCompleted ? foreground : undefined}
            showAssignee
            showMyDay
            task={task}
          />
        ) : null}
      </Box>
      {showActionButton ? (
        <Box alignItems="center" gapAll="medium" justifyContent="center">
          <TaskActionButton task={task} />
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(TaskContentDescription)
