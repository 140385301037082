import React from 'react'
import GiphyPicker from '@/components/core/GiphyPicker'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'

interface GiphyPickerModalProps {
  onClose: () => void
  onPick: (url: string) => void
}

function GiphyPickerModal({ onClose, onPick }: GiphyPickerModalProps) {
  const handleCancel = React.useCallback(() => {
    onClose()
  }, [onClose])

  const handlePick = React.useCallback(
    (url: string) => {
      onPick(url)
      onClose()
    },
    [onClose, onPick],
  )

  return (
    <ModalLayout
      insetBottom
      leftAction={{
        label: 'Cancel',
        onAction: handleCancel,
        type: 'linkPrimary',
      }}
      maxWidth={600}
      onCancel={handleCancel}
      title="Search Giphy"
    >
      <Box flex={1} gapBottom="medium" gapHorizontal="medium">
        <GiphyPicker onPick={handlePick} />
      </Box>
    </ModalLayout>
  )
}

export default React.memo(GiphyPickerModal)
