import { collectionUtils as C } from '@/core/utils'
import { Summary, SummaryItem } from '@/types/entities'
import {
  EditItemData,
  EditSummaryItemData,
  SummaryFieldConfiguration,
  SummaryFieldName,
} from './types'

export const buildEditItems = (summary: Summary, items: SummaryItem[]): EditItemData[] => [
  { content: summary.title, type: 'title' },
  { content: summary.greeting, type: 'greeting' },
  ...toEditItems(items),
  { content: summary.closing, type: 'closing' },
]

const toEditItems = (summaryItems: SummaryItem[]): EditSummaryItemData[] =>
  summaryItems.reduce((acc: EditSummaryItemData[], summaryItem: SummaryItem) => {
    const previous = C.lastOrDefault(acc, null)
    const header = summaryItem.itemType === 'HEADER' ? summaryItem : previous?.header || null

    acc.push({
      header,
      previous,
      summaryItem,
      type: 'summaryItem',
    })

    return acc
  }, [])

export const getSummaryFieldConfiguration = (field: SummaryFieldName): SummaryFieldConfiguration =>
  summaryFieldConfigurations[field]

const summaryFieldConfigurations: Record<SummaryFieldName, SummaryFieldConfiguration> = {
  closing: {
    label: 'Closing',
    placeholder: `Ex: "That's it for now!"`,
  },
  greeting: {
    label: 'Greeting',
    placeholder: 'Ex: "Good morning!"',
  },
  title: {
    isRequired: true,
    label: 'Executive Brief Title',
    placeholder: 'Executive Brief Title',
  },
}
