import { Task } from '@/types/entities'
import { TaskOrderIndex } from '@/types/taskLists'
import {
  TaskComparer,
  TaskFieldSort,
  TaskListSort,
  TaskSort,
  TaskSortField,
} from '@/types/tasks/sorting'
import { taskToIndexKey } from '../taskLists'
import { RelatedTaskData } from './related'

type TaskKey = (task: Task) => number | string | null

const isFieldSort = (sort: TaskSort): sort is TaskFieldSort => sort.type === 'field'

export const compileTaskSorts = (relatedData: RelatedTaskData, sorts: TaskSort[]): TaskComparer => {
  if (sorts.length === 0) {
    throw new Error('task sorting array must have at least one item')
  }

  return sorts.reduceRight(
    (nextComparer: TaskComparer | undefined, sort: TaskSort): TaskComparer => {
      if (isFieldSort(sort)) {
        return buildComparer(
          getSortKey(sort.field),
          sort.direction === 'asc' ? 1 : -1,
          sort.nullsFirst,
          nextComparer,
        )
      }

      return chainComparers(
        buildTaskListComparer(relatedData.data.taskOrderIndex, sort.taskListId),
        nextComparer,
      )
    },
    undefined,
  ) as TaskComparer
}

export const buildListSort = (
  taskListId: number,
  overrides?: Partial<Omit<TaskListSort, 'type' | 'taskListId'>>,
): TaskListSort => {
  const sort: TaskListSort = {
    direction: 'asc',
    nullsFirst: false,
    taskListId,
    type: 'list',
  }

  return overrides ? { ...sort, ...overrides } : sort
}

const buildComparer =
  (
    key: TaskKey,
    direction: number,
    nullsFirst: boolean,
    nextComparer?: TaskComparer,
  ): TaskComparer =>
  (left: Task, right: Task): number => {
    const lval = key(left)
    const rval = key(right)

    let result: number

    if (lval === null || lval === undefined) {
      if (rval === null || rval === undefined) {
        if (nextComparer) {
          return nextComparer(left, right)
        }
        return 0
      }
      return nullsFirst ? -1 : 1
    }

    if (rval === null || rval === undefined) {
      return nullsFirst ? 1 : -1
    }

    if (lval < rval) {
      result = -1
    } else if (lval === rval) {
      if (nextComparer) {
        return nextComparer(left, right)
      }
      result = 0
    } else {
      result = 1
    }

    return result * direction
  }

const chainComparers = (first: TaskComparer, second?: TaskComparer): TaskComparer => {
  if (!second) {
    return first
  }

  return (left: Task, right: Task) => {
    const result = first(left, right)
    return result === 0 ? second(left, right) : result
  }
}

const getSortKey = (field: TaskSortField): TaskKey => {
  switch (field) {
    case 'URGENCY':
      return urgencyToKey
    case 'DUE_DATE':
      return dueDateToKey
    case 'CREATED_DATE':
      return createdAtToKey
    case 'COMPLETED_DATE':
      return completedAtToKey
    case 'STATUS':
      return statusToKey
      break
    default:
      throw new Error(`Invalid sort field: ${field}`)
  }
}

const urgencyToKey = (task: Task) => {
  if (task.urgency === 'HIGH') {
    return 0
  }
  if (task.urgency === 'MEDIUM') {
    return 1
  }
  return 2
}

const dueDateToKey = (task: Task) => {
  if (task.dueDateType === 'DATE') {
    return task.dueDate
  }
  if (task.dueDateType === 'SOMEDAY') {
    return 'A'
  }
  return 'B'
}

const completedAtToKey = (task: Task) => task.completedAt
const createdAtToKey = (task: Task) => task.createdAt

const statusToKey = (task: Task) => {
  if (task.completedAt) {
    return 0
  }
  if (task.acceptedAt) {
    return 1
  }
  return 2
}

const buildTaskListComparer = (orderIndex: TaskOrderIndex, taskListId: number): TaskComparer =>
  buildComparer(task => orderIndex[taskToIndexKey(task)]?.[taskListId]?.orderingValue, 1, false)
