import React from 'react'
import { TaskUrgency } from '@/types/entities'
import TaskPriorityCycleButton from '../TaskPriorityCycleButton'
import { fieldNames } from './constants'
import { useTaskFormField } from './hooks'

interface TaskFormPriorityCycleButtonProps {
  showLabel?: boolean
}

function TaskFormPriorityCycleButton({ showLabel = false }: TaskFormPriorityCycleButtonProps) {
  const { value: urgency, setValue: setUrgency } = useTaskFormField<TaskUrgency>(fieldNames.urgency)
  return <TaskPriorityCycleButton onChange={setUrgency} showLabel={showLabel} urgency={urgency} />
}

export default React.memo(TaskFormPriorityCycleButton)
