import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Background from '@/components/core/Background'
import ContainedIcon from '@/components/core/ContainedIcon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { dateUtils as D, taskUtils } from '@/core/utils'
import { useAppDispatch, useCurrentUserId } from '@/hooks'
import { useCheckNotify } from '@/hooks/tasks'
import { taskThunks } from '@/thunks'
import { fieldNames } from './constants'
import * as hooks from './hooks'

function TaskFormAcceptLine() {
  const { interactionMode, task } = useTaskFormContext()
  const { value: completedAt } = hooks.useTaskFormField(fieldNames.completedAt)
  const [isLoading, setIsLoading] = React.useState(false)
  const checkNotify = useCheckNotify()
  const dispatch = useAppDispatch()

  const handleAccept = React.useCallback(async () => {
    if (!task) {
      return
    }

    setIsLoading(true)
    const notify = await checkNotify({ changes: { acceptedAt: D.nowISO() }, task })
    await dispatch(taskThunks.acceptTask(task.spaceId, task.id, notify))
    setIsLoading(false)
  }, [dispatch, task, checkNotify])

  const currentUserId = useCurrentUserId() || 0

  if (!task || interactionMode === 'create' || completedAt) {
    return null
  }

  if (taskUtils.isAccepted(task)) {
    return null
  }

  if (!taskUtils.canAcceptTask(task, currentUserId)) {
    return null
  }

  return (
    <Box gapVertical="xsmall">
      <Background color="light-red">
        <Touchable feedback="opacity" onPress={handleAccept}>
          <Box alignItems="center" direction="row" gapAll="medium" justifyContent="center">
            <Box alignItems="center" height={50} justifyContent="center" width={50}>
              {isLoading ? (
                <ActivityIndicator color="anchor" size="large" />
              ) : (
                <ContainedIcon
                  backgroundColor="transparent"
                  borderColor="anchor"
                  borderStyle="dashed"
                  borderWidth={1}
                  color="anchor"
                  name="thumbs-up"
                  padding={10}
                  shape="circle"
                  size={24}
                />
              )}
            </Box>
            <Box gapLeft="medium">
              <Text color="anchor" size="h6">
                Status
              </Text>
              <Box gapTop="xsmall">
                <Text size="h5" weight="medium">
                  Unaccepted (click to accept)
                </Text>
              </Box>
            </Box>
          </Box>
        </Touchable>
      </Background>
    </Box>
  )
}

export default React.memo(TaskFormAcceptLine)
