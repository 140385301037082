import { FontSize, FontWeight } from '@/types/typography'

const fontSizes: Record<FontSize, number> = {
  emoji: 60,
  h1: 32,
  h2: 24,
  h3: 20,
  h4: 16,
  h5: 14,
  h6: 12,
  sm: 10,
  xs: 8,
}

export const getFontSize = (size: FontSize | number) =>
  typeof size === 'number' ? size : fontSizes[size]

const NORMAL_LINE_HEIGHT = 1.3 // 1.3 is the minimum amount that doesn't cut off the top of emoji

// Originally these were all taken from
// the fontSizes above and multiplied
// by 1.3 which is slightly bigger than
// a "normal" line height in web browsers
const lineHeights: Record<FontSize, number> = {
  emoji: 78,
  h1: 42,
  h2: 31,
  h3: 26,
  h4: 22, // bumped up by 1px from original 21px
  h5: 18,
  h6: 16,
  sm: 13,
  xs: 10,
}

export const getLineHeight = (size: FontSize | number) =>
  typeof size === 'number' ? size * NORMAL_LINE_HEIGHT : lineHeights[size]

// export const getLineHeight = (size: FontSize | number) => getFontSize(size) * NORMAL_LINE_HEIGHT

const fonts = {
  bold: 'graphik-bold',
  boldItalic: 'graphik-bold-italic',
  italic: 'graphik-italic',
  medium: 'graphik-medium',
  mediumItalic: 'graphik-medium-italic',
  regular: 'graphik',
}

export const getFontFamily = (weight: FontWeight, italic: boolean) => {
  switch (weight) {
    case 'bold':
      return italic ? fonts.boldItalic : fonts.bold
    case 'medium':
      return italic ? fonts.mediumItalic : fonts.medium
    case 'regular':
    default:
      return italic ? fonts.italic : fonts.regular
  }
}
