import React from 'react'
import MessagesBadge from '@/components/badges/MessagesBadge'
import Background from '@/components/core/Background'
import Hoverable from '@/components/core/Hoverable'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useThemeContext } from '@/contexts/ThemeContext'
import { IconName } from '@/core/constants/icons'
import { channelUtils } from '@/core/utils'
import { useCachedSelector } from '@/hooks'
import { entitySelectors } from '@/store/selectors'
import { ChannelStatus, ResolvedChannel } from '@/types/entities'
import { ShowMessages } from '@/types/messaging'

interface ChannelListItemProps {
  channel: ResolvedChannel
  onShowMessages: ShowMessages
  isSelected?: boolean
}

function ChannelListItem({ channel, onShowMessages, isSelected }: ChannelListItemProps) {
  const channelUser = useCachedSelector(entitySelectors.myChannelUserSelector, [
    channel.spaceId,
    channel.id,
  ])
  const unreadCount = channelUser?.unreadMessageCount || 0
  const handleShowMessages = React.useCallback(() => {
    onShowMessages(channel, { taskRelationship: 'MINE', taskType: 'TASK' })
  }, [onShowMessages, channel])
  const iconName = getIcon(channelUtils.getChannelStatus(channel, channelUser))
  const theme = useThemeContext()

  // Direct messages have no name and display user names instead. When the app is loaded, the
  // channels list may be loaded earlier than the users list, leading to a display of empty
  // channels in direct messages. This will cause a channel row to not render if it has no name
  // until all users are available.
  if (!channel.name) {
    return null
  }

  return (
    <Touchable feedback="opacity" onPress={handleShowMessages}>
      <Hoverable>
        {hovering => (
          <Background color={theme.getBackground({ hovering, selected: isSelected })}>
            <Box alignItems="center" direction="row" gapLeft="large" gapRight={28} gapVertical={9}>
              <Box direction="row" flex={1}>
                {iconName ? (
                  <Box gapRight="small">
                    <Icon
                      color={theme.getForeground({ hovering, selected: isSelected })}
                      kind="solid"
                      name={iconName}
                      size="h4"
                    />
                  </Box>
                ) : null}
                <Text
                  color={theme.getForeground({ hovering, selected: isSelected })}
                  numberOfLines={2}
                  size="h4"
                  weight={unreadCount === 0 ? 'regular' : 'medium'}
                >
                  {channel.name}
                </Text>
              </Box>
              <MessagesBadge count={unreadCount} />
            </Box>
          </Background>
        )}
      </Hoverable>
    </Touchable>
  )
}

const getIcon = (status: ChannelStatus): IconName | null => {
  if (status === 'SILENCED') {
    return 'volume-mute'
  }
  if (status === 'ARCHIVED') {
    return 'archive'
  }
  return null
}

export default React.memo(ChannelListItem)
