import React from 'react'
import { useTaskFormContext } from '@/contexts/TaskFormContext'

type RenderContent = () => React.ReactElement

type TaskFormSizeContainerChildrenSmall =
  | { small?: RenderContent; smallMedium?: undefined }
  | { small?: undefined; smallMedium?: RenderContent }

type TaskFormSizeContainerChildrenMedium =
  | { smallMedium?: RenderContent; medium?: undefined; mediumLarge?: undefined }
  | { smallMedium?: undefined; medium?: RenderContent; mediumLarge?: undefined }
  | { smallMedium?: undefined; medium?: undefined; mediumLarge?: RenderContent }

type TaskFormSizeContainerChildrenLarge =
  | { mediumLarge?: RenderContent; large?: undefined }
  | { mediumLarge?: undefined; large?: RenderContent }

type TaskFormSizeContainerProps = TaskFormSizeContainerChildrenSmall &
  TaskFormSizeContainerChildrenMedium &
  TaskFormSizeContainerChildrenLarge

function TaskFormSizeContainer({
  small,
  medium,
  large,
  smallMedium,
  mediumLarge,
}: TaskFormSizeContainerProps) {
  const { taskFormSize: size } = useTaskFormContext()

  if (size === 'SMALL') {
    if (small) {
      return small()
    }
    if (smallMedium) {
      return smallMedium()
    }
    return null
  }
  if (size === 'MEDIUM') {
    if (medium) {
      return medium()
    }
    if (smallMedium) {
      return smallMedium()
    }
    if (mediumLarge) {
      return mediumLarge()
    }
    return null
  }
  if (size === 'LARGE') {
    if (large) {
      return large()
    }
    if (mediumLarge) {
      return mediumLarge()
    }
    return null
  }
  throw new Error(`Invalid task form size: ${size}`)
}

export default React.memo(TaskFormSizeContainer)
