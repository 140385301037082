import React from 'react'
import ModalPresenterContext, {
  ModalPresenterContextType,
  useModalPresenterState,
} from '@/contexts/ModalPresenterContext'
import ShowTaskFormContext from '@/contexts/ShowTaskFormContext'
import { TaskFormArgs } from '@/types/tasks'
import ModalPresenter from './ModalPresenter'

interface ModalPresenterContainerProps {
  children: React.ReactNode
}

function ModalPresenterContainer({ children }: ModalPresenterContainerProps) {
  const { modals, pushModal, popModal } = useModalPresenterState()
  const contextValue = React.useMemo<ModalPresenterContextType>(
    () => ({ pop: popModal, push: pushModal }),
    [pushModal, popModal],
  )
  const showTaskFormContextValue = React.useMemo(
    () => ({
      showTaskForm: (args: TaskFormArgs) => pushModal('task', args),
    }),
    [pushModal],
  )

  // In order to make sure we can open task modals from other modals we have to nest this
  // like this. However we can override the context further down in the tree where necessary
  return (
    <ModalPresenterContext.Provider value={contextValue}>
      <ShowTaskFormContext.Provider value={showTaskFormContextValue}>
        {children}
        <ModalPresenter modals={modals} onPop={popModal} />
      </ShowTaskFormContext.Provider>
    </ModalPresenterContext.Provider>
  )
}

export default React.memo(ModalPresenterContainer)
