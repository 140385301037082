import React from 'react'
import { dateUtils as D } from '@/core/utils'
import { useAppDispatch, useCachedSelector } from '@/hooks'
import { useFormValues } from '@/hooks/forms'
import { useSpace } from '@/hooks/spaces'
import { formActions, notificationActions } from '@/store/actions'
import { entitySelectors } from '@/store/selectors'
import { spaceThunks } from '@/thunks'
import { formRequest } from '@/thunks/forms'
import { Channel } from '@/types/entities'
import { formName } from './constants'

type CreateStep = 'HUB_USERS' | 'USER_INFORMATION' | 'USER_ROLE'

export const useInviteUserModal = (
  spaceId: number,
  onClose: () => void,
  onCreated?: (channel: Channel) => void,
) => {
  const dispatch = useAppDispatch()
  const space = useSpace(spaceId)

  const inactiveHubUsers = useCachedSelector(entitySelectors.inHubWithNonActiveUsersSelector, [
    spaceId,
  ])
  const hasInactiveHubUsers = inactiveHubUsers.length !== 0
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [step, setStep] = React.useState<CreateStep>(
    hasInactiveHubUsers ? 'HUB_USERS' : 'USER_INFORMATION',
  )

  const goToNextStep = React.useCallback(() => setStep(getNextStep), [setStep])
  const goBack = React.useCallback(
    () => setStep(_step => getPrevStep(_step, hasInactiveHubUsers)),
    [setStep, hasInactiveHubUsers],
  )
  const canGoBack = (() => {
    if (step === 'HUB_USERS') {
      return false
    }
    if (step === 'USER_ROLE') {
      return true
    }
    return hasInactiveHubUsers
  })()

  const values = useFormValues(formName)
  const canInviteTeamMember =
    !!values.firstName?.trim() && !!values.lastName?.trim() && !!values.emailAddress?.trim()
  const canInviteHub = canInviteTeamMember && !!values.role && values.role !== 'OTHER'

  const handleInvite = React.useCallback(async () => {
    setIsSubmitting(true)

    const response = await dispatch(
      formRequest(formName, ({ emailAddress, firstName, lastName, role, inviteNote }) =>
        spaceThunks.createUser(spaceId, {
          createHub: step === 'USER_ROLE',
          emailAddress,
          firstName,
          inviteNote,
          lastName,
          role,
          timezoneName: D.getTimeZoneName(),
        }),
      ),
    )

    setIsSubmitting(false)

    if (response.ok) {
      const { channels } = response.data
      if (channels.length) {
        onCreated?.(channels[0])
      }
      onClose()
      dispatch(
        notificationActions.push({
          title: `We've sent your invitation!`,
          type: 'success',
        }),
      )
    } else if (step === 'USER_ROLE') {
      // If there was an error and you're on the role selector, go back to the previous page,
      // at this point that's the only place there can be a validation error (email address)
      goBack()
    }
  }, [dispatch, spaceId, step, onClose, onCreated, goBack])

  React.useEffect(() => {
    dispatch(
      formActions.initialize({
        fields: {
          emailAddress: '',
          firstName: '',
          inviteNote: 'Join me in Emmre and we can start collaborating together!',
          lastName: '',
          role: 'OTHER',
        },
        formName,
      }),
    )
  }, [dispatch])

  return {
    canGoBack,
    canInviteHub,
    canInviteTeamMember,
    goBack,
    goToNextStep,
    handleInvite,
    inactiveHubUsers,
    isSubmitting,
    space,
    step,
  }
}

const getNextStep = (step: CreateStep): CreateStep => {
  switch (step) {
    case 'HUB_USERS':
      return 'USER_INFORMATION'
    case 'USER_INFORMATION':
      return 'USER_ROLE'
    case 'USER_ROLE':
    default:
      return step
  }
}

const getPrevStep = (step: CreateStep, hasInactiveHubUsers: boolean): CreateStep => {
  switch (step) {
    case 'USER_INFORMATION':
      return hasInactiveHubUsers ? 'HUB_USERS' : step
    case 'USER_ROLE':
      return 'USER_INFORMATION'
    case 'HUB_USERS':
    default:
      return step
  }
}
