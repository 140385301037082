import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { nowMilliseconds } from '@/core/utils/dates'
import { APIStatus } from '@/types/api'
import { RootState } from '../index'

export interface EndpointState {
  lastAttemptStartedAt?: number
  lastSuccessStartedAt?: number
  firstSuccessStartedAt?: number
  status: APIStatus
}

interface APIState {
  [key: string]: EndpointState
}

const initialState: APIState = {}

const apiStatusSlice = createSlice({
  initialState,
  name: 'apiStatus',
  /* eslint-disable no-param-reassign */
  reducers: {
    failure: (state, action: PayloadAction<{ endpointId: string }>) => {
      const { endpointId } = action.payload
      const currentEntry = state[endpointId]

      if (!currentEntry) {
        return state
      }

      const nextEntry: EndpointState = {
        ...currentEntry,
        status: 'ERROR',
      }

      return {
        ...state,
        [endpointId]: nextEntry,
      }
    },
    reset: (state, action: PayloadAction<{ endpointId: string }>) => ({
      ...state,
      [action.payload.endpointId]: {
        firstSuccessStartedAt: undefined,
        lastAttemptStartedAt: undefined,
        lastSuccessStartedAt: undefined,
        status: 'READY',
      },
    }),
    start: (state, action: PayloadAction<{ endpointId: string }>) => {
      const { endpointId } = action.payload
      const currentEntry = state[endpointId]
      const updates: EndpointState = { lastAttemptStartedAt: nowMilliseconds(), status: 'PENDING' }
      const nextEntry = currentEntry ? { ...currentEntry, ...updates } : updates

      return {
        ...state,
        [endpointId]: nextEntry,
      }
    },
    success: (state, action: PayloadAction<{ endpointId: string; wasFirstRequest: boolean }>) => {
      const { endpointId, wasFirstRequest } = action.payload
      const currentEntry = state[endpointId]

      if (!currentEntry) {
        return state
      }

      let firstSuccessStartedAt =
        currentEntry.firstSuccessStartedAt || currentEntry.lastAttemptStartedAt

      if (wasFirstRequest) {
        firstSuccessStartedAt = currentEntry.lastAttemptStartedAt
      }

      const nextEntry: EndpointState = {
        ...currentEntry,
        firstSuccessStartedAt,
        lastSuccessStartedAt: currentEntry.lastAttemptStartedAt,
        status: 'SUCCESS',
      }

      return {
        ...state,
        [endpointId]: nextEntry,
      }
    },
  },
  /* eslint-enable */
})

const { actions, reducer } = apiStatusSlice

const selectEndpoint = (state: RootState, endpointId: string): EndpointState =>
  state.apiStatus[endpointId] || { status: 'NOT_STARTED' }

const selectIsStatus = (state: RootState, endpointId: string, status: APIStatus) => {
  const value = state.apiStatus[endpointId]?.status || 'NOT_STARTED'
  return status === value
}

const selectIsPending = (state: RootState, endpointId: string) =>
  selectIsStatus(state, endpointId, 'PENDING')

const selectors = {
  endpoint: selectEndpoint,
  isPending: selectIsPending,
  isStatus: selectIsStatus,
}

export { actions, selectors }
export default reducer
