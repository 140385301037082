import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import MouseView from './MouseView'

interface HoverableProps {
  children: (isHovered: boolean) => React.ReactElement
  style?: StyleProp<ViewStyle>
}

function Hoverable({ style, children }: HoverableProps) {
  const [isHovered, setIsHovered] = React.useState(false)
  const handleMouseEnter = React.useCallback(() => setIsHovered(true), [setIsHovered])
  const handleMouseLeave = React.useCallback(() => setIsHovered(false), [setIsHovered])

  return (
    <MouseView onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style}>
      {children(isHovered)}
    </MouseView>
  )
}

export default React.memo(Hoverable)
