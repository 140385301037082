import React from 'react'
import Box from '@/components/layouts/Box'
import Icon from './Icon'
import ListItem, { ListItemProps } from './ListItem'

interface SelectListItemProps extends ListItemProps {
  isSelected?: boolean
}

function SelectListItem({ children, isSelected, ...listItemProps }: SelectListItemProps) {
  return (
    <ListItem {...listItemProps}>
      {children}
      {isSelected ? (
        <Box gapLeft="medium">
          <Icon name="check" size="h4" />
        </Box>
      ) : null}
    </ListItem>
  )
}

export default React.memo(SelectListItem)
