import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import VerticalSwipeContainer from '@/components/gestures/VerticalSwipeContainer'
import Box from '@/components/layouts/Box'
import {
  TaskFormExpandButton,
  TaskFormPriorityCycleButton,
  TaskModalContainer,
} from '@/components/tasks/TaskForm'
import TaskFormContext from '@/contexts/TaskFormContext'
import { platformUtils } from '@/core/utils'
import { useFocusTextInput } from '@/hooks/textInput'
import { ResolvedChannel } from '@/types/entities'
import ContentInput from './ContentInput'
import InvitePartnerDisabledInput from './InvitePartnerDisabledInput'
import MQTToggle from './MQTToggle'
import QuestionInputFooter from './QuestionInputFooter'
import TaskInputFooter from './TaskInputFooter'
import TaskInputHeader from './TaskInputHeader'
import Toolbar from './Toolbar'
import { useMessageTextInput } from './hooks'

const INDEPENDENT_MESSAGE_EVENT = { 'message type': 'independent' }
const REPLY_MESSAGE_EVENT = { 'message type': 'reply' }

interface MessageTextInputProps {
  autoFocus?: boolean
  channel: ResolvedChannel
  onBlur?: () => void
  onFocus?: () => void
  threadId?: number
}

const inputStyle = {
  paddingBottom: 10,
  paddingTop: 10,
}

function MessageTextInput({
  autoFocus = false,
  channel,
  onBlur,
  onFocus,
  threadId,
}: MessageTextInputProps) {
  const hooks = useMessageTextInput(channel, threadId)
  const disabled = !hooks.canSubmit

  const actionButton = React.useMemo(() => {
    if (hooks.sendingDisabled) {
      return (
        <Box alignSelf="flex-end" gapAll="xsmall">
          <BaseIconButton
            backgroundColor="heather"
            color="anchor"
            disabled
            kind="regular"
            name="arrow-up"
            padding={8}
            size={16}
          />
        </Box>
      )
    }

    switch (hooks.inputMode) {
      case 'message':
        return (
          <Box alignSelf="flex-end" gapAll="xsmall">
            <BaseIconButton
              amplitudeEventName="send message"
              amplitudeEventProperties={threadId ? REPLY_MESSAGE_EVENT : INDEPENDENT_MESSAGE_EVENT}
              backgroundColor={disabled ? 'heather' : 'emmre-blue+2'}
              color={disabled ? 'anchor' : 'ghost'}
              disabled={disabled}
              kind="regular"
              name="arrow-up"
              onPress={hooks.handleSubmit}
              padding={8}
              size={16}
            />
          </Box>
        )
      case 'task':
        return (
          <Box alignSelf="flex-start" gapAll="xsmall">
            <TaskFormPriorityCycleButton />
          </Box>
        )
      case 'question':
        return (
          <Box alignSelf="flex-start" gapAll="small">
            <TaskFormExpandButton isExpanded={false} onToggle={hooks.showTaskModal} />
          </Box>
        )
      default:
        return null
    }
  }, [
    hooks.inputMode,
    disabled,
    hooks.handleSubmit,
    threadId,
    hooks.showTaskModal,
    hooks.sendingDisabled,
  ])

  const footer = React.useMemo(() => {
    if (hooks.sendingDisabled) {
      return null
    }
    switch (hooks.inputMode) {
      case 'message':
        return null
      case 'task':
        return <TaskInputFooter isExpanded={hooks.isExpanded} />
      case 'question':
        return <QuestionInputFooter />
      default:
        return null
    }
  }, [hooks.inputMode, hooks.isExpanded, hooks.sendingDisabled])

  const refocusInput = useFocusTextInput(hooks.inputRef)

  return (
    <TaskFormContext.Provider value={hooks.taskFormContextValue}>
      <VerticalSwipeContainer activeOffset={[-50, 75]} onSwipe={hooks.handleSwipe}>
        <Box direction="column" gapAll="small">
          <Box alignItems="flex-end" direction="row" testID="toggle_and_input_area">
            <Background
              borderColor="koala"
              borderRadius={8}
              borderWidth={1}
              color="transparent"
              flex={1}
              testID="task_name_and_assignee"
            >
              {hooks.inputMode === 'task' && !hooks.sendingDisabled ? (
                <Box>
                  <TaskInputHeader
                    isExpanded={hooks.isExpanded}
                    onShowTaskForm={hooks.showTaskModal}
                    onToggleExpand={hooks.toggleIsExpanded}
                  />
                </Box>
              ) : null}
              <Box
                alignItems="center"
                direction="row"
                testID={hooks.inputMode === 'message' ? 'create_message_input_box' : undefined}
              >
                <Box alignItems="stretch" flex={1} gapHorizontal="small">
                  {hooks.sendingDisabled ? (
                    <InvitePartnerDisabledInput
                      inputMode={hooks.inputMode}
                      partner={hooks.partner!}
                    />
                  ) : (
                    <ContentInput
                      ref={hooks.inputRef}
                      autoFocus={autoFocus}
                      channel={channel}
                      formName={hooks.formName}
                      inputMode={hooks.inputMode}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onSubmit={platformUtils.isWeb ? hooks.handleSubmit : undefined}
                      style={inputStyle}
                    />
                  )}
                </Box>
                {actionButton}
              </Box>
              {footer}
            </Background>
          </Box>
          {hooks.sendingDisabled ? null : (
            <Toolbar
              InputModeSelector={
                <MQTToggle inputMode={hooks.inputMode} onSelectInputMode={hooks.setInputMode} />
              }
              channel={channel}
              formName={hooks.formName}
              onEmojiPick={refocusInput}
              spaceId={channel.spaceId}
            />
          )}
        </Box>
      </VerticalSwipeContainer>
      <TaskModalContainer onClose={hooks.closeTaskDetail} taskDetail={hooks.taskDetail} />
    </TaskFormContext.Provider>
  )
}

export default React.memo(MessageTextInput)
