import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { getColor } from '@/core/constants/colors'
import { platformUtils } from '@/core/utils'
import { useSetStatusBar } from '@/hooks/components'
import { Theme } from './types'

export interface BaseHeaderProps {
  children: React.ReactNode
  height?: number
  theme: Theme
  maxContentWidth?: number
}

function BaseHeader({ children, height = 60, theme, maxContentWidth }: BaseHeaderProps) {
  useSetStatusBar(theme === 'white' ? 'dark' : 'light')
  const insets = useSafeAreaInsets()
  const paddingTop = platformUtils.isWeb ? 10 : insets.top

  const backgroundStyle = React.useMemo<ViewStyle>(
    () => ({
      backgroundColor: getBackgroundColor(theme),
      borderBottomColor: getBorderColor(theme),
      borderBottomWidth: 1,
      flexDirection: 'row',
      height: height + paddingTop,
      justifyContent: 'center',
      padding: 10,
      paddingTop,
    }),
    [theme, height, paddingTop],
  )
  const foregroundStyle = React.useMemo<ViewStyle>(() => {
    const baseStyle: ViewStyle = { alignItems: 'center', flex: 1, flexDirection: 'row' }

    if (maxContentWidth) {
      return { ...baseStyle, maxWidth: maxContentWidth }
    }
    return baseStyle
  }, [maxContentWidth])

  /* eslint-disable react/style-prop-object */
  return (
    <View style={backgroundStyle}>
      <View style={foregroundStyle}>{children}</View>
    </View>
  )
  /* eslint-enable */
}

const getBackgroundColor = (theme: Theme): string | undefined => {
  if (theme === 'white') {
    return getColor('ghost')
  }
  if (theme === 'blue') {
    return getColor('emmre-blue+2')
  }
  return getColor('emmre-purple+4')
}

const getBorderColor = (theme: Theme): string | undefined => {
  if (theme === 'white') {
    return getColor('heather')
  }
  return getBackgroundColor(theme)
}

export default React.memo(BaseHeader)
