import React from 'react'
import { ViewStyle } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { componentUtils } from '@/core/utils/'
import { useAmplitude } from '@/hooks'
import { AmplitudeProps } from '@/types/amplitude'
import { ExpandablePadding, HitSlop } from '@/types/components'
import { FontSize, FontWeight } from '@/types/typography'
import Text from './Text'
import Touchable from './Touchable'

export interface BaseButtonProps extends AmplitudeProps {
  backgroundColor: ColorName
  borderColor?: ColorName
  borderRadius?: number
  disabled?: boolean
  foregroundColor: ColorName
  hitSlop?: HitSlop
  label: string
  labelSize?: FontSize
  minWidth?: number
  onPress: () => void
  onPressDisabled?: () => void
  padding: ExpandablePadding
  raised?: boolean
  testID?: string
  weight?: FontWeight
}

const shadowProperties: ViewStyle = {
  shadowColor: getColor('black'),
  shadowOffset: { height: 2, width: 0 },
  shadowOpacity: 0.5,
}

function BaseButton({
  amplitudeEventName,
  amplitudeEventProperties,
  backgroundColor,
  borderColor = 'transparent',
  borderRadius = 5,
  disabled = false,
  foregroundColor,
  hitSlop,
  label,
  labelSize,
  minWidth,
  onPress,
  onPressDisabled,
  padding,
  raised,
  testID,
  weight,
}: BaseButtonProps) {
  const wrappedOnPress = useAmplitude(onPress, amplitudeEventName, amplitudeEventProperties)
  const style = React.useMemo<ViewStyle>(
    () => ({
      alignItems: 'center',
      backgroundColor: getColor(backgroundColor),
      borderColor: getColor(borderColor),
      borderRadius,
      borderWidth: 1,
      minWidth,
      ...(raised ? shadowProperties : {}),
      ...componentUtils.expandPadding(padding),
    }),
    [minWidth, padding, backgroundColor, borderColor, borderRadius, raised],
  )

  return (
    <Touchable
      disabled={disabled}
      feedback="opacity"
      hitSlop={hitSlop}
      onPress={wrappedOnPress}
      onPressDisabled={onPressDisabled}
      style={style}
      testID={testID}
    >
      <Text align="center" color={foregroundColor} size={labelSize} weight={weight}>
        {label}
      </Text>
    </Touchable>
  )
}

export default React.memo(BaseButton)
