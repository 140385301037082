import React from 'react'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'
import { taskUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import { useAppDispatch } from '@/hooks'
import { useIsMounted } from '@/hooks/components'
import { questionThunks } from '@/thunks'
import { QuestionTask } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'

export const useSubmitResponse = (task: QuestionTask, onAnswered: (() => void) | undefined) => {
  const isMountedRef = useIsMounted()

  const dispatch = useAppDispatch()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const { responseForm } = useQuestionResponseContext()

  const submitResponse = React.useCallback(
    async (response: QuestionResponseForm) => {
      if (isSubmitting) {
        return
      }

      if (!taskUtils.responseIsComplete(response)) {
        return
      }

      setIsSubmitting(true)
      easeInOut()

      const result = await dispatch(questionThunks.answerQuestion(task.spaceId, task.id, response))

      if (isMountedRef.current) {
        setIsSubmitting(false)
      }

      if (result.ok && onAnswered) {
        onAnswered()
      }
    },
    [isSubmitting, dispatch, isMountedRef, onAnswered, setIsSubmitting, task],
  )

  const handleSubmitResponse = React.useCallback(
    () => submitResponse(responseForm),
    [submitResponse, responseForm],
  )

  return { handleSubmitResponse, isSubmitting, submitResponse }
}
