import { slackConnectionsAPI } from '@/api/requests'
import { slackConnectionActions } from '@/store/actions'
import { SlackConnection } from '@/types/entities'
import { Thunk } from '@/types/store'
import { showToast } from './notifications'
import { makeEnhancedRequest } from './utils'

export const getSlackConnectionsList =
  (spaceId: number, page = 1): Thunk<SlackConnection[]> =>
  async dispatch => {
    const request = slackConnectionsAPI.getSlackConnectionsList(spaceId, { page })
    const response = await dispatch(makeEnhancedRequest(request))

    if (!response.ok) {
      dispatch(
        showToast({
          body: 'An error occurred when getting your list of slack connetions',
          title: 'Error',
          type: 'error',
        }),
      )
      return []
    }

    dispatch(slackConnectionActions.upsertMany(response.data.slackConnections))

    return response.data.slackConnections
  }

export const deleteSlackConnection =
  (slackConnectionId: number): Thunk<boolean> =>
  async dispatch => {
    const request = slackConnectionsAPI.deleteSlackConnection(slackConnectionId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(slackConnectionActions.removeOne(slackConnectionId))
      return true
    }

    return false
  }
