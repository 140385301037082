import React from 'react'
import { AppLayout } from '@/types/components'

type AppLayoutContextType = AppLayout

const defaultAppLayoutContextValue: AppLayoutContextType = 'NARROW'

const AppLayoutContext = React.createContext<AppLayoutContextType>(defaultAppLayoutContextValue)

export const useAppLayoutContext = () => React.useContext(AppLayoutContext)

export default AppLayoutContext
