import React from 'react'
import { Task } from '@/types/entities'
import { TaskFocus } from '@/types/tasks'
import TaskListItemAssignedToFocus from './TaskListItemAssignedToFocus'
import TaskListItemBoardFocus from './TaskListItemBoardFocus'
import TaskListItemCreatedByFocus from './TaskListItemCreatedByFocus'
import TaskListItemCreatedDateFocus from './TaskListItemCreatedDateFocus'
import TaskListItemDueDateFocus from './TaskListItemDueDateFocus'
import TaskListItemPriorityFocus from './TaskListItemPriorityFocus'
import TaskListItemSourceFocus from './TaskListItemSourceFocus'
import TaskListItemStatusFocus from './TaskListItemStatusFocus'
import TaskListItemTagsFocus from './TaskListItemTagsFocus'

interface TaskListItemFocusProps {
  task: Task
  focus: TaskFocus
}

function TaskListItemFocus({ task, focus }: TaskListItemFocusProps) {
  if (focus === 'URGENCY') {
    return <TaskListItemPriorityFocus task={task} />
  }
  if (focus === 'BOARD') {
    return <TaskListItemBoardFocus task={task} />
  }
  if (focus === 'TAGS') {
    return <TaskListItemTagsFocus task={task} />
  }
  if (focus === 'CREATED_DATE') {
    return <TaskListItemCreatedDateFocus task={task} />
  }
  if (focus === 'ASSIGNED_USER') {
    return <TaskListItemAssignedToFocus task={task} />
  }
  if (focus === 'CREATOR') {
    return <TaskListItemCreatedByFocus task={task} />
  }
  if (focus === 'STATUS') {
    return <TaskListItemStatusFocus task={task} />
  }
  if (focus === 'SOURCE') {
    return <TaskListItemSourceFocus task={task} />
  }

  return <TaskListItemDueDateFocus hideIfNone task={task} />
}

export default React.memo(TaskListItemFocus)
