import React from 'react'
import ContainedIcon from '@/components/core/ContainedIcon'
import { IconName } from '@/core/constants/icons'
import { channelUtils } from '@/core/utils'
import { Channel, ResolvedChannel } from '@/types/entities'
import BaseContent from './BaseContent'

interface NonHubContentProps {
  channel: ResolvedChannel
}

const getIconName = (channel: Channel): IconName => {
  if (channelUtils.isDM(channel)) {
    return 'arrows-alt-h'
  }
  if (channelUtils.isPrivateGroup(channel)) {
    return 'user-lock'
  }
  return 'user-friends'
}

function NonHubContent({ channel }: NonHubContentProps) {
  const subtitle = channel.userIds.length === 1 ? 'Just you' : `${channel.userIds.length} members`

  return (
    <BaseContent
      icon={
        <ContainedIcon
          backgroundColor="anchor"
          color="ghost"
          kind="solid"
          name={getIconName(channel)}
          padding={10}
          size={24}
        />
      }
      subtitle={subtitle}
      title={channel.name}
    />
  )
}

export default React.memo(NonHubContent)
