import { ScrollPosition, Side } from './types'

export const determineScrollPosition = (
  containerWidth: number,
  contentWidth: number,
  scrollOffset: number,
): ScrollPosition => {
  const atEnd = contentWidth - scrollOffset <= containerWidth

  if (scrollOffset === 0) {
    return atEnd ? 'FULL' : 'BEGINNING'
  }

  if (atEnd) {
    return 'END'
  }

  return 'MIDDLE'
}

export const shouldShowButton = (side: Side, scrollPosition: ScrollPosition): boolean => {
  switch (scrollPosition) {
    case 'FULL':
      return false
    case 'MIDDLE':
      return true
    case 'BEGINNING':
      return side === 'right'
    case 'END':
      return side === 'left'
    default:
      return true
  }
}
