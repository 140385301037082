import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { amplitudeProxy, sentryProxy, stonlyProxy } from '@/core/instrumentation'
import { Thunk } from '@/types/store'

export const initializeApplication = (): Thunk<void> => async () => {
  // NOTE: only put things here that have to be done before anything can be displayed, like loading
  //       system fonts.
  await loadFonts()
}

export const preInitializeApplication = () => {
  SplashScreen.preventAutoHideAsync()
  polyfill()
  sentryProxy.initialize()
  amplitudeProxy.initialize()
  stonlyProxy.initialize()
}

const loadFonts = async () => {
  await Font.loadAsync({
    'fa-pro-brands': require('assets/fonts/fa-brands-400.ttf'),
    'fa-pro-light': require('assets/fonts/fa-light-300.ttf'),
    'fa-pro-regular': require('assets/fonts/fa-regular-400.ttf'),
    'fa-pro-solid': require('assets/fonts/fa-solid-900.ttf'),
    'graphik': require('assets/fonts/GraphikRegular.otf'),
    'graphik-bold': require('assets/fonts/GraphikBold.otf'),
    'graphik-bold-italic': require('assets/fonts/GraphikBoldItalic.otf'),
    'graphik-italic': require('assets/fonts/GraphikRegularItalic.otf'),
    'graphik-medium': require('assets/fonts/GraphikMedium.otf'),
    'graphik-medium-italic': require('assets/fonts/GraphikMediumItalic.otf'),
  })
}

const polyfill = () => {
  polyfillIntl()
}

const polyfillIntl = () => {
  // TODO: Can we remove this with the current versino of expo or react native?
  if (!global.Intl) {
    global.Intl = require('intl')
    require('intl/locale-data/jsonp/en')
  }
}
