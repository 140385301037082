import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import Text from '../Text'
import Touchable from '../Touchable'

interface ChoiceProps<T extends string> {
  disabled: boolean
  label: string | React.ReactElement
  value: T
  onSelect: (value: T) => void
  isSelected: boolean
  selectedColor: ColorName
}

function Choice<T extends string = string>({
  disabled,
  label,
  value,
  onSelect,
  isSelected,
  selectedColor,
}: ChoiceProps<T>) {
  const handleSelect = React.useCallback(() => onSelect(value), [onSelect, value])

  return (
    <Touchable
      disabled={disabled}
      feedback="opacity"
      onPress={handleSelect}
      style={[styles.container, isSelected ? { borderColor: getColor(selectedColor) } : null]}
    >
      {React.isValidElement(label) ? (
        label
      ) : (
        <View style={styles.textContainer}>
          <Text size="h3">{label}</Text>
        </View>
      )}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 3,
    borderColor: 'transparent',
  },
  textContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
})

export default React.memo(Choice) as typeof Choice
