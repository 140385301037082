import React from 'react'
import { collectionUtils as C, messageUtils } from '@/core/utils'
import { MessageListItemData } from '@/types/messaging'

export const useLatestMessageId = (messageItems: MessageListItemData[]) =>
  React.useMemo(() => {
    const firstItem = C.firstOrDefault(
      item => messageUtils.isSentMessage(item.message),
      messageItems,
      null,
    )
    if (firstItem) {
      return firstItem.message.id
    }
    return undefined
  }, [messageItems])
