import React from 'react'
import Action, { ActionProps } from '@/components/core/Action'
import Text from '@/components/core/Text'
import { typeUtils } from '@/core/utils'
import CenteredLayout from '../CenteredLayout'

export interface ModalLayoutHeaderProps {
  leftAction?: ActionProps | null | undefined
  rightAction?: ActionProps | null | undefined
  title?: string | React.ReactNode
}

function ModalLayoutHeader({ leftAction, rightAction, title = '' }: ModalLayoutHeaderProps) {
  return (
    <CenteredLayout
      centerContent={
        typeUtils.isString(title) ? (
          <Text size="h2" weight="medium">
            {title}
          </Text>
        ) : (
          title
        )
      }
      leftContent={leftAction ? <Action {...leftAction} /> : null}
      rightContent={rightAction ? <Action {...rightAction} /> : null}
    />
  )
}

export default React.memo(ModalLayoutHeader)
