import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { dateUtils as D } from '@/core/utils'
import { Message } from '@/types/entities'

interface CommentListItemProps {
  message: Message
}

function CommentListItem({ message }: CommentListItemProps) {
  const { spaceId, creatorId, content } = message

  return (
    <Background borderRadius={4} color="ghost">
      <Box direction="row" gapAll="medium">
        <UserProfilePicture spaceId={spaceId} userId={creatorId} />
        <Box flex={1} gapLeft="medium" gapTop={6}>
          <TextWithLinks color="shadow">{content}</TextWithLinks>
          <Box gapTop="small">
            <Text color="shadow" size="sm">
              {D.formatDateTime(D.parse(message.createdAt))}
            </Text>
          </Box>
        </Box>
      </Box>
    </Background>
  )
}

export default React.memo(CommentListItem)
