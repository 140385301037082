import React from 'react'
import FormTextInput, { FormTextInputProps } from '@/components/form/FormTextInput'
import type { RefType } from '@/components/form/FormTextInput'
import { config } from '@/core'
import { useTyping } from '@/hooks/messages'
import { OnKeyPress } from '@/types/components'
import { ResolvedChannel } from '@/types/entities'
import { InputMode } from '@/types/messaging'
import { getPlaceholder } from './utils'

interface ContentInputProps {
  autoFocus: boolean
  channel?: ResolvedChannel
  fieldName?: string
  formName: string
  inputMode: InputMode
  onBlur?: () => void
  onFocus?: () => void
  onSubmit?: () => void
  onKeyPress?: OnKeyPress
  placeholder?: string
  style?: FormTextInputProps['style']
}

const ContentInput = React.forwardRef(
  (
    {
      autoFocus,
      channel,
      fieldName = 'content',
      formName,
      inputMode,
      onBlur,
      onFocus,
      onSubmit,
      onKeyPress,
      placeholder,
      style,
    }: ContentInputProps,
    ref: RefType,
  ) => {
    const channelNamePlaceholder = getPlaceholder(channel)

    let inputPlaceholder = ''

    if (placeholder) {
      inputPlaceholder = placeholder
    } else if (inputMode === 'message') {
      inputPlaceholder = channelNamePlaceholder
    } else if (inputMode === 'task') {
      inputPlaceholder = 'Write Task Name'
    } else if (inputMode === 'question') {
      inputPlaceholder = 'Write Question'
    }

    const { handleTyping } = useTyping(channel, config.typingReportInterval)

    // If onSubmit is given then we will blur on submit and do the thing. We will refocus also if
    // there's a ref
    const handleSubmit = React.useCallback(() => {
      if (onSubmit) {
        onSubmit()
        if (ref && 'current' in ref) {
          setTimeout(() => ref.current?.focus())
        }
      }
    }, [onSubmit, ref])

    return (
      <FormTextInput
        ref={ref}
        autoFocus={autoFocus}
        blurOnSubmit={!!onSubmit}
        expandable
        fieldName={fieldName}
        formName={formName}
        kind="bare"
        maxHeight={100}
        multiline
        onBlur={onBlur}
        onChange={handleTyping}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onSubmitEditing={onSubmit ? handleSubmit : undefined}
        placeholder={inputPlaceholder}
        style={style}
        textAlignVertical="top"
      />
    )
  },
)

export default React.memo(ContentInput)

export { RefType }
