import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import { batch } from 'react-redux'
import { fileUtils, fnUtils, permissionUtils, platformUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { attachmentActions, formActions } from '@/store/actions'
import { fileThunks } from '@/thunks'
import { Attachment } from '@/types/api'
import { useActions } from '../actions'

const actionArgs = { title: 'Choose Attachment' }

export const useAttachments = (spaceId: number, formName: string, attachmentsFieldName: string) => {
  const dispatch = useAppDispatch()

  const handleRemoveAttachment = React.useCallback(
    (attachment: Attachment) =>
      // TODO: Delete the file on the server?
      dispatch(
        formActions.removeMember({
          fieldName: attachmentsFieldName,
          formName,
          value: attachment.temporaryId,
        }),
      ),
    [dispatch, formName, attachmentsFieldName],
  )

  const handleAddAttachment = React.useCallback(
    async (uri: string, filename: string | null = null) => {
      const attachment = fileUtils.buildAttachment(uri, filename)
      const { temporaryId } = attachment

      batch(() => {
        dispatch(attachmentActions.upsertOne(attachment))
        dispatch(
          formActions.addMember({ fieldName: attachmentsFieldName, formName, value: temporaryId }),
        )
      })

      const file = await dispatch(fileThunks.uploadLocalSpaceFile(spaceId, attachment))

      if (!file) {
        dispatch(
          formActions.removeMember({
            fieldName: attachmentsFieldName,
            formName,
            value: temporaryId,
          }),
        )
        return
      }

      dispatch(
        attachmentActions.updateOne({
          changes: { fileId: file.id, status: 'SUCCESS' },
          id: temporaryId,
        }),
      )
    },
    [dispatch, spaceId, formName, attachmentsFieldName],
  )

  const handlePickPhoto = React.useCallback(async () => {
    const granted = await permissionUtils.requestMediaLibraryPermissions(
      'attach a photo or video',
      true,
    )

    if (!granted) {
      return
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      exif: true,
      mediaTypes: ImagePicker.MediaTypeOptions.All,
    })

    if (pickerResult.cancelled === true) {
      return
    }

    handleAddAttachment(pickerResult.uri)
  }, [handleAddAttachment])

  const handleTakePhoto = React.useCallback(async () => {
    const granted = await permissionUtils.requestCameraPermissions('attach a photo or video', true)

    if (granted === false) {
      return
    }

    const cameraResult = await ImagePicker.launchCameraAsync({
      exif: true,
      mediaTypes: ImagePicker.MediaTypeOptions.All,
    })

    if (cameraResult.cancelled === true) {
      return
    }

    handleAddAttachment(cameraResult.uri)
  }, [handleAddAttachment])

  const handlePickDocument = React.useCallback(async () => {
    const result = await DocumentPicker.getDocumentAsync({ multiple: true })

    if (result.type === 'cancel') {
      return
    }

    const { output } = result

    if (output && output.length > 1) {
      for (let i = 0; i < output.length; i += 1) {
        const file = output[i]

        let uri: string | null = null

        try {
          uri = await fileUtils.fileToDataURI(file)
        } catch {
          uri = null
        }

        if (uri) {
          handleAddAttachment(uri, file.name)
        }
      }
    } else {
      const { uri, name } = result
      handleAddAttachment(uri, name)
    }
  }, [handleAddAttachment])

  const handleAttachPhoto = useActions<void>(
    React.useMemo(
      () => [
        {
          handler: handleTakePhoto,
          label: 'Take Photo',
        },
        {
          handler: handlePickPhoto,
          label: 'Choose From Library',
        },
        {
          handler: fnUtils.noOp,
          label: 'Cancel',
          type: 'cancel',
        },
      ],
      [handleTakePhoto, handlePickPhoto],
    ),
    actionArgs,
  )

  const handleAttachFileNative = useActions<void>(
    React.useMemo(
      () => [
        {
          handler: handleTakePhoto,
          label: 'Take Photo',
        },
        {
          handler: handlePickPhoto,
          label: 'Choose From Library',
        },
        {
          handler: handlePickDocument,
          label: 'Choose From Files',
        },
        {
          handler: fnUtils.noOp,
          label: 'Cancel',
          type: 'cancel',
        },
      ],
      [handleTakePhoto, handlePickDocument, handlePickPhoto],
    ),
    actionArgs,
  )

  const handleAttachFile = platformUtils.isWeb ? handlePickDocument : handleAttachFileNative

  return {
    handleAddAttachment,
    handleAttachFile,
    handleAttachPhoto,
    handlePickDocument,
    handleRemoveAttachment,
  }
}
