import React from 'react'
import { FlatList, ViewToken } from 'react-native'
import { eventTypes } from '@/core/constants'
import { useSubscription } from '@/hooks/pubsub'
import { ItemType, VisibleItemsChangedHandler } from './types'

interface ViewabilityInfo {
  viewableItems: ViewToken[]
  changed: ViewToken[]
}

export const useFlatListViewableItemsChanged = (
  onVisibleItemsChanged: VisibleItemsChangedHandler,
  firstUnreadMessageId: number | undefined,
) => {
  // FlatList doesen't like it if you change the viewableItemsChanged handler on the fly, so we
  // use refs to make sure the handler never changes
  const firstUnreadMessageIdRef = React.useRef<number | undefined>(firstUnreadMessageId)
  const onVisibleItemsChangedRef = React.useRef(onVisibleItemsChanged)

  firstUnreadMessageIdRef.current = firstUnreadMessageId
  onVisibleItemsChangedRef.current = onVisibleItemsChanged

  const handleViewableItemsChanged = React.useCallback(({ viewableItems }: ViewabilityInfo) => {
    const visibleMessages = viewableItems.map(token => token.item as ItemType)
    onVisibleItemsChangedRef.current(visibleMessages, firstUnreadMessageIdRef.current)
  }, [])

  return handleViewableItemsChanged
}

export const useFlatListScrollOnMessageSend = (listRef: React.RefObject<FlatList | null>) => {
  const scrollToBottom = React.useCallback(
    () => listRef.current?.scrollToOffset({ animated: true, offset: 0 }),
    [listRef],
  )
  useSubscription(eventTypes.MESSAGE_SENT, scrollToBottom)
}
