import React from 'react'
import HubUserPictures from '@/components/channels/HubUserPictures'
import Link from '@/components/core/Link'
import ListItem from '@/components/core/ListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { channelUtils } from '@/core/utils'
import { Channel } from '@/types/entities'

interface HubListItemProps {
  hub: Channel
  onUnarchive: (channelId: number) => void
}

function HubListItem({ hub, onUnarchive }: HubListItemProps) {
  const handleUnarchive = React.useCallback(() => {
    onUnarchive(hub.id)
  }, [hub.id, onUnarchive])

  return (
    <ListItem>
      <HubUserPictures hub={hub} size="SMALL" />
      <Box flex={1} gapHorizontal="large">
        <Text size="h4" weight="medium">
          {channelUtils.channelToLabel(hub)}
        </Text>
        <Text size="h5">{hub.name}</Text>
      </Box>
      <Link label="Reactivate Hub" onPress={handleUnarchive} underlined />
    </ListItem>
  )
}

export default React.memo(HubListItem)
