import EventEmitter from 'events'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { asyncUtils } from '@/core/utils'
import type { RTMEvent } from '@/types/rtm'

const WAIT_MS = 5

const readyState = {
  CLOSED: 3,
  CLOSING: 2,
  CONNECTING: 0,
  OPEN: 1,
}

type ReadyState = keyof typeof readyState
type MessagePayload = Record<string, any>

export type OpenHandler = (event: Event) => void
export type CloseHandler = (arg: { reason: string; event?: CloseEvent }) => void
export type MessageHandler = (arg: {
  type: string
  payload: MessagePayload
  event: MessageEvent
}) => void
export type ErrorHandler = (arg: { message?: string; event?: Event }) => void

interface Handlers {
  open: OpenHandler
  close: CloseHandler
  message: MessageHandler
  error: ErrorHandler
}

export default class WebSocketManager {
  uri: string
  socket: WebSocket | null
  emitter: EventEmitter

  constructor(uri: string) {
    this.uri = uri
    this.socket = null
    this.emitter = new EventEmitter()
  }

  async open(): Promise<void> {
    const status = this.status()

    if (status === 'CONNECTING' || status === 'CLOSING') {
      await asyncUtils.sleep(WAIT_MS)
      return this.open()
    }

    if (status === 'OPEN') {
      return Promise.resolve()
    }

    this.removeHandlers()
    this.socket = new WebSocket(this.uri)

    return new Promise((resolve, reject) => {
      const socket = this.socket as WebSocket

      const successHandler = (event: Event) => {
        this.addHandlers(event)

        socket.removeEventListener('open', successHandler)
        socket.removeEventListener('close', failureHandler)

        resolve()
      }
      const failureHandler = () => {
        socket.removeEventListener('open', successHandler)
        socket.removeEventListener('close', failureHandler)

        reject()
      }

      socket.addEventListener('open', successHandler)
      socket.addEventListener('close', failureHandler)
    })
  }

  async close() {
    const status = this.status()

    if (status === 'CONNECTING') {
      await asyncUtils.sleep(WAIT_MS)
      this.close()
    } else if (status === 'OPEN') {
      const socket = this.socket as WebSocket

      socket.close(1000)
    }
  }

  status(): ReadyState {
    if (!this.socket) {
      return 'CLOSED'
    }

    switch (this.socket.readyState) {
      case readyState.CONNECTING:
        return 'CONNECTING'
      case readyState.OPEN:
        return 'OPEN'
      case readyState.CLOSING:
        return 'CLOSING'
      case readyState.CLOSED:
      default:
        return 'CLOSED'
    }
  }

  addEventListener<T extends keyof Handlers>(name: T, handler: Handlers[T]) {
    this.emitter.on(name, handler)
  }

  removeEventListener<T extends keyof Handlers>(name: T, handler: Handlers[T]) {
    this.emitter.off(name, handler)
  }

  async send(event: RTMEvent) {
    const status = this.status()

    if (status === 'OPEN') {
      const socket = this.socket as WebSocket
      socket.send(JSON.stringify({ payload: decamelizeKeys(event.payload), type: event.type }))
    } else if (status === 'CONNECTING') {
      await asyncUtils.sleep(WAIT_MS)
      this.send(event)
    } else {
      this.emitter.emit('close', { reason: 'Trying to send message on closed socket' })
    }
  }

  private addHandlers(openEvent: Event) {
    if (!this.socket) {
      return
    }

    // On web (Firefox at least) the open event isn't getting called when attached after open.
    this.handleOpen(openEvent)

    this.socket.addEventListener('close', this.handleClose)
    this.socket.addEventListener('message', this.handleMessage)
    this.socket.addEventListener('error', this.handleError)
  }

  private removeHandlers() {
    if (!this.socket) {
      return
    }

    this.socket.removeEventListener('close', this.handleClose)
    this.socket.removeEventListener('message', this.handleMessage)
    this.socket.removeEventListener('error', this.handleError)
  }

  private handleOpen = (event: Event) => {
    this.emitter.emit('open', event)
  }

  private handleClose = (event: CloseEvent) => {
    this.removeHandlers()
    this.emitter.emit('close', { event, reason: event.reason })
  }

  private handleMessage = (event: MessageEvent) => {
    const { type, payload } = JSON.parse(event.data)
    this.emitter.emit('message', { event, payload: camelizeKeys(payload), type })
  }

  private handleError = (event: Event) => {
    this.emitter.emit('error', { event })
  }
}
