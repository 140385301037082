import { MyDayStatus } from './types'

export const getMyDayStatus = (isInMyDay: boolean, isIgnored: boolean) => {
  if (isInMyDay) {
    return 'MY_DAY'
  }
  if (isIgnored) {
    return 'IGNORED'
  }
  return 'NOT_MY_DAY'
}

export const getMyDayStatusLabel = (status: MyDayStatus) => {
  if (status === 'MY_DAY') {
    return 'Added to'
  }
  if (status === 'IGNORED') {
    return 'Ignored in'
  }
  return 'Add to'
}
