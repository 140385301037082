import React from 'react'
import { alwaysAsyncWarn, noOpWarn } from '@/core/logging'
import { Task } from '@/types/entities'
import { Action, Setter } from '@/types/generics'
import { QuestionMode, TaskDetail, TaskFormSize, TaskType } from '@/types/tasks'

export type TaskFormInteractionMode = 'create' | 'edit' | 'view'

export interface TaskFormContextType {
  cancel: () => void
  canClose: boolean
  canSubmit: boolean
  close: () => void
  formName: string
  interactionMode: TaskFormInteractionMode
  questionMode: QuestionMode
  setQuestionMode: Setter<QuestionMode>
  setTaskFormSize: Setter<TaskFormSize> | undefined
  showDetail: Action<TaskDetail>
  submit: () => Promise<boolean>
  submitDisabled: () => void
  task?: Task
  taskFormSize: TaskFormSize
  taskType: TaskType
}

const warningMessage = 'task form context not present'
const noOp = noOpWarn(warningMessage)

const defaultContext: TaskFormContextType = {
  canClose: false,
  canSubmit: false,
  cancel: noOp,
  close: noOp,
  formName: '__task_form_not_set__',
  interactionMode: 'view',
  questionMode: 'YES_NO',
  setQuestionMode: noOp,
  setTaskFormSize: undefined,
  showDetail: noOp,
  submit: alwaysAsyncWarn(true, warningMessage),
  submitDisabled: noOp,
  task: undefined,
  taskFormSize: 'SMALL',
  taskType: 'TASK',
}

const TaskFormContext = React.createContext<TaskFormContextType>(defaultContext)

export const useTaskFormContext = () => React.useContext(TaskFormContext)

export default TaskFormContext
