export const pluralize = (count: number, singular: string, plural?: string) => {
  if (count === 1) {
    return `1 ${singular}`
  }
  const label = count === 0 ? 'No' : count.toString()
  if (plural) {
    return `${label} ${plural}`
  }
  return `${label} ${singular}s`
}

export const sortAscending = (left: string, right: string): number => left.localeCompare(right)

export const sortDescending = (left: string, right: string): number =>
  -1 * sortAscending(left, right)

export const toList = (messages: string[], terminalJoiner = ', and'): string => {
  if (messages.length === 0) {
    throw new Error('cannot join a list without items')
  }
  if (messages.length === 1) {
    return messages[0]
  }

  const init = messages.slice(0, -1)
  const last = messages[messages.length - 1]

  return `${init.join(', ')}${terminalJoiner} ${last}`
}

export const splitOnce = (separator: string, value: string) => {
  const [first, ...second] = value.split(separator)
  return [first, second.join(separator)]
}

export const sentenceCase = (value: string) => {
  const firstLetter = value.substring(0, 1)
  const rest = value.substring(1)

  return `${firstLetter.toUpperCase()}${rest}`
}
