import React from 'react'
import { TaskDetail } from '@/types/tasks'
import TaskAttachmentsModal from './TaskAttachmentsModal'
import TaskBoardModal from './TaskBoardModal'
import TaskChannelModal from './TaskChannelModal'
import TaskChecklistModal from './TaskChecklistModal'
import TaskDatesModal from './TaskDatesModal'
import TaskNotesModal from './TaskNotesModal'
import TaskTagsModal from './TaskTagsModal'
import TaskUserModal from './TaskUserModal'

interface TaskModalContainerProps {
  taskDetail: TaskDetail | null
  onClose: (cancelled: boolean) => void
}

function TaskModalContainer({ taskDetail, onClose }: TaskModalContainerProps) {
  return (
    <>
      <TaskNotesModal isOpen={taskDetail === 'NOTES'} onClose={onClose} />
      <TaskTagsModal isOpen={taskDetail === 'TAGS'} onClose={onClose} />
      <TaskBoardModal isOpen={taskDetail === 'BOARD'} onClose={onClose} />
      <TaskDatesModal isOpen={taskDetail === 'DUE_DATE'} onClose={onClose} />
      <TaskChannelModal isOpen={taskDetail === 'CHANNEL'} onClose={onClose} />
      <TaskUserModal isOpen={taskDetail === 'ASSIGNED_USER'} onClose={onClose} />
      <TaskChecklistModal isOpen={taskDetail === 'CHECKLIST_ITEMS'} onClose={onClose} />
      <TaskAttachmentsModal isOpen={taskDetail === 'ATTACHMENTS'} onClose={onClose} />
    </>
  )
}

export default React.memo(TaskModalContainer)
