import { Comparer, Dictionary, KeyType, Pair, Predicate } from '@/types/generics'
import { isType } from './types'

// This function will not work if T is an array type, like a tuple.
export function toArray<T>(value: null | T | T[]): T[] | null {
  if (value === null) {
    return null
  }
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

export const toDictionary = <T>(data: T[], getKey: (t: T) => KeyType): Dictionary<T> =>
  Object.fromEntries(data.map(item => [getKey(item), item]))

export function pick<V>(keys: string[], data: Dictionary<V>): Dictionary<V> {
  return keys.reduce((acc: Dictionary<V>, key) => {
    if (key in data) {
      acc[key] = data[key]
    }
    return acc
  }, {})
}

export function sort<T>(comparer: Comparer<T>, items: T[]): T[] {
  const copy = [...items]
  copy.sort(comparer)
  return copy
}

export function combineComparers<T>(first: Comparer<T>, second: Comparer<T>): Comparer<T> {
  return (left: T, right: T): number => {
    const firstComp = first(left, right)
    return firstComp === 0 ? second(left, right) : firstComp
  }
}

export function shuffle<T>(items: T[]): T[] {
  return items
    .map((item: T): Pair<T, number> => [item, Math.random()])
    .sort((p1, p2) => p1[1] - p2[1])
    .map(p => p[0])
}

export function omit<K extends KeyType, V>(keys: K[] | Set<K>, data: Record<K, V>): Record<K, V> {
  if (Array.isArray(keys)) {
    return Object.fromEntries(
      Object.entries(data).filter(([key]) => !keys.includes(key as K)),
    ) as Record<K, V>
  }
  return Object.fromEntries(Object.entries(data).filter(([key]) => !keys.has(key as K))) as Record<
    K,
    V
  >
}

export function headOrDefault<T, F>(items: T[], fallback: F): T | F {
  if (items.length === 0) {
    return fallback
  }
  return head(items)
}

export function lastOrDefault<T, F>(items: T[], fallback: F): T | F {
  if (items.length === 0) {
    return fallback
  }
  return last(items)
}

export function firstOrDefault<T, F>(pred: Predicate<T>, items: T[], fallback: F): T | F {
  for (const item of items) {
    if (pred(item)) {
      return item
    }
  }
  return fallback
}

export function init<T>(items: T[]): T[] {
  return items.length === 0 ? items : items.slice(0, -1)
}

export function last<T>(items: T[]): T {
  if (items.length === 0) {
    throw new Error('cannot get last item of empty list')
  }
  return items[items.length - 1]
}

export function head<T>(items: T[]): T {
  if (items.length === 0) {
    throw new Error('cannot get first item of empty list')
  }
  return items[0]
}

export function tail<T>(items: T[]): T[] {
  return items.slice(1)
}

export const isLast = <T>(items: T[], index: number) => index === items.length - 1

export const without = <T>(item: T, items: T[]): T[] => items.filter(value => value !== item)

export const removeInPlace = <T>(items: T[], item: T): boolean => {
  const index = items.indexOf(item)

  if (index === -1) {
    return false
  }

  items.splice(index, 1)

  return true
}

export const removeAt = <T>(atIndex: number, items: T[]): T[] =>
  items.filter((value, index) => index !== atIndex)

export const replaceAt = <T>(atIndex: number, replaceWith: T, items: T[]): T[] => {
  const copy = [...items]
  copy.splice(atIndex, 1, replaceWith)
  return copy
}

export const index = <T, K extends KeyType>(getKey: (t: T) => K, items: T[]): Record<K, T> =>
  Object.fromEntries(items.map(item => [getKey(item), item])) as Record<K, T>

export const groupBy = <T, K extends string | number>(
  getKey: (t: T) => K,
  items: T[],
): Record<K, T[]> =>
  items.reduce((acc, item) => {
    const key = getKey(item)
    if (!(key in acc)) {
      acc[key] = []
    }
    acc[key].push(item)
    return acc
  }, {} as Record<K, T[]>)

export const partition = <T>(isInLeft: (t: T) => boolean, items: T[]): Pair<T[], T[]> => {
  const left: T[] = []
  const right: T[] = []

  items.forEach(item => {
    if (isInLeft(item)) {
      left.push(item)
    } else {
      right.push(item)
    }
  })

  return [left, right]
}

export function range(start: number, stop: number, step = 1): number[] {
  const values: number[] = []
  let currentValue = start
  while (currentValue < stop) {
    values.push(currentValue)
    currentValue += step
  }
  return values
}

export function rangeCount(count: number, start = 0, step = 1): number[] {
  return [...Array(count).keys()].map(n => n * step + start)
}

export const unique = <T>(values: T[]): T[] => [...new Set(values)]

export const setIntersection = <T>(left: Set<T>, right: Set<T>): Set<T> =>
  new Set([...left].filter(v => right.has(v)))

export const setEquals = <T>(left: Set<T>, right: Set<T>): boolean =>
  left.size === right.size && setIntersection(left, right).size === left.size

export const setUnion = <T>(left: Set<T>, right: Set<T>): Set<T> => new Set([...left, ...right])

export const setMerge = <T>(mutate: Set<T>, right: Iterable<T>): Set<T> => {
  for (const elem of right) {
    mutate.add(elem)
  }
  return mutate
}

export const setOverlaps = <T>(left: Set<T>, right: Iterable<T>): boolean => {
  for (const item of right) {
    if (left.has(item)) {
      return true
    }
  }
  return false
}

export const copyMissingPaths = (
  copyFrom: Dictionary,
  copyTo: Dictionary,
  paths: string[][],
): void => paths.forEach(path => copyMissingPath(copyFrom, copyTo, path))

const copyMissingPath = (copyFrom: Dictionary, copyTo: Dictionary, path: string[]) => {
  if (!(isType('Object', copyFrom) && isType('Object', copyTo))) {
    return
  }

  const [current, ...rest] = path

  if (current === undefined) {
    throw new Error('Empty copy path')
  }

  Object.keys(copyFrom).forEach(key => {
    if (key in copyTo) {
      if (rest.length !== 0) {
        copyMissingPath(copyFrom[key], copyTo[key], rest)
      }
    } else if (current === '*' || key === current) {
      copyTo[key] = copyFrom[key] // eslint-disable-line no-param-reassign
    }
  })
}

export const reversed = <T>(list: T[]): T[] => list.slice().reverse()

export const zip = <T, U>(left: T[], right: U[]): Pair<T, U>[] => {
  const output: Pair<T, U>[] = []
  const limit = Math.min(left.length, right.length)

  for (let i = 0; i < limit; i += 1) {
    output.push([left[i], right[i]])
  }

  return output
}

export const zipLongest = <T, U, F>(left: T[], right: U[], fallback: F): Pair<T | F, U | F>[] => {
  const output: Pair<T | F, U | F>[] = []
  const limit = Math.max(left.length, right.length)

  for (let i = 0; i < limit; i += 1) {
    output.push([i < left.length ? left[i] : fallback, i < right.length ? right[i] : fallback])
  }

  return output
}

export const chunk = <T>(width: number, items: T[]): T[][] => {
  const chunks: T[][] = []
  let start = 0

  while (start < items.length) {
    chunks.push(items.slice(start, width + start))
    start += width
  }

  return chunks
}
