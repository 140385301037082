import React from 'react'
import { messageUtils, typeUtils } from '@/core/utils'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import { authSelectors } from '@/store/selectors'
import { createMessage } from '@/thunks/messages'

export const useSendMessage = (channelId: number, spaceId: number) => {
  const creatorId = useAppSelector(authSelectors.myId)
  const dispatch = useAppDispatch()
  typeUtils.assertIsDefined(creatorId)

  return React.useCallback(
    async (content: string) => {
      const unsentMessage = messageUtils.createUnsentMessage(
        {
          channelId,
          content,
          creatorId,
          spaceId,
          threadId: null,
        },
        [],
      )
      await dispatch(createMessage(unsentMessage))
    },
    [channelId, creatorId, dispatch, spaceId],
  )
}
