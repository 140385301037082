import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import { ColorName } from '@/core/constants/colors'
import { IconKind, IconName } from '@/core/constants/icons'
import { TaskDetail } from '@/types/tasks'
import { useIsDetailActive } from '../hooks'

interface TaskDetailButtonProps {
  iconKind: IconKind
  iconName: IconName
  onPress: (taskDetail: TaskDetail) => void
  taskDetail: TaskDetail
}

function TaskDetailButton({ iconKind, iconName, onPress, taskDetail }: TaskDetailButtonProps) {
  const handlePress = React.useCallback(() => {
    onPress(taskDetail)
  }, [onPress, taskDetail])
  const isActive = useIsDetailActive(taskDetail)
  const color: ColorName = isActive ? 'emmre-blue' : 'anchor'

  return (
    <BaseIconButton
      color={color}
      hitSlop="none"
      kind={iconKind}
      name={iconName}
      onPress={handlePress}
      padding={10}
      shape="short-square"
      size="h3"
    />
  )
}

export default React.memo(TaskDetailButton)
