import React from 'react'
import TextInput from '@/components/core/TextInput'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'

interface OpenEndedResponseProps {
  onSubmitAnswer: () => void
  autoFocus?: boolean
}

function OpenEndedResponse({ onSubmitAnswer, autoFocus = false }: OpenEndedResponseProps) {
  const { responseForm, setOpenEndedResponse } = useQuestionResponseContext()

  return (
    <TextInput
      autoFocus={autoFocus}
      blurOnSubmit
      expandable
      kind="question"
      multiline
      onChangeText={setOpenEndedResponse}
      onSubmitEditing={onSubmitAnswer}
      placeholder="Enter Response"
      value={responseForm.openEndedResponse}
    />
  )
}

export default React.memo(OpenEndedResponse)
