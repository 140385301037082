import { hasFeature } from '@/core/config'
import { messageUtils, typeUtils } from '@/core/utils'
import { formActions } from '@/store/actions'
import { authSelectors, formSelectors } from '@/store/selectors'
import { Channel } from '@/types/entities'
import { Thunk } from '@/types/store'
import { fetchRandomGiphyResult } from './giphy'
import { createMessage } from './messages'
import { showToast } from './notifications'
import { createQuestion } from './tasks'

type SlashCommandSplit = { name: string; body: string }
type SlashCommandResult =
  | { type: 'SUCCESS' }
  | { type: 'NO_MATCH' | 'INVALID_FORMAT' | 'FAILED'; message?: string | undefined }

export const processSlashCommandForm =
  (channel: Channel, formName: string, fieldName: string): Thunk<boolean> =>
  async (dispatch, getState) => {
    const value = formSelectors.value(getState(), formName, fieldName)

    if (!value || value[0] !== '/') {
      return false
    }

    const result = await dispatch(processSlashCommand(channel, value))

    if (result.type === 'SUCCESS') {
      dispatch(formActions.clear(formName))
      return true
    }

    let body: string | undefined = result.message

    if (!body) {
      if (result.type === 'NO_MATCH') {
        body = 'Unrecognized slash command'
      } else if (result.type === 'INVALID_FORMAT') {
        body = 'Invalid format for command'
      } else if (result.type === 'FAILED') {
        body = 'Command failed'
      }
    }

    dispatch(
      showToast({
        body,
        title: 'Unable to process slash command',
        type: 'error',
      }),
    )

    return true
  }

export const processSlashCommand =
  (channel: Channel, command: string): Thunk<SlashCommandResult> =>
  async dispatch => {
    const { name, body } = splitCommand(command)

    switch (name) {
      case '/question':
        return dispatch(processQuestionCommand(channel, body))
      case '/giphy':
        return dispatch(processGiphyCommand(channel, body))
      default:
        return { type: 'NO_MATCH' }
    }
  }

const processGiphyCommand =
  (channel: Channel, body: string): Thunk<SlashCommandResult> =>
  async (dispatch, getState) => {
    if (!hasFeature('giphy')) {
      return { type: 'NO_MATCH' }
    }

    const result = await fetchRandomGiphyResult(body, 10)
    if (!result) {
      return { type: 'FAILED' }
    }
    const creatorId = authSelectors.myId(getState())
    typeUtils.assertIsDefined(creatorId)
    const unsentMessage = messageUtils.createUnsentMessage(
      {
        channelId: channel.id,
        content: result,
        creatorId,
        spaceId: channel.spaceId,
        threadId: null,
      },
      [],
    )
    await dispatch(createMessage(unsentMessage))
    return { type: 'SUCCESS' }
  }

const processQuestionCommand =
  (channel: Channel, command: string): Thunk<SlashCommandResult> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState())
    const notMyIds = channel.userIds.filter(userId => userId !== myId)

    if (notMyIds.length !== 1) {
      return {
        message: 'You can only create questions in channels with exactly one other user',
        type: 'FAILED',
      }
    }

    const assignedUserId = notMyIds[0]
    const match = /(.*)a:(.*)/i.exec(command)

    if (!match) {
      return { type: 'INVALID_FORMAT' }
    }

    const question = match[1].trim()
    const allResponses = match[2]
      .split(';')
      .map(response => response.trim())
      .filter(response => !!response)
    const responses = allResponses.filter(response => response.toLowerCase() !== 'other')
    const isOpenEnded = responses.length < allResponses.length

    if (!isOpenEnded && responses.length < 2) {
      return {
        message: 'Non-opened-ended questions must have at least two response options',
        type: 'FAILED',
      }
    }

    const result = await dispatch(
      createQuestion(
        channel.spaceId,
        {
          assignedUserId,
          channelId: channel.id,
          content: question,
          isOpenEnded,
          responseType: 'SINGLE',
          responses,
        },
        'NOTIFY',
      ),
    )

    return { type: result.ok ? 'SUCCESS' : 'FAILED' }
  }

const splitCommand = (command: string): SlashCommandSplit => {
  const parts = command.split(/\s+/).filter(word => !!word)
  const name = parts[0]
  const body = parts.slice(1).join(' ')

  return { body, name: name.toLowerCase() }
}
