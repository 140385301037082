import React from 'react'
import Badge from '@/components/core/Badge'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { IconName } from '@/core/constants/icons'

interface ChoiceProps {
  label: string
  icon?: IconName
  count?: number
}

function Choice({ count, label, icon }: ChoiceProps) {
  return (
    <Box
      alignItems="center"
      direction="row"
      gapHorizontal="large"
      gapVertical="medium"
      height={58}
      justifyContent="center"
    >
      {icon ? (
        <Box gapRight="medium">
          <Icon name={icon} />
        </Box>
      ) : null}
      <Text>{label}</Text>
      {count === undefined ? null : (
        <Box gapLeft="medium">
          <Badge background="heather" count={count} foreground="obsidian" />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(Choice)
