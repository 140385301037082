import React from 'react'
import Background from '@/components/core/Background'
import TextInput from '@/components/core/TextInput'
import { SummaryFieldName } from '../../types'
import { getSummaryFieldConfiguration } from '../../utils'
import { useEditField } from './hooks'

interface EditFieldProps {
  content: string | null
  fieldName: SummaryFieldName
}

function EditField({ content, fieldName }: EditFieldProps) {
  const { handleKeyPress, handleDoneEditing, newContent, setNewContent } = useEditField(
    fieldName,
    content,
  )

  return (
    <Background borderRadius={8} color="ghost">
      <TextInput
        autoFocus
        blurOnSubmit
        expandable
        multiline
        onChangeText={setNewContent}
        onKeyPress={handleKeyPress}
        onSubmitEditing={handleDoneEditing}
        placeholder={getSummaryFieldConfiguration(fieldName).placeholder}
        selectTextOnFocus
        value={newContent}
      />
    </Background>
  )
}

export default React.memo(EditField)
