import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { Channel, Myself, Space, SpaceUser, TeamMember, UserAttribute } from '@/types/entities'
import { AccessResponse } from './auth'

export interface UsersResponse {
  users: SpaceUser[]
}

export interface MeResponse {
  user: Myself
}

export interface UserResponse {
  user: SpaceUser
}

export type ResetPasswordResponse = AccessResponse

export interface UpdateUser
  extends Partial<Pick<Myself, 'firstName' | 'lastName' | 'emailAddress' | 'timezoneName'>> {
  profilePictureFileId?: number | null
  newPassword?: string
  currentPassword?: string
}

export type SetUserAttribute = {
  attribute: UserAttribute
  value: string
}

interface CreateAccountBody {
  emailAddress: string
  firstName: string
  lastName: string
  password: string
  paymentIntentId?: string | null
  signUpCode?: string | null
  spaceName: string
  tosAccepted: boolean
  verificationCode: string
  timezoneName: string | null
}

export interface CreatePartnerAccountBody extends CreateAccountBody {
  partnerEmailAddress: string | null
  partnerFirstName: string
  partnerLastName: string
  role: string
  asPartnership: boolean
}

export interface CreateTeamsAccountBody extends CreateAccountBody {
  taskName: string
  channelName: string
  teamMembers: TeamMember[]
}

type ValidatePartnerAccount = Partial<CreatePartnerAccountBody>

export interface CreateAccountResponse extends AccessResponse {
  spaces: Space[]
  channels: Channel[]
}

export const getUsersList = (spaceId: number, updatedSince: string | null, paging: PagingRequest) =>
  new Request<UsersResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['updatedSince', updatedSince]],
    url: `v1/spaces/${spaceId}/users`,
  })

export const updateUser = (userId: number, updates: UpdateUser) =>
  new Request<MeResponse>({
    authentication: 'PRIVATE',
    body: updates,
    method: 'PUT',
    url: `v1/users/${userId}`,
  })

export const setUserAttribute = (userId: number, body: SetUserAttribute) =>
  new Request<MeResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v1/users/${userId}/attributes`,
  })

export const removeUserAttribute = (userId: number, attribute: UserAttribute) =>
  new Request<MeResponse>({
    authentication: 'PRIVATE',
    body: { attribute, value: null },
    method: 'POST',
    url: `v1/users/${userId}/attributes`,
  })

export const sendVerificationCode = (emailAddress: string) =>
  new Request<void>({
    authentication: 'PUBLIC',
    body: { emailAddress },
    method: 'POST',
    url: 'v1/users/send-verification-code',
  })

export const requestPasswordReset = (emailAddress: string) =>
  new Request<void>({
    authentication: 'PUBLIC',
    body: { emailAddress },
    method: 'POST',
    url: 'v1/users/initiate-password-reset',
  })

export const resetPassword = (resetToken: string, password: string) =>
  new Request<AccessResponse>({
    authentication: 'PUBLIC',
    body: {
      password,
      resetToken,
    },
    method: 'PUT',
    url: 'v1/users/password',
  })

export const validatePartnerAccount = (body: ValidatePartnerAccount) =>
  new Request<void>({
    authentication: 'PUBLIC',
    body,
    method: 'POST',
    url: 'v1/partner-account/validate',
  })

export const createPartnerAccount = (body: CreatePartnerAccountBody) =>
  new Request<CreateAccountResponse>({
    authentication: 'PUBLIC',
    body,
    method: 'POST',
    url: 'v1/partner-account',
  })

export const createTeamsAccount = (body: CreateTeamsAccountBody) =>
  new Request<CreateAccountResponse>({
    authentication: 'PUBLIC',
    body,
    method: 'POST',
    url: 'v1/teams-account',
  })
