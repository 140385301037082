import { QuestionTask } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'
import { defaultState } from './constants'

export const getInitialState = (task: QuestionTask | null): QuestionResponseForm =>
  task ? taskToState(task) : defaultState

const taskToState = (task: QuestionTask): QuestionResponseForm => ({
  openEndedResponse: task.question.openEndedResponse,
  otherSelected: !!task.question.openEndedResponse,
  selectedResponseIds: task?.question.responses
    .filter(response => response.isSelected)
    .map(response => response.id),
})
