import React from 'react'
import { dateUtils as D, taskUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { useDestructiveAlert } from '@/hooks/actions'
import { useCheckNotify } from '@/hooks/tasks'
import { taskThunks } from '@/thunks'
import { Task } from '@/types/entities'
import { Optional } from '@/types/generics'

export const useDeleteTask = (task: Optional<Task>, onClose: () => void) => {
  const dispatch = useAppDispatch()
  const checkNotify = useCheckNotify()
  const [isDeleting, setIsDeleting] = React.useState(false)
  const executeDelete = React.useCallback(async () => {
    if (!task) {
      return
    }
    setIsDeleting(true)
    const notify = await checkNotify({
      changes: { status: 'DELETED', statusUpdatedAt: D.nowISO() },
      task,
    })
    await dispatch(taskThunks.deleteTask(task.spaceId, task.id, notify))
    setIsDeleting(false)
    onClose()
  }, [setIsDeleting, dispatch, task, onClose, checkNotify])
  const isQuestion = task && taskUtils.isQuestion(task)

  const handleDelete = useDestructiveAlert<void>(
    isQuestion ? 'Delete Question' : 'Delete Task',
    'Delete',
    executeDelete,
    `Are you sure you want to delete this ${isQuestion ? 'question' : 'task'}?`,
  )

  return {
    handleDelete,
    isDeleting,
  }
}
