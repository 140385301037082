import { refreshAccess } from '@/api/requests/auth'
import { dateUtils as D, asyncUtils } from '@/core/utils'
import { authActions } from '@/store/actions'
import { authSelectors } from '@/store/selectors'
import { Thunk } from '@/types/store'
import { signOut } from './signOut'

type RefreshMode = 'IF_NEEDED' | 'FORCE'
type RefreshResult = { type: 'EXCEPTION' | 'ERROR' } | { type: 'SUCCESS'; accessToken: string }

const refreshingAccessToken = {
  current: false,
}

const REFRESH_WAIT_MS = 5
const MAX_REFRESH_WAIT_MS = D.toMilliseconds.seconds(10)
const isRefreshing = () => refreshingAccessToken.current

const continueWaitingForRefresh = (sleepDurationMS: number) =>
  isRefreshing() && sleepDurationMS < MAX_REFRESH_WAIT_MS

export const getAccessToken =
  (refreshMode: RefreshMode): Thunk<string | null> =>
  async (dispatch, getState) => {
    // If a refresh is already occurring, wait until it's done and then return new refresh token
    if (isRefreshing()) {
      await asyncUtils.sleepWhile(continueWaitingForRefresh, REFRESH_WAIT_MS)

      // If we timed out waiting for the refresh token, return no access token
      if (isRefreshing()) {
        return null
      }

      return authSelectors.accessToken(getState())
    }

    const needsRefresh = refreshMode === 'FORCE' || authSelectors.needsRefresh(getState())

    // If a refresh isn't going and we need to refresh, go ahead and do that
    if (needsRefresh) {
      const newToken = await dispatch(tryToRefreshAccessToken())
      return newToken
    }

    // Otherwise just return the access token
    return authSelectors.accessToken(getState())
  }

const tryToRefreshAccessToken = (): Thunk<string | null> => async (dispatch, getState) => {
  const refreshToken = authSelectors.refreshToken(getState())

  if (!refreshToken) {
    dispatch(signOut('Signed user out due to missing refresh token'))
    return null
  }

  refreshingAccessToken.current = true

  try {
    // Retry refreshing while the request fails -- i.e. a network request can't be made
    const result = await asyncUtils.retryUntil(
      () => dispatch(refreshAccessToken(refreshToken)),
      refreshResult => refreshResult.type === 'EXCEPTION',
      tries => Math.max(tries * D.toMilliseconds.seconds(1), D.toMilliseconds.seconds(5)), // Maxxed Linear back off
    )

    if (result.type === 'SUCCESS') {
      return result.accessToken
    }

    dispatch(signOut('Signed user out due to refresh token error'))
    return null
  } finally {
    refreshingAccessToken.current = false
  }
}

const refreshAccessToken =
  (token: string): Thunk<RefreshResult> =>
  async dispatch => {
    const request = refreshAccess(token)
    const response = await request.getResponse()

    if (response.type === 'SUCCESS') {
      const { accessToken, expiresAt, refreshToken, user, scopes } = response.data
      dispatch(authActions.setAuth({ accessToken, expiresAt, refreshToken, scopes, user }))
      return { accessToken, type: 'SUCCESS' }
    }

    if (response.type === 'EXCEPTION') {
      // TODO: Set app in offline mode?
      return { type: 'EXCEPTION' }
    }

    return { type: 'ERROR' }
  }
