import * as SplashScreen from 'expo-splash-screen'
import React from 'react'
import { useAppInitializationStatus } from '@/hooks/initializers'
import AccountManagement from '@/screens/NarrowScreen/AccountManagement/lazy'
import ArchivedItemsScreen from '@/screens/NarrowScreen/ArchivedItemsScreen'
import AuthScreen from '@/screens/NarrowScreen/AuthScreen/lazy'
import CreateSpaceScreen from '@/screens/NarrowScreen/CreateSpaceScreen/lazy'
import GeneralCreateScreen from '@/screens/NarrowScreen/GeneralCreateScreen'
import JoinSpaceScreen from '@/screens/NarrowScreen/JoinSpaceScreen/lazy'
import { PartnerSignUpScreen, TeamsSignUpScreen } from '@/screens/NarrowScreen/SignUpScreen/lazy'
import SpacesScreen from './SpacesScreen/lazy'
import { RootStack as Stack, rootScreenOptions as screenOptions } from './routes'

function WideScreen() {
  const status = useAppInitializationStatus()

  const initialRoute = React.useMemo(() => {
    if (status === 'READY') {
      return 'spaces'
    }
    if (status === 'NO_SPACES') {
      return 'create-space'
    }
    return 'auth'
  }, [status])

  const isAuthenticated = status !== 'UNAUTHENTICATED'
  const showApp = status !== 'BOOTING'

  React.useEffect(() => {
    if (showApp) {
      SplashScreen.hideAsync()
    }
  }, [showApp])

  if (status === 'BOOTING') {
    return null
  }

  return (
    <Stack.Navigator initialRouteName={initialRoute}>
      {isAuthenticated ? (
        <>
          <Stack.Screen
            component={SpacesScreen}
            initialParams={{ spaceId: null }}
            name="spaces"
            options={screenOptions.spaces}
          />
          <Stack.Screen
            component={CreateSpaceScreen}
            name="create-space"
            options={screenOptions.createSpace}
          />
          <Stack.Screen
            component={GeneralCreateScreen}
            name="create"
            options={screenOptions.create}
          />
          <Stack.Screen
            key="account-management"
            component={AccountManagement}
            name="account-management"
            options={screenOptions.accountManagement}
          />
          <Stack.Screen
            key="archived-items"
            component={ArchivedItemsScreen}
            name="archived-items"
            options={screenOptions.archivedItems}
          />
        </>
      ) : null}
      <Stack.Screen component={AuthScreen} name="auth" options={screenOptions.auth} />
      <Stack.Screen component={PartnerSignUpScreen} name="sign-up" options={screenOptions.signUp} />
      <Stack.Screen
        component={TeamsSignUpScreen}
        name="teams-sign-up"
        options={screenOptions.signUp}
      />
      <Stack.Screen
        component={JoinSpaceScreen}
        name="join-space"
        options={screenOptions.joinSpace}
      />
    </Stack.Navigator>
  )
}

export default React.memo(WideScreen)
