import React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import Background from '@/components/core/Background'
import ModalBackdrop from '@/components/modals/ModalBackdrop'
import { getColor } from '@/core/constants/colors'
import { maxModalWidth } from '@/core/constants/sizes'
import ContentContainer from '../ContentContainer'
import ModalLayoutHeader from './ModalLayoutHeader'
import { ModalLayoutProps } from './types'

function ModalLayout({
  children,
  onCancel,
  insetBottom = true,
  maxWidth,
  fullHeight,
  headerColor = 'transparent',
  ...headerProps
}: ModalLayoutProps) {
  const extraStyle = React.useMemo<ViewStyle>(
    () => ({
      alignItems: 'stretch',
      flex: fullHeight ? 1 : undefined,
      maxWidth: maxWidth || maxModalWidth,
    }),
    [maxWidth, fullHeight],
  )
  return (
    <ModalBackdrop onPress={onCancel}>
      <View style={[styles.container, extraStyle]}>
        <ContentContainer
          avoidKeyboard
          backgroundStyle={styles.background}
          context="MODAL"
          foregroundStyle={styles.foreground}
          insetBottom={insetBottom}
        >
          <Background borderTopLeftRadius={9} borderTopRightRadius={9} color={headerColor}>
            <ModalLayoutHeader {...headerProps} />
          </Background>
          {children}
        </ContentContainer>
      </View>
    </ModalBackdrop>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    maxHeight: '100%',
    padding: 20,
  },
  container: {
    maxHeight: '100%',
    width: '100%',
  },
  foreground: {
    backgroundColor: getColor('ghost'),
    borderColor: getColor('shadow'),
    borderRadius: 10,
    borderWidth: 1,
    flex: 1,
    maxHeight: '100%',
  },
})

// ts-unused-exports:disable-next-line
export default React.memo(ModalLayout)
