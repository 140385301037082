import React from 'react'
import { View } from 'react-native'
import { getGaps } from '@/core/utils/components'
import { AlignItems, Gap, GapType, JustifyContent } from '@/types/components'

export type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse'

interface BoxProps {
  alignItems?: AlignItems
  alignSelf?: AlignItems
  children?: React.ReactNode
  direction?: Direction
  flex?: number
  flexGrow?: number
  flexShrink?: number
  gapAll?: Gap
  gapBottom?: Gap
  gapHorizontal?: Gap
  gapLeft?: Gap
  gapRight?: Gap
  gapTop?: Gap
  gapType?: GapType
  gapVertical?: Gap
  height?: number | string
  justifyContent?: JustifyContent
  maxHeight?: number | string
  maxWidth?: number | string
  minHeight?: number | string
  minWidth?: number | string
  testID?: string
  width?: number | string
  wrap?: Wrap
}

function Box({
  alignItems,
  alignSelf,
  children,
  direction,
  flex,
  flexGrow,
  flexShrink,
  gapAll,
  gapBottom,
  gapHorizontal,
  gapLeft,
  gapRight,
  gapTop,
  gapType = 'padding',
  gapVertical,
  height,
  justifyContent,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  testID,
  width,
  wrap = 'nowrap',
}: BoxProps) {
  const style = {
    alignItems,
    alignSelf,
    flex,
    flexDirection: direction,
    flexGrow,
    flexShrink,
    flexWrap: wrap,
    height,
    justifyContent,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    width,
    ...getGaps(gapType, {
      gapAll,
      gapBottom,
      gapHorizontal,
      gapLeft,
      gapRight,
      gapTop,
      gapVertical,
    }),
  }
  return (
    <View style={style} testID={testID}>
      {children}
    </View>
  )
}

export default React.memo(Box)
