import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { Channel, ChannelUser } from '@/types/entities'
import { Predicate } from '@/types/generics'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, channelId: number, userId: number): string =>
  `${spaceId}:${channelId}:${userId}`

const adapter = createEntityAdapter<ChannelUser>({
  selectId: channelUser => buildId(channelUser.spaceId, channelUser.channelId, channelUser.userId),
  sortComparer: (a, b) => a.channelId - b.channelId,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/channelsUsers',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    setAll: adapter.setAll,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.channelsUsers)

const selectById = (state: RootState, spaceId: number, channelId: number, userId: number) =>
  state.entities.channelsUsers.entities[buildId(spaceId, channelId, userId)]

const byIdSelector = (spaceId: number, channelId: number, userId: number) =>
  createSelector(
    sliceSelectors.selectEntities,
    records => records[buildId(spaceId, channelId, userId)],
  )

const byChannelAndUserIdSelector = (channel: Channel, userId: number) =>
  byIdSelector(channel.spaceId, channel.id, userId)

const byUserIdSelector = (userId: number) =>
  createSelector(sliceSelectors.selectAll, records =>
    records.filter(record => record.userId === userId),
  )

const byChannelIdSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectAll, records =>
    records.filter(record => record.spaceId === spaceId && record.channelId === channelId),
  )

export type CountUnreadOptions =
  | { mode: 'selected_space'; spaceId: number }
  | { mode: 'selected_channel'; spaceId: number; channelId: number }
  | { mode: 'selected_channels'; spaceId: number; channelIds: number[] }
  | { mode: 'other_spaces'; spaceId: number }
  | { mode: 'other_channels'; spaceId: number; channelId: number }
  | { mode: 'full_count' }

const buildCountUnreadMessagesFilter = (opts: CountUnreadOptions): Predicate<ChannelUser> => {
  if (opts.mode === 'selected_space') {
    return cu => cu.spaceId === opts.spaceId
  }
  if (opts.mode === 'selected_channel') {
    return cu => cu.spaceId === opts.spaceId && cu.channelId === opts.channelId
  }
  if (opts.mode === 'other_spaces') {
    return cu => cu.spaceId !== opts.spaceId
  }
  if (opts.mode === 'other_channels') {
    return cu => cu.spaceId !== opts.spaceId || cu.channelId !== opts.channelId
  }
  if (opts.mode === 'selected_channels') {
    const channelIds = new Set([...opts.channelIds])
    return cu => cu.spaceId === opts.spaceId && channelIds.has(cu.channelId)
  }
  return () => true
}

const addUnread = (sum: number, record: ChannelUser) => sum + record.unreadMessageCount

const unreadMessagesCountSelector = (userId: number, opts: CountUnreadOptions) =>
  createSelector(byUserIdSelector(userId), records =>
    records.filter(buildCountUnreadMessagesFilter(opts)).reduce(addUnread, 0),
  )

const selectors = {
  all: sliceSelectors.selectAll,
  byChannelAndUserIdSelector,
  byChannelIdSelector,
  byId: selectById,
  byIdSelector,
  entities: sliceSelectors.selectEntities,
  unreadMessagesCountSelector,
}

export { actions, selectors, buildId }
export default reducer
