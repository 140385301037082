import React from 'react'
import { ModalName, ModalStackItem, RegisteredModals } from '@/contexts/ModalPresenterContext/types'
import ArchivedChannelsModal from '../ArchivedChannelsModal'
import ArchivedHubsModal from '../ArchivedHubsModal'
import CreateChannelModal from '../CreateChannelModal'
import CreateSummaryModal from '../CreateSummaryModal'
import EditChannelModal from '../EditChannelModal'
import EditMessageModal from '../EditMessageModal'
import EditUserModal from '../EditUserModal'
import EmojiPickerModal from '../EmojiPickerModal'
import GiphyPickerModal from '../GiphyPickerModal'
import InviteUserModal from '../InviteUserModal'
import MakePaymentModal from '../MakePaymentModal'
import MessageActionsModal from '../MessageActionsModal'
import MessageThreadModal from '../MessageThreadModal'
import PlanModal from '../PlanModal'
import SelectTasksModal from '../SelectTasksModal'
import ShareSummaryModal from '../ShareSummaryModal'
import SpaceSelectorModal from '../SpaceSelectorModal'
import SummaryModal from '../SummaryModal'
import TaskModal from '../TaskModal'
import UpgradeAccountModal from '../UpgradeAccountModal'

interface RegisteredModalProps<T extends ModalName> extends ModalStackItem<T> {
  onPop: () => void
}

function RegisteredModal<T extends ModalName>({ name, params, onPop }: RegisteredModalProps<T>) {
  if (name === 'createChannel') {
    const typedParams = params as RegisteredModals['createChannel']
    return <CreateChannelModal {...typedParams} onClose={onPop} />
  }

  if (name === 'createSummary') {
    const typedParams = params as RegisteredModals['createSummary']
    return <CreateSummaryModal {...typedParams} onClose={onPop} />
  }

  if (name === 'editChannel') {
    const typedParams = params as RegisteredModals['editChannel']
    return <EditChannelModal {...typedParams} onClose={onPop} />
  }

  if (name === 'task') {
    const typedParams = params as RegisteredModals['task']
    return <TaskModal {...typedParams} onClose={onPop} />
  }

  if (name === 'openThread') {
    const typedParams = params as RegisteredModals['openThread']
    return <MessageThreadModal {...typedParams} onClose={onPop} />
  }

  if (name === 'selectTasks') {
    const typedParams = params as RegisteredModals['selectTasks']
    return <SelectTasksModal {...typedParams} onClose={onPop} />
  }

  if (name === 'shareSummary') {
    const typedParams = params as RegisteredModals['shareSummary']
    return <ShareSummaryModal {...typedParams} onClose={onPop} />
  }

  if (name === 'archivedHubs') {
    const typedParams = params as RegisteredModals['archivedHubs']
    return <ArchivedHubsModal {...typedParams} onClose={onPop} />
  }

  if (name === 'archivedChannels') {
    const typedParams = params as RegisteredModals['archivedChannels']
    return <ArchivedChannelsModal {...typedParams} onClose={onPop} />
  }

  if (name === 'summary') {
    const typedParams = params as RegisteredModals['summary']
    return <SummaryModal {...typedParams} onClose={onPop} />
  }

  if (name === 'emojiPicker') {
    const typedParams = params as RegisteredModals['emojiPicker']
    return <EmojiPickerModal {...typedParams} onClose={onPop} />
  }

  if (name === 'messageActions') {
    const typedParams = params as RegisteredModals['messageActions']
    return <MessageActionsModal {...typedParams} onClose={onPop} />
  }

  if (name === 'editMessage') {
    const typedParams = params as RegisteredModals['editMessage']
    return <EditMessageModal {...typedParams} onClose={onPop} />
  }

  if (name === 'giphyPicker') {
    const typedParams = params as RegisteredModals['giphyPicker']
    return <GiphyPickerModal {...typedParams} onClose={onPop} />
  }

  if (name === 'inviteUser') {
    const typedParams = params as RegisteredModals['inviteUser']
    return <InviteUserModal {...typedParams} onClose={onPop} />
  }

  if (name === 'upgradeAccount') {
    const typedParams = params as RegisteredModals['upgradeAccount']
    return <UpgradeAccountModal {...typedParams} onClose={onPop} />
  }

  if (name === 'makePayment') {
    const typedParams = params as RegisteredModals['makePayment']
    return <MakePaymentModal {...typedParams} onClose={onPop} />
  }

  if (name === 'selectPlan') {
    const typedParams = params as RegisteredModals['selectPlan']
    return <PlanModal {...typedParams} onClose={onPop} />
  }

  if (name === 'selectSpace') {
    const typedParams = params as RegisteredModals['selectSpace']
    return <SpaceSelectorModal {...typedParams} onClose={onPop} />
  }

  if (name === 'editUser') {
    const typedParams = params as RegisteredModals['editUser']
    return <EditUserModal {...typedParams} onClose={onPop} />
  }

  throw new Error(`Invalid registered modal: ${name}`)
}

export default React.memo(RegisteredModal)
