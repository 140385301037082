import React from 'react'
import Box from '@/components/layouts/Box'
import HubModeSelectorOption from './HubModeSelectorOption'
import { CreateHubMode } from './types'

interface HubModeSelectorContentProps {
  options: CreateHubMode[]
  onSelect: (mode: CreateHubMode) => void
  value: CreateHubMode | null
}

function HubModeSelectorContent({ options, onSelect, value }: HubModeSelectorContentProps) {
  if (options.length === 0) {
    throw new Error('No options given')
  }

  return (
    <Box alignItems="stretch" gapBottom="large">
      {options.map(option => (
        <HubModeSelectorOption
          key={option}
          isSelected={value === option}
          onSelect={onSelect}
          option={option}
        />
      ))}
    </Box>
  )
}

export default React.memo(HubModeSelectorContent)
