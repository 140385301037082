import rawEmojiGroups from 'assets/emoji.json'
import React from 'react'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { ManualPromise } from '@/core/ManualPromise'
import { collectionUtils as C } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { formActions } from '@/store/actions'
import { Emoji, EmojiGroup, RawEmojiGroup } from '@/types/emoji'
import { useTimeout } from './timeout'

const buildEmojiSections = (rowWidth: number, searchText: string): EmojiGroup[] =>
  (rawEmojiGroups as RawEmojiGroup[]).map(
    ([key, name, rawEmoji]) =>
      ({
        data: C.chunk(
          rowWidth,
          rawEmoji
            .filter(([, description]) => !searchText || description.includes(searchText))
            .map(([symbol, description]) => ({ description, symbol } as Emoji)),
        ),
        key,
        name,
      } as EmojiGroup),
  )

export const useFilteredEmojiGroups = (rowWidth: number, searchText: string) => {
  // TODO: web version with workers?
  const [groups, setGroups] = React.useState<EmojiGroup[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const { timeout } = useTimeout(300)

  const search = React.useCallback(() => {
    setGroups(
      buildEmojiSections(rowWidth, searchText.toLowerCase()).filter(
        section => section.data.length > 0,
      ),
    )
    setIsLoading(false)
  }, [searchText, rowWidth])

  React.useEffect(() => {
    setIsLoading(true)
    timeout(search)
  }, [timeout, search])

  return { groups, isLoading }
}

export const useEmojiPicker = () => {
  const modalPresenter = useModalPresenterContext()

  return React.useCallback(() => {
    const promise = new ManualPromise<Emoji | null>()

    modalPresenter.push('emojiPicker', {
      onPick: emoji => promise.resolve(emoji),
    })

    return promise
  }, [modalPresenter])
}

export const useAppendEmojiToFormField = (
  formName: string,
  fieldName: string,
  onPick?: () => void,
) => {
  const dispatch = useAppDispatch()
  return React.useCallback(
    (emoji: Emoji) => {
      dispatch(
        formActions.appendValue({
          fieldName,
          formName,
          value: emoji.symbol,
        }),
      )
      onPick?.()
    },
    [dispatch, formName, onPick, fieldName],
  )
}
