import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { toMaxString } from '@/core/utils/numbers'
import Text from './Text'

type Size = 'MEDIUM' | 'SMALL' | 'XSMALL' | 'XXSMALL'

export interface BadgeProps {
  count: number
  background?: ColorName
  foreground?: ColorName
  indeterminate?: boolean
  size?: Size
  outline?: ColorName
}

function Badge({
  count,
  background = 'emmre-blue',
  foreground = 'ghost',
  indeterminate,
  size = 'MEDIUM',
  outline,
}: BadgeProps) {
  if (count === 0) {
    return null
  }

  return (
    <View
      style={[
        styles.container,
        styleForSize[size],
        outline ? styles.outline : null,
        { backgroundColor: getColor(background), borderColor: getColor(outline) },
      ]}
    >
      {indeterminate ? null : (
        <Text color={foreground} lineHeight={sizes[size]} size={sizes[size]} weight="medium">
          {toMaxString(count, 9)}
        </Text>
      )}
    </View>
  )
}

const sizes: Record<Size, number> = {
  MEDIUM: 14,
  SMALL: 10,
  XSMALL: 8,
  XXSMALL: 4,
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerMedium: {
    borderRadius: 10,
    height: 20,
    width: 20,
  },
  containerSmall: {
    borderRadius: 8,
    height: 16,
    width: 16,
  },
  containerXSmall: {
    borderRadius: 6,
    height: 12,
    width: 12,
  },
  containerXXSmall: {
    borderRadius: 4,
    height: 8,
    width: 8,
  },
  outline: {
    borderWidth: 2,
  },
})

const styleForSize = {
  MEDIUM: styles.containerMedium,
  SMALL: styles.containerSmall,
  XSMALL: styles.containerXSmall,
  XXSMALL: styles.containerXXSmall,
} as const

export default React.memo(Badge)
