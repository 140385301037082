import React from 'react'
import { amplitudeProxy, sentryProxy } from '@/core/instrumentation'
import { dateUtils as D } from '@/core/utils'
import { useRTMAuthenticated } from '@/hooks/rtm'
import { authSelectors, spaceSelectors } from '@/store/selectors'
import { appThunks, fileThunks, initializerThunks } from '@/thunks'
import { useNativeAppForegrounded } from './appState'
import { useCouldBeSignedIn, useCurrentUser } from './auth'
import { useAppDispatch, useAppSelector } from './redux'
import { useThrottleLock } from './sync'

type AppInitializationStatus =
  | 'BOOTING'
  | 'UNAUTHENTICATED'
  | 'INITIALIZING'
  | 'NO_SPACES'
  | 'READY'

export const useAppInitializationStatus = (): AppInitializationStatus => {
  const isBooting = useBootApplication()
  const isAuthenticated = useCouldBeSignedIn()
  useRefreshCachedData()
  useInitializeUserData()

  const isInitialized = useAppSelector(authSelectors.isInitialized)
  const spacesCount = useAppSelector(spaceSelectors.spacesCount)

  if (isBooting) {
    return 'BOOTING'
  }
  if (!isAuthenticated) {
    return 'UNAUTHENTICATED'
  }
  if (!isInitialized) {
    return 'INITIALIZING'
  }
  if (spacesCount === 0) {
    return 'NO_SPACES'
  }
  return 'READY'
}

// The app will show a splash screen until booting is set to false, so this should only contain
// the absolute bare minimum things to wait on before setting isBooting to false
const useBootApplication = () => {
  const dispatch = useAppDispatch()
  const [isBooting, setIsBooting] = React.useState(true)

  useNativeAppForegrounded(fileThunks.clearCachedFiles)

  React.useEffect(() => {
    dispatch(appThunks.initializeApplication()).then(() => setIsBooting(false))
    fileThunks.clearCachedFiles()
  }, [dispatch])

  return isBooting
}

// For setup and teardown of any user-specific data
const useInitializeUserData = () => {
  const currentUser = useCurrentUser()

  React.useEffect(() => {
    if (currentUser) {
      sentryProxy.setUser(currentUser)
      amplitudeProxy.setUser(currentUser)
    } else {
      sentryProxy.clearUser()
      amplitudeProxy.clearUser()
    }
  }, [currentUser])
}

// Queue data refresh jobs on certain events.
const useRefreshCachedData = () => {
  const refreshSpaces = useRefreshSpaces(15, D.toSeconds.minutes(5))
  const [isRefreshing, setIsRefreshing] = React.useState(false)
  const isSignedIn = useAppSelector(authSelectors.couldBeSignedIn)

  const refreshIfSignedIn = React.useCallback(() => {
    if (isSignedIn) {
      try {
        setIsRefreshing(true)
        refreshSpaces()
      } finally {
        setIsRefreshing(false)
      }
    }
  }, [isSignedIn, refreshSpaces])

  useNativeAppForegrounded(refreshIfSignedIn)

  // TODO: on sign in the RTM will be connected and this will trigger a refresh, which we do
  //       not want. Not sure how to handle that.
  useRTMAuthenticated(refreshIfSignedIn)

  return { isRefreshing }
}

const useRefreshSpaces = (partialRefreshThrottle: number, fullRefreshThrottle: number) => {
  const dispatch = useAppDispatch()

  const refreshData = React.useCallback(
    async (secondsSinceLastRun: number) => {
      const excludeDeferrable = secondsSinceLastRun <= fullRefreshThrottle
      await dispatch(initializerThunks.queueRefreshJobs(excludeDeferrable))
    },
    [dispatch, fullRefreshThrottle],
  )

  return useThrottleLock(refreshData, partialRefreshThrottle)
}
