import React from 'react'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { Summary } from '@/types/entities'
import ShareSummaryContent from './ShareSummaryContent'
import { useShareSummaryModal } from './hooks'

interface ShareSummaryModalProps {
  summary: Summary
  onClose: () => void
}

function ShareSummaryModal({ summary, onClose }: ShareSummaryModalProps) {
  const { status, handleCreateSummaryShare, ...contentProps } = useShareSummaryModal(summary)

  return (
    <ModalLayout
      leftAction={
        status === 'SUCCESS'
          ? undefined
          : {
              label: 'Cancel',
              onAction: onClose,
              type: 'linkPrimary',
            }
      }
      maxWidth={550}
      onCancel={onClose}
      rightAction={
        status === 'SUCCESS' ? { label: 'Done', onAction: onClose, type: 'linkPrimary' } : undefined
      }
      title={`Share ${summary.title}`}
    >
      <Box gapAll="medium">
        <ShareSummaryContent
          onCreateSummaryShare={handleCreateSummaryShare}
          status={status}
          summary={summary}
          {...contentProps}
        />
      </Box>
    </ModalLayout>
  )
}

export default React.memo(ShareSummaryModal)
