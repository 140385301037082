import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface BaseContentProps {
  icon: React.ReactNode
  title: string
  subtitle: string
}

function BaseContent({ icon, title, subtitle }: BaseContentProps) {
  return (
    <Box alignItems="center" direction="row">
      <Box direction="row" justifyContent="flex-start" width={60}>
        {icon}
      </Box>
      <Box direction="column">
        <Text size="h4" weight="medium">
          {title}
        </Text>
        <Box gapTop="xsmall">
          <Text color="anchor" size="h5">
            {subtitle}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(BaseContent)
