import React from 'react'
import { ScrollView } from 'react-native'
import Box from '@/components/layouts/Box'
import { getPlanName } from '@/core/utils/chargebee'
import { noOp } from '@/core/utils/fns'
import { useAppDispatch } from '@/hooks'
import { useIsWide } from '@/hooks/appState'
import { notificationActions } from '@/store/actions'
import { ProductVersion, Space } from '@/types/entities'
import { AssistantProDescription, PartnershipDescription } from './Descriptions'
import EnterPayment from './EnterPayment'
import ProductCard from './ProductCard'
import WithEstimates, { ChargebeeEstimatesMap } from './WithEstimates'
import { usePay, useRemoveUsers } from './hooks'

interface SelectPlanBodyProps {
  space: Space
  onClose: () => void
  onManageUsers: (spaceId: number) => void
}

function SelectPlanBody({ space, onClose, onManageUsers }: SelectPlanBodyProps) {
  const { id: spaceId, productVersion: currentProductVersion } = space
  const dispatch = useAppDispatch()
  const { makePayment, isPending } = usePay(spaceId)
  const [showPayment, setShowPayment] = React.useState(false)
  const setPaymentShown = React.useCallback(() => setShowPayment(true), [])

  const checkCanSelect = useRemoveUsers(
    spaceId,
    noOp,
    React.useCallback(() => {
      onClose()
      onManageUsers(spaceId)
    }, [onClose, spaceId, onManageUsers]),
  )

  const handleSelectPlan = React.useCallback(
    async (productVersion: ProductVersion) => {
      if (!(await checkCanSelect(productVersion))) {
        return
      }

      const ok = await makePayment(productVersion, null)

      if (ok) {
        const planName = getPlanName(productVersion)
        dispatch(
          notificationActions.push({
            body: `Your plan has been updated to ${planName}.`,
            title: `Plan switched to ${planName}!`,
            type: 'success',
          }),
        )
        onClose()
      }
    },
    [makePayment, dispatch, onClose, checkCanSelect],
  )

  const handlePay = React.useCallback(
    async (paymentIntentId: string) => {
      const ok = await makePayment(currentProductVersion, paymentIntentId)

      if (ok) {
        dispatch(
          notificationActions.push({
            body: 'Welcome to the Emmre team. We are honored you signed up.',
            title: 'Thank you for your purchase!',
            type: 'success',
          }),
        )
        onClose()
      }
    },
    [makePayment, dispatch, onClose, currentProductVersion],
  )

  const isSmall = !useIsWide()
  const content = (estimates: ChargebeeEstimatesMap) => (
    <Box
      alignItems={isSmall ? 'center' : 'stretch'}
      direction={isSmall ? 'column' : 'row'}
      minHeight={300}
    >
      <Box alignItems="stretch" direction="row" flex={1} gapRight={isSmall ? undefined : 'medium'}>
        <ProductCard
          color="emmre-blue"
          description={<AssistantProDescription />}
          disabled={isPending}
          estimates={estimates}
          onAddPayment={setPaymentShown}
          onSelect={handleSelectPlan}
          productVersion="ASSISTANT_PRO"
          space={space}
          supportsMultipleUsers={false}
        />
      </Box>
      <Box
        alignItems="stretch"
        direction="row"
        flex={1}
        gapLeft={isSmall ? undefined : 'medium'}
        gapTop={isSmall ? 'medium' : undefined}
      >
        <ProductCard
          color="indigo"
          description={<PartnershipDescription />}
          disabled={isPending}
          estimates={estimates}
          onAddPayment={setPaymentShown}
          onSelect={handleSelectPlan}
          productVersion="PARTNERSHIP"
          space={space}
          supportsMultipleUsers
        />
      </Box>
    </Box>
  )

  return (
    <>
      <WithEstimates spaceId={spaceId}>
        {estimates =>
          isSmall ? (
            <ScrollView keyboardShouldPersistTaps="handled">{content(estimates)}</ScrollView>
          ) : (
            content(estimates)
          )
        }
      </WithEstimates>
      {showPayment ? (
        <Box gapTop="xlarge">
          <EnterPayment isPending={isPending} onSelect={handlePay} spaceId={spaceId} />
        </Box>
      ) : null}
    </>
  )
}

export default React.memo(SelectPlanBody)
