import React from 'react'
import FullScreenMediaContext, {
  FullScreenMediaContextType,
  FullScreenMediaProps,
} from '@/contexts/FullScreenMediaContext'
import FullScreenMediaModal from './FullScreenMediaModal'

interface FullScreenMediaContainerProps {
  children: React.ReactNode
}

function FullScreenMediaContainer({ children }: FullScreenMediaContainerProps) {
  const [imageProps, setImageProps] = React.useState<FullScreenMediaProps | null>(null)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen])
  const showMedia = React.useCallback<FullScreenMediaContextType>(
    (props: FullScreenMediaProps) => {
      setImageProps(props)
      setIsOpen(true)
    },
    [setImageProps, setIsOpen],
  )

  return (
    <FullScreenMediaContext.Provider value={showMedia}>
      {children}
      {imageProps ? (
        <FullScreenMediaModal {...imageProps} isOpen={isOpen} onClose={handleClose} />
      ) : null}
    </FullScreenMediaContext.Provider>
  )
}

export default React.memo(FullScreenMediaContainer)
