import { ResizeMode } from 'expo-av'
import React from 'react'
import { StyleSheet } from 'react-native'
import ActionableVideo from '@/components/media/ActionableVideo'
import { Measurement } from '@/types/components'
import { File, Message } from '@/types/entities'
import FileListItemError from './FileListItemError'

interface VideoFileListItemProps {
  file: File
  message?: Message
  size: Measurement
  playVideoInline: boolean
}

function VideoFileListItem({ message, file, size, playVideoInline }: VideoFileListItemProps) {
  return (
    <ActionableVideo
      ErrorComponent={<FileListItemError label="video" />}
      file={file}
      message={message}
      playVideoInline={playVideoInline}
      resizeMode={ResizeMode.CONTAIN}
      size={size}
      style={styles.video}
      uri={file.url}
    />
  )
}

const styles = StyleSheet.create({
  video: {
    borderRadius: 5,
  },
})

export default React.memo(VideoFileListItem)
