import React from 'react'
import { batch } from 'react-redux'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormField } from '@/components/tasks/TaskForm/hooks'
import { dateUtils as D, offsetUtils as O } from '@/core/utils'
import { ReminderOffset, TaskDueDateType } from '@/types/entities'
import { getNextDate } from './utils'

interface DueDateState {
  dueDateType: TaskDueDateType
  dueDate: Date | null
  remindAt: Date | null
  remindAtOffset: ReminderOffset | null
  recurrenceRule: string | null
}

type DueDateAction =
  | {
      type: 'setDueDate'
      payload: { dueDate: Date | null; dueDateType?: TaskDueDateType | null }
    }
  | { type: 'setRemindAtOffset'; payload: { remindAtOffset: ReminderOffset | null } }
  | { type: 'setRecurrenceRule'; payload: { recurrenceRule: string | null } }
  | { type: 'initialize'; payload: DueDateState }

const dueDateReducer = (state: DueDateState, action: DueDateAction): DueDateState => {
  const { type } = action

  if (type === 'initialize') {
    return action.payload
  }

  if (type === 'setDueDate') {
    const { dueDate, dueDateType } = action.payload

    if (dueDate) {
      return {
        ...state,
        dueDate: getNextDate(state.dueDate, dueDate),
        dueDateType: 'DATE',
        remindAt: state.remindAtOffset ? O.applyOffset(state.remindAtOffset, dueDate) : null,
      }
    }

    return {
      ...state,
      dueDate: null,
      dueDateType: dueDateType || 'NONE',
      recurrenceRule: null,
      remindAt: null,
      remindAtOffset: null,
    }
  }

  if (type === 'setRemindAtOffset') {
    const { remindAtOffset } = action.payload

    if (!state.dueDate) {
      return {
        ...state,
        remindAt: null,
        remindAtOffset: null,
      }
    }

    const nextOffset = remindAtOffset
      ? O.mergeOffsets(state.remindAtOffset || {}, remindAtOffset)
      : null

    return {
      ...state,
      remindAt: nextOffset ? O.applyOffset(nextOffset, state.dueDate) : null,
      remindAtOffset: nextOffset,
    }
  }

  if (type === 'setRecurrenceRule') {
    const { recurrenceRule } = action.payload

    return {
      ...state,
      recurrenceRule,
    }
  }

  return state
}

export const useDateSelector = (onClose: (cancelled: boolean) => void, isOpen: boolean) => {
  const { value: dueDateType, setValue: setDueDateType } = useTaskFormField<TaskDueDateType>(
    fieldNames.dueDateType,
  )
  const { value: dueDateString, setValue: setDueDateString } = useTaskFormField(fieldNames.dueDate)
  const { value: remindAtString, setValue: setRemindAtString } = useTaskFormField(
    fieldNames.remindAt,
  )
  const { value: remindAtOffset, setValue: setRemindAtOffset } =
    useTaskFormField<ReminderOffset | null>(fieldNames.remindAtOffset)
  const { value: recurrenceRule, setValue: setRecurrenceRule } = useTaskFormField(
    fieldNames.recurrenceRule,
  )

  const initialState = React.useMemo<DueDateState>(
    () => ({
      dueDate: D.tryParse(dueDateString),
      dueDateType,
      recurrenceRule,
      remindAt: D.tryParse(remindAtString),
      remindAtOffset,
    }),
    [dueDateString, dueDateType, remindAtString, remindAtOffset, recurrenceRule],
  )

  const [state, dispatch] = React.useReducer(dueDateReducer, initialState)

  const handleSelectedDateChange = React.useCallback(
    (newDate: Date | null, newType?: TaskDueDateType) =>
      dispatch({ payload: { dueDate: newDate, dueDateType: newType }, type: 'setDueDate' }),
    [dispatch],
  )

  const handleRemindAtChange = React.useCallback(
    (offset: ReminderOffset | null) =>
      dispatch({
        payload: { remindAtOffset: offset },
        type: 'setRemindAtOffset',
      }),
    [dispatch],
  )

  const handleRecurrenceRuleChange = React.useCallback(
    (newRecurrenceRule: string | null) =>
      dispatch({
        payload: { recurrenceRule: newRecurrenceRule },
        type: 'setRecurrenceRule',
      }),
    [dispatch],
  )

  const handleRemoveDueDate = React.useCallback(
    () => dispatch({ payload: { dueDate: null }, type: 'setDueDate' }),
    [dispatch],
  )

  const handleCancel = React.useCallback(() => {
    onClose(true)
  }, [onClose])

  const handleDone = React.useCallback(() => {
    batch(() => {
      setDueDateType(state.dueDateType)
      setDueDateString(state.dueDate ? D.formatISO(state.dueDate) : null)
      setRemindAtString(state.remindAt ? D.formatISO(state.remindAt) : null)
      setRemindAtOffset(state.remindAtOffset)
      setRecurrenceRule(state.recurrenceRule)
    })
    onClose(false)
  }, [
    setDueDateType,
    setDueDateString,
    setRemindAtString,
    setRemindAtOffset,
    setRecurrenceRule,
    onClose,
    state,
  ])

  React.useEffect(() => {
    if (!isOpen) {
      dispatch({ payload: initialState, type: 'initialize' })
    }
  }, [dispatch, isOpen, initialState])

  return {
    dueDateType: state.dueDateType,
    handleCancel,
    handleDone,
    handleRecurrenceRuleChange,
    handleRemindAtChange,
    handleRemoveDueDate,
    handleSelectedDateChange,
    recurrenceRule: state.recurrenceRule,
    selectedDate: state.dueDate,
    selectedRemindAt: state.remindAt,
    selectedRemindAtOffset: state.remindAtOffset,
  }
}
