import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { useCachedSelector } from '@/hooks'
import { entitySelectors } from '@/store/selectors'
import { useTaskFormField } from '../hooks'
import MyDayIcon from './MyDayIcon'
import { getMyDayStatus, getMyDayStatusLabel } from './utils'

interface TaskMyDayToggleButtonProps {
  showLabel?: boolean
}

function TaskMyDayToggleButton({ showLabel = true }: TaskMyDayToggleButtonProps) {
  const { value: isMyDay, setValue: setIsMyDay } = useTaskFormField<boolean>('isMyDay')
  const handleChange = React.useCallback(() => setIsMyDay(!isMyDay), [setIsMyDay, isMyDay])
  const { task } = useTaskFormContext()
  const isIgnored = useCachedSelector(entitySelectors.isIgnoredInMyDay, [task])
  const status = getMyDayStatus(isMyDay, isIgnored)

  let content: React.ReactElement

  if (!showLabel) {
    content = <MyDayIcon status={status} />
  } else {
    content = (
      <Box alignItems="center" direction="row">
        <MyDayIcon status={status} />
        <Box direction="column" gapLeft="small">
          <Text color="anchor" size="h6">
            {getMyDayStatusLabel(status)}
          </Text>
          <Text size="h5">My Day</Text>
        </Box>
      </Box>
    )
  }

  return (
    <Touchable feedback="opacity" onPress={handleChange}>
      {content}
    </Touchable>
  )
}

export default React.memo(TaskMyDayToggleButton)
