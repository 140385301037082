import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { SummaryItem } from '@/types/entities'
import { OrderingBounds } from '@/types/ordering'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, summaryItemId: number): string => `${spaceId}:${summaryItemId}`

const adapter = createEntityAdapter<SummaryItem>({
  selectId: summaryItem => buildId(summaryItem.spaceId, summaryItem.id),
  sortComparer: (a, b) => a.orderingValue - b.orderingValue,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/summaryItems',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    setOne: adapter.setOne,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.summaryItems)

const selectById = (state: RootState, spaceId: number, summaryItemId: number) =>
  state.entities.summaryItems.entities[buildId(spaceId, summaryItemId)]

const bySummaryIdSelector = (spaceId: number, summaryId: number) =>
  createSelector(sliceSelectors.selectAll, summaryItems =>
    summaryItems.filter(
      summaryItem => summaryItem.spaceId === spaceId && summaryItem.summaryId === summaryId,
    ),
  )

const byIndicesSelector = (spaceId: number, summaryId: number, indices: number[]) =>
  createSelector(bySummaryIdSelector(spaceId, summaryId), items =>
    Object.fromEntries(indices.map(index => [index, items[index]])),
  )

const byIndexSelector = (spaceId: number, summaryId: number, index: number) =>
  createSelector(bySummaryIdSelector(spaceId, summaryId), items => items[index])

const bySectionSelector = (spaceId: number, summaryId: number, headerId: number) =>
  createSelector(bySummaryIdSelector(spaceId, summaryId), items => {
    // Every item after a header, until the next header, is considered part of the section of
    // that header.

    let startIndex = -1
    let stopIndex = -1

    items.forEach((item, index) => {
      if (stopIndex !== -1) {
        return
      }

      if (item.id === headerId) {
        startIndex = index
      } else if (startIndex !== -1 && item.itemType === 'HEADER') {
        stopIndex = index
      }
    })

    if (stopIndex === -1) {
      stopIndex = items.length
    }

    if (startIndex !== -1 && stopIndex !== -1) {
      return items.slice(startIndex, stopIndex)
    }

    return []
  })

const boundingOrderingValuesSelector = (
  spaceId: number,
  summaryId: number,
  beforeItemId: number | null,
) =>
  createSelector(bySummaryIdSelector(spaceId, summaryId), (items): OrderingBounds => {
    // When an item is moved it is placed before another item, (or at the end if beforeItemId is
    // null). This method will return back a tuple with the ordering values for the new placement

    if (beforeItemId === null) {
      // If beforeItemId is not given then the new position is the end of the list
      const lastItem = items[items.length - 1]
      return [lastItem.orderingValue, null]
    }

    const itemIndex = items.findIndex(item => item.id === beforeItemId)

    if (itemIndex === -1) {
      // If the item wasn't found (this shouldn't happen) then just stick things at the end of
      // the list
      const lastItem = items[items.length - 1]
      return [lastItem.orderingValue, null]
    }

    const upperItem = items[itemIndex]

    if (itemIndex === 0) {
      // If the item is at the beginning of the list, the lower value is unbounded
      return [null, upperItem.orderingValue]
    }

    const lowerItem = items[itemIndex - 1]

    return [lowerItem.orderingValue, upperItem.orderingValue]
  })

const selectors = {
  boundingOrderingValuesSelector,
  byId: selectById,
  byIndexSelector,
  byIndicesSelector,
  bySectionSelector,
  bySummaryIdSelector,
}

export { actions, selectors, buildId }
export default reducer
