import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import { SpacesScreenProps } from './types'

const LazySpacesScreen = React.lazy(() => import('./index'))

function SpacesScreen(props: SpacesScreenProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazySpacesScreen {...props} />
    </Suspense>
  )
}

export default SpacesScreen
