import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Icon from '@/components/core/Icon'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import SummaryTaskItem from '@/components/summaries/SummaryTaskItem'
import { SummaryItem } from '@/types/entities'
import CanAcknowledgeToggleButton from './CanAcknowledgeToggleButton'
import CanCommentToggleButton from './CanCommentToggleButton'
import { useViewSummaryItem } from './hooks'

interface ViewItemProps {
  summaryItem: SummaryItem
}

function ViewItem({ summaryItem }: ViewItemProps) {
  const { content, canAcknowledge, canComment, spaceId, taskId } = summaryItem
  const { handleDelete } = useViewSummaryItem(summaryItem)

  return (
    <Box direction="row">
      <Background borderColor="koala" borderRadius={8} borderWidth={1} color="silver" flex={1}>
        <Box gapAll="medium">
          <Box alignItems="flex-start" direction="row">
            {content ? <Icon name="align-left" /> : null}
            <Box flex={1} gapHorizontal="medium">
              {content ? <TextWithLinks size="h4">{content}</TextWithLinks> : null}
            </Box>
          </Box>
          {taskId ? (
            <Box gapTop="medium">
              <SummaryTaskItem editing spaceId={spaceId} taskId={taskId} />
            </Box>
          ) : null}
          {canAcknowledge || canComment ? (
            <Box gapTop="medium">
              {canComment ? (
                <Box gapTop={canAcknowledge ? 'small' : 'none'}>
                  <CanCommentToggleButton canComment={canComment} disabled />
                </Box>
              ) : null}
              {canAcknowledge ? (
                <CanAcknowledgeToggleButton canAcknowledge={canAcknowledge} />
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Background>
      <Box gapLeft="medium">
        <BaseIconButton
          borderColor="koala"
          borderRadius={8}
          borderStyle="solid"
          borderWidth={1}
          color="koala"
          name="trash-alt"
          onPress={handleDelete}
          padding={12}
          shape="square"
        />
      </Box>
    </Box>
  )
}

export default React.memo(ViewItem)
