import { DrawerContentComponentProps } from '@react-navigation/drawer'
import React from 'react'
import { useFocusedSpaceContext } from '@/contexts/FocusedSpaceContext'
import { useAppDispatch } from '@/hooks'
import { authThunks } from '@/thunks'
import { Space } from '@/types/entities'

export const useDrawerNavigation = ({ navigation }: DrawerContentComponentProps) => {
  const dispatch = useAppDispatch()
  const spaceId = useFocusedSpaceContext()
  // TODO: Don't have good typing on navigation route
  const handleSpaceSelectionChange = React.useCallback(
    (space: Space) => navigation.navigate('spaces', { screen: 'main', spaceId: space.id }),
    [navigation],
  )
  const handleNavigateProfile = React.useCallback(
    () => navigation.navigate('account-management', { screen: 'profile', spaceId }),
    [navigation, spaceId],
  )
  const handleSignOut = React.useCallback(() => {
    navigation.closeDrawer()
    dispatch(authThunks.signOut(null))
    navigation?.navigate('auth', {
      params: { message: 'You have successfully Signed Out' },
      screen: 'sign-in',
    })
  }, [navigation, dispatch])
  const handleNavigateSpace = React.useCallback(
    (space: Space) =>
      navigation.navigate('account-management', { screen: 'index', spaceId: space.id }),
    [navigation],
  )
  const handleCreateSpace = React.useCallback(
    () => navigation.navigate('create-space'),
    [navigation],
  )
  const handleShowHelp = React.useCallback(
    () => navigation.navigate('account-management', { screen: 'help', spaceId }),
    [navigation, spaceId],
  )

  return {
    handleCreateSpace,
    handleNavigateProfile,
    handleNavigateSpace,
    handleShowHelp,
    handleSignOut,
    handleSpaceSelectionChange,
  }
}
