import React from 'react'
import Button from '@/components/core/Button'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import { useAppDispatch } from '@/hooks'
import { userThunks } from '@/thunks'
import { SpaceUser } from '@/types/entities'

interface EditUserFormProps {
  user: SpaceUser
  onDone: (userId: number) => void
}

function EditUserForm({ user, onDone }: EditUserFormProps) {
  const { id: userId } = user
  const [firstName, setFirstName] = React.useState(user.firstName)
  const [lastName, setLastName] = React.useState(user.lastName || '')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const dispatch = useAppDispatch()
  const canSubmit = !!(firstName.trim() && lastName.trim())
  const handleSave = React.useCallback(async () => {
    if (!canSubmit) {
      return
    }
    setIsSubmitting(true)
    const response = await dispatch(userThunks.editProfile(userId, { firstName, lastName }))

    if (response.ok) {
      onDone(userId)
    } else {
      setIsSubmitting(false)
    }
  }, [firstName, lastName, userId, onDone, canSubmit, dispatch])

  return (
    <Box>
      <Box direction="row">
        <Box flex={1} gapRight="small">
          <TextInput label="First Name" onChangeText={setFirstName} value={firstName} />
        </Box>
        <Box flex={1} gapLeft="small">
          <TextInput label="Last Name" onChangeText={setLastName} value={lastName} />
        </Box>
      </Box>
      <Box gapTop="large">
        <Button
          disabled={isSubmitting || !canSubmit}
          kind="primary"
          label="Save"
          onPress={handleSave}
        />
      </Box>
    </Box>
  )
}

export default React.memo(EditUserForm)
