import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { userUtils } from '@/core/utils'
import { useAppSelector, useCachedSelector } from '@/hooks'
import { channelSelectors, userSelectors } from '@/store/selectors'
import { fieldNames } from './constants'
import { useTaskFormField, useTaskFormValue } from './hooks'

function TaskAssignee() {
  const spaceId: number = useTaskFormValue(fieldNames.spaceId)
  const channelId: number = useTaskFormValue(fieldNames.channelId) || 0
  const { value: assignedUserId, setValue: setAssignedUserId } = useTaskFormField<number | null>(
    fieldNames.assignedUserId,
  )
  const assignedUser = useAppSelector(userSelectors.byId, spaceId, assignedUserId || 0)
  const channel = useCachedSelector(channelSelectors.byIdSelector, [spaceId, channelId])
  const { showDetail } = useTaskFormContext()
  const canChange = !!channel && channel.userIds.length > 1
  const handleSelect = React.useCallback(() => {
    if (!channel) {
      showDetail('ASSIGNED_USER')
    } else if (channel.userIds.length <= 1) {
      // pass
    } else if (channel.userIds.length === 2) {
      const [first, second] = channel.userIds
      if (assignedUserId === first) {
        setAssignedUserId(second)
      } else {
        setAssignedUserId(first)
      }
    } else {
      showDetail('ASSIGNED_USER')
    }
  }, [channel, assignedUserId, setAssignedUserId, showDetail])

  return (
    <Touchable feedback="opacity" onPress={canChange ? handleSelect : undefined}>
      <Box alignItems="center" direction="row" gapRight="small">
        <UserProfilePicture size={38} user={assignedUser} />
        <Box direction="column" flex={1} gapLeft="medium">
          <Text color="anchor" size="h6">
            Assigned To
          </Text>
          <Text ellipsizeMode="tail" numberOfLines={1} size="h5">
            {assignedUser ? userUtils.getFullName(assignedUser) : 'Unassigned'}
          </Text>
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskAssignee)
