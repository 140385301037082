import React from 'react'
import { Animated, Modal, StyleSheet } from 'react-native'
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context'
import StackContextContainer from '@/components/screens/StackContextContainer'
import { ModalStack } from '@/contexts/ModalPresenterContext/types'
import RegisteredModal from './RegisteredModal'

interface ModalPresenterProps {
  modals: ModalStack
  onPop: () => void
}

function ModalPresenter({ modals, onPop }: ModalPresenterProps) {
  const lastModalsRef = React.useRef<ModalStack>([])

  const [showBackdrop, setShowBackdrop] = React.useState(false) // eslint-disable-line @typescript-eslint/no-unused-vars
  const opacityAnimationRef = React.useRef(new Animated.Value(0))

  lastModalsRef.current = []

  const rootModal = modals.reduceRight(
    (innerChild: React.ReactNode | undefined, modal) => (
      <Modal key={modal.name} animationType="slide" transparent visible>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <StackContextContainer>
            <RegisteredModal name={modal.name} onPop={onPop} params={modal.params} />
            {innerChild}
          </StackContextContainer>
        </SafeAreaProvider>
      </Modal>
    ),
    undefined,
  )

  const modalsLength = modals.length
  const backgroundColor = modals[0]?.bgColor || '#000'

  React.useEffect(() => {
    if (modalsLength > 1) {
      return
    }

    const open = modalsLength !== 0
    const toValue = open ? 0.5 : 0

    if (open) {
      setShowBackdrop(true)
    }

    Animated.timing(opacityAnimationRef.current, {
      duration: 300,
      toValue,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (!open && finished) {
        setShowBackdrop(false)
      }
    })
  }, [setShowBackdrop, modalsLength])

  const backdropOpacityStyle = {
    opacity: opacityAnimationRef.current,
  }

  if (showBackdrop) {
    return (
      <Animated.View style={[styles.backdrop, { backgroundColor }, backdropOpacityStyle]}>
        {rootModal}
      </Animated.View>
    )
  }

  return null
}

const styles = StyleSheet.create({
  backdrop: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default React.memo(ModalPresenter)
