import React from 'react'
import { useAlertContext } from '@/contexts/AlertContext'
import { actions } from '@/core/constants'
import { actionUtils, entityUtils, userUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { spaceThunks } from '@/thunks'
import { Space, SpaceUser } from '@/types/entities'

export const useRemoveUser = (space: Space, user: SpaceUser) => {
  const { id: spaceId } = space
  const dispatch = useAppDispatch()
  const showAlert = useAlertContext()
  const [isRemoving, setIsRemoving] = React.useState(false)
  const canRemove =
    user.membershipType !== 'INACTIVE' &&
    entityUtils.hasAction(actions.spaceActions.removeMemberFromSpace, space)
  const handleRemove = React.useCallback(async () => {
    if (!user) {
      return
    }

    const userName = userUtils.getFullName(user)
    const remove = await actionUtils.showDestructiveAlertAsync(
      showAlert,
      'Remove Access to Space',
      'Remove',
      `Are you sure you want to remove ${userName} from this space?`,
    )

    if (!remove) {
      return
    }

    setIsRemoving(true)
    dispatch(spaceThunks.removeUserFromSpace(spaceId, user.id))
    setIsRemoving(false)
  }, [dispatch, spaceId, showAlert, user])

  return {
    canRemove,
    handleRemove,
    isRemoving,
  }
}
