import React from 'react'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'

interface MessageAvatarProps {
  spaceId: number
  userId: number
  isFromMe: boolean
  showOtherAvatar: boolean
}

const spacerWidth = 40

function MessageAvatar({ spaceId, userId, isFromMe, showOtherAvatar }: MessageAvatarProps) {
  if (!showOtherAvatar) {
    return null
  }

  if (isFromMe) {
    return <Box width={spacerWidth} />
  }

  return (
    <Box direction="row" justifyContent="flex-start" width={spacerWidth}>
      <UserProfilePicture spaceId={spaceId} userId={userId} />
    </Box>
  )
}

export default React.memo(MessageAvatar)
