type PromiseHandler<T, R> = (value: T) => R | PromiseLike<R>

export class ManualPromise<T> implements PromiseLike<T> {
  handleResolve: ((value: T) => void) | undefined
  handleReject: ((value: T) => void) | undefined
  value: T | undefined
  status: 'RESOLVED' | 'REJECTED' | 'PENDING'

  constructor() {
    this.handleResolve = undefined
    this.handleReject = undefined
    this.value = undefined
    this.status = 'PENDING'
  }

  then<R, E>(onResolved: PromiseHandler<T, R>, onRejected: PromiseHandler<T, E>): PromiseLike<R | E>
  then<R>(onResolved: PromiseHandler<T, R>, onRejected?: undefined | null): PromiseLike<T | R>
  then<E>(onResolved: undefined | null, onRejected: PromiseHandler<T, E>): PromiseLike<T | E>
  then<R, E>(
    onResolved: PromiseHandler<T, R> | null | undefined,
    onRejected?: PromiseHandler<T, E> | null | undefined,
  ): PromiseLike<R | E> {
    // Only allow `.then` to be called once per instance
    if (this.handleResolve) {
      throw new Error('you cannot call then twice on a pending promise')
    }

    const nextPromise = new ManualPromise<R | E>()

    const handleResolve = (value: T) => {
      if (onResolved) {
        const result = onResolved(value)

        if (result && 'then' in result) {
          result.then((v: R) => nextPromise.resolve(v))
        } else {
          nextPromise.resolve(result)
        }
      } else {
        // If no onResolved is given we just forward to the next promise, is that right?
        nextPromise.resolve(value as unknown as R)
      }
    }

    const handleReject = (value: T) => {
      if (onRejected) {
        const result = onRejected(value)

        if (result && 'then' in result) {
          result.then((v: E) => nextPromise.reject(v))
        } else {
          nextPromise.reject(result)
        }
      } else {
        nextPromise.resolve(value as unknown as E)
      }
    }

    if (this.status === 'RESOLVED') {
      setTimeout(() => handleResolve(this.value as T))
    } else if (this.status === 'REJECTED') {
      setTimeout(() => handleReject(this.value as T))
    } else {
      this.handleResolve = handleResolve
      this.handleReject = handleReject
    }

    return nextPromise
  }

  catch(handleReject: any) {
    const nextPromise = new ManualPromise()

    this.handleReject = (value: any) => {
      const result = handleReject(value)

      if (result.then) {
        result.then((v: any) => nextPromise.reject(v))
      } else {
        nextPromise.reject(result)
      }
    }

    return nextPromise
  }

  resolve = (value: T) => {
    if (this.status !== 'PENDING') {
      throw new Error('promise cannot be resolved or rejected multiple times')
    }

    this.status = 'RESOLVED'
    this.value = value

    if (this.handleResolve) {
      this.handleResolve(value)
    }
  }
  reject = (value: T) => {
    if (this.status !== 'PENDING') {
      throw new Error('promise cannot be resolved or rejected multiple times')
    }

    this.status = 'REJECTED'
    this.value = value

    if (this.handleReject) {
      this.handleReject(value)
    }
  }
}
