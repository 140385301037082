import React from 'react'
import { FlatList, ListRenderItemInfo } from 'react-native'
import Box from '@/components/layouts/Box'
import { MessageListItemData } from '@/types/messaging'
import MessageActionsContainer from '../MessageActionsContainer'
import MessageListHeader from './MessageListHeader'
import MessageListItem from './MessageListItem'
import NewMessagesIndicator from './NewMessagesIndicator'
import { useMessagesList } from './hooks'
import { useFlatListScrollOnMessageSend, useFlatListViewableItemsChanged } from './hooks/flatList'
import { MessagesListProps } from './types'
import { defaultShowOtherAvatar } from './utils'

type MessageItem = ListRenderItemInfo<MessageListItemData>

const getMessageKey = (item: MessageListItemData) =>
  (item.message.clientTemporaryId || item.message.id).toString()

const viewabilityConfig = {
  itemVisiblePercentThreshold: 1,
  minimumViewTime: 300,
  waitForInteraction: false,
}

function MessagesList({
  channel,
  channelUser,
  threadId,
  showThreadStart = true,
  showOtherAvatar: overrideShowOtherAvatar,
  showIntro = false,
}: MessagesListProps) {
  const flatListRef = React.useRef<FlatList | null>(null)
  const hooks = useMessagesList({ channel, channelUser, showThreadStart, threadId })

  const handleViewableItemsChanged = useFlatListViewableItemsChanged(
    hooks.visibleItemsChanged,
    hooks.firstUnreadMessageId,
  )
  useFlatListScrollOnMessageSend(flatListRef)

  const isDisplayedInThread = !!threadId
  const showOtherAvatar =
    overrideShowOtherAvatar === undefined
      ? defaultShowOtherAvatar(channel)
      : overrideShowOtherAvatar

  const renderItem = React.useCallback(
    ({ item }: MessageItem) => (
      <MessageListItem
        channelUser={channelUser}
        isDisplayedInThread={isDisplayedInThread}
        isFirstUnreadMessage={item.message.id === hooks.firstUnreadMessageId}
        item={item}
        showOtherAvatar={showOtherAvatar}
      />
    ),
    [channelUser, isDisplayedInThread, hooks.firstUnreadMessageId, showOtherAvatar],
  )

  // NOTE: inverted flat lists have a number of issues on web
  return (
    <MessageActionsContainer isDisplayedInThread={isDisplayedInThread}>
      <Box direction="column" flex={1}>
        <FlatList
          ref={flatListRef}
          ListFooterComponent={
            <MessageListHeader
              channel={channel}
              hasError={hooks.hasError}
              hasMore={hooks.hasMore}
              isLoading={hooks.isLoading}
              onLoadMore={hooks.loadMore}
              showIntro={showIntro}
            />
          }
          data={hooks.messageItems}
          inverted
          keyExtractor={getMessageKey}
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="handled"
          onViewableItemsChanged={handleViewableItemsChanged}
          renderItem={renderItem}
          viewabilityConfig={viewabilityConfig}
        />
        <NewMessagesIndicator show={hooks.hasUnreadAbove} />
      </Box>
    </MessageActionsContainer>
  )
}

export default React.memo(MessagesList)
