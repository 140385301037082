import { decamelizeKeys } from 'humps'
import { config } from '@/core'
import { FetchParams, RequestDescriptor } from '@/types/api'
import { appJSON, commonHeaders } from './constants'
import { buildUrl, mergeHeaders } from './utils'

export const mapRequest = (request: RequestDescriptor): FetchParams => {
  const url = buildUrl(config.apiURLRoot, request)
  const headers = mergeHeaders(commonHeaders, request.headers)

  let body = null

  if ((request.method === 'POST' || request.method === 'PUT') && request.body) {
    if (request.body instanceof FormData) {
      body = request.body
    } else {
      body = JSON.stringify(decamelizeKeys(request.body))
      headers['Content-Type'] = appJSON
    }
  }

  return {
    body,
    headers,
    method: request.method,
    onUploadProgress: request.onUploadProgress,
    url,
  }
}
