import React from 'react'
import { useRTMContext } from '@/contexts/RTMContext'
import { dateUtils as D } from '@/core/utils'
import { useAppDispatch } from '@/hooks/redux'
import { useInterval } from '@/hooks/timeout'
import { rtmThunks } from '@/thunks'
import { Channel } from '@/types/entities'

const defaultDelay = D.toMilliseconds.seconds(1)

export const useTyping = (channel?: Channel, delayMS = defaultDelay) => {
  const dispatch = useAppDispatch()
  const rtm = useRTMContext()
  const unsentTypingCountRef = React.useRef(0)
  const { interval, clear, isActive } = useInterval(delayMS)

  const sendTyping = React.useCallback(() => {
    if (!rtm || !channel) {
      return
    }

    if (unsentTypingCountRef.current === 0) {
      clear()
    } else {
      unsentTypingCountRef.current = 0
      dispatch(rtmThunks.typing(rtm, channel))
    }
  }, [rtm, clear, channel, dispatch])

  const handleTyping = React.useCallback(() => {
    unsentTypingCountRef.current += 1
    if (!isActive()) {
      sendTyping()
      interval(sendTyping)
    }
  }, [isActive, sendTyping, interval])

  return { clearTyping: clear, handleTyping }
}
