import React from 'react'
import { View, ViewStyle } from 'react-native'
import { getColor } from '@/core/constants/colors'

interface VerticalProgressOverlayProps {
  percentage: number
}

function VerticalProgressOverlay({ percentage }: VerticalProgressOverlayProps) {
  const style: ViewStyle = {
    backgroundColor: getColor('ModalBG'),
    height: `${(1 - percentage) * 100}%`,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  }
  return <View style={style} />
}

export default React.memo(VerticalProgressOverlay)
