import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { SummaryFieldName } from '../../types'
import { getSummaryFieldConfiguration } from '../../utils'
import AddSummaryItemButton from '../AddSummaryItemButton'
import EditField from './EditField'
import ViewField from './ViewField'
import { useField } from './hooks'

interface EditSummaryFieldProps {
  border?: 'top' | 'bottom' | 'both'
  content: string | null
  fieldName: SummaryFieldName
  index: number
  showAddHeader?: boolean
  showAddItem?: boolean
}

function EditSummaryField({
  border,
  content,
  fieldName,
  index,
  showAddHeader = false,
  showAddItem = false,
}: EditSummaryFieldProps) {
  const { isEditing, handleStartEditing } = useField(fieldName, index)
  const fieldConfig = getSummaryFieldConfiguration(fieldName)

  return (
    <>
      {showAddHeader || showAddItem ? (
        <Box gapBottom="medium">
          <AddSummaryItemButton
            index={index}
            showAddHeader={showAddHeader}
            showAddItem={showAddItem}
          />
        </Box>
      ) : null}
      {border === 'top' || border === 'both' ? <BorderTop /> : null}
      <Touchable feedback="none" onPress={handleStartEditing}>
        <Box alignItems="center" direction="row" gapBottom="xsmall">
          <Text size="h6" weight="medium">
            {fieldConfig.label}
            {fieldConfig.isRequired ? '*' : ''}
          </Text>
          {fieldConfig.isRequired ? null : (
            <Text color="iron" size="h6">
              {' '}
              (optional)
            </Text>
          )}
        </Box>
        <Background borderColor="koala" borderRadius={8} borderWidth={1} color="silver">
          <Box gapAll="medium">
            {isEditing ? (
              <EditField content={content} fieldName={fieldName} />
            ) : (
              <ViewField content={content} fieldName={fieldName} />
            )}
          </Box>
        </Background>
      </Touchable>
      {border === 'bottom' || border === 'both' ? <BorderBottom /> : null}
    </>
  )
}

const BorderTop = () => (
  <Box gapBottom="medium" gapHorizontal="-large">
    <Background borderColor="koala" borderTopWidth={1} />
  </Box>
)

const BorderBottom = () => (
  <Box gapHorizontal="-large" gapTop="medium">
    <Background borderBottomWidth={1} borderColor="koala" />
  </Box>
)
export default React.memo(EditSummaryField)
