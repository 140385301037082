import { FileCacheInterface } from './types'

export class WebCache implements FileCacheInterface {
  /* eslint-disable class-methods-use-this, @typescript-eslint/no-empty-function */
  getLocalURI(remoteURI: string): string | null {
    return remoteURI
  }

  async cacheFile(remoteURI: string): Promise<string | null> {
    return remoteURI
  }

  async warmupCache(): Promise<void> {}

  async clearCache(): Promise<void> {}

  async cleanupCaches(): Promise<void> {}
  /* eslint-enable */
}
