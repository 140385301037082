import React from 'react'
import { Modal } from 'react-native'
import EmojiPicker from '@/components/core/EmojiPicker'
import MeasuredIconButton from '@/components/core/MeasuredIconButton'
import MenuLayout from '@/components/layouts/MenuLayout'
import { useLayoutPosition } from '@/hooks/components'
import { Measure } from '@/types/components'
import { Emoji, SelectEmoji } from '@/types/emoji'
import { FontSize } from '@/types/typography'

interface EmojiPickerButtonProps {
  onPick: SelectEmoji
  size?: FontSize | number
}

const defaultMeasure: Measure = { height: 0, width: 0, x: 0, y: 0 }

function EmojiPickerButton({ onPick, size = 'h3' }: EmojiPickerButtonProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [anchorMeasure, setAnchorMeasure] = React.useState(defaultMeasure)
  const layout = useLayoutPosition('BOTTOM', 'RIGHT', anchorMeasure)

  const handleClose = React.useCallback(() => setIsOpen(false), [setIsOpen])
  const handleOpen = React.useCallback(
    (measure: Measure) => {
      setAnchorMeasure(measure)
      setIsOpen(true)
    },
    [setIsOpen, setAnchorMeasure],
  )

  const handlePick = React.useCallback(
    (emoji: Emoji) => {
      onPick(emoji)
      setIsOpen(false)
    },
    [onPick, setIsOpen],
  )

  return (
    <>
      <MeasuredIconButton
        color="iron"
        hitSlop="xsmall"
        name="smile-wink"
        onLayoutChange={setAnchorMeasure}
        onPress={handleOpen}
        padding={0}
        shape="square"
        size={size}
      />

      <Modal animationType="fade" transparent visible={isOpen}>
        <MenuLayout layout={layout} onClose={handleClose} width={350}>
          <EmojiPicker height={400} onCancel={handleClose} onPick={handlePick} />
        </MenuLayout>
      </Modal>
    </>
  )
}

export default React.memo(EmojiPickerButton)
