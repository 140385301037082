import React from 'react'
import { useSummaryItems } from '@/hooks/summaries'
import { Summary } from '@/types/entities'
import { useEditSummaryContext } from '../EditSummaryContext'
import { buildEditItems } from '../utils'

export const useEditSummaryItems = (summary: Summary) => {
  const { isLoading, summaryItems } = useSummaryItems(summary)
  const { addItem } = useEditSummaryContext()

  const wasLoadingRef = React.useRef(isLoading)
  const itemsCount = summaryItems.length

  const createInitialHeader = React.useCallback(() => {
    if (isLoading || !wasLoadingRef.current) {
      return
    }
    wasLoadingRef.current = false
    if (itemsCount === 0) {
      addItem({ beforeItemId: null, index: 0, itemType: 'HEADER' })
    }
  }, [isLoading, itemsCount, addItem])

  const editItems = React.useMemo(
    () => buildEditItems(summary, summaryItems),
    [summary, summaryItems],
  )

  React.useEffect(createInitialHeader, [createInitialHeader])

  return {
    editItems,
    isLoading,
  }
}
