import { RouteProp as NativeRouteProp, NavigatorScreenParams } from '@react-navigation/native'
import { StackNavigationProp, createStackNavigator } from '@react-navigation/stack'
import {
  RootRouteParams as NarrowRootRouteParams,
  SpaceRouteParams as NarrowSpaceRouteParams,
  SpaceParams,
} from '@/screens/NarrowScreen/routes/params'

export { rootScreenOptions, spaceScreenOptions } from '@/screens/NarrowScreen/routes/screenOptions'

type SpaceRouteParams = Pick<
  NarrowSpaceRouteParams,
  'summaries' | 'view-summary' | 'edit-summary' | 'main'
>
type RootRouteParams = Pick<
  NarrowRootRouteParams,
  | 'auth'
  | 'join-space'
  | 'sign-up'
  | 'teams-sign-up'
  | 'create-space'
  | 'account-management'
  | 'create'
  | 'archived-items'
> & {
  spaces: SpaceParams | (SpaceParams & NavigatorScreenParams<SpaceRouteParams>)
}

export type RootNavigationProp<T extends keyof RootRouteParams> = StackNavigationProp<
  RootRouteParams,
  T
>
export type RootRouteProp<T extends keyof RootRouteParams> = NativeRouteProp<RootRouteParams, T>
export const RootStack = createStackNavigator<RootRouteParams>()

export type SpaceNavigationProp<T extends keyof SpaceRouteParams> = StackNavigationProp<
  SpaceRouteParams,
  T
>
export const SpaceStack = createStackNavigator<SpaceRouteParams>()
