import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import Icon from '../Icon'
import { ScrollPosition, Side } from './types'
import { shouldShowButton } from './utils'

interface ScrollButtonProps {
  backgroundColor: ColorName
  iconColor: ColorName
  onPress: (side: Side) => void
  scrollPosition: ScrollPosition
  side: Side
}

const transparent = getColor('transparent')
const left = { x: 0, y: 0.5 }
const right = { x: 1, y: 0.5 }
const locations = [0.5, 1]

function ScrollButton({
  backgroundColor,
  iconColor,
  side,
  onPress,
  scrollPosition,
}: ScrollButtonProps) {
  const handlePress = React.useCallback(() => onPress(side), [onPress, side])
  const colors = React.useMemo(
    () => [getColor(backgroundColor), transparent] as string[],
    [backgroundColor],
  )

  if (!shouldShowButton(side, scrollPosition)) {
    return null
  }

  return (
    <LinearGradient
      colors={colors}
      end={side === 'left' ? right : left}
      locations={locations}
      start={side === 'left' ? left : right}
      style={styles[side]}
    >
      <Pressable onPress={handlePress} style={styles.pressable}>
        <Icon
          color={iconColor}
          kind="regular"
          name={side === 'left' ? 'chevron-left' : 'chevron-right'}
          size="h3"
        />
      </Pressable>
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  left: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 40,
  },
  pressable: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  right: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 40,
  },
})

export default React.memo(ScrollButton)
