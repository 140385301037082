import React from 'react'
import Box from '@/components/layouts/Box'
import { CreateTaskFilterOptionContainer } from '@/contexts/CreateTaskFilterOptionContext'
import TasksListViewContext from '@/contexts/TasksListViewContext'
import { ColorName } from '@/core/constants/colors'
import { platformUtils } from '@/core/utils'
import { Task } from '@/types/entities'
import { TasksListArgs } from '@/types/tasks'
import CreateTaskButton from '../CreateTaskButton'
import EditTaskContainer from './EditTaskContainer'
import TaskFilters from './TaskFilters'
import TaskRelationshipTabs from './TaskRelationshipTabs'
import TasksList from './TasksList'
import EmptyTasksList from './TasksList/EmptyTasksList'
import { defaultGroupSort, useTasksListView } from './hooks'

interface TasksListViewProps extends TasksListArgs {
  backgroundColor?: ColorName
  foregroundColor?: ColorName
  showFilters: boolean
  showRelationshipToggle: boolean
}

function TasksListView({
  backgroundColor = 'ghost',
  foregroundColor,
  contextFilter,
  fastCreate = false,
  initialTaskRelationship = 'MINE',
  onSelectTask,
  queryKey,
  selectedTaskIds,
  showFilters,
  showRelationshipToggle,
  spaceId,
  taskType,
}: TasksListViewProps) {
  const {
    channelId,
    createFilter,
    editTask,
    editTaskDetail,
    handleDoneEditingTask,
    optionGroups,
    queryKeys,
    setTaskRelationship,
    shouldShowCreateButton,
    taskGrouping,
    taskQuery,
    taskRelationship,
    tasksViewContextValue,
  } = useTasksListView({
    contextFilter,
    groupSort: defaultGroupSort,
    initialTaskRelationship,
    queryKey,
    showAllGroup: false,
    spaceId,
    taskType,
  })

  const taskFormSize = (() => {
    if (taskType === 'QUESTION') {
      return 'LARGE'
    }
    return platformUtils.isWeb ? 'MEDIUM' : 'SMALL'
  })()

  const handleSelectNewTask = React.useCallback(
    (task: Task) => {
      if (onSelectTask) {
        onSelectTask(task.id, true)
      }
    },
    [onSelectTask],
  )

  return (
    <TasksListViewContext.Provider value={tasksViewContextValue}>
      <Box flex={1}>
        {showRelationshipToggle ? (
          <Box gapHorizontal="small">
            <TaskRelationshipTabs
              channelId={channelId}
              onChange={setTaskRelationship}
              spaceId={spaceId}
              taskType={taskType}
              value={taskRelationship}
            />
          </Box>
        ) : null}
        {showFilters ? (
          <CreateTaskFilterOptionContainer channelId={channelId} spaceId={spaceId}>
            <TaskFilters
              buttonColor={backgroundColor}
              optionGroups={optionGroups}
              queryKeys={queryKeys}
            />
          </CreateTaskFilterOptionContainer>
        ) : null}
        <Box flex={1} gapTop="small">
          <TasksList
            emptyComponent={
              <EmptyTasksList
                channelId={channelId}
                spaceId={spaceId}
                taskRelationship={taskRelationship}
                taskType={taskType}
              />
            }
            fastCreate={fastCreate}
            onSelectTask={onSelectTask}
            selectedTaskIds={selectedTaskIds}
            showAssignee={taskRelationship !== 'MINE'}
            showMyDay
            taskGrouping={taskGrouping}
            taskQuery={taskQuery}
            taskType={taskType}
          />
        </Box>
        <EditTaskContainer
          onDone={handleDoneEditingTask}
          task={editTask}
          taskDetail={editTaskDetail}
        />
        {shouldShowCreateButton ? (
          <CreateTaskButton
            createTaskType={taskType}
            fastCreate={fastCreate}
            filter={createFilter}
            foregroundColor={
              foregroundColor || (taskType === 'TASK' ? 'emmre-blue' : 'emmre-purple')
            }
            formContext="tasks"
            initialTaskFormSize={taskFormSize}
            onSuccess={handleSelectNewTask}
            spaceId={spaceId}
            taskRelationship={taskRelationship}
          />
        ) : null}
      </Box>
    </TasksListViewContext.Provider>
  )
}

export default React.memo(TasksListView)
