import React from 'react'
import Box from '@/components/layouts/Box'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { fnUtils } from '@/core/utils'
import { useUser } from '@/hooks'
import { MessageListItemData } from '@/types/messaging'
import MessageBubble from './MessageBubble'
import MessageFilesList from './MessageFilesList'

interface MessageContentProps {
  disabled?: boolean
  item: MessageListItemData
  isFromMe: boolean
  isDisplayedInThread: boolean
}

function MessageContent({
  disabled = false,
  isFromMe,
  item,
  isDisplayedInThread,
}: MessageContentProps) {
  const { message } = item
  const creator = useUser(message.spaceId, message.creatorId)
  const modalPresenterContext = useModalPresenterContext()
  const handleLongPress = React.useCallback(
    () => modalPresenterContext.push('messageActions', { item }),
    [modalPresenterContext, item],
  )

  if (!creator) {
    return fnUtils.fail('Message creator not found')
  }

  const justifyContent = isFromMe ? 'flex-end' : 'flex-start'

  return (
    <Box>
      <Box alignItems={justifyContent}>
        <MessageFilesList
          canShowThread={!isDisplayedInThread}
          disabled={disabled}
          isFromMe={isFromMe}
          message={message}
          onLongPress={handleLongPress}
        />
      </Box>
      {message.content ? (
        <Box direction="row" justifyContent={justifyContent}>
          <MessageBubble
            canShowThread={!isDisplayedInThread}
            disabled={disabled}
            isFromMe={isFromMe}
            item={item}
            onLongPress={handleLongPress}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(MessageContent)
