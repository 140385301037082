import type { AlertButton as RNAlertButton } from 'react-native'
import { Alert } from 'react-native'
import { AlertButton } from '@/types/actions'

export {
  showActionAlert,
  showActionAlertAsync,
  showDestructiveAlert,
  showDestructiveAlertAsync,
  showNoActionAlert,
} from './actionAlert'

const mapAlertButtonStyle = (style: AlertButton['style']): RNAlertButton['style'] => {
  if (style === 'primary') {
    return 'default'
  }
  return style
}

const mapAlertButtonToRNAlertButton = (alertButton: AlertButton): RNAlertButton => ({
  ...alertButton,
  style: mapAlertButtonStyle(alertButton.style),
})

export const rnAlert = (title: string, message: string | undefined, buttons: AlertButton[]) => {
  Alert.alert(title, message, buttons.map(mapAlertButtonToRNAlertButton))
}
