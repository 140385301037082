import React from 'react'
import { collectionUtils as C, taskUtils } from '@/core/utils'
import { useSentinelEffect } from '@/hooks/state'
import { QuestionResponseType, QuestionTask } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'
import { QuestionResponseContextType } from './types'
import { getInitialState } from './utils'

type State = QuestionResponseForm
type QuestionResponseAction =
  | {
      type: 'setResponseSelected'
      payload: { responseType: QuestionResponseType; responseId: number; isSelected: boolean }
    }
  | {
      type: 'setOtherSelected'
      payload: { responseType: QuestionResponseType; isSelected: boolean }
    }
  | {
      type: 'setOpenEndedResponse'
      payload: { responseType: QuestionResponseType; openEndedResponse: string }
    }
  | { type: 'reset'; payload: QuestionResponseForm }

const reducer = (state: State, action: QuestionResponseAction): State => {
  const { type } = action

  if (type === 'setResponseSelected') {
    const { responseId, isSelected, responseType } = action.payload

    if (isSelected) {
      return {
        ...state,
        selectedResponseIds:
          responseType === 'SINGLE' ? [responseId] : [...state.selectedResponseIds, responseId],
      }
    }

    return {
      ...state,
      selectedResponseIds: C.without(responseId, state.selectedResponseIds),
    }
  }

  if (type === 'setOtherSelected') {
    const { isSelected, responseType } = action.payload

    if (isSelected) {
      return {
        ...state,
        otherSelected: true,
        selectedResponseIds: responseType === 'SINGLE' ? [] : state.selectedResponseIds,
      }
    }

    return {
      ...state,
      openEndedResponse: null,
      otherSelected: false,
    }
  }

  if (type === 'setOpenEndedResponse') {
    const { openEndedResponse, responseType } = action.payload

    return {
      ...state,
      openEndedResponse,
      otherSelected: true,
      selectedResponseIds: responseType === 'SINGLE' ? [] : state.selectedResponseIds,
    }
  }

  if (type === 'reset') {
    return action.payload
  }

  return state
}

export const useQuestionResponseForm = (
  task: QuestionTask,
  initialState?: State,
): QuestionResponseContextType => {
  const compareState = initialState || getInitialState(task)
  const [responseForm, dispatch] = React.useReducer(reducer, compareState)
  const { responseType } = task.question

  const setOpenEndedResponse = React.useCallback(
    (openEndedResponse: string) =>
      dispatch({ payload: { openEndedResponse, responseType }, type: 'setOpenEndedResponse' }),
    [dispatch, responseType],
  )
  const setOtherSelected = React.useCallback(
    (isSelected: boolean) =>
      dispatch({ payload: { isSelected, responseType }, type: 'setOtherSelected' }),
    [dispatch, responseType],
  )
  const setResponseSelected = React.useCallback(
    (responseId: number, isSelected: boolean) =>
      dispatch({ payload: { isSelected, responseId, responseType }, type: 'setResponseSelected' }),
    [dispatch, responseType],
  )

  useSentinelEffect(
    React.useCallback(() => {
      dispatch({ payload: getInitialState(task), type: 'reset' })
    }, [dispatch, task]),
    task?.updatedAt,
  )

  return {
    isChanged: taskUtils.responsesAreDifferent(compareState, responseForm),
    isComplete: taskUtils.responseIsComplete(responseForm),
    responseForm,
    setOpenEndedResponse,
    setOtherSelected,
    setResponseSelected,
  }
}
