import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import DragView from '@/components/core/DragView'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { SummaryItem } from '@/types/entities'
import AddSummaryItemButton from '../AddSummaryItemButton'
import { useHideDecorations } from '../hooks'
import EditItem from './EditItem'
import ViewItem from './ViewItem'
import { useHeader } from './hooks'

interface EditSummaryHeaderItemProps {
  index: number
  isDragging: boolean
  onInitiateDrag?: () => void
  showAdd: boolean
  summaryItem: SummaryItem
}

function EditSummaryHeaderItem({
  index,
  isDragging,
  onInitiateDrag,
  showAdd,
  summaryItem,
}: EditSummaryHeaderItemProps) {
  const { isEditing, handleDelete, handleStartEditing } = useHeader(summaryItem, index)
  const { hideDecorations } = useHideDecorations(isDragging)

  return (
    <>
      {hideDecorations || !showAdd ? null : (
        <Box gapBottom="medium">
          <AddSummaryItemButton
            beforeItemId={summaryItem.id}
            index={index}
            showAddHeader
            showAddItem
          />
        </Box>
      )}
      <DragView
        onDragStart={isEditing ? undefined : onInitiateDrag}
        onPress={isEditing ? undefined : handleStartEditing}
      >
        {hideDecorations ? null : (
          <Box alignItems="center" direction="row" gapBottom="xsmall">
            <Text size="h6" weight="medium">
              Header
            </Text>
          </Box>
        )}
        <Box direction="row">
          <Background borderColor="koala" borderRadius={8} borderWidth={1} color="silver" flex={1}>
            <Box gapAll="medium">
              {isEditing ? (
                <EditItem summaryItem={summaryItem} />
              ) : (
                <ViewItem summaryItem={summaryItem} />
              )}
            </Box>
          </Background>
          <Box gapLeft="medium">
            <BaseIconButton
              borderColor="koala"
              borderRadius={8}
              borderStyle="solid"
              borderWidth={1}
              color="koala"
              name="trash-alt"
              onPress={handleDelete}
              padding={12}
              shape="square"
            />
          </Box>
        </Box>
      </DragView>
    </>
  )
}

export default React.memo(EditSummaryHeaderItem)
