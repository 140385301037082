import React from 'react'
import { StyleSheet } from 'react-native'
import { getColor } from '@/core/constants/colors'
import Icon from '../../Icon'
import Text from '../../Text'
import Touchable from '../../Touchable'
import { ListItemAction, UnderlayButtonType } from './types'

interface ListItemUnderlayActionProps<S> extends ListItemAction<S> {
  onClose: () => void
  subject: S
  buttonType: UnderlayButtonType
  buttonWidth: number
}

function ListItemUnderlayAction<S>({
  buttonType,
  buttonWidth,
  handler,
  icon,
  label,
  onClose,
  subject,
  type = 'default',
}: ListItemUnderlayActionProps<S>) {
  const handlePress = React.useCallback(() => {
    onClose()
    handler(subject)
  }, [handler, subject, onClose])

  return (
    <Touchable
      feedback="opacity"
      onPress={handlePress}
      style={[styles[type], { width: buttonWidth }]}
    >
      {buttonType === 'ICON' ? (
        <Icon color="ghost" kind="solid" name={icon} size="h4" />
      ) : (
        <Text color="ghost">{label}</Text>
      )}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  cancel: {
    alignItems: 'center',
    backgroundColor: getColor('shadow'),
    justifyContent: 'center',
  },
  default: {
    alignItems: 'center',
    backgroundColor: getColor('emmre-blue'),
    justifyContent: 'center',
  },
  destructive: {
    alignItems: 'center',
    backgroundColor: getColor('red'),
    justifyContent: 'center',
  },
  primary: {
    alignItems: 'center',
    backgroundColor: getColor('emmre-blue'),
    justifyContent: 'center',
  },
})

export default React.memo(ListItemUnderlayAction) as typeof ListItemUnderlayAction
