import React from 'react'
import { Image } from 'react-native'

interface EmmreLogoProps {
  size: number
}

function EmmreLogo({ size }: EmmreLogoProps) {
  const style = React.useMemo(() => ({ height: size, width: size }), [size])

  return <Image source={require('assets/icon-square.png')} style={style} />
}

export default React.memo(EmmreLogo)
