import * as Updates from 'expo-updates'
import { config } from '@/core'
import { reportError } from '@/core/logging'
import { rnAlert, showNoActionAlert } from './actions'

const updateEventHandler = (event: Updates.UpdateEvent) => {
  if (event.type !== Updates.UpdateEventType.UPDATE_AVAILABLE) {
    return
  }

  showNoActionAlert(
    rnAlert,
    'Emmre Updated',
    'An update for Emmre has been downloaded. The updated version will be loaded after you press OK',
    () => Updates.reloadAsync(),
  )
}

export const attachUpdateHandler = () => Updates.addListener(updateEventHandler)

export const manuallyUpdateApp = async () => {
  try {
    await fetchUpdate()
  } catch (err: any) {
    reportError(err)
  }
}

const fetchUpdate = async (): Promise<boolean> => {
  if (!config.checkForUpdates) {
    return false
  }

  const { isAvailable } = await Updates.checkForUpdateAsync()

  if (!isAvailable) {
    return false
  }

  const { isNew } = await Updates.fetchUpdateAsync()

  return isNew
}
