import React from 'react'
import { View } from 'react-native'
import { PanGestureHandler } from 'react-native-gesture-handler'

type VerticalSwipeDirection = 'up' | 'down'

export type VerticalSwipeEventArg = {
  direction: VerticalSwipeDirection
  distance: number
}

interface VerticalSwipeContainerProps {
  children: React.ReactNode
  activeOffset: number | [number, number]
  onSwipeBegan?: () => void
  onSwipe: (arg: VerticalSwipeEventArg) => void
}

function VerticalSwipeContainer({
  children,
  activeOffset,
  onSwipe,
  onSwipeBegan,
}: VerticalSwipeContainerProps) {
  const handleSwipe = React.useCallback(
    ({ nativeEvent: { translationY } }) => {
      const direction = translationY < 0 ? 'up' : 'down'
      onSwipe({ direction, distance: Math.abs(translationY) })
    },
    [onSwipe],
  )

  // PanGestureHandler needs a real component to attach a ref to?
  return (
    <PanGestureHandler
      activeOffsetY={activeOffset}
      onBegan={onSwipeBegan}
      onGestureEvent={handleSwipe}
    >
      <View>{children}</View>
    </PanGestureHandler>
  )
}

export default React.memo(VerticalSwipeContainer)
