import React from 'react'
import { easeInOut } from '@/core/utils/animations'

export function useTimeout(defaultDelay: number) {
  const timeoutHandleRef = React.useRef<ReturnType<typeof setTimeout> | null>(null)
  const clear = React.useCallback(() => {
    if (timeoutHandleRef.current !== null) {
      clearTimeout(timeoutHandleRef.current)
    }
  }, [])
  const timeout = React.useCallback(
    (callback: () => void, delay: number = defaultDelay) => {
      clear()
      timeoutHandleRef.current = setTimeout(callback, delay)
    },
    [defaultDelay, clear],
  )

  React.useEffect(() => clear, [clear])

  return { clear, timeout }
}

export function useInterval(delay: number) {
  const intervalHandleRef = React.useRef<ReturnType<typeof setInterval> | null>(null)
  const clear = React.useCallback(() => {
    if (intervalHandleRef.current !== null) {
      clearInterval(intervalHandleRef.current)
      intervalHandleRef.current = null
    }
  }, [])
  const interval = React.useCallback(
    (callback: () => void) => {
      clear()
      intervalHandleRef.current = setInterval(callback, delay)
    },
    [delay, clear],
  )
  const isActive = React.useCallback(() => intervalHandleRef.current !== null, [])

  React.useEffect(() => clear, [clear])

  return { clear, interval, isActive }
}

type TimedSwitchArgs = {
  delay?: number
  animate?: boolean
}

export function useTimedSwitch({ delay: defaultDelay = 3000, animate = true }: TimedSwitchArgs) {
  const { timeout, clear } = useTimeout(defaultDelay)
  const [isOn, setIsOn] = React.useState(false)
  const turnOn = React.useCallback(
    (delay = defaultDelay) => {
      clear()
      if (animate) {
        easeInOut()
      }
      setIsOn(true)
      timeout(() => {
        if (animate) {
          easeInOut()
        }
        setIsOn(false)
      }, delay || defaultDelay)
    },
    [clear, setIsOn, timeout, defaultDelay, animate],
  )

  return {
    isOn,
    turnOn,
  }
}
