import React from 'react'
import { platformUtils } from '@/core/utils'
import { useActions } from '@/hooks/actions'
import { ActionArgs, ActionOption } from '@/types/actions'
import { ButtonKind } from './Button'
import IconButton from './IconButton'

interface ActionsButtonProps<S, A extends ActionOption<S>> {
  actionArgs: ActionArgs
  actions: A[]
  item: S
  kind?: ButtonKind
  webOnly?: boolean
}

function ActionsButton<S, A extends ActionOption<S>>({
  actionArgs,
  actions,
  item,
  kind = 'transparentDark',
  webOnly = false,
}: ActionsButtonProps<S, A>) {
  const showActions = useActions(actions, actionArgs)

  const handlePress = React.useCallback(() => showActions(item), [showActions, item])

  if (actions.length === 0) {
    return null
  }

  if (webOnly && !platformUtils.isWeb) {
    return null
  }

  return <IconButton kind={kind} name="ellipsis-h" onPress={handlePress} size="h6" />
}

export default React.memo(ActionsButton) as typeof ActionsButton
