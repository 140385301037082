export const noOp = (): void => {} // eslint-disable-line @typescript-eslint/no-empty-function

export const identity = <T>(t: T): T => t

export const always =
  <T>(t: T): (() => T) =>
  () =>
    t

export const alwaysAsync =
  <T>(t: T): (() => Promise<T>) =>
  () =>
    Promise.resolve(t)

export const discard = <T>(callable: () => T): void => {
  callable()
}

export const fail = (message: string): never => {
  throw new Error(message)
}
