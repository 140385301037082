import React from 'react'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { appUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { useIsMounted } from '@/hooks/components'
import { authThunks } from '@/thunks'

function AppErrorScreen() {
  const dispatch = useAppDispatch()
  const isMountedRef = useIsMounted()
  const [isReloading, setIsReloading] = React.useState(false)
  const handleReloadApp = React.useCallback(async () => {
    setIsReloading(true)
    await dispatch(authThunks.signOut(null))
    await appUtils.reloadApp()
    if (isMountedRef.current) {
      setIsReloading(false)
    }
  }, [dispatch, isMountedRef, setIsReloading])
  return (
    <Box alignItems="center" flex={1} gapAll="large" justifyContent="center">
      <Text align="center">
        Uh Oh. Something went wrong. We&apos;ve logged this error and our team is looking into it.
      </Text>
      <Box gapTop="large">
        <Button
          disabled={isReloading}
          kind="primary"
          label="Reload App"
          onPress={handleReloadApp}
        />
      </Box>
    </Box>
  )
}

export default React.memo(AppErrorScreen)
