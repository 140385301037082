import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { messageAPI } from '@/api/requests'
import { messageUtils, typeUtils } from '@/core/utils'
import { channelUserActions, formActions, messageActions } from '@/store/actions'
import { buildMessageId, getMessageId } from '@/store/entityIds'
import { authSelectors, channelUserSelectors, formSelectors } from '@/store/selectors'
import { SentMessage, UnsentMessage } from '@/types/entities'
import { SyncThunk, Thunk } from '@/types/store'
import { updateChannelStats } from './channels'
import { setErrors } from './errors'
import * as fileThunks from './files'
import { addMessage, addMessages, addTasks } from './store'
import { makeEnhancedRequest } from './utils'

const shouldReplace = (params: messageAPI.MessageParams) =>
  params.beforeMessageId === undefined && !params.threadId

interface NewMessagesParams {
  spaceId: number
  channelId: number
  threadId?: number
  afterMessageId: number
}

export const getNewerMessages =
  ({ spaceId, channelId, threadId, afterMessageId }: NewMessagesParams): Thunk<void> =>
  async dispatch => {
    const request = messageAPI.getMessages(spaceId, channelId, { afterMessageId, threadId })
    const response = await dispatch(makeEnhancedRequest(request))

    if (!response.ok) {
      return
    }

    const { messages, channelUser, tasks, threadHeads } = response.data

    if (messages.length === 0) {
      return
    }

    batch(() => {
      dispatch(channelUserActions.upsertOne(channelUser))
      dispatch(addMessages(messages))
      dispatch(addMessages(threadHeads || []))
      dispatch(addTasks(tasks))
    })

    const lastId = messages[0].id
    await dispatch(getNewerMessages({ afterMessageId: lastId, channelId, spaceId, threadId }))
  }

export const getMessages =
  (
    spaceId: number,
    channelId: number,
    params: messageAPI.MessageParams,
  ): RequestThunk<typeof messageAPI.getMessages> =>
  async dispatch => {
    const request = messageAPI.getMessages(spaceId, channelId, params)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { messages, channelUser, tasks, threadHeads } = response.data

      if (shouldReplace(params)) {
        batch(() => {
          dispatch(channelUserActions.upsertOne(channelUser))
          dispatch(addTasks(tasks))
          dispatch(addMessages(threadHeads || []))

          if (messages.length !== 0) {
            dispatch(
              messageActions.replaceWhere({
                entities: messages,
                predicate: entity => entity.channelId === channelId,
              }),
            )
          }
        })
      } else {
        batch(() => {
          dispatch(channelUserActions.upsertOne(channelUser))
          dispatch(addMessages(messages))
          dispatch(addMessages(threadHeads || []))
          dispatch(addTasks(tasks))
        })
      }
    }

    return response
  }

export const createMessage =
  (unsentMessage: UnsentMessage): RequestThunk<typeof messageAPI.createMessage> =>
  async dispatch => {
    dispatch(messageActions.upsertOne(unsentMessage))

    const { spaceId, channelId, content, threadId, clientTemporaryId, attachmentIds } =
      unsentMessage
    const fileIds = await dispatch(fileThunks.getFileIds(attachmentIds))

    const request = messageAPI.createMessage(spaceId, channelId, {
      clientTemporaryId,
      content,
      fileIds,
      threadId,
    })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { message, channelUser } = response.data
      batch(() => {
        dispatch(channelUserActions.upsertOne(channelUser))
        dispatch(addSentMessage(message))
        dispatch(updateChannelStats(message))
      })
    } else {
      dispatch(messageActions.removeOne(getMessageId(unsentMessage)))
    }

    return response
  }

export const editMessage =
  (
    spaceId: number,
    messageId: number,
    updates: messageAPI.EditMessage,
  ): RequestThunk<typeof messageAPI.editMessage> =>
  async dispatch => {
    const request = messageAPI.editMessage(spaceId, messageId, updates)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(addSentMessage(response.data.message))
    }

    return response
  }

export const removeFileFromMessage =
  (
    spaceId: number,
    messageId: number,
    fileId: number,
  ): RequestThunk<typeof messageAPI.removeFileFromMessage> =>
  async dispatch => {
    const request = messageAPI.removeFileFromMessage(spaceId, messageId, fileId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(addSentMessage(response.data.message))
    }

    return response
  }

export const reactToMessage =
  (
    spaceId: number,
    messageId: number,
    reaction: string,
  ): RequestThunk<typeof messageAPI.reactToMessage> =>
  async dispatch => {
    const request = messageAPI.reactToMessage(spaceId, messageId, reaction)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(addSentMessage(response.data.message))
    }

    return response
  }

export const removeMessageReaction =
  (spaceId: number, messageId: number): RequestThunk<typeof messageAPI.removeMessageReaction> =>
  async dispatch => {
    const request = messageAPI.removeMessageReaction(spaceId, messageId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(addSentMessage(response.data.message))
    }

    return response
  }

export const deleteMessage =
  (spaceId: number, messageId: number): RequestThunk<typeof messageAPI.deleteMessage> =>
  async dispatch => {
    const request = messageAPI.deleteMessage(spaceId, messageId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(addSentMessage(response.data.message))
    }

    return response
  }

export const createMessageForm =
  (
    spaceId: number,
    channelId: number,
    threadId: number | null,
    formName: string,
    clearForm: boolean,
  ): Thunk<boolean> =>
  async (dispatch, getState) => {
    const { content, attachmentIds } = formSelectors.values(getState(), formName)

    const creatorId = authSelectors.myId(getState())
    typeUtils.assertIsDefined(creatorId)

    const unsentMessage = messageUtils.createUnsentMessage(
      {
        channelId,
        content: content ? content.trim() : null,
        creatorId,
        spaceId,
        threadId,
      },
      attachmentIds || [],
    )

    if (clearForm) {
      dispatch(formActions.clear(formName))
    }
    const response = await dispatch(createMessage(unsentMessage))

    if (!response.ok) {
      batch(() => {
        dispatch(formActions.setValue({ fieldName: 'content', formName, value: content }))
        dispatch(setErrors(formName, response.errors, 'Error Creating Message'))
      })
    }
    return response.ok
  }

export const editMessageForm =
  (spaceId: number, messageId: number, formName: string): Thunk<boolean> =>
  async (dispatch, getState) => {
    const updates = formSelectors.values(getState(), formName)

    const response = await dispatch(editMessage(spaceId, messageId, updates))

    if (!response.ok) {
      dispatch(setErrors(formName, response.errors, 'Error Editing Message'))
    }

    return response.ok
  }

export const addSentMessage =
  (message: SentMessage): SyncThunk<void> =>
  dispatch => {
    const { clientTemporaryId } = message

    if (!clientTemporaryId) {
      dispatch(addMessage(message))
    } else {
      // The unsent message's ID is a number, and the clientTemporaryId is the string of that number
      // So the unsent message is stored under spaceId:clientTemporaryId
      const temporaryId = parseInt(clientTemporaryId, 10)
      const unsentMessageId = buildMessageId(message.spaceId, temporaryId)
      batch(() => {
        dispatch(messageActions.removeOne(unsentMessageId))
        dispatch(addMessage(message))
      })
    }
  }

export const isMessageRead =
  (spaceId: number, channelId: number, messageId: number): SyncThunk<boolean> =>
  (dispatch, getState) => {
    const userId = authSelectors.myId(getState())
    if (!userId) {
      return false
    }
    const channelUser = channelUserSelectors.byId(getState(), spaceId, channelId, userId)
    if (!channelUser) {
      return false
    }
    return channelUser.maxReadMessageId >= messageId
  }
