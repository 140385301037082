import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface FileListItemErrorProps {
  label: string
}

function FileListItemError({ label }: FileListItemErrorProps) {
  return (
    <Background borderColor="red" borderRadius={8} borderWidth={1}>
      <Box gapAll="small">
        <Text color="red" size="h5">
          There was a problem loading the {label}
        </Text>
      </Box>
    </Background>
  )
}

export default React.memo(FileListItemError)
