import * as Notifications from 'expo-notifications'
import React from 'react'
import { eventTypes } from '@/core/constants'
import { emitter } from '@/core/pubsub'
import { callWithModalBugFix } from '@/core/utils/components'
import { useAppDispatch } from '@/hooks'
import { useCurrentAppFocus } from '@/hooks/appProfile'
import { useCouldBeSignedIn } from '@/hooks/auth'
import { commThunks, messageThunks } from '@/thunks'
import { canUsePushNotifications, getNotificationRouting, storeNotificationData } from './utils'

const notificationSettings = (
  shouldShowAlert = true,
  shouldPlaySound = true,
  shouldSetBadge = true,
) => ({ shouldPlaySound, shouldSetBadge, shouldShowAlert })

const showNotification = notificationSettings(true, true, false)
const hideNotification = notificationSettings(false, false, false)

export const useNotificationHandler = () => {
  const dispatch = useAppDispatch()
  const { channelId: focusedChannelId, spaceId: focusedSpaceId } = useCurrentAppFocus()

  const handleNotification = React.useCallback(
    async (
      notification: Notifications.Notification,
    ): Promise<Notifications.NotificationBehavior> => {
      // NOTE: This method should be called for notifications whether or not the app is
      //       foregrounded, so we can make sure all data makes it into the store (whether or not
      //       we're going to display it right now)
      storeNotificationData(dispatch, notification)

      // If a task or a message comes in for the channel the user is currently looking at we
      // will not show a notification
      const routing = getNotificationRouting(notification)

      if (!routing) {
        return showNotification
      }

      if (routing.type === 'channel') {
        const inChannel =
          routing.spaceId === focusedSpaceId && routing.channelId === focusedChannelId

        if (inChannel) {
          return hideNotification
        }

        if (routing.subject === 'task') {
          return showNotification
        }

        const hasBeenRead = await dispatch(
          messageThunks.isMessageRead(routing.spaceId, routing.channelId, routing.messageId),
        )

        if (hasBeenRead) {
          return hideNotification
        }

        return showNotification
      }

      if (routing.type === 'task') {
        return showNotification
      }

      return showNotification
    },
    [focusedChannelId, focusedSpaceId, dispatch],
  )

  React.useEffect(() => {
    Notifications.setNotificationHandler({ handleNotification })

    return () => Notifications.setNotificationHandler(null)
  }, [handleNotification])
}

export const useResponseListener = () => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      // Scenario 1: app is in foreground. Data will be in redux from both pubsub and
      //             setNotificationHandler
      // Scenario 2: app is in background. Data will be in redux from setNotificationHandler
      // Scenario 3: app is killed. Most recent data will be loaded by mount. In this scenario we
      //             add data from the notification just so it shows up immediately while the
      //             data is being fetched from the server
      storeNotificationData(dispatch, response.notification)

      // If a user responds to a notification for a task or a message they will be taken to the
      // channel that task or message is in
      const routing = getNotificationRouting(response.notification)

      if (routing) {
        emitter.emit(eventTypes.NOTIFICATION_TAPPED, routing)
      }
    })
    return () => subscription.remove()
  }, [dispatch])
}

export const useNotificationStatus = () => {
  const dispatch = useAppDispatch()
  const [requestPermission, setRequestPermission] = React.useState(false)
  const couldBeSignedIn = useCouldBeSignedIn()

  const checkPermission = React.useCallback(async () => {
    if (!canUsePushNotifications()) {
      setRequestPermission(false)
      return
    }

    const response = await Notifications.getPermissionsAsync()

    if (response.status === 'undetermined') {
      setRequestPermission(true)
    } else if (response.status === 'denied') {
      setRequestPermission(false)
    } else if (response.status === 'granted') {
      setRequestPermission(false)
      dispatch(commThunks.registerPushNotifications)
    }
  }, [setRequestPermission, dispatch])

  const handleRequestPermission = React.useCallback(async () => {
    callWithModalBugFix(() => setRequestPermission(false))

    if (!canUsePushNotifications()) {
      return
    }

    const response = await Notifications.requestPermissionsAsync()

    if (response.status === 'granted') {
      dispatch(commThunks.registerPushNotifications)
    }
  }, [dispatch, setRequestPermission])

  React.useEffect(() => {
    if (couldBeSignedIn) {
      checkPermission()
    }
  }, [checkPermission, couldBeSignedIn])

  return {
    handleRequestPermission,
    requestPermission,
  }
}
