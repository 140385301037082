import React from 'react'
import ModalLayout from '@/components/layouts/ModalLayout'
import { Channel } from '@/types/entities'
import HubRoleBody from './HubRoleBody'
import HubUsersBody from './HubUsersBody'
import UserInfoBody from './UserInfoBody'
import { useInviteUserModal } from './hooks'

interface InviteUserModalProps {
  onClose: () => void
  onCreated?: (channel: Channel) => void
  spaceId: number
}

function InviteUserModal({ onClose, onCreated, spaceId }: InviteUserModalProps) {
  const {
    canGoBack,
    canInviteHub,
    canInviteTeamMember,
    goBack,
    goToNextStep,
    handleInvite,
    inactiveHubUsers,
    isSubmitting,
    space,
    step,
  } = useInviteUserModal(spaceId, onClose, onCreated)

  const leftAction = React.useMemo(
    () =>
      canGoBack
        ? ({
            icon: 'chevron-left',
            onAction: goBack,
            type: 'transparentPrimary',
          } as const)
        : undefined,
    [goBack, canGoBack],
  )

  const rightAction = React.useMemo(
    () =>
      ({
        icon: 'xmark',
        onAction: onClose,
        type: 'transparentDark',
      } as const),
    [onClose],
  )

  return (
    <ModalLayout
      leftAction={leftAction}
      maxWidth={450}
      rightAction={rightAction}
      title={`Invite to ${space?.name || 'Workspace'}`}
    >
      {(() => {
        switch (step) {
          case 'HUB_USERS':
            return (
              <HubUsersBody
                onGoToNextStep={goToNextStep}
                onInvited={onClose}
                users={inactiveHubUsers}
              />
            )
          case 'USER_ROLE':
            return (
              <HubRoleBody
                canSubmit={canInviteHub}
                isSubmitting={isSubmitting}
                onInvite={handleInvite}
              />
            )
          case 'USER_INFORMATION':
          default:
            return (
              <UserInfoBody
                canSubmit={canInviteTeamMember}
                isSubmitting={isSubmitting}
                onCreateHub={goToNextStep}
                onInvite={handleInvite}
                spaceId={spaceId}
              />
            )
        }
      })()}
    </ModalLayout>
  )
}

export default React.memo(InviteUserModal)
