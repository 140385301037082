import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { Thread } from '@/types/entities'

export interface ThreadsResponse {
  threads: Thread[]
}

export interface ThreadResponse {
  thread: Thread
}

export type UpdateThread = Pick<Thread, 'title'>

export const getThreadsList = (spaceId: number, paging: PagingRequest) =>
  new Request<ThreadsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/spaces/${spaceId}/threads`,
  })

export const updateThread = (spaceId: number, messageId: number, updates: UpdateThread) =>
  new Request<ThreadResponse>({
    authentication: 'PRIVATE',
    body: updates,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/threads/${messageId}`,
  })

export const ignoreThread = (spaceId: number, messageId: number) =>
  new Request<ThreadResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/threads/${messageId}/follow`,
  })

export const followThread = (spaceId: number, messageId: number) =>
  new Request<ThreadResponse>({
    authentication: 'PRIVATE',
    method: 'POST',
    url: `v1/spaces/${spaceId}/threads/${messageId}/follow`,
  })
