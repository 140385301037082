import { useAppSelector } from '@/hooks'
import { formSelectors } from '@/store/selectors'
import { InputMode } from '@/types/messaging'

export const useCanSubmit = (inputMode: InputMode, canSubmitTask: boolean, formName: string) => {
  const { attachmentIds, content } = useAppSelector(formSelectors.values, formName)

  const canSubmit =
    inputMode === 'task' || inputMode === 'question'
      ? canSubmitTask
      : !!content || (attachmentIds && attachmentIds.length)

  return canSubmit
}
