import React from 'react'
import { useOpenURL } from '@/hooks/linking'
import Link, { LinkProps } from './Link'

interface ExternalLinkProps extends Omit<LinkProps, 'onPress'> {
  url: string
}

function ExternalLink({ url, ...linkProps }: ExternalLinkProps) {
  const handlePress = useOpenURL(url)
  return <Link onPress={handlePress} {...linkProps} />
}

export default React.memo(ExternalLink)
