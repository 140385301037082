import React from 'react'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { taskUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { useActionsAsync } from '@/hooks/actions'
import { taskSelectors } from '@/store/selectors'
import { entityThunks } from '@/thunks'
import { Task } from '@/types/entities'
import { useHasChanges } from './formFields'

const duplicateActionArgs = {
  message: 'You have unsaved changes to this task, would you like to:',
  title: 'Unsaved Changes',
}
const duplicateOptions = [
  { label: 'Save & Duplicate', value: true },
  { label: 'Keep Editing', value: false },
]

export const useDuplicateTask = () => {
  const dispatch = useAppDispatch()
  const { formName, task, submit, close } = useTaskFormContext()
  const hasChanges = useHasChanges(formName)
  const { showTaskForm } = useShowTaskFormContext()

  const duplicateTask = React.useCallback(
    (original: Task) => {
      if (!original) {
        return
      }

      showTaskForm({
        defaultSpaceId: original.spaceId,
        formContext: 'duplicate',
        taskDefaults: taskUtils.copyTask(original),
      })
    },
    [showTaskForm],
  )

  const confirmSave = useActionsAsync<boolean, void>(duplicateOptions, duplicateActionArgs)

  const checkDuplicateTask = React.useCallback(async () => {
    if (!task) {
      return
    }

    if (!hasChanges) {
      close()
      duplicateTask(task)
      return
    }

    const saveAndDuplicate = await confirmSave()

    if (!saveAndDuplicate) {
      return
    }

    const ok = await submit()

    if (ok) {
      const updatedTask = dispatch(entityThunks.select(taskSelectors.byId, task.spaceId, task.id))
      duplicateTask(updatedTask || task)
    }
  }, [hasChanges, task, submit, duplicateTask, close, confirmSave, dispatch])

  return checkDuplicateTask
}
