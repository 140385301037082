import { dateUtils as D } from '@/core/utils'

export const getNextDate = (current: Date | null, next: Date | null): Date | null => {
  if (!next) {
    return null
  }

  if (current) {
    return next
  }

  const now = D.now()

  if (D.isSameDay(now, next)) {
    return D.matchTime(D.add(D.floor(now, 'hours'), 1, 'hour'), next)
  }

  return D.setTime(next, 9)
}
