import React from 'react'
import { StyleSheet, View } from 'react-native'
import { collectionUtils as C } from '@/core/utils'
import { Emoji, SelectEmoji } from '@/types/emoji'
import EmojiListItem from './EmojiListItem'

interface EmojiListRowProps {
  emojis: Emoji[]
  onSelect: SelectEmoji
  width: number
}

function EmojiListRow({ emojis, onSelect, width }: EmojiListRowProps) {
  return (
    <View style={styles.row}>
      {emojis.map(emoji => (
        <EmojiListItem key={emoji.symbol} emoji={emoji} onSelect={onSelect} />
      ))}
      {emojis.length >= width
        ? null
        : C.range(0, width - emojis.length).map(index => <EmojiListItem key={index} placeholder />)}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    flexDirection: 'row',
  },
})

export default React.memo(EmojiListRow)
