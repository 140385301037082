import React from 'react'
import { collectionUtils as C, messageUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { useTimeout } from '@/hooks/timeout'
import { channelThunks } from '@/thunks'
import { ChannelUser } from '@/types/entities'
import { ItemType, VisibleItemsChangedHandler } from './types'

export const useLastReadMessage = (channelUser: ChannelUser, delay = 300) => {
  const { channelId } = channelUser
  const dispatch = useAppDispatch()
  const { timeout } = useTimeout(delay)

  const minIdRef = React.useRef<number | undefined>(undefined)
  const maxIdRef = React.useRef<number>(-1)
  const [earliestViewedId, setEarliestViewedId] = React.useState<number | undefined>(undefined)
  const latestMarkedMessageIdRef = React.useRef(-1)

  const visibleItemsChanged = React.useCallback<VisibleItemsChangedHandler>(
    (visibleItems, firstUnreadMessageId) => {
      const bounds = getVisibleMessagesBounds(visibleItems)

      if (!bounds) {
        return
      }

      const { lbound, ubound } = bounds
      const minId = getMinId(lbound)
      const maxId = getMaxId(ubound)

      if (minIdRef.current === undefined || minIdRef.current > minId) {
        minIdRef.current = minId
        setEarliestViewedId(minIdRef.current)
      }

      if (maxIdRef.current === undefined || maxIdRef.current < maxId) {
        maxIdRef.current = maxId
      }

      if (firstUnreadMessageId === undefined) {
        return
      }

      // The user must have scrolled back to the first unread message
      const allUnreadHaveBeenViewed = minIdRef.current <= firstUnreadMessageId

      // We will not send an update on max read if the new value is less than what the server
      // recognizes as the max read
      const maxReadIsGreaterThanServer = maxIdRef.current > channelUser.maxReadMessageId

      // We will not send an update on max read if the new value is less than what the client
      // recognizes as max read. There is a time when the client and server disagree, when the]
      // client is sending an update to the server but it has completed yet.
      const maxReadIsGreaterThanClient = maxIdRef.current > latestMarkedMessageIdRef.current

      if (allUnreadHaveBeenViewed && maxReadIsGreaterThanServer && maxReadIsGreaterThanClient) {
        latestMarkedMessageIdRef.current = maxIdRef.current

        timeout(() => {
          dispatch(
            channelThunks.updateChannelUser(channelUser.spaceId, channelUser.channelId, null, {
              maxReadMessageId: latestMarkedMessageIdRef.current,
            }),
          )
        })
      }
    },
    [channelUser, dispatch, timeout],
  )

  // When the channel changes, reset things
  React.useEffect(() => {
    minIdRef.current = undefined
    maxIdRef.current = -1
    latestMarkedMessageIdRef.current = -1
    setEarliestViewedId(undefined)
  }, [channelId, setEarliestViewedId])

  return { earliestViewedId, visibleItemsChanged }
}

const getVisibleMessagesBounds = (
  viewableItems: ItemType[],
): { lbound: ItemType; ubound: ItemType } | null => {
  let lbound: ItemType | null = null
  let ubound: ItemType | null = null

  for (const item of viewableItems) {
    const { message } = item
    if (!messageUtils.isUnsentMessage(message)) {
      if (!lbound || lbound.message.id > message.id) {
        lbound = item
      }
      if (!ubound || ubound.message.id < message.id) {
        ubound = item
      }
    }
  }

  if (lbound && ubound) {
    return { lbound, ubound }
  }
  return null
}

const getMinId = (item: ItemType) => item.message.id

// If the last item is a task with comments, we want to mark the latest comment
const getMaxId = (item: ItemType) =>
  item.comments.length === 0 ? item.message.id : C.last(item.comments).id
