import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import { JoinSpaceScreenProps } from './types'

const LazyJoinSpaceScreen = React.lazy(() => import('./index'))

function JoinSpaceScreen(props: JoinSpaceScreenProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyJoinSpaceScreen {...props} />
    </Suspense>
  )
}

export default JoinSpaceScreen
