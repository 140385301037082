import React from 'react'
import { MessageActionsContextType } from '@/contexts/MessageActionsContext'
import { useActions } from '@/hooks/actions'
import { useMessageActions } from '@/hooks/messages/actions'
import { Message } from '@/types/entities'

const actionArgs = { title: 'Message Actions' }

export const useMessageActionsContextValue = (isDisplayedInThread: boolean) => {
  const {
    handleCopyText,
    handleDeleteMessage,
    handleEditMessage,
    handleShowThread,
    handleConvertToTask,
    messageActions,
  } = useMessageActions({ isDisplayedInThread })

  const handleShowMessageActions = useActions<Message>(messageActions, actionArgs)

  const messageActionsContextValue = React.useMemo<MessageActionsContextType>(
    () => ({
      convertToTask: handleConvertToTask,
      copyText: handleCopyText,
      deleteMessage: handleDeleteMessage,
      editMessage: handleEditMessage,
      isDisplayedInThread,
      showActions: handleShowMessageActions,
      showThread: handleShowThread,
    }),
    [
      handleConvertToTask,
      handleCopyText,
      handleDeleteMessage,
      handleEditMessage,
      handleShowMessageActions,
      handleShowThread,
      isDisplayedInThread,
    ],
  )

  return messageActionsContextValue
}
