import React from 'react'
import IconButton from '@/components/core/IconButton'
import Box from '@/components/layouts/Box'
import { useMessageActionsContext } from '@/contexts/MessageActionsContext'
import { useAmplitude } from '@/hooks'
import { useMessageReactions } from '@/hooks/messages/reactions'
import { Message } from '@/types/entities'

interface HoverMenuProps {
  message: Message
  isFromMe: boolean
  showConvertToTask?: boolean
  showMoreActions?: boolean
}

function HoverMenu({ message, isFromMe, showConvertToTask, showMoreActions }: HoverMenuProps) {
  const { showActions, convertToTask, showThread, isDisplayedInThread } = useMessageActionsContext()
  const { pickMessageReaction } = useMessageReactions()
  const handleShowActions = React.useCallback(() => showActions(message), [showActions, message])
  const handleConvertToTask = React.useCallback(
    () => convertToTask(message),
    [convertToTask, message],
  )
  const handleShowThread = React.useCallback(() => showThread(message), [showThread, message])
  const handleReactToMessage = React.useCallback(
    () => pickMessageReaction(message),
    [pickMessageReaction, message],
  )

  const wrappedHandleConvertToTask = useAmplitude(handleConvertToTask, 'convert message to task')

  return (
    <Box direction={isFromMe ? 'row' : 'row-reverse'}>
      {showMoreActions ? (
        <IconButton kind="transparentDark" name="ellipsis-h" onPress={handleShowActions} />
      ) : null}
      {showConvertToTask ? (
        <IconButton kind="transparentDark" name="shuffle" onPress={wrappedHandleConvertToTask} />
      ) : null}
      <IconButton kind="transparentDark" name="face-smile" onPress={handleReactToMessage} />
      {isDisplayedInThread ? null : (
        <IconButton kind="transparentDark" name="reply" onPress={handleShowThread} />
      )}
    </Box>
  )
}

export default React.memo(HoverMenu)
