import React from 'react'
import { collectionUtils as C, dateUtils as D } from '@/core/utils'

export const useCalendar = (givenDate: Date | null) => {
  const selectedDate = givenDate || D.now()
  const [focusedDate, setFocusedDate] = React.useState<Date>(D.copy(selectedDate))
  const calendarRows = React.useMemo(() => buildRows(focusedDate), [focusedDate])
  const goToPrevMonth = React.useCallback(
    () => setFocusedDate(date => D.add(date, -1, 'month')),
    [setFocusedDate],
  )
  const goToNextMonth = React.useCallback(
    () => setFocusedDate(date => D.add(date, 1, 'month')),
    [setFocusedDate],
  )

  return {
    calendarRows,
    focusedDate,
    goToNextMonth,
    goToPrevMonth,
  }
}

const buildRows = (focusedDate: Date): Date[][] => {
  const startOfMonth = D.floor(focusedDate, 'month')
  const startOfCalendar = D.floor(startOfMonth, 'week')

  const rows: Date[][] = []

  for (;;) {
    const startOfWeek = rows.length === 0 ? startOfCalendar : nextDate(rows)
    const row = buildRow(startOfWeek)
    rows.push(row)

    const last = row[6]

    if (last.getMonth() !== focusedDate.getMonth()) {
      break
    }
  }

  if (rows.length === 5) {
    rows.push(buildRow(nextDate(rows)))
  }

  return rows
}

const buildRow = (startOfWeek: Date): Date[] =>
  C.range(0, 7).map(weekday => D.add(startOfWeek, weekday, 'day'))

const nextDate = (rows: Date[][]): Date => D.add(C.last(C.last(rows)), 1, 'day')
