import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Invoice from '@/components/financials/Invoice'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { getInvoiceFromEstimate, removeEmmrePrefix } from '@/core/utils/chargebee'
import { centsToDollars } from '@/core/utils/numbers'
import { ChargebeeEstimate } from '@/types/chargebee'

interface ProductInformationCardProps {
  color?: ColorName
  isSelected: boolean
  estimate?: ChargebeeEstimate | null
  unitLabel: string
}

const radius = 8

function ProductInformationCard({
  estimate,
  color = 'emmre-blue',
  isSelected,
  unitLabel,
}: ProductInformationCardProps) {
  if (!estimate) {
    return null
  }

  const invoice = getInvoiceFromEstimate(estimate)
  const plan = invoice.lineItems[0]

  if (!plan) {
    return null
  }

  const unitAmountDollars = centsToDollars(plan.unitAmount)

  return (
    <Background borderColor={color} borderRadius={radius} borderWidth={2} width="100%">
      <Background color={color}>
        <Box gapHorizontal="large" gapVertical="medium">
          <Text color="ghost">{isSelected ? 'Current Plan' : 'Change Plan'}</Text>
        </Box>
      </Background>
      <Box gapAll="large">
        <Box direction="row" justifyContent="space-between">
          <Text size="h2" weight="medium">
            {removeEmmrePrefix(plan.description)}
          </Text>
          <Text size="h3" weight="medium">
            ${unitAmountDollars} <Text size="h4">{unitLabel}</Text>
          </Text>
        </Box>
        <Box flex={1} gapAll="medium">
          <Invoice color={color} invoice={invoice} mapDescription={removeEmmrePrefix} />
        </Box>
      </Box>
    </Background>
  )
}

export default React.memo(ProductInformationCard)
