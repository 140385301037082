import React from 'react'
import Checkbox, { CheckboxProps } from '@/components/core/Checkbox'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { formActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'

interface FormCheckboxProps extends Omit<CheckboxProps, 'checked' | 'onChange' | 'state'> {
  formName: string
  fieldName: string
}

function FormCheckbox({ formName, fieldName, ...checkboxProps }: FormCheckboxProps) {
  const dispatch = useAppDispatch()
  const field = useAppSelector(formSelectors.field, formName, fieldName)
  const handleChange = React.useCallback(
    (value: boolean) => {
      dispatch(formActions.setValue({ fieldName, formName, value }))
    },
    [fieldName, formName, dispatch],
  )

  return <Checkbox checked={field.value} onChange={handleChange} {...checkboxProps} />
}

export default React.memo(FormCheckbox)
