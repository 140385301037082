import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import CenteredLayout from '@/components/layouts/CenteredLayout'
import { dateUtils as D } from '@/core/utils'

interface RemindAtIndicatorProps {
  remindAt: Date | null
  onRemove: () => void
}

function RemindAtIndicator({ remindAt, onRemove }: RemindAtIndicatorProps) {
  const label = remindAt ? D.formatShort(remindAt) : 'No Reminder'
  return (
    <Box gapTop="medium">
      <Background borderRadius={10} color="heather">
        <CenteredLayout
          centerContent={<Text>{label}</Text>}
          leftContent={<Icon kind="solid" name="bell" size="h4" />}
          rightContent={
            remindAt ? (
              <BaseIconButton
                backgroundColor="anchor"
                color="heather"
                kind="solid"
                name="xmark"
                onPress={onRemove}
                padding={2}
                shape="circle"
                size="h5"
              />
            ) : null
          }
        />
      </Background>
    </Box>
  )
}

export default React.memo(RemindAtIndicator)
