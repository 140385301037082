import React from 'react'
import { SummaryActivityType } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'
import ActivityLabel from './ActivityLabel'
import ThreadIndicator from './ThreadIndicator'

interface SummaryActivityIndicatorProps {
  isFromMe: boolean
  item: MessageListItemData
}

function SummaryActivityIndicator({ isFromMe, item }: SummaryActivityIndicatorProps) {
  const { message } = item
  const { creatorId, spaceId, summaryActivityType } = message

  if (!summaryActivityType || summaryActivityType === 'COMMENT') {
    return <ThreadIndicator isFromMe={isFromMe} item={item} />
  }
  return (
    <ActivityLabel
      action={getActionDescription(summaryActivityType)}
      creatorId={creatorId}
      isFromMe={isFromMe}
      spaceId={spaceId}
    />
  )
}

const getActionDescription = (activityType: SummaryActivityType): string => {
  switch (activityType) {
    case 'PUBLISHED':
      return 'sent this Executive Brief'
    case 'COMPLETED':
      return 'completed this Executive Brief'
    case 'REVIEWED':
      return 'reviewed this Executive Brief'
    default:
      throw new Error(`Invalid task activity type: ${activityType}`)
  }
}

export default React.memo(SummaryActivityIndicator)
