import React from 'react'
import { disableChannels } from '@/core/constants/channels'
import { userUtils } from '@/core/utils'
import { useAppDispatch, useCachedSelector } from '@/hooks'
import { useJustYouHub } from '@/hooks/hubs'
import { entitySelectors } from '@/store/selectors'
import { channelThunks, notificationThunks } from '@/thunks'
import { Channel } from '@/types/entities'
import { CreateHubMode } from './types'

export const useCreateHubModal = (spaceId: number, isOpen: boolean) => {
  const justYouHub = useJustYouHub(spaceId)
  const noHubWithUsers = useCachedSelector(entitySelectors.notInHubWithUsersSelector, [spaceId])
  const me = useCachedSelector(entitySelectors.meSelector, [spaceId])

  const availableOptions = React.useMemo(() => {
    const options: CreateHubMode[] = ['INVITE_USER']

    if (!disableChannels && noHubWithUsers.length !== 0) {
      options.push('EXISTING_USER')
    }

    if (!!me && !userUtils.isExecutive(me.role) && !justYouHub) {
      options.push('JUST_YOU')
    }

    return options
  }, [justYouHub, noHubWithUsers, me])

  const defaultMode: CreateHubMode | null = availableOptions.length > 1 ? null : availableOptions[0]
  const [selectedCreateHubMode, setSelectedCreateHubMode] = React.useState<CreateHubMode | null>(
    defaultMode,
  )
  const [currentCreateHubMode, setCurrentCreateHubMode] = React.useState<CreateHubMode | null>(
    defaultMode,
  )

  const activateSelectedCreateHubMode = React.useCallback(() => {
    setCurrentCreateHubMode(selectedCreateHubMode)
  }, [selectedCreateHubMode])

  const resetToBeginning = React.useCallback(() => {
    setCurrentCreateHubMode(null)
  }, [])

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedCreateHubMode(defaultMode)
      setCurrentCreateHubMode(defaultMode)
    }
  }, [isOpen, setSelectedCreateHubMode, setCurrentCreateHubMode, defaultMode])

  return {
    activateSelectedCreateHubMode,
    availableOptions,
    currentCreateHubMode,
    noHubWithUsers,
    resetToBeginning,
    selectedCreateHubMode,
    setSelectedCreateHubMode,
  }
}

export const useCreateHub = (
  spaceId: number,
  onClose: () => void,
  onCreated: (channel: Channel) => void,
) => {
  const dispatch = useAppDispatch()
  const [isCreating, setIsCreating] = React.useState(false)

  const create = React.useCallback(
    async (userId: number | null, setAsDefault: boolean) => {
      setIsCreating(true)
      const response = await dispatch(
        channelThunks.createChannel(spaceId, {
          channelType: 'HUB',
          name: null,
          userIds: userId ? [userId] : [],
        }),
      )
      setIsCreating(false)

      if (response.ok) {
        const { channel } = response.data

        if (setAsDefault) {
          dispatch(channelThunks.setDefaultChannel(spaceId, channel.id))
        }
        onCreated(channel)
        onClose()
      } else {
        dispatch(
          notificationThunks.showToast({
            title: 'An error occurred when creating the hub',
            type: 'error',
          }),
        )
      }
    },
    [onClose, onCreated, setIsCreating, spaceId, dispatch],
  )

  return { create, isCreating }
}
