import React from 'react'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAppDispatch } from '@/hooks'
import { formSelectors, taskSelectors } from '@/store/selectors'
import { entityThunks, taskThunks } from '@/thunks'
import { Task } from '@/types/entities'
import { TaskType } from '@/types/tasks'

type CheckNotifyArgs =
  | { spaceId: number; taskId: number; changes: Partial<Task> }
  | { spaceId: number; taskId: number; formName: string }
  | { task: Task; changes: Partial<Task> }
  | { task: Task; formName: string }
  | { spaceId: number; channelId: number; taskType: TaskType }

export const useCheckNotify = () => {
  const showAlert = useAlertContext()
  const dispatch = useAppDispatch()

  return React.useCallback(
    async (args: CheckNotifyArgs) => {
      if ('channelId' in args) {
        return dispatch(
          taskThunks.checkNotifyCreate(showAlert, args.spaceId, args.channelId, args.taskType),
        )
      }

      const task =
        'task' in args
          ? args.task
          : dispatch(entityThunks.select(taskSelectors.byId, args.spaceId, args.taskId))
      const changes =
        'changes' in args
          ? args.changes
          : dispatch(entityThunks.select(formSelectors.values, args.formName))

      if (!task) {
        return 'NOTIFY'
      }

      return dispatch(taskThunks.checkNotifyUpdate(showAlert, task, changes))
    },
    [dispatch, showAlert],
  )
}
