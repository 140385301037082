import React from 'react'
import { SwipeableItemClose } from './types'

export const useSwipeableItem = (
  itemRefs: React.MutableRefObject<Map<string, any>>,
  itemKey: string,
) => {
  const close = React.useCallback<SwipeableItemClose>(
    mode => {
      if (mode === 'self') {
        itemRefs.current.get(itemKey)?.close()
      } else {
        itemRefs.current.forEach((value, key) => {
          if (value && (mode === 'all' || key !== itemKey)) {
            value.close()
          }
        })
      }
    },
    [itemKey, itemRefs],
  )

  const setRef = React.useCallback<React.RefCallback<any>>(
    ref => {
      if (ref && !itemRefs.current.get(itemKey)) {
        itemRefs.current.set(itemKey, ref)
      }
    },
    [itemRefs, itemKey],
  )

  return { close, setRef }
}
