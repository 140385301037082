import { RequestThunk } from '@/api/call'
import { boardAPI } from '@/api/requests'
import { boardActions } from '@/store/actions'
import { buildBoardId } from '@/store/entityIds'
import { getAllUpdatedTasks } from './tasks'
import { makeEnhancedRequest } from './utils'

export const createBoard =
  (
    spaceId: number,
    channelId: number,
    board: boardAPI.CreateBoard,
  ): RequestThunk<typeof boardAPI.createBoard> =>
  async dispatch => {
    const request = boardAPI.createBoard(spaceId, channelId, board)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(boardActions.upsertOne(response.data.board))
    }
    return response
  }

export const updateBoard =
  (
    spaceId: number,
    boardId: number,
    board: boardAPI.UpdateBoard,
  ): RequestThunk<typeof boardAPI.updateBoard> =>
  async dispatch => {
    const request = boardAPI.updateBoard(spaceId, boardId, board)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(boardActions.upsertOne(response.data.board))
    }

    return response
  }

export const deleteBoard =
  (spaceId: number, boardId: number): RequestThunk<typeof boardAPI.deleteBoard> =>
  async dispatch => {
    const request = boardAPI.deleteBoard(spaceId, boardId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(boardActions.removeOne(buildBoardId(spaceId, boardId)))
      dispatch(getAllUpdatedTasks(spaceId))
    }

    return response
  }
