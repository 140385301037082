import React from 'react'
import CachedVideo, { CachedVideoProps } from '@/components/core/CachedVideo'
import Touchable from '@/components/core/Touchable'
import { useFileActionsContext } from '@/contexts/FileActionsContext'
import { useFullScreenMediaContext } from '@/contexts/FullScreenMediaContext'
import { File, Message } from '@/types/entities'

interface ActionableVideoProps extends Omit<CachedVideoProps, 'useNativeControls'> {
  playVideoInline?: boolean
  message?: Message
  file?: File
}

const duration = 'short'

function ActionableVideo({
  playVideoInline = true,
  message,
  file,
  ...cachedVideoProps
}: ActionableVideoProps) {
  const { uri } = cachedVideoProps
  const showFileActions = useFileActionsContext()
  const showMedia = useFullScreenMediaContext()

  const handleSharing = React.useCallback(async () => {
    if (uri) {
      showFileActions({ duration, uri })
    }
  }, [showFileActions, uri])
  const handleShowVideo = React.useCallback(
    () => showMedia({ duration, file, mediaType: 'VIDEO', message, uri }),
    [showMedia, uri, message, file],
  )

  return (
    <Touchable
      feedback="opacity"
      onLongPress={handleSharing}
      onPress={playVideoInline ? undefined : handleShowVideo}
    >
      <CachedVideo {...cachedVideoProps} useNativeControls={playVideoInline} />
    </Touchable>
  )
}

export default React.memo(ActionableVideo)
