import React from 'react'
import { useWindowDimensions } from 'react-native'
import Background from '@/components/core/Background'
import { ColorName } from '@/core/constants/colors'
import Box from './Box'

interface CenterWideContainerProps {
  backgroundColor?: ColorName
  children: React.ReactNode
  highlightContent?: boolean
  maxWidth?: number
}

function CenterWideContainer({
  backgroundColor = 'silver',
  children,
  highlightContent = false,
  maxWidth = 600,
}: CenterWideContainerProps) {
  const { width } = useWindowDimensions()

  if (width <= maxWidth) {
    return <>{children}</> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <Box direction="row" flex={1}>
      <Background
        borderRightColor="heather"
        borderRightWidth={highlightContent ? 1 : 0}
        color={highlightContent ? backgroundColor : 'transparent'}
        flex={1}
      />
      <Box maxWidth={maxWidth} width={maxWidth}>
        {children}
      </Box>
      <Background
        borderLeftColor="heather"
        borderLeftWidth={highlightContent ? 1 : 0}
        color={highlightContent ? backgroundColor : 'transparent'}
        flex={1}
      />
    </Box>
  )
}

export default React.memo(CenterWideContainer)
