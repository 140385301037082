import { RequestThunk } from '@/api/call'
import * as authAPI from '@/api/requests/auth'
import { amplitudeProxy } from '@/core/instrumentation'
import { authActions, formActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'
import { ErrorResponse, SuccessResponse } from '@/types/api'
import { Thunk } from '@/types/store'
import { setErrors } from '../errors'
import { makeEnhancedRequest } from '../utils'

export type AuthResponse<R, I> =
  | {
      ok: true
      response: SuccessResponse<R>
      initializationResult: I
    }
  | {
      ok: false
      response: ErrorResponse
    }
export type AuthInitializer<T> = () => Thunk<T>

export const signInForm =
  <I>(
    formName: string,
    onInitialize: AuthInitializer<I>,
  ): Thunk<AuthResponse<authAPI.AccessResponse, I>> =>
  async (dispatch, getState) => {
    const { emailAddress, password } = formSelectors.values(getState(), formName)
    const result = await dispatch(signInInitialized({ emailAddress, password }, onInitialize))

    if (result.ok) {
      dispatch(formActions.clear(formName))
      return result
    }

    dispatch(setErrors(formName, result.response.errors, 'Error Signing In'))

    return result
  }

export const signInUninitialized =
  (signInArgs: authAPI.SignInArgs): RequestThunk<typeof authAPI.signIn> =>
  async dispatch => {
    const request = authAPI.signIn(signInArgs)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { accessToken, expiresAt, refreshToken, user, scopes } = response.data
      dispatch(authActions.setAuth({ accessToken, expiresAt, refreshToken, scopes, user }))
    }
    return response
  }

const signInInitialized =
  <I>(
    signInArgs: authAPI.SignInArgs,
    onInitialize: AuthInitializer<I>,
  ): Thunk<AuthResponse<authAPI.AccessResponse, I>> =>
  async dispatch => {
    const request = authAPI.signIn(signInArgs)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const initializationResult = await dispatch(handleSignIn(response.data, onInitialize))

      await amplitudeProxy.setUser(response.data.user)
      amplitudeProxy.logEvent('sign in to emmre')

      return { initializationResult, ok: true, response }
    }

    return { ok: false, response }
  }

export const handleSignIn =
  <I>(access: authAPI.AccessResponse, onInitialize: AuthInitializer<I>): Thunk<I> =>
  async dispatch => {
    const { accessToken, expiresAt, refreshToken, user, scopes } = access
    dispatch(authActions.setAuth({ accessToken, expiresAt, refreshToken, scopes, user }))
    const result = await dispatch(onInitialize())
    dispatch(authActions.setInitialized())
    return result
  }
