import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import SpacePicture from '@/components/spaces/SpacePicture'
import { typeUtils } from '@/core/utils'
import { useSpace } from '@/hooks/spaces'
import CenteredHeader from './CenteredHeader'
import SpaceBackButton from './SpaceBackButton'
import { Theme } from './types'

interface StackHeader {
  onBack?: () => void
  onPress?: () => void
  rightContent?: React.ReactNode
  currentChannelId?: number
  spaceId?: number
  theme?: Theme
  title: string | React.ReactNode
  sideWidth?: number
  maxContentWidth?: number
}

function StackHeader({
  currentChannelId,
  onBack,
  onPress,
  rightContent,
  sideWidth,
  spaceId,
  theme = 'white',
  title,
  maxContentWidth,
}: StackHeader) {
  const space = useSpace(spaceId || 0)

  const iconColor = theme === 'white' ? 'emmre-blue' : 'ghost'
  const textColor = theme === 'white' ? 'obsidian' : 'ghost'

  return (
    <CenteredHeader
      centerContent={
        <Touchable feedback="opacity" onPress={onPress}>
          {typeUtils.isString(title) ? (
            <Text
              align="center"
              color={textColor}
              ellipsizeMode="tail"
              numberOfLines={1}
              size={spaceId ? 'h4' : 'h3'}
              weight="medium"
            >
              {title}
            </Text>
          ) : (
            title
          )}
          {space ? (
            <Box alignItems="center" direction="row" gapTop="small" justifyContent="center">
              <SpacePicture size="xsmall" space={space} />
              <Box gapLeft="small">
                <Text color={textColor} size="h5">
                  {space.name || ''}
                </Text>
              </Box>
            </Box>
          ) : null}
        </Touchable>
      }
      leftContent={
        onBack ? (
          <SpaceBackButton
            channelId={currentChannelId}
            color={iconColor}
            onBack={onBack}
            spaceId={spaceId}
          />
        ) : null
      }
      maxContentWidth={maxContentWidth}
      rightContent={rightContent}
      sideWidth={sideWidth}
      theme={theme}
    />
  )
}

export default React.memo(StackHeader)
