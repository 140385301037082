import React from 'react'
import { ScrollView } from 'react-native'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormField } from '@/components/tasks/TaskForm/hooks'
import { collectionUtils as C } from '@/core/utils'
import * as dateUtils from '@/core/utils/dates'
import { ChecklistItem } from '@/types/entities'

interface ChecklistState {
  items: ChecklistItem[]
}

const emptyItem: ChecklistItem = { completed: false, description: '' }

type ChecklistAction =
  | { type: 'addItem' }
  | { type: 'removeItem'; payload: { index: number } }
  | { type: 'updateItem'; payload: { index: number; item: ChecklistItem } }
  | { type: 'setItems'; payload: { items: ChecklistItem[] } }

const checklistReducer = (state: ChecklistState, action: ChecklistAction): ChecklistState => {
  const { type } = action

  if (type === 'addItem') {
    const clientTemporaryId = dateUtils.nowMilliseconds().toString()
    const items = [...state.items, { ...emptyItem, clientTemporaryId }]
    return { ...state, items }
  }

  if (type === 'removeItem') {
    const { index } = action.payload
    const items = C.removeAt(index, state.items)
    return { ...state, items }
  }

  if (type === 'updateItem') {
    const { index, item } = action.payload
    const items = C.replaceAt(index, item, state.items)
    return { ...state, items }
  }

  if (type === 'setItems') {
    const { items } = action.payload
    return { ...state, items }
  }

  return state
}

export const useChecklist = (onClose: (cancelled: boolean) => void, isOpen: boolean) => {
  const { value: checklistItems, setValue: setChecklistItems } = useTaskFormField<
    ChecklistItem[] | null
  >(fieldNames.checklistItems)

  const [{ items }, dispatch] = React.useReducer(checklistReducer, { items: checklistItems || [] })

  const handleCancel = React.useCallback(() => {
    dispatch({ payload: { items: checklistItems || [] }, type: 'setItems' })
    onClose(true)
  }, [dispatch, checklistItems, onClose])

  const handleDone = React.useCallback(() => {
    const notEmpty = items
      .map(item => ({ ...item, description: item.description ? item.description.trim() : '' }))
      .filter(item => !!item.description)
    setChecklistItems(notEmpty)
    dispatch({ payload: { items: notEmpty }, type: 'setItems' })
    onClose(false)
  }, [items, setChecklistItems, onClose])

  const addItem = React.useCallback(() => dispatch({ type: 'addItem' }), [dispatch])
  const itemAddedRef = React.useRef(false)
  const handleAddItem = React.useCallback(() => {
    itemAddedRef.current = true
    addItem()
    setTimeout(() => scrollViewRef.current?.scrollToEnd())
  }, [addItem])
  const scrollViewRef = React.useRef<ScrollView | null>(null)

  const handleRemoveItem = React.useCallback(
    (index: number) => dispatch({ payload: { index }, type: 'removeItem' }),
    [dispatch],
  )

  const handleUpdateItem = React.useCallback(
    (index: number, item: ChecklistItem) =>
      dispatch({ payload: { index, item }, type: 'updateItem' }),
    [dispatch],
  )

  React.useEffect(() => {
    if (!isOpen) {
      dispatch({
        payload: {
          items: !checklistItems || checklistItems.length === 0 ? [emptyItem] : checklistItems,
        },
        type: 'setItems',
      })
    }
  }, [dispatch, checklistItems, isOpen])

  return {
    handleAddItem,
    handleCancel,
    handleDone,
    handleRemoveItem,
    handleUpdateItem,
    items,
    scrollViewRef,
  }
}
