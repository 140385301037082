import { Request } from '@/api/call'
import { ReceivedMessage, ReceivedTask } from '@/types/apiEntities'
import { ChannelUser, QuestionResponseType, Task } from '@/types/entities'
import { TaskNotify } from '@/types/tasks'

interface TaskResponse {
  message: ReceivedMessage
  task: ReceivedTask
}

export interface CreateTaskResponse extends TaskResponse {
  channelUser: ChannelUser
  comments?: ReceivedMessage[]
}

export interface UpdateTaskResponse extends TaskResponse {
  activityMessages?: ReceivedMessage[] | null
  channelUser?: ChannelUser
  nextTask?: ReceivedTask
}

export interface TasksResponse {
  tasks: ReceivedTask[]
}

type QuestionFields = {
  responseType: QuestionResponseType
  isOpenEnded: boolean
  responses: { content: string }[]
}

export interface UpdateTask
  extends Pick<
    Partial<Task>,
    | 'acceptedAt'
    | 'assignedUserId'
    | 'channelId'
    | 'completedAt'
    | 'content'
    | 'dueDate'
    | 'dueDateType'
    | 'notes'
    | 'boardId'
    | 'recurrenceRule'
    | 'remindAt'
    | 'remindAtOffset'
    | 'tagIds'
    | 'urgency'
    | 'checklistItems'
  > {
  fileIds?: number[]
  question?: QuestionFields
}

interface CreateTaskProps extends Omit<UpdateTask, 'channelId' | 'completedAt' | 'question'> {
  question?: QuestionFields
}

export interface CreateTask extends CreateTaskProps {
  comments?: string[]
}

export const updateTask = (
  spaceId: number,
  taskId: number,
  props: UpdateTask,
  notify: TaskNotify,
) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    body: props,
    method: 'PUT',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/tasks/${taskId}`,
  })

export const acceptTask = (spaceId: number, taskId: number, notify: TaskNotify) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    method: 'POST',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/tasks/${taskId}/accept`,
  })

export const unacceptTask = (spaceId: number, taskId: number, notify: TaskNotify) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/tasks/${taskId}/accept`,
  })

export const completeTask = (spaceId: number, taskId: number, notify: TaskNotify) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    method: 'POST',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/tasks/${taskId}/complete`,
  })

export const uncompleteTask = (spaceId: number, taskId: number, notify: TaskNotify) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/tasks/${taskId}/complete`,
  })

export const createTask = (
  spaceId: number,
  channelId: number,
  props: CreateTask,
  notify: TaskNotify,
) =>
  new Request<CreateTaskResponse>({
    authentication: 'PRIVATE',
    body: props,
    method: 'POST',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/channels/${channelId}/tasks`,
  })

export const createTaskFromMessage = (
  spaceId: number,
  messageId: number,
  props: CreateTask,
  notify: TaskNotify,
) =>
  new Request<CreateTaskResponse>({
    authentication: 'PRIVATE',
    body: props,
    method: 'POST',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/messages/${messageId}/task`,
  })

export interface GetTasksArgs {
  updatedSince?: string | null
  limit?: number
  beforeTaskId?: number | null
}

export const getTasks = (
  spaceId: number,
  { limit = 100, updatedSince, beforeTaskId }: GetTasksArgs,
) =>
  new Request<TasksResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    query: [
      ['updatedSince', updatedSince],
      ['beforeTaskId', beforeTaskId],
      ['limit', limit],
    ],
    url: `v1/spaces/${spaceId}/tasks`,
  })

export const deleteTask = (spaceId: number, taskId: number, notify: TaskNotify) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    query: [['silent', notify === 'SILENT']],
    url: `v1/spaces/${spaceId}/tasks/${taskId}`,
  })

export const setChecklistItemCompleted = (
  spaceId: number,
  checklistItemId: number,
  isCompleted: boolean,
) =>
  new Request<UpdateTaskResponse>({
    authentication: 'PRIVATE',
    body: { isCompleted },
    method: 'PUT',
    url: `v1/spaces/${spaceId}/checklist-items/${checklistItemId}/completed`,
  })
