import { versionAPI } from '@/api/requests'
import { dateUtils as D, semverUtils } from '@/core/utils'
import { notificationActions } from '@/store/actions'
import { SemVer } from '@/types/semver'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

const getAvailableVersion = (): Thunk<SemVer | null> => async dispatch => {
  const request = versionAPI.getAvailableVersion(D.nowSeconds())
  const response = await dispatch(makeEnhancedRequest(request))

  if (response.ok) {
    return semverUtils.tryParse(response.data.build)
  }

  return null
}

export const checkWebVersion = (): Thunk<boolean> => async dispatch => {
  const availableVersion = await dispatch(getAvailableVersion())
  const currentVersion = semverUtils.currentVersion()

  if (!availableVersion) {
    return false
  }

  if (!currentVersion) {
    return false
  }

  const comp = semverUtils.compare(availableVersion, currentVersion)

  if (comp > 0) {
    dispatch(
      notificationActions.push({
        body: 'An updated version of Emmre is available. Refresh your browser to get the latest version.',
        duration: D.toMilliseconds.seconds(10),
        title: 'Updated Version Available',
        type: 'info',
      }),
    )
    return true
  }

  return false
}
