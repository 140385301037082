import React from 'react'
import Button from '@/components/core/Button'
import Box from '@/components/layouts/Box'
import { BaseUsersSelector } from '@/components/users/UsersSelector'
import { Channel, SpaceUser } from '@/types/entities'
import { useCreateHub } from './hooks'

interface CreateHubWithExistingUserContentProps {
  spaceId: number
  users: SpaceUser[]
  onClose: () => void
  onCreated: (channel: Channel) => void
}

function CreateHubWithExistingUserContent({
  spaceId,
  users,
  onClose,
  onCreated,
}: CreateHubWithExistingUserContentProps) {
  const [selectedUserId, setSelectedUserId] = React.useState<number | null>(null)
  const { isCreating, create } = useCreateHub(spaceId, onClose, onCreated)

  const handleSetSelection = React.useCallback(
    (user: SpaceUser, selected: boolean) => {
      if (selected) {
        setSelectedUserId(user.id)
      } else {
        setSelectedUserId(null)
      }
    },
    [setSelectedUserId],
  )
  const handleCreate = React.useCallback(async () => {
    if (selectedUserId) {
      create(selectedUserId, false)
    }
  }, [selectedUserId, create])

  const disabled = !selectedUserId || isCreating

  return (
    <>
      <BaseUsersSelector
        onSetSelection={handleSetSelection}
        selectedUserIds={React.useMemo(
          () => (selectedUserId ? [selectedUserId] : null),
          [selectedUserId],
        )}
        users={users}
      />
      <Box alignItems="center" gapAll="large">
        <Button disabled={disabled} kind="primary" label="Create Hub" onPress={handleCreate} />
      </Box>
    </>
  )
}

export default React.memo(CreateHubWithExistingUserContent)
