import React from 'react'
import ExternalLink from '@/components/core/ExternalLink'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'

function TaskSlackLink() {
  const { task } = useTaskFormContext()

  if (!(task && task.slackMessagePermalink)) {
    return null
  }

  return (
    <Box alignItems="center" direction="row" gapAll="medium">
      <Icon color="slack" kind="brands" name="slack" size="h4" />
      <Box gapLeft="medium">
        <ExternalLink label="Open Slack Conversation" url={task.slackMessagePermalink} />
      </Box>
    </Box>
  )
}

export default React.memo(TaskSlackLink)
