import { isString } from './types'

export const zeroPad = (n: number, length = 2): string => {
  let output = n.toString()
  while (output.length < length) {
    output = `0${output}`
  }
  return output
}

export const toInt = (val: string | number | null | undefined): number | null => {
  if (val === null || val === undefined) {
    return null
  }

  if (isString(val)) {
    const parsed = parseInt(val, 10)
    if (Number.isNaN(parsed)) {
      return null
    }
    return parsed
  }
  return val
}

export const toMaxString = (val: number, max: number): string => {
  if (val > max) {
    return `${max}+`
  }
  return val.toString()
}

export const clamp = (minVal: number, maxVal: number, val: number) => {
  if (val < minVal) {
    return minVal
  }
  if (val > maxVal) {
    return maxVal
  }
  return val
}

export const distance = (start: number, end: number) => Math.abs(end - start)

export const centsToDollars = (value: number) => {
  const totalCents = Math.ceil(value)
  const dollars = Math.floor(totalCents / 100)
  const cents = totalCents % 100

  if (cents === 0) {
    return `${dollars}`
  }

  return `${dollars}.${zeroPad(cents, 2)}`
}

export const randBetween = (min: number, max: number) => {
  const rand = Math.random()

  // +1 because we need to go above the max value, but rand is an exclusive upper bound
  // E.G. randBetween(0, 10) gives a range of 11, so the scaled value will be in the
  // range [0, 11). And the floored value will be in {0, 1, ..., 10}
  const scaled = (max - min + 1) * rand + min

  return Math.floor(scaled)
}
