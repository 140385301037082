import React from 'react'
import { View } from 'react-native'
import { useKeyboardVisibilityChange } from '@/hooks/keyboard'
import { Measure } from '@/types/components'
import BaseIconButton, { BaseIconButtonProps } from './BaseIconButton'

interface MeasuredIconButtonProps extends Omit<BaseIconButtonProps, 'onPress'> {
  onPress: (measure: Measure) => void
  onLayoutChange?: (measure: Measure) => void
}

function MeasuredIconButton({ onLayoutChange, onPress, ...buttonProps }: MeasuredIconButtonProps) {
  const containerRef = React.useRef<View | null>(null)
  const handleMeasure = React.useCallback(() => {
    if (onLayoutChange) {
      containerRef.current?.measure((x, y, width, height, pageX, pageY) => {
        onLayoutChange({ height, width, x: pageX, y: pageY })
      })
    }
  }, [onLayoutChange])
  const handlePress = React.useCallback(() => {
    containerRef.current?.measure((x, y, width, height, pageX, pageY) => {
      onPress({ height, width, x: pageX, y: pageY })
    })
  }, [onPress])
  useKeyboardVisibilityChange(handleMeasure)

  return (
    <View ref={containerRef} renderToHardwareTextureAndroid>
      <BaseIconButton {...buttonProps} onPress={handlePress} />
    </View>
  )
}

export default React.memo(MeasuredIconButton)
