export const fieldNames = {
  acceptedAt: 'acceptedAt',
  assignedUserId: 'assignedUserId',
  attachmentIds: 'attachmentIds',
  boardId: 'boardId',
  channelId: 'channelId',
  checklistItems: 'checklistItems',
  comment: 'comment',
  completedAt: 'completedAt',
  content: 'content',
  dueDate: 'dueDate',
  dueDateType: 'dueDateType',
  notes: 'notes',
  recurrenceRule: 'recurrenceRule',
  remindAt: 'remindAt',
  remindAtOffset: 'remindAtOffset',
  spaceId: 'spaceId',
  tagIds: 'tagIds',
  urgency: 'urgency',
}

export const leftIconWidth = 30
