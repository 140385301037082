import React from 'react'
import { StyleSheet } from 'react-native'
import Hoverable from '@/components/core/Hoverable'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import TabbedContainer, { TabbedContainerProps } from '@/components/messages/TabbedContainer'
import { useMessagesListContext } from '@/contexts/MessagesListContext'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { platformUtils, taskUtils } from '@/core/utils'
import { Task } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'
import HoverMenu from './MessageContent/HoverMenu'
import MessageReactionsList from './MessageReactionsList'

interface TaskContainerProps extends Omit<TabbedContainerProps, 'hasOverlay'> {
  disabled?: boolean
  onPress?: () => void
  item: MessageListItemData
  task: Task
  isFromMe: boolean
}

const { isWeb } = platformUtils

function TaskContainer({
  disabled = false,
  onPress,
  children,
  item,
  task,
  ...tabbedContainerProps
}: TaskContainerProps) {
  const { message } = item
  const { isFromMe } = tabbedContainerProps
  const { spaceId, id: taskId } = task!
  const { showTask } = useMessagesListContext()
  const modalPresenterContext = useModalPresenterContext()
  const handleOpenTask = React.useCallback(
    () => showTask(spaceId, taskId),
    [showTask, spaceId, taskId],
  )

  const handleLongPress = React.useCallback(
    () => modalPresenterContext.push('messageActions', { item }),
    [modalPresenterContext, item],
  )

  const isDeletedOrMoved = !!task && (taskUtils.isDeleted(task) || taskUtils.isMoved(task))
  const justifyContent = isFromMe ? 'flex-end' : 'flex-start'

  return (
    <Hoverable>
      {hovering => (
        <Box alignItems="center" direction="row" justifyContent={justifyContent}>
          {hovering && isFromMe && !disabled ? (
            <HoverMenu isFromMe={false} message={item.message} />
          ) : null}
          <Touchable
            disabled={disabled || isDeletedOrMoved}
            feedback="opacity"
            onLongPress={isWeb ? undefined : handleLongPress}
            onPress={onPress || handleOpenTask}
            style={styles.touchable}
          >
            <>
              <TabbedContainer {...tabbedContainerProps} hasOverlay={isDeletedOrMoved}>
                {children}
              </TabbedContainer>
              {message.reactions ? (
                <FloatContainer
                  left={isFromMe ? -7 : undefined}
                  right={isFromMe ? undefined : -7}
                  top={-14}
                >
                  <MessageReactionsList message={message} />
                </FloatContainer>
              ) : null}
            </>
          </Touchable>
          {hovering && !isFromMe && !disabled ? (
            <HoverMenu isFromMe={false} message={item.message} />
          ) : null}
        </Box>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  touchable: {
    flex: 1,
    flexDirection: 'row',
    maxWidth: platformUtils.isWeb ? 'min(768px, calc(100% - 64px))' : '80%',
  },
})

export default React.memo(TaskContainer)
