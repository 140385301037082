import React from 'react'
import { StyleSheet } from 'react-native'
import { IconKind } from '@/core/constants/icons'
import { getFontSize } from '@/core/constants/typography'
import { FontSize } from '@/types/typography'
import BaseIconButton, { BaseIconButtonProps } from './BaseIconButton'
import { ButtonKind, colorMap } from './Button'
import Text from './Text'
import Touchable from './Touchable'

const getIconPadding = (size: FontSize | number) => {
  if (size === 'h2') {
    return 10
  }
  if (size === 'h4') {
    return 8
  }
  if (size === 'h5') {
    return 6
  }
  if (size === 'h6') {
    return 4
  }
  return getFontSize(size) / 2
}

interface IconButtonProps extends Omit<BaseIconButtonProps, 'backgroundColor' | 'color' | 'kind'> {
  iconKind?: IconKind
  kind?: ButtonKind
  label?: string
}

function IconButton({
  disabled = false,
  iconKind = 'regular',
  kind = 'primary',
  shape = 'circle',
  size = 'h4',
  label,
  onPress,
  ...baseIconButtonProps
}: IconButtonProps) {
  const { foreground, background } = colorMap[kind][disabled ? 'disabled' : 'enabled']
  const padding = getIconPadding(size)

  const button = (
    <BaseIconButton
      backgroundColor={background}
      color={foreground}
      disabled={disabled}
      kind={iconKind}
      onPress={label ? undefined : onPress}
      padding={padding}
      shape={shape}
      size={size}
      {...baseIconButtonProps}
    />
  )

  if (label) {
    return (
      <Touchable feedback="opacity" onPress={onPress} style={styles.container}>
        {button}
        <Text align="center" size="h5">
          {label}
        </Text>
      </Touchable>
    )
  }

  return button
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default React.memo(IconButton)
