import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import SwipeableItem from 'react-native-swipeable-item'
import ListItemOverlay from './ListItemOverlay'
import ListItemUnderlay from './ListItemUnderlay'
import { useSwipeableItem } from './hooks'
import { ListItemAction, UnderlayButtonType } from './types'

export interface ActionableListItemProps<S> {
  actions: ListItemAction<S>[]
  children: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
  subjectKey: string
  itemRefs: React.MutableRefObject<Map<string, any>>
  itemStyle?: StyleProp<ViewStyle>
  onLongPress?: (subject: S) => void
  onPress?: (subject: S) => void
  subject: S
  buttonType: UnderlayButtonType
  buttonWidth: number
}

// React Native Debugger fails when reanimated 2 is called (which it does with swiping)
// so if I need to debug something on a screen that swipable items I can disable to avoid
// this
const DISABLE_SWIPE = false

function ActionableListItem<S>({
  actions: allActions,
  children,
  containerStyle,
  itemStyle,
  onLongPress,
  onPress,
  subject,
  subjectKey,
  itemRefs,
  buttonType,
  buttonWidth,
}: ActionableListItemProps<S>) {
  const { close, setRef } = useSwipeableItem(itemRefs, subjectKey)
  const actions = React.useMemo(
    () =>
      allActions.filter(
        action => action.type !== 'cancel' && (!action.isAvailable || action.isAvailable(subject)),
      ),
    [allActions, subject],
  )
  const snapPointsLeft = React.useMemo(
    () => [buttonWidth * actions.length],
    [buttonWidth, actions.length],
  )

  if (DISABLE_SWIPE) {
    return (
      <ListItemOverlay
        close={close}
        containerStyle={containerStyle}
        itemStyle={itemStyle}
        onLongPress={onLongPress}
        onPress={onPress}
        subject={subject}
      >
        {children}
      </ListItemOverlay>
    )
  }

  return (
    <SwipeableItem
      ref={setRef}
      item={subject}
      renderOverlay={({ percentOpenLeft, percentOpenRight }) => (
        <ListItemOverlay
          close={close}
          containerStyle={containerStyle}
          itemStyle={itemStyle}
          onLongPress={onLongPress}
          onPress={onPress}
          percentOpenLeft={percentOpenLeft}
          percentOpenRight={percentOpenRight}
          subject={subject}
        >
          {children}
        </ListItemOverlay>
      )}
      renderUnderlayLeft={() => (
        <ListItemUnderlay
          actions={actions}
          buttonType={buttonType}
          buttonWidth={buttonWidth}
          subject={subject}
        />
      )}
      snapPointsLeft={snapPointsLeft}
    />
  )
}

export default React.memo(ActionableListItem) as typeof ActionableListItem
