import { Task } from '@/types/entities'
import { DateRange, FutureDate, PastDate } from '@/types/tasks'
import * as D from '../dates'

export const getFutureDateBounds = (
  futureDate: FutureDate,
  relative: Date | null = null,
): DateRange => {
  switch (futureDate) {
    case 'PAST':
      return bounds(null, endOfDay(-1, relative))
    case 'TODAY':
      return bounds(startOfDay(0, relative), endOfDay(0, relative))
    case 'TOMORROW':
      return bounds(startOfDay(1, relative), endOfDay(1, relative))
    case 'UPCOMING':
      return bounds(startOfDay(2, relative), endOfDay(8, relative))
    case 'FUTURE':
      return bounds(startOfDay(9, relative), null)
    default:
      throw new Error(`Invalid future date value: ${futureDate}`)
  }
}

export const getPastDateBounds = (pastDate: PastDate, relative: Date | null = null): DateRange => {
  switch (pastDate) {
    case 'EARLIER':
      return bounds(null, endOfDay(-9, relative))
    case 'PREVIOUS':
      return bounds(startOfDay(-8, relative), endOfDay(-2, relative))
    case 'YESTERDAY':
      return bounds(startOfDay(-1, relative), endOfDay(-1, relative))
    case 'TODAY':
      return bounds(startOfDay(0, relative), endOfDay(0, relative))
    case 'FUTURE':
      return bounds(startOfDay(1, relative), null)
    default:
      throw new Error(`Invalid past date value: ${pastDate}`)
  }
}

const bounds = (lbound: string | null, ubound: string | null): DateRange => ({
  lbound,
  ubound,
})

const endOfDay = (offset: number, basis: Date | null = null) =>
  D.formatISO(D.ceil(D.add(basis || D.now(), offset, 'days'), 'day'))
const startOfDay = (offset: number, basis: Date | null = null) =>
  D.formatISO(D.floor(D.add(basis || D.now(), offset, 'days'), 'day'))

const isInRange = (range: DateRange, date: string) => {
  const { lbound, ubound } = range
  return (lbound === null || date >= lbound) && (ubound === null || date <= ubound)
}

const isInPastRange = (pastDate: PastDate, date: string): boolean =>
  isInRange(getPastDateBounds(pastDate), date)
const isInFutureRange = (futureDate: FutureDate, date: string): boolean =>
  isInRange(getFutureDateBounds(futureDate), date)

const allPastDates: PastDate[] = ['FUTURE', 'TODAY', 'YESTERDAY', 'PREVIOUS', 'EARLIER']
const allFutureDates: FutureDate[] = ['PAST', 'TODAY', 'TOMORROW', 'UPCOMING', 'FUTURE']

export const getPastDate = (date: string): PastDate => {
  for (const pastDate of allPastDates) {
    if (isInPastRange(pastDate, date)) {
      return pastDate
    }
  }
  throw new Error('Date does not fall into any categories')
}

export const getFutureDate = (date: string): FutureDate => {
  for (const futureDate of allFutureDates) {
    if (isInFutureRange(futureDate, date)) {
      return futureDate
    }
  }
  return 'PAST'
}

export const hasDueDateInFuture = (task: Task) => {
  if (!task.dueDate) {
    return false
  }
  const dueDate = D.parse(task.dueDate)
  if (!dueDate) {
    return false
  }
  return dueDate >= D.floor(D.add(D.now(), 1, 'day'), 'day')
}

export const isPastDue = (task: Task) => {
  if (!task.dueDate) {
    return false
  }
  const dueDate = D.parse(task.dueDate)
  return dueDate.getTime() < D.now().getTime()
}
