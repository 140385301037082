import React from 'react'
import { Modal } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import Link from '@/components/core/Link'
import ModalOverlay from '@/components/core/ModalOverlay'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import ShareSummaryModal from '@/components/modals/ShareSummaryModal'
import { useAppDispatch, useToggle } from '@/hooks'
import { useSpace } from '@/hooks/spaces'
import { summaryThunks } from '@/thunks'
import { Summary } from '@/types/entities'

interface EditSummaryButtonsProps {
  summary: Summary
  onEditSummary: (summary: Summary) => void
}

function EditSummaryButtons({ summary, onEditSummary }: EditSummaryButtonsProps) {
  const { spaceId, id: summaryId, status } = summary
  const space = useSpace(spaceId)
  const canSubmit = status === 'OPEN'
  const canSend = space?.productVersion === 'PARTNERSHIP'
  const insets = useSafeAreaInsets()

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [showShareModal, toggleShowShareModal] = useToggle(false)
  const dispatch = useAppDispatch()

  const handleEditSummary = React.useCallback(
    () => onEditSummary(summary),
    [onEditSummary, summary],
  )

  const handleSubmitSummary = React.useCallback(async () => {
    setIsSubmitting(true)
    await dispatch(summaryThunks.submitSummary(spaceId, summaryId, { notify: false }))
    setIsSubmitting(false)
  }, [setIsSubmitting, spaceId, summaryId, dispatch])

  const handleSendSummary = React.useCallback(async () => {
    setIsSubmitting(true)
    await dispatch(summaryThunks.submitSummary(spaceId, summaryId, { notify: true }))
    if (!canSend && status === 'OPEN') {
      toggleShowShareModal(true)
    }
    setIsSubmitting(false)
  }, [setIsSubmitting, canSend, status, toggleShowShareModal, spaceId, summaryId, dispatch])

  const sendButtonLabel = getSendButtonTitle(summary, canSend)

  return (
    <FloatContainer bottom={0} left={0} right={0}>
      <Background
        borderBottomLeftRadius={8}
        borderBottomRightRadius={8}
        borderColor="heather"
        borderTopWidth={1}
        color="ghost"
      >
        <Box alignItems="center" direction="row" gapAll="medium" gapBottom={insets.bottom || 10}>
          <Box direction="row" flex={1} justifyContent="flex-start">
            <Button
              kind="primary"
              label="Edit"
              minWidth={125}
              onPress={handleEditSummary}
              testID="edit_summary_button"
            />
          </Box>
          <Box direction="row" gapHorizontal="large" justifyContent="center">
            {canSubmit ? (
              <Link
                disabled={isSubmitting}
                label="Publish Only"
                onPress={handleSubmitSummary}
                size="h5"
                testID="publish_only_button"
              />
            ) : (
              <Link
                label="Copy Link"
                onPress={toggleShowShareModal}
                size="h5"
                testID="copy_link_button"
              />
            )}
          </Box>
          <Box direction="row" flex={1} justifyContent="flex-end">
            {sendButtonLabel ? (
              <Button
                disabled={isSubmitting || !getSendButtonEnabled(summary)}
                kind="primary"
                label={sendButtonLabel}
                minWidth={125}
                onPress={handleSendSummary}
                testID="publish_and_send_button"
              />
            ) : null}
          </Box>
        </Box>
        <Modal animationType="fade" transparent visible={showShareModal}>
          <ModalOverlay>
            <ShareSummaryModal onClose={toggleShowShareModal} summary={summary} />
          </ModalOverlay>
        </Modal>
      </Background>
    </FloatContainer>
  )
}

const getSendButtonTitle = (summary: Summary, canSend: boolean) => {
  const { status, threadId } = summary
  if (canSend) {
    if (status === 'OPEN') {
      return 'Create & Send'
    }
    if (threadId) {
      return 'Sent'
    }
    return 'Send'
  }
  if (status === 'OPEN') {
    return 'Create'
  }
  return null
}

const getSendButtonEnabled = (summary: Summary) => {
  const { threadId } = summary

  return !threadId
}

export default React.memo(EditSummaryButtons)
