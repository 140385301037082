import { createSelector } from 'reselect'
import { channelUtils, typeUtils, userUtils } from '@/core/utils'
import { SpaceMembershipType, SpaceUser } from '@/types/entities'
import { selectors as authSelectors } from '../../auth'
import { selectors as channelSelectors } from '../channels'
import { buildId as buildUserId, selectors as userSelectors } from '../users'

export interface UserFilter {
  spaceId: number
  channelId?: number | null
  excludeMe?: boolean
  searchText?: string | RegExp | null
  userIds?: number[] | null
  includeInactive?: boolean
  membershipType?: SpaceMembershipType
}

export const filteredUsersSelector = ({
  spaceId,
  channelId,
  excludeMe = false,
  searchText,
  userIds,
  includeInactive,
  membershipType,
}: UserFilter) =>
  createSelector(
    userSelectors.all,
    channelId ? channelSelectors.byIdSelector(spaceId, channelId) : () => undefined,
    authSelectors.myId,
    (allUsers, channel, myId) => {
      const isRegExp = typeUtils.isType('RegExp', searchText)
      const loweredSearchText = isRegExp ? '' : ((searchText as string) || '').toLowerCase()

      const filterUser = (user: SpaceUser) => {
        if (excludeMe && user.id === myId) {
          return false
        }

        if (user.spaceId !== spaceId) {
          return false
        }

        if (channel && !channel.userIds.includes(user.id)) {
          return false
        }

        if (membershipType && membershipType !== user.membershipType) {
          return false
        }

        if (user.membershipType === 'INACTIVE' && !includeInactive) {
          return false
        }

        if (searchText) {
          if (isRegExp) {
            const matches = (searchText as RegExp).test(userUtils.getFullName(user))
            if (!matches) {
              return false
            }
          } else {
            const matches = userUtils.getFullName(user).toLowerCase().includes(loweredSearchText)
            if (!matches) {
              return false
            }
          }
        }

        if (userIds) {
          if (!userIds.includes(user.id)) {
            return false
          }
        }

        return true
      }

      return allUsers.filter(filterUser)
    },
  )

export const meSelector = (spaceId: number) =>
  createSelector(
    userSelectors.entities,
    authSelectors.myId,
    (usersIndex, myId) => usersIndex[buildUserId(spaceId, myId || 0)],
  )

export const notInHubWithUsersSelector = (spaceId: number) =>
  createSelector(
    userSelectors.all,
    channelSelectors.all,
    authSelectors.myId,
    (users, channels, myId) => {
      const hubUserIds = new Set<number>()

      channels.forEach(channel => {
        if (channel.spaceId === spaceId && channelUtils.isHub(channel)) {
          channel.userIds.forEach(userId => hubUserIds.add(userId))
        }
      })

      return users.filter(
        user => user.spaceId === spaceId && user.id !== myId && !hubUserIds.has(user.id),
      )
    },
  )

export const inHubWithNonActiveUsersSelector = (spaceId: number) =>
  createSelector(
    userSelectors.all,
    channelSelectors.all,
    authSelectors.myId,
    (users, channels, myId) => {
      const hubUserIds = new Set<number>()

      channels.forEach(channel => {
        if (channel.spaceId === spaceId && channelUtils.isHub(channel)) {
          channel.userIds.forEach(userId => hubUserIds.add(userId))
        }
      })

      return users.filter(
        user =>
          user.spaceId === spaceId &&
          user.id !== myId &&
          hubUserIds.has(user.id) &&
          (user.membershipType === 'INVITED' || user.membershipType === 'NOT_INVITED'),
      )
    },
  )
