import React from 'react'
import { includeList, mergeFilters, unaryFilter } from '@/core/utils/tasks/filtering'
import {
  buildBaseFilter,
  buildMyDayTasksFilter,
  buildNoRelationshipFilter,
  getTaskRelationshipFields,
} from '@/core/utils/tasks/filters'
import { useCurrentUserId } from '@/hooks/auth'
import { TaskRelationship, TaskRelationshipFields, TaskType, UnaryTaskFilter } from '@/types/tasks'
import { useHubs } from '../channels'
import { useMyDayList } from '../taskLists'

// The base filter will show only uncompleted tasks in the current space. If there
// is a context filter coming in, it will be merged with the base filter
export const useBaseFilter = (
  spaceId: number,
  taskType: TaskType,
  contextFilter?: UnaryTaskFilter,
): UnaryTaskFilter =>
  React.useMemo<UnaryTaskFilter>(() => {
    const filter = buildBaseFilter(spaceId, taskType)

    return contextFilter ? mergeFilters(filter, contextFilter) : filter
  }, [spaceId, taskType, contextFilter])

export const useMyDayFilter = (
  contextFilter?: UnaryTaskFilter | undefined | null,
  userId?: number | undefined | null,
) => {
  const myId = useCurrentUserId()
  const { taskList } = useMyDayList(userId || myId || 0)
  return React.useMemo<UnaryTaskFilter>(() => {
    const filter = buildMyDayTasksFilter(taskList?.id || 0)

    return contextFilter ? mergeFilters(contextFilter, filter) : filter
  }, [contextFilter, taskList])
}

export const useRelationshipFields = (
  taskRelationship: TaskRelationship,
): TaskRelationshipFields => {
  const myId = useCurrentUserId() || 0

  return React.useMemo(
    () => getTaskRelationshipFields(taskRelationship, myId),
    [taskRelationship, myId],
  )
}

export const useRelationshipFilter = (
  taskRelationship: TaskRelationship,
  filter: UnaryTaskFilter,
): UnaryTaskFilter => {
  const relationshipFields = useRelationshipFields(taskRelationship)
  return React.useMemo(() => mergeFilters(filter, relationshipFields), [filter, relationshipFields])
}

export const useNoSpaceFilter = (filter: UnaryTaskFilter): UnaryTaskFilter =>
  React.useMemo(() => mergeFilters(filter, { spaceIds: undefined }), [filter])

export const useNoRelationshipFilter = (filter: UnaryTaskFilter): UnaryTaskFilter =>
  React.useMemo(() => buildNoRelationshipFilter(filter), [filter])

export const useChannelTasksFilter = (spaceId: number, taskType: TaskType) => {
  const hubs = useHubs(spaceId, 'ACTIVE', true)

  return React.useMemo(
    () =>
      unaryFilter({
        channelIds: includeList(
          hubs.map(hub => [hub.spaceId, hub.id]),
          'exclude',
        ),
        completed: false,
        isQuestion: taskType === 'QUESTION',
        spaceIds: includeList([spaceId]),
      }),
    [hubs, spaceId, taskType],
  )
}
