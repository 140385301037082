import React from 'react'
import { ReminderOffset } from '@/types/entities'
import RecurrenceOptionItem from './RecurrenceOptionItem'
import ReminderOptionItem from './ReminderOptionItem'
import TimeOptionItem from './TimeOptionItem'

interface DateOptionsListProps {
  onDateChange: (date: Date) => void
  onRecurrenceRuleChange: (newRecurrenceRule: string | null) => void
  onRemindAtChange: (remindAtOffset: ReminderOffset | null) => void
  recurrenceRule: string | null
  selectedDate: Date | null
  selectedRemindAt: Date | null
  selectedRemindAtOffset: ReminderOffset | null
  localModal: boolean
}

function DateOptionsList({
  onDateChange,
  onRecurrenceRuleChange,
  onRemindAtChange,
  recurrenceRule,
  selectedDate,
  selectedRemindAt,
  selectedRemindAtOffset,
  localModal,
}: DateOptionsListProps) {
  return (
    <>
      <TimeOptionItem
        localModal={localModal}
        onDateChange={onDateChange}
        selectedDate={selectedDate}
      />
      <ReminderOptionItem
        localModal={localModal}
        onRemindAtChange={onRemindAtChange}
        selectedDate={selectedDate}
        selectedRemindAt={selectedRemindAt}
        selectedRemindAtOffset={selectedRemindAtOffset}
      />
      <RecurrenceOptionItem
        localModal={localModal}
        onRecurrenceRuleChange={onRecurrenceRuleChange}
        recurrenceRule={recurrenceRule}
        selectedDate={selectedDate}
      />
    </>
  )
}

export default React.memo(DateOptionsList)
