import React from 'react'
import { StyleSheet, View } from 'react-native'
import { dateUtils } from '@/core/utils'
import BaseIconButton from '../BaseIconButton'
import Text from '../Text'
import Cell, { CellStatus } from './Cell'
import { useCalendar } from './hooks'

interface CalendarProps {
  selectedDate: Date | null
  onSelectedDateChange: (date: Date) => void
}

function Calendar({ selectedDate, onSelectedDateChange }: CalendarProps) {
  const { focusedDate, goToNextMonth, goToPrevMonth, calendarRows } = useCalendar(selectedDate)
  const today = dateUtils.now()
  const selectedDay = selectedDate ? dateUtils.floor(selectedDate, 'day') : null
  const handleSelectedDateChange = React.useCallback(
    (date: Date) => {
      if (selectedDate) {
        onSelectedDateChange(dateUtils.matchTime(selectedDate, date))
      } else {
        onSelectedDateChange(date)
      }
    },
    [selectedDate, onSelectedDateChange],
  )

  return (
    <View>
      <View style={styles.header}>
        <View style={styles.monthName}>
          <Text>
            {monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
          </Text>
        </View>
        <View>
          <BaseIconButton name="chevron-left" onPress={goToPrevMonth} size="h4" />
        </View>
        <View>
          <BaseIconButton name="chevron-right" onPress={goToNextMonth} size="h4" />
        </View>
      </View>
      <View style={styles.row}>
        <Cell>S</Cell>
        <Cell>M</Cell>
        <Cell>T</Cell>
        <Cell>W</Cell>
        <Cell>T</Cell>
        <Cell>F</Cell>
        <Cell>S</Cell>
      </View>
      {calendarRows.map(row => (
        <View key={getRowKey(row)} style={styles.row}>
          {row.map(cell => (
            <Cell
              key={getCellKey(cell)}
              onPress={handleSelectedDateChange}
              status={getCellStatus(cell, today, focusedDate, selectedDay)}
              value={cell}
            >
              {cell.getDate().toString()}
            </Cell>
          ))}
        </View>
      ))}
    </View>
  )
}

const getCellStatus = (
  date: Date,
  today: Date,
  focusedDate: Date,
  selectedDay: Date | null,
): CellStatus => {
  if (selectedDay && date.getTime() === selectedDay.getTime()) {
    return 'selected'
  }
  if (date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
    return 'today'
  }
  if (date.getMonth() === focusedDate.getMonth()) {
    return 'normal'
  }
  return 'otherMonth'
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const getRowKey = (row: Date[]) => `${row[0].getMonth()}:${row[0].getDate()}`
const getCellKey = (cell: Date) => cell.getDate().toString()

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
  },
  monthName: {
    flex: 1,
    padding: 10,
  },
  row: {
    flexDirection: 'row',
  },
})

export default React.memo(Calendar)
