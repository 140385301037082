import { nowMilliseconds } from './dates'

export const sleep = (time: number) =>
  new Promise(resolve => {
    setTimeout(resolve, time)
  })

export const sleepWhile = async (
  callback: (sleepDurationMS: number) => boolean,
  timeMS: number,
) => {
  const startedSleeping = nowMilliseconds()

  while (callback(nowMilliseconds() - startedSleeping)) {
    await sleep(timeMS)
  }
}

export async function optimistic<ActionResult>(
  action: () => Promise<ActionResult>,
  isSuccess: (result: ActionResult) => boolean,
  undo: (result: ActionResult) => void,
): Promise<ActionResult> {
  const result = await action()
  if (!isSuccess(result)) {
    await undo(result)
  }
  return result
}

export const retryUntil = async <R>(
  callback: (tries: number) => Promise<R>,
  shouldTryAgain: (result: R, tries: number) => boolean,
  getSleepDuration: (tries: number) => number,
): Promise<R> => {
  let tries = 0

  /* eslint-disable no-constant-condition */
  while (true) {
    /* eslint-enable no-constant-condition */
    tries += 1
    const result = await callback(tries)

    // If the result doesn't need to be tried again, return it.
    if (!shouldTryAgain(result, tries)) {
      return result
    }

    await sleep(getSleepDuration(tries))
  }
}
