import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { CreateHubMode } from './types'

interface HubModeSelectorOptionProps {
  option: CreateHubMode
  onSelect: (mode: CreateHubMode) => void
  isSelected: boolean
}

function HubModeSelectorOption({ option, onSelect, isSelected }: HubModeSelectorOptionProps) {
  const handleSelect = React.useCallback(() => onSelect(option), [onSelect, option])

  return (
    <Touchable feedback="opacity" onPress={handleSelect}>
      <Box alignItems="center" direction="row" gapHorizontal="large" gapVertical="medium">
        <Checkbox checked={isSelected} />
        <Box flex={1} gapLeft="large">
          <Text lineHeight={18} size="h5" weight="medium">
            {getLabel(option)}
          </Text>
          <Text lineHeight={16} size="h6" weight="medium">
            {getDescription(option)}
          </Text>
        </Box>
      </Box>
    </Touchable>
  )
}

const getDescription = (option: CreateHubMode) => {
  switch (option) {
    case 'INVITE_USER':
      return 'You can either invite them to Emmre or create a virtual hub with this person.'
    case 'EXISTING_USER':
      return 'This will invite the person you select to be in a new hub with you.'
    case 'JUST_YOU':
      return 'This will create a private space for you to keep tasks that are not related to any of your hubs.'
    default:
      throw new Error(`Unhandled option: ${option}`)
  }
}

const getLabel = (option: CreateHubMode) => {
  switch (option) {
    case 'INVITE_USER':
      return (
        <>
          Someone{' '}
          <Text italic size="h5" weight="bold">
            Not
          </Text>{' '}
          in this Workspace
        </>
      )
    case 'EXISTING_USER':
      return 'Someone in this Workspace'
    case 'JUST_YOU':
      return 'Private Hub'
    default:
      throw new Error(`Unhandled option: ${option}`)
  }
}

export default React.memo(HubModeSelectorOption)
