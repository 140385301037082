import { Actionable, Timestamped } from '@/types/entities'

interface Named {
  name: string
}

export const compareByName = (left: Named, right: Named): number =>
  left.name.localeCompare(right.name)

export const hasAction = (actionName: string, entity: Actionable) =>
  !!entity.actions?.includes(actionName)

export const isUnedited = (entity: Timestamped) => entity.createdAt === entity.updatedAt
