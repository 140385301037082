import React from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet } from 'react-native'
import HorizontalList from '@/components/core/HorizontalList'
import Box from '@/components/layouts/Box'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { ColorName } from '@/core/constants/colors'
import { fnUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import { FilterGroup, FilterOption, QueryKeys } from '@/types/tasks'
import FilterGroupListItem from './FilterGroupListItem'
import FilterOptionsList from './FilterOptionsList'

interface FilterGroupsListProps {
  buttonColor: ColorName
  optionGroups: FilterGroup[]
  queryKeys: QueryKeys
}

type Item = ListRenderItemInfo<FilterGroup>

const keyExtractor = (group: FilterGroup) => group.key

function FilterGroupsList({ buttonColor, optionGroups, queryKeys }: FilterGroupsListProps) {
  const { groupKey, optionKey } = queryKeys
  const { setFilterOption } = useTasksListViewContext()

  const selectedOptionGroup = React.useMemo(
    () => optionGroups.find(optionGroup => optionGroup.key === queryKeys.groupKey),
    [optionGroups, queryKeys],
  )

  const handleSelectOptionGroup = React.useCallback(
    (optionGroup: FilterGroup) => {
      easeInOut()
      setFilterOption(optionGroup, optionGroup.options[0])
    },
    [setFilterOption],
  )

  const handleSelectOption = React.useCallback(
    (option: FilterOption) => {
      if (selectedOptionGroup) {
        easeInOut()
        setFilterOption(selectedOptionGroup, option)
      }
    },
    [setFilterOption, selectedOptionGroup],
  )

  const groups = React.useMemo(
    () => optionGroups.filter(optionGroup => optionGroup.options.length !== 0),
    [optionGroups],
  )

  const renderItem = React.useCallback(
    ({ item: optionGroup }: Item) => (
      <FilterGroupListItem
        isSelected={optionGroup.key === groupKey}
        onSelect={handleSelectOptionGroup}
        optionGroup={optionGroup}
      />
    ),
    [handleSelectOptionGroup, groupKey],
  )

  const flatListRef = React.useRef<FlatList | null>(null)
  const lastSelectedGroupRef = React.useRef(groupKey)
  React.useEffect(() => {
    if (groupKey === lastSelectedGroupRef.current || !selectedOptionGroup) {
      return
    }

    lastSelectedGroupRef.current = groupKey
    setTimeout(() => {
      flatListRef.current?.scrollToItem({
        animated: true,
        item: selectedOptionGroup,
        viewPosition: 0.5,
      })
    })
  }, [selectedOptionGroup, groupKey])

  return (
    <Box>
      <HorizontalList
        buttonBackgroundColor={buttonColor}
        buttonIconColor="anchor"
        contentContainerStyle={styles.flatListContentContainer}
        data={groups}
        extraData={groupKey}
        flatListRef={flatListRef}
        horizontal
        keyExtractor={keyExtractor}
        onScrollToIndexFailed={fnUtils.noOp}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
      />
      <FilterOptionsList
        buttonColor={buttonColor}
        group={selectedOptionGroup}
        onSelectOption={handleSelectOption}
        selectedOptionKey={optionKey}
      />
    </Box>
  )
}

const styles = StyleSheet.create({
  flatListContentContainer: {
    alignItems: 'center',
  },
})

export default React.memo(FilterGroupsList)
