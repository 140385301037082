import { dateUtils as D } from '@/core/utils'
import { apiStatusActions } from '@/store/actions'
import { apiStatusSelectors } from '@/store/selectors'
import { EndpointState } from '@/store/slices/apiStatus'
import { Thunk } from '@/types/store'

export type APIStatusResult = {
  success: boolean
}

// This function uses the apiStatus timestamp field to fetch updated data. The callback will
// accept the timestamp of the last logged ratchet value to get updated data, and this function
// will set a new ratchet value that is just before the method was called. The callback must
// return a special value that instructs this method on whether or not to update the ratchet. For
// instance if an call got an error then it may not be correct to update the ratchet.
export const callWithStatus =
  (
    endpointId: string,
    callback: (timestamp: number | null) => Thunk<APIStatusResult>,
    maxFirstSuccessAgeMilliseconds?: number,
  ): Thunk<boolean> =>
  async (dispatch, getState) => {
    const lastEntry = apiStatusSelectors.endpoint(getState(), endpointId)
    const timestamp = getStatusTimestamp(lastEntry, maxFirstSuccessAgeMilliseconds)
    let success = false

    dispatch(apiStatusActions.start({ endpointId }))

    try {
      const result = await dispatch(callback(timestamp))
      success = result.success
    } catch {
      success = false
    }

    if (success) {
      dispatch(apiStatusActions.success({ endpointId, wasFirstRequest: timestamp === null }))
    } else {
      dispatch(apiStatusActions.failure({ endpointId }))
    }
    return success
  }

// For some entities (for example those with pre-signed URLs) we want to fully refresh them after
// a certain point. The maxFirstSuccessAgeMilliseconds controls this. If there is on
// firstSuccessStartedAt, or if the age has passed the delta
const getStatusTimestamp = (
  entry: EndpointState,
  maxFirstSuccessAgeMilliseconds?: number,
): number | null => {
  if (!maxFirstSuccessAgeMilliseconds) {
    return entry.lastSuccessStartedAt || null
  }

  if (!entry.firstSuccessStartedAt) {
    return null
  }

  const delta = D.nowMilliseconds() - entry.firstSuccessStartedAt

  if (delta > maxFirstSuccessAgeMilliseconds) {
    return null
  }
  return entry.lastSuccessStartedAt || null
}
