import React from 'react'
import { StyleSheet, TextInput, TextStyle, View } from 'react-native'
import { getColor } from '@/core/constants/colors'
import { getFontSize, getLineHeight } from '@/core/constants/typography'
import { platformUtils } from '@/core/utils'
import Selector from '../Selector'
import { useTimeInput } from './hooks'
import { formatTime } from './utils'

interface TimeInputProps {
  value: Date
  onChange: (date: Date) => void
  autoFocus?: boolean
  disabled?: boolean
}

const choices = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
]

function TimeInput({ value, onChange, autoFocus = false, disabled = false }: TimeInputProps) {
  const { timeValue, handleChangeTimeValue, meridiem, handleChangeMeridiem } = useTimeInput(
    value,
    onChange,
  )

  return (
    <View style={styles.container}>
      <View style={styles.display}>
        <TextInput
          autoFocus={autoFocus}
          editable={!disabled}
          keyboardType="number-pad"
          onChangeText={handleChangeTimeValue}
          style={[styles.input, extraStyle]}
          value={formatTime(timeValue)}
        />
      </View>
      <Selector
        choices={choices}
        disabled={disabled}
        onChange={handleChangeMeridiem}
        value={meridiem}
      />
    </View>
  )
}

// react-native-web adds outline props to ViewStyle
const extraStyle: TextStyle | undefined = platformUtils.isWeb
  ? // @ts-ignore
    { outlineStyle: 'none' }
  : undefined

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  display: {
    alignItems: 'center',
    backgroundColor: getColor('heather'),
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  input: {
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    padding: 10,
    textAlign: 'right',
    width: 75,
  },
})

export default React.memo(TimeInput)
