import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { userAPI } from '@/api/requests'
import { collectionUtils as C, dateUtils as D, fileUtils } from '@/core/utils'
import { authActions, formActions, userActions } from '@/store/actions'
import { buildUserId } from '@/store/entityIds'
import { authSelectors, formSelectors, spaceSelectors } from '@/store/selectors'
import { SyncThunk, Thunk } from '@/types/store'
import { AuthInitializer, AuthResponse, handleSignIn } from './auth'
import { setErrors } from './errors'
import { uploadLocalFile } from './files'
import { makeEnhancedRequest } from './utils'

interface UpdateUser extends Omit<userAPI.UpdateUser, 'profilePictureFileId'> {
  profilePictureUrl?: string | null
  profilePictureName?: string | null
}

export const editProfileForm =
  (formName: string, fields: string[]): Thunk<boolean> =>
  async (dispatch, getState) => {
    const me = authSelectors.me(getState())

    if (!me) {
      return false
    }

    const updates = C.pick(fields, formSelectors.values(getState(), formName))
    const response = await dispatch(editProfile(me.id, updates))

    if (response.ok) {
      dispatch(initializeProfileForm(formName))
      return true
    }

    dispatch(setErrors(formName, response.errors, 'Error Updating Profile'))
    return false
  }

export const editPasswordForm =
  (formName: string): Thunk<boolean> =>
  async (dispatch, getState) => {
    const me = authSelectors.me(getState())

    if (!me) {
      return false
    }

    const updates = formSelectors.values(getState(), formName)

    if (updates.confirmNewPassword !== updates.newPassword) {
      dispatch(
        formActions.setErrors({
          errors: { confirmNewPassword: ["Passwords don't match"] },
          formName,
        }),
      )
      return false
    }

    const response = await dispatch(editProfile(me.id, updates))

    if (response.ok) {
      dispatch(initializePasswordForm(formName))
      return true
    }

    dispatch(setErrors(formName, response.errors, 'Error Updating Profile'))
    return false
  }

export const editProfile =
  (userId: number, updates: UpdateUser): RequestThunk<typeof userAPI.updateUser> =>
  async (dispatch, getState) => {
    const me = authSelectors.me(getState())
    const body: userAPI.UpdateUser = { ...updates }

    if (updates.profilePictureUrl) {
      const attachment = fileUtils.buildAttachment(
        updates.profilePictureUrl,
        updates.profilePictureName || null,
      )
      const newFile = await dispatch(uploadLocalFile(attachment))
      if (newFile) {
        body.profilePictureFileId = newFile.id
      }
    }

    const request = userAPI.updateUser(userId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { user } = response.data
      const spaceIds = spaceSelectors.all(getState()).map(space => space.id)

      batch(() => {
        if (userId === me?.id) {
          dispatch(authActions.setMe(user))
        }
        dispatch(
          userActions.updateMany(
            spaceIds.map(spaceId => ({
              changes: user,
              id: buildUserId(spaceId, user.id),
            })),
          ),
        )
      })
    }

    return response
  }

export const sendVerificationCodeForm =
  (formName: string): Thunk<boolean> =>
  async (dispatch, getState) => {
    const { emailAddress } = formSelectors.values(getState(), formName)
    const request = userAPI.sendVerificationCode(emailAddress)
    const response = await dispatch(makeEnhancedRequest(request))

    if (!response.ok) {
      dispatch(setErrors(formName, response.errors, 'Error Sending Verification Code'))
    }

    return response.ok
  }

export const createPartnerAccountForm =
  <T>(
    formName: string,
    extra: Record<string, any>,
    onInitialize: AuthInitializer<T>,
  ): Thunk<AuthResponse<userAPI.CreateAccountResponse, T>> =>
  async (dispatch, getState) => {
    const formData = formSelectors.values(getState(), formName)
    const data = { ...formData, ...extra }

    const request = userAPI.createPartnerAccount(data as userAPI.CreatePartnerAccountBody)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const initializationResult = await dispatch(handleSignIn(response.data, onInitialize))
      dispatch(formActions.clear(formName))
      return { initializationResult, ok: true, response }
    }

    dispatch(setErrors(formName, response.errors, 'Error Creating Account'))
    return { ok: false, response }
  }

export const createTeamsAccountForm =
  <T>(
    formName: string,
    extra: Partial<userAPI.CreateTeamsAccountBody>,
    onInitialize: AuthInitializer<T>,
  ): Thunk<AuthResponse<userAPI.CreateAccountResponse, T>> =>
  async (dispatch, getState) => {
    const formData = formSelectors.values(getState(), formName)
    const data = { ...formData, ...extra }

    const request = userAPI.createTeamsAccount(data as userAPI.CreateTeamsAccountBody)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const initializationResult = await dispatch(handleSignIn(response.data, onInitialize))
      dispatch(formActions.clear(formName))
      return { initializationResult, ok: true, response }
    }

    dispatch(setErrors(formName, response.errors, 'Error Creating Account'))
    return { ok: false, response }
  }

export const validatePartnerAccountForm =
  (formName: string, forceFields: Array<keyof userAPI.CreatePartnerAccountBody>): Thunk<boolean> =>
  async (dispatch, getState) => {
    const body = formSelectors.values(getState(), formName)
    forceFields.forEach(field => {
      if (!(field in body)) {
        body[field] = null
      }
    })
    const request = userAPI.validatePartnerAccount(body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (!response.ok) {
      dispatch(setErrors(formName, response.errors))
    }

    return response.ok
  }

export const initializeProfileForm =
  (formName: string): SyncThunk<void> =>
  (dispatch, getState) => {
    const me = authSelectors.me(getState()) || ({} as Record<string, any>)

    if (!me.timezoneName) {
      me.timezoneName = D.getTimeZoneName()
    }

    dispatch(
      formActions.initialize({
        fields: C.pick(
          ['firstName', 'lastName', 'emailAddress', 'timezoneName', 'profilePictureUrl'],
          me,
        ),
        formName,
      }),
    )
  }

export const initializePasswordForm =
  (formName: string): SyncThunk<void> =>
  dispatch => {
    dispatch(
      formActions.initialize({
        fields: {},
        formName,
      }),
    )
  }

export const requestPasswordReset =
  (emailAddress: string): Thunk<boolean> =>
  async dispatch => {
    const request = userAPI.requestPasswordReset(emailAddress)
    const response = await dispatch(makeEnhancedRequest(request))

    return response.ok
  }

export const resetPassword =
  <T>(
    resetToken: string,
    newPassword: string,
    onInitialize: AuthInitializer<T>,
  ): Thunk<AuthResponse<userAPI.ResetPasswordResponse, T>> =>
  async dispatch => {
    const request = userAPI.resetPassword(resetToken, newPassword)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const initializationResult = await dispatch(handleSignIn(response.data, onInitialize))
      return { initializationResult, ok: true, response }
    }

    return { ok: false, response }
  }
