import React from 'react'
import { amplitudeProxy } from '@/core/instrumentation'

export const useAmplitude = <HandlerParameters extends any[], HandlerReturn>(
  handler: (...args: HandlerParameters) => HandlerReturn,
  eventName?: string,
  eventProperties: Record<string, any> = {},
) =>
  React.useCallback(
    (...args: HandlerParameters) => {
      if (eventName) {
        amplitudeProxy.logEvent(eventName, eventProperties)
      }
      return handler(...args)
    },
    [handler, eventName, eventProperties],
  )

export const useOptionalAmplitude = <HandlerParameters extends any[], HandlerReturn>(
  handler?: (...args: HandlerParameters) => HandlerReturn,
  eventName?: string,
  eventProperties: Record<string, any> = {},
) => {
  const wrapped = React.useCallback(
    (...args: HandlerParameters) => {
      if (!handler) {
        throw new Error('Should not call wrapped amplitude handler when inner handler is undefined')
      }
      if (eventName) {
        amplitudeProxy.logEvent(eventName, eventProperties)
      }
      return handler(...args)
    },
    [handler, eventName, eventProperties],
  )

  return handler ? wrapped : undefined
}
