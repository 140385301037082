import React from 'react'
import Button from '@/components/core/Button'
import PaymentIntent from '@/components/financials/PaymentIntent'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { useAppDispatch } from '@/hooks'
import { useChargebeePayment, usePaymentIntent } from '@/hooks/chargebee'
import { notificationActions } from '@/store/actions'
import { internalThunks } from '@/thunks'

interface MakePaymentModalProps {
  spaceId: number
  onClose: () => void
  onCancel?: () => void
}

function MakePaymentModal({ spaceId, onClose, onCancel }: MakePaymentModalProps) {
  const { paymentIntent, setPaymentIntent, status: paymentIntentStatus } = usePaymentIntent(spaceId)
  const dispatch = useAppDispatch()
  const { cardRef, status: submitStatus, errorMessage, submitPayment } = useChargebeePayment()
  const [creatingPaymentSource, setCreatingPaymentSource] = React.useState(false)

  const handleSubmitPayment = React.useCallback(async () => {
    if (!paymentIntent) {
      return
    }

    const newPaymentIntent = await submitPayment(paymentIntent)

    if (!newPaymentIntent) {
      return
    }

    setPaymentIntent(newPaymentIntent)

    setCreatingPaymentSource(true)

    const paymentSourceResult = await dispatch(
      internalThunks.createChargebeePaymentSource(spaceId, newPaymentIntent.id),
    )

    setCreatingPaymentSource(false)

    if (!paymentSourceResult) {
      // What to do w/ error?
      dispatch(
        notificationActions.push({
          title: 'An error occurred when creating your payment source',
          type: 'error',
        }),
      )
    } else {
      onClose()
    }
  }, [paymentIntent, setPaymentIntent, submitPayment, dispatch, spaceId, onClose])

  const handleCancel = React.useCallback(() => {
    onClose()
    onCancel?.()
  }, [onClose, onCancel])

  const canSubmit =
    paymentIntentStatus === 'SUCCESS' && submitStatus !== 'PENDING' && !creatingPaymentSource

  return (
    <ModalLayout
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      title="Account Payment"
    >
      <Box gapAll="large">
        <PaymentIntent
          cardRef={cardRef}
          errorMessage={errorMessage}
          paymentIntent={paymentIntent}
          status={paymentIntentStatus}
        />
        {paymentIntent ? (
          <Box direction="row" gapTop="large" justifyContent="flex-end">
            <Button
              disabled={!canSubmit}
              kind="primary"
              label="Make Payment"
              onPress={handleSubmitPayment}
            />
          </Box>
        ) : null}
      </Box>
    </ModalLayout>
  )
}

export default React.memo(MakePaymentModal)
