import { taskUtils } from '@/core/utils'
import { useAppSelector } from '@/hooks/redux'
import { taskSelectors } from '@/store/selectors'

export { useCheckNotify } from './checkNotify'

const useTask = (spaceId: number, taskId: number) =>
  useAppSelector(taskSelectors.byId, spaceId, taskId)

export const useIsQuestion = (spaceId: number, taskId: number) => {
  const task = useTask(spaceId, taskId)
  return !!task && taskUtils.isQuestion(task)
}
