import { formActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'
import { APIThunk } from '@/types/api'
import { setErrors } from './errors'

export const formRequest =
  <T>(
    formName: string,
    delegate: (fields: Record<string, any>) => APIThunk<T>,
    errorNotificationTitle?: string,
  ): APIThunk<T> =>
  async (dispatch, getState) => {
    const fields = formSelectors.values(getState(), formName)
    const response = await dispatch(delegate(fields))

    if (response.ok) {
      dispatch(formActions.clear(formName))
    } else {
      dispatch(setErrors(formName, response.errors, errorNotificationTitle))
    }
    return response
  }
