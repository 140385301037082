import React from 'react'
import ContainedIcon from '@/components/core/ContainedIcon'
import { MyDayStatus } from './types'

const buttonSize = 26
const buttonPadding = 2
const buttonShape = 'circle'

interface MyDayIconProps {
  status: MyDayStatus
}

function MyDayIcon({ status }: MyDayIconProps) {
  if (status === 'MY_DAY') {
    return (
      <ContainedIcon
        backgroundColor="transparent"
        color="rust"
        kind="regular"
        name="sun-bright"
        padding={buttonPadding}
        shape={buttonShape}
        size={buttonSize}
      />
    )
  }
  if (status === 'IGNORED') {
    return (
      <ContainedIcon
        backgroundColor="transparent"
        color="koala"
        name="eclipse"
        padding={buttonPadding}
        shape={buttonShape}
        size={buttonSize}
      />
    )
  }
  return (
    <ContainedIcon
      backgroundColor="transparent"
      color="koala"
      kind="regular"
      name="sun-bright"
      padding={buttonPadding}
      shape={buttonShape}
      size={buttonSize}
    />
  )
}

export default React.memo(MyDayIcon)
