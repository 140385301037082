import React from 'react'
import Box from '@/components/layouts/Box'
import {
  TaskDetailButtons,
  TaskFormSubmitLine,
  TaskMyDayAssigneeLine,
} from '@/components/tasks/TaskForm'

interface TaskInputFooterProps {
  isExpanded: boolean
}

function TaskInputFooter({ isExpanded }: TaskInputFooterProps) {
  if (isExpanded) {
    return (
      <Box gapBottom="medium" gapHorizontal="medium">
        <TaskMyDayAssigneeLine gap={false} />
        <TaskFormSubmitLine alignSubmit="flex-end" gapHorizontal={false} gapTop>
          <TaskDetailButtons wrapped />
        </TaskFormSubmitLine>
      </Box>
    )
  }

  return (
    <Box gapBottom="medium" gapHorizontal="medium">
      <TaskFormSubmitLine alignSubmit="flex-end" gapHorizontal={false} gapTop={false}>
        <TaskMyDayAssigneeLine wrapped />
      </TaskFormSubmitLine>
    </Box>
  )
}

export default React.memo(TaskInputFooter)
