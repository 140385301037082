import React from 'react'
import Icon from '@/components/core/Icon'
import TextWithLinks from '@/components/core/TextWithLinks'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { fieldNames, leftIconWidth } from '../constants'
import { useTaskFormValue } from '../hooks'

function TaskNotes() {
  const { showDetail } = useTaskFormContext()
  const notes = useTaskFormValue(fieldNames.notes)
  const showNotes = React.useCallback(() => showDetail('NOTES'), [showDetail])

  if (!notes) {
    return null
  }

  return (
    <Touchable feedback="highlight" onPress={showNotes}>
      <Box alignItems="center" direction="row" gapHorizontal="large" gapTop="large">
        <Box alignSelf="flex-start" width={leftIconWidth}>
          <Icon color="shadow" kind="light" name="align-left" size="h4" />
        </Box>
        <Box flex={1}>
          <TextWithLinks color="shadow" ellipsizeMode="tail" numberOfLines={5} size="h5">
            {notes}
          </TextWithLinks>
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskNotes)
