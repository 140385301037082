import Constants from 'expo-constants'
import { Configuration, configuration as baseConfig } from './base'

const getHost = (): string => {
  const { manifest } = Constants

  const host = manifest?.debuggerHost?.split(':').shift()

  if (!host) {
    return window.location.hostname
  }

  return host
}

export const getConfiguration = (): Configuration => {
  const host = getHost()

  return {
    ...baseConfig,
    amplitudeApiKey: '0fdb9c467f0b3d925cf6b095c259e63c',
    apiURLRoot: `http://${host}`,
    appURLRoot: `http://${host}:19006`,
    chargebeePublishableKey: 'test_Tg3AfcdjCqWnHkCjoPdcuiGmOZTHH5cdpEI',
    chargebeeSite: 'emmre-test',
    checkForUpdates: false,
    features: {
      ...baseConfig.features,
      channels: false,
      giphy: true,
      paidSignUp: false,
    },
    giphyApiKey: 'clAlNSjlLlS0jboQnTWmW9bHNIeEvd09',
    logLevel: 'info',
    reduxDevTools: true,
    rtmURLRoot: `ws://${host}:9000`,
    versionSuffix: 'D',
  }
}
