import React from 'react'
import { StyleSheet } from 'react-native'
import Background from '@/components/core/Background'
import DragView, { PreventDragView } from '@/components/core/DragView'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import TaskCompleteCheckbox from '@/components/tasks/TaskCompleteCheckbox'
import TaskDetailsList from '@/components/tasks/TaskDetailsList'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { platformUtils, taskUtils } from '@/core/utils'
import TaskListItemFocus from './TaskListItemFocus'
import TaskSelectCheckbox from './TaskSelectCheckbox'
import { TaskListItemProps } from './types'

function TaskContent({
  task,
  focus,
  onPress,
  onInitiateMove,
  showAssignee,
  showMyDay,
  renderCustomFocus,
  isSelected,
  onSelectTask,
}: TaskListItemProps) {
  const handlePress = React.useCallback(() => onPress(task), [onPress, task])
  const completed = taskUtils.isCompleted(task)
  const draggable = platformUtils.isWeb && onInitiateMove
  const canComplete = !onSelectTask
  const tasksListContext = useTasksListViewContext()

  const completeCheckbox = <TaskCompleteCheckbox disabled={!canComplete} task={task} />
  const focusContent = (renderCustomFocus && renderCustomFocus(task, focus)) || (
    <TaskListItemFocus focus={focus} task={task} />
  )

  return (
    <DragView onDragStart={onInitiateMove} onPress={handlePress} style={styles.container}>
      {onSelectTask ? (
        <Box gapRight="medium" justifyContent="center">
          <TaskSelectCheckbox isSelected={isSelected} onSelect={onSelectTask} taskId={task.id} />
        </Box>
      ) : null}
      <Background
        borderRadius={10}
        color={completed ? 'silver' : tasksListContext.taskBackground}
        flex={1}
      >
        <Box alignItems="center" direction="row" gapAll="medium">
          {completeCheckbox ? (
            <Box alignSelf="flex-start" gapRight="medium">
              {draggable ? <PreventDragView>{completeCheckbox}</PreventDragView> : completeCheckbox}
            </Box>
          ) : null}
          <Box direction="column" flex={1} gapRight="small">
            <TextWithLinks color={completed ? 'koala' : 'obsidian'} italic={completed}>
              {task.content}
            </TextWithLinks>
            <TaskDetailsList showAssignee={showAssignee} showMyDay={showMyDay} task={task} />
          </Box>
          {draggable ? <PreventDragView>{focusContent}</PreventDragView> : focusContent}
        </Box>
      </Background>
    </DragView>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})

export default React.memo(TaskContent)
