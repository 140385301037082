import React from 'react'
import Box from '@/components/layouts/Box'
import { taskUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'
import YesNoResponse from './YesNoResponse'
import { useSubmitResponse } from './hooks'
import { QuestionDisabled } from './types'

interface YesNoResponseContainerProps {
  disabled: QuestionDisabled
  onAnswered?: () => void
  task: QuestionTask
}

function YesNoResponseContainer({ task, disabled, onAnswered }: YesNoResponseContainerProps) {
  const { submitResponse, isSubmitting } = useSubmitResponse(task, onAnswered)
  const isAnswered = taskUtils.isAnswered(task)
  const submitOnChange = !isAnswered
  const isDisabled =
    disabled === true || isSubmitting || (isAnswered && disabled === 'WHEN_ANSWERED')

  return (
    <Box>
      <YesNoResponse
        disabled={isDisabled}
        onSubmit={submitResponse}
        submitOnChange={submitOnChange}
        task={task}
      />
    </Box>
  )
}

export default React.memo(YesNoResponseContainer)
