import React from 'react'
import ProfilePicture, { ProfilePictureProps } from '@/components/core/ProfilePicture'
import { spaceUtils } from '@/core/utils'
import { Space } from '@/types/entities'
import { Optional } from '@/types/generics'

interface SpacePictureProps extends Omit<ProfilePictureProps, 'url' | 'initials'> {
  space: Optional<Space>
}

function SpacePicture({ space, shape = 'square', ...profilePictureProps }: SpacePictureProps) {
  return (
    <ProfilePicture
      {...profilePictureProps}
      initials={space ? spaceUtils.getInitials(space) : '?'}
      shape={shape}
      url={space ? space.iconImageUrl : null}
    />
  )
}

export default React.memo(SpacePicture)
