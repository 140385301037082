import React from 'react'
import { QuestionMode } from '@/types/tasks'
import MultipleChoiceQuestionEditor from './MultipleChoiceQuestionEditor'
import OpenEndedQuestionEditor from './OpenEndedQuestionEditor'
import YesNoQuestionEditor from './YesNoQuestionEditor'

interface QuestionEditorProps {
  questionMode: QuestionMode
}

function QuestionEditor({ questionMode }: QuestionEditorProps) {
  if (questionMode === 'YES_NO') {
    return <YesNoQuestionEditor />
  }

  if (questionMode === 'MULTIPLE_CHOICE') {
    return <MultipleChoiceQuestionEditor />
  }

  if (questionMode === 'OPEN_ENDED') {
    return <OpenEndedQuestionEditor />
  }

  throw new Error(`Invalid question mode: ${questionMode}`)
}

export default React.memo(QuestionEditor)
