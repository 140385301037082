import { dateUtils as D } from '@/core/utils'
import { zeroPad } from '@/core/utils/numbers'

export type Meridiem = 'AM' | 'PM'

const splitTime = (timeNumber: number): [number, number] => {
  const hours = Math.floor(timeNumber / 100)
  const minutes = timeNumber % 100

  return [hours, minutes]
}

export const dateToTimeNumber = (date: Date) => {
  const hours = date.getHours()

  if (hours > 12) {
    return (hours - 12) * 100 + date.getMinutes()
  }

  if (hours === 0) {
    return 1200 + date.getMinutes()
  }

  return hours * 100 + date.getMinutes()
}

export const toMeridiem = (date: Date): Meridiem => (date.getHours() >= 12 ? 'PM' : 'AM')

export const formatTime = (timeNumber: number) => {
  const [hours, minutes] = splitTime(timeNumber)
  if (hours) {
    if (hours > 12) {
      return `${hours - 12}:${zeroPad(minutes)}`
    }
    return `${hours}:${zeroPad(minutes)}`
  }
  return minutes.toString()
}

const isValidTimeNumber = (timeNumber: number): boolean => {
  const [hours, minutes] = splitTime(timeNumber)

  return hours >= 0 && hours <= 12 && minutes >= 0 && minutes <= 59
}

// 92 is on the way to 9:23, for instance
const isValidTimeNumberPrefix = (timeNumber: number) =>
  timeNumber >= 60 && timeNumber <= 95 && timeNumber % 10 <= 5

export const toValidTimeNumber = (timeNumber: number): number => {
  if (isValidTimeNumber(timeNumber) || isValidTimeNumberPrefix(timeNumber)) {
    return timeNumber
  }

  // At this point we know that it's not a valid number, and it's not on its way to a valid number,
  // so we strip it down to the last number entered and start over there.
  return toValidTimeNumber(timeNumber % 10)
}

const isValidTime = (timeNumber: number): boolean =>
  isValidTimeNumber(timeNumber) && timeNumber >= 100

export const timeNumberToDate = (date: Date, timeNumber: number, meridiem: Meridiem): Date => {
  if (!isValidTime(timeNumber)) {
    return date
  }
  const [hours, minutes] = splitTime(timeNumber)

  if (hours === 12) {
    return D.setTime(date, meridiem === 'AM' ? 0 : 12, minutes)
  }

  return D.setTime(date, meridiem === 'AM' ? hours : hours + 12, minutes)
}
