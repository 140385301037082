import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { fnUtils } from '@/core/utils'
import CompleteCheckbox from '../TaskCompleteCheckbox'

function TaskCompleteCheckbox() {
  const { interactionMode, task } = useTaskFormContext()

  if (interactionMode === 'edit' && task) {
    return <CompleteCheckbox task={task} />
  }

  return <Checkbox checked={!!task?.completedAt} disabled onChange={fnUtils.noOp} shape="square" />
}

export default React.memo(TaskCompleteCheckbox)
