import React from 'react'
import { RRule } from 'rrule'
import SelectListItem from '@/components/core/SelectListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface RecurrenceRuleListItemProps {
  rrule: RRule | null
  isSelected: boolean
  onSelect: (rrule: RRule | null) => void
}

function RecurrenceRuleListItem({ rrule, isSelected, onSelect }: RecurrenceRuleListItemProps) {
  const handleSelect = React.useCallback(() => onSelect(rrule), [onSelect, rrule])
  const label = rrule ? `Recurs ${rrule.toText()}` : 'Does not Recur'

  return (
    <SelectListItem isSelected={isSelected} onPress={handleSelect}>
      <Box flex={1}>
        <Text>{label}</Text>
      </Box>
    </SelectListItem>
  )
}

export default React.memo(RecurrenceRuleListItem)
