import React from 'react'
import { ColorName } from '@/core/constants/colors'
import { ActionOption } from '@/types/actions'
import Icon from '../Icon'
import ListItem from '../ListItem'
import Text from '../Text'
import { borderRadius } from './constants'

interface ActionsMenuItemProps<S> {
  option: ActionOption<S>
  subject: S
  position: 'first' | 'last' | 'middle'
}

function ActionsMenuItem<S>({ option, subject, position }: ActionsMenuItemProps<S>) {
  const handler = React.useCallback(() => option.handler(subject), [option, subject])
  const foreground: ColorName = option.type === 'destructive' ? 'red' : 'obsidian'

  return (
    <ListItem
      borderBottomRadius={position === 'last' ? borderRadius : undefined}
      borderColor={position === 'last' ? 'transparent' : undefined}
      borderTopRadius={position === 'first' ? borderRadius : undefined}
      justifyContent="space-between"
      onPress={handler}
    >
      <Text color={foreground} size="h4">
        {option.label}
      </Text>
      {option.icon ? <Icon color={foreground} name={option.icon} size="h4" /> : null}
    </ListItem>
  )
}

export default React.memo(ActionsMenuItem) as typeof ActionsMenuItem
