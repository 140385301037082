import React from 'react'
import { clipboardUtils } from '@/core/utils'
import { useTimedSwitch } from './timeout'

export const useCopyToClipboard = (value: string) => {
  const { isOn, turnOn } = useTimedSwitch({ animate: true, delay: 3000 })

  const handleCopyToClipboard = React.useCallback(async () => {
    const success = await clipboardUtils.writeToClipboard(value)
    if (success) {
      turnOn()
    }
  }, [value, turnOn])

  return {
    handleCopyToClipboard,
    wasCopied: isOn,
  }
}
