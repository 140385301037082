import React from 'react'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormField, useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { useAppDispatch, useAppSelector, useCachedSelector } from '@/hooks'
import { useDestructiveAlertAsync } from '@/hooks/actions'
import { boardActions } from '@/store/actions'
import { boardSelectors } from '@/store/selectors'
import { boardThunks } from '@/thunks'
import { Errors } from '@/types/api'
import { Board } from '@/types/entities'
import { CreateSegmentAction, useSegmentSelector } from '../TaskSegmentModal'

export const useBoardSelector = (onClose: (cancelled: boolean) => void, isOpen: boolean) => {
  const [createErrors, setCreateErrors] = React.useState<Errors>({})
  const dispatch = useAppDispatch()
  const spaceId = useTaskFormValue(fieldNames.spaceId)
  const channelId = useTaskFormValue(fieldNames.channelId)
  const { value: boardId, setValue: setBoardId } = useTaskFormField<number | null>(
    fieldNames.boardId,
  )
  const activeBoards = useCachedSelector(boardSelectors.byChannelIdSelector, [
    spaceId || 0,
    channelId || 0,
  ])
  const confirmDelete = useDestructiveAlertAsync(
    'Delete Project',
    'Delete',
    'Are you sure you want to delete this project?',
  )

  const {
    handleCancel,
    handleCreate,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isCreating,
    isSearching,
    searchResults,
    searchText,
    selectedIds,
    setSearchText,
  } = useSegmentSelector<Board, number>({
    allowOnlySingleSelection: true,
    commitChanges: React.useCallback(
      (nextIds: number[], search: Board[]) => {
        const nextId = nextIds.length === 0 ? null : nextIds[0]
        setBoardId(nextId)

        if (nextId) {
          const addBoard = search.find(p => p.id === nextId)
          if (addBoard) {
            dispatch(boardActions.upsertOne(addBoard))
          }
        }
      },
      [setBoardId, dispatch],
    ),
    createSegment: React.useCallback(
      async searchValue => {
        if (!searchValue) {
          return null
        }
        const response = await dispatch(
          boardThunks.createBoard(spaceId, channelId, { name: searchValue }),
        )

        if (!response.ok) {
          setCreateErrors(response.errors)
          return null
        }
        return response.data.board
      },
      [dispatch, spaceId, channelId],
    ),
    deleteSegment: React.useCallback(
      async (board: Board) => {
        const confirmed = await confirmDelete()

        if (!confirmed) {
          return false
        }

        const response = await dispatch(boardThunks.deleteBoard(board.spaceId, board.id))
        return response.ok
      },
      [confirmDelete, dispatch],
    ),
    editSegment: React.useCallback(
      async (updatedBoard: Board) => {
        const response = await dispatch(
          boardThunks.updateBoard(updatedBoard.spaceId, updatedBoard.id, updatedBoard),
        )

        if (response.ok) {
          return response.data.board
        }
        return null
      },
      [dispatch],
    ),
    getSegmentId: React.useCallback(board => board.id, []),
    initialSegments: activeBoards,
    initialSelectedIds: React.useMemo(() => (boardId ? [boardId] : []), [boardId]),
    isOpen,
    onClose,
    searchSegments: React.useCallback(
      async searchValue => {
        if (!searchValue) {
          return undefined
        }
        const search = searchValue.toLowerCase()
        return activeBoards.filter(board => board.name.toLowerCase().includes(search))
      },
      [activeBoards],
    ),
  })

  const boards = searchResults || activeBoards
  const matchText = searchText ? searchText.toLowerCase() : ''
  const hasExactMatch = React.useMemo(
    () => !!boards.find(board => board.name.toLowerCase() === matchText),
    [boards, matchText],
  )

  const allBoards = useAppSelector(boardSelectors.all)
  const searchMatchesNonActiveBoard = React.useMemo(
    () => !!allBoards.find(board => board.name.toLowerCase() === matchText),
    [allBoards, matchText],
  )

  const createBoardAction = React.useMemo<CreateSegmentAction>(
    () => ({
      canCreate: !!searchText && !hasExactMatch && !isSearching,
      isCreating,
      onCreate: handleCreate,
      segmentName: searchText || '',
    }),
    [searchText, hasExactMatch, isSearching, handleCreate, isCreating],
  )

  return {
    boards,
    createBoardAction,
    createErrors,
    handleCancel,
    handleCreate,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isCreating,
    isSearching,
    searchMatchesNonActiveBoard,
    searchText,
    selectedId: selectedIds && selectedIds.length !== 0 ? selectedIds[0] : null,
    setSearchText,
  }
}
