import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { messageUtils } from '@/core/utils'
import { RootState } from '@/store'
import { Message } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, messageId: number): string => `${spaceId}:${messageId}`

const adapter = createEntityAdapter<Message>({
  selectId: message => buildId(message.spaceId, message.id),
  // NOTE: We store the messages in reverse order because we invert the lists that display
  // them so they scroll to the bottom.
  sortComparer: (a, b) => b.id - a.id,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/messages',
  reducers: {
    removeMany: adapter.removeMany,
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const getId = adapter.selectId
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.messages)

const selectById = (state: RootState, spaceId: number, messageId: number) =>
  state.entities.messages.entities[buildId(spaceId, messageId)]

const byIdSelector = (spaceId: number, messageId: number) =>
  createSelector(
    sliceSelectors.selectEntities,
    messagesIndex => messagesIndex[buildId(spaceId, messageId)],
  )

const byChannelIdSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectAll, messages =>
    messages.filter(messageUtils.buildFilter({ channelId, spaceId })),
  )

const oldMessagesSelector = (spaceId: number, channelId: number, maxMessageId: number) =>
  createSelector(sliceSelectors.selectAll, messages =>
    messages.filter(
      message =>
        message.spaceId === spaceId && message.channelId === channelId && message.id < maxMessageId,
    ),
  )

const byThreadIdSelector = (spaceId: number, threadId: number, includeThreadStart: boolean) =>
  createSelector(sliceSelectors.selectAll, messages =>
    messages.filter(messageUtils.buildFilter({ includeThreadStart, spaceId, threadId })),
  )

const countByChannelIdSelector = (spaceId: number, channelId: number) =>
  createSelector(byChannelIdSelector(spaceId, channelId), messages => messages.length)

const countByThreadIdSelector = (spaceId: number, threadId: number, includeThreadStart: boolean) =>
  createSelector(
    byThreadIdSelector(spaceId, threadId, includeThreadStart),
    messages => messages.length,
  )

const previousMessageInThreadSelector = (
  spaceId: number,
  threadId: number,
  beforeMessageId: number,
) =>
  createSelector(byThreadIdSelector(spaceId, threadId, true), messages =>
    messages.find(message => message.id < beforeMessageId),
  )

const taskActivitiesInThread = (spaceId: number, threadId: number) =>
  createSelector(sliceSelectors.selectAll, messages =>
    messages.filter(
      message =>
        message.spaceId === spaceId &&
        (message.id === threadId || message.threadId === threadId) &&
        messageUtils.isTaskActivity(message),
    ),
  )

const lastTaskActivityInThreadSelector = (spaceId: number, threadId: number) =>
  createSelector(taskActivitiesInThread(spaceId, threadId), taskActivities =>
    taskActivities.length === 0 ? undefined : taskActivities[0],
  )

const lastMessageInChannelSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectAll, messages =>
    messages.find(m => m.spaceId === spaceId && m.channelId === channelId),
  )

const selectors = {
  byChannelIdSelector,
  byId: selectById,
  byIdSelector,
  byThreadIdSelector,
  countByChannelIdSelector,
  countByThreadIdSelector,
  lastMessageInChannelSelector,
  lastTaskActivityInThreadSelector,
  oldMessagesSelector,
  previousMessageInThreadSelector,
}

export { actions, selectors, getId, buildId }
export default reducer
