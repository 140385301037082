import React from 'react'
import { eventTypes } from '@/core/constants'
import { emitter } from '@/core/pubsub'
import {
  useAppDispatch,
  useAppSelector,
  useCachedSelector,
  useCurrentUserId,
  useFormValues,
} from '@/hooks'
import { useHasScope } from '@/hooks/auth'
import { channelUserSelectors, entitySelectors, messageSelectors } from '@/store/selectors'
import { messageThunks } from '@/thunks'
import { Task } from '@/types/entities'
import { useTaskFormValues } from '../hooks'

export const useTaskChat = (task: Task | undefined, formName: string, showThreadStart: boolean) => {
  const { spaceId, channelId } = useTaskFormValues()
  const { content } = useFormValues(formName)
  const dispatch = useAppDispatch()
  const userId = useCurrentUserId() || 0
  const taskId = task?.id || null
  const channel = useCachedSelector(entitySelectors.resolvedChannelSelector, [spaceId, channelId])
  const channelUser = useAppSelector(channelUserSelectors.byId, spaceId, channelId, userId)
  const messagesCount = useCachedSelector(messageSelectors.countByThreadIdSelector, [
    spaceId,
    taskId || 0,
    showThreadStart,
  ])
  const handleSubmit = React.useCallback(() => {
    dispatch(messageThunks.createMessageForm(spaceId, channelId, taskId, formName, true))
    emitter.emit(eventTypes.MESSAGE_SENT)
  }, [dispatch, spaceId, channelId, taskId, formName])
  const canSubmit = !!content
  const canComment = useHasScope('spaces', 'messages/create', `${spaceId}`)

  return {
    canComment,
    canSubmit,
    channel,
    channelUser,
    handleSubmit,
    messagesCount,
    task,
  }
}
