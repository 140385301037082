import React from 'react'
import UnreadMessagesInOtherChannelsBadge from '@/components/badges/UnreadMessagesInOtherChannelsBadge'
import UnreadMessagesInThisSpaceBadge from '@/components/badges/UnreadMessagesInThisSpaceBadge'
import Icon from '@/components/core/Icon'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'

interface SpaceBackButtonProps {
  channelId?: number
  color?: ColorName
  onBack: () => void
  spaceId?: number
}

function SpaceBackButton({ color = 'ghost', spaceId, channelId, onBack }: SpaceBackButtonProps) {
  const badge = (() => {
    if (spaceId) {
      if (channelId) {
        return <UnreadMessagesInOtherChannelsBadge channelId={channelId} spaceId={spaceId} />
      }
      return <UnreadMessagesInThisSpaceBadge spaceId={spaceId} />
    }
    return null
  })()

  return (
    <Touchable feedback="opacity" hitSlop="medium" onPress={onBack}>
      <Box alignItems="center" direction="row" justifyContent="space-between">
        <Box gapRight="medium">
          <Icon color={color} name="chevron-left" size="h2" />
        </Box>
        {badge}
      </Box>
    </Touchable>
  )
}

export default React.memo(SpaceBackButton)
