import React from 'react'
import { ScrollView } from 'react-native'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import SpaceSelector from '@/components/spaces/SpaceSelector'
import { Space } from '@/types/entities'

interface SpaceSelectorModalProps {
  onCancel?: () => void
  onClose: () => void
  onSelectSpace: (space: Space) => void
  excludeSpaceId?: number
}

function SpaceSelectorModal({
  excludeSpaceId,
  onCancel,
  onClose,
  onSelectSpace,
}: SpaceSelectorModalProps) {
  const handleCancel = React.useCallback(() => {
    onClose()
    onCancel?.()
  }, [onClose, onCancel])

  const handleSelect = React.useCallback(
    (space: Space) => {
      onClose()
      onSelectSpace(space)
    },
    [onClose, onSelectSpace],
  )

  return (
    <ModalLayout
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      title="Select Space"
    >
      <Box flex={1} gapAll="large">
        <ScrollView>
          <SpaceSelector excludeSpaceId={excludeSpaceId} onSelectionChange={handleSelect} />
        </ScrollView>
      </Box>
    </ModalLayout>
  )
}

export default React.memo(SpaceSelectorModal)
