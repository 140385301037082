import React from 'react'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { otherMessageWidth } from '@/components/messages/MessagesList/constants'
import { useMessagesListContext } from '@/contexts/MessagesListContext'
import { messageUtils, stringUtils, taskUtils, threadUtils } from '@/core/utils'
import { MessageListItemData } from '@/types/messaging'

interface ThreadIndicatorProps {
  item: MessageListItemData
  isFromMe: boolean
}

function ThreadIndicator({ item, isFromMe }: ThreadIndicatorProps) {
  const { message, thread, previousMessageInThread, parentTask, parentMessage } = item
  const { spaceId, id: messageId } = message
  const { showThread } = useMessagesListContext()

  const handleShowMessageThread = React.useCallback(
    () => showThread(spaceId, messageId),
    [showThread, spaceId, messageId],
  )

  if (!thread || thread.replyCount === 0) {
    return null
  }

  let text: string

  const isThreadHead = threadUtils.isThreadHead(thread, message)

  if (isThreadHead) {
    text = stringUtils.pluralize(thread.replyCount, 'Reply', 'Replies')

    if (thread.isIgnored) {
      text = `${text} (silenced)`
    }
  } else if (previousMessageInThread) {
    if (previousMessageInThread.content) {
      text = previousMessageInThread.content
    } else {
      text = 'Attachment'
    }
  } else {
    text = thread.title
  }

  const icon = (() => {
    if (isThreadHead) {
      return null
    }
    if (parentTask) {
      if (taskUtils.isQuestion(parentTask)) {
        return <Icon color="emmre-purple" kind="solid" name="square-question" size="h6" />
      }
      return <Icon color="emmre-green" kind="solid" name="clipboard-check" size="h6" />
    }

    if (
      messageUtils.isSummaryActivity(message) ||
      (parentMessage && messageUtils.isSummaryActivity(parentMessage))
    ) {
      return <Icon color="cyan" kind="solid" name="clipboard-list-check" size="h6" />
    }

    return <Icon color="teal" kind="solid" name="reply" size="h6" />
  })()

  return (
    <Touchable feedback="opacity" onPress={handleShowMessageThread}>
      <Box alignItems="center" direction="row" gapBottom="xsmall" gapTop="none">
        {isFromMe ? <Box flex={1} /> : null}
        {icon}
        <Box gapLeft="xsmall" maxWidth={otherMessageWidth}>
          <Text color="anchor" ellipsizeMode="tail" numberOfLines={1} size="h6">
            {text}
          </Text>
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(ThreadIndicator)
