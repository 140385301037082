import React from 'react'
import { taskUtils } from '@/core/utils'
import QuestionContent from './QuestionContent'
import TaskContent from './TaskContent'
import type { RenderCustomFocus, TaskListItemProps } from './types'

export type { RenderCustomFocus }

function TaskListItem(props: TaskListItemProps) {
  const { task, onPress, onSelectTask, isSelected } = props
  if (taskUtils.isQuestion(task)) {
    return (
      <QuestionContent
        isSelected={isSelected}
        onPress={onPress}
        onSelectTask={onSelectTask}
        task={task}
      />
    )
  }
  return <TaskContent {...props} />
}

export default React.memo(TaskListItem)
