import { Request } from '@/api/call'
import { fileUtils } from '@/core/utils'
import { ProgressEvent } from '@/types/api'
import { File } from '@/types/entities'

export interface FileInfo {
  uri: string
  filename: string
  contentType: string
}

export interface FileResponse {
  file: File
}

export const uploadFile = (fileInfo: FileInfo, onUploadProgress?: (event: ProgressEvent) => void) =>
  new Request<FileResponse>({
    authentication: 'PRIVATE',
    body: fileBody(fileInfo),
    method: 'POST',
    onUploadProgress,
    url: 'v1/files',
  })

export const uploadSpaceFile = (
  spaceId: number,
  fileInfo: FileInfo,
  onUploadProgress?: (event: ProgressEvent) => void,
) =>
  new Request<FileResponse>({
    authentication: 'PRIVATE',
    body: fileBody(fileInfo),
    method: 'POST',
    onUploadProgress,
    url: `v1/spaces/${spaceId}/files`,
  })

const fileBody = (fileInfo: FileInfo): FormData => {
  const form = new FormData()

  if (fileUtils.isDataURI(fileInfo.uri)) {
    form.append('file', fileUtils.dataURIToFile(fileInfo.uri, fileInfo.filename))
  } else {
    const data = { name: fileInfo.filename, type: fileInfo.contentType, uri: fileInfo.uri }
    // @ts-ignore
    form.append('file', data)
  }

  return form
}
