import React from 'react'
import { Animated } from 'react-native'
import { getColor } from '@/core/constants/colors'

interface PillProps {
  left: number
  width: number
  duration: number
}

function Pill({ left, width, duration }: PillProps) {
  const firstRenderRef = React.useRef<boolean>(false)
  const leftAnimation = React.useRef(new Animated.Value(0))
  const widthAnimation = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    let timingDuration: number

    if (!firstRenderRef.current) {
      firstRenderRef.current = true
      timingDuration = 0
    } else {
      timingDuration = duration
    }

    Animated.timing(leftAnimation.current, {
      duration: timingDuration,
      toValue: left,
      useNativeDriver: false,
    }).start()
    Animated.timing(widthAnimation.current, {
      duration: timingDuration,
      toValue: width,
      useNativeDriver: false,
    }).start()
  }, [left, width, duration])

  return (
    <Animated.View
      style={{
        backgroundColor: getColor('koala'),
        borderRadius: 10,
        height: '100%',
        left: leftAnimation.current,
        position: 'absolute',
        width: widthAnimation.current,
      }}
    />
  )
}

export default React.memo(Pill)
