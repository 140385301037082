import React from 'react'
import { messageUtils } from '@/core/utils'
import { ChannelUser } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'

export const useFirstUnreadMessageId = (
  channelUser: ChannelUser,
  messageItems: MessageListItemData[],
  atBeginning: boolean,
): number | undefined =>
  React.useMemo(() => {
    let minLoadedId: number | undefined
    let minUnreadId: number | undefined

    messageItems.forEach(({ message }) => {
      if (messageUtils.isUnsentMessage(message)) {
        return
      }

      if (minLoadedId === undefined || message.id < minLoadedId) {
        minLoadedId = message.id
      }

      if (
        message.creatorId !== channelUser.userId &&
        message.id > channelUser.maxReadMessageId &&
        (minUnreadId === undefined || message.id < minUnreadId)
      ) {
        minUnreadId = message.id
      }
    })

    if (atBeginning) {
      return minUnreadId
    }

    if (minLoadedId === undefined) {
      return undefined
    }

    // If the minimum loaded ID is greater than the max read message ID, that means the first
    // unread message is possibly in the previous page, so we will report the first unread message
    // ID as undefined.
    if (minLoadedId > channelUser.maxReadMessageId) {
      return undefined
    }

    return minUnreadId
  }, [channelUser, messageItems, atBeginning])
