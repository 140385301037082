import React from 'react'
import { Image, ImageProps, ImageStyle, StyleSheet } from 'react-native'
import { componentUtils, fileUtils } from '@/core/utils'
import { getRandomUUID } from '@/core/utils/uuid'
import { useIsMounted } from '@/hooks/components'
import { ComponentProp, Measurement, MeasurementFit } from '@/types/components'
import { CacheDuration } from '@/types/files'
import ActivityIndicator from './ActivityIndicator'

export interface CachedImageProps extends Omit<ImageProps, 'source'> {
  ErrorComponent?: ComponentProp | null | undefined
  LoadingComponent?: ComponentProp | null | undefined
  duration: CacheDuration
  fit?: MeasurementFit
  size: Measurement
  onError?: () => void
  style?: ImageStyle
  uri: string
}

function CachedImage({
  ErrorComponent,
  LoadingComponent,
  duration,
  size,
  fit = 'LARGEST',
  onError,
  style,
  uri,
  ...imageProps
}: CachedImageProps) {
  // This needs to start as null for caching to work properly
  const [imageURI, setImageURI] = React.useState<string | null>(null)
  const [scaledSize, setScaledSize] = React.useState<Measurement | null>(null)
  const [isError, setIsError] = React.useState(false)
  const traceIdRef = React.useRef(getRandomUUID())
  const isMountedRef = useIsMounted()
  const handleError = React.useCallback(async () => {
    // Fallback to the remote URI
    if (imageURI !== uri) {
      setImageURI(uri)
    } else {
      setIsError(true)
    }
  }, [setIsError, imageURI, uri])

  const cacheImage = React.useCallback(async () => {
    if (!isMountedRef.current) {
      return
    }

    const localURI = await fileUtils.cacheFile(uri, duration, traceIdRef.current)

    if (!isMountedRef.current) {
      return
    }

    if (!localURI) {
      setIsError(true)
      if (onError) {
        onError()
      }
      return
    }

    setImageURI(localURI)
  }, [uri, duration, setImageURI, onError, isMountedRef])

  const scaleSize = React.useCallback(
    async (uriToScale: string | null) => {
      if (!isMountedRef.current) {
        return
      }
      if (!uriToScale) {
        return
      }

      const scaled = await getScaledSize(fit, size, uriToScale)
      setScaledSize(scaled)
    },
    [fit, size, setScaledSize, isMountedRef],
  )

  const finalSize = scaledSize || size

  React.useEffect(() => {
    cacheImage()
  }, [cacheImage])

  React.useEffect(() => {
    scaleSize(imageURI)
  }, [scaleSize, imageURI])

  if (isError) {
    return ErrorComponent ? componentUtils.renderComponent(ErrorComponent) : null
  }

  if (!imageURI) {
    return LoadingComponent ? (
      componentUtils.renderComponent(LoadingComponent)
    ) : (
      <ActivityIndicator color="obsidian" delay={300} size={16} />
    )
  }

  return (
    <Image
      {...imageProps}
      onError={handleError}
      source={{ uri: imageURI }}
      style={[finalSize, styles.image, style]}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    maxWidth: '100%',
  },
})

const getScaledSize = (
  fit: MeasurementFit,
  size: Measurement,
  uri: string,
): Promise<Measurement | null> =>
  new Promise(resolve => {
    Image.getSize(
      uri,
      (width, height) => resolve(componentUtils.fitSize(fit, size, { height, width })),
      () => resolve(null),
    )
  })

export default React.memo(CachedImage)
