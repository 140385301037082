import React from 'react'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { leftIconWidth } from '@/components/tasks/TaskForm/constants'
import { useTaskFormValues } from '@/components/tasks/TaskForm/hooks'
import { dateUtils as D } from '@/core/utils'
import TaskDate from './TaskDate'

function TaskDueDate() {
  const { dueDateType, dueDate } = useTaskFormValues()

  const date = D.tryParse(dueDate)
  let label: React.ReactNode

  if (dueDateType === 'DATE' && date) {
    label = <TaskDate date={date} />
  } else if (dueDateType === 'SOMEDAY') {
    label = (
      <Text color="shadow" size="h6">
        Someday
      </Text>
    )
  } else {
    label = (
      <Text color="shadow" size="h6">
        No Due Date
      </Text>
    )
  }

  return (
    <Box alignItems="center" direction="row">
      <Box width={leftIconWidth}>
        <Icon color="shadow" kind="light" name="calendar-check" size="h4" />
      </Box>
      <Box direction="column">{label}</Box>
    </Box>
  )
}

export default React.memo(TaskDueDate)
