import React from 'react'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import EditUserForm from '@/components/users/EditUserForm'
import { SpaceUser } from '@/types/entities'

interface EditUserModalProps {
  user: SpaceUser
  onClose: () => void
}

function EditUserModal({ user, onClose }: EditUserModalProps) {
  return (
    <ModalLayout
      leftAction={{ label: 'Cancel', onAction: onClose, type: 'linkPrimary' }}
      maxWidth={360}
      title="Edit Name"
    >
      <Box gapAll="large">
        <EditUserForm onDone={onClose} user={user} />
      </Box>
    </ModalLayout>
  )
}

export default React.memo(EditUserModal)
