import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'
import { getColor } from '@/core/constants/colors'
import Touchable from '../../Touchable'
import { SwipeableItemClose } from './types'

interface ListItemOverlayProps<S> {
  children: React.ReactNode
  close: SwipeableItemClose
  containerStyle?: StyleProp<ViewStyle>
  itemStyle?: StyleProp<ViewStyle>
  onLongPress?: (subject: S) => void
  onPress?: (subject: S) => void
  percentOpenLeft?: Animated.DerivedValue<number>
  percentOpenRight?: Animated.DerivedValue<number>
  subject: S
}

function ListItemOverlay<S>({
  children,
  close,
  containerStyle,
  itemStyle,
  onLongPress,
  onPress,
  percentOpenLeft,
  percentOpenRight,
  subject,
}: ListItemOverlayProps<S>) {
  const getOpenPercent = React.useCallback(
    () => (percentOpenLeft?.value || 0) + (percentOpenRight?.value || 0),
    [percentOpenLeft, percentOpenRight],
  )
  const pressStartOpenPercentRef = React.useRef(0)

  // We want several interactions:
  // 1) we want to parent list to be scrollable, so we have to properly respond to vertical scroll
  //    gestures
  // 2) we do not want to trigger onPress for swipe events
  // 3) when an item is open and another item is tapped or swiped we want the open items to close
  // 4) when an item is open and it is tapped we want it to close but not trigger the onPress
  //    callback
  const handlePressIn = React.useCallback(() => {
    close('others')
    pressStartOpenPercentRef.current = getOpenPercent()
  }, [getOpenPercent, close])
  const handlePressOut = React.useCallback(() => {
    if (pressStartOpenPercentRef.current > 0) {
      close('self')
    } else if (onPress && getOpenPercent() < 0.05) {
      onPress(subject)
    }
  }, [getOpenPercent, onPress, close, subject])

  const handleLongPress = React.useCallback(() => {
    if (onLongPress) {
      close('self')
      onLongPress(subject)
    }
  }, [onLongPress, subject, close])

  return (
    <View style={[styles.item, itemStyle]}>
      <Touchable
        feedback={onPress || onLongPress ? 'opacity' : 'none'}
        onLongPress={onLongPress ? handleLongPress : undefined}
        onPress={handlePressOut}
        onPressIn={handlePressIn}
        style={[styles.container, containerStyle]}
      >
        {children}
      </Touchable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: getColor('ghost'),
    flex: 1,
    flexDirection: 'row',
  },
  item: {
    alignItems: 'center',
    backgroundColor: getColor('ghost'),
    flex: 1,
    flexDirection: 'row',
  },
})

export default React.memo(ListItemOverlay) as typeof ListItemOverlay
