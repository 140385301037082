import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import EditSummaryView from '@/components/summaries/EditSummaryView'
import ViewSummaryView from '@/components/summaries/ViewSummaryView'
import { SummaryModalHookArgs, useSummaryModal } from './hooks'

interface SummaryModalProps extends SummaryModalHookArgs {
  onClose: () => void
}

function SummaryModal({ onClose, ...hookArgs }: SummaryModalProps) {
  const {
    summary,
    status,
    message,
    mode,
    rightAction,
    handleEditSummary,
    handleSelectTasks,
    handleShowSummary,
  } = useSummaryModal(hookArgs)

  const title = (() => {
    if (status === 'READY' || status === 'PENDING') {
      return 'Loading Executive Brief...'
    }
    if (mode === 'EDIT') {
      return null
    }
    return summary ? (
      <Text textTransform="uppercase">{summary.title}</Text>
    ) : (
      'Executive Brief Not Found'
    )
  })()

  const content = (() => {
    if (status === 'READY' || status === 'PENDING') {
      return (
        <Box gapAll="large">
          <ActivityIndicator color="obsidian" size="large" />
        </Box>
      )
    }
    if (summary) {
      if (mode === 'VIEW') {
        return <ViewSummaryView onEditSummary={handleEditSummary} summary={summary} />
      }
      if (mode === 'EDIT') {
        return (
          <EditSummaryView
            onSelectTasks={handleSelectTasks}
            onShowSummary={handleShowSummary}
            summary={summary}
          />
        )
      }
      return null
    }

    return (
      <Box direction="row" gapAll="large" justifyContent="center">
        <Text color="red">{message || 'Executive Brief Not Found'}</Text>
      </Box>
    )
  })()

  const isPreview = mode === 'VIEW' && summary?.status === 'OPEN'

  return (
    <ModalLayout
      fullHeight
      headerColor={isPreview ? 'emmre-yellow' : undefined}
      insetBottom={false}
      leftAction={{
        label: 'Close',
        onAction: onClose,
        type: 'linkPrimary',
      }}
      onCancel={onClose}
      rightAction={rightAction}
      title={isPreview ? 'This is only a preview' : title}
    >
      {content}
    </ModalLayout>
  )
}

export default React.memo(SummaryModal)
