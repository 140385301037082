import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import TextWithLinks from '@/components/core/TextWithLinks'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { QuestionResponse, QuestionResponseType } from '@/types/entities'

interface MultipleChoiceResponseItemProps {
  foregroundColor: ColorName
  index: number
  disabled: boolean
  isSelected: boolean
  onChange: (value: boolean, state: string) => void
  response: QuestionResponse
  responseType: QuestionResponseType
}

function MultipleChoiceResponseItem({
  foregroundColor,
  index,
  disabled,
  isSelected,
  onChange,
  response,
  responseType,
}: MultipleChoiceResponseItemProps) {
  const handleChange = React.useCallback(() => {
    onChange(!isSelected, response.id.toString())
  }, [isSelected, response.id, onChange])

  return (
    <Box
      key={response.id}
      alignItems="flex-start"
      direction="row"
      gapTop={index === 0 ? 'none' : 'small'}
    >
      <Checkbox
        checked={isSelected}
        disabled={disabled}
        onChange={onChange}
        shape={responseType === 'SINGLE' ? 'circle' : 'square'}
        state={response.id.toString()}
      />
      <Box flex={1} gapLeft="small" gapTop={3}>
        <Touchable disabled={disabled} feedback="opacity" onPress={handleChange}>
          <TextWithLinks
            color={isSelected ? 'black' : foregroundColor}
            italic={disabled}
            size="h5"
            weight={isSelected ? 'medium' : 'regular'}
          >
            {response.content}
          </TextWithLinks>
        </Touchable>
      </Box>
    </Box>
  )
}

export default React.memo(MultipleChoiceResponseItem)
