import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'
import { ColorName } from '@/core/constants/colors'
import { numberUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'
import MultipleChoiceResponseItem from './MultipleChoiceResponseItem'
import OpenEndedResponseDisplay from './OpenEndedResponseDisplay'

interface MultipleChoiceResponseProps {
  foregroundColor: ColorName
  disabled: boolean
  task: QuestionTask
}

function MultipleChoiceResponse({ foregroundColor, disabled, task }: MultipleChoiceResponseProps) {
  const { question } = task
  const { responseForm, setOpenEndedResponse, setResponseSelected, setOtherSelected } =
    useQuestionResponseContext()

  const handleResponseSelectedChange = React.useCallback(
    (selected: boolean, state: string) => {
      const responseId = numberUtils.toInt(state) || 0
      setResponseSelected(responseId, selected)
    },
    [setResponseSelected],
  )

  const showOpenEnded = !!((!disabled && question.isOpenEnded) || !!responseForm.openEndedResponse)

  return (
    <>
      {question.responses.map((response, index) => (
        <MultipleChoiceResponseItem
          key={response.id}
          disabled={disabled}
          foregroundColor={foregroundColor}
          index={index}
          isSelected={responseForm.selectedResponseIds.includes(response.id)}
          onChange={handleResponseSelectedChange}
          response={response}
          responseType={question.responseType}
        />
      ))}
      {showOpenEnded ? (
        <Box alignItems="flex-start" direction="row" gapTop="small">
          <Box gapTop={disabled ? undefined : 2}>
            <Checkbox
              checked={responseForm.otherSelected}
              disabled={disabled}
              onChange={setOtherSelected}
              shape={question.responseType === 'SINGLE' ? 'circle' : 'square'}
            />
          </Box>
          <Box flex={1} gapLeft="small">
            {disabled ? (
              <OpenEndedResponseDisplay>{responseForm.openEndedResponse}</OpenEndedResponseDisplay>
            ) : (
              <TextInput
                kind="question"
                multiline
                onChangeText={setOpenEndedResponse}
                placeholder="Other..."
                value={responseForm.openEndedResponse}
              />
            )}
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export default React.memo(MultipleChoiceResponse)
