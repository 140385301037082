import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Button from '@/components/core/Button'
import WebDraggableFlatList, {
  DragListRenderItemInfo,
  WebDraggableListItem,
} from '@/components/core/WebDraggableFlatList'
import Box from '@/components/layouts/Box'
import { Summary } from '@/types/entities'
import { useEditSummaryContext } from './EditSummaryContext'
import EditSummaryListItem from './EditSummaryListItem'
import { useEditSummaryItems } from './hooks'
import { EditItemData } from './types'

interface EditSummaryItemsListProps {
  summary: Summary
  onShowSummary: (summary: Summary) => void
}

const keyExtractor = (item: EditItemData) =>
  item.type === 'summaryItem' ? item.summaryItem.id.toString() : item.type

function EditSummaryItemsList({ summary, onShowSummary }: EditSummaryItemsListProps) {
  const { editItems, isLoading } = useEditSummaryItems(summary)
  const { dragBegin, dragEnd, editingItemId } = useEditSummaryContext()
  const handlePreview = React.useCallback(() => onShowSummary(summary), [onShowSummary, summary])

  const renderItem = React.useCallback(
    ({ item, index, isActive }: DragListRenderItemInfo<EditItemData>) => {
      const draggable = item.type === 'summaryItem' && item.summaryItem.id !== editingItemId

      return (
        <WebDraggableListItem draggable={draggable} index={index}>
          <Box gapHorizontal="large" gapTop="medium">
            <EditSummaryListItem index={index} isDragging={isActive} item={item} />
          </Box>
        </WebDraggableListItem>
      )
    },
    [editingItemId],
  )

  return (
    <WebDraggableFlatList<EditItemData>
      ListFooterComponent={
        <Box direction="row" gapVertical="xlarge" justifyContent="space-around">
          <Button
            kind="primary"
            label="Preview"
            labelSize="h5"
            onPress={handlePreview}
            testID="preview_summary_button"
          />
        </Box>
      }
      ListHeaderComponent={
        isLoading ? (
          <Box alignItems="center" gapAll="large" justifyContent="center">
            <ActivityIndicator color="obsidian" />
          </Box>
        ) : null
      }
      data={editItems}
      extraData={renderItem}
      keyExtractor={keyExtractor}
      keyboardShouldPersistTaps="handled"
      onDragBegin={dragBegin}
      onDragEnd={dragEnd}
      renderItem={renderItem}
    />
  )
}

// ts-unused-exports:disable-next-line
export default React.memo(EditSummaryItemsList)
