import { sentryProxy } from '@/core/instrumentation'
import { fileUtils } from '@/core/utils'
import { resetActions } from '@/store/actions'
import { Thunk } from '@/types/store'
import { clearBadge } from '../notifications'

export const signOut =
  (reason: string | null): Thunk<void> =>
  async dispatch => {
    if (reason) {
      sentryProxy.captureMessage(reason)
    }
    dispatch(resetActions.resetStore())
    await clearBadge()
    await fileUtils.clearCache()
  }
