import React from 'react'
import UnreadMessagesInThisSpaceBadge from '@/components/badges/UnreadMessagesInThisSpaceBadge'
import BaseIconButton from '@/components/core/BaseIconButton'
import ListItem from '@/components/core/ListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import { Space } from '@/types/entities'
import SpacePicture from '../SpacePicture'

interface SpaceSelectorItemProps {
  space: Space
  selected: boolean
  onSelectionChange: (space: Space) => void
  onMore?: (space: Space) => void
  isLast: boolean
}

function SpaceSelectorItem({
  space,
  selected,
  onSelectionChange,
  onMore,
  isLast,
}: SpaceSelectorItemProps) {
  const handleSelectionChange = React.useCallback(
    () => onSelectionChange(space),
    [onSelectionChange, space],
  )
  const handleMore = React.useCallback(() => onMore?.(space), [onMore, space])

  return (
    <ListItem
      backgroundColor={selected ? 'heather' : 'transparent'}
      borderColor={isLast ? 'transparent' : undefined}
      inset="large"
      onPress={handleSelectionChange}
    >
      <Box>
        <SpacePicture space={space} />
        <FloatContainer right={-5} top={-5}>
          <UnreadMessagesInThisSpaceBadge spaceId={space.id} />
        </FloatContainer>
      </Box>
      <Box flex={1} gapHorizontal="medium">
        <Text>{space.name}</Text>
      </Box>
      {onMore ? (
        <BaseIconButton
          color="obsidian"
          kind="solid"
          name="ellipsis-h"
          onPress={handleMore}
          size="h4"
        />
      ) : null}
    </ListItem>
  )
}

export default React.memo(SpaceSelectorItem)
