import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { taskAPI } from '@/api/requests'
import { channelUserActions } from '@/store/actions'
import { QuestionResponseType } from '@/types/entities'
import { Optional } from '@/types/generics'
import { SyncThunk, Thunk } from '@/types/store'
import { TaskNotify } from '@/types/tasks'
import { updateChannelStats } from '../channels'
import { addMessage, addMessages, addTask, addTasks } from '../store'
import { makeEnhancedRequest } from '../utils'
import { flagTaskAttribute } from './attributes'

export const getTasks =
  (spaceId: number, args: taskAPI.GetTasksArgs): RequestThunk<typeof taskAPI.getTasks> =>
  async dispatch => {
    const request = taskAPI.getTasks(spaceId, args)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { tasks } = response.data
      if (tasks.length !== 0) {
        dispatch(addTasks(response.data.tasks))
      }
    }

    return response
  }

export const createTask =
  (
    spaceId: number,
    channelId: number,
    messageId: Optional<number>,
    props: taskAPI.CreateTask,
    notify: TaskNotify,
  ): RequestThunk<typeof taskAPI.createTask> =>
  async dispatch => {
    const request = messageId
      ? taskAPI.createTaskFromMessage(spaceId, messageId, props, notify)
      : taskAPI.createTask(spaceId, channelId, props, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { task, message, comments, channelUser } = response.data

      batch(() => {
        dispatch(addTask(task))
        dispatch(addMessage(message))
        dispatch(updateChannelStats(message))
        dispatch(channelUserActions.upsertOne(channelUser))

        if (comments) {
          dispatch(addMessages(comments))
        }
      })

      dispatch(flagTaskAttribute(task))
    }

    return response
  }

export type QuestionFields = {
  assignedUserId: number
  channelId: number
  content: string
  isOpenEnded: boolean
  responses: string[]
  responseType: QuestionResponseType
  comment?: string | null
}

export const createQuestion = (spaceId: number, question: QuestionFields, notify: TaskNotify) =>
  createTask(
    spaceId,
    question.channelId,
    null,
    {
      assignedUserId: question.assignedUserId,
      comments: question.comment ? [question.comment] : undefined,
      content: question.content,
      question: {
        isOpenEnded: question.isOpenEnded,
        responseType: question.responseType,
        responses: question.responses.map(response => ({ content: response })),
      },
    },
    notify,
  )

export const updateQuestion = (
  spaceId: number,
  taskId: number,
  question: QuestionFields,
  notify: TaskNotify,
) =>
  updateTask(
    spaceId,
    taskId,
    {
      assignedUserId: question.assignedUserId,
      content: question.content,
      question: {
        isOpenEnded: question.isOpenEnded,
        responseType: question.responseType,
        responses: question.responses.map(response => ({ content: response })),
      },
    },
    notify,
  )

export const updateTask =
  (
    spaceId: number,
    taskId: number,
    updates: taskAPI.UpdateTask,
    notify: TaskNotify,
  ): RequestThunk<typeof taskAPI.updateTask> =>
  async dispatch => {
    const request = taskAPI.updateTask(spaceId, taskId, updates, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response
  }

export const setChecklistItemCompleted =
  (
    spaceId: number,
    checklistItemId: number,
    isCompleted: boolean,
  ): RequestThunk<typeof taskAPI.setChecklistItemCompleted> =>
  async dispatch => {
    const request = taskAPI.setChecklistItemCompleted(spaceId, checklistItemId, isCompleted)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response
  }

export const acceptTask =
  (spaceId: number, taskId: number, notify: TaskNotify): Thunk<boolean> =>
  async dispatch => {
    const request = taskAPI.acceptTask(spaceId, taskId, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response.ok
  }

export const unacceptTask =
  (spaceId: number, taskId: number, notify: TaskNotify): Thunk<boolean> =>
  async dispatch => {
    const request = taskAPI.unacceptTask(spaceId, taskId, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response.ok
  }

export const completeTask =
  (spaceId: number, taskId: number, notify: TaskNotify): Thunk<boolean> =>
  async dispatch => {
    const request = taskAPI.completeTask(spaceId, taskId, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response.ok
  }

export const uncompleteTask =
  (spaceId: number, taskId: number, notify: TaskNotify): Thunk<boolean> =>
  async dispatch => {
    const request = taskAPI.uncompleteTask(spaceId, taskId, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response.ok
  }

export const deleteTask =
  (spaceId: number, taskId: number, notify: TaskNotify): RequestThunk<typeof taskAPI.deleteTask> =>
  async dispatch => {
    const request = taskAPI.deleteTask(spaceId, taskId, notify)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(storeTaskResponse(response.data))
    }

    return response
  }

const storeTaskResponse =
  (data: taskAPI.UpdateTaskResponse): SyncThunk<void> =>
  dispatch => {
    const { task, message, nextTask, activityMessages, channelUser } = data
    batch(() => {
      dispatch(addTask(task))
      dispatch(addMessage(message))
      dispatch(updateChannelStats(message))

      if (nextTask) {
        dispatch(addTask(nextTask))
      }

      if (activityMessages) {
        dispatch(addMessages(activityMessages))
      }

      if (channelUser) {
        dispatch(channelUserActions.upsertOne(channelUser))
      }
    })
  }
