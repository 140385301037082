import React from 'react'
import { channelUtils } from '@/core/utils'
import { useAppDispatch, useCachedSelector } from '@/hooks'
import { entitySelectors } from '@/store/selectors'
import { channelThunks } from '@/thunks'
import { Channel } from '@/types/entities'

export const useReadReceipts = (channel: Channel) => {
  const dispatch = useAppDispatch()

  const readReceipts = useCachedSelector(entitySelectors.readReceiptsSelector, [channel])
  const hasReadReceipts = channelUtils.hasReadReceipts(channel)
  const { spaceId, id: channelId } = channel

  const getReadReceipts = React.useCallback(() => {
    if (hasReadReceipts) {
      dispatch(channelThunks.getChannelsUsersList(spaceId, channelId))
    }
  }, [dispatch, hasReadReceipts, channelId, spaceId])

  React.useEffect(() => {
    getReadReceipts()
  }, [getReadReceipts])

  return readReceipts
}
