import React from 'react'
import { AttachmentsList } from '@/components/files/AttachmentsList'
import FilesList from '@/components/files/FilesList'
import Box from '@/components/layouts/Box'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { collectionUtils as C } from '@/core/utils'
import { useFormValue } from '@/hooks'
import { useAttachments } from '@/hooks/messages'
import AddAttachment from './AddAttachment'

interface TaskFormAttachmentsListProps {
  canAdd?: boolean
  onChange?: (attachmentIds: number[]) => void
}

const size = { height: 80, width: 80 }
const emptyAttachmentsList: string[] = []

const renderItemContainer = (content: React.ReactElement) => (
  <Box gapBottom="medium" gapRight="medium">
    {content}
  </Box>
)

function TaskFormAttachmentsList({ canAdd = false, onChange }: TaskFormAttachmentsListProps) {
  const { task, formName } = useTaskFormContext()
  const spaceId = useTaskFormValue(fieldNames.spaceId)
  const { handleAttachFile, handleRemoveAttachment } = useAttachments(
    spaceId,
    formName,
    fieldNames.attachmentIds,
  )
  const attachmentIds = useFormValue(formName, fieldNames.attachmentIds) || emptyAttachmentsList
  const reversedAttachmentIds: string[] = React.useMemo(
    () => C.reversed(attachmentIds),
    [attachmentIds],
  )

  React.useEffect(() => {
    onChange?.(attachmentIds)
  }, [attachmentIds, onChange])

  return (
    <Box direction="row" wrap="wrap">
      <AttachmentsList
        attachmentIds={reversedAttachmentIds}
        onRemove={handleRemoveAttachment}
        renderItemContainer={renderItemContainer}
        size={size}
      />
      <FilesList
        files={task?.files || []}
        playVideoInline={false}
        renderItemContainer={renderItemContainer}
        size={size}
      />
      {canAdd ? <AddAttachment onAdd={handleAttachFile} /> : null}
    </Box>
  )
}

export default React.memo(TaskFormAttachmentsList)
