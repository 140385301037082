import React from 'react'
import Box from '@/components/layouts/Box'
import { getTaskColors } from '@/components/messages/MessagesList/constants'
import { taskUtils } from '@/core/utils'
import { Message, Task } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'
import TaskCommentsList from '../TaskCommentsList'
import TaskContainer from '../TaskContainer'
import TaskContentDescription from './TaskContentDescription'

interface TaskContentProps {
  comments?: Message[]
  disabled?: boolean
  isFromMe: boolean
  simplified?: boolean
  task: Task
  item: MessageListItemData
}

function TaskContent({
  disabled = false,
  item,
  comments,
  isFromMe,
  task,
  simplified = false,
}: TaskContentProps) {
  const canEdit = taskUtils.canEditTask()
  const isCompleted = taskUtils.isCompleted(task)
  const { background } = getTaskColors(isFromMe, isCompleted)
  const tabColor = isCompleted ? 'emmre-green+9' : 'emmre-green'

  return (
    <TaskContainer
      backgroundColor={background}
      disabled={disabled}
      isFromMe={isFromMe}
      item={item}
      tabColor={tabColor}
      task={task}
    >
      <Box>
        <TaskContentDescription
          canEdit={canEdit}
          isFromMe={isFromMe}
          simplified={simplified}
          task={task}
        />
        {simplified ? null : (
          <TaskCommentsList isFromMe={isFromMe} messages={comments} task={task} />
        )}
      </Box>
    </TaskContainer>
  )
}

export default React.memo(TaskContent)
