import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { taskUtils } from '@/core/utils'
import { TaskUrgency } from '@/types/entities'
import TaskPriorityIcon from './TaskPriorityIcon'

const urgencyCycle: Record<TaskUrgency, TaskUrgency> = {
  HIGH: 'LOW',
  LOW: 'MEDIUM',
  MEDIUM: 'HIGH',
}

interface TaskPriorityCycleButtonProps {
  showLabel?: boolean
  urgency: TaskUrgency
  onChange: (newValue: TaskUrgency) => void
}

function TaskPriorityCycleButton({
  urgency,
  onChange,
  showLabel = false,
}: TaskPriorityCycleButtonProps) {
  const handleChange = React.useCallback(
    () => onChange(urgencyCycle[urgency as TaskUrgency]),
    [onChange, urgency],
  )

  let content: React.ReactElement

  if (!showLabel) {
    content = <TaskPriorityIcon urgency={urgency} />
  } else {
    content = (
      <Box alignItems="center" direction="row">
        <TaskPriorityIcon urgency={urgency} />
        <Box direction="column" gapLeft="small">
          <Text color="anchor" size="sm">
            Priority
          </Text>
          <Text size="h6">{taskUtils.taskUrgencyLabel(urgency)}</Text>
        </Box>
      </Box>
    )
  }

  return (
    <Touchable feedback="opacity" onPress={handleChange}>
      {content}
    </Touchable>
  )
}

export default React.memo(TaskPriorityCycleButton)
