import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { isWeb } from '@/core/utils/platform'
import { Action } from '@/types/generics'

interface MouseViewProps {
  children: React.ReactNode
  onMouseEnter?: Action<React.SyntheticEvent<View>>
  onMouseLeave?: Action<React.SyntheticEvent<View>>
  onMouseMove?: Action<React.SyntheticEvent<View>>
  onMouseDown?: Action<React.SyntheticEvent<View>>
  onMouseUp?: Action<React.SyntheticEvent<View>>
  style?: StyleProp<ViewStyle>
}

function MouseView({ children, style, ...handlers }: MouseViewProps) {
  if (!isWeb) {
    return <View style={style}>{children}</View> // eslint-disable-line react/jsx-no-useless-fragment
  }
  return (
    // @ts-ignore
    <View {...handlers} style={style}>
      {children}
    </View>
  )
}

export default React.memo(MouseView)
