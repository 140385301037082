import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { sizes } from '@/core/constants'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'
import { platformUtils } from '@/core/utils'
import { useTimeout } from '@/hooks/timeout'
import { Notification, NotificationType } from '@/types/entities'
import NotificationAction from './NotificationAction'

const notificationColors: Record<NotificationType, ColorName> = {
  error: 'red',
  info: 'emmre-blue',
  success: 'green',
  warning: 'emmre-yellow',
}
const notificationIcons: Record<NotificationType, IconName> = {
  error: 'triangle-exclamation',
  info: 'bell',
  success: 'check',
  warning: 'triangle-exclamation',
}

interface NotificationToastProps {
  notification: Notification
  onDismiss: (notification: Notification) => void
  onAction: (notification: Notification, action: string) => void
  location: 'top' | 'bottom'
}

function NotificationToast({
  notification,
  location,
  onDismiss,
  onAction,
}: NotificationToastProps) {
  const handleDismiss = React.useCallback(() => onDismiss(notification), [onDismiss, notification])
  const handleAction = React.useCallback(
    (action: string) => {
      onDismiss(notification)
      onAction(notification, action)
    },
    [onDismiss, onAction, notification],
  )
  const { timeout, clear } = useTimeout(notification.duration)
  const insets = useSafeAreaInsets()

  React.useEffect(() => {
    if (notification.duration > 0) {
      timeout(handleDismiss)
    }
  }, [notification, timeout, handleDismiss])

  React.useEffect(() => clear, [clear])

  return (
    <Box
      direction="row"
      gapBottom={location === 'bottom' ? insets.bottom || 10 : undefined}
      gapHorizontal="medium"
      gapTop={location === 'top' ? insets.top || 10 : undefined}
      justifyContent="flex-end"
    >
      <Background
        borderColor={notificationColors[notification.type]}
        borderRadius={8}
        borderWidth={1}
        color="ghost"
        flex={platformUtils.isWeb ? undefined : 1}
        maxWidth={platformUtils.isWeb ? sizes.maxToastWidth : undefined}
        minWidth={platformUtils.isWeb ? sizes.minToastWidth : undefined}
      >
        <Box direction="row">
          <Background color={notificationColors[notification.type]}>
            <Box alignItems="center" flex={1} gapAll="medium" justifyContent="center">
              <Icon
                color="ghost"
                kind="solid"
                name={notificationIcons[notification.type]}
                size={20}
              />
            </Box>
          </Background>
          <Box flex={1}>
            <Box direction="column" gapHorizontal="medium" gapTop="medium">
              <Text color="shadow" size="h4" weight="medium">
                {notification.title}
              </Text>
              {notification.body ? (
                <Box gapTop="small">
                  <Text color="shadow" size="h4">
                    {notification.body}
                  </Text>
                </Box>
              ) : null}
            </Box>
            <Box alignItems="center" direction="row" gapAll="xsmall" gapTop="medium">
              {notification.actions
                ? notification.actions.map(action => (
                    <NotificationAction key={action} action={action} onAction={handleAction} />
                  ))
                : null}
              <Box flex={1} />
              <Button kind="transparentDark" label="Dismiss" onPress={handleDismiss} />
            </Box>
          </Box>
        </Box>
      </Background>
    </Box>
  )
}

export default React.memo(NotificationToast)
