import React from 'react'
import {
  FlatList,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  View,
} from 'react-native'
import ScrollButton from './ScrollButton'
import { HorizontalListProps, ScrollPosition, Side } from './types'
import { determineScrollPosition } from './utils'

const scrollPercent = 0.6

function HorizontalList<S>({
  flatListRef: outsideRef,
  buttonBackgroundColor,
  buttonIconColor,
  onContentSizeChange,
  onLayout,
  onScroll,
  ...flatListProps
}: HorizontalListProps<S>) {
  const localRef = React.useRef<FlatList | null>(null)
  const flatListRef = outsideRef || localRef

  const containerWidthRef = React.useRef(0)
  const contentWidthRef = React.useRef(0)
  const scrollOffsetRef = React.useRef(0)

  const [scrollPosition, setScrollPosition] = React.useState<ScrollPosition>('BEGINNING')

  const handleLayout = React.useCallback(
    (e: LayoutChangeEvent) => {
      containerWidthRef.current = e.nativeEvent.layout.width

      setScrollPosition(
        determineScrollPosition(
          containerWidthRef.current,
          contentWidthRef.current,
          scrollOffsetRef.current,
        ),
      )

      if (onLayout) {
        onLayout(e)
      }
    },
    [onLayout, setScrollPosition],
  )

  const handleContentSizeChange = React.useCallback(
    (w: number, h: number) => {
      contentWidthRef.current = w

      setScrollPosition(
        determineScrollPosition(
          containerWidthRef.current,
          contentWidthRef.current,
          scrollOffsetRef.current,
        ),
      )

      if (onContentSizeChange) {
        onContentSizeChange(w, h)
      }
    },
    [onContentSizeChange, setScrollPosition],
  )

  const handleScroll = React.useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { contentOffset, contentSize, layoutMeasurement } = e.nativeEvent

      containerWidthRef.current = layoutMeasurement.width
      contentWidthRef.current = contentSize.width
      scrollOffsetRef.current = contentOffset.x

      setScrollPosition(
        determineScrollPosition(
          containerWidthRef.current,
          contentWidthRef.current,
          scrollOffsetRef.current,
        ),
      )
      if (onScroll) {
        onScroll(e)
      }
    },
    [setScrollPosition, onScroll],
  )

  const handleScrollButtonPress = React.useCallback(
    (side: Side) => {
      const width = containerWidthRef.current
      const scrollDistance = width ? width * scrollPercent : 60
      const delta = side === 'left' ? -scrollDistance : scrollDistance

      flatListRef.current?.scrollToOffset({
        animated: true,
        offset: scrollOffsetRef.current + delta,
      })
    },
    [flatListRef],
  )

  return (
    <View style={styles.container}>
      <FlatList
        {...flatListProps}
        ref={flatListRef}
        onContentSizeChange={handleContentSizeChange}
        onLayout={handleLayout}
        onScroll={handleScroll}
      />
      <ScrollButton
        backgroundColor={buttonBackgroundColor}
        iconColor={buttonIconColor}
        onPress={handleScrollButtonPress}
        scrollPosition={scrollPosition}
        side="left"
      />
      <ScrollButton
        backgroundColor={buttonBackgroundColor}
        iconColor={buttonIconColor}
        onPress={handleScrollButtonPress}
        scrollPosition={scrollPosition}
        side="right"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
})

// ts-unused-exports:disable-next-line
export default React.memo(HorizontalList) as typeof HorizontalList
