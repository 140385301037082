import { decamelize } from 'humps'
import { Pair } from '@/types/generics'

export const appendQueryString = (url: string, queryString: string): string => {
  if (url.includes('?')) {
    return `${url}&${queryString}`
  }

  return `${url}?${queryString}`
}

export const toQueryString = (pairs: Pair<string, any>[]): string => {
  const params: string[] = []

  pairs
    .filter(kvp => kvp[1] !== undefined && kvp[1] !== null)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(val => params.push(urlEncodePair(key, val)))
      } else {
        params.push(urlEncodePair(key, value))
      }
    })

  return params.join('&')
}

const urlEncodePair = (key: string, value: any): string => {
  const eKey = encodeURIComponent(decamelize(key))
  const eVal = encodeURIComponent(toString(value))
  return `${eKey}=${eVal}`
}

const toString = (value: any): string => {
  if (value === null) {
    return ''
  }
  if (value === undefined) {
    return ''
  }
  return value.toString()
}
