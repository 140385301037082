import React from 'react'
import { useUnmount } from '@/hooks/state'
import type { OnKeyPress } from '@/types/components'
import { SummaryItem } from '@/types/entities'
import { useEditSummaryContext } from '../../EditSummaryContext'

export const useHeader = (summaryItem: SummaryItem, index: number) => {
  const { id: summaryItemId } = summaryItem
  const { setEditingItemId, editingItemId, deleteSummaryItem } = useEditSummaryContext()
  const isEditing = editingItemId === summaryItemId
  const handleDelete = React.useCallback(async () => {
    deleteSummaryItem(summaryItemId)
    setEditingItemId(null)
  }, [summaryItemId, deleteSummaryItem, setEditingItemId])

  const handleStartEditing = React.useCallback(() => {
    setEditingItemId({ index, itemId: summaryItemId })
  }, [setEditingItemId, summaryItemId, index])

  return { handleDelete, handleStartEditing, isEditing }
}

export const useEditHeader = (summaryItem: SummaryItem) => {
  const { id: summaryItemId, content, description } = summaryItem
  const { setEditingItemId, updateSummaryItem } = useEditSummaryContext()
  const [newContent, setNewContent] = React.useState(summaryItem.content)
  const [newDescription, setNewDescription] = React.useState(summaryItem.description)
  const hasChanges =
    newContent !== summaryItem.content || newDescription !== summaryItem.description

  const syncState = React.useCallback(() => {
    setNewContent(content)
    setNewDescription(description)
  }, [setNewContent, content, description])

  const doUpdate = React.useCallback(() => {
    if (!hasChanges) {
      return
    }

    updateSummaryItem(summaryItemId, { content: newContent, description: newDescription })
  }, [newContent, updateSummaryItem, hasChanges, summaryItemId, newDescription])

  const handleUpdate = React.useCallback(async () => {
    setEditingItemId(null)
  }, [setEditingItemId])

  const handleCancel = React.useCallback(() => {
    syncState()
    setTimeout(() => setEditingItemId(null))
  }, [syncState, setEditingItemId])

  const handleKeyPress = React.useCallback<OnKeyPress>(
    ({ nativeEvent }) => {
      if (nativeEvent.key === 'Escape') {
        handleCancel()
      }
    },
    [handleCancel],
  )

  useUnmount(doUpdate)

  React.useEffect(() => {
    syncState()
  }, [syncState])

  return {
    handleCancel,
    handleKeyPress,
    handleUpdate,
    newContent,
    newDescription,
    setNewContent,
    setNewDescription,
  }
}
