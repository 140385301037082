import React from 'react'
import { noOpWarn } from '@/core/logging'
import { defaultState } from './constants'
import { QuestionResponseContextType } from './types'

export { useQuestionResponseForm } from './hooks'

const noOp = noOpWarn('question response context not present')

const defaultContextValue: QuestionResponseContextType = {
  isChanged: false,
  isComplete: false,
  responseForm: defaultState,
  setOpenEndedResponse: noOp,
  setOtherSelected: noOp,
  setResponseSelected: noOp,
}

const QuestionResponseContext =
  React.createContext<QuestionResponseContextType>(defaultContextValue)

export const useQuestionResponseContext = () => React.useContext(QuestionResponseContext)

export default QuestionResponseContext
