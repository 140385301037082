import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useCachedSelector } from '@/hooks'
import { entitySelectors } from '@/store/selectors'
import { TaskFilter, TaskType } from '@/types/tasks'

interface ToggleCompletedButtonProps {
  hiddenFilter: TaskFilter
  showCompleted: boolean
  onToggle: () => void
  taskType: TaskType
}

function ToggleCompletedButton({
  hiddenFilter,
  showCompleted,
  onToggle,
  taskType,
}: ToggleCompletedButtonProps) {
  const count = useCachedSelector(entitySelectors.countTasksByFilterSelector, [hiddenFilter])

  if (count === 0 && !showCompleted) {
    return null
  }

  return (
    <Box direction="row" gapBottom="xsmall" gapLeft="medium">
      <Touchable feedback="opacity" onPress={onToggle}>
        <Background borderRadius={4} color="ghost">
          <Box direction="row" gapAll="small">
            <Icon kind="solid" name={showCompleted ? 'chevron-down' : 'chevron-up'} size="h4" />
            <Box gapLeft="medium">
              <Text>
                {getLabel(taskType, showCompleted)} ({count})
              </Text>
            </Box>
          </Box>
        </Background>
      </Touchable>
    </Box>
  )
}

const getLabel = (taskType: TaskType, showCompleted: boolean): string => {
  if (taskType === 'TASK') {
    return showCompleted ? 'Incomplete' : 'Complete'
  }
  return showCompleted ? 'Unanswered' : 'Answered'
}

export default React.memo(ToggleCompletedButton)
