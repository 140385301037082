import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { Tag } from '@/types/entities'

export interface TagResponse {
  tag: Tag
}

export interface TagsResponse {
  tags: Tag[]
}

export type CreateTag = Pick<Tag, 'name'>
export type UpdateTag = Pick<Tag, 'name'>

export const createTag = (spaceId: number, tag: CreateTag) =>
  new Request<TagResponse>({
    authentication: 'PRIVATE',
    body: tag,
    method: 'POST',
    url: `v1/spaces/${spaceId}/tags`,
  })

export const updateTag = (spaceId: number, tagId: number, tag: UpdateTag) =>
  new Request<TagResponse>({
    authentication: 'PRIVATE',
    body: tag,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/tags/${tagId}`,
  })

export const deleteTag = (spaceId: number, tagId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/tags/${tagId}`,
  })

export interface ListParams {
  updatedSince?: string | null
  name?: string | null
  active?: boolean
}

export const getTagsList = (
  spaceId: number,
  { updatedSince, name, active }: ListParams,
  paging: PagingRequest,
) =>
  new Request<TagsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [
      ['updatedSince', updatedSince],
      ['name', name],
      ['active', active],
    ],
    url: `v1/spaces/${spaceId}/tags`,
  })
