import React from 'react'
import { useTaskModals } from '@/components/tasks/TaskForm/hooks'
import { TaskFormContextType } from '@/contexts/TaskFormContext'
import { fnUtils } from '@/core/utils'
import { useChannelPartner } from '@/hooks/users'
import { ResolvedChannel } from '@/types/entities'
import { useForm } from './form'
import { useInputFocus } from './inputFocus'
import { useSwipeHandler } from './swipe'

export const useMessageTextInput = (channel: ResolvedChannel, threadId?: number) => {
  const {
    canSubmit,
    formName,
    handleCancel,
    handleSubmit,
    handleSubmitDisabled,
    inputMode,
    isExpanded,
    questionMode,
    setIsExpanded,
    setQuestionMode,
    showTaskModal,
    setInputMode,
    toggleIsExpanded,
  } = useForm(channel, fnUtils.noOp, threadId)

  const { taskDetail, showTaskDetail, closeTaskDetail } = useTaskModals()

  const taskFormContextValue = React.useMemo<TaskFormContextType>(
    () => ({
      canClose: false,
      canSubmit,
      cancel: handleCancel,
      close: fnUtils.noOp,
      formName,
      interactionMode: 'create',
      questionMode,
      setQuestionMode,
      setTaskFormSize: undefined,
      showDetail: showTaskDetail,
      submit: handleSubmit,
      submitDisabled: handleSubmitDisabled,
      task: undefined,
      taskFormSize: 'SMALL',
      taskType: inputMode === 'question' ? 'QUESTION' : 'TASK',
    }),
    [
      canSubmit,
      formName,
      handleCancel,
      handleSubmit,
      handleSubmitDisabled,
      inputMode,
      questionMode,
      setQuestionMode,
      showTaskDetail,
    ],
  )

  const { inputRef } = useInputFocus(inputMode)
  const handleSwipe = useSwipeHandler(setIsExpanded)

  const partner = useChannelPartner(channel)

  const sendingDisabled =
    partner?.membershipType === 'INACTIVE' || partner?.membershipType === 'NOT_INVITED'

  return {
    canSubmit,
    closeTaskDetail,
    formName,
    handleSubmit,
    handleSwipe,
    inputMode,
    inputRef,
    isExpanded,
    partner,
    sendingDisabled,
    setInputMode,
    showTaskModal,
    taskDetail,
    taskFormContextValue,
    toggleIsExpanded,
  }
}
