import React from 'react'
import { useAppDispatch } from '@/hooks'
import { formActions } from '@/store/actions'
import { Setter } from '@/types/generics'
import { QuestionMode } from '@/types/tasks'
import { getDefaultQuestionFields } from './utils'

export const useQuestionMode = (
  initialMode: QuestionMode,
  formName: string,
  initializeFormFields: boolean,
): [QuestionMode, Setter<QuestionMode>] => {
  const [questionMode, setQuestionMode] = React.useState<QuestionMode>(initialMode)
  const dispatch = useAppDispatch()
  const skipResetRef = React.useRef(!initializeFormFields)

  const setQuestionFields = React.useCallback(
    (fields: Record<string, any>) => {
      dispatch(formActions.setValues({ fields, formName, markChanged: true }))
    },
    [dispatch, formName],
  )

  const resetQuestionFormFields = React.useCallback(
    (newQuestionMode: QuestionMode) => {
      if (skipResetRef.current) {
        skipResetRef.current = false
        return
      }

      setQuestionFields(getDefaultQuestionFields(newQuestionMode))
    },
    [setQuestionFields],
  )

  React.useEffect(
    () => resetQuestionFormFields(questionMode),
    [resetQuestionFormFields, questionMode],
  )

  return [questionMode, setQuestionMode]
}
