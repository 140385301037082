import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import Tab from './Tab'
import type { TabOption } from './Tab'

interface TabsProps {
  backgroundColor: ColorName
  foregroundColor: ColorName
  onChange: (value: string) => void
  options: TabOption[]
  selectedBackgroundColor: ColorName
  selectedBorderColor?: ColorName
  selectedForegroundColor: ColorName
  value: string
}

function Tabs({
  backgroundColor,
  foregroundColor,
  onChange,
  options,
  selectedBackgroundColor,
  selectedBorderColor = 'transparent',
  selectedForegroundColor,
  value,
}: TabsProps) {
  return (
    <View style={[styles.container, { backgroundColor: getColor(backgroundColor) }]}>
      {options.map(option => (
        <Tab
          key={option.value}
          backgroundColor={option.value === value ? selectedBackgroundColor : 'transparent'}
          borderColor={option.value === value ? selectedBorderColor : 'transparent'}
          foregroundColor={option.value === value ? selectedForegroundColor : foregroundColor}
          isSelected={option.value === value}
          label={option.label}
          onPress={onChange}
          value={option.value}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    flexDirection: 'row',
    padding: 3,
  },
})

export default React.memo(Tabs)

export type { TabOption }
