import { ReceivedMessage } from '@/types/apiEntities'
import { File, Message, SentMessage, Thread, UnsentMessage } from '@/types/entities'
import * as dateUtils from './dates'

export const isUnsentMessage = (message: Message): message is UnsentMessage =>
  (message as any).sendStatus !== undefined

export const isSentMessage = (message: Message): message is SentMessage =>
  (message as any).sendStatus === undefined

export const createUnsentMessage = (
  message: Omit<Message, 'id' | 'createdAt' | 'updatedAt' | 'files' | 'clientTemporaryId'>,
  attachmentIds: string[],
): UnsentMessage => {
  const clientTemporaryId = dateUtils.nowMilliseconds()
  const now = dateUtils.nowISO()

  return {
    ...message,
    attachmentIds,
    clientTemporaryId: clientTemporaryId.toString(),
    createdAt: now,
    id: clientTemporaryId,
    sendStatus: 'sending',
    updatedAt: now,
  }
}

export const canEditMessage = (message: Message, userId: number): boolean =>
  message.creatorId === userId

export const canDeleteMessage = (message: Message, userId: number): boolean =>
  message.creatorId === userId

export const messageHasFiles = (message: Message): boolean => {
  if (isUnsentMessage(message)) {
    return !!message.attachmentIds && message.attachmentIds.length !== 0
  }
  return !!message.files && message.files.length !== 0
}

export const mapFiles = <T>(message: SentMessage, map: (file: File) => T): T[] => {
  if (message.files) {
    return message.files.map(map)
  }
  return []
}

export const getThreadId = (message: Message): number => message.threadId || message.id

export const isTaskActivity = (message: Message) => !!message.taskActivityType

export const isSummaryActivity = (message: Message) =>
  !!message.summaryId && !!message.summaryActivityType

export const hasReactionFrom = (message: Message, userId: number) =>
  getReaction(message, userId) !== null

export const getReaction = (message: Message, userId: number): string | null =>
  message.reactions?.[userId] || null

export const showInChat = (message: SentMessage): boolean => {
  if (message.showInChat === undefined) {
    return true
  }
  return message.showInChat
}

export const isSameDay = (left: Message, right: Message) => {
  const leftDate = dateUtils.parse(left.createdAt)
  const rightDate = dateUtils.parse(right.createdAt)

  return (
    leftDate.getFullYear() === rightDate.getFullYear() &&
    leftDate.getMonth() === rightDate.getMonth() &&
    leftDate.getDate() === rightDate.getDate()
  )
}

type FilterParams = {
  spaceId: number
  channelId?: number
  threadId?: number
  includeThreadStart?: boolean
}

export const buildFilter =
  ({ spaceId, channelId, threadId, includeThreadStart = true }: FilterParams) =>
  (message: Message) =>
    message.spaceId === spaceId &&
    (!channelId || message.channelId === channelId) &&
    (!threadId || message.threadId === threadId || (includeThreadStart && message.id === threadId))

export const normalizeMessage = (
  message: ReceivedMessage,
): { message: SentMessage; thread?: Thread } => {
  const { thread } = message
  const withoutThread = { ...message }
  delete withoutThread.thread

  return {
    message: withoutThread,
    thread,
  }
}
