import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { ChannelStats } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, channelId: number): string => `${spaceId}:${channelId}`

const adapter = createEntityAdapter<ChannelStats>({
  selectId: channelStats => buildId(channelStats.spaceId, channelStats.channelId),
  sortComparer: (a, b) => a.channelId - b.channelId,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/channelStats',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.channelStats)

const selectById = (state: RootState, spaceId: number, channelId: number) =>
  state.entities.channelStats.entities[buildId(spaceId, channelId)]

const byIdSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectEntities, records => records[buildId(spaceId, channelId)])

const selectors = {
  all: sliceSelectors.selectAll,
  byId: selectById,
  byIdSelector,
  entities: sliceSelectors.selectEntities,
}

export { actions, selectors, buildId }
export default reducer
