import React from 'react'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import { Action } from '@/types/generics'
import RecurrenceRulesList from './RecurrenceRulesList'

interface TaskRecurrenceModalProps {
  isOpen: boolean
  onClose: () => void
  recurrenceRule: string | null
  onChange: Action<string | null>
  localModal: boolean
}

function TaskRecurrenceModal({
  isOpen,
  onClose,
  recurrenceRule,
  onChange,
  localModal,
}: TaskRecurrenceModalProps) {
  const [newRecurrenceRule, setNewRecurrenceRule] = React.useState(recurrenceRule)
  const handleCancel = React.useCallback(() => {
    onClose()
  }, [onClose])
  const handleDone = React.useCallback(() => {
    onChange(newRecurrenceRule)
    onClose()
  }, [onChange, newRecurrenceRule, onClose])
  const setAndClose = React.useCallback(
    (newRule: string | null) => {
      onChange(newRule)
      onClose()
    },
    [onChange, onClose],
  )

  React.useEffect(() => {
    if (!isOpen) {
      setNewRecurrenceRule(recurrenceRule)
    }
  }, [isOpen, setNewRecurrenceRule, recurrenceRule])

  return (
    <StandardModal
      depth={2}
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      minHeight={0}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Recurrence"
    >
      <Box gapVertical="large">
        <RecurrenceRulesList onChange={setAndClose} recurrenceRule={newRecurrenceRule} />
      </Box>
    </StandardModal>
  )
}

export default React.memo(TaskRecurrenceModal)
