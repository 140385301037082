import React from 'react'
import Background from '@/components/core/Background'
import IconButton from '@/components/core/IconButton'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'

interface AccountManangementItemProps {
  label: string
  subLabel?: string
  onPress?: () => void
}

function AccountManagementItem({ label, onPress, subLabel }: AccountManangementItemProps) {
  return (
    <Touchable feedback="opacity" onPress={onPress}>
      <Background borderBottomColor="heather" borderBottomWidth={1}>
        <Box
          alignItems="center"
          direction="row"
          gapHorizontal="medium"
          gapVertical="xsmall"
          justifyContent="space-between"
        >
          <Box gapVertical={subLabel ? 'small' : 'medium'}>
            <Text>{label}</Text>
            {subLabel ? (
              <Text color="anchor" size="sm">
                {subLabel}
              </Text>
            ) : null}
          </Box>
          {onPress && <IconButton kind="transparentDark" name="chevron-right" />}
        </Box>
      </Background>
    </Touchable>
  )
}

export default React.memo(AccountManagementItem)
