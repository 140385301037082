import { log } from '@/core/logging'
import { APIThunk } from '@/types/api'
import { Thunk } from '@/types/store'

export const getAllPages =
  <T>(getPage: (page: number) => APIThunk<T>, maxPages = 10): Thunk<boolean> =>
  async dispatch => {
    let page = 1
    let response = await dispatch(getPage(page))

    while (response.ok && response.paging && response.paging.page < response.paging.totalPages) {
      page += 1

      if (page > maxPages) {
        log('warning', 'max page number for getAllPages exceeded')
        break
      }

      response = await dispatch(getPage(page))
    }

    return response.ok
  }
