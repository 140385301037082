export const linkingConfig = {
  screens: {
    'account-management': {
      path: 'account-management/:spaceId',
      screens: {
        'about': 'about',
        'email': 'email',
        'finances': 'finances',
        'help': 'help',
        'integrations': 'integrations',
        'members': 'members',
        'notifications': 'notifications',
        'password': 'password',
        'preferences': 'preferences',
        'profile': 'profile',
        'space': 'space',
        'view-profile': 'view-profile',
      },
    },
    'auth': {
      path: 'auth',
      screens: {
        'request-password-reset': 'request-password-reset',
        'reset-password': 'reset-password',
        'sign-in': 'sign-in',
        'token-in': 'token-in',
      },
    },
    'create-space': 'create-space',
    'join-space': 'join-space',
    'my-day': 'my-day',
    'no-spaces': 'no-spaces',
    'sign-up': {
      path: 'sign-up',
      screens: {
        'email-step': 'email-step',
        'password-step': 'password-step',
        'payment-step': 'payment-step',
        'role-choice-step': 'role-choice-step',
      },
    },
    'spaces': {
      path: 'spaces/:spaceId',
      screens: {
        'channel': 'channels/:channelId',
        'edit-summary': 'summaries/:summaryId/edit',
        'main': 'main',
        'summaries': 'summaries',
        'task': 'tasks/:taskId',
        'tasks': 'tasks',
        'thread': 'threads/:threadId',
        'threads': 'threads',
        'view-summary': 'summaries/:summaryId',
      },
    },
    'teams-sign-up': {
      path: 'teams-sign-up',
      screens: {
        'email-step': 'email-step',
        'payment-step': 'payment-step',
        'teams-channel-setup': 'teams-channel-setup',
        'teams-password-step': 'teams-password-step',
        'teams-tasks-setup': 'teams-tasks-setup',
        'teams-team-members-setup': 'teams-team-members-setup',
        'teams-workspace-setup': 'teams-workspace-setup',
      },
    },
  },
}
