import React from 'react'
import TextWithLinks from '@/components/core/TextWithLinks'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { getTaskColors } from '@/components/messages/MessagesList/constants'
import TabbedContainer from '@/components/messages/TabbedContainer'
import { useMessagesListContext } from '@/contexts/MessagesListContext'
import { MessageListItemData } from '@/types/messaging'

interface SummaryActivityContentProps {
  disabled?: boolean
  isFromMe: boolean
  item: MessageListItemData
}

function SummaryActivityContent({ disabled = false, isFromMe, item }: SummaryActivityContentProps) {
  const {
    message: { id: messageId, spaceId, summaryId, summaryActivityType },
  } = item
  const isCompleted = summaryActivityType === 'COMPLETED'
  const { background, foreground } = getTaskColors(isFromMe, isCompleted)
  const { showSummary, showThread } = useMessagesListContext()

  const handlePress = React.useCallback(() => {
    if (!summaryActivityType || summaryActivityType === 'COMMENT') {
      showThread(spaceId, messageId)
    } else if (summaryId) {
      showSummary(spaceId, summaryId)
    }
  }, [summaryId, summaryActivityType, spaceId, showSummary, showThread, messageId])

  return (
    <Touchable disabled={disabled} feedback="opacity" onPress={handlePress}>
      <TabbedContainer
        backgroundColor={background}
        hasOverlay={false}
        isFromMe={isFromMe}
        tabColor={isCompleted ? 'CompletedSummaryCyan' : 'cyan'}
      >
        <Box gapAll="medium">
          <TextWithLinks color={foreground} italic={isCompleted}>
            {item.message.content}
          </TextWithLinks>
        </Box>
      </TabbedContainer>
    </Touchable>
  )
}

export default React.memo(SummaryActivityContent)
