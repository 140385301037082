import React from 'react'
import { messageUtils } from '@/core/utils'
import { MessageListItemData } from '@/types/messaging'
import SummaryActivityIndicator from './SummaryActivityIndicator'
import TaskActivityIndicator from './TaskActivityIndicator'
import TaskCreatedIndicator from './TaskCreatedIndicator'
import ThreadIndicator from './ThreadIndicator'

interface MessageListItemHeaderProps {
  item: MessageListItemData
  isFromMe: boolean
  isDisplayedInThread: boolean
}

function MessageListItemHeader({
  item,
  isFromMe,
  isDisplayedInThread,
}: MessageListItemHeaderProps) {
  if (isDisplayedInThread) {
    return null
  }

  const { message, task, thread } = item

  if (task) {
    return <TaskCreatedIndicator task={task} thread={thread} />
  }

  if (messageUtils.isTaskActivity(message)) {
    return (
      <TaskActivityIndicator
        creatorId={message.creatorId}
        isFromMe={isFromMe}
        spaceId={message.spaceId}
        taskActivityType={message.taskActivityType}
      />
    )
  }

  if (messageUtils.isSummaryActivity(message)) {
    return <SummaryActivityIndicator isFromMe={isFromMe} item={item} />
  }

  return <ThreadIndicator isFromMe={isFromMe} item={item} />
}

export default React.memo(MessageListItemHeader)
