import { RequestThunk } from '@/api/call'
import { userAPI } from '@/api/requests'
import { authActions } from '@/store/actions'
import { authSelectors } from '@/store/selectors'
import { UserAttribute } from '@/types/entities'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

export const setUserAttribute =
  (userId: number, body: userAPI.SetUserAttribute): RequestThunk<typeof userAPI.setUserAttribute> =>
  async dispatch => {
    const request = userAPI.setUserAttribute(userId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { user } = response.data
      dispatch(authActions.setMe(user))
    }

    return response
  }

export const flagUserAttribute =
  (attribute: UserAttribute): Thunk<boolean> =>
  async (dispatch, getState) => {
    const value = authSelectors.attributeValue(getState(), attribute)
    const myId = authSelectors.myId(getState())

    if (!myId || !!value) {
      return false
    }

    const response = await dispatch(setUserAttribute(myId, { attribute, value: 'true' }))

    return response.ok
  }

export const removeUserAttribute =
  (attribute: UserAttribute): Thunk<boolean> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState())

    if (!myId) {
      return false
    }

    const request = userAPI.removeUserAttribute(myId, attribute)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { user } = response.data
      dispatch(authActions.setMe(user))
    }

    return response.ok
  }
