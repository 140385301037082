import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import { useAppDispatch } from '@/hooks'
import { summaryThunks } from '@/thunks'
import { SummaryItem } from '@/types/entities'

interface AcknowledgeButtonProps {
  summaryItem: SummaryItem
}

function AcknowledgeButton({ summaryItem }: AcknowledgeButtonProps) {
  const dispatch = useAppDispatch()
  const { spaceId, id: summaryItemId, canAcknowledge, acknowledgedAt } = summaryItem
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const handleAcknowledge = React.useCallback(async () => {
    setIsSubmitting(true)
    await dispatch(summaryThunks.acknowledgeSummaryItem(spaceId, summaryItemId))
    setIsSubmitting(false)
  }, [setIsSubmitting, dispatch, spaceId, summaryItemId])

  if (!canAcknowledge) {
    return null
  }

  const isAcknowledged = !!acknowledgedAt

  return (
    <BaseIconButton
      color="emmre-blue"
      disabled={isSubmitting || isAcknowledged}
      kind={isAcknowledged ? 'solid' : 'light'}
      name="thumbs-up"
      onPress={handleAcknowledge}
    />
  )
}

export default React.memo(AcknowledgeButton)
