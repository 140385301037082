import React from 'react'
import { Text } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { IconKind, IconName, iconMap } from '@/core/constants/icons'
import { getFontSize } from '@/core/constants/typography'
import { FontSize } from '@/types/typography'

export interface IconProps {
  color?: ColorName
  name: IconName
  size?: FontSize | number
  kind?: IconKind
}

function Icon({ color = 'obsidian', name, size = 'h4', kind = 'light' }: IconProps) {
  const fontSize = getFontSize(size)

  const style = {
    color: getColor(color as ColorName),
    fontFamily: `fa-pro-${kind}`,
    fontSize: getFontSize(size),
    lineHeight: fontSize * 1.1,
  }

  return <Text style={style}>{iconMap[name]}</Text>
}

export default React.memo(Icon)
