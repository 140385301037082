import { createSelector } from 'reselect'
import { Channel } from '@/types/entities'
import { selectors as authSelectors } from '../../auth'
import { selectors as channelsUsersSelectors } from '../channelsUsers'

export const readReceiptsSelector = (channel: Channel) =>
  createSelector(authSelectors.myId, channelsUsersSelectors.all, (myId, channelsUsers) =>
    channelsUsers.filter(
      record =>
        record.spaceId === channel.spaceId &&
        record.channelId === channel.id &&
        record.userId !== myId,
    ),
  )
