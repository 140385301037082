import React from 'react'
import BlankSlate from '@/components/core/BlankSlate'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { TaskRelationship, TaskType } from '@/types/tasks'
import { useEmptyTasksList } from './hooks'

interface EmptyTasksListProps {
  taskType: TaskType
  taskRelationship: TaskRelationship
  spaceId: number
  channelId?: number | null
}

function EmptyTasksList({ taskType, taskRelationship, channelId, spaceId }: EmptyTasksListProps) {
  const { partner } = useEmptyTasksList(spaceId, channelId)

  if (taskType === 'TASK') {
    if (taskRelationship === 'ALL') {
      return (
        <BlankSlate
          icon="balloons"
          subtitle="This is where you see both of your tasks combined"
          title="Wow neither of you have any Tasks here!"
        />
      )
    }
    if (taskRelationship === 'MINE') {
      return (
        <BlankSlate
          icon="party-horn"
          subtitle="You don't have any tasks in here to complete"
          title="Congratulations!"
        />
      )
    }
    if (taskRelationship === 'WATCHING') {
      if (!partner) {
        return null
      }
      return (
        <BlankSlate
          icon="party-face"
          subtitle={`This is where you can create and track tasks for ${partner.firstName}`}
          title={`Tell ${partner.firstName} Congratulations!`}
        />
      )
    }
  } else {
    if (taskRelationship === 'ALL') {
      return (
        <BlankSlate
          icon="balloons"
          iconColor="emmre-purple"
          subtitle="This is where you see both of your questions combined"
          title="Wow neither of you have any Questions here!"
        />
      )
    }
    if (taskRelationship === 'MINE') {
      return (
        <BlankSlate
          icon="party-horn"
          iconColor="emmre-purple"
          subtitle="You don't have any questions in here to answer"
          title="Congratulations!"
        />
      )
    }
    if (taskRelationship === 'WATCHING') {
      if (!partner) {
        return null
      }
      return (
        <BlankSlate
          icon="party-face"
          iconColor="emmre-purple"
          subtitle={`This is where you can create questions ${partner.firstName}`}
          title={`Tell ${partner.firstName} Congratulations!`}
        />
      )
    }
  }

  return (
    <Box direction="row" gapAll="large" justifyContent="center">
      <Text size="h2">Nothing to see here!</Text>
    </Box>
  )
}

export default React.memo(EmptyTasksList)
