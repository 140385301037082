import React from 'react'
import 'react-native-gesture-handler'
import ErrorBoundary from '@/components/core/ErrorBoundary'
import AppLayoutContext from '@/contexts/AppLayoutContext'
import { isWeb } from '@/core/utils/platform'
import { useIsWide } from '@/hooks/appState'
import { AppErrorScreen, NarrowScreen, Providers, WideScreen } from '@/screens'
import { preInitializeApplication } from '@/thunks/app'
import { AppLayout } from '@/types/components'

preInitializeApplication()

function App() {
  const isWide = useIsWide()
  const appLayout: AppLayout = isWeb && isWide ? 'WIDE' : 'NARROW'

  return (
    <AppLayoutContext.Provider value={appLayout}>
      <Providers>
        <ErrorBoundary fallback={<AppErrorScreen />}>
          {appLayout === 'WIDE' ? <WideScreen /> : <NarrowScreen />}
        </ErrorBoundary>
      </Providers>
    </AppLayoutContext.Provider>
  )
}

export default React.memo(App)
