import { CardComponent } from '@chargebee/chargebee-js-react-wrapper'
import React from 'react'
import Background from '@/components/core/Background'
import Box from '@/components/layouts/Box'
import { getColor } from '@/core/constants/colors'
import { getFontFamily, getFontSize, getLineHeight } from '@/core/constants/typography'
import { CreditCardProps } from './types'

type OnChangeEvent = { complete: boolean; error: { errorCode: string; message: string } | null }

function CreditCard({ cardRef, onCompleteChange }: CreditCardProps) {
  const isCompleteRef = React.useRef(false)
  const handleChange = React.useCallback(
    (e: OnChangeEvent) => {
      const complete = e.complete && !e.error
      if (onCompleteChange && complete !== isCompleteRef.current) {
        isCompleteRef.current = complete
        onCompleteChange(complete)
      }
    },
    [onCompleteChange],
  )

  return (
    <Background borderRadius={8} color="heather">
      <Box gapAll="medium">
        <CardComponent ref={cardRef} onChange={handleChange} styles={cardStyles} />
      </Box>
    </Background>
  )
}

const cardStyles = {
  base: {
    color: getColor('obsidian'),
    fontFamily: getFontFamily('regular', false),
    fontSize: `${getFontSize('h4')}px`,
    lineHeight: `${getLineHeight('h4')}px`,
  },
}

// ts-unused-exports:disable-next-line
export default React.memo(CreditCard)
