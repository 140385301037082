import { LogLevel } from '@/types/logging'

const day = 60 * 60 * 24

type Features = {
  channels: boolean
  paidSignUp: boolean
  giphy: boolean
}

export type FeatureName = keyof Features

export interface Configuration {
  amplitudeApiKey: string | null
  apiURLRoot: string
  appURLRoot: string
  cacheFilesDuration: number
  cacheTempFilesDuration: number
  chargebeePublishableKey: string
  chargebeeSite: 'emmre' | 'emmre-test'
  checkForUpdates: boolean
  contactSupportUrl: string
  faqURL: string
  features: Features
  giphyApiKey: string
  helpEmail: string
  helpVideosUrl: string
  logLevel: LogLevel
  logToSentryLevel: LogLevel
  longRequestThresholdMS: number
  privacyPolicyURL: string
  reduxDevTools: boolean
  rtmURLRoot: string
  sentryDebug: boolean
  sentryDSN: string | null
  stonlyDebug: boolean
  stonlyWidgetId: string
  supportURL: string
  termsOfServiceURL: string
  typingReportInterval: number
  versionSuffix: 'P' | 'S' | 'D' | 'T'
}

export const configuration: Configuration = {
  amplitudeApiKey: null,
  apiURLRoot: '',
  appURLRoot: '',
  cacheFilesDuration: 7 * day,
  cacheTempFilesDuration: 2 * day,
  chargebeePublishableKey: '',
  chargebeeSite: 'emmre',
  checkForUpdates: true,
  contactSupportUrl: 'https://www.emmre.com/contactsupport',
  faqURL: 'https://www.emmre.com/faq',
  features: {
    channels: false,
    giphy: false,
    paidSignUp: false,
  },
  giphyApiKey: '',
  helpEmail: 'help@emmre.com',
  helpVideosUrl: 'https://www.emmre.com/helpvideos',
  logLevel: 'off',
  logToSentryLevel: 'off',
  longRequestThresholdMS: 1000,
  privacyPolicyURL: 'https://www.emmre.com/privacy-policy',
  reduxDevTools: false,
  rtmURLRoot: '',
  sentryDSN: null,
  sentryDebug: false,
  stonlyDebug: false,
  stonlyWidgetId: '',
  supportURL: 'https://www.emmre.com/support',
  termsOfServiceURL: 'https://www.emmre.com/terms-of-service',
  typingReportInterval: 750,
  versionSuffix: 'P',
}
