import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { dateUtils } from '@/core/utils'

interface DayIndicatorProps {
  date: Date | string
}

function DayIndicator({ date }: DayIndicatorProps) {
  return (
    <Box direction="row" gapVertical="medium" justifyContent="center">
      <Text color="anchor" size="h6" weight="medium">
        {dateUtils.formatDateRelative(dateUtils.parse(date))}
      </Text>
    </Box>
  )
}

export default React.memo(DayIndicator)
