import React from 'react'
import Icon from '@/components/core/Icon'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { collectionUtils as C, taskUtils } from '@/core/utils'
import { useAppDispatch, useToggle } from '@/hooks'
import { taskThunks } from '@/thunks'
import { ChecklistItem } from '@/types/entities'
import TaskChecklistItemsList from '../TaskChecklistItemsList'
import { fieldNames, leftIconWidth } from '../constants'
import { useTaskFormField } from '../hooks'

function TaskChecklistItems() {
  const dispatch = useAppDispatch()
  const { value: checklistItems, setValue: setChecklistItems } = useTaskFormField<
    ChecklistItem[] | undefined
  >(fieldNames.checklistItems)
  const { showDetail, task } = useTaskFormContext()
  const [hideCompleted, toggleHideCompleted] = useToggle(false)
  const showChecklist = React.useCallback(() => showDetail('CHECKLIST_ITEMS'), [showDetail])
  const handleEditItem = React.useCallback(
    (index: number, item: ChecklistItem) => {
      if (task && item.id) {
        dispatch(taskThunks.setChecklistItemCompleted(task.spaceId, item.id, item.completed))
      } else {
        const nextItems = C.replaceAt(index, item, checklistItems || [])
        setChecklistItems(nextItems)
      }
    },
    [setChecklistItems, checklistItems, task, dispatch],
  )
  const [completedCount, totalCount] = React.useMemo(
    () => taskUtils.getCompletedItemsRatio(checklistItems),
    [checklistItems],
  )

  if (!checklistItems || checklistItems.length === 0) {
    return null
  }

  return (
    <Touchable feedback="highlight" onPress={showChecklist}>
      <Box alignItems="center" direction="row" gapHorizontal="large" gapTop="large">
        <Box alignSelf="flex-start" width={leftIconWidth}>
          <Icon color="shadow" name="list-ul" size="h4" />
        </Box>
        <Box flex={1}>
          <Box alignItems="center" direction="row" gapBottom="small" gapTop={1}>
            <Text size="h5">
              {completedCount}/{totalCount} Completed •{' '}
              <Link
                label={hideCompleted ? 'Show Completed' : 'Hide Completed'}
                onPress={toggleHideCompleted}
                size="h5"
              />
            </Text>
          </Box>
          <TaskChecklistItemsList
            canCompleteItem
            hideCompleted={hideCompleted}
            items={checklistItems}
            onEditItem={handleEditItem}
          />
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskChecklistItems)
