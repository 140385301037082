import { collectionUtils as C, offsetUtils as O } from '@/core/utils'
import { ReminderOffset } from '@/types/entities'

export const defaultOffsetChoices: ReminderOffset[] = [
  O.build({}),
  O.build({ minutes: -30 }),
  O.build({ hours: -1 }),
  O.build({ hours: -2 }),
  O.build({ days: -1 }),
  O.build({ days: -2 }),
  O.build({ weeks: -1 }),
]

type Label = { singular: string; plural: string }

const label = (singular: string, plural?: string): Label => ({
  plural: plural || `${singular}s`,
  singular,
})

export const unitLabels = {
  days: label('Day'),
  hours: label('Hour'),
  minutes: label('Minute'),
  months: label('Month'),
  weeks: label('Week'),
  years: label('Year'),
}

export type OffsetUnit = keyof typeof unitLabels

export const offsetUnits = Object.keys(unitLabels) as OffsetUnit[]

const neg = (vals: number[]): number[] => vals.map(n => -n)

export const unitAmounts: Record<OffsetUnit, number[]> = {
  days: neg(C.range(0, 31)),
  hours: neg(C.range(0, 25)),
  minutes: neg([0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90]),
  months: neg(C.range(0, 25)),
  weeks: neg(C.range(0, 27)),
  years: neg(C.range(0, 6)),
}
