import React from 'react'
import { useMessageListItems } from '@/hooks/messages'
import { Channel, ChannelUser } from '@/types/entities'
import { useCatchUp } from './catchUp'
import { useFirstUnreadMessageId } from './firstUnreadMessageId'
import { useLastReadMessage } from './lastReadMessage'
import { useLatestMessageId } from './latestMessageId'
import { useLoadMessages } from './loadMessages'

type MessagesListHookArgs = {
  channel: Channel
  channelUser: ChannelUser
  threadId: number | undefined
  showThreadStart: boolean
}

export const useMessagesList = ({
  channel,
  channelUser,
  threadId,
  showThreadStart,
}: MessagesListHookArgs) => {
  const messageItems = useMessageListItems({
    channel,
    channelUser,
    hideTaskActivity: true,
    showThreadStart,
    threadId,
  })
  const { visibleItemsChanged, earliestViewedId } = useLastReadMessage(channelUser)
  const loadMessageArgs = React.useMemo(
    () => ({
      channelId: channel.id,
      limit: 25,
      spaceId: channel.spaceId,
      threadId,
    }),
    [channel.id, channel.spaceId, threadId],
  )
  const { loadMore, hasMore, isLoading, hasError } = useLoadMessages(loadMessageArgs)
  const atBeginning = !hasMore
  const firstUnreadMessageId = useFirstUnreadMessageId(channelUser, messageItems, atBeginning)
  const hasUnreadAbove = getHasUnreadAbove(channelUser, firstUnreadMessageId, earliestViewedId)
  const latestMessageId = useLatestMessageId(messageItems)

  useCatchUp(channel, threadId, latestMessageId)

  return {
    firstUnreadMessageId,
    hasError,
    hasMore,
    hasUnreadAbove,
    isLoading,
    loadMore,
    messageItems,
    visibleItemsChanged,
  }
}

const getHasUnreadAbove = (
  channelUser: ChannelUser,
  firstUnreadMessageId?: number,
  earliestViewedId?: number,
) => {
  if (channelUser.unreadMessageCount === 0) {
    return false
  }

  if (!earliestViewedId) {
    return false
  }
  if (!firstUnreadMessageId) {
    return earliestViewedId > channelUser.maxReadMessageId
  }
  return earliestViewedId > firstUnreadMessageId
}
