import React from 'react'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { dateUtils as D } from '@/core/utils'
import { useCachedSelector } from '@/hooks'
import { entitySelectors, taskListItemSelectors } from '@/store/selectors'
import { Task } from '@/types/entities'

interface MyDayIndicatorProps {
  task: Task
  size: number
  color: ColorName
}

function MyDayIndicator({ task, size, color }: MyDayIndicatorProps) {
  const myDayList = useCachedSelector(entitySelectors.currentUserTaskListSelector, ['MY_DAY'])
  const myDayItem = useCachedSelector(taskListItemSelectors.byIdsSelector, [
    myDayList?.id || 0,
    task.spaceId,
    task.id,
  ])

  const isIgnored = !!myDayItem && myDayItem.ignoreUntil && myDayItem.ignoreUntil >= D.nowISO()
  const isMyDay = !!myDayItem && !myDayItem.ignoreUntil

  if (isIgnored) {
    return (
      <Box gapRight="small">
        <Icon color={color} name="eclipse" size={size} />
      </Box>
    )
  }

  if (isMyDay) {
    return (
      <Box gapRight="small">
        <Icon color={color} name="sun-bright" size={size} />
      </Box>
    )
  }

  return null
}

export default React.memo(MyDayIndicator)
