import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { spaceUtils } from '@/core/utils'
import { getPlanName } from '@/core/utils/chargebee'
import { pluralize } from '@/core/utils/strings'
import { useAppDispatch } from '@/hooks'
import { notificationActions } from '@/store/actions'
import { Space } from '@/types/entities'
import { PartnershipDescription } from './Descriptions'
import ProductCard from './ProductCard'
import WithEstimates from './WithEstimates'
import { usePay } from './hooks'

interface UpgradeToPartnershipBodyProps {
  space: Space
  onClose: () => void
  onUpgraded: (spaceId: number) => void
  upgradeAction: string
}

function UpgradeToPartnershipBody({
  space,
  onClose,
  onUpgraded,
  upgradeAction,
}: UpgradeToPartnershipBodyProps) {
  const { id: spaceId } = space
  const daysRemaining = spaceUtils.daysRemaining(space.paidThroughDate)
  const { makePayment, isPending } = usePay(spaceId)
  const isTrial = spaceUtils.isUnpaidTrial(space.accountType)
  const dispatch = useAppDispatch()

  const handleSelect = React.useCallback(async () => {
    const ok = await makePayment('PARTNERSHIP', null)
    const planName = getPlanName('PARTNERSHIP')

    if (ok) {
      onClose()
      onUpgraded(spaceId)
      setTimeout(() => {
        dispatch(
          notificationActions.push({
            body: `Your plan has been updated to ${planName}.`,
            title: `Plan switched to ${planName}!`,
            type: 'success',
          }),
        )
      }, 300)
    }
  }, [onClose, onUpgraded, makePayment, spaceId, dispatch])

  return (
    <>
      <Text align="center">
        Switch to <Text weight="medium">Emmre Partnership</Text> to {upgradeAction}.
      </Text>
      <Box gapHorizontal={50} gapVertical="large">
        {isTrial ? (
          <Text align="center" color="emmre-blue">
            No CC required! You still have {pluralize(daysRemaining, 'day')} remaining in your free
            trial.
          </Text>
        ) : (
          <Text align="center" color="emmre-blue">
            Changes to your plan will be pro-rated for this billing period.
          </Text>
        )}
      </Box>
      <Box alignItems="center">
        <WithEstimates spaceId={spaceId}>
          {estimates => (
            <ProductCard
              color="indigo"
              description={<PartnershipDescription />}
              disabled={isPending}
              estimates={estimates}
              flex={false}
              onSelect={handleSelect}
              productVersion="PARTNERSHIP"
              space={space}
              supportsMultipleUsers
            />
          )}
        </WithEstimates>
      </Box>
    </>
  )
}

export default React.memo(UpgradeToPartnershipBody)
