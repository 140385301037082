import { clearTaskRelationshipFields } from '@/core/constants/tasks'
import { SpaceEntityIds } from '@/types/entities'
import { TaskRelationship, TaskRelationshipFields, TaskType, UnaryTaskFilter } from '@/types/tasks'
import { includeList, mergeFilters, unaryFilter } from './filtering'

export const buildBaseFilter = (spaceId: number, taskType: TaskType): UnaryTaskFilter =>
  unaryFilter({
    completed: false,
    isQuestion: taskType === 'QUESTION',
    spaceIds: includeList([spaceId]),
  })

export const buildChannelTasksFilter = (
  channelIds: SpaceEntityIds,
  contextFilter?: UnaryTaskFilter,
): UnaryTaskFilter => {
  const filter = unaryFilter({
    channelIds: includeList([[channelIds.spaceId, channelIds.id]]),
  })

  return contextFilter ? mergeFilters(contextFilter, filter) : filter
}

export const buildNoTaskTypeFilter = (filter?: UnaryTaskFilter): UnaryTaskFilter | undefined => {
  if (!filter) {
    return undefined
  }
  const copy = { ...filter }
  delete copy.isQuestion
  return copy
}

export const buildMyDayTasksFilter = (taskListId: number): UnaryTaskFilter =>
  unaryFilter({
    completed: false,
    spaceIds: { mode: 'all' },
    taskList: { relationship: 'IN_LIST', taskListId },
  })

export const buildPlanMyDayTasksFilter = (taskListId: number, seenTaskIds: [number, number][]) =>
  unaryFilter({
    taskIds: includeList(seenTaskIds, 'exclude'),
    taskList: { relationship: 'NOT_IGNORED', taskListId },
  })

export const buildHubTasksFilter = (channelIds: SpaceEntityIds, userId?: number): UnaryTaskFilter =>
  unaryFilter({
    assignedUserIds: userId ? includeList([userId], 'include') : undefined,
    channelIds: includeList([[channelIds.spaceId, channelIds.id]]),
    completed: false,
    isQuestion: false,
    spaceIds: includeList([channelIds.spaceId]),
  })

export const getTaskRelationshipFields = (
  taskRelationship: TaskRelationship,
  currentUserId: number,
): TaskRelationshipFields => {
  if (currentUserId) {
    if (taskRelationship === 'MINE') {
      return { assignedUserIds: includeList([currentUserId]), watchingUserIds: undefined }
    }
    if (taskRelationship === 'WATCHING') {
      return {
        assignedUserIds: includeList([currentUserId], 'exclude'),
        watchingUserIds: includeList([currentUserId]),
      }
    }
  }

  return clearTaskRelationshipFields
}

export const buildRelationshipFilter = (
  taskRelationship: TaskRelationship,
  currentUserId: number,
  filter: UnaryTaskFilter,
) => mergeFilters(filter, getTaskRelationshipFields(taskRelationship, currentUserId))

export const buildNoRelationshipFilter = (baseFilter: UnaryTaskFilter) =>
  mergeFilters(baseFilter, clearTaskRelationshipFields)
