import React from 'react'
import { EditItemData } from '../types'
import EditSummaryField from './EditSummaryField'
import EditSummaryHeaderItem from './EditSummaryHeaderItem'
import EditSummaryItem from './EditSummaryItem'

interface EditSummaryListItemProps {
  isDragging: boolean
  item: EditItemData
  onInitiateDrag?: () => void
  index: number
}

function EditSummaryListItem({
  item,
  onInitiateDrag,
  isDragging,
  index,
}: EditSummaryListItemProps) {
  if (item.type === 'title') {
    return <EditSummaryField content={item.content} fieldName="title" index={index} />
  }
  if (item.type === 'greeting') {
    return (
      <EditSummaryField border="bottom" content={item.content} fieldName="greeting" index={index} />
    )
  }
  if (item.type === 'closing') {
    return (
      <EditSummaryField
        border="top"
        content={item.content}
        fieldName="closing"
        index={index}
        showAddHeader
        showAddItem
      />
    )
  }
  const { summaryItem } = item
  const { itemType } = summaryItem

  if (itemType === 'HEADER') {
    return (
      <EditSummaryHeaderItem
        index={index}
        isDragging={isDragging}
        onInitiateDrag={onInitiateDrag}
        showAdd
        summaryItem={summaryItem}
      />
    )
  }
  if (itemType === 'ITEM') {
    const { header } = item

    return (
      <EditSummaryItem
        headerId={header?.id || null}
        index={index}
        isDragging={isDragging}
        onInitiateDrag={onInitiateDrag}
        showAdd
        summaryItem={summaryItem}
      />
    )
  }
  throw new Error(`Unhandled summary item type: ${itemType}`)
}

export default React.memo(EditSummaryListItem)
