import React from 'react'
import { prepareFilterOptions } from '@/core/utils/tasks/filterGroups'
import * as filterGroupHooks from '@/hooks/tasks/filterGroups'
import { useMapFilterOption } from '@/hooks/tasks/filtering'
import { FilterGroup, TaskRelationshipFields, TaskSort, UnaryTaskFilter } from '@/types/tasks'

type FilterGroupArgs = {
  baseFilter: UnaryTaskFilter
  channelId?: number | null | undefined
  groupSort: TaskSort[]
  relationshipFields: TaskRelationshipFields
  showAllGroup: boolean
  spaceId: number
}

export const useFilterGroups = ({
  baseFilter,
  channelId,
  groupSort,
  relationshipFields,
  showAllGroup,
  spaceId,
}: FilterGroupArgs) => {
  const mapOption = useMapFilterOption(baseFilter, relationshipFields)

  const allFilter = filterGroupHooks.useAllTasksFilterGroup(groupSort)
  const priorityFilter = filterGroupHooks.usePriorityFilterGroup(groupSort)
  const dueDateFilter = filterGroupHooks.useDueDateFilterGroup(groupSort)
  const boardFilter = filterGroupHooks.useBoardFilterGroup(spaceId, channelId || null, groupSort)
  const tagFilter = filterGroupHooks.useTagFilterGroup(spaceId, groupSort)
  const createdDateFilter = filterGroupHooks.useCreatedDateFilterGroup(groupSort)
  const statusFilter = filterGroupHooks.useStatusFilterGroup(groupSort)
  const createdByFilter = filterGroupHooks.useCreatedByFilterGroup(
    spaceId,
    channelId || null,
    groupSort,
  )
  const sourceFilter = filterGroupHooks.useSourceFilterGroup(groupSort)

  const optionGroups = React.useMemo<FilterGroup[]>(() => {
    let filterGroups = [
      createdDateFilter,
      priorityFilter,
      dueDateFilter,
      boardFilter,
      tagFilter,
      statusFilter,
      createdByFilter,
      sourceFilter,
    ]

    if (showAllGroup) {
      filterGroups = [allFilter, ...filterGroups]
    }

    // TODO: Only treat options that are currently displayed?
    return filterGroups.map(group => prepareFilterOptions(group, { mapOption }))
  }, [
    allFilter,
    boardFilter,
    createdByFilter,
    createdDateFilter,
    dueDateFilter,
    mapOption,
    priorityFilter,
    showAllGroup,
    sourceFilter,
    statusFilter,
    tagFilter,
  ])

  return optionGroups
}
