import React from 'react'
import TypingIndicator from '@/components/channels/TypingIndicator'
import BaseIconButton from '@/components/core/BaseIconButton'
import { ScrollableAttachmentsList } from '@/components/files/AttachmentsList'
import Box from '@/components/layouts/Box'
import EmojiPickerButton from '@/components/messages/EmojiPickerButton'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { hasFeature } from '@/core/config'
import { platformUtils } from '@/core/utils'
import { useCachedSelector } from '@/hooks'
import { useIsAppForegrounded } from '@/hooks/appState'
import { useAppendEmojiToFormField } from '@/hooks/emoji'
import { useAttachments } from '@/hooks/messages'
import { useSendMessage } from '@/hooks/messages/messages'
import { formSelectors } from '@/store/selectors'
import { Channel } from '@/types/entities'
import NewlineHelper from './NewlineHelper'

interface ToolbarProps {
  spaceId: number
  formName: string
  channel: Channel
  onEmojiPick?: () => void
  InputModeSelector: React.ReactNode
}

const size = { height: 64, width: 64 }

function Toolbar({ spaceId, formName, channel, onEmojiPick, InputModeSelector }: ToolbarProps) {
  const { attachmentIds, content } = useCachedSelector(formSelectors.valuesSelector, [formName])
  const { handleAttachPhoto, handleRemoveAttachment, handlePickDocument } = useAttachments(
    spaceId,
    formName,
    'attachmentIds',
  )
  const hasAttachments = attachmentIds && attachmentIds.length !== 0
  const handlePickEmoji = useAppendEmojiToFormField(formName, 'content', onEmojiPick)
  const isForegroudned = useIsAppForegrounded()
  const modalPresenter = useModalPresenterContext()
  const sendMessage = useSendMessage(channel.id, channel.spaceId)
  const handlePickGiphy = React.useCallback(() => {
    modalPresenter.push('giphyPicker', {
      onPick: url => sendMessage(url),
    })
  }, [modalPresenter, sendMessage])

  return (
    <Box direction="column">
      {hasAttachments ? (
        <Box gapTop="small">
          <ScrollableAttachmentsList
            attachmentIds={attachmentIds}
            onRemove={handleRemoveAttachment}
            size={size}
          />
        </Box>
      ) : null}
      <Box gapVertical="small">
        <TypingIndicator
          FallbackComponent={<NewlineHelper show={!!content} />}
          channel={channel}
          isActive={isForegroudned}
          mode="MULTIPLE"
        />
      </Box>
      <Box
        alignItems="flex-start"
        direction="row"
        gapHorizontal="small"
        justifyContent="space-between"
      >
        {InputModeSelector}
        <Box direction="row">
          {hasFeature('giphy') ? (
            <Box gapLeft="large">
              <BaseIconButton
                color="iron"
                hitSlop="xsmall"
                name="gif"
                onPress={handlePickGiphy}
                padding={0}
                shape="square"
                size="h3"
              />
            </Box>
          ) : null}
          <Box gapLeft="large">
            <BaseIconButton
              color="iron"
              hitSlop="xsmall"
              name="paperclip"
              onPress={handlePickDocument}
              padding={0}
              shape="square"
              size="h3"
            />
          </Box>
          {platformUtils.isWeb ? null : (
            <Box gapLeft="large">
              <BaseIconButton
                color="iron"
                hitSlop="xsmall"
                name="image"
                onPress={handleAttachPhoto}
                padding={0}
                shape="square"
                size="h3"
              />
            </Box>
          )}
          <Box gapLeft="large">
            <EmojiPickerButton onPick={handlePickEmoji} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(Toolbar)
