import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { dateUtils as D } from '@/core/utils'

interface DueDateLabelProps {
  dueDate: Date | null
}

function DueDateLabel({ dueDate }: DueDateLabelProps) {
  if (!dueDate) {
    return null
  }
  return (
    <Box direction="row" justifyContent="center">
      <Text color="iron">Due: {D.formatShort(dueDate)}</Text>
    </Box>
  )
}

export default React.memo(DueDateLabel)
