import React from 'react'
import { StyleSheet } from 'react-native'
import Handle from '@/components/core/Handle'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'

function TaskFormHandle() {
  const { taskFormSize, setTaskFormSize } = useTaskFormContext()
  const handleToggle = React.useCallback(() => {
    setTaskFormSize?.(taskFormSize === 'MEDIUM' ? 'SMALL' : 'MEDIUM')
  }, [taskFormSize, setTaskFormSize])
  const canChangeSize = !!setTaskFormSize

  // If the handle can't be used, just render out a margin placeholder
  if (!canChangeSize) {
    return <Box height={10} />
  }

  return (
    <Touchable feedback="none" onPress={handleToggle} style={styles.container}>
      <Handle color="iron" />
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 8,
    paddingTop: 8,
  },
})

export default React.memo(TaskFormHandle)
