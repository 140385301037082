import React from 'react'
import { StyleSheet } from 'react-native'
import Icon from '../Icon'
import Touchable from '../Touchable'
import { categoryIcons } from './constants'

interface EmojiCategoryListItemProps {
  categoryName: string
  onPress: (categoryName: string) => void
}

function EmojiCategoryListItem({ categoryName, onPress }: EmojiCategoryListItemProps) {
  const handlePress = React.useCallback(() => onPress(categoryName), [onPress, categoryName])

  return (
    <Touchable feedback="opacity" onPress={handlePress} style={styles.container}>
      <Icon color="iron" name={categoryIcons[categoryName] || 'face-smile'} size="h4" />
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
})

export default React.memo(EmojiCategoryListItem)
