import React from 'react'
import { noOpWarn } from '@/core/logging'
import { File, Message } from '@/types/entities'
import { CacheDuration } from '@/types/files'

export type FullScreenMediaProps = {
  uri: string
  mediaType: 'IMAGE' | 'VIDEO'
  duration?: CacheDuration
  message?: Message
  file?: File
}

export type FullScreenMediaContextType = (props: FullScreenMediaProps) => void

const defaultValue: FullScreenMediaContextType = noOpWarn('full screen image context not present')

const FullScreenMediaContext = React.createContext<FullScreenMediaContextType>(defaultValue)

export const useFullScreenMediaContext = () => React.useContext(FullScreenMediaContext)

export default FullScreenMediaContext
