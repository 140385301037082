import React from 'react'
import CachedImage, { CachedImageProps } from '@/components/core/CachedImage'
import Touchable from '@/components/core/Touchable'
import { useFileActionsContext } from '@/contexts/FileActionsContext'
import { useFullScreenMediaContext } from '@/contexts/FullScreenMediaContext'
import { File, Message } from '@/types/entities'

interface ActionableImageProps extends CachedImageProps {
  message?: Message
  file?: File
}

function ActionableImage({ message, file, ...cachedImageProps }: ActionableImageProps) {
  const { uri, duration } = cachedImageProps
  const showFileActions = useFileActionsContext()
  const showMedia = useFullScreenMediaContext()

  const handleShowImage = React.useCallback(
    () => showMedia({ duration, file, mediaType: 'IMAGE', message, uri }),
    [showMedia, uri, duration, message, file],
  )
  const handleSharing = React.useCallback(async () => {
    if (uri) {
      showFileActions({ duration, message, uri })
    }
  }, [showFileActions, uri, duration, message])

  return (
    <Touchable feedback="opacity" onLongPress={handleSharing} onPress={handleShowImage}>
      <CachedImage {...cachedImageProps} />
    </Touchable>
  )
}

export default React.memo(ActionableImage)
