import React from 'react'
import { ColorName } from '@/core/constants/colors'
import { typeUtils, urlUtils } from '@/core/utils'
import ExternalLink from './ExternalLink'
import Text, { TextProps } from './Text'

interface TextWithLinksWrapperProps extends TextProps {
  linkColor?: ColorName
}

interface TextWithLinksProps extends Omit<TextWithLinksWrapperProps, 'children'> {
  children: string
}

function TextWithLinks({ children, color, linkColor, ...textProps }: TextWithLinksProps) {
  const pairs = React.useMemo(() => urlUtils.splitURLs(children), [children])

  if (pairs.length === 1 && !pairs[0].url) {
    return (
      <Text {...textProps} color={color}>
        {children}
      </Text>
    )
  }

  /* eslint-disable react/no-array-index-key */
  return (
    <Text {...textProps} color={color}>
      {pairs.map(({ text, url }, index) => (
        <React.Fragment key={index}>
          {text}
          {url ? (
            <ExternalLink color={linkColor || color} label={url} underlined url={url} />
          ) : null}
        </React.Fragment>
      ))}
    </Text>
  )
  /* eslint-enable */
}

function TextWithLinksWrapper({ children, ...textProps }: TextWithLinksWrapperProps) {
  if (!typeUtils.isString(children) || !children) {
    return <Text {...textProps}>{children}</Text>
  }
  return <TextWithLinks {...textProps}>{children}</TextWithLinks>
}

export default React.memo(TextWithLinksWrapper)
