import { HubNamingMode } from '@/types/channels'
import {
  Channel,
  ChannelStatus,
  ChannelType,
  ChannelUser,
  ResolvedChannel,
  SpaceUser,
} from '@/types/entities'
import { Dictionary, Predicate } from '@/types/generics'
import * as D from './dates'
import * as S from './strings'
import { buildUserId, getFirstName, getFullName } from './users'

export const isChannelType = (channelType: ChannelType | ChannelType[]): Predicate<Channel> => {
  if (Array.isArray(channelType)) {
    const channelTypes = new Set(channelType)
    return channel => channelTypes.has(channel.channelType)
  }

  return channel => channel.channelType === channelType
}

export const isPublicGroup = (channel: Channel) => channel.channelType === 'PUBLIC_GROUP'

export const isPrivateGroup = (channel: Channel) => channel.channelType === 'PRIVATE_GROUP'

export const isGroup = (channel: Channel) => isPublicGroup(channel) || isPrivateGroup(channel)

export const isDM = (channel: Channel) => channel.channelType === 'DIRECT_MESSAGE'

export const isHub = (channel: Channel) => channel.channelType === 'HUB'

export const isPrivateHub = (channel: Channel) => channel.channelType === 'PRIVATE_HUB'

export const channelTypeToLabel = (channelType: ChannelType, plural = false): string => {
  if (channelType === 'DIRECT_MESSAGE') {
    return plural ? 'Direct Messages' : 'Direct Message'
  }
  if (channelType === 'HUB' || channelType === 'PRIVATE_HUB') {
    return plural ? 'Hubs' : 'Hub'
  }
  return plural ? 'Channels' : 'Channel'
}

export const channelToLabel = (channel: Channel): string => {
  if (isHub(channel)) {
    if (channel.userIds.length === 1) {
      return 'Private: Just You'
    }
    return 'Executive Hub'
  }
  if (isDM(channel)) {
    if (channel.userIds.length === 1) {
      return 'Your Hub'
    }
    return 'Direct Message'
  }
  if (isPublicGroup(channel)) {
    return 'Public Group'
  }
  if (isPrivateGroup(channel)) {
    return 'Private Group'
  }
  throw new Error(`Unable to determine channel type`)
}

type HubUsersResult = [SpaceUser, SpaceUser | undefined] | undefined

export const getHubUsers = (
  myId: number,
  firstUser: SpaceUser | undefined,
  secondUser: SpaceUser | undefined,
): HubUsersResult => {
  if (!firstUser) {
    return undefined
  }

  if (!secondUser) {
    return [firstUser, undefined]
  }

  // Always return the current user's name first
  return firstUser.id === myId ? [firstUser, secondUser] : [secondUser, firstUser]
}

export const getHubUserId = (userIds: number[], myId: number): number | null => {
  if (userIds.length === 1) {
    return userIds[0]
  }

  if (userIds.length !== 2) {
    return null
  }

  const [userId1, userId2] = userIds

  if (userId1 === myId) {
    return userId2
  }

  if (userId2 === myId) {
    return userId1
  }

  // This should never happen
  return null
}

export const hasReadReceipts = (channel: Channel): boolean =>
  (isDM(channel) || isHub(channel)) && channel.userIds.length === 2

export const isPersonalDM = (channel?: Channel): boolean =>
  !!channel && isDM(channel) && channel.userIds.length === 1

export const isJustYouHub = (channel?: Channel): boolean =>
  !!channel && isHub(channel) && channel.userIds.length === 1

export const getProxyPersonalDMChannel = (userId: number, spaceId: number): Channel => ({
  archivedAt: null,
  channelType: 'DIRECT_MESSAGE',
  createdAt: D.nowISO(),
  id: -1,
  name: null,
  spaceId,
  updatedAt: D.nowISO(),
  userIds: [userId],
})

export const membershipMatches = (otherUserIds: Set<number>, channel: Channel): boolean => {
  // A channel will have the current user and other user IDs. To check of a channel is with other
  // users, we should have a list of other user IDs and one id in the channel that isn't included

  if (channel.userIds.length !== otherUserIds.size + 1) {
    return false
  }

  let foundMissing = false

  for (const userId of channel.userIds) {
    if (!otherUserIds.has(userId)) {
      // The first missing individual is the current user. The 2nd missing individual means the
      // channel membership doesn't match
      if (foundMissing) {
        return false
      }

      foundMissing = true
    }
  }

  return true
}

const isArchived = (channel: Channel) => !!channel.archivedAt

const isHidden = (channelUser?: ChannelUser) => channelUser?.visibility === 'HIDDEN'

export const getChannelStatus = (channel: Channel, channelUser?: ChannelUser): ChannelStatus => {
  if (isArchived(channel)) {
    return 'ARCHIVED'
  }
  if (isHidden(channelUser)) {
    return 'SILENCED'
  }
  return 'ACTIVE'
}

export const isUserInChannel = (channel: Channel, userId: number): boolean =>
  channel.userIds.includes(userId)

export const compareByChannelType = (left: ResolvedChannel, right: ResolvedChannel): number => {
  if (left.channelType === right.channelType) {
    // Shove personal to end
    if (isPersonalDM(left)) {
      return 1
    }
    if (isPersonalDM(right)) {
      return -1
    }
    return left.name.localeCompare(right.name)
  }
  if (isGroup(left)) {
    return -1
  }
  if (isGroup(right)) {
    return 1
  }
  return 0
}

export const compareByRecentActivity = (left: ResolvedChannel, right: ResolvedChannel): number => {
  const leftLastMessageAt = left.channelStats?.lastMessageSentAt
  const rightLastMessageAt = right.channelStats?.lastMessageSentAt
  const leftDate = leftLastMessageAt || left.createdAt
  const rightDate = rightLastMessageAt || right.createdAt

  const comp = S.sortDescending(leftDate, rightDate)
  if (comp !== 0) {
    return comp
  }

  return S.sortAscending(left.name, right.name)
}

export const getChannelName = (
  channel: Channel,
  myId: number,
  usersIndex: Dictionary<SpaceUser | undefined>,
  hubNamingMode: HubNamingMode = 'BOTH',
): string => {
  // If a channel has a name, show that. Otherwise we will show something based on the names of
  // the members of the channel
  if (channel.name) {
    return channel.name
  }

  if (isPersonalDM(channel)) {
    const me = usersIndex[buildUserId(channel.spaceId, myId)]
    return me ? `${getFirstName(me)} (You)` : '(You)'
  }

  if (isJustYouHub(channel)) {
    return 'Private: Just You'
  }

  const asHub = isHub(channel)

  // If it's a 2 person hub, use the two-person hub ordering for naming
  if (asHub && channel.userIds.length === 2) {
    const [firstUserId, secondUserId] = channel.userIds
    if (hubNamingMode === 'OTHER') {
      const userId = firstUserId === myId ? secondUserId : firstUserId
      const user = usersIndex[buildUserId(channel.spaceId, userId)]

      if (user) {
        return getFullName(user)
      }
    } else {
      const firstUser = usersIndex[buildUserId(channel.spaceId, firstUserId)]
      const secondUser = usersIndex[buildUserId(channel.spaceId, secondUserId)]
      const hubUsers = getHubUsers(myId, firstUser, secondUser)

      if (hubUsers) {
        const [primary, secondary] = hubUsers
        if (primary && secondary) {
          return `${getFirstName(primary)} & ${getFirstName(secondary)}`
        }
      }
    }
  }

  // Always show the current user's name last
  const userIds = channel.userIds.filter(userId => userId !== myId)
  userIds.push(myId)

  const userNames = userIds
    .map(userId => {
      const user = usersIndex[buildUserId(channel.spaceId, userId)]

      if (!user) {
        return ''
      }

      // For hubs show the first names of all users
      if (asHub) {
        if (hubNamingMode === 'OTHER' && user.id === myId) {
          return ''
        }
        return getFirstName(user)
      }

      // If it is a 1:1 DM, only show the name of the other person
      if (user.id === myId && channel.userIds.length === 2) {
        return ''
      }

      return getFullName(user)
    })
    .filter(name => !!name)

  if (!userNames || userNames.length === 0) {
    return ''
  }

  const namesList = S.toList(userNames, ' &')

  return namesList
}
