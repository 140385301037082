import { useFocusEffect } from '@react-navigation/native'
import { StatusBarStyle, setStatusBarStyle } from 'expo-status-bar'
import React from 'react'
import { SectionListData, TextInput, useWindowDimensions } from 'react-native'
import { measureToLayoutPosition } from '@/core/utils/components'
import { HorizontalPin, LayoutPosition, Measure, VerticalPin } from '@/types/components'

export const useIsMounted = (): React.MutableRefObject<boolean> => {
  const isMountedRef = React.useRef<boolean>(false)

  React.useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  })

  return isMountedRef
}

export const useSetStatusBar = (style: StatusBarStyle) =>
  useFocusEffect(React.useCallback(() => setStatusBarStyle(style), [style]))

export const useLayoutPosition = (
  verticalPin: VerticalPin,
  horizontalPin: HorizontalPin,
  anchorMeasure: Measure,
): LayoutPosition => {
  const window = useWindowDimensions()
  return React.useMemo(
    () => measureToLayoutPosition(verticalPin, horizontalPin, window, anchorMeasure),
    [window, verticalPin, horizontalPin, anchorMeasure],
  )
}

export const useTextInputAutoFocus = () => {
  const ref = React.useRef<TextInput | null>(null)
  React.useEffect(() => {
    setTimeout(() => ref.current?.focus())
  }, [])
  return ref
}

export const useSectionListGetItemLayout = <ItemT, SectionT>(
  data: SectionListData<ItemT, SectionT>[],
  rowHeight: number,
) =>
  React.useCallback(
    (item: SectionListData<ItemT, SectionT>[] | null, index: number) => ({
      index,
      length: rowHeight,
      offset: index * rowHeight,
    }),

    // let indexOffset = 0
    // let sectionCount = 0

    // for (const section of data) {
    //   const maxIndex = section.data.length + indexOffset

    //   if (maxIndex >= index) {
    //     break
    //   }

    //   sectionCount += 1
    //   indexOffset += section.data.length
    // }

    // const offset = rowHeight * index + sectionCount * headerHeight

    // return {
    //   index,
    //   length: rowHeight,
    //   offset,
    // }
    // [data, rowHeight, headerHeight],
    [rowHeight],
  )

const defaultMeasure: Measure = { height: 0, width: 0, x: 0, y: 0 }

export const useMenu = () => {
  const [anchorMeasure, setAnchorMeasure] = React.useState(defaultMeasure)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const openMenu = React.useCallback((measure: Measure) => {
    setAnchorMeasure(measure)
    setIsMenuOpen(true)
  }, [])
  const closeMenu = React.useCallback(() => setIsMenuOpen(false), [setIsMenuOpen])

  return { anchorMeasure, closeMenu, isMenuOpen, openMenu }
}
