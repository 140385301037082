import React from 'react'
import Box from '@/components/layouts/Box'
import EmojiCategoryListItem from './EmojiCategoryListItem'

interface EmojiCategoryListProps {
  categoryNames: string[]
  onSelect: (categoryName: string) => void
}

function EmojiCategoryList({ categoryNames, onSelect }: EmojiCategoryListProps) {
  return (
    <Box direction="row">
      {categoryNames.map(categoryName => (
        <EmojiCategoryListItem key={categoryName} categoryName={categoryName} onPress={onSelect} />
      ))}
    </Box>
  )
}

export default React.memo(EmojiCategoryList)
