import React from 'react'
import DraggableFlatList, {
  DragEndParams,
  RenderItem,
  ScaleDecorator,
} from 'react-native-draggable-flatlist'
import Box from '@/components/layouts/Box'
import { GroupedTask, TaskGrouping, TaskQuery, TaskSelector, TaskType } from '@/types/tasks'
import TaskListItem, { RenderCustomFocus } from './TaskListItem'
import TaskSectionHeader from './TaskSectionHeader'
import ToggleCompletedButton from './ToggleCompletedButton'
import { useTasksList } from './hooks'

interface DraggableTasksListProps extends TaskSelector {
  fastCreate: boolean
  onDragEnd: (params: DragEndParams<GroupedTask>) => void
  renderCustomFocus?: RenderCustomFocus
  showAssignee: boolean
  showMyDay: boolean
  taskGrouping: TaskGrouping | null
  taskQuery: TaskQuery
  emptyComponent?: React.ComponentType<any> | React.ReactElement | null
  taskType: TaskType
}

const keyExtractor = (item: GroupedTask) => `${item.task.spaceId}:${item.task.id}:${item.label}`

function DraggableTasksList({
  fastCreate,
  onDragEnd,
  onSelectTask,
  renderCustomFocus,
  selectedTaskIds,
  showAssignee,
  showMyDay,
  taskGrouping,
  taskQuery,
  taskType,
  emptyComponent,
}: DraggableTasksListProps) {
  const {
    flatListRef,
    groupedTasks,
    handleShowTask,
    hiddenFilter,
    showCompleted,
    toggleShowCompleted,
  } = useTasksList({ fastCreate, rememberPreviousItems: false, taskGrouping, taskQuery })
  const { focus } = taskQuery

  const renderGroupedTask = React.useCallback<RenderItem<GroupedTask>>(
    ({ item, index, drag }) => {
      const { task, label } = item

      const listItem = (
        <TaskListItem
          focus={focus}
          isSelected={!!selectedTaskIds && selectedTaskIds.includes(task.id)}
          onInitiateMove={drag}
          onPress={handleShowTask}
          onSelectTask={onSelectTask}
          renderCustomFocus={renderCustomFocus}
          showAssignee={showAssignee}
          showMyDay={showMyDay}
          task={task}
        />
      )

      const previous = !index ? undefined : groupedTasks[index - 1]
      const showLabel = label && previous?.label !== label

      return (
        <ScaleDecorator>
          <Box gapBottom="xsmall" gapHorizontal="medium">
            {showLabel ? <TaskSectionHeader label={label} /> : null}
            {listItem}
          </Box>
        </ScaleDecorator>
      )
    },
    [
      focus,
      handleShowTask,
      groupedTasks,
      showAssignee,
      showMyDay,
      renderCustomFocus,
      onSelectTask,
      selectedTaskIds,
    ],
  )

  const toggleButton = (
    <ToggleCompletedButton
      hiddenFilter={hiddenFilter}
      onToggle={toggleShowCompleted}
      showCompleted={showCompleted}
      taskType={taskType}
    />
  )

  const position = showCompleted || groupedTasks.length === 0 ? 'top' : 'bottom'

  return (
    <DraggableFlatList
      ref={flatListRef}
      ListEmptyComponent={emptyComponent}
      ListFooterComponent={position === 'bottom' ? <Box minHeight={100}>{toggleButton}</Box> : null}
      ListHeaderComponent={position === 'top' ? toggleButton : null}
      data={groupedTasks}
      extraData={renderGroupedTask}
      keyExtractor={keyExtractor}
      keyboardShouldPersistTaps="handled"
      onDragEnd={onDragEnd}
      renderItem={renderGroupedTask}
    />
  )
}

export default React.memo(DraggableTasksList)
