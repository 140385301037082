import React from 'react'
import { renderProp } from '@/core/utils/components'

interface IfProps {
  check: boolean | (() => boolean)
  children: React.ReactNode | (() => React.ReactNode)
  ifFalse?: React.ReactNode | (() => React.ReactNode)
}

function If({ check, children, ifFalse }: IfProps) {
  const val = typeof check === 'function' ? check() : check

  if (val) {
    return <>{renderProp(children)}</>
  }

  if (ifFalse) {
    return <>{renderProp(ifFalse)}</>
  }

  return null
}

export default React.memo(If)
