import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'
import Overlay from './Overlay'
import { getTabWidth, tabRadius } from './constants'

interface ContentTabProps {
  side: 'left' | 'right'
  color: ColorName
  icon?: IconName
  hasOverlay?: boolean
}

function ContentTab({ side, color, icon, hasOverlay = false }: ContentTabProps) {
  const blr = side === 'left' ? tabRadius : 0
  const brr = side === 'right' ? tabRadius : 0
  const tlr = side === 'left' ? tabRadius : 0
  const trr = side === 'right' ? tabRadius : 0

  return (
    <Background
      borderBottomLeftRadius={blr}
      borderBottomRightRadius={brr}
      borderTopLeftRadius={tlr}
      borderTopRightRadius={trr}
      color={color}
      width={getTabWidth(icon)}
    >
      <Box direction="row" gapAll="small" justifyContent="center">
        {icon ? <Icon color="ghost" name={icon} size="h4" /> : null}
      </Box>
      {hasOverlay ? <Overlay radiusSide={side} /> : null}
    </Background>
  )
}

export default React.memo(ContentTab)
