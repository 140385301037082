import React from 'react'
import Link from '@/components/core/Link'
import Box from '@/components/layouts/Box'
import CenteredLayout from '@/components/layouts/CenteredLayout'
import { SummaryItem } from '@/types/entities'
import AcknowledgeButton from './AcknowledgeButton'
import CreateSummaryItemComment from './CreateSummaryItemComment'

interface MessageInteractionsProps {
  summaryItem: SummaryItem
}

function MessageInteractions({ summaryItem }: MessageInteractionsProps) {
  const { canAcknowledge, canComment } = summaryItem
  const [showCommentInput, setShowCommentInput] = React.useState(false)
  const toggleShowCommentInput = React.useCallback(
    () => setShowCommentInput(v => !v),
    [setShowCommentInput],
  )

  return (
    <>
      <CenteredLayout
        centerContent={
          canComment && !showCommentInput ? (
            <Box gapVertical="xsmall">
              <Link label="Comment" onPress={toggleShowCommentInput} size="h5" />
            </Box>
          ) : null
        }
        gapHorizontal="none"
        gapVertical="none"
        leftContent={canAcknowledge ? <AcknowledgeButton summaryItem={summaryItem} /> : null}
      />
      {showCommentInput ? (
        <Box gapTop="medium">
          <CreateSummaryItemComment onDone={toggleShowCommentInput} summaryItem={summaryItem} />
        </Box>
      ) : null}
    </>
  )
}

export default React.memo(MessageInteractions)
