import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { summaryAPI } from '@/api/requests'
import { collectionUtils as C, orderingUtils, summaryUtils } from '@/core/utils'
import { channelActions, summaryActions, summaryItemActions, userActions } from '@/store/actions'
import { buildSummaryItemId } from '@/store/entityIds'
import { summaryItemSelectors } from '@/store/selectors'
import { PagingRequest } from '@/types/api'
import { SummaryItem, SummaryStatus } from '@/types/entities'
import { Thunk } from '@/types/store'
import { addMessages, addTasks } from './store'
import { makeEnhancedRequest } from './utils'

export const acknowledgeSummaryItem =
  (
    spaceId: number,
    summaryItemId: number,
  ): RequestThunk<typeof summaryAPI.acknowledgeSummaryItem> =>
  async dispatch => {
    const request = summaryAPI.acknowledgeSummaryItem(spaceId, summaryItemId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryItemActions.upsertOne(response.data.summaryItem))
    }

    return response
  }

export const createSummaryItem =
  (
    spaceId: number,
    summaryId: number,
    body: summaryAPI.CreateSummaryItem,
  ): RequestThunk<typeof summaryAPI.createSummaryItem> =>
  async dispatch => {
    const request = summaryAPI.createSummaryItem(spaceId, summaryId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryItemActions.upsertOne(response.data.summaryItem))
    }

    return response
  }

export const createSummaryItemComment =
  (
    spaceId: number,
    summaryItemId: number,
    comment: string,
  ): RequestThunk<typeof summaryAPI.createSummaryItemComment> =>
  async dispatch => {
    const request = summaryAPI.createSummaryItemComment(spaceId, summaryItemId, comment)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { summaryItem, messages } = response.data
      batch(() => {
        dispatch(summaryItemActions.upsertOne(summaryItem))
        dispatch(addMessages(messages))
      })
    }

    return response
  }

export const createSummaryShare =
  (
    spaceId: number,
    summaryId: number,
    body: summaryAPI.CreateSummaryShare,
  ): RequestThunk<typeof summaryAPI.createSummaryShare> =>
  async dispatch => {
    const request = summaryAPI.createSummaryShare(spaceId, summaryId, body)
    const response = await dispatch(makeEnhancedRequest(request))
    return response
  }

export const createSummary =
  (
    spaceId: number,
    channelId: number,
    body: Partial<summaryAPI.CreateSummary> = {},
  ): RequestThunk<typeof summaryAPI.createSummary> =>
  async dispatch => {
    const request = summaryAPI.createSummary(spaceId, channelId, {
      ...summaryUtils.getSummaryDefaults(),
      ...body,
    })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryActions.upsertOne(response.data.summary))
    }

    return response
  }

export const deleteSummaryItem =
  (spaceId: number, summaryItemId: number): RequestThunk<typeof summaryAPI.deleteSummaryItem> =>
  async dispatch => {
    const request = summaryAPI.deleteSummaryItem(spaceId, summaryItemId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryItemActions.removeOne(buildSummaryItemId(spaceId, summaryItemId)))
    }

    return response
  }

export const hasSummaries =
  (spaceId: number, channelId: number): Thunk<boolean> =>
  async dispatch => {
    const request = summaryAPI.getSummariesList(spaceId, channelId, null, { perPage: 1 })
    const response = await dispatch(makeEnhancedRequest(request))

    return !response.ok || response.data.summaries.length !== 0
  }

export const getSummariesList =
  (
    spaceId: number,
    channelId: number,
    statuses: SummaryStatus[] | null,
    paging?: PagingRequest,
  ): RequestThunk<typeof summaryAPI.getSummariesList> =>
  async dispatch => {
    const request = summaryAPI.getSummariesList(spaceId, channelId, statuses, paging)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const page = paging?.page || 1

      if (page === 1) {
        dispatch(
          summaryActions.replaceWhere({
            entities: response.data.summaries,
            predicate: s => s.spaceId === spaceId && s.channelId === channelId,
          }),
        )
      } else {
        dispatch(summaryActions.upsertMany(response.data.summaries))
      }
    }

    return response
  }

export const getSummary =
  (spaceId: number, summaryId: number): RequestThunk<typeof summaryAPI.getSummary> =>
  async dispatch => {
    const request = summaryAPI.getSummary(spaceId, summaryId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { summary, summaryItems, messages, tasks, users, channel } = response.data

      batch(() => {
        dispatch(summaryActions.upsertOne(summary))
        dispatch(
          summaryItemActions.replaceWhere({
            entities: summaryItems,
            predicate: s => s.spaceId === spaceId && s.summaryId === summaryId,
          }),
        )
        dispatch(channelActions.upsertOne(channel))
        dispatch(addMessages(messages))
        dispatch(addTasks(tasks))
        dispatch(userActions.upsertMany(users))
      })
    }

    return response
  }

export const moveSummaryItems =
  (
    spaceId: number,
    summaryId: number,
    body: summaryAPI.MoveSummaryItems,
  ): RequestThunk<typeof summaryAPI.moveSummaryItems> =>
  async dispatch => {
    const request = summaryAPI.moveSummaryItems(spaceId, summaryId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryItemActions.upsertMany(response.data.summaryItems))
    }

    return response
  }

export const reviewSummary =
  (
    spaceId: number,
    summaryId: number,
    body: summaryAPI.ReviewSummary,
  ): RequestThunk<typeof summaryAPI.reviewSummary> =>
  async dispatch => {
    const request = summaryAPI.reviewSummary(spaceId, summaryId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryActions.upsertOne(response.data.summary))
    }

    return response
  }

export const submitSummary =
  (
    spaceId: number,
    summaryId: number,
    body: summaryAPI.SubmitSummary,
  ): RequestThunk<typeof summaryAPI.submitSummary> =>
  async dispatch => {
    const request = summaryAPI.submitSummary(spaceId, summaryId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryActions.upsertOne(response.data.summary))
    }

    return response
  }

export const updateSummaryItem =
  (
    spaceId: number,
    summaryItemId: number,
    body: summaryAPI.UpdateSummaryItem,
  ): RequestThunk<typeof summaryAPI.updateSummaryItem> =>
  async dispatch => {
    const request = summaryAPI.updateSummaryItem(spaceId, summaryItemId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryItemActions.upsertOne(response.data.summaryItem))
    }

    return response
  }

export const updateSummary =
  (
    spaceId: number,
    summaryId: number,
    body: summaryAPI.UpdateSummary,
  ): RequestThunk<typeof summaryAPI.updateSummary> =>
  async dispatch => {
    const request = summaryAPI.updateSummary(spaceId, summaryId, body)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(summaryActions.upsertOne(response.data.summary))
    }

    return response
  }

export const optimisticallyMoveSummaryItems =
  (
    spaceId: number,
    summaryId: number,
    moveItem: SummaryItem,
    beforeItemId: number | null,
  ): Thunk<boolean> =>
  async (dispatch, getState) => {
    const summaryItems =
      moveItem.itemType === 'HEADER'
        ? summaryItemSelectors.bySectionSelector(spaceId, summaryId, moveItem.id)(getState())
        : [moveItem]
    const summaryItemIds = summaryItems.map(item => item.id)

    // Optimistically update the ordering values so that things appear in the right order
    const boundaries = summaryItemSelectors.boundingOrderingValuesSelector(
      spaceId,
      summaryId,
      beforeItemId,
    )(getState())
    const temporaryOrders = orderingUtils.spreadEvenly(boundaries, summaryItemIds.length)
    const updates = C.zip(summaryItems, temporaryOrders).map(([item, orderingValue]) => ({
      ...item,
      orderingValue,
    }))
    dispatch(summaryItemActions.upsertMany(updates))

    const response = await dispatch(
      moveSummaryItems(spaceId, summaryId, { beforeItemId, summaryItemIds }),
    )

    // If the request fails, undo the optimistic update
    if (!response.ok) {
      dispatch(summaryItemActions.upsertMany(summaryItems))
    }

    return response.ok
  }
