import React from 'react'
import { VerticalSwipeEventArg } from '@/components/gestures/VerticalSwipeContainer'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { TaskFormSize } from '@/types/tasks'
import { nextSizeDown, nextSizeUp } from './utils'

export const useTaskFormSize = (initialSize: TaskFormSize, canChangeSize: boolean) => {
  const [taskFormSize, setTaskFormSize] = React.useState<TaskFormSize>(initialSize)

  if (!canChangeSize) {
    return { setTaskFormSize: undefined, taskFormSize: 'LARGE' as TaskFormSize }
  }
  return {
    setTaskFormSize,
    taskFormSize,
  }
}

const threshold = 30
const swipeOnceOnly = true

export const useTaskFormSizeSwipe = () => {
  const { setTaskFormSize } = useTaskFormContext()
  const lastChangeTranslateYRef = React.useRef(0)

  const handleSwipeBegan = React.useCallback(() => {
    lastChangeTranslateYRef.current = 0
  }, [])

  const handleSwipe = React.useCallback(
    (arg: VerticalSwipeEventArg) => {
      if (!setTaskFormSize) {
        return
      }

      const delta = arg.distance - lastChangeTranslateYRef.current

      if (swipeOnceOnly && lastChangeTranslateYRef.current !== 0) {
        return
      }

      if (arg.direction === 'up') {
        if (delta > threshold) {
          lastChangeTranslateYRef.current = arg.distance
          setTaskFormSize(val => nextSizeUp(val))
        } else if (delta < 0) {
          lastChangeTranslateYRef.current = arg.distance
          setTaskFormSize(val => nextSizeDown(val))
        }
      } else if (delta > threshold) {
        lastChangeTranslateYRef.current = arg.distance
        setTaskFormSize(val => nextSizeDown(val))
      } else if (delta < 0) {
        lastChangeTranslateYRef.current = arg.distance
        setTaskFormSize(val => nextSizeUp(val))
      }
    },
    [setTaskFormSize],
  )

  return {
    handleSwipe,
    handleSwipeBegan,
  }
}
