import { log } from '@/core/logging'
import { APIThunk } from '@/types/api'
import { Thunk } from '@/types/store'

type NextMarker<M> = { done: false; marker: M } | { done: true }

export const getAll =
  <T, M>(
    getPage: (marker: M) => APIThunk<T>,
    getNextMarker: (data: T) => NextMarker<M>,
    firstMarker: M,
    identifier: string,
    maxPages = 10,
  ): Thunk<boolean> =>
  async dispatch => {
    let page = 1
    let response = await dispatch(getPage(firstMarker))

    while (response.ok) {
      const nextMarker = getNextMarker(response.data)
      page += 1

      if (nextMarker.done) {
        return true
      }

      if (page > maxPages) {
        log('warning', `max pages for getAll exceeded (${identifier})`)
        return false
      }

      response = await dispatch(getPage(nextMarker.marker))
    }

    return response.ok
  }
