import React from 'react'
import { dateUtils as D, userUtils } from '@/core/utils'
import { useCurrentUserId } from '@/hooks'
import { useHubUserByIds } from '@/hooks/channels'
import { useReviewSummaryActions } from '@/hooks/summaries'
import { useTimedSwitch } from '@/hooks/timeout'
import { Summary } from '@/types/entities'

export const useReviewSummaryButtons = (summary: Summary) => {
  const { creatorId, status } = summary
  const notificationName = useNotificationName(summary)
  const [isCompleting, setIsCompleting] = React.useState(false)
  const isCompleted = status === 'COMPLETED' || isCompleting

  const myId = useCurrentUserId()
  const isCreator = myId === creatorId
  const notify = !isCreator

  const { isUpdating, markSummaryReviewed, markSummaryCompleted } = useReviewSummaryActions()

  const { isOn: feedbackVisible, turnOn: showFeedback } = useTimedSwitch({
    delay: D.toMilliseconds.seconds(5),
  })

  const handleReviewSummary = React.useCallback(async () => {
    const ok = await markSummaryReviewed(summary, true)

    if (ok) {
      showFeedback()
    }
  }, [markSummaryReviewed, showFeedback, summary])

  const handleCompleteSummary = React.useCallback(async () => {
    setIsCompleting(true)
    const ok = await markSummaryCompleted(summary, notify)
    setIsCompleting(false)

    if (ok && notify) {
      showFeedback()
    }
  }, [markSummaryCompleted, showFeedback, summary, notify])

  return {
    feedbackVisible,
    handleCompleteSummary,
    handleReviewSummary,
    isCompleted,
    isUpdating,
    notificationName,
  }
}

const useNotificationName = (summary: Summary) => {
  const { spaceId, channelId } = summary
  const user = useHubUserByIds(spaceId, channelId)

  return user ? userUtils.getFirstName(user) : 'them'
}
