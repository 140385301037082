import React from 'react'
import { StyleSheet, View } from 'react-native'
import { tabRadius } from './constants'

interface TabbedContainerOverlayProps {
  radiusSide: 'left' | 'right'
}

function TabbedContainerOverlay({ radiusSide }: TabbedContainerOverlayProps) {
  return (
    <View
      style={[styles.overlay, radiusSide === 'left' ? styles.radiusLeft : styles.radiusRight]}
    />
  )
}

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: '#0004',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  radiusLeft: {
    borderBottomLeftRadius: tabRadius,
    borderTopLeftRadius: tabRadius,
  },
  radiusRight: {
    borderBottomRightRadius: tabRadius,
    borderTopRightRadius: tabRadius,
  },
})

export default React.memo(TabbedContainerOverlay)
