import React from 'react'
import Box from '@/components/layouts/Box'
import { useAppSelector, useCachedSelector, useCurrentUserId } from '@/hooks'
import { useThread } from '@/hooks/threads'
import { channelUserSelectors, entitySelectors } from '@/store/selectors'
import MessageTextInput from './MessageTextInput'
import MessagesList from './MessagesList'

interface MessageThreadProps {
  spaceId: number
  messageId: number
  autoFocus?: boolean
  showThreadStart?: boolean
  onInputFocus?: () => void
  onInputBlur?: () => void
}

function MessageThread({
  spaceId,
  messageId,
  autoFocus = false,
  showThreadStart = true,
  onInputFocus,
  onInputBlur,
}: MessageThreadProps) {
  const userId = useCurrentUserId()
  const { thread, message } = useThread(spaceId, messageId)
  const channelId = thread?.channelId || message?.channelId || 0
  const channel = useCachedSelector(entitySelectors.resolvedChannelSelector, [spaceId, channelId])
  const channelUser = useAppSelector(channelUserSelectors.byId, spaceId, channelId, userId || 0)

  if (!(channel && channelUser)) {
    return null
  }

  return (
    <Box direction="column" flex={1}>
      <MessagesList
        channel={channel}
        channelUser={channelUser}
        showThreadStart={showThreadStart}
        threadId={messageId}
      />
      <MessageTextInput
        autoFocus={autoFocus}
        channel={channel}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        threadId={messageId}
      />
    </Box>
  )
}

export default React.memo(MessageThread)
