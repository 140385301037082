import { taskAPI } from '@/api/requests'
import { formActions } from '@/store/actions'
import { formSelectors, taskSelectors } from '@/store/selectors'
import { Task } from '@/types/entities'
import { Optional } from '@/types/generics'
import { GetState, Thunk } from '@/types/store'
import { TaskNotify } from '@/types/tasks'
import { setErrors } from '../errors'
import * as fileThunks from '../files'
import { QuestionFields, createQuestion, createTask, updateQuestion, updateTask } from './api'
import { getTaskChannelId, getTaskListIds } from './utils'

export const createTaskForm =
  (
    spaceId: number,
    messageId: Optional<number>,
    formName: string,
    { notify, clearOnSuccess }: { notify: TaskNotify; clearOnSuccess: boolean },
  ): ReturnType<typeof createTask> =>
  async (dispatch, getState) => {
    const { attachmentIds, comment, isMyDay, ...taskProps } = formSelectors.values(
      getState(),
      formName,
    )
    const channelId = await dispatch(
      getTaskChannelId(spaceId, taskProps.channelId, taskProps.assignedUserId),
    )

    const fileIds = await dispatch(fileThunks.getFileIds(attachmentIds))
    const taskListIds = dispatch(getTaskListIds(!!isMyDay))
    const task = { ...taskProps, comments: comment ? [comment] : [], fileIds, taskListIds }

    const response = await dispatch(
      createTask(spaceId, channelId, messageId, task as taskAPI.CreateTask, notify),
    )

    if (response.ok) {
      if (clearOnSuccess) {
        dispatch(formActions.clear(formName))
      }
    } else {
      dispatch(setErrors(formName, response.errors, 'Error Creating Task'))
    }
    return response
  }

export const updateTaskForm =
  (
    spaceId: number,
    taskId: number,
    formName: string,
    notify: TaskNotify,
  ): ReturnType<typeof updateTask> =>
  async (dispatch, getState) => {
    const { attachmentIds, isMyDay, ...taskProps } = formSelectors.values(getState(), formName)
    const existing = taskSelectors.byId(getState(), spaceId, taskId)

    const channelId = await dispatch(
      getTaskChannelId(spaceId, taskProps.channelId, taskProps.assignedUserId),
    )
    taskProps.channelId = channelId

    const taskListIds = await dispatch(getTaskListIds(!!isMyDay))
    taskProps.taskListIds = taskListIds

    const newFileIds = await dispatch(fileThunks.getFileIds(attachmentIds || []))
    const existingFileIds = existing?.files?.map(file => file.id) || []
    const fileIds = [...newFileIds, ...existingFileIds]
    const task = { ...taskProps, fileIds }

    const response = await dispatch(updateTask(spaceId, taskId, task as taskAPI.UpdateTask, notify))

    if (response.ok) {
      dispatch(formActions.clear(formName))
    } else {
      dispatch(setErrors(formName, response.errors, 'Error Updating Task'))
    }

    return response
  }

export const createQuestionForm =
  (
    spaceId: number,
    formName: string,
    { clearOnSuccess, notify }: { clearOnSuccess: boolean; notify: TaskNotify },
  ): ReturnType<typeof createQuestion> =>
  async (dispatch, getState) => {
    const fields = buildQuestionFromForm(getState, formName)
    const response = await dispatch(createQuestion(spaceId, fields, notify))

    if (response.ok) {
      if (clearOnSuccess) {
        dispatch(formActions.clear(formName))
      }
    } else {
      dispatch(setErrors(formName, response.errors, 'Error Creating Question'))
    }
    return response
  }

export const updateQuestionForm =
  (
    spaceId: number,
    taskId: number,
    formName: string,
    notify: TaskNotify,
  ): ReturnType<typeof updateQuestion> =>
  async (dispatch, getState) => {
    const fields = buildQuestionFromForm(getState, formName)
    const response = await dispatch(updateQuestion(spaceId, taskId, fields, notify))

    if (response.ok) {
      dispatch(formActions.clear(formName))
    } else {
      dispatch(setErrors(formName, response.errors, 'Error Updating Question'))
    }
    return response
  }

const mergeKeys: Array<keyof Task> = [
  'acceptedAt',
  'assignedUserId',
  'boardId',
  'channelId',
  'completedAt',
  'content',
  'dueDate',
  'dueDateType',
  'notes',
  'remindAt',
  'remindAtOffset',
  'urgency',
  'checklistItems',
]

export const mergeUpdatedTaskToForm =
  (task: Task, formName: string): Thunk<void> =>
  async (dispatch, getState) => {
    const formUpdates: Record<string, any> = {}
    const form = formSelectors.formSelector(formName)(getState())

    for (const taskKey of mergeKeys) {
      const field = form[taskKey]

      if (!field?.changed) {
        formUpdates[taskKey] = task[taskKey]
      }
    }

    dispatch(formActions.setValues({ fields: formUpdates, formName, markChanged: false }))
  }

const buildQuestionFromForm = (getState: GetState, formName: string): QuestionFields => {
  const {
    content,
    channelId,
    assignedUserId,
    questionResponseType: responseType,
    questionResponses: responses,
    questionIsOpenEnded: isOpenEnded,
    comment,
  } = formSelectors.values(getState(), formName)

  return {
    assignedUserId,
    channelId,
    comment,
    content,
    isOpenEnded,
    responseType,
    responses: responses
      .map((response: string | null) => (response ? response.trim() : null))
      .filter((response: string | null) => !!response),
  }
}
