import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import { AccountManagementProps } from './types'

const LazyAccountManagement = React.lazy(() => import('./index'))

function AccountManagement(props: AccountManagementProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyAccountManagement {...props} />
    </Suspense>
  )
}

export default AccountManagement
