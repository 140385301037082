import React from 'react'
import MessageActionsContext from '@/contexts/MessageActionsContext'
import { useMessageActionsContextValue } from './hooks'

interface MessageActionsContainerProps {
  children: React.ReactNode
  isDisplayedInThread: boolean
}

function MessageActionsContainer({ isDisplayedInThread, children }: MessageActionsContainerProps) {
  const messageActionsContextValue = useMessageActionsContextValue(isDisplayedInThread)

  return (
    <MessageActionsContext.Provider value={messageActionsContextValue}>
      {children}
    </MessageActionsContext.Provider>
  )
}
export default React.memo(MessageActionsContainer)
