import { fileAPI } from '@/api/requests'
import { FileInfo } from '@/api/requests/files'
import { config } from '@/core'
import { FILE_UPLOAD_PROGRESS } from '@/core/constants/eventTypes'
import { emitter } from '@/core/pubsub'
import { asyncUtils, fileUtils } from '@/core/utils'
import { attachmentSelectors } from '@/store/selectors'
import { Attachment, ProgressEvent } from '@/types/api'
import { File } from '@/types/entities'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

const emitUploadProgress = (attachment: Attachment) => (event: ProgressEvent) => {
  emitter.emit(FILE_UPLOAD_PROGRESS, { attachment, event })
}

const canUpload = (attachment: Attachment) =>
  fileUtils.isLocalFile(attachment.uri) || fileUtils.isDataURI(attachment.uri)

export const uploadLocalFile =
  (attachment: Attachment): Thunk<File | null> =>
  async dispatch => {
    if (!canUpload(attachment)) {
      return null
    }

    return dispatch(uploadFile(attachment))
  }

export const uploadLocalSpaceFile =
  (spaceId: number, attachment: Attachment): Thunk<File | null> =>
  async dispatch => {
    if (!canUpload(attachment)) {
      return null
    }
    return dispatch(uploadSpaceFile(spaceId, attachment))
  }

export const uploadFile =
  (attachment: Attachment): Thunk<File | null> =>
  async dispatch => {
    const fileInfo = attachmentToFileInfo(attachment)
    const request = fileAPI.uploadFile(fileInfo, emitUploadProgress(attachment))
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return response.data.file
    }
    return null
  }

export const uploadSpaceFile =
  (spaceId: number, attachment: Attachment): Thunk<File | null> =>
  async dispatch => {
    const fileInfo = attachmentToFileInfo(attachment)
    const request = fileAPI.uploadSpaceFile(spaceId, fileInfo, emitUploadProgress(attachment))

    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      return response.data.file
    }
    return null
  }

export const getFileIds =
  (attachmentIds: string[]): Thunk<number[]> =>
  async (dispatch, getState) => {
    if (attachmentIds.length === 0) {
      return []
    }

    /* eslint-disable no-constant-condition */
    while (true) {
      /* eslint-enable */
      const attachments = attachmentSelectors.byIdsSelector(attachmentIds)(getState())
      const allDone = attachments.every((attachment: Attachment) => attachment.status === 'SUCCESS')

      if (allDone) {
        return attachments
          .map(attachment => attachment.fileId)
          .filter(fileId => !!fileId) as number[]
      }
      await asyncUtils.sleep(500)
    }
  }

const attachmentToFileInfo = (attachment: Attachment): FileInfo => ({
  contentType: fileUtils.getContentType(attachment.uri),
  filename: attachment.filename,
  uri: fileUtils.mapURIForUploading(attachment.uri),
})

export const clearCachedFiles = () =>
  fileUtils.cleanupCache({ long: config.cacheFilesDuration, short: config.cacheTempFilesDuration })
