import React from 'react'
import Box from '@/components/layouts/Box'
import { AlignItems } from '@/types/components'
import TaskFormSubmitButton from './TaskFormSubmitButton'

interface TaskFormSubmitLineProps {
  children: React.ReactNode
  gapTop?: boolean
  gapHorizontal?: boolean
  alignSubmit?: AlignItems
}

function TaskFormSubmitLine({
  gapTop = true,
  gapHorizontal = true,
  children,
  alignSubmit,
}: TaskFormSubmitLineProps) {
  return (
    <Box
      alignItems="center"
      direction="row"
      gapHorizontal={gapHorizontal ? 'large' : undefined}
      gapTop={gapTop ? 'medium' : undefined}
    >
      <Box alignItems="center" direction="row" flex={1}>
        {children}
      </Box>
      <Box alignSelf={alignSubmit} gapLeft="medium">
        <TaskFormSubmitButton />
      </Box>
    </Box>
  )
}

export default React.memo(TaskFormSubmitLine)
