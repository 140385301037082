import { createSelector } from 'reselect'
import { isTaskIgnoredForList, isTaskInList } from '@/core/utils/taskLists'
import { Task, TaskListType } from '@/types/entities'
import { selectors as authSelectors } from '../../auth'
import { selectors as taskListItemSelectors } from '../taskListItems'
import { selectors as taskListSelectors } from '../taskLists'

type IsMyDayArgs = { type: 'task'; task: Task } | { type: 'defaults'; taskListId?: number }

export const currentUserTaskListSelector = (listType: TaskListType) =>
  createSelector(
    authSelectors.myId,
    taskListSelectors.all,
    (myId, taskLists) =>
      taskLists.filter(taskList => taskList.userId === myId && taskList.listType === listType)[0],
  )

export const isInMyDaySelector = (args: IsMyDayArgs) =>
  createSelector(
    currentUserTaskListSelector('MY_DAY'),
    taskListItemSelectors.taskOrderIndex,
    (myDayList, taskOrderIndex) => {
      if (!myDayList) {
        return false
      }
      if (args.type === 'defaults') {
        return args.taskListId === myDayList.id
      }
      return isTaskInList(taskOrderIndex, myDayList.id, args.task)
    },
  )

export const isIgnoredInMyDay = (task: Task | undefined) =>
  createSelector(
    currentUserTaskListSelector('MY_DAY'),
    taskListItemSelectors.taskOrderIndex,
    (myDayList, taskOrderIndex) => {
      if (!myDayList) {
        return false
      }
      if (!task) {
        return false
      }
      return isTaskIgnoredForList(taskOrderIndex, myDayList.id, task)
    },
  )
