import { Measure } from '@/types/components'
import { VisibleKeyBounds } from './types'

type ViewMode = 'TOP_IN_VIEW' | 'ANY_PART_IN_VIEW'

const mode: ViewMode = 'TOP_IN_VIEW'

export const isVerticallyInView = (viewLbound: number, viewUbound: number, measure: Measure) => {
  const lower = measure.y

  if (mode === 'TOP_IN_VIEW') {
    // lower is smaller number i.e. higher up on the screen toward 0,0 which is top left
    return lower >= viewLbound && lower <= viewUbound
  }

  if (mode === 'ANY_PART_IN_VIEW') {
    const upper = lower + measure.height
    return lower <= viewUbound && upper >= viewLbound
  }

  throw new Error('Invalid View Mode')
}

export const keyBoundsChanged = (
  oldBounds: VisibleKeyBounds | null,
  newBounds: VisibleKeyBounds | null,
) => {
  if (oldBounds === null) {
    if (newBounds === null) {
      return false
    }
    return true
  }
  if (newBounds === null) {
    return true
  }
  return oldBounds[0] !== newBounds[0] || oldBounds[1] !== newBounds[1]
}
