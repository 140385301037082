import React from 'react'
import Background from '@/components/core/Background'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'

interface OpenEndedResponseDisplayProps {
  children?: string | null
}

function OpenEndedResponseDisplay({ children }: OpenEndedResponseDisplayProps) {
  if (!children) {
    return null
  }
  return (
    <Background borderRadius={5} color="light-purple">
      <Box gapHorizontal={6} gapVertical={4}>
        <TextWithLinks size="h5">{children}</TextWithLinks>
      </Box>
    </Background>
  )
}

export default React.memo(OpenEndedResponseDisplay)
