import React from 'react'
import { useModalPresenterContext } from '@/contexts/ModalPresenterContext'
import { clipboardUtils, fnUtils, messageUtils } from '@/core/utils'
import { messageThunks, taskThunks } from '@/thunks'
import { ActionOption } from '@/types/actions'
import { Message } from '@/types/entities'
import { useDestructiveAlert } from '../actions'
import { useAmplitude } from '../amplitude'
import { useCurrentUserId } from '../auth'
import { useAppDispatch } from '../redux'
import { useShowMessageThread } from './thread'

type MessageActionsArgs = {
  isDisplayedInThread: boolean
  onAction?: () => void
  showCancel?: boolean
}

export const useMessageActions = ({
  isDisplayedInThread,
  onAction = fnUtils.noOp,
  showCancel = true,
}: MessageActionsArgs) => {
  const showReply = !isDisplayedInThread
  const dispatch = useAppDispatch()
  const myId = useCurrentUserId() || 0
  const modalPresenterContext = useModalPresenterContext()
  const showThread = useShowMessageThread()

  const handleShowThread = React.useCallback(
    (message: Message) => {
      onAction()
      showThread(message.spaceId, message.id)
    },
    [showThread, onAction],
  )

  const handleCopyText = React.useCallback(
    (message: Message) => {
      onAction()
      clipboardUtils.writeToClipboard(message.content || '')
    },
    [onAction],
  )

  const handleEditMessage = React.useCallback(
    (message: Message) => {
      onAction()
      modalPresenterContext.push('editMessage', { messageIds: message })
    },
    [modalPresenterContext, onAction],
  )

  const baseHandleConvertToTask = React.useCallback(
    (message: Message) => {
      onAction()
      if (message.content) {
        dispatch(
          taskThunks.createTask(
            message.spaceId,
            message.channelId,
            message.id,
            {
              assignedUserId: myId as number,
              content: message.content,
              dueDate: null,
              dueDateType: 'NONE',
              notes: null,
              urgency: 'MEDIUM',
            },
            'NOTIFY',
          ),
        )
      }
    },
    [dispatch, myId, onAction],
  )

  const handleConvertToTask = useAmplitude(baseHandleConvertToTask, 'convert message to task')

  const removeMessageReaction = React.useCallback(
    (message: Message) => {
      const { spaceId, id: messageId } = message
      onAction()
      dispatch(messageThunks.removeMessageReaction(spaceId, messageId))
    },
    [dispatch, onAction],
  )

  const handleDeleteMessage = useDestructiveAlert<Message>(
    'Delete Message',
    'Delete',
    React.useCallback(
      (message: Message) => {
        onAction()
        dispatch(messageThunks.deleteMessage(message.spaceId, message.id))
      },
      [dispatch, onAction],
    ),
    'Are you sure you want to delete this message?',
  )

  const messageActions = React.useMemo<ActionOption<Message>[]>(() => {
    const actions: ActionOption<Message>[] = [
      {
        handler: handleShowThread,
        icon: 'reply',
        isAvailable: () => showReply,
        label: 'Reply',
      },
      {
        handler: handleCopyText,
        icon: 'clone',
        isAvailable: (message: Message) => !!message.content,
        label: 'Copy Text',
      },
      {
        handler: handleEditMessage,
        icon: 'pen-to-square',
        isAvailable: (message: Message) => messageUtils.canEditMessage(message, myId),
        label: 'Edit Message',
      },
      {
        handler: handleConvertToTask,
        icon: 'shuffle',
        label: 'Convert to Task',
      },
      {
        handler: removeMessageReaction,
        icon: 'face-smile',
        isAvailable: (message: Message) => messageUtils.hasReactionFrom(message, myId),
        label: 'Remove Reaction',
      },
      {
        handler: handleDeleteMessage,
        icon: 'trash-alt',
        isAvailable: (message: Message) => messageUtils.canDeleteMessage(message, myId),
        label: 'Delete Message',
        type: 'destructive',
      },
    ]

    if (showCancel) {
      return [
        ...actions,
        {
          handler: fnUtils.noOp,
          label: 'Cancel',
          type: 'cancel',
        },
      ]
    }
    return actions
  }, [
    handleConvertToTask,
    handleCopyText,
    handleDeleteMessage,
    handleEditMessage,
    removeMessageReaction,
    handleShowThread,
    myId,
    showCancel,
    showReply,
  ])

  return {
    handleConvertToTask,
    handleCopyText,
    handleDeleteMessage,
    handleEditMessage,
    handleRemoveReaction: removeMessageReaction,
    handleShowThread,
    messageActions,
  }
}
