import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'

const LazyNoSpacesScreen = React.lazy(() => import('./index'))

function NoSpacesScreen() {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyNoSpacesScreen />
    </Suspense>
  )
}

export default NoSpacesScreen
