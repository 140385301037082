import React from 'react'
import TaskPriorityCycleButton from '@/components/tasks/TaskPriorityCycleButton'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { Task, TaskUrgency } from '@/types/entities'

interface TaskListItemPriorityFocusProps {
  task: Task
}

function TaskListItemPriorityFocus({ task }: TaskListItemPriorityFocusProps) {
  const { changeTask } = useTasksListViewContext()
  const { spaceId, id: taskId } = task
  const handleChange = React.useCallback(
    (urgency: TaskUrgency) => {
      changeTask(spaceId, taskId, { urgency })
    },
    [spaceId, taskId, changeTask],
  )
  return <TaskPriorityCycleButton onChange={handleChange} urgency={task.urgency} />
}

export default React.memo(TaskListItemPriorityFocus)
