import React from 'react'
import { eventTypes } from '@/core/constants'
import { emitter } from '@/core/pubsub'
import { easeInOut } from '@/core/utils/animations'
import { useAppDispatch } from '@/hooks'
import { messageThunks, notificationThunks, slashCommandThunks } from '@/thunks'
import { Channel } from '@/types/entities'
import { Action } from '@/types/generics'
import { InputMode } from '@/types/messaging'
import { revertToMessageOnSubmit } from './constants'

type SubmitHandlerArgs = {
  channel: Channel
  formName: string
  threadId: number | null | undefined
  inputMode: InputMode
  setInputMode: Action<InputMode>
  handleSubmitTask: () => Promise<boolean>
  resetForm: () => void
}
export const useSubmitHandler = ({
  channel,
  formName,
  threadId,
  inputMode,
  setInputMode,
  resetForm,
  handleSubmitTask,
}: SubmitHandlerArgs) => {
  const dispatch = useAppDispatch()
  const handleSubmitMessage = React.useCallback(async () => {
    const handled = await dispatch(
      slashCommandThunks.processSlashCommandForm(channel, formName, 'content'),
    )

    if (!handled) {
      // No await. We do an optimistic create
      dispatch(
        messageThunks.createMessageForm(
          channel.spaceId,
          channel.id,
          threadId || null,
          formName,
          false,
        ),
      )
      resetForm()
    }
  }, [channel, dispatch, formName, threadId, resetForm])

  const handleSubmit = React.useCallback(async () => {
    easeInOut()

    if (inputMode === 'message') {
      // No await. We do an optimistic create
      handleSubmitMessage()
    } else {
      const ok = await handleSubmitTask()

      if (!ok) {
        return false
      }

      resetForm()

      if (revertToMessageOnSubmit) {
        setInputMode('message')
      }

      if (threadId) {
        dispatch(
          notificationThunks.showToast({
            body: 'Tasks can not be thread replies. The task you just created has been sent to the main conversation',
            title: 'Task Created',
            type: 'info',
          }),
        )
      }
    }

    emitter.emit(eventTypes.MESSAGE_SENT)
    return true
  }, [
    // channel,
    inputMode,
    setInputMode,
    handleSubmitMessage,
    handleSubmitTask,
    resetForm,
    dispatch,
    threadId,
    // formName,
  ])

  return handleSubmit
}
