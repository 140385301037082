import React from 'react'
import { ViewStyle } from 'react-native'
import {
  ComponentProp,
  ExpandablePadding,
  ExpandedPadding,
  Gap,
  GapSize,
  GapType,
  HitSlop,
  HorizontalPin,
  Insets,
  LayoutPosition,
  Measure,
  Measurement,
  MeasurementFit,
  VerticalPin,
} from '@/types/components'

export const idKey = (item: { id: number }) => item.id.toString()

const insets = (n: number) => ({ bottom: n, left: n, right: n, top: n })

// Animations (like LayoutAnimation.easeInEaseOut) are causing issues with modals in RN 0.68.2:
// https://github.com/facebook/react-native/issues/33733
//
// Adding setTimeout seems to help
export const callWithModalBugFix = (fn: () => void) => setTimeout(() => fn())
// InteractionManager.runAfterInteractions(() => setTimeout(() => fn()))

export const mapHitSlop = (value: HitSlop | undefined): Insets | undefined => {
  if (value === undefined) {
    return undefined
  }
  if (value === 'none') {
    return undefined
  }
  if (value === 'xsmall') {
    return insets(5)
  }
  if (value === 'small') {
    return insets(10)
  }
  if (value === 'medium') {
    return insets(20)
  }
  if (value === 'large') {
    return insets(40)
  }
  if (typeof value === 'number') {
    if (Number.isNaN(value)) {
      return undefined
    }
    return insets(value as number)
  }
  return value as Insets
}

export const measureToLayoutPosition = (
  verticalPin: VerticalPin,
  horizontalPin: HorizontalPin,
  window: { width: number; height: number },
  measure: Measure,
  padding = 5,
): LayoutPosition => ({
  horizontal: {
    offset: horizontalPin === 'RIGHT' ? window.width - (measure.x + measure.width) : measure.x,
    pin: horizontalPin,
  },
  vertical: {
    offset:
      verticalPin === 'TOP'
        ? measure.y + measure.height + padding
        : window.height - measure.y - padding,
    pin: verticalPin,
  },
})

export const renderProp = (element: React.ReactNode | (() => React.ReactNode)): React.ReactNode =>
  typeof element === 'function' ? element() : element

export const renderComponent = (Component: ComponentProp): React.ReactElement => {
  if (React.isValidElement(Component)) {
    return Component
  }
  return <Component />
}

const scaleDown = (scale: number, size: Measurement): Measurement =>
  scale >= 1
    ? size
    : {
        height: size.height * scale,
        width: size.width * scale,
      }

const restrictSize = (maxSize: Measurement, actualSize: Measurement) => ({
  height: Math.min(maxSize.height, actualSize.height),
  width: Math.min(maxSize.width, actualSize.width),
})

export const fitSize = (
  fit: MeasurementFit,
  maxSize: Measurement,
  actualSize: Measurement,
): Measurement => {
  const { width, height } = actualSize
  const { width: maxWidth, height: maxHeight } = maxSize

  const widthScale = maxWidth / width
  const heightScale = maxHeight / height

  if (fit === 'WIDTH') {
    return restrictSize(maxSize, scaleDown(widthScale, actualSize))
  }

  if (fit === 'HEIGHT') {
    return restrictSize(maxSize, scaleDown(heightScale, actualSize))
  }

  if (fit === 'LARGEST') {
    return scaleDown(Math.min(widthScale, heightScale), actualSize)
  }

  if (fit === 'SMALLEST') {
    return restrictSize(maxSize, scaleDown(Math.max(widthScale, heightScale), actualSize))
  }

  throw new Error(`Unhandled fit value: ${fit}`)
}

export const stopPropagation = (e: React.SyntheticEvent<any>) => e.stopPropagation()

export const expandPadding = (padding: ExpandablePadding): ExpandedPadding => {
  if (Array.isArray(padding)) {
    if (padding.length === 2) {
      const [vertical, horizontal] = padding
      return {
        paddingBottom: vertical,
        paddingLeft: horizontal,
        paddingRight: horizontal,
        paddingTop: vertical,
      }
    }
    if (padding.length === 4) {
      const [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding
      return { paddingBottom, paddingLeft, paddingRight, paddingTop }
    }
    // @ts-ignore
    throw new Error(`Padding array must have length 2 or 4. Has ${padding.length}`)
  }
  return {
    paddingBottom: padding,
    paddingLeft: padding,
    paddingRight: padding,
    paddingTop: padding,
  }
}

type GetGapOpts = {
  gapAll?: Gap
  gapVertical?: Gap
  gapHorizontal?: Gap
  gapBottom?: Gap
  gapTop?: Gap
  gapLeft?: Gap
  gapRight?: Gap
}

export const getGaps = (
  gapType: GapType,
  { gapAll, gapVertical, gapHorizontal, gapBottom, gapTop, gapLeft, gapRight }: GetGapOpts,
) => {
  const top = getGap(gapAll, gapVertical, gapTop)
  const bottom = getGap(gapAll, gapVertical, gapBottom)
  const right = getGap(gapAll, gapHorizontal, gapRight)
  const left = getGap(gapAll, gapHorizontal, gapLeft)

  const style: ViewStyle = {}

  if (top < 0 || gapType === 'margin') {
    style.marginTop = top
  } else {
    style.paddingTop = top
  }

  if (bottom < 0 || gapType === 'margin') {
    style.marginBottom = bottom
  } else {
    style.paddingBottom = bottom
  }

  if (right < 0 || gapType === 'margin') {
    style.marginRight = right
  } else {
    style.paddingRight = right
  }

  if (left < 0 || gapType === 'margin') {
    style.marginLeft = left
  } else {
    style.paddingLeft = left
  }

  return style
}

export const getGap = (all?: Gap, direction?: Gap, side?: Gap): number => {
  if (side !== undefined) {
    return gapToLength(side)
  }
  if (direction !== undefined) {
    return gapToLength(direction)
  }
  return gapToLength(all)
}

const gapSizes: Record<GapSize, number> = {
  '-large': -16,
  '-medium': -10,
  '-small': -8,
  '-xlarge': -24,
  '-xsmall': -4,
  'large': 16,
  'medium': 10,
  'none': 0,
  'small': 8,
  'xlarge': 24,
  'xsmall': 4,
}

const gapToLength = (pad?: Gap): number => {
  if (pad === undefined) {
    return 0
  }
  if (typeof pad === 'number') {
    return pad
  }
  const size = gapSizes[pad]
  return size
}
