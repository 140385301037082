import React from 'react'
import { TaskFormBody } from '@/types/tasks'

export const useTaskFormChat = (initialTaskFormBody: TaskFormBody) => {
  const [showChat, setShowChat] = React.useState(initialTaskFormBody === 'COMMENTS')
  const handleToggleChat = React.useCallback(() => setShowChat(val => !val), [setShowChat])
  const handleFocus = React.useCallback(() => setShowChat(true), [setShowChat])

  return {
    handleFocus,
    handleToggleChat,
    showChat,
  }
}
