import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Button from '@/components/core/Button'
import If from '@/components/core/If'
import Text from '@/components/core/Text'
import PaymentIntent from '@/components/financials/PaymentIntent'
import Box from '@/components/layouts/Box'
import { useChargebeePayment, usePaymentIntent } from '@/hooks/chargebee'

interface EnterPaymentProps {
  isPending: boolean
  onSelect: (paymentIntent: string) => void
  spaceId: number
}

function EnterPayment({ isPending, spaceId, onSelect }: EnterPaymentProps) {
  const { paymentIntent, setPaymentIntent, status: fetchStatus } = usePaymentIntent(spaceId)
  const { cardRef, status: submitStatus, errorMessage, submitPayment } = useChargebeePayment()
  const [ccComplete, setCCComplete] = React.useState(false)

  const handleSelect = React.useCallback(async () => {
    if (!paymentIntent) {
      return
    }

    const newPaymentIntent = await submitPayment(paymentIntent)

    if (!newPaymentIntent) {
      return
    }

    setPaymentIntent(newPaymentIntent)

    onSelect(newPaymentIntent.id)
  }, [paymentIntent, onSelect, setPaymentIntent, submitPayment])

  return (
    <>
      <PaymentIntent
        cardRef={cardRef}
        errorMessage={errorMessage}
        label="Card"
        onCompleteChange={setCCComplete}
        paymentIntent={paymentIntent}
        status={fetchStatus}
      />
      {isPending || submitStatus === 'PENDING' ? (
        <Box direction="row" gapVertical="medium" justifyContent="center">
          <ActivityIndicator color="obsidian" size="small" />
          <Box gapLeft="medium">
            <Text>Securely communicating with payment processor...</Text>
          </Box>
        </Box>
      ) : null}
      <If check={!!paymentIntent}>
        <Box alignSelf="center" gapTop="medium">
          <Button
            disabled={submitStatus === 'PENDING' || isPending || !ccComplete}
            kind="warning"
            label="Confirm Purchase"
            onPress={handleSelect}
          />
        </Box>
      </If>
    </>
  )
}

export default React.memo(EnterPayment)
