import React from 'react'
import Switch from '@/components/core/Switch'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface CanCommentToggleButtonProps {
  canComment: boolean
  disabled: boolean
  onToggle?: () => void
}

function CanCommentToggleButton({ canComment, disabled, onToggle }: CanCommentToggleButtonProps) {
  return (
    <Box alignItems="center" direction="row">
      <Switch
        disabled={disabled}
        onValueChange={onToggle}
        thumbColor="ghost"
        trackColorOff="iron"
        trackColorOn="emmre-blue"
        value={canComment}
      />
      <Box gapLeft="medium">
        <Text color={canComment ? 'emmre-blue' : 'iron'}>Comments</Text>
      </Box>
    </Box>
  )
}

export default React.memo(CanCommentToggleButton)
