import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { threadAPI } from '@/api/requests'
import { threadActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

export const getThreadsList =
  (spaceId: number, page = 1): RequestThunk<typeof threadAPI.getThreadsList> =>
  async dispatch => {
    const request = threadAPI.getThreadsList(spaceId, { page })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      batch(() => {
        if (page === 1) {
          dispatch(
            threadActions.replaceWhere({
              entities: response.data.threads,
              predicate: thread => thread.spaceId === spaceId,
            }),
          )
        } else {
          dispatch(threadActions.upsertMany(response.data.threads))
        }
      })
    }

    return response
  }

export const updateThreadForm =
  (spaceId: number, messageId: number, formName: string): Thunk<void> =>
  async (dispatch, getState) => {
    const form = formSelectors.formSelector(formName)(getState())

    if (form?.title?.changed) {
      const {
        title: { value: title },
      } = form
      await dispatch(updateThread(spaceId, messageId, { title }))
    }
    if (form?.follow?.changed) {
      const {
        follow: { value: follow },
      } = form
      if (follow === 'silence') {
        await dispatch(ignoreThread(spaceId, messageId))
      } else {
        await dispatch(followThread(spaceId, messageId))
      }
    }
  }

export const updateThread =
  (
    spaceId: number,
    messageId: number,
    updates: threadAPI.UpdateThread,
  ): RequestThunk<typeof threadAPI.updateThread> =>
  async dispatch => {
    const request = threadAPI.updateThread(spaceId, messageId, updates)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(threadActions.upsertOne(response.data.thread))
    }

    return response
  }

export const followThread =
  (spaceId: number, messageId: number): RequestThunk<typeof threadAPI.followThread> =>
  async dispatch => {
    const request = threadAPI.followThread(spaceId, messageId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(threadActions.upsertOne(response.data.thread))
    }

    return response
  }

export const ignoreThread =
  (spaceId: number, messageId: number): RequestThunk<typeof threadAPI.ignoreThread> =>
  async dispatch => {
    const request = threadAPI.ignoreThread(spaceId, messageId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(threadActions.upsertOne(response.data.thread))
    }

    return response
  }
