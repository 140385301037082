import React from 'react'
import { LayoutChangeEvent, ScrollView, StyleSheet, View } from 'react-native'
import { componentUtils } from '@/core/utils'
import { useIsAppForegrounded } from '@/hooks/appState'
import { ComponentProp, Measure } from '@/types/components'
import LayoutWrapper from './LayoutWrapper'
import { ItemInfo, VisibleKeyBounds } from './types'
import { isVerticallyInView, keyBoundsChanged } from './utils'

interface IntrospectedScrollListProps<T> {
  HeaderComponent?: ComponentProp
  data: T[]
  inverted: true
  keyExtractor: (t: T) => string
  onVisibleItemsChanged: (items: T[]) => void
  renderItem: (t: T, index: number) => React.ReactNode
  scrollViewRef?: React.Ref<ScrollView>
}

function IntrospectedScrollList<T>({
  HeaderComponent,
  data,
  inverted,
  keyExtractor,
  onVisibleItemsChanged,
  renderItem,
  // I'm having some issues with using forwardRef with a generic component, so I'm using a custom
  // ref prop for now.
  // https://reactjs.org/docs/refs-and-the-dom.html#exposing-dom-refs-to-parent-components
  scrollViewRef,
}: IntrospectedScrollListProps<T>) {
  if (!inverted) {
    throw new Error('Uninverted lists are not supported')
  }

  const scrollOffsetRef = React.useRef(0)
  const scrollHeightRef = React.useRef(0)
  const itemMeasures = React.useRef<Record<string, ItemInfo>>({})
  const lastVisibleKeyBounds = React.useRef<VisibleKeyBounds | null>(null)
  const isForegrounded = useIsAppForegrounded()

  const calculateVisibleItems = React.useCallback(() => {
    // Given the latest measurements, determine which items are visible
    if (!isForegrounded) {
      return
    }

    const lbound = scrollOffsetRef.current
    const ubound = lbound + scrollHeightRef.current

    const visibleItems = data.filter(item => {
      const key = keyExtractor(item)
      const measure = itemMeasures.current[key]?.measure
      return measure && isVerticallyInView(lbound, ubound, measure)
    })

    const nextVisibleKeyBounds: VisibleKeyBounds | null =
      visibleItems.length === 0
        ? null
        : [keyExtractor(visibleItems[0]), keyExtractor(visibleItems[visibleItems.length - 1])]

    if (keyBoundsChanged(lastVisibleKeyBounds.current, nextVisibleKeyBounds)) {
      lastVisibleKeyBounds.current = nextVisibleKeyBounds
      onVisibleItemsChanged(visibleItems)
    }
  }, [isForegrounded, data, keyExtractor, onVisibleItemsChanged])

  const remeasureItems = React.useCallback(() => {
    // Remeasure all of the items, and clear out items that are no longer present

    const currentKeys = new Set(data.map(keyExtractor))
    const deleteKeys: string[] = []

    Object.entries(itemMeasures.current).forEach(pair => {
      const [key, info] = pair

      if (!currentKeys.has(key)) {
        deleteKeys.push(key)
      } else {
        info.ref.current?.measure((x, y, width, height) => {
          info.measure = { height, width, x, y }
        })
      }
    })

    deleteKeys.forEach(key => {
      delete itemMeasures.current[key]
    })

    setTimeout(calculateVisibleItems)
  }, [data, keyExtractor, calculateVisibleItems])

  const handleScroll = React.useCallback(
    e => {
      const { nativeEvent } = e

      scrollOffsetRef.current = nativeEvent.contentOffset.y
      scrollHeightRef.current = nativeEvent.layoutMeasurement.height

      calculateVisibleItems()
    },
    [calculateVisibleItems],
  )

  const handleLayout = React.useCallback(
    ({ nativeEvent }: LayoutChangeEvent) => {
      scrollHeightRef.current = nativeEvent.layout.height
      calculateVisibleItems()
    },
    [calculateVisibleItems],
  )

  const handleContentSizeChange = React.useCallback(() => {
    remeasureItems()
  }, [remeasureItems])

  const handleItemLayout = React.useCallback(
    (itemKey: string, ref: React.RefObject<View | null>, measure: Measure) => {
      itemMeasures.current[itemKey] = { measure, ref }
    },
    [],
  )

  // When the app is foregrounded we want to report the visible items. This will keep things from
  // being marked as read when the browser tab is in the background, but make sure they're marked
  // as read when the browser tab is focused.
  const recalculateOnForegroundedRef = React.useRef(isForegrounded)

  React.useEffect(() => {
    if (isForegrounded && recalculateOnForegroundedRef.current !== isForegrounded) {
      calculateVisibleItems()
    }
    recalculateOnForegroundedRef.current = isForegrounded
  }, [calculateVisibleItems, isForegrounded])

  React.useEffect(() => {
    remeasureItems()
  }, [data, remeasureItems])

  return (
    <ScrollView
      ref={scrollViewRef}
      contentContainerStyle={styles.contentContainer}
      keyboardShouldPersistTaps="handled"
      onContentSizeChange={handleContentSizeChange}
      onLayout={handleLayout}
      onScroll={handleScroll}
      scrollEventThrottle={50}
      style={styles.contentContainer}
    >
      {data.map((item, index) => {
        const key = keyExtractor(item)
        return (
          <LayoutWrapper
            key={key}
            callbackState={key}
            onLayout={handleItemLayout}
            style={styles.layoutWrapper}
          >
            {renderItem(item, index)}
          </LayoutWrapper>
        )
      })}
      {HeaderComponent ? componentUtils.renderComponent(HeaderComponent) : null}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    flexDirection: 'column-reverse',
  },
  layoutWrapper: {
    flexDirection: 'column-reverse',
    width: '100%',
  },
  scrollView: {
    flex: 1,
    flexDirection: 'column-reverse',
  },
})

export default React.memo(IntrospectedScrollList) as typeof IntrospectedScrollList
