import React from 'react'
import { FlatList, ListRenderItemInfo } from 'react-native'
import { componentUtils } from '@/core/utils'
import { SpaceUser } from '@/types/entities'
import UserListItem from './UserListItem'

export interface BaseUsersSelectorProps {
  disabled?: boolean
  onSetSelection: (user: SpaceUser, selected: boolean) => void
  partitionIndex?: number
  selectedUserIds: number[] | null
  users: SpaceUser[]
}

type UserItem = ListRenderItemInfo<SpaceUser>

function BaseUsersSelector({
  users,
  onSetSelection,
  selectedUserIds,
  partitionIndex = -1,
  disabled = false,
}: BaseUsersSelectorProps) {
  const renderUser = React.useCallback(
    (data: UserItem) => (
      <UserListItem
        disabled={disabled}
        isPartition={data.index === partitionIndex}
        onSetSelection={onSetSelection}
        selected={selectedUserIds ? selectedUserIds.includes(data.item.id) : false}
        user={data.item}
      />
    ),
    [selectedUserIds, disabled, onSetSelection, partitionIndex],
  )

  return (
    <FlatList
      data={users}
      extraData={React.useMemo(
        () => ({ partitionIndex, selectedUserIds }),
        [selectedUserIds, partitionIndex],
      )}
      keyExtractor={componentUtils.idKey}
      keyboardShouldPersistTaps="handled"
      renderItem={renderUser}
    />
  )
}

export default React.memo(BaseUsersSelector)
