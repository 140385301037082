import React from 'react'
import { dateUtils as D, platformUtils, updateUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { versionThunks } from '@/thunks'
import { useAppForegrounded } from './appState'
import { useThrottleLock } from './sync'

export const useUpdateApp = () => {
  const dispatch = useAppDispatch()

  const checkForUpdate = React.useCallback(async () => {
    if (platformUtils.isWeb) {
      dispatch(versionThunks.checkWebVersion())
    } else {
      updateUtils.manuallyUpdateApp()
    }
  }, [dispatch])

  const updateApp = useThrottleLock(checkForUpdate, D.toSeconds.hours(1))

  useAppForegrounded(updateApp)

  React.useEffect(() => {
    const subscription = updateUtils.attachUpdateHandler()

    return () => subscription.remove()
  }, [])
}
