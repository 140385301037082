export const CREATE_CHANNEL = 'createChannel'
export const CREATE_HUB = 'createHub'
export const CREATE_MESSAGE = 'createMessage'
export const CREATE_PHONE_NUMBER = 'createPhoneNumber'
export const CREATE_SPACE = 'createSpace'
export const CREATE_SUMMARY = 'createSummary'
export const EDIT_CHANNEL = 'editChannel'
export const EDIT_MESSAGE = 'editMessage'
export const EDIT_PASSWORD = 'editPassword'
export const EDIT_PROFILE = 'editProfile'
export const EDIT_EMAIL = 'editEmail'
export const EDIT_SPACE = 'editSpace'
export const EDIT_THREAD = 'editThread'
export const INVITE_USER_TO_SPACE = 'inviteUserToSpace'
export const JOIN_SPACE = 'joinSpace'
export const REQUEST_PASSWORD_RESET = 'requestPasswordReset'
export const RESET_PASSWORD = 'resetPassword'
export const SIGN_IN = 'signIn'
export const SIGN_UP = 'signUp'
export const VERIFY_PHONE_NUMBER = 'verifyPhoneNumber'
