import React from 'react'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useIsDetailActive } from '@/components/tasks/TaskForm/hooks'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import TaskDueDate from './TaskDueDate'
import TaskRecurrence from './TaskRecurrence'
import TaskReminderDate from './TaskReminderDate'

function TaskDates() {
  const isDueDateActive = useIsDetailActive('DUE_DATE')
  const isRemindAtActive = useIsDetailActive('REMINDER')
  const isRecurrenceActive = useIsDetailActive('RECURRENCE')
  const { showDetail } = useTaskFormContext()
  const showDates = React.useCallback(() => showDetail('DUE_DATE'), [showDetail])

  if (!(isDueDateActive || isRemindAtActive || isRecurrenceActive)) {
    return null
  }

  return (
    <Touchable feedback="highlight" onPress={showDates}>
      <Box alignItems="center" direction="row" gapHorizontal="large" gapTop="large">
        <Box flex={1}>
          <TaskDueDate />
        </Box>
        <Box flex={1}>
          <TaskReminderDate />
        </Box>
        <Box flex={1}>
          <TaskRecurrence />
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskDates)
