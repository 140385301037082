import { LogLevel, LogOptions, logLevels } from '@/types/logging'
import config from './config'
import { sentryProxy } from './instrumentation'

const shouldLogToSentry = (level: LogLevel) =>
  logLevels[level] <= logLevels[config.logToSentryLevel]
const shouldLog = (level: LogLevel) => logLevels[level] <= logLevels[config.logLevel]

export function reportError(err: Error): void {
  if (shouldLogToSentry('error')) {
    sentryProxy.captureException(err)
  } else {
    log('error', 'Error thrown', { context: err })
  }
}

function reportMessage(message: string, options?: LogOptions) {
  sentryProxy.captureMessage(message, options)
}

export function log(level: LogLevel, message: string, options?: LogOptions): void {
  if (shouldLogToSentry(level)) {
    reportMessage(`${level}: ${message}`, options)
  } else if (shouldLog(level)) {
    const traceId = options?.traceId
    const context = options?.context

    const logMessage = traceId ? `${message} (Trace ID: ${traceId})` : message

    switch (level) {
      /* eslint-disable no-console */
      case 'error':
        if (context) {
          console.error(logMessage, context)
        } else {
          console.error(logMessage)
        }
        break
      case 'warning':
        if (context) {
          console.warn(logMessage, context)
        } else {
          console.warn(logMessage)
        }
        break
      case 'info':
        if (context) {
          console.info(logMessage, context)
        } else {
          console.info(logMessage)
        }
        break
      case 'debug':
        if (context) {
          console.debug(logMessage, context)
        } else {
          console.debug(logMessage)
        }
        break
      default:
        if (context) {
          console.log(logMessage, context)
        } else {
          console.log(logMessage)
        }
        break
      /* eslint-enable */
    }
  }
}

export const noOpWarn = (message: string) => (): void => {
  log('info', message)
}

export const alwaysAsyncWarn =
  <T>(t: T, message: string) =>
  (): Promise<T> => {
    log('info', message)
    return Promise.resolve(t)
  }
