import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { stringUtils as S, taskUtils } from '@/core/utils'
import { useCurrentUserId, useUser } from '@/hooks'
import { Task, Thread, User } from '@/types/entities'

interface TaskCreatedIndicatorProps {
  task: Task
  thread?: Thread
}

function TaskCreatedIndicator({ task, thread }: TaskCreatedIndicatorProps) {
  const myId = useCurrentUserId() || 0
  const messageCreator = useUser(task.spaceId, task.messageCreatorId)
  const taskCreator = useUser(task.spaceId, task.creatorId)
  const assignee = useUser(task.spaceId, task.assignedUserId)
  const alignRight = task.creatorId === myId

  const description = getDescription({
    assignee,
    messageCreator,
    myId,
    task,
    taskCreator,
  })

  let replyCount = ''
  if (thread && thread.replyCount) {
    replyCount = ` (${S.pluralize(thread.replyCount, 'reply', 'replies')})`
  }

  return (
    <Box alignItems="center" direction="row" gapBottom="xsmall" gapTop="none">
      {alignRight ? <Box flex={1} /> : null}
      <Text color="anchor" ellipsizeMode="tail" numberOfLines={1} size="h6" weight="medium">
        {`${S.sentenceCase(description)}${replyCount}`}
      </Text>
    </Box>
  )
}

type DescriptionArgs = {
  assignee: User | undefined
  messageCreator: User | undefined
  myId: number
  task: Task
  taskCreator: User | undefined
}

const getDescription = (args: DescriptionArgs): string => {
  const { task } = args

  const isQuestion = taskUtils.isQuestion(task)
  const taskLabel = isQuestion ? 'question' : 'task'

  switch (task.status) {
    case 'MOVED':
      return `This ${taskLabel} was moved`
    case 'DELETED':
      return `This ${taskLabel} was deleted`
    case `ARCHIVED`:
      return `This ${taskLabel} was archived`
    default:
      if (taskUtils.isCompleted(task)) {
        return isQuestion ? 'This question was answered' : 'This task was completed'
      }
      if (taskUtils.isAccepted(task) && task.creatorId !== task.assignedUserId) {
        return getAcceptedDescription(args)
      }
      return getCreatedDescription(args)
  }
}

const getAcceptedDescription = ({ assignee, myId }: DescriptionArgs): string => {
  if (assignee) {
    return assignee.id === myId
      ? 'You accepted this task'
      : `${assignee.firstName} accepted this task`
  }
  return 'This task was accepted'
}

const getCreatedDescription = ({
  task,
  taskCreator,
  messageCreator,
  assignee,
  myId,
}: DescriptionArgs): string => {
  const isFromMe = taskCreator?.id === myId
  const isToMe = assignee?.id === myId
  const fromName = isFromMe ? 'you' : taskCreator?.firstName
  const toName = isToMe ? 'you' : assignee?.firstName

  if (taskCreator?.id !== messageCreator?.id) {
    if (messageCreator?.id === myId) {
      return `${fromName} converted your message to a task`
    }
    return `${fromName} converted a message to a task`
  }

  const isQuestion = taskUtils.isQuestion(task)

  return isQuestion
    ? `${fromName} asked ${toName} a question`
    : `${fromName} assigned a task to ${toName}`
}

export default React.memo(TaskCreatedIndicator)
