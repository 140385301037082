import React from 'react'
import SelectListItem from '@/components/core/SelectListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { offsetUtils as O } from '@/core/utils'
import { ReminderOffset } from '@/types/entities'

interface OffsetListItemProps {
  offset: ReminderOffset
  isSelected: boolean
  onSelect: (offset: ReminderOffset) => void
}

function OffsetListItem({ offset, isSelected, onSelect }: OffsetListItemProps) {
  const handleSelect = React.useCallback(() => onSelect(offset), [onSelect, offset])
  const label = O.toString(offset)

  return (
    <SelectListItem isSelected={isSelected} onPress={handleSelect}>
      <Box flex={1}>
        <Text>{label ? `${label} before` : 'On Due Date and Time'}</Text>
      </Box>
    </SelectListItem>
  )
}

export default React.memo(OffsetListItem)
