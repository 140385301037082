import { FutureDate, PastDate, TaskRelationshipFields, TaskSort } from '@/types/tasks'

export const createdAtSort: TaskSort = {
  direction: 'desc',
  field: 'CREATED_DATE',
  nullsFirst: false,
  type: 'field',
}

type DateConfiguration<T> = {
  value: T
  label: string
  allListLabel?: string | null | undefined
}

export const futureDates: DateConfiguration<FutureDate>[] = [
  { label: 'Late', value: 'PAST' },
  { label: 'Today', value: 'TODAY' },
  { label: 'Tomorrow', value: 'TOMORROW' },
  { label: 'Upcoming', value: 'UPCOMING' },
  { label: 'Future', value: 'FUTURE' },
]

export const pastDates: DateConfiguration<PastDate>[] = [
  { label: 'Today', value: 'TODAY' },
  { label: 'Yesterday', value: 'YESTERDAY' },
  { allListLabel: 'Previous 7 Days', label: 'Previous', value: 'PREVIOUS' },
  { label: 'Earlier', value: 'EARLIER' },
]

export const clearTaskRelationshipFields: TaskRelationshipFields = {
  assignedUserIds: undefined,
  watchingUserIds: undefined,
}
