import { dateUtils as D } from '@/core/utils'
import { useCurrentTimestamp } from '@/hooks/dates'
import { taskListSelectors } from '@/store/selectors'
import { useCurrentUserId } from './auth'
import { useCachedSelector } from './redux'

export const useMyDayList = (userId?: number) => {
  const myId = useCurrentUserId()
  const taskList = useCachedSelector(taskListSelectors.taskListSelector, [
    userId || myId || 0,
    'MY_DAY',
  ])

  const currentTimestamp = useCurrentTimestamp()
  const needsPlanning =
    !taskList ||
    taskList.lastPlannedAt === null ||
    taskList.lastPlannedAt < D.formatISO(D.floor(currentTimestamp, 'day'))

  return {
    needsPlanning,
    taskList,
  }
}
