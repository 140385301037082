import { isWeb } from '@/core/utils/platform'
import { CacheDuration } from '@/types/files'
import { FileCache } from './FileCache'
import { WebCache } from './WebCache'

// Singleton FileCache that all exported functions use
const fileCache = isWeb ? new WebCache() : new FileCache()

export const getLocalURI = (remoteURI: string): string | null => fileCache.getLocalURI(remoteURI)

export const cacheFile = async (remoteURI: string, duration: CacheDuration, traceId?: string) =>
  fileCache.cacheFile(remoteURI, duration, traceId)

export const cacheFiles = async (remoteURIs: string[], duration: CacheDuration) =>
  fileCache.warmupCache(remoteURIs, duration)

export const clearCache = async () => fileCache.clearCache()

export const cleanupCache = async (maxAges: Record<CacheDuration, number>) =>
  fileCache.cleanupCaches(maxAges)
