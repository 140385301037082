import { Question, QuestionTask, Task } from '@/types/entities'
import { QuestionMode, QuestionResponseForm } from '@/types/tasks'
import { setEquals } from '../collections'

export { isCompleted as isAnswered } from './status'

export const isQuestion = (task: Task): task is QuestionTask => !!task.question

export const getQuestionMode = (question: Question): QuestionMode => {
  if (!question.responses || question.responses.length === 0) {
    if (question.isOpenEnded) {
      return 'OPEN_ENDED'
    }
    throw new Error('Unable to determine question mode')
  }
  if (responsesIsYesNo(question)) {
    return 'YES_NO'
  }
  return 'MULTIPLE_CHOICE'
}

const responsesIsYesNo = (question: Question) => {
  if (question.responseType === 'MULTIPLE' || question.isOpenEnded) {
    return false
  }
  const { responses } = question
  return (
    responses.length === 2 &&
    responses[0].content.toLowerCase() === 'yes' &&
    responses[1].content.toLowerCase() === 'no'
  )
}

export const responsesAreDifferent = (
  left: QuestionResponseForm,
  right: QuestionResponseForm,
): boolean => {
  if (left.openEndedResponse !== right.openEndedResponse) {
    return true
  }
  if (!setEquals(new Set(left.selectedResponseIds), new Set(right.selectedResponseIds))) {
    return true
  }
  return false
}

export const responseIsComplete = (response: QuestionResponseForm) =>
  response.selectedResponseIds.length !== 0 || !!response.openEndedResponse
