import React from 'react'
import Icon from '@/components/core/Icon'
import Touchable from '@/components/core/Touchable'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { Task } from '@/types/entities'

interface TaskListItemTagsFocusProps {
  task: Task
}

function TaskListItemTagsFocus({ task }: TaskListItemTagsFocusProps) {
  const { editTask } = useTasksListViewContext()
  const handlePress = React.useCallback(() => editTask(task, 'TAGS'), [editTask, task])

  return (
    <Touchable feedback="opacity" hitSlop="large" onPress={handlePress}>
      <Icon color="iron" kind="solid" name="hashtag" size="h3" />
    </Touchable>
  )
}

export default React.memo(TaskListItemTagsFocus)
