import React from 'react'
import { noOpWarn } from '@/core/logging'
import { WebDraggableFlatListContextType } from './types'

const noOp = noOpWarn('WebDraggableFlatListContext not present')

const defaultContextValue: WebDraggableFlatListContextType = {
  dragEnd: noOp,
  dragOver: noOp,
  dragStart: noOp,
  drop: noOp,
  fromIndex: null,
  toIndex: null,
}

const WebDraggableFlatListContext = React.createContext(defaultContextValue)

export const useWebDraggableFlatListContext = () => React.useContext(WebDraggableFlatListContext)

export default WebDraggableFlatListContext
