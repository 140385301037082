import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { userUtils } from '@/core/utils'
import { useCurrentUserId, useUser } from '@/hooks'
import { Channel } from '@/types/entities'

interface MessageListBeginningIntroProps {
  channel: Channel
}

const MessageListBeginningIntro = ({ channel }: MessageListBeginningIntroProps) => {
  const myId = useCurrentUserId()
  const partnerId = React.useMemo(
    () => channel.userIds.find(id => id !== myId),
    [myId, channel.userIds],
  )
  const me = useUser(channel.spaceId, myId ?? 0)
  const partner = useUser(channel.spaceId, partnerId ?? 0)

  if (me && channel.userIds.length === 1) {
    return (
      <Box gapAll="xlarge">
        <Box direction="row" gapAll="large" justifyContent="center">
          <UserProfilePicture size="massive" user={me} />
        </Box>
        <Box alignItems="center" gapAll="large" gapTop="none">
          <Text color="iron" size="h5">
            {userUtils.getFullName(me)}
          </Text>
          <Text color="iron" size="h5">
            (You)
          </Text>
        </Box>
        <Box gapAll="large">
          <Text align="center" color="emmre-blue" lineHeight={32} size="h2" weight="medium">
            Welcome to your Direct Message with Just You!
          </Text>
        </Box>
        <Box gapAll="large">
          <Text color="iron" size="h4">
            This is your messages with yourself. This is a safe space, we don&apos;t judge. You can
            use this area to draft messages, keep things handy, or get something from your phone to
            your computer.
          </Text>
        </Box>
        <Box gapAll="large">
          <Text color="iron" size="h4">
            You can even chat with yourself. Some people find that very helpful, let&apos;s just try
            and keep it positive!
          </Text>
        </Box>
      </Box>
    )
  }

  if (!partner || channel.userIds.length > 2) {
    return null
  }

  const firstName = userUtils.getFirstName(partner, false)
  const firstNamePlural = userUtils.getFirstName(partner, true)
  const fullName = userUtils.getFullName(partner)

  return (
    <Box gapAll="xlarge">
      <Box direction="row" gapAll="large" justifyContent="center">
        <UserProfilePicture size="massive" user={partner} />
      </Box>
      <Box alignItems="center" direction="row" gapAll="large" gapTop="none" justifyContent="center">
        <Text color="iron" size="h5">
          {fullName}
        </Text>
      </Box>
      <Box gapAll="large">
        <Text align="center" color="emmre-blue" lineHeight={32} size="h2" weight="medium">
          Welcome to your Direct Message with {fullName}
        </Text>
      </Box>
      <Box gapAll="large">
        <Text color="iron" size="h4">
          When you send a Executive Brief to {firstName} this is where you will see{' '}
          {firstNamePlural} responses: Comments, Answered Questions and Task Interactions.
        </Text>
      </Box>
    </Box>
  )
}

export default React.memo(MessageListBeginningIntro)
