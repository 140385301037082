import React from 'react'
import { useAppDispatch } from '@/hooks'
import { useNativeAppForegrounded } from '@/hooks/appState'
import { useRTMAuthenticated } from '@/hooks/rtm'
import { messageThunks } from '@/thunks'
import { Channel } from '@/types/entities'

export const useCatchUp = (channel: Channel, threadId?: number, lastMessageId?: number) => {
  const dispatch = useAppDispatch()
  const [isCatchingUp, setIsCatchingUp] = React.useState<boolean>(false)

  const catchUp = React.useCallback(async () => {
    if (!lastMessageId) {
      return
    }

    setIsCatchingUp(true)

    await dispatch(
      messageThunks.getNewerMessages({
        afterMessageId: lastMessageId,
        channelId: channel.id,
        spaceId: channel.spaceId,
        threadId,
      }),
    )

    setIsCatchingUp(false)
  }, [dispatch, lastMessageId, channel, threadId, setIsCatchingUp])

  // For native apps we need to refresh when they're foregrounded because they may have
  // disconnected from RTM. For web apps we can assume that the tab being backgrounded does not
  // discconect rtm? on ios it seems to disconnect when the phone is locked (for native)
  useNativeAppForegrounded(catchUp)
  useRTMAuthenticated(catchUp)

  return { isCatchingUp }
}
