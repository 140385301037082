import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'

interface NewMessagesIndicatorProps {
  show: boolean
}

function NewMessagesIndicator({ show }: NewMessagesIndicatorProps) {
  if (!show) {
    return null
  }

  return (
    <FloatContainer left={0} right={0} top={15}>
      <Box direction="row" gapTop="xsmall" justifyContent="center">
        <Background borderRadius={15} color="obsidian">
          <Box gapAll="medium">
            <Text color="ghost" size="h6">
              New Messages Above
            </Text>
          </Box>
        </Background>
      </Box>
    </FloatContainer>
  )
}

export default React.memo(NewMessagesIndicator)
