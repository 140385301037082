import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import { getFilename } from './files'
import { requestMediaLibraryPermissions } from './permissions'
import { isWeb } from './platform'

type DocumentResult = {
  filename: string
  uri: string
}

type PickPhoto = () => Promise<DocumentResult | null>

export const pickPhoto: PickPhoto = () => {
  if (isWeb) {
    return pickPhotoDocument()
  }
  return pickPhotoLibrary()
}

const pickPhotoLibrary: PickPhoto = async () => {
  const granted = await requestMediaLibraryPermissions('pick a photo', true)

  if (!granted) {
    return null
  }

  const pickerResult = await ImagePicker.launchImageLibraryAsync({
    allowsEditing: true,
    aspect: [1, 1],
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    quality: 0,
  })

  if (pickerResult.cancelled) {
    return null
  }

  const { uri } = pickerResult

  return { filename: getFilename(uri), uri }
}

const pickPhotoDocument: PickPhoto = async () => {
  const result = await DocumentPicker.getDocumentAsync({ type: 'image/*' })

  if (result.type === 'cancel') {
    return null
  }

  const { uri, name } = result
  return { filename: name, uri }
}
