import React from 'react'
import { StyleSheet, View } from 'react-native'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Hoverable from '@/components/core/Hoverable'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { getColor } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'

interface SummaryItemButtonProps {
  iconName: IconName
  onPress: () => void
  description: string
}

function SummaryItemButton({ iconName, onPress, description }: SummaryItemButtonProps) {
  return (
    <Hoverable>
      {hovering => (
        <>
          <Background borderRadius={7} color={hovering ? 'emmre-blue' : 'ghost'}>
            <Box gapAll="medium">
              <BaseIconButton
                color={hovering ? 'ghost' : 'emmre-blue'}
                kind="light"
                name={iconName}
                onPress={onPress}
                padding={0}
              />
            </Box>
          </Background>
          {hovering ? (
            <View style={styles.helperText}>
              <Text color="ghost" numberOfLines={1}>
                {description}
              </Text>
            </View>
          ) : null}
        </>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  helperText: {
    backgroundColor: getColor('obsidian'),
    borderRadius: 8,
    bottom: '100%',
    marginBottom: 4,
    padding: 8,
    position: 'absolute',
  },
})

export default React.memo(SummaryItemButton)
