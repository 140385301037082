import React from 'react'
import { TextInput } from 'react-native'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import type { OnKeyPress } from '@/types/components'
import TaskCompleteCheckbox from './TaskCompleteCheckbox'
import TaskFormPriorityCycleButton from './TaskFormPriorityCycleButton'
import { fieldNames } from './constants'

interface TaskTitleInputProps {
  fastMode: boolean
}

function TaskTitleInput({ fastMode }: TaskTitleInputProps) {
  const { interactionMode, formName, submit, canSubmit, cancel } = useTaskFormContext()
  const inputRef = React.useRef<TextInput | null>(null)
  const handleSubmitEditing = React.useCallback(() => {
    if (canSubmit) {
      submit()
      if (inputRef && 'current' in inputRef) {
        setTimeout(() => inputRef.current?.focus())
      }
    }
  }, [canSubmit, submit])
  const handleKeyPress = React.useCallback<OnKeyPress>(
    ({ nativeEvent }) => {
      if (nativeEvent.key === 'Escape') {
        cancel()
      }
    },
    [cancel],
  )

  return (
    <Box alignItems="center" direction="row" gapHorizontal="large" gapTop="xsmall">
      {interactionMode === 'create' ? null : (
        <Box gapRight="medium">
          <TaskCompleteCheckbox />
        </Box>
      )}
      <Box flex={1}>
        <FormTextInput
          ref={inputRef}
          autoFocus={interactionMode === 'create'}
          blurOnSubmit={fastMode}
          expandable
          fieldName={fieldNames.content}
          formName={formName}
          kind="taskTitle"
          maxHeight={54}
          multiline
          onKeyPress={fastMode ? handleKeyPress : undefined}
          onSubmitEditing={fastMode ? handleSubmitEditing : undefined}
          placeholder="Write Task Title"
        />
      </Box>
      <TaskFormPriorityCycleButton />
    </Box>
  )
}

export default React.memo(TaskTitleInput)
