import { Task, TaskCreationSource, TaskDueDateType, TaskUrgency } from '@/types/entities'
import { Pair, Predicate } from '@/types/generics'
import {
  BinaryTaskFilter,
  DateRange,
  InclusionList,
  InclusionMode,
  TaskFilter,
  TaskFilterFields,
  TaskFilterOperator,
  UnaryTaskFilter,
} from '@/types/tasks'
import { TaskListFilterField } from '@/types/tasks/filtering'
import * as C from '../collections'
import { isString } from '../types'
import { getFutureDateBounds, getPastDateBounds } from './dates'
import { isQuestion } from './questions'
import { RelatedTaskData } from './related'
import { isAccepted, isActive, isCompleted } from './status'

type InclusionSet<T> =
  | {
      values: Set<T>
      mode: InclusionMode
    }
  | { mode: 'none' | 'any' | 'all' }

type InclusionMap<K, V> =
  | {
      values: Map<K, Set<V>>
      mode: InclusionMode
    }
  | { mode: 'none' | 'any' | 'all' }

interface UnaryCompiledTaskFilter {
  type: 'UNARY'
  operator: TaskFilterOperator

  accepted?: boolean
  assignedUserIds?: InclusionSet<number>
  channelIds?: InclusionMap<number, number>
  completed?: boolean
  createdDateRange?: DateRange
  creatorUserIds?: InclusionSet<number>
  dueDateRange?: DateRange
  dueDateTypes?: InclusionSet<TaskDueDateType>
  spaceIds?: InclusionSet<number>
  urgencies?: InclusionSet<TaskUrgency>
  creationSources?: InclusionSet<TaskCreationSource>
  boardIds?: InclusionMap<number, number>
  tagIds?: InclusionMap<number, number>
  watchingUserIds?: InclusionSet<number>
  taskIds?: InclusionMap<number, number>
  taskList?: TaskListFilterField
  isQuestion?: boolean
}

interface BinaryCompiledTaskFilter {
  type: 'BINARY'
  operator: TaskFilterOperator

  left: CompiledTaskFilter
  right: CompiledTaskFilter
}

type CompiledTaskFilter = UnaryCompiledTaskFilter | BinaryCompiledTaskFilter

export const unaryFilter = (filter: TaskFilterFields): UnaryTaskFilter => ({
  operator: 'AND',
  type: 'UNARY',
  ...filter,
})

export const binaryFilter = (
  left: TaskFilter,
  right: TaskFilter,
  operator: TaskFilterOperator,
): BinaryTaskFilter => ({
  left,
  operator,
  right,
  type: 'BINARY',
})

export const compileTaskFilter = (
  relatedData: RelatedTaskData,
  taskFilter: TaskFilter,
): Predicate<Task> => {
  const compiled = compileFilter(taskFilter)

  return task => includeTask(compiled, relatedData, task)
}

export const countIncludedTasks = (
  taskFilter: TaskFilter,
  relatedData: RelatedTaskData,
  tasks: Task[],
): number => {
  const compiled = compileFilter(taskFilter)

  return tasks.reduce((acc, task) => (includeTask(compiled, relatedData, task) ? acc + 1 : acc), 0)
}

export const includeList = <T>(values: T[], mode: InclusionMode = 'include'): InclusionList<T> => ({
  mode,
  values,
})

// Apply updates to all unary, AND filters in the tree
export function mergeFilters(
  baseFilter: UnaryTaskFilter,
  updates: TaskFilterFields,
): UnaryTaskFilter
export function mergeFilters(
  baseFilter: BinaryTaskFilter,
  updates: TaskFilterFields,
): BinaryTaskFilter
export function mergeFilters(baseFilter: TaskFilter, updates: TaskFilterFields): TaskFilter

export function mergeFilters(baseFilter: TaskFilter, updates: TaskFilterFields): TaskFilter {
  if (baseFilter.type === 'BINARY') {
    return {
      ...baseFilter,
      left: mergeFilters(baseFilter.left, updates),
      right: mergeFilters(baseFilter.right, updates),
    }
  }
  // TODO: what to do if one field overwrites another? Do we need something more sophisticated
  //       to compare fields and properly handle include lists?
  return { ...baseFilter, ...updates }
}

// Flatten a binary filter into a unary filter. This is used to see what all fields are being
// filtered on. The resulting unary task filter will not necessarily be the equivalent of the
// original binary filter.
export function flattenFilter(filter: TaskFilter): UnaryTaskFilter {
  if (filter.type === 'UNARY') {
    return filter
  }

  return mergeFilters(flattenFilter(filter.left), flattenFilter(filter.right))
}

export const getIncludedValue = <T, F>(list: InclusionList<T>, fallback: F): T | F => {
  // Returns the single included value, if there's just one. Otherwise fallback
  if (list.mode === 'include' && list.values.length === 1) {
    return list.values[0]
  }
  return fallback
}

type IncludeResult = { operator: TaskFilterOperator; value: boolean; done: boolean }

const updateIncludeResult = (result: IncludeResult, nextValue: boolean) => {
  /* eslint-disable no-param-reassign */
  result.value = nextValue

  if (result.operator === 'AND') {
    if (nextValue === false) {
      result.done = true
    }
  } else if (nextValue === true) {
    result.done = true
  }
  /* eslint-enable */
}

const includeTask = (
  filter: CompiledTaskFilter,
  relatedData: RelatedTaskData,
  task: Task,
): boolean => {
  if (filter.type === 'UNARY') {
    return taskPassesUnaryFilter(filter, relatedData, task)
  }

  const passesLeft = includeTask(filter.left, relatedData, task)

  if (passesLeft && filter.operator === 'OR') {
    return true
  }
  if (!passesLeft && filter.operator === 'AND') {
    return false
  }

  return includeTask(filter.right, relatedData, task)
}

const taskPassesUnaryFilter = (
  filter: UnaryCompiledTaskFilter,
  relatedData: RelatedTaskData,
  task: Task,
): boolean => {
  if (!isActive(task)) {
    return false
  }

  if (filter.isQuestion !== undefined) {
    if (filter.isQuestion !== isQuestion(task)) {
      return false
    }
  }

  // If the filter requests particular task IDs to be included or excluded
  // tasks will be checked on that first and will short-circuit the process
  // if they are in the inclusion/exclusion list, without regard to the
  // AND / OR status of the filter
  if (filter.taskIds) {
    const { mode } = filter.taskIds

    if (mode === 'include' || mode === 'exclude') {
      const { values } = filter.taskIds

      const present = !!values.get(task.spaceId)?.has(task.id)

      if (present) {
        return mode === 'include'
      }
    } else {
      throw new Error(`Invalid mode for task id filtering: ${mode}`)
    }
  }

  if (filter.taskList !== undefined) {
    const { taskListId, relationship } = filter.taskList
    if (relationship === 'IN_LIST') {
      if (!relatedData.isTaskInList(taskListId, task)) {
        return false
      }
    } else if (relationship === 'NOT_IGNORED') {
      if (relatedData.isTaskIgnoredForList(taskListId, task)) {
        return false
      }
    }
  }

  const result: IncludeResult = {
    done: false,
    operator: filter.operator,
    value: true,
  }

  if (filter.completed !== undefined) {
    updateIncludeResult(result, isCompleted(task) === filter.completed)

    if (result.done) {
      return result.value
    }
  }

  if (filter.accepted !== undefined) {
    updateIncludeResult(result, isAccepted(task) === filter.accepted)

    if (result.done) {
      return result.value
    }
  }

  if (filter.assignedUserIds) {
    const { mode } = filter.assignedUserIds

    if (mode === 'include') {
      updateIncludeResult(result, filter.assignedUserIds.values.has(task.assignedUserId))
    } else if (mode === 'exclude') {
      updateIncludeResult(result, !filter.assignedUserIds.values.has(task.assignedUserId))
    }

    // Ignore all, any, and none since every task has an assigned user

    if (result.done) {
      return result.value
    }
  }

  if (filter.channelIds) {
    const { mode } = filter.channelIds

    if (mode === 'include' || mode === 'exclude') {
      const channelIds = filter.channelIds.values.get(task.spaceId)
      const hasChannel = channelIds !== undefined && channelIds.has(task.channelId)

      if (mode === 'include') {
        updateIncludeResult(result, hasChannel)
      } else {
        updateIncludeResult(result, !hasChannel)
      }
    }

    // Ignore all, any, and none since every task has a channel

    if (result.done) {
      return result.value
    }
  }

  if (filter.createdDateRange) {
    // TODO: use bounds inclusion

    const { lbound, ubound } = filter.createdDateRange

    updateIncludeResult(result, lbound === null || task.createdAt >= lbound)
    if (result.value) {
      updateIncludeResult(result, ubound === null || task.createdAt <= ubound)
    }

    if (result.done) {
      return result.value
    }
  }

  if (filter.creatorUserIds) {
    const { mode } = filter.creatorUserIds

    if (mode === 'include') {
      updateIncludeResult(result, filter.creatorUserIds.values.has(task.creatorId))
    } else if (mode === 'exclude') {
      updateIncludeResult(result, !filter.creatorUserIds.values.has(task.creatorId))
    }

    // Ignore all, any, and none since every task has a creator

    if (result.done) {
      return result.value
    }
  }

  if (filter.dueDateRange) {
    // TODO: use bounds inclusion

    if (!task.dueDate) {
      updateIncludeResult(result, false)
    } else {
      const { lbound, ubound } = filter.dueDateRange

      updateIncludeResult(result, lbound === null || task.dueDate >= lbound)
      if (result.value) {
        updateIncludeResult(result, ubound === null || task.dueDate <= ubound)
      }
    }

    if (result.done) {
      return result.value
    }
  }

  if (filter.dueDateTypes) {
    const { mode } = filter.dueDateTypes

    if (mode === 'include') {
      updateIncludeResult(result, filter.dueDateTypes.values.has(task.dueDateType))
    } else if (mode === 'exclude') {
      updateIncludeResult(result, !filter.dueDateTypes.values.has(task.dueDateType))
    }

    // Ignore all, any, and none since every task has a due date type

    if (result.done) {
      return result.value
    }
  }

  if (filter.spaceIds) {
    const { mode } = filter.spaceIds

    if (mode === 'include') {
      updateIncludeResult(result, filter.spaceIds.values.has(task.spaceId))
    } else if (mode === 'exclude') {
      updateIncludeResult(result, !filter.spaceIds.values.has(task.spaceId))
    }

    // Ignore all, any, and none since every task has a space ID

    if (result.done) {
      return result.value
    }
  }

  if (filter.urgencies) {
    const { mode } = filter.urgencies

    if (mode === 'include') {
      updateIncludeResult(result, filter.urgencies.values.has(task.urgency))
    } else if (mode === 'exclude') {
      updateIncludeResult(result, !filter.urgencies.values.has(task.urgency))
    }

    // Ignore all, any, and none since every task has an urgency

    if (result.done) {
      return result.value
    }
  }

  if (filter.creationSources) {
    const { mode } = filter.creationSources

    if (mode === 'include') {
      updateIncludeResult(result, filter.creationSources.values.has(task.creationSource))
    } else if (mode === 'exclude') {
      updateIncludeResult(result, !filter.creationSources.values.has(task.creationSource))
    }

    // Ignore all, any, and none since all tasks have a creation source

    if (result.done) {
      return result.value
    }
  }

  if (filter.boardIds) {
    if (filter.boardIds.mode === 'all') {
      // pass, we don't filter in this case
    } else if (filter.boardIds.mode === 'any') {
      updateIncludeResult(result, !!task.boardId)
    } else if (filter.boardIds.mode === 'none') {
      updateIncludeResult(result, !task.boardId)
    } else if (filter.boardIds.mode === 'include' || filter.boardIds.mode === 'exclude') {
      const boardIds = filter.boardIds.values.get(task.spaceId)

      const hasBoard = !!(boardIds !== undefined && task.boardId && boardIds.has(task.boardId))

      if (filter.boardIds.mode === 'include') {
        updateIncludeResult(result, hasBoard)
      }
      if (filter.boardIds.mode === 'exclude') {
        updateIncludeResult(result, !hasBoard)
      }
    }

    if (result.done) {
      return result.value
    }
  }

  if (filter.tagIds) {
    const { mode } = filter.tagIds
    const hasTags = !!(task.tagIds && task.tagIds.length !== 0)

    if (mode === 'all') {
      // Pass, we don't filter in this case
    } else if (mode === 'any') {
      updateIncludeResult(result, hasTags)
    } else if (mode === 'none') {
      updateIncludeResult(result, !hasTags)
    } else if (mode === 'include') {
      const lookingFor = filter.tagIds.values.get(task.spaceId)
      updateIncludeResult(
        result,
        !!(lookingFor && task.tagIds && C.setOverlaps(lookingFor, task.tagIds)),
      )
    } else if (mode === 'exclude') {
      const lookingFor = filter.tagIds.values.get(task.spaceId)
      updateIncludeResult(
        result,
        !lookingFor || !task.tagIds || !C.setOverlaps(lookingFor, task.tagIds),
      )
    }

    if (result.done) {
      return result.value
    }
  }

  if (filter.watchingUserIds) {
    const { mode } = filter.watchingUserIds
    const hasWatchers = !(task.watchers && task.watchers.length !== 0)

    if (mode === 'all') {
      // Pass, we don't filter in this case
    } else if (mode === 'any') {
      updateIncludeResult(result, hasWatchers)
    } else if (mode === 'none') {
      updateIncludeResult(result, !hasWatchers)
    } else if (mode === 'include') {
      updateIncludeResult(
        result,
        !!task.watchers && C.setOverlaps(filter.watchingUserIds.values, task.watchers),
      )
    } else if (mode === 'exclude') {
      updateIncludeResult(
        result,
        !task.watchers || !C.setOverlaps(filter.watchingUserIds.values, task.watchers),
      )
    }

    if (result.done) {
      return result.value
    }
  }

  return result.value
}

const compileInclusionSet = <T>(list: InclusionList<T>): InclusionSet<T> => {
  if (list.mode === 'none' || list.mode === 'all' || list.mode === 'any') {
    return { mode: list.mode }
  }
  if (list.mode === 'include' || list.mode === 'exclude') {
    return {
      mode: list.mode,
      values: new Set(list.values),
    }
  }
  throw new Error(`Invalid inclusion list mode: ${list.mode}`)
}

const compileInclusionMap = <K, V>(list: InclusionList<Pair<K, V>>): InclusionMap<K, V> => {
  if (list.mode === 'none' || list.mode === 'all' || list.mode === 'any') {
    return { mode: list.mode }
  }
  if (list.mode === 'include' || list.mode === 'exclude') {
    return {
      mode: list.mode,
      values: list.values.reduce((map: Map<K, Set<V>>, [key, value]: Pair<K, V>) => {
        const valueSet = map.get(key)
        if (!valueSet) {
          map.set(key, new Set([value]))
        } else {
          valueSet.add(value)
        }
        return map
      }, new Map<K, Set<V>>()),
    }
  }
  throw new Error(`Invalid inclusion list mode: ${list.mode}`)
}

const compileFilter = (taskFilter: TaskFilter): CompiledTaskFilter => {
  if (taskFilter.type === 'UNARY') {
    return compileUnaryFilter(taskFilter)
  }

  return {
    left: compileFilter(taskFilter.left),
    operator: taskFilter.operator,

    right: compileFilter(taskFilter.right),
    type: 'BINARY',
  }
}

const compileUnaryFilter = (taskFilter: UnaryTaskFilter): UnaryCompiledTaskFilter => {
  const compiled: UnaryCompiledTaskFilter = {
    operator: taskFilter.operator,
    type: 'UNARY',
  }

  if (taskFilter.taskIds !== undefined) {
    compiled.taskIds = compileInclusionMap(taskFilter.taskIds)
  }

  if (taskFilter.accepted !== undefined) {
    compiled.accepted = taskFilter.accepted
  }

  if (taskFilter.assignedUserIds) {
    compiled.assignedUserIds = compileInclusionSet(taskFilter.assignedUserIds)
  }

  if (taskFilter.completed !== undefined) {
    compiled.completed = taskFilter.completed
  }

  if (taskFilter.channelIds) {
    compiled.channelIds = compileInclusionMap(taskFilter.channelIds)
  }

  if (taskFilter.createdDateRange) {
    if (isString(taskFilter.createdDateRange)) {
      compiled.createdDateRange = getPastDateBounds(taskFilter.createdDateRange)
    } else {
      compiled.createdDateRange = taskFilter.createdDateRange as DateRange
    }
  }

  if (taskFilter.creatorUserIds) {
    compiled.creatorUserIds = compileInclusionSet(taskFilter.creatorUserIds)
  }

  if (taskFilter.dueDateRange) {
    if (isString(taskFilter.dueDateRange)) {
      compiled.dueDateRange = getFutureDateBounds(taskFilter.dueDateRange)
    } else {
      compiled.dueDateRange = taskFilter.dueDateRange as DateRange
    }
  }

  if (taskFilter.dueDateTypes) {
    compiled.dueDateTypes = compileInclusionSet(taskFilter.dueDateTypes)
  }

  if (taskFilter.spaceIds) {
    compiled.spaceIds = compileInclusionSet(taskFilter.spaceIds)
  }

  if (taskFilter.urgencies) {
    compiled.urgencies = compileInclusionSet(taskFilter.urgencies)
  }

  compiled.taskList = taskFilter.taskList

  if (taskFilter.creationSources) {
    compiled.creationSources = compileInclusionSet(taskFilter.creationSources)
  }

  if (taskFilter.boardIds) {
    compiled.boardIds = compileInclusionMap(taskFilter.boardIds)
  }

  if (taskFilter.tagIds) {
    compiled.tagIds = compileInclusionMap(taskFilter.tagIds)
  }

  if (taskFilter.watchingUserIds) {
    compiled.watchingUserIds = compileInclusionSet(taskFilter.watchingUserIds)
  }

  compiled.isQuestion = taskFilter.isQuestion

  return compiled
}
