import React from 'react'
import { noOpWarn } from '@/core/logging'
import { CreateTaskFilterOptionContextType } from './types'

const defaultValue: CreateTaskFilterOptionContextType = {
  canCreate: () => null,
  onCreate: noOpWarn('CreateTaskFilterOptionContext not available'),
}

const CreateTaskFilterOptionContext = React.createContext(defaultValue)

export const useCreateTaskFilterOptionContext = () =>
  React.useContext(CreateTaskFilterOptionContext)

export default CreateTaskFilterOptionContext
