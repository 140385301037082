import React from 'react'
import { noOpWarn } from '@/core/logging'

export type MessagesListContextType = {
  showTask: (spaceId: number, taskId: number) => void
  showSummary: (spaceId: number, summaryId: number) => void
  showThread: (spaceId: number, threadId: number) => void
}

const noOp = noOpWarn('Messages list context not present')

const defaultContextType: MessagesListContextType = {
  showSummary: noOp,
  showTask: noOp,
  showThread: noOp,
}

const MessagesListContext = React.createContext(defaultContextType)

export const useMessagesListContext = () => React.useContext(MessagesListContext)

export default MessagesListContext
