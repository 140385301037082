import { DrawerContentComponentProps } from '@react-navigation/drawer'
import * as SplashScreen from 'expo-splash-screen'
import React from 'react'
import { useAppInitializationStatus } from '@/hooks/initializers'
import {
  RootDrawer as Drawer,
  rootScreenOptions as screenOptions,
} from '@/screens/NarrowScreen/routes'
import AccountManagement from './AccountManagement/lazy'
import AppDrawer from './AppDrawer'
import ArchivedItemsScreen from './ArchivedItemsScreen'
import AuthScreen from './AuthScreen/lazy'
import CreateSpaceScreen from './CreateSpaceScreen/lazy'
import GeneralCreateScreen from './GeneralCreateScreen'
import JoinSpaceScreen from './JoinSpaceScreen/lazy'
import MyDayScreen from './MyDayScreen/lazy'
import NoSpacesScreen from './NoSpacesScreen/lazy'
import { PartnerSignUpScreen, TeamsSignUpScreen } from './SignUpScreen/lazy'
import SpacesScreen from './SpacesScreen/lazy'

// Prevents an issue with the message cycle button. At some point having swipe enabled caused
// the keyboard to drop because of event bubbling
const drawerNavigatorScreenOptions = { swipeEnabled: false }

function NarrowScreen() {
  const status = useAppInitializationStatus()

  const renderDrawerContent = React.useCallback<
    (props: DrawerContentComponentProps) => React.ReactNode
  >(props => <AppDrawer {...props} />, [])

  const initialRoute = React.useMemo(() => {
    if (status === 'READY') {
      return 'spaces'
    }
    if (status === 'NO_SPACES') {
      return 'create-space'
    }
    return 'auth'
  }, [status])

  const isAuthenticated = status !== 'UNAUTHENTICATED'
  const showApp = status !== 'BOOTING'

  React.useEffect(() => {
    if (showApp) {
      setTimeout(() => SplashScreen.hideAsync(), 300)
    }
  }, [showApp])

  if (status === 'BOOTING') {
    return null
  }

  return (
    <Drawer.Navigator
      backBehavior="history"
      drawerContent={renderDrawerContent}
      initialRouteName={initialRoute}
      screenOptions={drawerNavigatorScreenOptions}
    >
      {isAuthenticated ? (
        <>
          <Drawer.Screen
            key="spaces"
            component={SpacesScreen}
            initialParams={{ spaceId: null }}
            name="spaces"
            options={screenOptions.spaces}
          />
          <Drawer.Screen
            key="account-management"
            component={AccountManagement}
            name="account-management"
            options={screenOptions.accountManagement}
          />
          <Drawer.Screen
            key="create-space"
            component={CreateSpaceScreen}
            name="create-space"
            options={screenOptions.createSpace}
          />
          <Drawer.Screen
            key="no-spaces"
            component={NoSpacesScreen}
            name="no-spaces"
            options={screenOptions.noSpaces}
          />
          <Drawer.Screen
            key="my-day"
            component={MyDayScreen}
            name="my-day"
            options={screenOptions.myDay}
          />
          <Drawer.Screen
            key="create"
            component={GeneralCreateScreen}
            name="create"
            options={screenOptions.create}
          />
          <Drawer.Screen
            key="archived-items"
            component={ArchivedItemsScreen}
            name="archived-items"
            options={screenOptions.archivedItems}
          />
        </>
      ) : null}
      <Drawer.Screen key="auth" component={AuthScreen} name="auth" options={screenOptions.auth} />
      <Drawer.Screen
        key="join-space"
        component={JoinSpaceScreen}
        name="join-space"
        options={screenOptions.joinSpace}
      />
      <Drawer.Screen
        key="sign-up"
        component={PartnerSignUpScreen}
        name="sign-up"
        options={screenOptions.signUp}
      />
      <Drawer.Screen
        key="teams-sign-up"
        component={TeamsSignUpScreen}
        name="teams-sign-up"
        options={screenOptions.signUp}
      />
    </Drawer.Navigator>
  )
}

export default React.memo(NarrowScreen)
