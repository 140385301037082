import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { Board } from '@/types/entities'

export interface BoardResponse {
  board: Board
}

export interface BoardsResponse {
  boards: Board[]
}

export type CreateBoard = Pick<Board, 'name'>
export type UpdateBoard = Pick<Board, 'name'>

export const createBoard = (spaceId: number, channelId: number, board: CreateBoard) =>
  new Request<BoardResponse>({
    authentication: 'PRIVATE',
    body: board,
    method: 'POST',
    url: `v1/spaces/${spaceId}/channels/${channelId}/boards`,
  })

export const updateBoard = (spaceId: number, boardId: number, board: UpdateBoard) =>
  new Request<BoardResponse>({
    authentication: 'PRIVATE',
    body: board,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/boards/${boardId}`,
  })

export const deleteBoard = (spaceId: number, boardId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/boards/${boardId}`,
  })

export interface ListParams {
  updatedSince?: string | null
  name?: string | null
  active?: boolean
}

export const getBoardsBySpaceList = (
  spaceId: number,
  { updatedSince }: ListParams,
  paging: PagingRequest,
) =>
  new Request<BoardsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['updatedSince', updatedSince]],
    url: `v1/spaces/${spaceId}/boards`,
  })

export const getBoardsByChannelList = (
  spaceId: number,
  channelId: number,
  { updatedSince }: ListParams,
  paging: PagingRequest,
) =>
  new Request<BoardsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['updatedSince', updatedSince]],
    url: `v1/spaces/${spaceId}/channels/${channelId}/boards`,
  })
