import React from 'react'
import RTMContext from '@/contexts/RTMContext'
import { useRTM } from './hooks'

interface RTMContainerProps {
  children: React.ReactNode
}

function RTMContainer({ children }: RTMContainerProps) {
  const { webSocketManager } = useRTM()
  return <RTMContext.Provider value={webSocketManager}>{children}</RTMContext.Provider>
}

export default React.memo(RTMContainer)
