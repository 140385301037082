import React from 'react'
import Calendar from '@/components/core/Calendar'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import DateOptionsList from './DateOptionsList'
import DueDateIndicator from './DueDateIndicator'
import QuickDateButtonsList from './QuickDateButtonsList'
import { useDateSelector } from './hooks'

interface TaskDatesModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskDatesModal({ isOpen, onClose, localModal = false }: TaskDatesModalProps) {
  const {
    dueDateType,
    selectedDate,
    selectedRemindAt,
    selectedRemindAtOffset,
    handleRemindAtChange,
    handleRemoveDueDate,
    handleCancel,
    handleDone,
    handleSelectedDateChange,
    recurrenceRule,
    handleRecurrenceRuleChange,
  } = useDateSelector(onClose, isOpen)

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      minHeight={0}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Due Date"
    >
      <Box gapAll="medium">
        <DueDateIndicator
          dueDate={selectedDate}
          dueDateType={dueDateType}
          onRemove={handleRemoveDueDate}
        />
        <QuickDateButtonsList
          dueDateType={dueDateType}
          onSelectDate={handleSelectedDateChange}
          selectedDate={selectedDate}
        />
        <Calendar onSelectedDateChange={handleSelectedDateChange} selectedDate={selectedDate} />
      </Box>
      <DateOptionsList
        localModal={localModal}
        onDateChange={handleSelectedDateChange}
        onRecurrenceRuleChange={handleRecurrenceRuleChange}
        onRemindAtChange={handleRemindAtChange}
        recurrenceRule={recurrenceRule}
        selectedDate={selectedDate}
        selectedRemindAt={selectedRemindAt}
        selectedRemindAtOffset={selectedRemindAtOffset}
      />
    </StandardModal>
  )
}

export default React.memo(TaskDatesModal)
