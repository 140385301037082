import React from 'react'
import Text from '@/components/core/Text'
import { Message } from '@/types/entities'

interface MessageReactionsListProps {
  message: Message
}

function MessageReactionsList({ message }: MessageReactionsListProps) {
  if (!message.reactions) {
    return null
  }

  const reactions = Object.values(message.reactions)

  return <Text size="h3">{reactions.join(' ')}</Text>
}

export default React.memo(MessageReactionsList)
