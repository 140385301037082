import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { PhoneNumber } from '@/types/entities'

export interface PhoneNumbersResponse {
  phoneNumbers: PhoneNumber[]
}

export interface PhoneNumberResponse {
  phoneNumber: PhoneNumber
}

export const getPhoneNumbersList = (spaceId: number, paging: PagingRequest) =>
  new Request<PhoneNumbersResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/spaces/${spaceId}/phone-numbers`,
  })

export const createPhoneNumber = (spaceId: number, userPhoneNumber: string) =>
  new Request<PhoneNumberResponse>({
    authentication: 'PRIVATE',
    body: { userPhoneNumber },
    method: 'POST',
    url: `v1/spaces/${spaceId}/phone-numbers`,
  })

export const sendPhoneNumberVerificationCode = (phoneNumberId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'POST',
    url: `v1/phone-numbers/${phoneNumberId}/send-verification`,
  })

export const verifyPhoneNumber = (phoneNumberId: number, verificationCode: string) =>
  new Request<PhoneNumberResponse>({
    authentication: 'PRIVATE',
    body: { verificationCode },
    method: 'PUT',
    url: `v1/phone-numbers/${phoneNumberId}/verify`,
  })

export const deletePhoneNumber = (phoneNumberId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/phone-numbers/${phoneNumberId}`,
  })
