import React from 'react'
import Text from '@/components/core/Text'
import FormSelector from '@/components/form/FormSelector'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { FormUsersSelector } from '@/components/users/UsersSelector'
import { forms } from '@/core/constants'
import { useAppDispatch, useFormValue } from '@/hooks'
import { formActions } from '@/store/actions'
import { entitySelectors } from '@/store/selectors'
import { channelThunks, entityThunks } from '@/thunks'
import { ChannelMode, ResolvedChannel } from '@/types/entities'
import { Action } from '@/types/generics'

interface CreateChannelModalProps {
  defaultChannelMode?: ChannelMode
  onClose: () => void
  onCreate?: Action<ResolvedChannel>
  spaceId: number
}

const formName = forms.CREATE_CHANNEL
const channelModeChoices = [
  { label: 'Public Group', value: 'PUBLIC_GROUP' },
  { label: 'Private Group', value: 'PRIVATE_GROUP' },
  { label: 'Direct Message', value: 'DIRECT_MESSAGE' },
]

const publicDescription = 'Public groups can be searched for and joined without invitation.'
const privateDescription =
  'Private groups do not show up in search results and can only be joined at the invitation of a member.'
const closedDescription =
  'Direct messages are private channels whose membership cannot change over time. They are not given a name but are identified by the members.'

function CreateChannelModal({
  defaultChannelMode = 'PUBLIC_GROUP',
  onClose,
  onCreate,
  spaceId,
}: CreateChannelModalProps) {
  const dispatch = useAppDispatch()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const channelMode = useFormValue(formName, 'channelMode')
  const description = (() => {
    if (channelMode === 'DIRECT_MESSAGE') {
      return closedDescription
    }
    if (channelMode === 'PRIVATE_GROUP') {
      return privateDescription
    }
    return publicDescription
  })()

  const handleSubmit = React.useCallback(async () => {
    setIsSubmitting(true)
    const newChannel = await dispatch(channelThunks.createChannelForm(spaceId, formName))
    setIsSubmitting(false)

    if (newChannel) {
      const resolvedChannel = dispatch(
        entityThunks.select(
          entitySelectors.resolvedChannelSelector(newChannel.spaceId, newChannel.id),
        ),
      )
      if (resolvedChannel) {
        onCreate?.(resolvedChannel)
      }
      onClose()
    }
  }, [spaceId, dispatch, onCreate, onClose])

  React.useEffect(() => {
    dispatch(
      formActions.initialize({
        fields: { channelMode: defaultChannelMode, name: '', userIds: [] },
        formName,
      }),
    )
  }, [dispatch, defaultChannelMode])

  const title = `Create Channel`

  return (
    <ModalLayout
      leftAction={{ label: 'Cancel', onAction: onClose, type: 'linkPrimary' }}
      onCancel={onClose}
      rightAction={{
        disabled: isSubmitting,
        label: 'Create',
        onAction: handleSubmit,
        type: 'linkPrimary',
      }}
      title={title}
    >
      <Box gapAll="medium" gapBottom="none">
        <Box alignItems="center" direction="row" justifyContent="flex-start">
          <FormSelector
            choices={channelModeChoices}
            fieldName="channelMode"
            formName={formName}
            label="Channel Type"
          />
        </Box>
        <Box gapTop="small">
          <Text>{description}</Text>
        </Box>
        {channelMode !== 'DIRECT_MESSAGE' ? (
          <Box gapTop="medium">
            <FormTextInput
              autoCapitalize="words"
              autoCorrect={false}
              autoFocus
              fieldName="name"
              formName={formName}
              label="Channel Name"
            />
          </Box>
        ) : null}
      </Box>
      <Box gapAll="medium">
        <Text size="h3">Include Users</Text>
      </Box>
      <Box flex={1} gapBottom="medium">
        <FormUsersSelector excludeMe fieldName="userIds" formName={formName} spaceId={spaceId} />
      </Box>
    </ModalLayout>
  )
}

export default React.memo(CreateChannelModal)
