import React from 'react'
import Box from '@/components/layouts/Box'
import { useCachedSelector } from '@/hooks'
import { messageSelectors } from '@/store/selectors'
import { SummaryItem } from '@/types/entities'
import CommentListItem from './CommentListItem'

interface CommentsListProps {
  summaryItem: SummaryItem
}

function CommentsList({ summaryItem }: CommentsListProps) {
  const { spaceId, threadId } = summaryItem
  const messages = useCachedSelector(messageSelectors.byThreadIdSelector, [
    spaceId,
    threadId || 0,
    false,
  ])

  if (messages.length === 0) {
    return null
  }

  return (
    <>
      {messages.map(message => (
        <Box key={message.id} gapTop="medium">
          <CommentListItem message={message} />
        </Box>
      ))}
    </>
  )
}

export default React.memo(CommentsList)
