import React from 'react'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import { Channel } from '@/types/entities'
import CreateHubWithExistingUserContent from './CreateHubWithExistingUserContent'
import CreateJustYouHubContent from './CreateJustYouHubContent'
import HubModeSelectorContent from './HubModeSelectorContent'
import InviteUserAndCreateHubContent from './InviteUserAndCreateHubContent'
import { useCreateHubModal } from './hooks'

interface CreateHubModalProps {
  isOpen: boolean
  onClose: () => void
  onCreated: (channel: Channel) => void
  spaceId: number
}

function CreateHubModal({ isOpen, onClose, spaceId, onCreated }: CreateHubModalProps) {
  const {
    availableOptions,
    currentCreateHubMode,
    setSelectedCreateHubMode,
    activateSelectedCreateHubMode,
    noHubWithUsers,
    selectedCreateHubMode,
    resetToBeginning,
  } = useCreateHubModal(spaceId, isOpen)

  const isOnFirstStep = !currentCreateHubMode
  const onlyOneOption = availableOptions.length === 1

  const leftAction = React.useMemo(
    () =>
      isOnFirstStep || onlyOneOption
        ? ({
            label: 'Cancel',
            onAction: onClose,
            type: 'linkPrimary',
          } as const)
        : ({
            icon: 'chevron-left',
            onAction: resetToBeginning,
            type: 'transparentPrimary',
          } as const),
    [isOnFirstStep, onClose, resetToBeginning, onlyOneOption],
  )

  const rightAction = React.useMemo(
    () =>
      isOnFirstStep
        ? ({
            disabled: !selectedCreateHubMode,
            label: 'Next',
            onAction: activateSelectedCreateHubMode,
            type: 'linkPrimary',
          } as const)
        : ({
            icon: 'xmark',
            onAction: onClose,
            type: 'transparentDark',
          } as const),
    [isOnFirstStep, onClose, activateSelectedCreateHubMode, selectedCreateHubMode],
  )

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={leftAction}
      minHeight={0}
      rightAction={onlyOneOption ? undefined : rightAction}
      title={
        <Box alignItems="center">
          <Text align="center">Create Hub</Text>
          {!currentCreateHubMode ? (
            <Text color="shadow" size="h6">
              Select Option Below
            </Text>
          ) : null}
        </Box>
      }
    >
      {(() => {
        switch (currentCreateHubMode) {
          case 'INVITE_USER':
            return (
              <InviteUserAndCreateHubContent
                isOpen={isOpen}
                onClose={onClose}
                onCreated={onCreated}
                spaceId={spaceId}
              />
            )
          case 'EXISTING_USER':
            return (
              <CreateHubWithExistingUserContent
                onClose={onClose}
                onCreated={onCreated}
                spaceId={spaceId}
                users={noHubWithUsers}
              />
            )
          case 'JUST_YOU':
            return (
              <CreateJustYouHubContent onClose={onClose} onCreated={onCreated} spaceId={spaceId} />
            )
          default:
            return (
              <HubModeSelectorContent
                onSelect={setSelectedCreateHubMode}
                options={availableOptions}
                value={selectedCreateHubMode}
              />
            )
        }
      })()}
    </StandardModal>
  )
}

export default React.memo(CreateHubModal)
