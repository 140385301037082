import React from 'react'
import DropButton from '@/components/core/DropButton'
import ListItem from '@/components/core/ListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface CustomToggleProps {
  isOpen: boolean
  onToggle: () => void
}

function CustomToggle({ isOpen, onToggle }: CustomToggleProps) {
  return (
    <ListItem onPress={onToggle}>
      <Box flex={1}>
        <Text>Custom</Text>
      </Box>
      <DropButton open={isOpen} />
    </ListItem>
  )
}

export default React.memo(CustomToggle)
