import React from 'react'
import { StyleSheet, View } from 'react-native'
import FadeInOutContainer from '@/components/animated//FadeInOutContainer'
import Text from '@/components/core/Text'
import { getColor } from '@/core/constants/colors'

interface NewMessageBaggerProps {
  visible: boolean
}

function NewMessageBanner({ visible }: NewMessageBaggerProps) {
  return (
    <View style={styles.container}>
      <FadeInOutContainer animateInitialRender open={visible}>
        <View style={styles.line} />
        <View style={styles.text}>
          <Text color="red" size="h5">
            New
          </Text>
        </View>
      </FadeInOutContainer>
    </View>
  )
}

const styles = StyleSheet.create({
  container: { height: 10 },
  line: { borderTopColor: getColor('red'), borderTopWidth: 1, marginTop: 4 },
  text: {
    backgroundColor: getColor('ghost'),
    left: '50%',
    paddingLeft: 10,
    paddingRight: 10,
    position: 'absolute',
    transform: [{ translateY: -3 }, { translateX: -25 }],
  },
})

export default React.memo(NewMessageBanner)
