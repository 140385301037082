import React from 'react'
import { StyleSheet } from 'react-native'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import { ColorName, getColor } from '@/core/constants/colors'
import { ActionOptionType, AlertButton } from '@/types/actions'
import { FontWeight } from '@/types/typography'
import { CloseHandler } from './types'

type AlertButtonStyle = ActionOptionType | undefined

interface AlertModalButtonProps {
  button: AlertButton
  onClose: CloseHandler
}

function AlertModalButton({ button, onClose }: AlertModalButtonProps) {
  const { text, onPress, style: buttonType } = button
  const handlePress = React.useCallback(() => {
    onClose(onPress)
  }, [onPress, onClose])

  return (
    <Touchable feedback="opacity" onPress={handlePress} style={styles.button}>
      <Text color={getTextColor(buttonType)} weight={getTextWeight(buttonType)}>
        {text}
      </Text>
    </Touchable>
  )
}

const getTextColor = (buttonType: AlertButtonStyle): ColorName => {
  if (buttonType === 'destructive') {
    return 'red'
  }
  return 'emmre-blue'
}

const getTextWeight = (buttonType: AlertButtonStyle): FontWeight => {
  if (buttonType === 'destructive' || buttonType === 'primary') {
    return 'medium'
  }
  return 'regular'
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderTopColor: getColor('heather'),
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 15,
  },
})

export default React.memo(AlertModalButton)
