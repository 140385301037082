import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ColorName } from '@/core/constants/colors'
import Choice from './Choice'

export type ChoiceType<T extends string> = { value: T; label: string | React.ReactElement }

interface SimpleSelectorProps<T extends string> {
  choices: ChoiceType<T>[]
  disabled?: boolean
  onChange: (value: T) => void
  value: T | null
  selectedColor?: ColorName
}

function SimpleSelector<T extends string = string>({
  choices,
  disabled = false,
  onChange,
  value,
  selectedColor = 'emmre-blue',
}: SimpleSelectorProps<T>) {
  return (
    <View style={styles.container}>
      {choices.map(choice => (
        <Choice
          key={choice.value}
          disabled={disabled}
          isSelected={choice.value === value}
          label={choice.label}
          onSelect={onChange}
          selectedColor={selectedColor}
          value={choice.value}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
  },
})

export default React.memo(SimpleSelector) as typeof SimpleSelector
