import { combineReducers } from 'redux'
import activityReducer from './slices/activity'
import apiStatusReducer from './slices/apiStatus'
import appProfileReducer from './slices/appProfile'
import attachmentsReducer from './slices/attachments'
import authReducer from './slices/auth'
import entitiesReducer from './slices/entities'
import formsReducer from './slices/forms'
import notificationsReducer from './slices/notifications'
import { RESET_ALL } from './slices/reset'

const appReducer = combineReducers({
  activity: activityReducer,
  apiStatus: apiStatusReducer,
  appProfile: appProfileReducer,
  attachments: attachmentsReducer,
  auth: authReducer,
  entities: entitiesReducer,
  forms: formsReducer,
  notifications: notificationsReducer,
})

type AppReducer = typeof appReducer

const rootReducer: AppReducer = (state, action) => {
  if (action.type === RESET_ALL) {
    return appReducer(undefined, action)
  }
  const nextState = appReducer(state, action)
  return nextState
}

export default rootReducer
