import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import Checkbox from '@/components/core/Checkbox'
import TextInput from '@/components/core/TextInput'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import { isWeb } from '@/core/utils/platform'
import { ChecklistItem } from '@/types/entities'

interface ChecklistItemItemProps {
  canComplete: boolean
  canDelete: boolean
  canEdit: boolean
  index: number
  isLast: boolean
  item: ChecklistItem
  onDelete?: (index: number) => void
  onEdit?: (index: number, item: ChecklistItem) => void
  onSubmitEditing?: (index: number, item: ChecklistItem) => void
}

const hitSlop = { bottom: 5, left: 15, right: 15, top: 5 }

function ChecklistItemItem({
  canComplete,
  canDelete,
  canEdit,
  index,
  isLast,
  item,
  onDelete,
  onEdit,
  onSubmitEditing,
}: ChecklistItemItemProps) {
  const isFirst = index === 0
  const handleChangeText = React.useCallback(
    (description: string) => {
      if (canEdit) {
        onEdit?.(index, { ...item, description: description.replace(/\s+/g, ' ') })
      }
    },
    [onEdit, index, item, canEdit],
  )
  const handleChangeCompleted = React.useCallback(() => {
    if (canComplete) {
      onEdit?.(index, { ...item, completed: !item.completed })
    }
  }, [onEdit, index, item, canComplete])
  const handleDelete = React.useCallback(() => {
    if (canDelete) {
      onDelete?.(index)
    }
  }, [onDelete, canDelete, index])
  const handleSubmitEditing = React.useCallback(
    () => onSubmitEditing?.(index, item),
    [onSubmitEditing, index, item],
  )

  return (
    <Box
      alignItems="flex-start"
      direction="row"
      gapBottom="xsmall"
      gapTop={isFirst ? undefined : 'xsmall'}
    >
      <Box gapRight="medium">
        <Checkbox
          checked={item.completed}
          disabled={!canComplete}
          hitSlop={hitSlop}
          onChange={handleChangeCompleted}
        />
      </Box>
      <Box flex={1} gapTop={1}>
        {canEdit ? (
          <TextInput
            autoFocus={isLast && !item.description}
            blurOnSubmit={isWeb}
            expandable
            kind="bare"
            maxHeight={54}
            multiline
            onChangeText={handleChangeText}
            onSubmitEditing={handleSubmitEditing}
            placeholder="Next step"
            returnKeyType="next"
            value={item.description}
          />
        ) : (
          <TextWithLinks>{item.description}</TextWithLinks>
        )}
      </Box>
      {canDelete ? (
        <BaseIconButton
          color="obsidian"
          hitSlop="small"
          name="xmark"
          onPress={handleDelete}
          padding={0}
          size="h4"
        />
      ) : null}
    </Box>
  )
}

export default React.memo(ChecklistItemItem)
