import React from 'react'
import Text from '@/components/core/Text'
import { platformUtils } from '@/core/utils'

interface NewlineHelperProps {
  show: boolean
}

function NewlineHelper({ show }: NewlineHelperProps) {
  return (
    <Text color="iron" size="h6">
      {platformUtils.isWeb && show ? (
        <>
          <Text color="iron" size="h6" weight="medium">
            Shift + Return
          </Text>{' '}
          to add a new line
        </>
      ) : (
        ' '
      )}
    </Text>
  )
}

export default React.memo(NewlineHelper)
