import { camelizeKeys } from 'humps'
import {
  CommonFetchResponse,
  Errors,
  MappedResponse,
  RequestDescriptor,
  ResolvedResponse,
} from '@/types/api'
import { appJSON, requestExceptionResponse } from './constants'
import { getPaging, logRequestException, parseRawHeaders } from './utils'

export const mapResponse = async <T>(response: Response): Promise<ResolvedResponse<T>> => {
  const rawBody = await response.text()

  return mapCommonFetchResponse({
    body: rawBody,
    contentType: response.headers.get('Content-Type'),
    headers: response.headers,
    ok: response.ok,
    status: response.status,
    statusText: response.statusText,
  })
}

export const mapXHRResponse = <T>(xhr: XMLHttpRequest): ResolvedResponse<T> => {
  const ok = xhr.status < 300 && xhr.status >= 200

  return mapCommonFetchResponse({
    body: xhr.response,
    contentType: xhr.getResponseHeader('Content-Type'),
    headers: parseRawHeaders(xhr.getAllResponseHeaders()),
    ok,
    status: xhr.status,
    statusText: xhr.statusText,
  })
}

const mapCommonFetchResponse = <T>(response: CommonFetchResponse): ResolvedResponse<T> => {
  if (response.ok) {
    if (response.contentType !== appJSON) {
      throw new Error(`Unable to handle response content type ${response.contentType}`)
    }
    return {
      data: camelizeKeys(JSON.parse(response.body)) as unknown as T,
      ok: true,
      paging: getPaging(response.headers),
      rawBody: response.body,
      type: 'SUCCESS',
    }
  }

  if (response.contentType === appJSON) {
    return {
      errors: camelizeKeys(JSON.parse(response.body)) as unknown as Errors,
      ok: false,
      rawBody: response.body,
      status: response.status,
      type: 'ERROR',
    }
  }

  return {
    errors: { '': [response.statusText] } as Errors,
    ok: false,
    rawBody: response.body,
    status: response.status,
    type: 'ERROR',
  }
}

export const resolveResponse = <T>(
  request: RequestDescriptor,
  response: MappedResponse<T>,
): ResolvedResponse<T> => {
  if (response.type === 'EXCEPTION') {
    logRequestException(request, response.exception)
    return requestExceptionResponse
  }
  return response
}
