import React from 'react'
import { ScrollView } from 'react-native'
import { eventTypes } from '@/core/constants'
import { useSubscription } from '@/hooks/pubsub'
import { ItemType, VisibleItemsChangedHandler } from './types'

export const useScrollViewScrollOnMessageSend = (listRef: React.RefObject<ScrollView | null>) => {
  const scrollToBottom = React.useCallback(
    () => setTimeout(() => listRef.current?.scrollTo({ animated: true, y: 0 })),
    [listRef],
  )
  useSubscription(eventTypes.MESSAGE_SENT, scrollToBottom)
}

export const useScrollListVisibleItemsChanged = (
  onVisibleItemsChanged: VisibleItemsChangedHandler,
  firstUnreadMessageId: number | undefined,
) => {
  // FlatList doesen't like it if you change the viewableItemsChanged handler on the fly, so we
  // use refs to make sure the handler never changes
  const firstUnreadMessageIdRef = React.useRef<number | undefined>(firstUnreadMessageId)
  const onVisibleItemsChangedRef = React.useRef(onVisibleItemsChanged)

  firstUnreadMessageIdRef.current = firstUnreadMessageId
  onVisibleItemsChangedRef.current = onVisibleItemsChanged

  const handleVisibleItemsChanged = React.useCallback((visibleItems: ItemType[]) => {
    onVisibleItemsChangedRef.current(visibleItems, firstUnreadMessageIdRef.current)
  }, [])

  return handleVisibleItemsChanged
}
