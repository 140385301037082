import React from 'react'
import { easeInOut } from '@/core/utils/animations'

type ToggleOptions = {
  animate?: boolean
}

const defaultOpts = {}

export const useToggle = (defaultValue = false, opts: ToggleOptions = defaultOpts) => {
  const [value, setValue] = React.useState<boolean>(defaultValue)
  const toggle = React.useCallback(
    (newVal?: any) => {
      if (opts.animate) {
        easeInOut()
      }
      if (newVal === true || newVal === false) {
        setValue(newVal)
      } else {
        setValue(val => !val)
      }
    },
    [opts, setValue],
  )

  return [value, toggle] as const
}

export const useSentinelEffect = <T>(callback: () => void, sentinelValue: T) => {
  const sentinelRef = React.useRef<T>(sentinelValue)

  React.useEffect(() => {
    if (sentinelValue !== sentinelRef.current) {
      sentinelRef.current = sentinelValue
      callback()
    }
  }, [sentinelValue, callback])
}

export const useUnmount = (callback: () => void) => {
  const callbackRef = React.useRef(callback)
  callbackRef.current = callback

  React.useEffect(
    () => () => {
      callbackRef.current()
    },
    [],
  )
}

export const useMount = (callback: () => any) => {
  const mountedRef = React.useRef(false)

  React.useEffect(() => {
    if (mountedRef.current) {
      return undefined
    }
    mountedRef.current = true
    return callback()
  }, [callback])
}

export const useExternalState = <T>(externalValue: T): [T, (t: T | ((old: T) => T)) => void] => {
  const [value, setValue] = React.useState(externalValue)
  React.useEffect(() => {
    setValue(externalValue)
  }, [setValue, externalValue])
  return [value, setValue]
}
