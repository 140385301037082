import React from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { UsersSelector } from '@/components/users/UsersSelector'
import { userUtils } from '@/core/utils'
import { useCopyToClipboard } from '@/hooks/clipboard'
import { useUser } from '@/hooks/users'
import { SpaceUser, Summary } from '@/types/entities'
import { ShareStatus } from './types'

interface ShareSummaryContentProps {
  message: string | null
  status: ShareStatus
  summary: Summary
  selectedUserId: number | null
  setSelectedUserId: (userId: number | null) => void
  onCreateSummaryShare: () => void
  url: string | null
}

function ShareSummaryContent({
  message,
  onCreateSummaryShare,
  selectedUserId,
  setSelectedUserId,
  status,
  summary,
  url,
}: ShareSummaryContentProps) {
  const { spaceId, channelId } = summary
  const selectedUserIds = React.useMemo(
    () => (selectedUserId ? [selectedUserId] : null),
    [selectedUserId],
  )
  const handleSelectUser = React.useCallback(
    (user: SpaceUser, selected: boolean) => setSelectedUserId(selected ? user.id : null),
    [setSelectedUserId],
  )
  const selectedUser = useUser(spaceId, selectedUserId || 0)
  const { handleCopyToClipboard, wasCopied } = useCopyToClipboard(url || '')

  if (status === 'INITIALIZING' || status === 'PENDING') {
    return (
      <Box alignItems="center" gapAll="large" justifyContent="center">
        <ActivityIndicator color="obsidian" size="large" />
      </Box>
    )
  }

  if (status === 'SELECT_USER') {
    return (
      <>
        <UsersSelector
          channelId={channelId}
          excludeMe
          onSetSelection={handleSelectUser}
          selectedUserIds={selectedUserIds}
          spaceId={spaceId}
        />
        <Box direction="row" gapTop="large" justifyContent="center">
          <Button
            disabled={!selectedUserId}
            kind="primary"
            label="Share"
            onPress={onCreateSummaryShare}
          />
        </Box>
      </>
    )
  }

  if (status === 'ERROR') {
    return (
      <Text color="red">{message || 'An error occurred when sharing the executive brief'}</Text>
    )
  }

  return (
    <Box>
      <Text>
        Share this link to give {selectedUser ? userUtils.getFirstName(selectedUser) : ''} access to
        this executive brief:
      </Text>
      <Box gapTop="medium">
        <Background borderRadius={8} color="heather">
          <Box gapAll="medium">
            <Text size="h5">{url}</Text>
          </Box>
        </Background>
      </Box>
      <Box gapTop="medium">
        {wasCopied ? (
          <Text color="shadow">link copied to clipboard</Text>
        ) : (
          <Link label="copy link" onPress={handleCopyToClipboard} />
        )}
      </Box>
    </Box>
  )
}

export default React.memo(ShareSummaryContent)
