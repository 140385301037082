import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { PhoneNumber } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const adapter = createEntityAdapter<PhoneNumber>({
  selectId: phoneNumber => phoneNumber.id,
  sortComparer: (a, b) => a.id - b.id,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/phoneNumbers',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.phoneNumbers)

const bySpaceIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, phoneNumbers =>
    phoneNumbers.filter(conn => conn.spaceId === spaceId),
  )

const selectors = {
  bySpaceIdSelector,
}

export { actions, selectors }
export default reducer
