import { ChargebeeEstimate, ChargebeeInvoice } from '@/types/chargebee'
import { ProductVersion } from '@/types/entities'

export const getInvoiceFromEstimate = (estimate: ChargebeeEstimate): ChargebeeInvoice =>
  estimate.invoiceEstimate || estimate.nextInvoiceEstimate
export const removeEmmrePrefix = (val: string) => val.replace(/^Emmre\s*/, '')

export const getPlanName = (productVersion: ProductVersion) => {
  if (productVersion === 'ASSISTANT_PRO') {
    return 'Assistant Pro'
  }
  if (productVersion === 'PARTNERSHIP') {
    return 'Partnership'
  }
  throw new Error(`plan name for ${productVersion} not implemented`)
}
