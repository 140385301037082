import React from 'react'
import { AppState, useWindowDimensions } from 'react-native'
import { sizes } from '@/core/constants'
import { fnUtils, platformUtils } from '@/core/utils'
import { ApplicationState } from '@/types/app'

const useAppState = (handler: (nextState: ApplicationState) => void) => {
  const switchHandler = React.useCallback(
    (nextAppState: string) => {
      if (nextAppState === 'active') {
        handler('foreground')
      } else if (nextAppState === 'background') {
        handler('background')
      }
    },
    [handler],
  )

  React.useEffect(() => {
    const handle = AppState.addEventListener('change', switchHandler)

    return () => {
      handle.remove()
    }
  }, [switchHandler])
}

export const useNativeAppForegrounded = platformUtils.isWeb
  ? fnUtils.noOp
  : (handler: () => void) =>
      useAppState(
        React.useCallback(
          appState => {
            if (appState === 'foreground') {
              handler()
            }
          },
          [handler],
        ),
      )

export const useAppForegrounded = (handler: () => void) =>
  useAppState(
    React.useCallback(
      appState => {
        if (appState === 'foreground') {
          handler()
        }
      },
      [handler],
    ),
  )

export const useIsAppForegrounded = () => {
  const [isForegrounded, setIsForegrounded] = React.useState(AppState.currentState === 'active')

  useAppState(
    React.useCallback(
      appState => {
        setIsForegrounded(appState === 'foreground')
      },
      [setIsForegrounded],
    ),
  )

  return isForegrounded
}

export const useIsWide = () => {
  const { width } = useWindowDimensions()

  return width >= sizes.wideWidth
}
