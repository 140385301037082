import { collectionUtils as C, dateUtils as D, userUtils } from '@/core/utils'
import { boardSelectors, formSelectors, tagSelectors, userSelectors } from '@/store/selectors'
import { Pair } from '@/types/generics'
import { SyncThunk } from '@/types/store'
import { callWithStatus, getAll } from '../utils'
import { getTasks } from './api'

export const getAllUpdatedTasks = (spaceId: number, limit = 100) =>
  callWithStatus(
    `spaces/${spaceId}/tasks`,
    timestamp => async dispatch => {
      const updatedSince = timestamp === null ? null : D.formatISO(timestamp)
      const ok = await dispatch(getAllTasks(spaceId, updatedSince, limit))
      return { success: ok }
    },
    D.toMilliseconds.days(6),
  )

const getAllTasks = (spaceId: number, updatedSince: string | null, limit = 100) =>
  getAll(
    (beforeTaskId: number | null) => getTasks(spaceId, { beforeTaskId, limit, updatedSince }),
    ({ tasks }) =>
      tasks.length < limit ? { done: true } : { done: false, marker: C.last(tasks).id },
    null,
    `spaces/${spaceId}/tasks?updatedSince=${updatedSince}`,
    25,
  )

export const taskToString =
  (formName: string, lineEnding = '\r'): SyncThunk<string> =>
  (dispatch, getState) => {
    const updates = formSelectors.values(getState(), formName)
    const { spaceId } = updates
    const board = boardSelectors.byId(getState(), spaceId || 0, updates.boardId || 0)
    const tags = tagSelectors.byIdsSelector(spaceId || 0, updates.tagIds || [])(getState())
    const creator = userSelectors.byId(getState(), spaceId || 0, updates.creatorId || 0)
    const assignee = userSelectors.byId(getState(), spaceId || 0, updates.assignedUserId || 0)

    const parts: Pair<string, string>[] = [
      ['Task Name', updates.content + (updates.completedAt ? ' (Completed)' : '')],
    ]

    if (creator) {
      parts.push(['Creator', userUtils.getFullName(creator)])
    }

    if (assignee) {
      parts.push(['Assigned to', userUtils.getFullName(assignee)])
    }

    parts.push(['Priority', updates.urgency])

    if (updates.notes) {
      parts.push(['Notes', updates.notes])
    }

    if (updates.dueDate) {
      const dueDate = D.tryParse(updates.dueDate)
      if (dueDate) {
        parts.push(['Due', D.formatDateTime(dueDate)])
      }
    }

    if (board) {
      parts.push(['Project', board.name])
    }

    if (tags && tags.length !== 0) {
      parts.push(['Tags', tags.map(tag => tag.name).join(', ')])
    }

    return parts.map(([label, value]) => `${label}: ${value}`).join(lineEnding)
  }
