import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { numberUtils as N } from '@/core/utils'

interface RowProps {
  color: ColorName
  label: string
  amount: number
  negate?: boolean
  bold?: boolean
  amountWidth?: number
  border?: boolean
}

function Row({ border = false, color, label, amount, amountWidth = 70, negate, bold }: RowProps) {
  const content = (
    <Box direction="row" gapTop="xsmall">
      <Box flex={1} gapRight="medium" justifyContent="flex-end">
        <Text color={color} weight={bold ? 'medium' : undefined}>
          {label}
        </Text>
      </Box>
      <Box direction="row" justifyContent="flex-end" width={amountWidth}>
        <Text color={color} weight={bold ? 'medium' : undefined}>
          {negate ? '-' : ''}${N.centsToDollars(amount)}
        </Text>
      </Box>
    </Box>
  )

  return border ? (
    <Box gapTop="xsmall">
      <Background borderColor={color} borderTopWidth={1}>
        {content}
      </Background>
    </Box>
  ) : (
    content
  )
}

export default React.memo(Row)
