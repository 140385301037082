import { Request } from '@/api/call'
import { Myself, Scope } from '@/types/entities'

export interface AccessResponse {
  accessToken: string
  expiresAt: number
  refreshToken: string
  user: Myself
  scopes: Scope[] | null
}

export type SignInArgs =
  | {
      emailAddress: string
      password: string
    }
  | { token: string }

export const signIn = (body: SignInArgs) =>
  new Request<AccessResponse>({
    authentication: 'PUBLIC',
    body,
    method: 'POST',
    url: 'v1/auth/token',
  })

export const refreshAccess = (refreshToken: string) =>
  new Request<AccessResponse>({
    authentication: 'PUBLIC',
    body: {
      refreshToken,
    },
    method: 'POST',
    url: 'v1/auth/refresh',
  })
