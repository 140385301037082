import React from 'react'
import { TaskActivityType } from '@/types/entities'
import ActivityLabel from './ActivityLabel'

interface TaskActivityIndicatorProps {
  spaceId: number
  creatorId: number
  taskActivityType?: TaskActivityType
  isFromMe: boolean
}

function TaskActivityIndicator({
  spaceId,
  creatorId,
  isFromMe,
  taskActivityType,
}: TaskActivityIndicatorProps) {
  if (!taskActivityType) {
    return null
  }

  const action = getActionDescription(taskActivityType)

  return (
    <ActivityLabel action={action} creatorId={creatorId} isFromMe={isFromMe} spaceId={spaceId} />
  )
}

const getActionDescription = (activityType: TaskActivityType): string => {
  switch (activityType) {
    case 'ACCEPTED':
      return 'accepted this task'
    case 'UNACCEPTED':
      return 'unaccepted this task'
    case 'COMPLETED':
      return 'completed this task'
    case 'UNCOMPLETED':
      return 'uncompleted this task'
    case 'MOVED':
      return 'moved this task'
    case 'DELETED':
      return 'deleted this task'
    case 'REASSIGNED':
      return 'reassigned this task'
    case 'EDITED':
      return 'edited this task'
    case 'ANSWERED':
      return 'answered this question'
    default:
      throw new Error(`Invalid task activity type: ${activityType}`)
  }
}

export default React.memo(TaskActivityIndicator)
