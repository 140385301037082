import React from 'react'
import { TaskModalContainer } from '@/components/tasks/TaskForm'
import { useTaskForm, useTaskFormActions } from '@/components/tasks/TaskForm/hooks'
import TaskFormContext, { TaskFormContextType } from '@/contexts/TaskFormContext'
import { fnUtils, taskUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import { Task } from '@/types/entities'
import { TaskDetail, TaskType } from '@/types/tasks'

interface EditTaskContainerProps {
  task?: Task
  taskDetail?: TaskDetail
  onDone: () => void
}

function EditTaskContainer({ task, taskDetail, onDone }: EditTaskContainerProps) {
  const [initializedTaskId, setInitializedTaskId] = React.useState<number | null>(null)

  const taskType: TaskType = (task && taskUtils.getTaskType(task)) || 'TASK'

  const { formName, interactionMode, resetForm, questionMode, setQuestionMode } = useTaskForm({
    formContext: 'task',
    shouldInitializeForm: false,
    spaceId: task?.spaceId || 0,
    taskId: task?.id,
  })
  const { handleSubmit, handleCancel } = useTaskFormActions({
    checkNotifyOnCreate: false,
    formName,
    interactionMode,
    onClose: fnUtils.noOp,
    taskId: task?.id,
    taskType,
  })
  const contextValue = React.useMemo<TaskFormContextType>(
    () => ({
      canClose: true,
      canSubmit: true,
      cancel: fnUtils.noOp,
      close: fnUtils.noOp,
      formName,
      interactionMode,
      questionMode,
      setQuestionMode,
      setTaskFormSize: undefined,
      showDetail: fnUtils.noOp,
      submit: fnUtils.alwaysAsync(false),
      submitDisabled: fnUtils.noOp,
      task,
      taskFormSize: 'LARGE',
      taskType,
    }),
    [formName, task, interactionMode, taskType, questionMode, setQuestionMode],
  )
  const handleCloseTaskDetail = React.useCallback(
    async (cancelled: boolean) => {
      if (cancelled) {
        handleCancel()
      } else {
        easeInOut()
        await handleSubmit()
      }
      onDone()
    },
    [handleCancel, handleSubmit, onDone],
  )

  // The context has to boot the form for the task, but that happens in a callback. By setting
  // initialize to false and manually initializing we are able to know exactly what the form is
  // initialized for.
  React.useEffect(() => {
    if (!task) {
      setInitializedTaskId(null)
    } else {
      resetForm()
      setInitializedTaskId(task.id)
    }
  }, [task, setInitializedTaskId, resetForm])

  if (!(task && taskDetail)) {
    return null
  }

  if (initializedTaskId !== task.id) {
    return null
  }

  return (
    <TaskFormContext.Provider value={contextValue}>
      <TaskModalContainer onClose={handleCloseTaskDetail} taskDetail={taskDetail} />
    </TaskFormContext.Provider>
  )
}

export default React.memo(EditTaskContainer)
