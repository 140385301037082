import React from 'react'
import { useTaskFormField } from '@/components/tasks/TaskForm/hooks'
import { QuestionResponseType } from '@/types/entities'

const emptyResponses: string[] = []
const maxResponses = 5

export const useForm = () => {
  const { value: questionIsOpenEnded, setValue: setQuestionIsOpenEnded } =
    useTaskFormField<boolean>('questionIsOpenEnded')
  const { value: formResponses, setValue: setQuestionResponses } =
    useTaskFormField<string[]>('questionResponses')
  const { value: questionResponseType, setValue: setQuestionResponseType } =
    useTaskFormField<QuestionResponseType>('questionResponseType')
  const questionResponses = formResponses || emptyResponses
  const canAddResponse = questionResponses.length < maxResponses

  const addNewResponse = React.useCallback(() => {
    if (canAddResponse) {
      setQuestionResponses([...questionResponses.map(response => response.trim()), ''])
    }
  }, [canAddResponse, questionResponses, setQuestionResponses])

  const updateResponseValue = React.useCallback(
    (indexToUpdate: number, newValue: string) => {
      setQuestionResponses(
        questionResponses.map((value, index) =>
          index === indexToUpdate ? newValue.trimStart().replace(/\s+/g, ' ') : value,
        ),
      )
    },
    [questionResponses, setQuestionResponses],
  )

  const removeResponse = React.useCallback(
    (indexToRemove: number) => {
      setQuestionResponses(questionResponses.filter((value, index) => index !== indexToRemove))
    },
    [setQuestionResponses, questionResponses],
  )

  const handleQuestionResponseTypeChange = React.useCallback(
    (checked: boolean, state: string) => {
      if (checked) {
        setQuestionResponseType(state === 'SINGLE' ? 'SINGLE' : 'MULTIPLE')
      }
    },
    [setQuestionResponseType],
  )

  const toggleQuestionIsOpenEnded = React.useCallback(() => {
    setQuestionIsOpenEnded(!questionIsOpenEnded)
  }, [questionIsOpenEnded, setQuestionIsOpenEnded])

  return {
    addNewResponse,
    canAddResponse,
    questionIsOpenEnded,
    questionResponseType,
    questionResponses,
    removeResponse,
    setQuestionResponseType: handleQuestionResponseTypeChange,
    toggleQuestionIsOpenEnded,
    updateResponseValue,
  }
}
