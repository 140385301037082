import React from 'react'
import { TasksListViewContextType } from '@/contexts/TasksListViewContext'
import { ColorName } from '@/core/constants/colors'
import { createdAtSort } from '@/core/constants/tasks'
import { easeInOut } from '@/core/utils/animations'
import { locateFilterOption, parseFilterOptionSelection } from '@/core/utils/tasks/filterGroups'
import { flattenFilter, getIncludedValue } from '@/core/utils/tasks/filtering'
import {
  buildNoRelationshipFilter,
  buildNoTaskTypeFilter,
  buildRelationshipFilter,
} from '@/core/utils/tasks/filters'
import { useAppDispatch, useCurrentUserId } from '@/hooks'
import { useChannel } from '@/hooks/channels'
import { useExternalState, useSentinelEffect } from '@/hooks/state'
import { useCheckNotify } from '@/hooks/tasks'
import { useBaseFilter, useRelationshipFields } from '@/hooks/tasks/filters'
import { taskThunks } from '@/thunks'
import { Task } from '@/types/entities'
import {
  FilterGroup,
  FilterOption,
  QueryKeys,
  TaskDetail,
  TaskGrouping,
  TaskQuery,
  TaskRelationship,
  TaskSort,
  TaskType,
  UnaryTaskFilter,
} from '@/types/tasks'
import { useFilterGroups } from './filters'

type TasksListViewState = {
  queryKeys: QueryKeys
  taskQuery: TaskQuery
  taskGrouping: TaskGrouping | null
  editTask?: Task
  editTaskDetail?: TaskDetail
}

type TasksListViewAction =
  | {
      type: 'setFilterOption'
      payload: { group: FilterGroup; option: FilterOption }
    }
  | { type: 'setEditTask'; payload: { task: Task; taskDetail: TaskDetail } }
  | { type: 'clearEditTask' }
  | { type: 'merge'; payload: TasksListViewState }

const tasksViewStateReducer = (
  state: TasksListViewState,
  action: TasksListViewAction,
): TasksListViewState => {
  const { type } = action

  if (type === 'merge') {
    return {
      ...state,
      ...action.payload,
    }
  }

  if (type === 'setFilterOption') {
    const { group, option } = action.payload

    return {
      ...state,
      ...groupAndOptionToState(group, option),
    }
  }

  if (type === 'setEditTask') {
    const { task, taskDetail } = action.payload
    return {
      ...state,
      editTask: task,
      editTaskDetail: taskDetail,
    }
  }

  if (type === 'clearEditTask') {
    return {
      ...state,
      editTask: undefined,
      editTaskDetail: undefined,
    }
  }

  return state
}

type TasksListViewHookArgs = {
  contextFilter?: UnaryTaskFilter
  groupSort: TaskSort[]
  initialTaskRelationship: TaskRelationship
  queryKey?: string
  showAllGroup: boolean
  spaceId: number
  taskType: TaskType
  taskBackground?: ColorName
  questionBackground?: ColorName
}

export const defaultGroupSort: TaskSort[] = [createdAtSort]

export const useTasksListView = ({
  contextFilter,
  groupSort,
  initialTaskRelationship,
  queryKey,
  showAllGroup,
  spaceId,
  taskType,
  taskBackground = 'heather',
  questionBackground = 'emmre-purple-9',
}: TasksListViewHookArgs) => {
  const currentUserId = useCurrentUserId() || 0
  const channelId =
    (contextFilter &&
      contextFilter.channelIds &&
      getIncludedValue(contextFilter.channelIds, null)?.[1]) ||
    null
  const baseFilter = useBaseFilter(
    spaceId,
    taskType,
    React.useMemo(() => buildNoTaskTypeFilter(contextFilter), [contextFilter]),
  )
  const [taskRelationship, setTaskRelationship] = useExternalState(initialTaskRelationship)
  const relationshipFields = useRelationshipFields(taskRelationship)
  const optionGroups = useFilterGroups({
    baseFilter,
    channelId,
    groupSort: groupSort || defaultGroupSort,
    relationshipFields,
    showAllGroup,
    spaceId,
  })
  const checkNotify = useCheckNotify()

  const defaultOption = React.useMemo(
    () => locateFilterOption(optionGroups, queryKey),
    [optionGroups, queryKey],
  )
  const initialState = React.useMemo<TasksListViewState>(() => {
    const { group, option } = defaultOption
    return groupAndOptionToState(group, option)
  }, [defaultOption])

  const [state, dispatch] = React.useReducer(tasksViewStateReducer, initialState)
  const dispatchRedux = useAppDispatch()
  const createFilter = React.useMemo(() => {
    const flattened = flattenFilter(state.taskQuery.filter)
    // If you're looking at the 'My Tasks' tab, then default tasks to you. Otherwise
    // use the default no assignee behavior which is to assign it to the other person
    // in the channel if there's only one other person, otherwise to you
    if (taskRelationship === 'MINE') {
      return buildRelationshipFilter(taskRelationship, currentUserId, flattened)
    }
    return buildNoRelationshipFilter(flattened)
  }, [state.taskQuery.filter, taskRelationship, currentUserId])

  const setFilterOption = React.useCallback(
    (group: FilterGroup, option: FilterOption) => {
      easeInOut()
      dispatch({ payload: { group, option }, type: 'setFilterOption' })
    },
    [dispatch],
  )

  const setDefaultFilterOption = React.useCallback(
    () => setFilterOption(defaultOption.group, defaultOption.option),
    [setFilterOption, defaultOption],
  )

  const editTask = React.useCallback(
    (task: Task, taskDetail: TaskDetail) =>
      dispatch({ payload: { task, taskDetail }, type: 'setEditTask' }),
    [dispatch],
  )

  const handleDoneEditingTask = React.useCallback(
    () => dispatch({ type: 'clearEditTask' }),
    [dispatch],
  )

  const changeTask = React.useCallback(
    async (taskSpaceId: number, taskId: number, changes: Partial<Task>) => {
      easeInOut()
      const notify = await checkNotify({ changes, spaceId: taskSpaceId, taskId })
      const response = await dispatchRedux(
        taskThunks.updateTask(taskSpaceId, taskId, changes, notify),
      )
      return response.ok
    },
    [dispatchRedux, checkNotify],
  )

  const tasksViewContextValue = React.useMemo<TasksListViewContextType>(
    () => ({
      changeTask,
      editTask,
      questionBackground,
      setFilterOption,
      taskBackground,
      taskRelationship,
    }),
    [setFilterOption, editTask, changeTask, taskRelationship, taskBackground, questionBackground],
  )

  // When the base filter changes we need to reset the selected filter
  // Unfortunately this will move the user from their current selection
  // which probably isn't right. Instead we should probably try to find
  // matching new option and select that?
  const baseFilterRef = React.useRef(baseFilter)
  const relationshipFieldsRef = React.useRef(relationshipFields)

  React.useEffect(() => {
    if (
      baseFilter !== baseFilterRef.current ||
      relationshipFields !== relationshipFieldsRef.current
    ) {
      dispatch({ payload: initialState, type: 'merge' })
      baseFilterRef.current = baseFilter
      relationshipFieldsRef.current = relationshipFields
    }
  }, [baseFilter, relationshipFields, dispatch, initialState])

  useSentinelEffect(setDefaultFilterOption, queryKey)

  const channel = useChannel(spaceId, channelId || 0)

  const channelHasExactlyTwoUsers = channel?.userIds.length === 2

  const shouldShowCreateButton =
    taskType === 'TASK' ||
    (taskType === 'QUESTION' && channelHasExactlyTwoUsers && taskRelationship === 'WATCHING')

  return {
    channelId,
    createFilter,
    editTask: state.editTask,
    editTaskDetail: state.editTaskDetail,
    handleDoneEditingTask,
    optionGroups,
    queryKeys: state.queryKeys,
    setTaskRelationship,
    shouldShowCreateButton,
    taskGrouping: state.taskGrouping,
    taskQuery: state.taskQuery,
    taskRelationship,
    tasksViewContextValue,
  }
}

const groupAndOptionToState = (group: FilterGroup, option: FilterOption): TasksListViewState => {
  const { filter, sorts, focus, grouping } = parseFilterOptionSelection(group, option)
  const queryKeys = { groupKey: group.key, optionKey: option.key }

  return {
    queryKeys,
    taskGrouping: grouping,
    taskQuery: {
      filter,
      focus,
      sorts,
    },
  }
}
