import * as Clipboard from 'expo-clipboard'
import { isWeb } from './platform'

export const writeToClipboard = async (value: string): Promise<boolean> => {
  if (isWeb) {
    try {
      await navigator.clipboard.writeText(value)
    } catch {
      return false
    }

    return true
  }

  Clipboard.setString(value)
  return true
}
