import React from 'react'
import { channelUtils } from '@/core/utils'
import { useAppDispatch, useAppSelector, useCachedSelector } from '@/hooks'
import { useIsWide } from '@/hooks/appState'
import { RootNavigationProp } from '@/screens/NarrowScreen/routes'
import { appProfileActions } from '@/store/actions'
import { channelSelectors, entitySelectors, userSelectors } from '@/store/selectors'
import { HubNamingMode } from '@/types/channels'
import { Channel, ChannelStatus, ChannelType, ResolvedChannel, SpaceUser } from '@/types/entities'
import { useCurrentUserId } from './auth'
import { useUser } from './users'

export const useChannel = (spaceId: number, channelId: number): Channel | undefined =>
  useAppSelector(channelSelectors.byId, spaceId, channelId)

export const useChannelName = (
  spaceId: number,
  channelId: number,
  hubNamingMode: HubNamingMode,
) => {
  const myId = useCurrentUserId()
  const usersIndex = useAppSelector(userSelectors.entities)
  const channel = useChannel(spaceId, channelId)

  if (!channel) {
    return 'No Channel'
  }

  return channelUtils.getChannelName(channel, myId || 0, usersIndex, hubNamingMode)
}

const useHubUserId = (userIds: number[]): number => {
  const myId = useCurrentUserId() || 0

  return channelUtils.getHubUserId(userIds, myId) || 0
}

export const useHubUser = (spaceId: number, userIds: number[]): SpaceUser | undefined => {
  const userId = useHubUserId(userIds)
  return useUser(spaceId, userId)
}

export const useHubUserByIds = (spaceId: number, channelId: number): SpaceUser | undefined => {
  const myId = useCurrentUserId() || 0
  const channel = useChannel(spaceId, channelId)
  const userId = (!!channel && channelUtils.getHubUserId(channel.userIds, myId)) || 0
  return useUser(spaceId, userId)
}

export const useHubUsers = (channel?: Channel) => {
  const myId = useCurrentUserId() || 0
  const [firstId, secondId] = channel?.userIds ?? []
  const firstUser = useUser(channel?.spaceId ?? 0, firstId || 0)
  const secondUser = useUser(channel?.spaceId ?? 0, secondId || 0)

  if (!channel) {
    return undefined
  }

  if (channel.userIds.length === 0 || channel.userIds.length > 2) {
    return undefined
  }

  return channelUtils.getHubUsers(myId, firstUser, secondUser)
}

export const useResolvedChannel = (
  spaceId: number,
  channelId: number,
): ResolvedChannel | undefined =>
  useCachedSelector(entitySelectors.resolvedChannelSelector, [spaceId, channelId])

export const usePersonalDefaultChannel = (spaceId: number): ResolvedChannel | undefined =>
  useCachedSelector(entitySelectors.defaultChannelSelector, [spaceId])

export const useChannelStatuses = (spaceId: number, hubs: boolean) =>
  useCachedSelector(entitySelectors.resolvedChannelsSelector, [
    React.useMemo(
      () => ({
        channelTypes: (hubs
          ? ['HUB']
          : ['PUBLIC_GROUP', 'PRIVATE_GROUP', 'DIRECT_MESSAGE']) as ChannelType[],
        spaceId,
        status: ['ARCHIVED', 'SILENCED'] as ChannelStatus[],
      }),
      [spaceId, hubs],
    ),
  ]).reduce((acc, channel) => acc.add(channel.status), new Set<ChannelStatus>())

export const useHubs = (spaceId: number, status: ChannelStatus, includeJustYouHub: boolean) => {
  const hubs = useCachedSelector(entitySelectors.resolvedChannelsSelector, [
    React.useMemo(
      () => ({
        channelTypes: 'HUB' as ChannelType,
        spaceId,
        status,
      }),
      [spaceId, status],
    ),
  ])
  return React.useMemo(
    () => (includeJustYouHub ? hubs : hubs.filter(hub => !channelUtils.isJustYouHub(hub))),
    [hubs, includeJustYouHub],
  )
}

export const useIsHubOnlyUser = (spaceId: number) =>
  useCachedSelector(entitySelectors.isHubOnlyUserSelector, [spaceId])

export const useNavigateToChannel = (navigation: RootNavigationProp<any>) => {
  const isWide = useIsWide()
  const dispatchRedux = useAppDispatch()

  const setChannelForWideScreen = React.useCallback(
    (channel: Channel) => {
      navigation.reset({ index: 0, routes: [] })
      navigation.navigate('spaces', { spaceId: channel.spaceId })
      dispatchRedux(
        appProfileActions.focusOnChannel({ channelId: channel.id, spaceId: channel.spaceId }),
      )
    },
    [dispatchRedux, navigation],
  )

  const setChannelForNarrowScreen = React.useCallback(
    (channel: Channel) => {
      navigation.reset({ index: 0, routes: [] })
      navigation.navigate('spaces', {
        params: { channelId: channel.id },
        screen: 'channel',
        spaceId: channel.spaceId,
      })
    },
    [navigation],
  )

  return React.useCallback(
    (channel: Channel) => {
      if (isWide) {
        setChannelForWideScreen(channel)
      } else {
        setChannelForNarrowScreen(channel)
      }
    },
    [setChannelForNarrowScreen, setChannelForWideScreen, isWide],
  )
}

export const useHasActiveChannels = (spaceId: number) => {
  const channelFilterConfig = React.useMemo(
    () =>
      ({
        alwaysShowUnread: true,
        channelTypes: ['PUBLIC_GROUP', 'PRIVATE_GROUP', 'DIRECT_MESSAGE'] as ChannelType[],
        spaceId,
        status: 'ACTIVE',
      } as const),
    [spaceId],
  )
  const channels = useCachedSelector(entitySelectors.resolvedChannelsSelector, [
    channelFilterConfig,
  ])

  return channels.length > 0
}
