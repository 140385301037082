import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { stringUtils } from '@/core/utils'

interface TaskCommentsToggleProps {
  isOpen: boolean
  onToggle: () => void
  messagesCount: number
}

function TaskCommentsToggle({ isOpen, onToggle, messagesCount }: TaskCommentsToggleProps) {
  let content: React.ReactNode

  if (isOpen) {
    content = (
      <Text color="iron" size="h6">
        Comments
      </Text>
    )
  } else {
    content = (
      <>
        <Text color="iron" size="h6">
          Comments:&nbsp;
        </Text>
        <Text color={messagesCount === 0 ? 'iron' : 'emmre-blue'} size="h6">
          {stringUtils.pluralize(messagesCount, 'Reply', 'Replies')}
        </Text>
      </>
    )
  }
  return (
    <Touchable feedback="highlight" onPress={onToggle}>
      <Background color="ghost">
        <Box alignItems="center" direction="row" gapLeft="medium" gapVertical="small">
          {content}
          <Box gapLeft="small">
            <Icon
              color="iron"
              kind="solid"
              name={isOpen ? 'chevron-down' : 'chevron-right'}
              size="sm"
            />
          </Box>
        </Box>
      </Background>
    </Touchable>
  )
}

export default React.memo(TaskCommentsToggle)
