import { createSelector } from 'reselect'
import { taskUtils } from '@/core/utils'
import { Selector } from '@/types/store'
import { IncludedTask, TaskFilter, TaskGroupingContext, TaskSort } from '@/types/tasks'
import { selectors as boardSelectors } from '../boards'
import { selectors as taskListItemSelectors } from '../taskListItems'
import { sortComparer, selectors as taskSelectors } from '../tasks'

export const relatedTaskFilterDataSelector = createSelector(
  taskListItemSelectors.taskOrderIndex,
  taskOrderIndex => new taskUtils.RelatedTaskData({ taskOrderIndex }),
)

export const tasksByFilterSelector = (taskFilter: TaskFilter, taskSorts?: TaskSort[]) =>
  createSelector(taskSelectors.all, relatedTaskFilterDataSelector, (tasks, relatedData) => {
    const filter = taskUtils.compileTaskFilter(relatedData, taskFilter)
    const filtered = tasks.filter(filter)
    if (taskSorts) {
      const compare = taskUtils.compileTaskSorts(relatedData, taskSorts)
      filtered.sort(compare)
    }

    return filtered
  })

export const tasksByFilterAndInclusionSetSelector = (
  taskFilter: TaskFilter,
  taskSorts?: TaskSort[],
  includeKeys?: Set<string>,
) =>
  createSelector(
    taskSelectors.entities,
    relatedTaskFilterDataSelector,
    (taskIndex, relatedData) => {
      const filter = taskUtils.compileTaskFilter(relatedData, taskFilter)
      const extraKeys = includeKeys || new Set()

      const filtered: IncludedTask[] = []

      Object.entries(taskIndex).forEach(([key, task]) => {
        if (!task) {
          return
        }
        if (filter(task)) {
          filtered.push({ inclusion: 'direct', key, task })
        } else if (extraKeys.has(key)) {
          filtered.push({ inclusion: 'indirect', key, task })
        }
      })

      const compare = taskSorts ? taskUtils.compileTaskSorts(relatedData, taskSorts) : sortComparer
      filtered.sort((a: IncludedTask, b: IncludedTask) => compare(a.task, b.task))

      return filtered
    },
  )

export const countTasksByFilterSelector = (taskFilter: TaskFilter) =>
  createSelector(taskSelectors.all, relatedTaskFilterDataSelector, (tasks, relatedData) =>
    taskUtils.countIncludedTasks(taskFilter, relatedData, tasks),
  )

export const taskGroupingContextSelector = (): Selector<TaskGroupingContext> =>
  createSelector(boardSelectors.entities, boards => ({ boards }))
