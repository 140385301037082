import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import {
  addItemToIndex,
  buildIndexKey,
  indexItems,
  removeItemFromIndex,
} from '@/core/utils/taskLists'
import { RootState } from '@/store'
import { TaskListItem } from '@/types/entities'
import { Predicate } from '@/types/generics'
import { ListsIndex, TaskOrderIndex } from '@/types/taskLists'

type Items = Record<number, TaskListItem>

interface TaskListItemsState {
  items: Items
  tasksIndex: TaskOrderIndex
}

const initialState: TaskListItemsState = {
  items: {},
  tasksIndex: {},
}

const taskListItemsSlice = createSlice({
  initialState,
  name: 'taskListItems',
  /* eslint-disable no-param-reassign */
  reducers: {
    addItem: (state, action: PayloadAction<{ item: TaskListItem }>) => {
      const { items, tasksIndex } = state
      const { item } = action.payload

      const nextItems = { ...items, [item.id]: item }
      const nextTasksIndex = addItemToIndex(tasksIndex, item)

      return {
        ...state,
        items: nextItems,
        tasksIndex: nextTasksIndex,
      }
    },
    addItems: (state, action: PayloadAction<{ items: TaskListItem[] }>) => {
      const { items, tasksIndex } = state
      const { items: addItems } = action.payload

      const nextItems = {
        ...items,
        ...Object.fromEntries(addItems.map(item => [item.id, item])),
      }

      const nextTasksIndex = addItems.reduce(
        (index: TaskOrderIndex, item: TaskListItem) => addItemToIndex(index, item),
        tasksIndex,
      )

      return {
        items: nextItems,
        tasksIndex: nextTasksIndex,
      }
    },
    removeItem: (state, action: PayloadAction<{ itemId: number }>) => {
      const { items, tasksIndex } = state
      const { itemId } = action.payload
      const item = items[itemId]

      if (!item) {
        return state
      }

      const nextItems = { ...items }
      delete nextItems[item.id]

      const nextTasksIndex = removeItemFromIndex(tasksIndex, item)

      return {
        ...state,
        items: nextItems,
        tasksIndex: nextTasksIndex,
      }
    },
    removeWhere: (state, action: PayloadAction<{ predicate: Predicate<TaskListItem> }>) => {
      const { predicate } = action.payload
      const { items } = state
      const keepItems = Object.values(items).filter(predicate)
      const nextItems = Object.fromEntries(keepItems.map(item => [item.id, item]))
      const nextTasksIndex = indexItems(keepItems)

      return {
        ...state,
        items: nextItems,
        tasksIndex: nextTasksIndex,
      }
    },
    setItemsForList: (
      state: TaskListItemsState,
      action: PayloadAction<{ taskListId: number; items: TaskListItem[] }>,
    ) => {
      const { taskListId, items } = action.payload

      const nextItems = [
        ...Object.values(state.items).filter(item => item.taskListId !== taskListId),
        ...items,
      ]
      // Rather than updating the index just rebuild it completely
      const nextTasksIndex = indexItems(nextItems)

      return {
        ...state,
        items: Object.fromEntries(nextItems.map(item => [item.id, item])),
        tasksIndex: nextTasksIndex,
      }
    },
    setItemsForTask: (
      state: TaskListItemsState,
      action: PayloadAction<{ spaceId: number; messageId: number; items: TaskListItem[] }>,
    ) => {
      const { spaceId, messageId, items } = action.payload
      const nextItems = [
        ...Object.values(state.items).filter(
          item => !(item.spaceId === spaceId && item.messageId === messageId),
        ),
        ...items,
      ]
      // Rather than updating the index just rebuild it completely
      const nextTasksIndex = indexItems(nextItems)

      return {
        ...state,
        items: Object.fromEntries(nextItems.map(item => [item.id, item])),
        tasksIndex: nextTasksIndex,
      }
    },
  },
  /* eslint-enable */
})

const selectItems = (state: RootState): Items => state.entities.taskListItems.items || {}
const selectValues = (state: RootState): TaskListItem[] => Object.values(selectItems(state))

const selectTaskOrderIndex = (state: RootState): TaskOrderIndex =>
  state.entities.taskListItems.tasksIndex || {}

const selectListsForTask = (
  state: RootState,
  spaceId: number,
  messageId: number,
): ListsIndex | null => {
  const key = buildIndexKey(spaceId, messageId)
  const index = state.entities.taskListItems.tasksIndex || {}
  const entry = index[key]
  return entry || null
}

const selectByIds = (state: RootState, taskListId: number, spaceId: number, messageId: number) => {
  const items = selectValues(state)
  return items.find(
    item =>
      item.taskListId === taskListId && item.spaceId === spaceId && item.messageId === messageId,
  )
}

const byIdsSelector = (taskListId: number, spaceId: number, messageId: number) =>
  createSelector(selectValues, items =>
    items.find(
      item =>
        item.taskListId === taskListId && item.spaceId === spaceId && item.messageId === messageId,
    ),
  )

const listsIndexSelector = (spaceId: number, messageId: number) =>
  createSelector(selectTaskOrderIndex, index => index[buildIndexKey(spaceId, messageId)])

const isInListSelector = (taskListId: number, spaceId: number, messageId: number) =>
  createSelector(
    byIdsSelector(taskListId, spaceId, messageId),
    entry => !!entry && entry.orderingValue !== null,
  )

const isIgnoredInListSelector = (taskListId: number, spaceId: number, messageId: number) =>
  createSelector(
    byIdsSelector(taskListId, spaceId, messageId),
    entry => !!entry && !!entry.ignoreUntil,
  )

const selectors = {
  all: selectValues,
  byIds: selectByIds,
  byIdsSelector,
  isIgnoredInListSelector,
  isInListSelector,
  listsForTask: selectListsForTask,
  listsIndexSelector,
  taskOrderIndex: selectTaskOrderIndex,
}

const { actions, reducer } = taskListItemsSlice

export { actions, selectors }
export default reducer
