import React from 'react'
import Text from '@/components/core/Text'
import { collectionUtils, componentUtils, userUtils } from '@/core/utils'
import { useCachedSelector } from '@/hooks'
import { activitySelectors, userSelectors } from '@/store/selectors'
import { ComponentProp } from '@/types/components'
import { SpaceUser } from '@/types/entities'
import { useTypingTimestamp } from './hooks'

interface MultipleUsersTypingIndicatorProps {
  FallbackComponent?: ComponentProp
  spaceId: number
  userIds: number[]
}

function MultipleUsersTypingIndicator({
  spaceId,
  userIds,
  FallbackComponent,
}: MultipleUsersTypingIndicatorProps) {
  const { timestamp } = useTypingTimestamp()
  const areTyping = useCachedSelector(activitySelectors.areUsersTypingSelector, [
    spaceId,
    userIds,
    timestamp,
  ])
  const users = useCachedSelector(userSelectors.byIdsSelector, [spaceId, userIds])
  const typingUsers = React.useMemo(
    () =>
      collectionUtils
        .zip(users, areTyping)
        .filter(([user, isTyping]) => isTyping && user)
        .map(([user]) => user as SpaceUser),
    [users, areTyping],
  )

  if (typingUsers.length === 0) {
    return FallbackComponent ? componentUtils.renderComponent(FallbackComponent) : null
  }

  let note: string
  if (typingUsers.length === 1) {
    note = `${userUtils.getFirstName(typingUsers[0])} is typing...`
  } else if (typingUsers.length === 2) {
    const names = typingUsers.map(user => userUtils.getFirstName(user)).join(' and ')
    note = `${names} are typing...`
  } else {
    note = 'Several people are typing...'
  }

  return (
    <Text color="shadow" size="h6">
      {note}
    </Text>
  )
}

export default React.memo(MultipleUsersTypingIndicator)
