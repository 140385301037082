import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { Measurement } from '@/types/components'
import CachedImage from './CachedImage'
import Text from './Text'

type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'massive'
type Shape = 'circle' | 'square'

export interface ProfilePictureProps {
  backgroundColor?: ColorName
  borderColor?: ColorName | null
  borderGap?: number
  borderWidth?: number
  foregroundColor?: ColorName
  initials: string
  shape?: Shape
  size?: Size | number
  url: string | null
  testID?: string
}

const sizes: Record<Size, number> = {
  large: 30,
  massive: 100,
  medium: 24,
  small: 18,
  xlarge: 40,
  xsmall: 12,
  xxlarge: 48,
}

const defaultSize: Size = 'large'
const getBorderRadius = (shape: Shape, size: number) => (shape === 'circle' ? size / 2 : size / 6)
const sizeToNumber = (size: Size | number) => (typeof size === 'string' ? sizes[size] : size)
export const getWidth = (size?: Size | number, borderWidth?: number, borderGap?: number) =>
  sizeToNumber(size || defaultSize) + (borderWidth || 0) * 2 + (borderGap || 0) * 2

function ProfilePicture({
  backgroundColor = 'silver',
  borderColor = null,
  borderGap = 0,
  borderWidth = 2,
  foregroundColor = 'obsidian',
  initials,
  shape = 'circle',
  size = defaultSize,
  url,
  testID,
}: ProfilePictureProps) {
  const imagePx = sizeToNumber(size)
  const imageStyle = React.useMemo<Measurement>(
    () => ({ borderRadius: getBorderRadius(shape, imagePx), height: imagePx, width: imagePx }),
    [imagePx, shape],
  )

  const containerStyle = React.useMemo<Measurement>(() => {
    if (borderColor) {
      const containerPx = getWidth(imagePx, borderWidth, borderGap)
      return {
        borderColor: getColor(borderColor),
        borderRadius: getBorderRadius(shape, containerPx),
        borderWidth,
        height: containerPx,
        padding: borderGap,
        width: containerPx,
      }
    }
    return { borderRadius: getBorderRadius(shape, imagePx), height: imagePx, width: imagePx }
  }, [imagePx, borderWidth, borderGap, borderColor, shape])

  const initialsDisplay = (
    <View style={[imageStyle, styles.initials, { backgroundColor: getColor(backgroundColor) }]}>
      <Text color={foregroundColor} size={imagePx / 2}>
        {initials.slice(0, 2).toUpperCase()}
      </Text>
    </View>
  )
  const showImage = !!url

  return (
    <View style={[containerStyle, styles.container]} testID={testID}>
      {showImage ? (
        <CachedImage
          ErrorComponent={initialsDisplay}
          duration="long"
          fit="SMALLEST"
          size={imageStyle}
          style={imageStyle}
          uri={url}
        />
      ) : (
        initialsDisplay
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: getColor('transparent'),
    justifyContent: 'center',
  },
  initials: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default React.memo(ProfilePicture)
