import React from 'react'
import { useAlertContext } from '@/contexts/AlertContext'
import { TaskFormInteractionMode } from '@/contexts/TaskFormContext'
import { actionUtils, stringUtils } from '@/core/utils'
import { useAppDispatch, useFormValue } from '@/hooks'
import { useCheckNotify } from '@/hooks/tasks'
import { formActions } from '@/store/actions'
import { taskThunks } from '@/thunks'
import { Task } from '@/types/entities'
import { TaskType } from '@/types/tasks'
import { fieldNames } from '../constants'
import { useTaskConfirmCancel } from './confirmCancel'
import { useFormMissingFields, useHasChanges, usePendingAttachments } from './formFields'
import { mapFieldToPrompt } from './utils'

interface TaskActionsArgs {
  onFastCreate?: () => void
  formName: string
  messageId?: number
  onClose: () => void
  onSuccess?: (task: Task) => void
  taskId?: number
  interactionMode: TaskFormInteractionMode
  taskType: TaskType
  checkNotifyOnCreate: boolean
}

const clearOnSuccess = false

export const useTaskFormActions = ({
  checkNotifyOnCreate,
  formName,
  interactionMode,
  messageId,
  onClose,
  onFastCreate,
  onSuccess,
  taskId,
  taskType,
}: TaskActionsArgs) => {
  const dispatch = useAppDispatch()
  const showAlert = useAlertContext()
  const spaceId = useFormValue(formName, fieldNames.spaceId)
  const channelId = useFormValue(formName, fieldNames.channelId)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const missingFields = useFormMissingFields(formName, taskType)
  const hasChanges = useHasChanges(formName)
  const pendingAttachments = usePendingAttachments(formName)
  const checkNotify = useCheckNotify()
  const isComplete = missingFields.length === 0
  const canSubmit =
    !isSubmitting &&
    isComplete &&
    (interactionMode === 'create' || hasChanges) &&
    pendingAttachments.length === 0

  const cancel = React.useCallback(() => {
    dispatch(formActions.clear(formName))
    onClose()
  }, [dispatch, onClose, formName])

  const handleSubmit = React.useCallback(async () => {
    let result: Task | null = null

    setIsSubmitting(true)

    if (interactionMode === 'edit' && taskId) {
      if (taskType === 'QUESTION') {
        const response = await dispatch(
          taskThunks.updateQuestionForm(spaceId, taskId, formName, 'NOTIFY'),
        )
        result = response.ok ? response.data.task : null
      } else {
        const notify = await checkNotify({ formName, spaceId, taskId })
        const response = await dispatch(
          taskThunks.updateTaskForm(spaceId, taskId, formName, notify),
        )
        result = response.ok ? response.data.task : null
      }
    } else if (interactionMode === 'create') {
      const notify = checkNotifyOnCreate
        ? await checkNotify({ channelId, spaceId, taskType })
        : 'NOTIFY'

      if (taskType === 'QUESTION') {
        const response = await dispatch(
          taskThunks.createQuestionForm(spaceId, formName, { clearOnSuccess, notify }),
        )
        result = response.ok ? response.data.task : null
      } else {
        const response = await dispatch(
          taskThunks.createTaskForm(spaceId, messageId, formName, {
            clearOnSuccess,
            notify,
          }),
        )
        result = response.ok ? response.data.task : null
      }
    }
    setIsSubmitting(false)

    if (result) {
      if (onSuccess) {
        onSuccess(result)
      }
      if (interactionMode === 'create' && onFastCreate) {
        onFastCreate()
      } else {
        onClose()
      }
    }

    return !!result
  }, [
    channelId,
    checkNotify,
    checkNotifyOnCreate,
    dispatch,
    formName,
    interactionMode,
    messageId,
    onClose,
    onFastCreate,
    onSuccess,
    spaceId,
    taskId,
    taskType,
  ])

  const handleSubmitDisabled = React.useCallback(() => {
    if (missingFields.length === 0) {
      return
    }
    dispatch(
      formActions.setErrors({
        errors: Object.fromEntries(
          missingFields.map(fieldName => [fieldName, ['field is required']]),
        ),
        formName,
      }),
    )

    const alertBody =
      taskType === 'QUESTION'
        ? `Please ${stringUtils.toList(missingFields.map(mapFieldToPrompt))} to ask this question`
        : `Please ${stringUtils.toList(missingFields.map(mapFieldToPrompt))} to submit this task`

    actionUtils.showNoActionAlert(showAlert, 'Missing Fields', alertBody)
  }, [missingFields, dispatch, formName, showAlert, taskType])

  const handleCancel = useTaskConfirmCancel(
    formName,
    interactionMode === 'create' ? 'create' : 'edit',
    handleSubmit,
    cancel,
  )

  const handleClose = React.useCallback(() => {
    if (interactionMode === 'view') {
      dispatch(formActions.clear(formName))
    }
    onClose()
  }, [dispatch, interactionMode, onClose, formName])

  return {
    canSubmit,
    handleCancel,
    handleClose,
    handleSubmit,
    handleSubmitDisabled,
    hasChanges,
  }
}
