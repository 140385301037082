import { batch } from 'react-redux'
import { RequestThunk } from '@/api/call'
import { taskListAPI as api } from '@/api/requests'
import { taskListActions, taskListItemActions } from '@/store/actions'
import { taskListItemSelectors } from '@/store/selectors'
import { Task, TaskListItem } from '@/types/entities'
import { Thunk } from '@/types/store'
import { getAllPages, makeEnhancedRequest } from '../utils'

export const getTaskLists =
  (page = 1): RequestThunk<typeof api.getTaskLists> =>
  async dispatch => {
    const request = api.getTaskLists({ page })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { taskLists } = response.data
      dispatch(taskListActions.upsertMany(taskLists))
    }

    return response
  }

export const getTaskListById =
  (taskListId: number): RequestThunk<typeof api.getTaskListById> =>
  async dispatch => {
    const request = api.getTaskListById(taskListId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { taskList } = response.data
      dispatch(taskListActions.upsertOne(taskList))
    }

    return response
  }

export const createTaskList =
  (taskList: api.CreateTaskList): RequestThunk<typeof api.createTaskList> =>
  async dispatch => {
    const request = api.createTaskList(taskList)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(taskListActions.upsertOne(response.data.taskList))
    }

    return response
  }

export const ignoreTaskListItem =
  (taskListId: number, item: api.IgnoreTaskListItem): RequestThunk<typeof api.ignoreTaskListItem> =>
  async dispatch => {
    const request = api.ignoreTaskListItem(taskListId, item)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { taskListItem } = response.data
      dispatch(taskListItemActions.addItem({ item: taskListItem }))
    }

    return response
  }

const updateTaskListItem =
  (
    taskListItemId: number,
    beforeItemId: number | null,
  ): RequestThunk<typeof api.updateTaskListItem> =>
  async dispatch => {
    const request = api.updateTaskListItem(taskListItemId, beforeItemId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { taskListItem } = response.data
      dispatch(taskListItemActions.addItem({ item: taskListItem }))
    }

    return response
  }

export const deleteTaskFromList =
  (taskListId: number, spaceId: number, taskId: number): Thunk<boolean> =>
  async (dispatch, getState) => {
    const item = taskListItemSelectors.byIds(getState(), taskListId, spaceId, taskId)

    if (!item) {
      return false
    }

    const response = await dispatch(deleteTaskListItem(item.id))
    return response.ok
  }

const deleteTaskListItem =
  (taskListItemId: number): RequestThunk<typeof api.deleteTaskListItem> =>
  async dispatch => {
    const request = api.deleteTaskListItem(taskListItemId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(taskListItemActions.removeItem({ itemId: taskListItemId }))
    }

    return response
  }

export const getAllTaskListItems = (taskListId: number) =>
  getAllPages(page => getTaskListItems(taskListId, page))

const getTaskListItems =
  (taskListId: number, page = 1): RequestThunk<typeof api.getTaskListItems> =>
  async dispatch => {
    const request = api.getTaskListItems(taskListId, { page, perPage: 100 })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { taskListItems } = response.data
      if (page === 1) {
        dispatch(taskListItemActions.setItemsForList({ items: taskListItems, taskListId }))
      } else {
        dispatch(taskListItemActions.addItems({ items: taskListItems }))
      }
    }

    return response
  }

export const setTaskListItems =
  (
    taskListId: number,
    items: api.CreateTaskListItem[],
  ): RequestThunk<typeof api.setTaskListItems> =>
  async dispatch => {
    const request = api.setTaskListItems(taskListId, items)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { taskList, taskListItems } = response.data
      batch(() => {
        dispatch(taskListActions.upsertOne(taskList))
        dispatch(taskListItemActions.setItemsForList({ items: taskListItems, taskListId }))
      })
    }

    return response
  }

type MoveTaskArgs = {
  taskListId: number
  movedTask: Task
  afterTask?: Task
  beforeTask?: Task
}

export const moveTaskInList =
  ({ taskListId, movedTask, afterTask, beforeTask }: MoveTaskArgs): Thunk<boolean> =>
  async (dispatch, getState) => {
    const movedItem = taskListItemSelectors.byIds(
      getState(),
      taskListId,
      movedTask.spaceId,
      movedTask.id,
    )
    const beforeItem = beforeTask
      ? taskListItemSelectors.byIds(getState(), taskListId, beforeTask.spaceId, beforeTask.id)
      : undefined
    const afterItem = afterTask
      ? taskListItemSelectors.byIds(getState(), taskListId, afterTask.spaceId, afterTask.id)
      : undefined

    if (!movedItem) {
      return false
    }

    const temporaryOrderingValue = getTemporaryOrderingValue(afterItem, beforeItem)

    // Optimistically update the ordering value
    dispatch(
      taskListItemActions.addItem({
        item: {
          ...movedItem,
          orderingValue: temporaryOrderingValue,
        },
      }),
    )

    const result = await dispatch(updateTaskListItem(movedItem.id, beforeItem?.id || null))

    if (!result) {
      // If it failed then revert the ordering value
      dispatch(taskListItemActions.addItem({ item: movedItem }))
    }

    return result.ok
  }

const getTemporaryOrderingValue = (
  afterItem: TaskListItem | undefined,
  beforeItem: TaskListItem | undefined,
) => {
  if (afterItem && afterItem.orderingValue !== null) {
    if (beforeItem && beforeItem.orderingValue !== null) {
      return (beforeItem.orderingValue + afterItem.orderingValue) / 2
    }
    return afterItem.orderingValue + 1
  }
  if (beforeItem && beforeItem.orderingValue !== null) {
    return beforeItem.orderingValue - 1
  }
  return 0
}
