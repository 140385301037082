import React from 'react'
import Action from '@/components/core/Action'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { useCurrentUserId } from '@/hooks'

function TaskFormSubmitButton() {
  const { close, submit, submitDisabled, interactionMode, canSubmit, task } = useTaskFormContext()
  const currentUserId = useCurrentUserId()
  const eventProperties = React.useMemo(
    () => ({
      'created for': task?.assignedUserId === currentUserId ? 'self' : 'other',
    }),
    [task?.assignedUserId, currentUserId],
  )
  const disabled = !canSubmit

  if (interactionMode === 'create') {
    return (
      <Action
        amplitudeEventName="create task"
        amplitudeEventProperties={eventProperties}
        disabled={disabled}
        label="Create"
        onAction={submit}
        onActionDisabled={submitDisabled}
        type="buttonSuccess"
      />
    )
  }
  if (interactionMode === 'edit') {
    return (
      <Action
        amplitudeEventName="update task"
        disabled={disabled}
        hitSlop="none"
        label="Update"
        onAction={submit}
        onActionDisabled={submitDisabled}
        type="linkPrimary"
      />
    )
  }
  return <Action label="Done" onAction={close} type="linkPrimary" />
}

export default React.memo(TaskFormSubmitButton)
