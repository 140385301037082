import React from 'react'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import TaskCompleteCheckbox from '@/components/tasks/TaskCompleteCheckbox'
import TaskDetailsList from '@/components/tasks/TaskDetailsList'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { Task } from '@/types/entities'

interface TaskContentProps {
  task: Task
}

function TaskContent({ task }: TaskContentProps) {
  return (
    <Box alignItems="flex-start" direction="row" gapAll="medium">
      <Box flex={1}>
        <Box direction="row">
          <TaskCompleteCheckbox task={task} />
          <Box flex={1} gapLeft="medium">
            <TextWithLinks color="shadow">{task.content}</TextWithLinks>
          </Box>
        </Box>
        <TaskDetailsList color="iron" maxItems={5} task={task} />
      </Box>
      <UserProfilePicture spaceId={task.spaceId} userId={task.assignedUserId} />
    </Box>
  )
}

export default React.memo(TaskContent)
