import React from 'react'
import TaskTimeModal from '@/components/tasks/TaskForm/Modals/TaskTimeModal'
import { dateUtils as D } from '@/core/utils'
import { useToggle } from '@/hooks'
import DateOptionItem from './DateOptionItem'

interface TimeOptionItemProps {
  selectedDate: Date | null
  onDateChange: (date: Date) => void
  localModal: boolean
}

function TimeOptionItem({ selectedDate, onDateChange, localModal }: TimeOptionItemProps) {
  const [open, toggleOpen] = useToggle(false)

  return (
    <>
      <DateOptionItem
        disabled={!selectedDate}
        icon="clock"
        onPress={toggleOpen}
        title={selectedDate ? 'Time' : 'Time (Requires Due Date)'}
        value={selectedDate ? D.formatTime(selectedDate) : 'None'}
      />
      <TaskTimeModal
        isOpen={open}
        localModal={localModal}
        onClose={toggleOpen}
        onDateChange={onDateChange}
        selectedDate={selectedDate}
      />
    </>
  )
}

export default React.memo(TimeOptionItem)
