import React from 'react'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import Icon from '@/components/core/Icon'
import IconButton from '@/components/core/IconButton'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import TextInput from '@/components/core/TextInput'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import SummaryTaskItem from '@/components/summaries/SummaryTaskItem'
import { isUnedited } from '@/core/utils/entities'
import { SummaryItem } from '@/types/entities'
import EditItemHeader from '../EditItemHeader'
import CanAcknowledgeToggleButton from './CanAcknowledgeToggleButton'
import CanCommentToggleButton from './CanCommentToggleButton'
import { useEditSummaryItem } from './hooks'

interface EditItemProps {
  summaryItem: SummaryItem
}

function EditItem({ summaryItem }: EditItemProps) {
  const { spaceId } = summaryItem
  const {
    canAcknowledge,
    canComment,
    canUpdate,
    content,
    handleAttachQuestion,
    handleAttachTask,
    handleCancel,
    handleDelete,
    handlePromptAttach,
    handleRemoveTask,
    handleUpdate,
    hasQuestionAttached,
    hasTaskAttached,
    setContent,
    taskId,
    toggleCanAcknowledge,
    toggleCanComment,
  } = useEditSummaryItem(summaryItem)

  const title = (() => {
    if (hasQuestionAttached) {
      return 'Question'
    }
    if (hasTaskAttached) {
      return 'Task'
    }
    return 'Message'
  })()

  return (
    <>
      <Box gapBottom="xsmall">
        <Text size="h6" weight="medium">
          {title}
        </Text>
      </Box>
      <Box direction="row">
        <Background borderColor="koala" borderRadius={8} borderWidth={1} color="silver" flex={1}>
          <Box gapAll="medium">
            <EditItemHeader
              disabled={!canUpdate}
              handleCancel={handleCancel}
              handleUpdate={handleUpdate}
            />
            <Background borderRadius={8} color="ghost" flex={1}>
              <TextInput
                autoFocus
                blurOnSubmit
                expandable
                multiline
                onChangeText={setContent}
                onSubmitEditing={handleUpdate}
                placeholder="Write message / comment / description / note / etc..."
                selectTextOnFocus={isUnedited(summaryItem)}
                value={content}
              />
            </Background>
            {taskId ? (
              <Box gapTop="medium">
                <SummaryTaskItem editing spaceId={spaceId} taskId={taskId} />
                <Box alignSelf="flex-end" gapTop="xsmall">
                  <Link label="remove" onPress={handleRemoveTask} size="h5" />
                </Box>
              </Box>
            ) : (
              <Box
                alignItems="center"
                direction="row"
                gapVertical="medium"
                testID="attach_to_summary_item"
              >
                <Touchable feedback="opacity" onPress={handlePromptAttach}>
                  <Box alignItems="center" direction="row">
                    <Icon color="emmre-blue" name="plus" size="h6" />
                    <Box gapLeft="medium">
                      <Text color="emmre-blue" size="h6">
                        Add Item...
                      </Text>
                    </Box>
                  </Box>
                </Touchable>
                <Box gapLeft="medium">
                  <IconButton
                    hitSlop="none"
                    iconKind="solid"
                    kind="transparentPrimary"
                    name="square-check"
                    onPress={handleAttachTask}
                    size="h6"
                  />
                </Box>
                <Box gapLeft="medium">
                  <IconButton
                    hitSlop="none"
                    iconKind="solid"
                    kind="transparentPrimary"
                    name="square-question"
                    onPress={handleAttachQuestion}
                    size="h6"
                  />
                </Box>
              </Box>
            )}
            <Box>
              <CanCommentToggleButton
                canComment={canComment}
                disabled={!content}
                onToggle={toggleCanComment}
              />
              <Box gapTop="small">
                {hasQuestionAttached ? null : (
                  <CanAcknowledgeToggleButton
                    canAcknowledge={canAcknowledge}
                    onToggle={toggleCanAcknowledge}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Background>
        <Box gapLeft="medium">
          <BaseIconButton
            borderColor="koala"
            borderRadius={8}
            borderStyle="solid"
            borderWidth={1}
            color="koala"
            name="trash-alt"
            onPress={handleDelete}
            padding={12}
            shape="square"
          />
        </Box>
      </Box>
    </>
  )
}

export default React.memo(EditItem)
