import { taskUtils } from '@/core/utils'
import { entitySelectors } from '@/store/selectors'
import { Optional } from '@/types/generics'
import { SyncThunk, Thunk } from '@/types/store'
import { TaskDefaults, UnaryTaskFilter } from '@/types/tasks'
import { getOrCreateDefaultChannelWithUser } from '../channels'

export const getTaskChannelId =
  (spaceId: number, channelId: Optional<number>, assignedUserId: Optional<number>): Thunk<number> =>
  async dispatch => {
    if (channelId) {
      return channelId
    }
    const channel = await dispatch(
      getOrCreateDefaultChannelWithUser(spaceId, assignedUserId || null),
    )
    return channel.id
  }

export const getTaskListIds =
  (isMyDay: boolean): SyncThunk<number[] | undefined> =>
  (dispatch, getState) => {
    if (!isMyDay) {
      return []
    }

    const myDayList = entitySelectors.currentUserTaskListSelector('MY_DAY')(getState())

    if (!myDayList) {
      return undefined
    }

    return [myDayList.id]
  }

export const getTaskDefaults =
  (defaultSpaceId: number, filter: UnaryTaskFilter): Thunk<TaskDefaults> =>
  async dispatch => {
    const taskDefaults = taskUtils.getTaskDefaults(defaultSpaceId, filter)

    if (taskDefaults.assignedUserId && !taskDefaults.channelId) {
      const channel = await dispatch(
        getOrCreateDefaultChannelWithUser(taskDefaults.spaceId, taskDefaults.assignedUserId),
      )

      return {
        ...taskDefaults,
        channelId: channel.id,
      }
    }

    return taskDefaults
  }
