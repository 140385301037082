import React from 'react'
import { usePromptContext } from '@/contexts/PromptContext'
import { useAppDispatch } from '@/hooks'
import { boardThunks, tagThunks } from '@/thunks'
import { FilterGroup } from '@/types/tasks'
import CreateTaskFilterOptionContext from './context'
import { CreateTaskFilterOptionContextType } from './types'

interface CreateTaskFilterOptionContainerProps {
  channelId: number | null
  children: React.ReactNode
  spaceId: number
}

function CreateTaskFilterOptionContainer({
  children,
  spaceId,
  channelId,
}: CreateTaskFilterOptionContainerProps) {
  const dispatch = useAppDispatch()
  const showPrompt = usePromptContext()

  const createBoard = React.useCallback(
    (name: string) => {
      if (!channelId) {
        return
      }
      dispatch(boardThunks.createBoard(spaceId, channelId, { name }))
    },
    [spaceId, channelId, dispatch],
  )

  const createTag = React.useCallback(
    (name: string) => {
      dispatch(tagThunks.createTag(spaceId, { name }))
    },
    [dispatch, spaceId],
  )

  const canCreate = React.useCallback(
    (group: FilterGroup) => {
      switch (group.key) {
        case 'BOARD':
          return channelId ? '+ Create Project' : null
        case 'TAGS':
          return '+ Create Tag'
        default:
          return null
      }
    },
    [channelId],
  )

  const onCreate = React.useCallback(
    (group: FilterGroup) => {
      if (group.key === 'BOARD') {
        showPrompt({ onSubmit: createBoard, title: 'Project Name' })
      } else if (group.key === 'TAGS') {
        showPrompt({ onSubmit: createTag, title: 'Tag Name' })
      }
    },
    [showPrompt, createBoard, createTag],
  )

  const contextValue = React.useMemo<CreateTaskFilterOptionContextType>(
    () => ({
      canCreate,
      onCreate,
    }),
    [onCreate, canCreate],
  )

  return (
    <CreateTaskFilterOptionContext.Provider value={contextValue}>
      {children}
    </CreateTaskFilterOptionContext.Provider>
  )
}

export default React.memo(CreateTaskFilterOptionContainer)
