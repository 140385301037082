import { messageUtils } from '@/core/utils'
import { messageSelectors, taskSelectors, threadSelectors } from '@/store/selectors'
import { Message, Task, Thread } from '@/types/entities'
import { useAppSelector, useCachedSelector } from './redux'

type ThreadData = {
  thread?: Thread
  message?: Message
  task?: Task
}

export const useThread = (spaceId: number, messageId: number): ThreadData => {
  const thread = useAppSelector(threadSelectors.byId, spaceId, messageId)
  const message = useAppSelector(messageSelectors.byId, spaceId, messageId)
  const task = useAppSelector(taskSelectors.byId, spaceId, messageId)

  return {
    message,
    task,
    thread,
  }
}

export const useIsLastTaskActivityInThread = (message: Message) => {
  const threadId = messageUtils.getThreadId(message)
  const lastMessageInThread = useCachedSelector(messageSelectors.lastTaskActivityInThreadSelector, [
    message.spaceId,
    threadId,
  ])
  return !lastMessageInThread || lastMessageInThread.id === message.id
}
