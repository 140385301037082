import React from 'react'
import { FlatList, FlatListProps, ListRenderItemInfo } from 'react-native'
import ActionableListItem from './ActionableListItem'
import { ActionableListBaseProps } from './types'

type ActionableFlatListProps<S> = Omit<FlatListProps<S>, 'keyExtractor' | 'renderItem'> &
  ActionableListBaseProps<S>

function ActionableFlatList<S>({
  actions,
  itemContainerStyle,
  itemStyle,
  keyExtractor,
  onItemLongPress,
  onItemPress,
  renderItem,
  buttonType = 'ICON',
  buttonWidth = 50,
  ...flatListProps
}: ActionableFlatListProps<S>) {
  const itemRefs = React.useRef<Map<string, any>>(new Map())

  const renderSwipeableItem = React.useCallback(
    (data: ListRenderItemInfo<S>) => (
      <ActionableListItem
        actions={actions}
        buttonType={buttonType}
        buttonWidth={buttonWidth}
        containerStyle={itemContainerStyle}
        itemRefs={itemRefs}
        itemStyle={itemStyle}
        onLongPress={onItemLongPress}
        onPress={onItemPress}
        subject={data.item}
        subjectKey={keyExtractor(data.item)}
      >
        {renderItem({ actions, item: data.item })}
      </ActionableListItem>
    ),
    [
      actions,
      buttonType,
      buttonWidth,
      itemContainerStyle,
      itemStyle,
      keyExtractor,
      onItemLongPress,
      onItemPress,
      renderItem,
    ],
  )

  return (
    <FlatList keyExtractor={keyExtractor} renderItem={renderSwipeableItem} {...flatListProps} />
  )
}

export default React.memo(ActionableFlatList) as typeof ActionableFlatList
