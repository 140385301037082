import React from 'react'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import InviteForm from '@/components/users/InviteForm'
import RoleSelector from '@/components/users/RoleSelector'
import { forms } from '@/core/constants'
import { dateUtils as D } from '@/core/utils'
import { useAppDispatch, useFormField, useFormValues } from '@/hooks'
import { formActions } from '@/store/actions'
import { formRequest } from '@/thunks/forms'
import { createUser } from '@/thunks/spaces'
import { Channel, SpaceMemberRole } from '@/types/entities'

interface InviteUserAndCreateHubContentProps {
  spaceId: number
  onCreated: (channel: Channel) => void
  onClose: () => void
  isOpen: boolean
}

const formName = forms.CREATE_HUB

function InviteUserAndCreateHubContent({
  spaceId,
  onCreated,
  onClose,
  isOpen,
}: InviteUserAndCreateHubContentProps) {
  const dispatch = useAppDispatch()
  const { value: role, setValue: setRole, error: roleError } = useFormField(formName, 'role')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const handleCreateHub = React.useCallback(async () => {
    setIsSubmitting(true)
    const response = await dispatch(
      formRequest(formName, fields =>
        createUser(spaceId, {
          createHub: true,
          emailAddress: fields.emailAddress,
          firstName: fields.firstName,
          lastName: fields.lastName,
          role: fields.role,
          timezoneName: D.getTimeZoneName(),
        }),
      ),
    )
    setIsSubmitting(false)

    if (response.ok) {
      const channel = response.data.channels[0]
      if (channel) {
        onCreated(channel)
      }
      onClose()
    }
  }, [spaceId, setIsSubmitting, onClose, dispatch, onCreated])

  const formValues = useFormValues(formName)
  const canSubmit = !!formValues.firstName && !!formValues.role
  const isDisabled = !canSubmit || isSubmitting

  React.useEffect(() => {
    if (!isOpen) {
      dispatch(formActions.clear(formName))
    }
  }, [dispatch, isOpen])

  return (
    <Box gapAll="medium">
      <Text align="center" color="emmre-purple">
        Is your{' '}
        <Text color="emmre-purple" italic weight="medium">
          partner
        </Text>{' '}
        an Executive or an Executive Assistant?
      </Text>
      <Box gapTop="medium">
        <RoleSelector
          amplitudeInviteContext="create hub"
          errorMessage={roleError ? 'Please select a role' : null}
          onSelect={setRole}
          roleFor="partner"
          selectedValue={role}
        />
      </Box>
      <Box gapTop="medium">
        <InviteForm
          amplitudeInviteContext="create hub"
          formName={formName}
          role={role as SpaceMemberRole}
          spaceId={spaceId}
        />
      </Box>
      <Box gapTop="large">
        <Button disabled={isDisabled} kind="primary" label="Create Hub" onPress={handleCreateHub} />
      </Box>
    </Box>
  )
}

export default React.memo(InviteUserAndCreateHubContent)
