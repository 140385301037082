import React from 'react'
import Icon from '@/components/core/Icon'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { useCachedSelector, useUser } from '@/hooks'
import { activitySelectors } from '@/store/selectors'
import { useTypingTimestamp } from './hooks'

interface SingleUserTypingIndicatorProps {
  spaceId: number
  userId: number
}

function SingleUserTypingIndicator({ spaceId, userId }: SingleUserTypingIndicatorProps) {
  const { timestamp } = useTypingTimestamp()
  const isTyping = useCachedSelector(activitySelectors.isUserTypingSelector, [
    spaceId,
    userId,
    timestamp,
  ])
  const user = useUser(spaceId, userId)

  if (!(user && isTyping)) {
    return null
  }

  return (
    <Box direction="row" gapHorizontal="medium" gapVertical="small">
      <UserProfilePicture user={user} />
      <Box direction="column" flex={1} gapLeft="medium" justifyContent="center">
        <Icon name="message-dots" size="h4" />
      </Box>
    </Box>
  )
}

export default React.memo(SingleUserTypingIndicator)
