import { taskListAPI as api } from '@/api/requests'
import { sleepWhile } from '@/core/utils/asynchronous'
import { entitySelectors } from '@/store/selectors'
import { TaskList } from '@/types/entities'
import { GetState, Thunk } from '@/types/store'
import { makeEnhancedRequest } from '../utils'
import { createTaskList, getAllTaskListItems, getTaskListById, getTaskLists } from './api'
import { storeTaskList } from './store'

const myDayLock = {
  locked: false,
}

export const getUpdatedMyDayTaskList = (): Thunk<boolean> => async (dispatch, getState) => {
  const localMyDay = getLocalMyDayList(getState)

  if (!localMyDay) {
    const myDayList = await dispatch(getOrCreatedMyDayList())

    if (myDayList) {
      await dispatch(getAllTaskListItems(myDayList.id))
      return true
    }
    return false
  }

  const { id: taskListId } = localMyDay

  const response = await dispatch(getTaskListById(taskListId))

  if (!response.ok) {
    return false
  }

  const { taskList: updatedList } = response.data

  if (updatedList.updatedAt > localMyDay.updatedAt) {
    await dispatch(getAllTaskListItems(taskListId))
  }

  return true
}

const getOrCreatedMyDayList = (): Thunk<TaskList | null> => async (dispatch, getState) => {
  // If it's locked then another promise is trying to create / load it. Once it is successful
  // the task list will be in state, so just return that (if it worked)
  if (myDayLock.locked) {
    await sleepWhile(() => myDayLock.locked, 5)
  }

  const localMyDay = getLocalMyDayList(getState)

  if (localMyDay) {
    return localMyDay
  }

  try {
    myDayLock.locked = true
    const getResponse = await dispatch(getTaskLists())

    if (getResponse.ok) {
      const { taskLists: newLists } = getResponse.data
      const myDays = newLists.filter(taskList => taskList.listType === 'MY_DAY')
      if (myDays.length >= 1) {
        return myDays[0]
      }
    }

    const createResponse = await dispatch(createTaskList({ listType: 'MY_DAY', name: 'My Day' }))

    if (createResponse.ok) {
      return createResponse.data.taskList
    }

    return null
  } finally {
    myDayLock.locked = false
  }
}

const getLocalMyDayList = (getState: GetState): TaskList | null =>
  entitySelectors.currentUserTaskListSelector('MY_DAY')(getState()) || null

export const getTheirDay =
  (userId: number): Thunk<boolean> =>
  async dispatch => {
    const request = api.getTaskListByType(userId, 'MY_DAY')
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      await dispatch(storeTaskList(response.data.taskList, true))
    }

    return response.ok
  }
