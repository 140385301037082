import React from 'react'
import { ScrollView } from 'react-native'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import HubListItem from './HubListItem'
import { useArchivedHubsModal } from './hooks'

interface ArchivedHubsModalProps {
  onClose: () => void
  spaceId: number
}

function ArchivedHubsModal({ onClose, spaceId }: ArchivedHubsModalProps) {
  const { archivedHubs, handleUnarchiveHub } = useArchivedHubsModal(spaceId, onClose)
  return (
    <ModalLayout
      onCancel={onClose}
      rightAction={{
        icon: 'xmark',
        onAction: onClose,
        type: 'transparentDark',
      }}
      title="Archived Hubs"
    >
      <ScrollView>
        <Box gapBottom="xlarge" gapHorizontal="xlarge">
          {archivedHubs.map(hub => (
            <HubListItem key={hub.id} hub={hub} onUnarchive={handleUnarchiveHub} />
          ))}
        </Box>
      </ScrollView>
    </ModalLayout>
  )
}

export default React.memo(ArchivedHubsModal)
