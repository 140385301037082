import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { Tag } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, tagId: number): string => `${spaceId}:${tagId}`
const sortComparer = (a: Tag, b: Tag) => a.name.localeCompare(b.name)

const adapter = createEntityAdapter<Tag>({
  selectId: tag => buildId(tag.spaceId, tag.id),
  sortComparer,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/tags',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    setOne: adapter.setOne,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.tags)
const getId = adapter.selectId

const selectById = (state: RootState, spaceId: number, tagId: number) =>
  state.entities.tags.entities[buildId(spaceId, tagId)]
const byIdSelector = (spaceId: number, tagId: number) =>
  createSelector(sliceSelectors.selectEntities, tags => tags[buildId(spaceId, tagId)])
const byIdsSelector = (spaceId: number, tagIds: number[]) =>
  createSelector(
    sliceSelectors.selectEntities,
    tagIndex =>
      tagIds.map(tagId => tagIndex[buildId(spaceId, tagId)]).filter(tag => !!tag) as Tag[],
  )

const bySpaceIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, tags => tags.filter(tag => tag.spaceId === spaceId))

const selectors = {
  byId: selectById,
  byIdSelector,
  byIdsSelector,
  bySpaceIdSelector,
}

export { actions, selectors, buildId, getId }
export default reducer
