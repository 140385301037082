import React from 'react'
import ModalLayout from '@/components/layouts/ModalLayout'
import MessageThread from '@/components/messages/MessageThread'
import { useThread } from '@/hooks/threads'

interface MessageThreadModalProps {
  spaceId: number
  messageId: number
  onClose: () => void
}

function MessageThreadModal({ spaceId, messageId, onClose }: MessageThreadModalProps) {
  const { thread } = useThread(spaceId, messageId)

  return (
    <ModalLayout
      leftAction={thread ? undefined : { label: 'Cancel', onAction: onClose, type: 'linkPrimary' }}
      onCancel={onClose}
      rightAction={{ label: 'Done', onAction: onClose, type: 'linkPrimary' }}
      title={thread ? 'Message Thread' : 'Start New Thread'}
    >
      <MessageThread autoFocus messageId={messageId} spaceId={spaceId} />
    </ModalLayout>
  )
}

export default React.memo(MessageThreadModal)
