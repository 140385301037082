import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { Task } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, taskId: number): string => `${spaceId}:${taskId}`
const sortComparer = (a: Task, b: Task) => b.id - a.id

const adapter = createEntityAdapter<Task>({
  selectId: task => buildId(task.spaceId, task.id),
  sortComparer,
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/tasks',
  reducers: {
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const { selectId: getId } = adapter

const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.tasks)

const selectById = (state: RootState, spaceId: number, taskId: number) =>
  state.entities.tasks.entities[buildId(spaceId, taskId)]

const byIdSelector = (taskId: string) =>
  createSelector(sliceSelectors.selectEntities, tasks => tasks[taskId])

const byIdsSelector = (spaceId: number, taskIds: number[]) =>
  createSelector(
    sliceSelectors.selectEntities,
    tasks =>
      taskIds.map(taskId => tasks[buildId(spaceId, taskId)]).filter(task => !!task) as Task[],
  )

const bySpaceIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, tasks => tasks.filter(task => task.spaceId === spaceId))

const selectors = {
  all: sliceSelectors.selectAll,
  byId: selectById,
  byIdSelector,
  byIdsSelector,
  bySpaceIdSelector,
  entities: sliceSelectors.selectEntities,
}

export { actions, selectors, getId, sortComparer }
export default reducer
