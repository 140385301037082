import React from 'react'
import { Summary } from '@/types/entities'
import { SelectTasks } from '@/types/tasks'
import EditSummaryContext from './EditSummaryContext'
import EditSummaryItemsList from './EditSummaryItemsList'
import { useEditSummaryContextValue } from './hooks'

interface EditSummaryViewProps {
  summary: Summary
  onShowSummary: (summary: Summary) => void
  onSelectTasks: SelectTasks
}

function EditSummaryView({ summary, onShowSummary, onSelectTasks }: EditSummaryViewProps) {
  const contextValue = useEditSummaryContextValue(summary, onSelectTasks)

  return (
    <EditSummaryContext.Provider value={contextValue}>
      <EditSummaryItemsList onShowSummary={onShowSummary} summary={summary} />
    </EditSummaryContext.Provider>
  )
}

export default React.memo(EditSummaryView)
