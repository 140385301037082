import React from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, ParameterizedSelector, RootState, Selector } from '@/types/store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export function useAppSelector<A extends any[], T>(
  selector: ParameterizedSelector<A, T>,
  ...args: A
): T {
  return useTypedSelector(state => selector(state, ...(args || [])))
}

export function useCachedSelector<A extends any[], T>(
  getSelector: (...a: A) => Selector<T>,
  args: A,
): T {
  const cachedSelector = React.useMemo(() => getSelector(...args), [getSelector, ...args]) // eslint-disable-line react-hooks/exhaustive-deps
  return useTypedSelector(cachedSelector)
}
