import React from 'react'
import { useOptionalAmplitude } from '@/hooks/amplitude'
import { AmplitudeProps } from '@/types/amplitude'
import { HitSlop } from '@/types/components'
import Text, { TextProps } from './Text'
import Touchable from './Touchable'

export interface LinkProps
  extends Omit<TextProps, 'decoration' | 'children' | 'onPress'>,
    AmplitudeProps {
  label: string
  underlined?: boolean
  disabled?: boolean
  onPress?: () => void
  hitSlop?: HitSlop
}

function Link({
  amplitudeEventName,
  amplitudeEventProperties,
  color = 'emmre-blue',
  disabled = false,
  hitSlop,
  label,
  onPress,
  underlined = false,
  ...textProps
}: LinkProps) {
  const wrappedOnPress = useOptionalAmplitude(onPress, amplitudeEventName, amplitudeEventProperties)
  if (hitSlop) {
    return (
      <Touchable disabled={disabled} feedback="opacity" hitSlop={hitSlop} onPress={wrappedOnPress}>
        <Text {...textProps} color={color} decoration={underlined ? 'underline' : 'none'}>
          {label}
        </Text>
      </Touchable>
    )
  }

  return (
    <Text
      {...textProps}
      color={color}
      decoration={underlined ? 'underline' : 'none'}
      onPress={disabled ? undefined : wrappedOnPress}
    >
      {label}
    </Text>
  )
}

export default React.memo(Link)
