import { Summary, SummaryStatus } from '@/types/entities'
import { commonFormats, format, now } from './dates'

export const canEditSummary = (userId: number, summary: Summary) => summary.creatorId === userId

export const getSummaryDefaults = () => {
  const date = format(now(), commonFormats.dateNoYear)
  return {
    closing: null,
    greeting: null,
    title: `Executive Brief ${date}`,
  }
}

export const summaryStatusToLabel = (status: SummaryStatus) => {
  switch (status) {
    case 'OPEN':
      return 'Creating'
    case 'SUBMITTED':
      return 'Submitted'
    case 'REVIEWED':
      return 'Reviewed'
    case 'COMPLETED':
      return 'Completed'
    default:
      throw new Error(`Unhandled status type: ${status}`)
  }
}
