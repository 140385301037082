import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import { useCreateTaskFilterOptionContext } from '@/contexts/CreateTaskFilterOptionContext'
import { FilterGroup } from '@/types/tasks'

interface CreateOptionButtonProps {
  group: FilterGroup
}

function CreateOptionButton({ group }: CreateOptionButtonProps) {
  const { canCreate, onCreate } = useCreateTaskFilterOptionContext()

  const handleCreate = React.useCallback(() => onCreate(group), [onCreate, group])
  const label = canCreate(group)

  if (!label) {
    return null
  }

  return (
    <Touchable feedback="opacity" onPress={handleCreate}>
      <Text size="h5">{label}</Text>
    </Touchable>
  )
}

export default React.memo(CreateOptionButton)
