import React from 'react'
import Checkbox from '@/components/core/Checkbox'
import { SelectTask } from '@/types/tasks'

interface TaskSelectCheckboxProps {
  isSelected: boolean
  onSelect: SelectTask
  taskId: number
}

function TaskSelectCheckbox({ isSelected, onSelect, taskId }: TaskSelectCheckboxProps) {
  const handleChange = React.useCallback(
    (selected: boolean) => onSelect(taskId, selected),
    [onSelect, taskId],
  )

  return <Checkbox checked={isSelected} onChange={handleChange} shape="circle" size={30} />
}

export default React.memo(TaskSelectCheckbox)
