import { RequestThunk } from '@/api/call'
import { channelAPI } from '@/api/requests'
import { fnUtils, messageUtils } from '@/core/utils'
import {
  channelActions,
  channelStatActions,
  channelUserActions,
  formActions,
} from '@/store/actions'
import { getChannelId } from '@/store/entityIds'
import {
  authSelectors,
  channelSelectors,
  channelStatSelectors,
  channelUserSelectors,
  entitySelectors,
  formSelectors,
  messageSelectors,
} from '@/store/selectors'
import { Channel, ChannelType, ChannelUser, Message, ResolvedChannel } from '@/types/entities'
import { SyncThunk, Thunk } from '@/types/store'
import { select } from './entities'
import { notifyErrors, setErrors } from './errors'
import { setBadge } from './notifications'
import { makeEnhancedRequest } from './utils'

export const getChannelsUsersList =
  (
    spaceId: number,
    channelId: number,
    page = 1,
  ): RequestThunk<typeof channelAPI.getChannelsUsersList> =>
  async dispatch => {
    const request = channelAPI.getChannelsUsersList(spaceId, channelId, { page })
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(channelUserActions.upsertMany(response.data.channelsUsers))
    }

    return response
  }

// TODO: use thunks/entities/select?
export const getChannel =
  (spaceId: number, channelId: number): SyncThunk<Channel | undefined> =>
  (dispatch, getState) =>
    channelSelectors.byId(getState(), spaceId, channelId)

export const updateChannelUser =
  (
    spaceId: number,
    channelId: number,
    userId: number | null,
    channelUser: Partial<ChannelUser>,
  ): RequestThunk<typeof channelAPI.updateChannelUser> =>
  async (dispatch, getState) => {
    const useUserId = userId || (authSelectors.myId(getState()) as number)
    const request = channelAPI.updateChannelUser(spaceId, channelId, useUserId, channelUser)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(channelUserActions.upsertOne(response.data.channelUser))
      dispatch(setBadge())
    }

    return response
  }

export const setDefaultChannel =
  (spaceId: number, channelId: number): RequestThunk<typeof channelAPI.setDefaultChannel> =>
  async dispatch => {
    const request = channelAPI.setDefaultChannel(spaceId, channelId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(channelUserActions.upsertMany(response.data.channelsUsers))
      dispatch(setBadge())
    }

    return response
  }

export const maybeMarkChannelRead =
  (channelUser: ChannelUser): Thunk<boolean> =>
  async (dispatch, getState) => {
    const { channelId, spaceId } = channelUser
    const lastMessage = messageSelectors.lastMessageInChannelSelector(
      spaceId,
      channelId,
    )(getState())

    if (lastMessage && lastMessage.id > channelUser.maxReadMessageId) {
      const response = await dispatch(
        updateChannelUser(spaceId, channelId, null, { maxReadMessageId: lastMessage.id }),
      )
      return response.ok
    }
    return false
  }

export const createChannel =
  (
    spaceId: number,
    channel: channelAPI.CreateChannel,
  ): RequestThunk<typeof channelAPI.createChannel> =>
  async dispatch => {
    const request = channelAPI.createChannel(spaceId, channel)
    const response = await dispatch(makeEnhancedRequest(request))
    if (response.ok) {
      dispatch(channelUserActions.upsertOne(response.data.channelUser))
      dispatch(channelActions.upsertOne(response.data.channel))
    }
    return response
  }

export const updateChannel =
  (
    spaceId: number,
    channelId: number,
    channel: channelAPI.UpdateChannel,
  ): RequestThunk<typeof channelAPI.updateChannel> =>
  async dispatch => {
    const request = channelAPI.updateChannel(spaceId, channelId, channel)
    const response = await dispatch(makeEnhancedRequest(request))
    if (response.ok) {
      dispatch(channelActions.upsertOne(response.data.channel))
    }
    return response
  }

export const archiveChannel =
  (spaceId: number, channelId: number): RequestThunk<typeof channelAPI.archiveChannel> =>
  async dispatch => {
    const request = channelAPI.archiveChannel(spaceId, channelId)
    const response = await dispatch(makeEnhancedRequest(request))
    if (response.ok) {
      dispatch(channelActions.upsertOne(response.data.channel))
    }
    return response
  }

const removeUserFromChannel =
  (
    spaceId: number,
    channelId: number,
    userId: number,
  ): RequestThunk<typeof channelAPI.removeUserFromChannel> =>
  async dispatch => {
    const request = channelAPI.removeUserFromChannel(spaceId, channelId, userId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      const { channel } = response.data
      dispatch(channelActions.upsertOne(channel))
    }
    return response
  }

export const leaveChannel =
  (spaceId: number, channelId: number, beforeRemove: () => void): Thunk<boolean> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState())

    if (!myId) {
      return false
    }

    const response = await dispatch(removeUserFromChannel(spaceId, channelId, myId))

    if (response.ok) {
      await beforeRemove()
      dispatch(channelActions.removeOne(getChannelId(response.data.channel)))
    } else {
      dispatch(notifyErrors('Error Leaving Channel', response.errors))
    }

    return response.ok
  }

export const getOrCreateDefaultChannelWithUser =
  (spaceId: number, userId: number | null): Thunk<Channel> =>
  async (dispatch, getState) => {
    const myId = authSelectors.myId(getState())
    const otherUserId = userId === myId ? null : userId

    let defaultChannel: Channel | undefined

    if (otherUserId) {
      defaultChannel = channelSelectors.defaultChannelWithUserSelector(
        spaceId,
        otherUserId,
      )(getState())
    } else {
      defaultChannel = dispatch(select(entitySelectors.defaultChannelSelector(spaceId)))
    }

    if (defaultChannel) {
      return defaultChannel
    }

    // If not direct message is found, create one and return that.
    const response = await dispatch(
      createChannel(spaceId, {
        channelType: 'DIRECT_MESSAGE',
        name: null,
        userIds: otherUserId ? [otherUserId] : [],
      }),
    )

    if (response.ok) {
      return response.data.channel
    }

    return fnUtils.fail('Unable to create direct message')
  }

export const incrementUnreadMessageCount =
  (spaceId: number, channelId: number, amount = 1): SyncThunk<void> =>
  (dispatch, getState) => {
    const myId = authSelectors.myId(getState()) as number
    const channelUser = channelUserSelectors.byIdSelector(spaceId, channelId, myId)(getState())
    if (!channelUser) {
      return
    }
    dispatch(
      channelUserActions.upsertOne({
        ...channelUser,
        unreadMessageCount: channelUser.unreadMessageCount + amount,
      }),
    )
  }

export const updateChannelStats =
  (message: Message): SyncThunk<void> =>
  (dispatch, getState) => {
    // Don't update channel stats for a message that doesn't show in chat.
    if (!messageUtils.showInChat(message)) {
      return
    }

    const channelStats = channelStatSelectors.byIdSelector(
      message.spaceId,
      message.channelId,
    )(getState())

    if (channelStats) {
      if (channelStats.lastMessageId < message.id) {
        const update = {
          ...channelStats,
          lastMessageId: message.id,
          lastMessageSentAt: message.createdAt,
        }
        dispatch(channelStatActions.upsertOne(update))
      }
    } else {
      dispatch(
        channelStatActions.upsertOne({
          channelId: message.channelId,
          lastMessageId: message.id,
          lastMessageSentAt: message.createdAt,
          spaceId: message.spaceId,
          updatedAt: message.createdAt,
        }),
      )
    }
  }

export const createChannelForm =
  (spaceId: number, formName: string): Thunk<Channel | null> =>
  async (dispatch, getState) => {
    const { name, channelMode, userIds } = formSelectors.values(getState(), formName)
    let channelType: ChannelType = 'PUBLIC_GROUP'

    if (channelMode === 'DIRECT_MESSAGE') {
      channelType = 'DIRECT_MESSAGE'
    } else if (channelMode === 'PRIVATE_GROUP') {
      channelType = 'PRIVATE_GROUP'
    }

    const response = await dispatch(createChannel(spaceId, { channelType, name, userIds }))

    if (response.ok) {
      dispatch(formActions.clear(formName))
      return response.data.channel
    }

    dispatch(setErrors(formName, response.errors, 'Error Creating Channel'))
    return null
  }

export const updateChannelForm =
  (spaceId: number, channelId: number, formName: string): Thunk<Channel | null> =>
  async (dispatch, getState) => {
    const { name, userIds } = formSelectors.values(getState(), formName)
    const response = await dispatch(updateChannel(spaceId, channelId, { name, userIds }))

    if (response.ok) {
      dispatch(formActions.clear(formName))
      return response.data.channel
    }

    dispatch(setErrors(formName, response.errors, 'Error Updating Channel'))
    return null
  }

export const getResolvedChannel =
  (spaceId: number, channelId: number): SyncThunk<ResolvedChannel | undefined> =>
  (dispatch, getState) =>
    entitySelectors.resolvedChannelSelector(spaceId, channelId)(getState())
