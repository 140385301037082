import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import { MyDayScreenProps } from './types'

const LazyMyDayScreen = React.lazy(() => import('./index'))

function MyDayScreen(props: MyDayScreenProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyMyDayScreen {...props} />
    </Suspense>
  )
}

export default MyDayScreen
