import React from 'react'
import Button from '@/components/core/Button'
import Link from '@/components/core/Link'
import ListItem from '@/components/core/ListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import UserProfilePicture from '@/components/users/UserProfilePicture'
import { userUtils } from '@/core/utils'
import { useIsWide } from '@/hooks/appState'
import { useInviteUser } from '@/hooks/restrictedActions'
import { useSpace } from '@/hooks/spaces'
import { useRemoveUser } from '@/hooks/spaces/removeUser'
import { SpaceUser } from '@/types/entities'

interface SpaceUserListItemProps {
  user: SpaceUser
  onSelect: (userId: number) => void
  canEdit?: boolean
  canCancel?: boolean
  onInvited?: () => void
  compact?: boolean
}

const SpaceUserListItem = ({
  user,
  onSelect,
  canEdit = true,
  canCancel = true,
  onInvited,
  compact: requestCompact = false,
}: SpaceUserListItemProps) => {
  const membershipTypeLabel = userUtils.getMembershipTypeLabel(user.membershipType)
  const handleSelect = React.useCallback(() => onSelect(user.id), [onSelect, user])
  const inviteUser = useInviteUser(user, onInvited)
  const handleInviteUser = React.useCallback(async () => {
    await inviteUser()
  }, [inviteUser])
  const space = useSpace(user.spaceId)

  if (!space) {
    throw new Error('Unable to load space')
  }

  const { isRemoving, canRemove, handleRemove } = useRemoveUser(space, user)
  const forceCompact = !useIsWide()
  const compact = requestCompact || forceCompact
  const inviteButtons = (
    <>
      {canRemove && user.membershipType === 'INVITED' && canCancel ? (
        <Button disabled={isRemoving} kind="error" label="Cancel Invite" onPress={handleRemove} />
      ) : null}
      {userUtils.canBeInvited(user) ? (
        <Box gapLeft="medium">
          <Button
            kind="primary"
            label={user.membershipType === 'INVITED' ? 'Resend Invite' : 'Invite'}
            onPress={handleInviteUser}
          />
        </Box>
      ) : null}
    </>
  )

  return (
    <ListItem gapHorizontal="large">
      <Box flex={1}>
        <Box alignItems="center" direction="row">
          <UserProfilePicture size="xlarge" user={user} />
          <Box flex={1} gapLeft="medium" justifyContent="flex-start">
            <Box alignItems="center" direction="row">
              <Text size="h4" weight="medium">
                {user.firstName} {user.lastName}
              </Text>
              {user.membershipType !== 'ACTIVE' ? (
                <Text color={userUtils.membershipTypeToColor(user.membershipType)} size="h6">
                  &nbsp;({membershipTypeLabel})
                </Text>
              ) : null}
            </Box>
            <Text size="h5">{userUtils.getMemberRoleLabel(user.role)}</Text>
          </Box>
          {compact ? null : inviteButtons}
          {canEdit ? (
            <Box gapLeft="large">
              <Link label="Edit" onPress={handleSelect} />
            </Box>
          ) : null}
        </Box>
        {compact ? (
          <Box direction="row" gapTop="medium" justifyContent="center">
            {inviteButtons}
          </Box>
        ) : null}
      </Box>
    </ListItem>
  )
}

export default React.memo(SpaceUserListItem) as typeof SpaceUserListItem
