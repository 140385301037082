import { useCachedSelector, useCurrentUserId } from '@/hooks'
import { channelSelectors } from '@/store/selectors'
import { BaseHubFocus, HubFocus } from '@/types/hubs'
import { MyDayConfig } from '@/types/taskLists'
import { TasksListConfiguration } from '@/types/tasks'

export const useJustYouHub = (spaceId: number) =>
  useCachedSelector(channelSelectors.justYouHubSelector, [spaceId])

export const useHubFocus = (
  hubFocus: BaseHubFocus,
  myDayConfig: MyDayConfig,
  tasksListConfig: TasksListConfiguration,
): HubFocus => {
  const myId = useCurrentUserId()

  if (hubFocus === 'TASKS') {
    const { taskType, taskRelationship } = tasksListConfig

    if (taskType === 'TASK') {
      if (taskRelationship === 'ALL') {
        return 'ALL_TASKS'
      }
      if (taskRelationship === 'MINE') {
        return 'MY_TASKS'
      }
      if (taskRelationship === 'WATCHING') {
        return 'THEIR_TASKS'
      }
    } else if (taskType === 'QUESTION') {
      if (taskRelationship === 'ALL') {
        return 'ALL_QUESTIONS'
      }
      if (taskRelationship === 'MINE') {
        return 'MY_QUESTIONS'
      }
      if (taskRelationship === 'WATCHING') {
        return 'THEIR_QUESTIONS'
      }
    }
    throw new Error(`Invalid task type / task relationship: ${taskType} / ${taskRelationship}`)
  }

  if (hubFocus === 'CHANNEL_TASKS') {
    const { taskType, taskRelationship } = tasksListConfig

    if (taskType === 'TASK') {
      if (taskRelationship === 'ALL') {
        return 'ALL_CHANNEL_TASKS'
      }
      if (taskRelationship === 'MINE') {
        return 'MY_CHANNEL_TASKS'
      }
      if (taskRelationship === 'WATCHING') {
        return 'THEIR_CHANNEL_TASKS'
      }
    } else if (taskType === 'QUESTION') {
      if (taskRelationship === 'ALL') {
        return 'ALL_CHANNEL_QUESTIONS'
      }
      if (taskRelationship === 'MINE') {
        return 'MY_CHANNEL_QUESTIONS'
      }
      if (taskRelationship === 'WATCHING') {
        return 'THEIR_CHANNEL_QUESTIONS'
      }
    }
    throw new Error(`Invalid task type / task relationship: ${taskType} / ${taskRelationship}`)
  }

  if (hubFocus === 'MY_DAY') {
    if (!myDayConfig.userId || myDayConfig.userId === myId) {
      return 'MY_DAY'
    }
    return 'THEIR_DAY'
  }

  return hubFocus
}
