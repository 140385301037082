import { Message, Thread } from '@/types/entities'

export const isThreadHead = (thread: Thread, message: Message) => thread.messageId === message.id

export const shouldHideMessage = (message: Message, thread?: Thread) => {
  if (!thread) {
    return false
  }
  if (!thread.isIgnored) {
    return false
  }
  if (thread.messageId === message.threadId) {
    return true
  }
  return false
}
