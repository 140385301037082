import React from 'react'
import Box from '@/components/layouts/Box'
import {
  MultipleChoiceResponse,
  OpenEndedResponse,
  YesNoResponse,
  useSubmitResponse,
} from '@/components/questions/QuestionResponseForm/responses'
import { ColorName } from '@/core/constants/colors'
import { taskUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'

interface AnswerQuestionContentProps {
  task: QuestionTask
  foregroundColor: ColorName
  onClose: () => void
}

function AnswerQuestionContent({ task, foregroundColor, onClose }: AnswerQuestionContentProps) {
  const { handleSubmitResponse, submitResponse, isSubmitting } = useSubmitResponse(task, onClose)
  const isDisabled = isSubmitting
  const questionMode = taskUtils.getQuestionMode(task.question)

  if (questionMode === 'YES_NO') {
    return (
      <YesNoResponse
        disabled={isDisabled}
        onSubmit={submitResponse}
        submitOnChange={false}
        task={task}
      />
    )
  }
  if (questionMode === 'MULTIPLE_CHOICE') {
    return (
      <Box gapLeft="small">
        <MultipleChoiceResponse
          disabled={isDisabled}
          foregroundColor={foregroundColor}
          task={task}
        />
      </Box>
    )
  }
  if (questionMode === 'OPEN_ENDED') {
    return <OpenEndedResponse autoFocus onSubmitAnswer={handleSubmitResponse} />
  }
  return null
}

export default React.memo(AnswerQuestionContent)
