import React from 'react'
import Badge, { BadgeProps } from '@/components/core/Badge'
import { useUnreadMessagesCountInSelectedSpace } from '@/hooks/messages/unreadMessages'

interface UnreadMessagesInThisSpaceBadgeProps extends Omit<BadgeProps, 'count'> {
  spaceId: number
  hide?: boolean
}

function UnreadMessagesInThisSpaceBadge({
  spaceId,
  hide = false,
  ...badgeProps
}: UnreadMessagesInThisSpaceBadgeProps) {
  const unreadMessagesCount = useUnreadMessagesCountInSelectedSpace(spaceId)
  return <Badge {...badgeProps} count={hide ? 0 : unreadMessagesCount} />
}

export default React.memo(UnreadMessagesInThisSpaceBadge)
