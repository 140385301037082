import React from 'react'
import { collectionUtils as C } from '@/core/utils'
import { useTimeout } from '@/hooks/timeout'
import { KeyType } from '@/types/generics'

interface SegmentState<S, ID extends KeyType> {
  selectedIds: ID[]
  searchText: string | null
  isSearching: boolean
  searchResults: S[] | undefined
  isCreating: boolean
}

type SegmentAction<S, ID extends KeyType> =
  | { type: 'selectSegment'; payload: { segmentId: ID; isSelected: boolean; clearOthers: boolean } }
  | { type: 'setSelectedIds'; payload: { selectedIds: ID[] } }
  | { type: 'setSearchText'; payload: { searchText: string } }
  | { type: 'setSearchResults'; payload: { searchResults: S[] } }
  | { type: 'setIsCreating'; payload: boolean }
  | { type: 'removeSegment'; payload: { segment: S; getSegmentId: (s: S) => ID } }
  | { type: 'editSegment'; payload: { segment: S; getSegmentId: (s: S) => ID } }

type ReducerType<S, ID extends KeyType> = (
  state: SegmentState<S, ID>,
  action: SegmentAction<S, ID>,
) => SegmentState<S, ID>

const segmentStateReducer = <S, ID extends KeyType>(
  state: SegmentState<S, ID>,
  action: SegmentAction<S, ID>,
): SegmentState<S, ID> => {
  const { type } = action
  if (type === 'selectSegment') {
    const { segmentId, isSelected, clearOthers } = action.payload
    let selectedIds: ID[]

    if (isSelected) {
      selectedIds = clearOthers ? [segmentId] : [...state.selectedIds, segmentId]
    } else {
      selectedIds = C.without(segmentId, state.selectedIds)
    }

    return { ...state, selectedIds }
  }

  if (type === 'setSelectedIds') {
    const { selectedIds } = action.payload
    return { ...state, selectedIds }
  }

  if (type === 'setSearchText') {
    const { searchText } = action.payload
    if (searchText) {
      return { ...state, isSearching: true, searchText }
    }
    return { ...state, isSearching: false, searchResults: undefined, searchText }
  }

  if (type === 'setSearchResults') {
    const { searchResults } = action.payload
    return { ...state, isSearching: false, searchResults }
  }

  if (type === 'setIsCreating') {
    return { ...state, isCreating: action.payload }
  }

  if (type === 'removeSegment') {
    const { segment, getSegmentId } = action.payload
    const segmentId = getSegmentId(segment)
    const selectedIds = C.without(segmentId, state.selectedIds)
    const searchResults = state.searchResults?.filter(seg => getSegmentId(seg) !== segmentId)

    return {
      ...state,
      searchResults,
      selectedIds,
    }
  }

  if (type === 'editSegment') {
    const { segment: updated, getSegmentId } = action.payload
    const updatedId = getSegmentId(updated)
    const searchResults = state.searchResults?.map(seg =>
      getSegmentId(seg) === updatedId ? updated : seg,
    )

    return {
      ...state,
      searchResults,
    }
  }

  return state
}

interface SegmentSelectorArgs<S, ID extends KeyType> {
  allowOnlySingleSelection?: boolean
  commitChanges: (ids: ID[], searchResults: S[]) => void
  createSegment: (searchText: string | null) => Promise<S | null>
  deleteSegment: (segment: S) => Promise<boolean>
  editSegment: (segment: S) => Promise<S | null>
  getSegmentId: (segment: S) => ID
  initialSegments: S[]
  initialSelectedIds: ID[]
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  searchDelay?: number
  searchSegments: (searchText: string | null) => Promise<S[] | undefined>
}

export const useSegmentSelector = <S, ID extends KeyType>(args: SegmentSelectorArgs<S, ID>) => {
  const {
    allowOnlySingleSelection = false,
    commitChanges,
    createSegment,
    deleteSegment,
    editSegment,
    getSegmentId,
    initialSelectedIds,
    isOpen,
    onClose,
    searchDelay = 500,
    searchSegments,
  } = args

  const { timeout: searchTimeout, clear: clearSearchTimeout } = useTimeout(searchDelay)

  const [{ selectedIds, searchText, isSearching, isCreating, searchResults }, dispatch] =
    React.useReducer(
      segmentStateReducer as ReducerType<S, ID>,
      React.useMemo(
        (): SegmentState<S, ID> => ({
          isCreating: false,
          isSearching: false,
          searchResults: undefined,
          searchText: null,
          selectedIds: initialSelectedIds || [],
        }),
        [initialSelectedIds],
      ),
    )

  const setSearchText = React.useCallback(
    (value: string) => dispatch({ payload: { searchText: value }, type: 'setSearchText' }),
    [dispatch],
  )

  const setSelectedIds = React.useCallback(
    (nextIds: ID[]) => dispatch({ payload: { selectedIds: nextIds }, type: 'setSelectedIds' }),
    [dispatch],
  )

  const search = React.useCallback(async () => {
    if (!searchSegments) {
      return
    }
    const results = await searchSegments(searchText)
    if (results === undefined) {
      return
    }
    dispatch({ payload: { getSegmentId, searchResults: results }, type: 'setSearchResults' })
  }, [searchSegments, searchText, dispatch, getSegmentId])

  const handleCancel = React.useCallback(() => {
    onClose(true)
  }, [onClose])

  const handleDone = React.useCallback(async () => {
    await commitChanges(selectedIds, searchResults || [])
    dispatch({ payload: { searchText: '' }, type: 'setSearchText' })
    onClose(false)
  }, [commitChanges, selectedIds, dispatch, onClose, searchResults])

  const handleSelect = React.useCallback(
    (segment: S, isSelected: boolean) =>
      dispatch({
        payload: {
          clearOthers: allowOnlySingleSelection,
          isSelected,
          segmentId: getSegmentId(segment),
        },
        type: 'selectSegment',
      }),
    [dispatch, getSegmentId, allowOnlySingleSelection],
  )

  const handleCreate = React.useCallback(async () => {
    dispatch({ payload: true, type: 'setIsCreating' })
    const result = await createSegment(searchText)
    if (result) {
      dispatch({
        payload: {
          clearOthers: allowOnlySingleSelection,
          isSelected: true,
          segmentId: getSegmentId(result),
        },
        type: 'selectSegment',
      })
    }
    await search()
    setTimeout(() => dispatch({ payload: false, type: 'setIsCreating' }))
  }, [dispatch, searchText, createSegment, getSegmentId, search, allowOnlySingleSelection])

  const handleEdit = React.useCallback(
    async (segment: S) => {
      const updated = await editSegment(segment)

      if (updated) {
        dispatch({ payload: { getSegmentId, segment }, type: 'editSegment' })
      }
    },
    [dispatch, editSegment, getSegmentId],
  )

  const handleDelete = React.useCallback(
    async (segment: S) => {
      const ok = await deleteSegment(segment)

      if (ok) {
        dispatch({ payload: { getSegmentId, segment }, type: 'removeSegment' })
      }
    },
    [dispatch, deleteSegment, getSegmentId],
  )

  React.useEffect(() => {
    clearSearchTimeout()
    searchTimeout(search)
  }, [clearSearchTimeout, searchTimeout, search])

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedIds(initialSelectedIds)
    }
  }, [isOpen, setSelectedIds, initialSelectedIds])

  return {
    handleCancel,
    handleCreate,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isCreating,
    isSearching,
    searchResults,
    searchText,
    selectedIds,
    setSearchText,
    setSelectedIds,
  }
}
