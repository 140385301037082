import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'

interface AddAttachmentProps {
  onAdd: () => void
  size?: number
}

function AddAttachment({ onAdd, size = 60 }: AddAttachmentProps) {
  return (
    <Box gapBottom="medium" gapRight="medium">
      <Background
        borderColor="iron"
        borderRadius={5}
        borderStyle="dashed"
        borderWidth={1}
        color="transparent"
      >
        <Touchable feedback="highlight" onPress={onAdd}>
          <Box alignItems="center" height={size} justifyContent="center" width={size}>
            <Icon name="plus" size="h3" />
          </Box>
        </Touchable>
      </Background>
    </Box>
  )
}

export default React.memo(AddAttachment)
