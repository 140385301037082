export const logLevels = {
  debug: 4,
  error: 1,
  info: 3,
  off: 0,
  warning: 2,
}

export type LogLevel = keyof typeof logLevels

export type LogOptions = {
  traceId?: string
  context?: any
}
