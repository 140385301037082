import React from 'react'
import { ActionProps } from '@/components/core/Action'
import { useSummary } from '@/hooks/summaries'
import { useSelectTasks } from '@/hooks/tasks/select'
import { SummaryMode } from '@/types/summaries'

export type SummaryModalHookArgs = {
  initialSummaryMode: SummaryMode | null
  spaceId: number
  summaryId: number
}

export const useSummaryModal = ({
  initialSummaryMode,
  spaceId,
  summaryId,
}: SummaryModalHookArgs) => {
  const { summary, status, message } = useSummary(spaceId, summaryId)
  const [mode, setMode] = React.useState<SummaryMode | null>(initialSummaryMode)

  const handleEditSummary = React.useCallback(() => setMode('EDIT'), [setMode])
  const handleShowSummary = React.useCallback(() => setMode('VIEW'), [setMode])
  const handleSelectTasks = useSelectTasks(spaceId)

  const rightAction = React.useMemo<ActionProps | null>(() => {
    if (!summary) {
      return null
    }

    if (mode === 'EDIT') {
      return {
        label: 'Preview',
        onAction: handleShowSummary,
        type: 'linkPrimary',
      }
    }

    if (summary.status === 'OPEN') {
      return {
        label: 'Edit',
        onAction: handleEditSummary,
        type: 'linkPrimary',
      }
    }

    if (summary.status === 'COMPLETED') {
      return null
    }

    return null
  }, [handleShowSummary, handleEditSummary, mode, summary])

  React.useEffect(() => {
    if (summary && mode === null) {
      setMode(summary.status === 'OPEN' ? 'EDIT' : 'VIEW')
    }
  }, [summary, mode, setMode])

  return {
    handleEditSummary,
    handleSelectTasks,
    handleShowSummary,
    message,
    mode,
    rightAction,
    status,
    summary,
  }
}
