import React from 'react'
import { useCurrentUserId, useUser } from '@/hooks'
import { useChannel } from '@/hooks/channels'

export const useEmptyTasksList = (spaceId: number, channelId?: number | null) => {
  const channel = useChannel(spaceId || 0, channelId || 0)
  const myId = useCurrentUserId()

  const partnerId = React.useMemo(() => {
    if (!channel) {
      return undefined
    }
    const otherUserIds = channel.userIds.filter(userId => userId !== myId)
    if (otherUserIds.length === 1) {
      return otherUserIds[0]
    }
    return undefined
  }, [channel, myId])

  const partner = useUser(channel?.spaceId ?? 0, partnerId ?? 0)

  return { partner }
}
