import React from 'react'
import Box from '@/components/layouts/Box'
import { fnUtils, messageUtils, threadUtils } from '@/core/utils'
import { useUser } from '@/hooks'
import { ChannelUser } from '@/types/entities'
import { MessageListItemData } from '@/types/messaging'
import DayIndicator from './DayIndicator'
import MessageAvatar from './MessageAvatar'
import MessageListItemBody from './MessageListItemBody'
import MessageListItemHeader from './MessageListItemHeader'
import MessageTimestamp from './MessageTimestamp'
import NewMessageBanner from './NewMessageBanner'

interface MessageListItemProps {
  channelUser: ChannelUser
  item: MessageListItemData
  showOtherAvatar: boolean
  isDisplayedInThread: boolean
  isFirstUnreadMessage: boolean
}

function MessageListItem({
  channelUser,
  item,
  showOtherAvatar,
  isDisplayedInThread,
  isFirstUnreadMessage,
}: MessageListItemProps) {
  const { task, message, previousMessage, readReceipts, thread } = item
  const { spaceId, createdAt } = message
  // In the case of messages converted to tasks the task creator may be different from the
  // message creator, and should be indicated as the creator in the list
  const creatorId = task?.creatorId || message.creatorId

  const isFromMe = channelUser.userId === creatorId
  const creator = useUser(spaceId, creatorId)

  if (!creator) {
    return fnUtils.fail('Message creator not found')
  }

  if (threadUtils.shouldHideMessage(message, thread)) {
    return null
  }

  if (!item.showInChat && !isDisplayedInThread) {
    return null
  }

  return (
    <>
      <Box direction="row" gapHorizontal="medium">
        <MessageAvatar
          isFromMe={isFromMe}
          showOtherAvatar={showOtherAvatar}
          spaceId={message.spaceId}
          userId={creatorId}
        />
        <Box flex={1}>
          <MessageListItemHeader
            isDisplayedInThread={isDisplayedInThread}
            isFromMe={isFromMe}
            item={item}
          />
          <MessageListItemBody
            isDisplayedInThread={isDisplayedInThread}
            isFromMe={isFromMe}
            item={item}
          />
          <MessageTimestamp isFromMe={isFromMe} message={message} readReceipts={readReceipts} />
        </Box>
      </Box>
      {!previousMessage || !messageUtils.isSameDay(message, previousMessage) ? (
        <DayIndicator date={createdAt} />
      ) : null}
      <NewMessageBanner visible={isFirstUnreadMessage} />
    </>
  )
}

export default React.memo(MessageListItem)
