import React from 'react'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import StackContextContainer from '@/components/screens/StackContextContainer'
import TaskFormAttachmentsList from '@/components/tasks/TaskForm/TaskFormAttachmentsList'

interface TaskAttachmentsModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskAttachmentsModal({ isOpen, onClose, localModal = false }: TaskAttachmentsModalProps) {
  const [attachmentIds, setAttachmentIds] = React.useState<number[]>([])
  const hasAttachments = !!attachmentIds.length

  const rightAction = React.useMemo(
    () => ({
      label: hasAttachments ? 'Done' : 'Cancel',
      onAction: hasAttachments ? () => onClose(false) : () => onClose(true),
      type: 'linkPrimary' as const,
    }),
    [hasAttachments, onClose],
  )

  return (
    <StandardModal
      isOpen={isOpen}
      localModal={localModal}
      onRequestClose={() => onClose(true)}
      rightAction={rightAction}
      title="Attachments"
    >
      <StackContextContainer>
        <Box gapHorizontal="medium">
          <TaskFormAttachmentsList canAdd onChange={setAttachmentIds} />
        </Box>
      </StackContextContainer>
    </StandardModal>
  )
}

export default React.memo(TaskAttachmentsModal)
