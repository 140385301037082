import React from 'react'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import { addUserWarning } from '@/core/constants/billing'
import { userUtils } from '@/core/utils'
import { isExecutive } from '@/core/utils/users'
import { useFormField, useFormValue } from '@/hooks'
import { useAlertActionsAsync } from '@/hooks/actions'
import { useUpgradePrompt } from '@/hooks/restrictedActions'
import { useSpace } from '@/hooks/spaces'
import type { ActionArgs, ActionOptionAsync } from '@/types/actions'
import { AmplitudeInviteContext } from '@/types/amplitude'
import { SpaceMemberRole } from '@/types/entities'

interface InviteFormProps {
  amplitudeInviteContext: AmplitudeInviteContext
  fieldPrefix?: string
  formName: string
  role: SpaceMemberRole | null
  showNameContext?: boolean
  spaceId: number | null
  requireLastName?: boolean
}

const prefixFieldName = (prefix: string | undefined, fieldName: string): string => {
  if (!prefix) {
    return fieldName
  }
  return prefix + fieldName[0].toUpperCase() + fieldName.slice(1)
}

type ConfirmInvite = 'YES' | 'NO'
const options: ActionOptionAsync<ConfirmInvite, null>[] = [
  { label: 'Upgrade Free Trial', type: 'primary', value: 'YES' },
  { label: 'Cancel', type: 'cancel', value: 'NO' },
]

function InviteForm({
  amplitudeInviteContext,
  role,
  formName,
  fieldPrefix,
  showNameContext = false,
  spaceId,
  requireLastName = false,
}: InviteFormProps) {
  const [showPartnerEmail, setShowPartnerEmail] = React.useState(false)
  const space = useSpace(spaceId || 0)
  const isFreeTrial = !space || space.accountType === 'FREE_TRIAL'

  const alertArgs = React.useMemo<ActionArgs>(
    () => ({
      message: '(Still free, still no credit card.)',
      title: `Inviting your ${
        role ? userUtils.getMemberRoleLabel(role) : 'partner'
      } will upgrade your free trial to our Partnership Plan.`,
    }),
    [role],
  )
  const showAlert = useAlertActionsAsync(options, alertArgs)
  const showPrompt = useUpgradePrompt({
    onUpgraded: () => setShowPartnerEmail(true),
    spaceId: spaceId || 0,
    upgradeAction: 'invite someone',
  })

  const { setValue: setAsPartnershipValue } = useFormField<boolean>(formName, 'as_partnership')
  const theirFirstName = useFormValue(formName, prefixFieldName(fieldPrefix, 'firstName'))
  const inviteNeedsUpgrade = !space || space.productVersion !== 'PARTNERSHIP'

  const toggleShowPartnerEmail = React.useCallback(async () => {
    if (showPartnerEmail || !inviteNeedsUpgrade) {
      setShowPartnerEmail(val => !val)
      return
    }

    if (spaceId) {
      showPrompt()
    } else {
      const alertResult = await showAlert(null)
      if (alertResult === 'YES') {
        setShowPartnerEmail(true)
      }
    }
  }, [showPartnerEmail, setShowPartnerEmail, inviteNeedsUpgrade, showAlert, showPrompt, spaceId])

  React.useEffect(() => {
    setAsPartnershipValue(showPartnerEmail)
  }, [setAsPartnershipValue, showPartnerEmail])

  if (!role) {
    return null
  }

  return (
    <Box gapTop="large">
      {showNameContext ? (
        <Box>
          <Text size="h5">
            {isExecutive(role) ? (
              <>What is the name of the Executive you support?</>
            ) : (
              <>What is the name of your Executive Assistant?</>
            )}
          </Text>
          <Box gapTop="medium">
            <Text size="h5">
              (We&apos;ll use this for your team name, you can always change it later.)
            </Text>
          </Box>
        </Box>
      ) : null}
      <Box direction="row" gapTop="xlarge">
        <Box flex={1}>
          <FormTextInput
            autoCapitalize="words"
            autoCorrect={false}
            autoFocus
            fieldName={prefixFieldName(fieldPrefix, 'firstName')}
            formName={formName}
            label="Their First Name"
            placeholder="First"
          />
        </Box>
        {requireLastName || showPartnerEmail ? (
          <>
            <Box width={10} />
            <Box flex={1}>
              <FormTextInput
                autoCapitalize="words"
                autoCorrect={false}
                fieldName={prefixFieldName(fieldPrefix, 'lastName')}
                formName={formName}
                label="Their Last Name"
                placeholder="Last"
              />
            </Box>
          </>
        ) : null}
      </Box>
      {showPartnerEmail ? (
        <>
          <Box gapTop="medium">
            <FormTextInput
              autoCapitalize="none"
              autoCorrect={false}
              autoFocus
              fieldName={prefixFieldName(fieldPrefix, 'emailAddress')}
              formName={formName}
              keyboardType="email-address"
              label="Their Work Email"
              placeholder="name@company.com"
            />
          </Box>
          {isFreeTrial ? null : (
            <Box gapTop="medium">
              <Text align="center" color="emmre-blue" size="h5">
                {addUserWarning}
              </Text>
            </Box>
          )}
        </>
      ) : (
        <Box direction="row" gapTop="large" justifyContent="center">
          <Link
            amplitudeEventName={inviteAmplitudeEventName(amplitudeInviteContext, role)}
            label={theirFirstName ? `Invite ${theirFirstName} to Emmre` : 'Invite to Emmre'}
            onPress={toggleShowPartnerEmail}
          />
        </Box>
      )}
    </Box>
  )
}
const inviteAmplitudeEventName = (context: AmplitudeInviteContext, role: SpaceMemberRole) =>
  isExecutive(role) ? `invite executive - ${context}` : `invite executive assistant - ${context}`

export default React.memo(InviteForm)
