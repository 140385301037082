import React from 'react'
import { SectionList, SectionListRenderItemInfo } from 'react-native'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import { fnUtils, platformUtils } from '@/core/utils'
import { useSectionListGetItemLayout } from '@/hooks/components'
import { useFilteredEmojiGroups } from '@/hooks/emoji'
import { OnKeyPress } from '@/types/components'
import { Emoji, EmojiGroup, EmojiRow, SelectEmoji } from '@/types/emoji'
import ActivityIndicator from '../ActivityIndicator'
import TextInput from '../TextInput'
import EmojiCategoryList from './EmojiCategoryList'
import EmojiListHeader from './EmojiListHeader'
import EmojiListRow from './EmojiListRow'
import { lineHeight } from './constants'
import { useFrequentlyUsedGroup } from './frequentlyUsed'

type EmojiItem = SectionListRenderItemInfo<EmojiRow, EmojiGroup>

interface EmojiPickerProps {
  height?: number
  onCancel: () => void
  onPick: SelectEmoji
  rowWidth?: number
  showFrequentlyUsedOnFilter?: boolean
}
const keyExtractor = (row: EmojiRow) => row[0].symbol

const renderSectionHeader = ({ section }: { section: EmojiGroup }) => (
  <EmojiListHeader emojiGroup={section} />
)

function EmojiPicker({
  onCancel,
  height,
  onPick,
  rowWidth = 8,
  showFrequentlyUsedOnFilter = false,
}: EmojiPickerProps) {
  const { frequentlyUsedGroup, addToFrequentlyUsed } = useFrequentlyUsedGroup(
    rowWidth * 3,
    rowWidth,
  )
  const handlePick = React.useCallback(
    (emoji: Emoji) => {
      addToFrequentlyUsed(emoji)
      onPick(emoji)
    },
    [onPick, addToFrequentlyUsed],
  )

  const renderItem = React.useCallback(
    (data: EmojiItem) => (
      <EmojiListRow
        key={data.item[0].symbol}
        emojis={data.item}
        onSelect={handlePick}
        width={rowWidth}
      />
    ),
    [handlePick, rowWidth],
  )
  const [filterText, setFilterText] = React.useState('')
  const { groups, isLoading } = useFilteredEmojiGroups(rowWidth, filterText)
  const allGroups = React.useMemo(() => {
    if (
      frequentlyUsedGroup &&
      frequentlyUsedGroup.data.length !== 0 &&
      (!filterText || showFrequentlyUsedOnFilter)
    ) {
      return [frequentlyUsedGroup, ...groups]
    }
    return groups
  }, [groups, frequentlyUsedGroup, filterText, showFrequentlyUsedOnFilter])
  const categoryKeys = React.useMemo(() => allGroups.map(g => g.key), [allGroups])
  const sectionListRef = React.useRef<any>(null)

  const getItemLayout = useSectionListGetItemLayout(groups, lineHeight)

  const handleEscape = React.useCallback<OnKeyPress>(
    ({ nativeEvent }) => {
      if (nativeEvent.key === 'Escape') {
        onCancel()
      }
    },
    [onCancel],
  )
  const handleSelectSection = React.useCallback(
    (categoryKey: string) => {
      const sectionIndex = categoryKeys.indexOf(categoryKey)

      if (sectionIndex !== undefined) {
        sectionListRef.current?.scrollToLocation({
          animated: true,
          itemIndex: 0,
          sectionIndex,
        })
      }
    },
    [categoryKeys],
  )

  return (
    <Box direction="column" height={height}>
      <Box gapTop="small">
        <EmojiCategoryList categoryNames={categoryKeys} onSelect={handleSelectSection} />
      </Box>
      <Box gapTop="small">
        <TextInput
          autoFocus={platformUtils.isWeb}
          onChangeText={setFilterText}
          onKeyPress={handleEscape}
          placeholder="Search emoji..."
          value={filterText}
        />
        {isLoading ? (
          <FloatContainer right={20} top={20}>
            <ActivityIndicator color="obsidian" delay={100} size="small" />
          </FloatContainer>
        ) : null}
      </Box>
      <SectionList
        ref={sectionListRef}
        getItemLayout={getItemLayout}
        keyExtractor={keyExtractor}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled
        onScrollToIndexFailed={fnUtils.noOp}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        sections={allGroups}
      />
    </Box>
  )
}

export default React.memo(EmojiPicker)
