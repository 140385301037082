import React from 'react'
import { FlatList, FlatListProps, ListRenderItemInfo } from 'react-native'
import WebDraggableFlatListContext from './WebDraggableFlatListContext'
import { useWebDraggableFlatListContextValue } from './hooks'
import { DragBeginHandler, DragEndHandler, DragListRenderItemInfo } from './types'

export { default as WebDraggableListItem } from './WebDraggableListItem'

interface WebDraggableFlatListProps<ItemT> extends Omit<FlatListProps<ItemT>, 'renderItem'> {
  renderItem: (data: DragListRenderItemInfo<ItemT>) => React.ReactElement | null
  onDragBegin: DragBeginHandler
  onDragEnd: DragEndHandler
}

function WebDraggableFlatList<ItemT>({
  onDragBegin,
  onDragEnd,
  renderItem,
  ...flatListProps
}: WebDraggableFlatListProps<ItemT>) {
  const contextValue = useWebDraggableFlatListContextValue({ onDragBegin, onDragEnd })
  const { fromIndex } = contextValue

  const renderWrapper = React.useCallback(
    (data: ListRenderItemInfo<ItemT>) =>
      renderItem({ ...data, isActive: data.index === fromIndex }),
    [fromIndex, renderItem],
  )

  return (
    <WebDraggableFlatListContext.Provider value={contextValue}>
      <FlatList renderItem={renderWrapper} {...flatListProps} />
    </WebDraggableFlatListContext.Provider>
  )
}

export default React.memo(WebDraggableFlatList) as typeof WebDraggableFlatList

export type { DragListRenderItemInfo }
