import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { Channel, ChannelStats, ChannelUser } from '@/types/entities'

export interface ChannelResponse {
  channel: Channel
}

export interface CreateChannelResponse {
  channel: Channel
  channelUser: ChannelUser
}

export interface ChannelsResponse {
  channels: Channel[]
}

export interface ChannelUserResponse {
  channelUser: ChannelUser
}

export interface ChannelsUsersResponse {
  channelsUsers: ChannelUser[]
}

export interface ChannelStatsResponse {
  channelStats: ChannelStats[]
}

export type CreateChannel = Pick<Channel, 'channelType' | 'name' | 'userIds'>

export type UpdateChannel = Pick<Channel, 'name' | 'userIds'>

export const getChannelsList = (
  spaceId: number,
  updatedSince: string | null,
  paging: PagingRequest,
) =>
  new Request<ChannelsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['updatedSince', updatedSince]],
    url: `v1/spaces/${spaceId}/channels`,
  })

export const createChannel = (spaceId: number, channel: CreateChannel) =>
  new Request<CreateChannelResponse>({
    authentication: 'PRIVATE',
    body: channel,
    method: 'POST',
    url: `v1/spaces/${spaceId}/channels`,
  })

export const updateChannel = (spaceId: number, channelId: number, channel: UpdateChannel) =>
  new Request<ChannelResponse>({
    authentication: 'PRIVATE',
    body: channel,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/channels/${channelId}`,
  })

export const archiveChannel = (spaceId: number, channelId: number) =>
  new Request<ChannelResponse>({
    authentication: 'PRIVATE',
    method: 'PUT',
    url: `v1/spaces/${spaceId}/channels/${channelId}/archive`,
  })

export const getMyChannelsUsersList = (spaceId: number, paging: PagingRequest) =>
  new Request<ChannelsUsersResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/spaces/${spaceId}/channels-users`,
  })

export const getChannelsUsersList = (spaceId: number, channelId: number, paging: PagingRequest) =>
  new Request<ChannelsUsersResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/spaces/${spaceId}/channels/${channelId}/channels-users`,
  })

export const updateChannelUser = (
  spaceId: number,
  channelId: number,
  userId: number,
  changes: Partial<ChannelUser>,
) =>
  new Request<ChannelUserResponse>({
    authentication: 'PRIVATE',
    body: changes,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/channels/${channelId}/users/${userId}`,
  })

export const setDefaultChannel = (spaceId: number, channelId: number) =>
  new Request<ChannelsUsersResponse>({
    authentication: 'PRIVATE',
    method: 'PUT',
    url: `v1/spaces/${spaceId}/channels/${channelId}/set-default`,
  })

export const removeUserFromChannel = (spaceId: number, channelId: number, userId: number) =>
  new Request<ChannelResponse>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/channels/${channelId}/users/${userId}`,
  })

export const getMyChannelStatsList = (
  spaceId: number,
  updatedSince: string | null,
  paging: PagingRequest,
) =>
  new Request<ChannelStatsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['updatedSince', updatedSince]],
    url: `v1/spaces/${spaceId}/channel-stats`,
  })
