import React from 'react'
import ContainedIcon from '@/components/core/ContainedIcon'
import { TaskUrgency } from '@/types/entities'

interface TaskPriorityIconProps {
  urgency: TaskUrgency
}

const buttonSize = 20
const buttonPadding = 4
const buttonShape = 'square'

function TaskPriorityIcon({ urgency }: TaskPriorityIconProps) {
  if (urgency === 'LOW') {
    return (
      <ContainedIcon
        backgroundColor="transparent"
        color="medium-indigo"
        kind="solid"
        name="arrow-down-alt"
        padding={buttonPadding}
        shape={buttonShape}
        size={buttonSize}
      />
    )
  }
  if (urgency === 'MEDIUM') {
    return (
      <ContainedIcon
        backgroundColor="transparent"
        color="koala"
        name="star"
        padding={buttonPadding}
        shape={buttonShape}
        size={buttonSize}
      />
    )
  }

  return (
    <ContainedIcon
      backgroundColor="transparent"
      color="yellow"
      kind="solid"
      name="star"
      padding={buttonPadding}
      shape={buttonShape}
      size={buttonSize}
    />
  )
}

export default React.memo(TaskPriorityIcon)
