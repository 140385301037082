import React from 'react'
import BaseIconButton from '@/components/core/BaseIconButton'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'

interface AddChoiceButtonProps {
  onAdd: () => void
}

function AddChoiceButton({ onAdd }: AddChoiceButtonProps) {
  return (
    <Touchable feedback="opacity" hitSlop="none" onPress={onAdd}>
      <Box alignItems="center" direction="row" gapBottom="small">
        <BaseIconButton color="iron" kind="solid" name="plus" padding={3} size="h4" />
        <Box flex={1} gapLeft="medium">
          <Text color="iron">Add Option</Text>
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(AddChoiceButton)
