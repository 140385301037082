import React from 'react'
import { StyleSheet, View } from 'react-native'
import Text from '@/components/core/Text'
import { getColor } from '@/core/constants/colors'
import { fileUtils } from '@/core/utils'
import { Attachment } from '@/types/api'
import { Measurement } from '@/types/components'

interface GenericAttachmentListItemProps {
  attachment: Attachment
  size: Measurement
}

function GenericAttachmentListItem({ attachment, size }: GenericAttachmentListItemProps) {
  return (
    <View style={[size, styles.container]}>
      <Text>{fileUtils.getFilename(attachment.filename)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: getColor('heather'),
    borderRadius: 5,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    padding: 5,
  },
})

export default React.memo(GenericAttachmentListItem)
