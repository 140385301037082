import React from 'react'
import { fnUtils } from '@/core/utils'
import { Action } from '@/types/generics'

type NotificationContextType = Action<boolean>

const NotificationContext = React.createContext<NotificationContextType>(fnUtils.noOp)

export const useNotificationContext = () => React.useContext(NotificationContext)

export default NotificationContext
