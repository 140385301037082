import React from 'react'
import { Colors, ExpandablePadding } from '@/types/components'
import { FontSize } from '@/types/typography'
import BaseButton, { BaseButtonProps } from './BaseButton'

export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'light'
  | 'dark'
  | 'transparentLight'
  | 'transparentDark'
  | 'transparentPrimary'
  | 'warning'
type ColorsPair = { enabled: Colors; disabled: Colors }
type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'input'

interface ButtonProps
  extends Omit<BaseButtonProps, 'foregroundColor' | 'backgroundColor' | 'padding' | 'borderColor'> {
  kind: ButtonKind
  size?: Size
}

function Button({ disabled = false, kind, size = 'medium', ...baseButtonProps }: ButtonProps) {
  const { foreground, background, border } = colorMap[kind][disabled ? 'disabled' : 'enabled']

  return (
    <BaseButton
      labelSize={labelSize[size]}
      {...baseButtonProps}
      backgroundColor={background}
      borderColor={border}
      disabled={disabled}
      foregroundColor={foreground}
      padding={padding[size]}
    />
  )
}

export default React.memo(Button)

const padding: Record<Size, ExpandablePadding> = {
  input: 10,
  large: 20,
  medium: 7,
  small: [2, 6],
  xsmall: [1, 10],
}

const labelSize: Record<Size, FontSize> = {
  input: 'h4',
  large: 'h4',
  medium: 'h4',
  small: 'h4',
  xsmall: 'h5',
}

export const colorMap: Record<ButtonKind, ColorsPair> = {
  dark: {
    disabled: {
      background: 'heather',
      border: 'obsidian',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'transparent',
      border: 'iron',
      foreground: 'shadow',
    },
  },
  error: {
    disabled: {
      background: 'heather',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'red',
      foreground: 'ghost',
    },
  },
  light: {
    disabled: {
      background: 'heather',
      border: 'obsidian',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'transparent',
      border: 'ghost',
      foreground: 'ghost',
    },
  },
  primary: {
    disabled: {
      background: 'heather',
      border: 'iron',
      foreground: 'anchor',
    },
    enabled: {
      background: 'emmre-blue',
      foreground: 'ghost',
    },
  },
  secondary: {
    disabled: {
      background: 'heather',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'emmre-purple',
      foreground: 'ghost',
    },
  },
  success: {
    disabled: {
      background: 'heather',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'emmre-green',
      foreground: 'ghost',
    },
  },
  transparentDark: {
    disabled: {
      background: 'heather',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'transparent',
      foreground: 'shadow',
    },
  },
  transparentLight: {
    disabled: {
      background: 'heather',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'transparent',
      foreground: 'ghost',
    },
  },
  transparentPrimary: {
    disabled: {
      background: 'heather',
      foreground: 'obsidian',
    },
    enabled: {
      background: 'transparent',
      foreground: 'emmre-blue',
    },
  },
  warning: {
    disabled: {
      background: 'heather',
      border: 'iron',
      foreground: 'anchor',
    },
    enabled: {
      background: 'cheddar',
      foreground: 'obsidian',
    },
  },
}
