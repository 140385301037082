import React from 'react'
import { StyleSheet, View } from 'react-native'
import { GiphyGif } from '@/types/giphy'
import CachedImage from '../CachedImage'
import Touchable from '../Touchable'

interface GiphyItemProps {
  gif: GiphyGif
  onPress: (gif: GiphyGif) => void
  width: number
}

function GiphyItem({ gif, onPress, width }: GiphyItemProps) {
  const handleOnPress = React.useCallback(() => {
    onPress(gif)
  }, [onPress, gif])

  const size = React.useMemo(() => ({ height: width, width }), [width])

  return (
    <View style={styles.container}>
      <Touchable key={gif.images.original.url} feedback="opacity" onPress={handleOnPress}>
        <CachedImage duration="short" fit="HEIGHT" size={size} uri={gif.images.preview_gif.url} />
      </Touchable>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    padding: 5,
  },
})

export default React.memo(GiphyItem)
