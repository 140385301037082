import Constants from 'expo-constants'
import { Platform } from 'react-native'
import { Configuration, FeatureName } from './base'
import { getConfiguration as devConfig } from './development'
import { getConfiguration as productionConfig } from './production'
import { getConfiguration as stagingConfig } from './staging'
import { getConfiguration as testingConfig } from './testing'

const envs = new Set(['testing', 'production', 'staging', 'development'])

const getEnv = (): string => {
  if (Platform.OS === 'web') {
    // @ts-ignore
    const env = global?.env || 'production'

    if (envs.has(env)) {
      return env
    }
  }

  return Constants.manifest?.extra?.env || 'testing'
}

const config = ((): Configuration => {
  const env = getEnv()

  if (env === 'testing') {
    return testingConfig()
  }
  if (env === 'development') {
    return devConfig()
  }
  if (env === 'staging') {
    return stagingConfig()
  }
  if (env === 'production') {
    return productionConfig()
  }

  throw new Error(`Unable to find configuration for ${env} environment`)
})()

export const hasFeature = (featureName: FeatureName): boolean => config.features[featureName]

export default config
