import React from 'react'
import {
  TextInput as CoreInput,
  TextInputProps as CoreInputProps,
  TextStyle as CoreInputStyle,
  StyleSheet,
  View,
} from 'react-native'
import { getColor } from '@/core/constants/colors'
import { getFontFamily, getFontSize, getLineHeight } from '@/core/constants/typography'
import { platformUtils, typeUtils } from '@/core/utils'
import { useTextContentSizeChange } from '@/hooks/textInput'
import Box from '../layouts/Box'
import Label from './Label'
import Text from './Text'

export interface TextInputProps extends Omit<CoreInputProps, 'style' | 'value'> {
  label?: string | null
  error?: string | null
  kind?: 'standard' | 'bare' | 'title' | 'taskTitle' | 'inset' | 'question' | 'emmre'
  maxHeight?: number
  minHeight?: number
  value?: string | null
  style?: CoreInputStyle
  expandable?: boolean
  labelAccessory?: string | null
}

export type RefType = React.ForwardedRef<CoreInput>

const TextInput = React.forwardRef(
  (
    {
      error = null,
      label = null,
      kind = 'standard',
      maxHeight,
      minHeight: propsMinHeight,
      value,
      editable = true,
      style: propsStyle,
      expandable = false,
      labelAccessory,
      ...textInputProps
    }: TextInputProps,
    ref: RefType,
  ) => {
    const hasError = typeUtils.isString(error)
    const { handleContentSizeChange, height } = useTextContentSizeChange(value ?? '')
    const minHeight = expandable ? height : propsMinHeight
    const style = React.useMemo(() => {
      const baseStyle: CoreInputStyle[] = [styles[kind]]
      const extra: CoreInputStyle = {}

      if (propsStyle) {
        baseStyle.push(propsStyle)
      }

      if (maxHeight) {
        extra.maxHeight = maxHeight
        if (minHeight) {
          extra.minHeight = Math.min(minHeight, maxHeight)
        }
      } else if (minHeight) {
        extra.minHeight = minHeight
      }

      if (kind === 'taskTitle' && !value) {
        extra.fontFamily = getFontFamily('regular', false)
      }

      if (!editable) {
        extra.color = getColor('koala')
      }

      if (platformUtils.isWeb) {
        // react-native-web adds outline props to ViewStyle
        // @ts-ignore
        extra.outlineStyle = 'none'
      }

      baseStyle.push(extra)

      return baseStyle
    }, [maxHeight, minHeight, kind, value, editable, propsStyle])

    return (
      <View>
        {label ? (
          <Box alignItems="center" direction="row">
            <Box flex={1}>
              <Label kind={hasError ? 'error' : 'dark'} position="top">
                {hasError ? `${label} ${error}` : label}
              </Label>
            </Box>
            <Box gapLeft="xsmall">
              <Text size="h6">{labelAccessory}</Text>
            </Box>
          </Box>
        ) : null}
        <CoreInput
          onContentSizeChange={handleContentSizeChange}
          {...textInputProps}
          {...platformProps}
          ref={ref}
          placeholderTextColor={hasError ? getColor('red') : getColor('iron')}
          style={style}
          value={value || ''}
        />
        {!label && hasError ? (
          <Label kind="error" position="bottom">
            {error}
          </Label>
        ) : null}
      </View>
    )
  },
)

const platformProps: any = platformUtils.isWeb
  ? {
      onClick: (e: any) => e.stopPropagation(),
    }
  : {}

const styles = StyleSheet.create({
  bare: {
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    paddingBottom: 0,
    paddingTop: 0,
  },
  emmre: {
    borderColor: getColor('emmre-blue'),
    borderRadius: 5,
    borderWidth: 1,
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
  },
  errorContainer: {
    marginBottom: 10,
  },
  inset: {
    backgroundColor: getColor('heather'),
    borderRadius: 8,
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  },
  question: {
    backgroundColor: getColor('ghost'),
    borderColor: getColor('iron'),
    borderRadius: 5,
    borderWidth: 1,
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    padding: 5,
  },
  standard: {
    borderColor: getColor('iron'),
    borderRadius: 5,
    borderWidth: 1,
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
  },
  taskTitle: {
    color: getColor('shadow'),
    fontFamily: getFontFamily('medium', false),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    paddingBottom: 5,
    paddingTop: 5,
  },
  title: {
    color: getColor('obsidian'),
    fontSize: getFontSize('h4'),
    lineHeight: getLineHeight('h4'),
    paddingBottom: 5,
    paddingTop: 5,
    textAlign: 'center',
  },
})

export default React.memo(TextInput)
