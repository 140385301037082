import React from 'react'
import ContainedIcon from '@/components/core/ContainedIcon'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { taskUtils } from '@/core/utils'

function TaskAcceptedIndicator() {
  const { task } = useTaskFormContext()

  if (!(task && taskUtils.isAccepted(task))) {
    return null
  }

  return (
    <ContainedIcon
      backgroundColor="emmre-green"
      color="ghost"
      name="thumbs-up"
      padding={8}
      shape="circle"
      size={20}
    />
  )
}

export default React.memo(TaskAcceptedIndicator)
