import React from 'react'
import { ViewStyle } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import { typeUtils } from '@/core/utils'
import { easeInOut } from '@/core/utils/animations'
import Text from '../Text'
import Touchable from '../Touchable'

interface TabConfiguration {
  backgroundColor: ColorName
  borderColor: ColorName
  foregroundColor: ColorName
  isSelected: boolean
}

type RenderTab = (config: TabConfiguration) => React.ReactNode

export type TabOption = {
  label: string | RenderTab
  value: string
}

interface TabProps extends TabConfiguration, TabOption {
  onPress: (value: string) => void
}

function Tab({
  borderColor,
  isSelected,
  label,
  value,
  backgroundColor,
  foregroundColor,
  onPress,
}: TabProps) {
  const style = React.useMemo<ViewStyle>(
    () => ({
      alignItems: 'center',
      backgroundColor: getColor(backgroundColor),
      borderColor: getColor(borderColor),
      borderRadius: 10,
      borderWidth: 1,
      flex: 1,
      justifyContent: 'center',
      padding: 5,
    }),
    [backgroundColor, borderColor],
  )
  const handlePress = React.useCallback(() => {
    easeInOut()
    onPress(value)
  }, [onPress, value])

  return (
    <Touchable feedback="none" onPress={handlePress} style={style}>
      {typeUtils.isString(label) ? (
        <Text color={foregroundColor} size="h5" weight="medium">
          {label}
        </Text>
      ) : (
        label({ backgroundColor, borderColor, foregroundColor, isSelected })
      )}
    </Touchable>
  )
}

export default React.memo(Tab)
