import React from 'react'
import { StyleSheet } from 'react-native'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import ContentContainer from '@/components/layouts/ContentContainer'
import { getColor } from '@/core/constants/colors'
import { stringUtils } from '@/core/utils'

interface UpgradeAccountModalProps {
  onClose: () => void
  daysLeft: number | null
  canClose: boolean
}

function UpgradeAccountModal({ onClose, daysLeft, canClose }: UpgradeAccountModalProps) {
  return (
    <ContentContainer
      avoidKeyboard
      backgroundStyle={styles.background}
      context="MODAL"
      foregroundStyle={styles.foreground}
      scrollable
    >
      <Box gapHorizontal="large" gapVertical="xlarge">
        <Text align="center" color="ghost" size="h1" weight="medium">
          {getLabelText(daysLeft)}
        </Text>
        <Box gapTop="xlarge">
          <Text align="center" color="ghost" size="h3">
            Contact an account administrator to keep your account active!
          </Text>
        </Box>
        {canClose ? (
          <Box gapTop="xlarge">
            <Button kind="warning" label="Got It" onPress={onClose} />
          </Box>
        ) : null}
      </Box>
    </ContentContainer>
  )
}

const getLabelText = (daysLeft: number | null) => {
  if (daysLeft === null) {
    return 'Free trial has expired'
  }

  if (daysLeft >= 1) {
    return `${stringUtils.pluralize(daysLeft, 'day', 'days')} left in free trial`
  }
  if (daysLeft === 0) {
    return 'Free trial expires today'
  }
  return 'Free trial has expired'
}

const styles = StyleSheet.create({
  background: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
  },
  foreground: {
    backgroundColor: getColor('emmre-blue'),
    borderRadius: 7,
    maxHeight: '100%',
    maxWidth: 400,
  },
})
export default React.memo(UpgradeAccountModal)
