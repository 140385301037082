import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Emoji, SelectEmoji } from '@/types/emoji'
import Touchable from '../Touchable'
import { fontSize, lineHeight } from './constants'

type SelectableEmojiListItemProps = {
  emoji: Emoji
  onSelect: SelectEmoji
}
type PlaceholderEmojiListItemProps = { placeholder: true }

function SelectableEmojiListItem({ emoji, onSelect }: SelectableEmojiListItemProps) {
  const handleSelect = React.useCallback(() => onSelect(emoji), [onSelect, emoji])
  return (
    <Touchable feedback="opacity" onPress={handleSelect} style={styles.container}>
      <Text style={styles.text}>{emoji.symbol}</Text>
    </Touchable>
  )
}

function PlaceholderEmojiListItem() {
  return <View style={styles.container} />
}

function EmojiListItem(props: SelectableEmojiListItemProps | PlaceholderEmojiListItemProps) {
  if ('placeholder' in props) {
    return <PlaceholderEmojiListItem />
  }
  return <SelectableEmojiListItem {...props} />
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    height: lineHeight,
    justifyContent: 'center',
  },
  text: {
    fontSize,
    lineHeight,
  },
})
export default React.memo(EmojiListItem)
