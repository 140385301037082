import React from 'react'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import RoleSelector from '@/components/users/RoleSelector'
import { useFormField } from '@/hooks'
import { formName } from './constants'

interface HubRoleBodyProps {
  onInvite: () => void
  isSubmitting: boolean
  canSubmit: boolean
}

function HubRoleBody({ onInvite, isSubmitting, canSubmit }: HubRoleBodyProps) {
  const { value: role, setValue: setRole, error: roleError } = useFormField(formName, 'role')
  const disabled = isSubmitting || !canSubmit

  return (
    <Box gapAll="large">
      <Text align="center" color="emmre-purple">
        Is your{' '}
        <Text color="emmre-purple" italic weight="medium">
          partner
        </Text>{' '}
        an Executive or an Executive Assistant?
      </Text>
      <Box gapTop="medium">
        <RoleSelector
          amplitudeInviteContext="create hub"
          errorMessage={roleError ? 'Please select a role' : null}
          onSelect={setRole}
          roleFor="partner"
          selectedValue={role}
        />
      </Box>
      <Box gapTop="xlarge">
        <Button
          disabled={disabled}
          kind="primary"
          label="Invite and Create Hub"
          onPress={onInvite}
        />
      </Box>
    </Box>
  )
}

export default React.memo(HubRoleBody)
