import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { IconName } from '@/core/constants/icons'

interface QuickDateButtonProps {
  onPress: () => void
  icon: IconName
  title: string
  isSelected: boolean
}

function QuickDateButton({ onPress, icon, title, isSelected }: QuickDateButtonProps) {
  const color: ColorName = isSelected ? 'ghost' : 'obsidian'
  return (
    <Background borderRadius={7} color={isSelected ? 'obsidian' : 'transparent'}>
      <Touchable feedback="highlight" hitSlop="medium" onPress={onPress}>
        <Box alignItems="center" direction="column" gapAll="medium">
          <Box height={24} justifyContent="center">
            <Icon color={color} name={icon} size="h4" />
          </Box>
          <Text color={color} size="h6">
            {title}
          </Text>
        </Box>
      </Touchable>
    </Background>
  )
}

export default React.memo(QuickDateButton)
