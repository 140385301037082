import React from 'react'
import StandardModal from '@/components/layouts/StandardModal'
import { UsersSelector } from '@/components/users/UsersSelector'
import { useUserSelector } from './hooks'

interface TaskUserModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskUserModal({ isOpen, onClose, localModal = false }: TaskUserModalProps) {
  const {
    handleCancel,
    handleDone,
    userId,
    selectUser,
    searchText,
    setSearchText,
    spaceId,
    channelId,
  } = useUserSelector(onClose)

  const userIds = React.useMemo(() => (userId ? [userId] : null), [userId])

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Assign Task"
    >
      <UsersSelector
        channelId={channelId}
        channelMode="FILTER"
        excludeMe={false}
        onChangeSearchText={setSearchText}
        onSetSelection={selectUser}
        searchText={searchText}
        selectedUserIds={userIds}
        spaceId={spaceId}
      />
    </StandardModal>
  )
}

export default React.memo(TaskUserModal)
