import React from 'react'
import Background from '@/components/core/Background'
import Box from '@/components/layouts/Box'

interface InputBorderProps {
  children: React.ReactNode
}

function InputBorder({ children }: InputBorderProps) {
  return (
    <Box gapHorizontal="medium" gapVertical="xsmall">
      <Background borderColor="koala" borderRadius={8} borderWidth={1} color="transparent">
        <Box alignItems="center" direction="row" gapAll="xsmall" gapLeft="none">
          {children}
        </Box>
      </Background>
    </Box>
  )
}

export default React.memo(InputBorder)
