import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '@/store'
import { SlackConnection } from '@/types/entities'
import { createEntityReducers } from './entityReducers'

const adapter = createEntityAdapter<SlackConnection>({
  selectId: connection => connection.id,
  sortComparer: (a, b) => a.slackTeamName.localeCompare(b.slackTeamName),
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/slackConnections',
  reducers: {
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.slackConnections)

const bySpaceIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, slackConnections =>
    slackConnections.filter(conn => conn.spaceId === spaceId),
  )

const selectors = {
  bySpaceIdSelector,
}

export { actions, selectors }
export default reducer
