import { ChecklistItem } from '@/types/entities'

const getCompletedItemsCount = (items: ChecklistItem[]) =>
  items.reduce((acc, item) => acc + (item.completed ? 1 : 0), 0)

export const getCompletedItemsRatio = (
  items: ChecklistItem[] | null | undefined,
): [number, number] => {
  if (!items) {
    return [0, 0]
  }
  return [getCompletedItemsCount(items), items.length]
}
