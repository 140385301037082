import { collectionUtils as C, asyncUtils } from '@/core/utils'
import { QueuedJob } from '@/types/jobScheduling'

export default class JobQueue {
  jobs: QueuedJob[]
  status: 'PROCESSING' | 'WAITING' | 'READY'

  constructor() {
    this.jobs = []
    this.status = 'READY'
  }

  queueJob(job: QueuedJob) {
    this.insertJob(job)
    this.processNextJob()
  }

  queueJobs(jobs: QueuedJob[]) {
    jobs.forEach(job => this.insertJob(job))
    this.processNextJob()
  }

  private insertJob(job: QueuedJob) {
    if (job.priority === 'DISCARD') {
      return
    }

    const insertIndex = getInsertIndex(this.jobs, job)

    if (insertIndex === null) {
      this.jobs.push(job)
    } else {
      this.jobs.splice(insertIndex, 0, job)
    }
  }

  private async processNextJob() {
    const job = this.nextJob()

    if (!job) {
      return
    }

    if (job.priority === 'IMMEDIATE') {
      this.executeJob(job)
    } else {
      if (this.status !== 'READY') {
        return
      }

      const delay = getDelay(job)

      if (delay !== 0) {
        this.status = 'WAITING'
        await asyncUtils.sleep(delay)
      }

      this.status = 'PROCESSING'

      try {
        await this.executeJob(job)
      } finally {
        this.status = 'READY'
      }
    }

    setTimeout(() => this.processNextJob())
  }

  private nextJob() {
    return this.jobs[0]
  }

  private async executeJob(job: QueuedJob) {
    if (!C.removeInPlace(this.jobs, job)) {
      return false
    }

    try {
      await job.task()
    } catch {
      return false
    }

    return true
  }
}

const getOrder = (job: QueuedJob): number => {
  switch (job.priority) {
    case 'IMMEDIATE':
      return 0
    case 'HIGH':
      return 1
    case 'MEDIUM':
      return 2
    case 'LOW':
      return 3
    default:
      throw new Error(`Invalid job priority: ${job.priority}`)
  }
}

const getDelay = (job: QueuedJob): number => {
  switch (job.priority) {
    case 'IMMEDIATE':
      return 0
    case 'HIGH':
      return 0
    case 'MEDIUM':
      return 500
    case 'LOW':
      return 1000
    default:
      throw new Error(`Invalid job priority: ${job.priority}`)
  }
}
const getInsertIndex = (jobs: QueuedJob[], newJob: QueuedJob): number | null => {
  const newJobOrder = getOrder(newJob)

  for (let index = 0; index < jobs.length; index += 1) {
    const job = jobs[index]
    const jobOrder = getOrder(job)

    if (jobOrder > newJobOrder) {
      return index
    }
  }

  return null
}
