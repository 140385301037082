import { Channel, QuestionResponseType, Task } from '@/types/entities'
import { QuestionMode, TaskFormContext, TaskFormSize } from '@/types/tasks'

type GetFormNameArgs = {
  spaceId: number
  task?: Task
  channel?: Channel
  context: TaskFormContext
}

type QuestionForm = {
  questionIsOpenEnded: boolean
  questionResponseType: QuestionResponseType
  questionResponses: string[]
}

export const getFormName = ({ spaceId, task, channel, context }: GetFormNameArgs) => {
  let formName = 'task:space'

  if (task) {
    formName = `${formName}/${task.spaceId}/task/${task.id}`
  } else if (channel) {
    formName = `${formName}/${channel.spaceId}/channel/${channel.id}`
  } else {
    formName = `${formName}/${spaceId}`
  }

  return `${formName}/context/${context}`
}

export const nextSizeUp = (size: TaskFormSize): TaskFormSize => {
  if (size === 'SMALL') {
    return 'MEDIUM'
  }
  return 'LARGE'
}

export const nextSizeDown = (size: TaskFormSize): TaskFormSize => {
  if (size === 'LARGE') {
    return 'MEDIUM'
  }
  return 'SMALL'
}

export const mapFieldToPrompt = (fieldName: string): string => {
  switch (fieldName) {
    case 'spaceId':
      return 'select a space'
    case 'assignedUserId':
      return 'assign to a user'
    case 'content':
      return 'enter a task title'
    case 'questionResponses':
      return 'add at least one choice'
    default:
      throw new Error(`Invalid prompt field: ${fieldName}`)
  }
}

export const taskToFormFields = (task: Task): Record<string, any> => {
  const base: Record<string, any> = { ...task }

  delete base.question

  if (task.question) {
    base.questionIsOpenEnded = task.question.isOpenEnded
    base.questionResponseType = task.question.responseType
    base.questionResponses = task.question.responses.map(response => response.content)
  }

  return base
}

export const getDefaultQuestionFields = (questionMode: QuestionMode): QuestionForm => {
  if (questionMode === 'YES_NO') {
    return {
      questionIsOpenEnded: false,
      questionResponseType: 'SINGLE',
      questionResponses: ['Yes', 'No'],
    }
  }
  if (questionMode === 'MULTIPLE_CHOICE') {
    return {
      questionIsOpenEnded: false,
      questionResponseType: 'SINGLE',
      questionResponses: [],
    }
  }
  if (questionMode === 'OPEN_ENDED') {
    return {
      questionIsOpenEnded: true,
      questionResponseType: 'SINGLE',
      questionResponses: [],
    }
  }
  throw new Error(`Invalid question mode ${questionMode}`)
}
