import React from 'react'
import { Modal, StyleSheet } from 'react-native'
import Button from '@/components/core/Button'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import ContentContainer from '@/components/layouts/ContentContainer'
import { getColor } from '@/core/constants/colors'

interface NotificationModalProps {
  isOpen: boolean
  onRequestPermission: () => Promise<any>
}

function NotificationModal({ isOpen, onRequestPermission }: NotificationModalProps) {
  if (!isOpen) {
    return null
  }

  return (
    <Modal animationType="fade" transparent visible>
      <ContentContainer
        avoidKeyboard
        backgroundStyle={styles.background}
        context="MODAL"
        foregroundStyle={styles.foreground}
        scrollable
      >
        <Box gapHorizontal="large" gapVertical="xlarge">
          <Text align="center" color="ghost" size="h1" weight="medium">
            Don&apos;t let anything fall through the cracks
          </Text>
          <Box alignItems="center" gapTop="xlarge">
            <Icon color="ghost" kind="solid" name="megaphone" size={160} />
          </Box>
          <Box gapTop="xlarge">
            <Text align="center" color="ghost" size="h3">
              Set up push notifications so you know when you&apos;ve been messaged or when you have
              a reminder for a task.
            </Text>
          </Box>
          <Box gapTop="xlarge">
            <Button kind="warning" label="Notification Settings" onPress={onRequestPermission} />
          </Box>
        </Box>
      </ContentContainer>
    </Modal>
  )
}

const styles = StyleSheet.create({
  background: {
    backgroundColor: '#0008',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
  },
  foreground: {
    backgroundColor: getColor('emmre-blue'),
    borderRadius: 7,
    maxHeight: '100%',
    maxWidth: '100%',
  },
})

export default React.memo(NotificationModal)
