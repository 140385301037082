import React from 'react'
import { VerticalSwipeEventArg } from '@/components/gestures/VerticalSwipeContainer'
import { Action } from '@/types/generics'

export const useSwipeHandler = (setIsExpanded: Action<boolean>) => {
  const handleSwipe = React.useCallback(
    (arg: VerticalSwipeEventArg) => {
      if (arg.direction === 'up') {
        setIsExpanded(true)
      } else {
        setIsExpanded(false)
      }
    },
    [setIsExpanded],
  )
  return handleSwipe
}
