import React from 'react'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { AnswerQuestionActions } from '@/components/tasks/TaskForm/QuestionActions'
import QuestionResponseContext, {
  useQuestionResponseForm,
} from '@/contexts/QuestionResponseContext'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { QuestionTask } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'
import AnswerQuestionContent from './AnswerQuestionContent'

interface AnswerQuestionBodyProps {
  task: QuestionTask
  initialQuestionResponse?: QuestionResponseForm
  onEditQuestion: () => void
}

function AnswerQuestionBody({
  task,
  initialQuestionResponse,
  onEditQuestion,
}: AnswerQuestionBodyProps) {
  const { close } = useTaskFormContext()

  return (
    <QuestionResponseContext.Provider
      value={useQuestionResponseForm(task, initialQuestionResponse)}
    >
      <Box gapHorizontal="large">
        <AnswerQuestionActions onClose={close} task={task} />
        <Box gapVertical="medium">
          <Text size="h4">{task.content}</Text>
        </Box>
        <AnswerQuestionContent foregroundColor="obsidian" onClose={close} task={task} />
        <Box direction="row" gapTop="large" justifyContent="center">
          <Link label="Edit Question" onPress={onEditQuestion} />
        </Box>
      </Box>
    </QuestionResponseContext.Provider>
  )
}

export default React.memo(AnswerQuestionBody)
