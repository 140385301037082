import React from 'react'
import { emitter } from '@/core/pubsub'

export const useSubscription = (eventName: string, handler: (arg: any) => void) => {
  React.useEffect(() => {
    emitter.on(eventName, handler)
    return () => {
      emitter.off(eventName, handler)
    }
  }, [eventName, handler])
}

export const usePublisher = () => emitter
