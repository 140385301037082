import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import { appProfileActions } from '@/store/actions'
import { appProfileSelectors, spaceSelectors } from '@/store/selectors'
import { SpacesScreenConfig, SummariesListScreenConfig } from '@/store/slices/appProfile'
import { spaceThunks } from '@/thunks'
import { useHasScope } from './auth'
import { useAppDispatch, useAppSelector } from './redux'

export const useLastAppFocus = (spaceId: number) =>
  useAppSelector(appProfileSelectors.lastFocus, spaceId)

export const useCurrentAppFocus = () => useAppSelector(appProfileSelectors.currentFocus)

const useFocusOnSpace = (spaceId: number | null) => {
  const dispatch = useAppDispatch()

  useFocusEffect(
    React.useCallback(() => {
      if (spaceId) {
        dispatch(appProfileActions.focusOnSpace({ spaceId }))
      }
    }, [dispatch, spaceId]),
  )
}

export const useFocusOnChannel = (spaceId: number, channelId: number) => {
  const dispatch = useAppDispatch()

  useFocusEffect(
    React.useCallback(() => {
      dispatch(appProfileActions.focusOnChannel({ channelId, spaceId }))

      return () => dispatch(appProfileActions.focusOnChannel({ channelId: null, spaceId }))
    }, [dispatch, channelId, spaceId]),
  )
}

export const useRouteSpaceId = (routeSpaceId: number | null, onNoSpaces: () => void) => {
  const dispatch = useAppDispatch()
  const focusedSpaceId = useAppSelector(appProfileSelectors.focusedSpaceId)
  const [spaceId, setSpaceId] = React.useState(routeSpaceId)

  const hasViewScope = useHasScope('spaces', 'view')
  const routeSpace = useAppSelector(spaceSelectors.byId, spaceId || 0)
  const isInvalidSpace = !routeSpace

  const { channelId: focusedChannelId } = useAppSelector(
    appProfileSelectors.lastFocus,
    spaceId || 0,
  )

  const setDefaultSpace = React.useCallback(async () => {
    // If the user can not list spaces assume the route space is correct and the route will
    // properly provide the space
    if (!hasViewScope) {
      return
    }

    const defaultSpace = await dispatch(spaceThunks.getDefaultSpace(focusedSpaceId))

    if (defaultSpace) {
      setSpaceId(defaultSpace.id)
    } else {
      onNoSpaces()
    }
  }, [dispatch, setSpaceId, onNoSpaces, focusedSpaceId, hasViewScope])

  useFocusOnSpace(spaceId)

  React.useEffect(() => {
    setSpaceId(routeSpaceId)
  }, [setSpaceId, routeSpaceId])

  // In several situations we either 1) won't have a space ID yet, or 2) will have an invalid
  // space ID. This will make it so the user is redirected to a good space should there be one
  React.useEffect(() => {
    if (isInvalidSpace) {
      setDefaultSpace()
    }
  }, [isInvalidSpace, setDefaultSpace])

  return {
    channelId: focusedChannelId,
    spaceId,
  }
}

export const useSpacesScreenConfig = () => {
  const dispatch = useAppDispatch()
  const spacesScreenConfig = useAppSelector(appProfileSelectors.spacesScreenConfig)
  const updateSpacesScreenConfig = React.useCallback(
    (updates: Partial<SpacesScreenConfig>) =>
      dispatch(appProfileActions.updateSpacesScreenConfig(updates)),
    [dispatch],
  )
  const switchPanes = React.useCallback(() => dispatch(appProfileActions.switchPanes()), [dispatch])
  return {
    spacesScreenConfig,
    switchPanes,
    updateSpacesScreenConfig,
  }
}

const useSummariesListScreenConfig = () => {
  const dispatch = useAppDispatch()
  const summariesListScreenConfig = useAppSelector(appProfileSelectors.summariesListScreenConfig)
  const updateSummariesListScreenConfig = React.useCallback(
    (
      updates:
        | Partial<SummariesListScreenConfig>
        | ((current: SummariesListScreenConfig) => Partial<SummariesListScreenConfig>),
    ) => {
      if (typeof updates === 'function') {
        dispatch(
          appProfileActions.updateSummariesListScreenConfig(updates(summariesListScreenConfig)),
        )
      } else {
        dispatch(appProfileActions.updateSummariesListScreenConfig(updates))
      }
    },
    [dispatch, summariesListScreenConfig],
  )
  return {
    summariesListScreenConfig,
    updateSummariesListScreenConfig,
  }
}

export const useCompletedSummariesToggle = () => {
  const { summariesListScreenConfig, updateSummariesListScreenConfig } =
    useSummariesListScreenConfig()
  const { showCompleted } = summariesListScreenConfig
  const setShowCompleted = React.useCallback(
    () => updateSummariesListScreenConfig(vals => ({ showCompleted: !vals.showCompleted })),
    [updateSummariesListScreenConfig],
  )

  return {
    setShowCompleted,
    showCompleted,
  }
}
