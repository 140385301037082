import React from 'react'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormField, useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { collectionUtils as C } from '@/core/utils'
import { useAppDispatch, useCachedSelector } from '@/hooks'
import { useDestructiveAlertAsync } from '@/hooks/actions'
import { tagActions } from '@/store/actions'
import { tagSelectors } from '@/store/selectors'
import { tagThunks } from '@/thunks'
import { Tag } from '@/types/entities'
import { CreateSegmentAction, useSegmentSelector } from '../TaskSegmentModal'

const emptyTagIds: number[] = []

export const useTagSelector = (onClose: (cancelled: boolean) => void, isOpen: boolean) => {
  const dispatch = useAppDispatch()
  const spaceId = useTaskFormValue(fieldNames.spaceId)
  const { value: tagIds, setValue: setTagIds } = useTaskFormField<number[] | null>(
    fieldNames.tagIds,
  )
  const activeTags = useCachedSelector(tagSelectors.bySpaceIdSelector, [spaceId || 0])
  const confirmDelete = useDestructiveAlertAsync(
    'Delete Tag',
    'Delete',
    'Are you sure you want to delete this tag?',
  )

  const {
    handleCancel,
    handleCreate,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isCreating,
    isSearching,
    searchResults,
    searchText,
    selectedIds,
    setSearchText,
  } = useSegmentSelector<Tag, number>({
    commitChanges: React.useCallback(
      (nextIds: number[], search: Tag[]) => {
        setTagIds(nextIds)

        const index = C.index(t => t.id, search)
        const addTags = nextIds.map(tagId => index[tagId]).filter(tag => !!tag) as Tag[]

        if (addTags.length !== 0) {
          dispatch(tagActions.upsertMany(addTags))
        }
      },
      [setTagIds, dispatch],
    ),
    createSegment: React.useCallback(
      async searchValue => {
        if (!searchValue) {
          return null
        }
        return dispatch(tagThunks.createTag(spaceId, { name: searchValue }))
      },
      [dispatch, spaceId],
    ),
    deleteSegment: React.useCallback(
      async (tag: Tag) => {
        const confirmed = await confirmDelete()

        if (!confirmed) {
          return false
        }

        const response = await dispatch(tagThunks.deleteTag(tag.spaceId, tag.id))
        return response.ok
      },
      [dispatch, confirmDelete],
    ),
    editSegment: React.useCallback(
      async (updatedTag: Tag) => {
        const response = await dispatch(
          tagThunks.updateTag(updatedTag.spaceId, updatedTag.id, updatedTag),
        )

        if (response.ok) {
          return response.data.tag
        }
        return null
      },
      [dispatch],
    ),
    getSegmentId: React.useCallback(tag => tag.id, []),
    initialSegments: activeTags,
    initialSelectedIds: tagIds || emptyTagIds,
    isOpen,
    onClose,
    searchSegments: React.useCallback(
      async searchValue => {
        if (!searchValue) {
          return undefined
        }
        const response = await dispatch(tagThunks.searchTags(spaceId, searchValue))
        return response.ok ? response.data.tags : []
      },
      [dispatch, spaceId],
    ),
  })

  const tags = searchResults || activeTags
  const matchText = searchText ? searchText.toLowerCase() : ''
  const hasExactMatch = React.useMemo(
    () => !!tags.find(tag => tag.name.toLowerCase() === matchText),
    [tags, matchText],
  )

  const createTagAction = React.useMemo<CreateSegmentAction>(
    () => ({
      canCreate: !!searchText && !hasExactMatch && !isSearching,
      isCreating,
      onCreate: handleCreate,
      segmentName: searchText || '',
    }),
    [searchText, hasExactMatch, isSearching, handleCreate, isCreating],
  )

  return {
    createTagAction,
    handleCancel,
    handleDelete,
    handleDone,
    handleEdit,
    handleSelect,
    isSearching,
    searchText,
    selectedIds,
    setSearchText,
    tags,
  }
}
