import React from 'react'
import { ScrollView } from 'react-native'
import ChannelListItem from '@/components/channels/ChannelsList/ChannelListItem'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { useCachedSelector } from '@/hooks'
import { entitySelectors } from '@/store/selectors'
import { Channel } from '@/types/entities'

interface ArchivedChannelsModalProps {
  onClose: () => void
  spaceId: number
  onShowMessages: (channel: Channel) => void
}

function ArchivedChannelsModal({ onClose, spaceId, onShowMessages }: ArchivedChannelsModalProps) {
  const channels = useCachedSelector(entitySelectors.resolvedChannelsSelector, [
    {
      spaceId,
      status: 'ARCHIVED',
    },
  ])

  const handleShowMessages = React.useCallback(
    (channel: Channel) => {
      onClose()
      onShowMessages(channel)
    },
    [onShowMessages, onClose],
  )

  return (
    <ModalLayout
      onCancel={onClose}
      rightAction={{
        icon: 'xmark',
        onAction: onClose,
        type: 'transparentDark',
      }}
      title="Archived Channels"
    >
      <ScrollView>
        <Box gapBottom="xlarge" gapHorizontal="xlarge">
          {channels.map(channel => (
            <ChannelListItem
              key={channel.id}
              channel={channel}
              onShowMessages={handleShowMessages}
            />
          ))}
        </Box>
      </ScrollView>
    </ModalLayout>
  )
}

export default React.memo(ArchivedChannelsModal)
