import React from 'react'
import FileActionsContext from '@/contexts/FileActionsContext'
import { useFileActions } from './hooks'

interface FileActionsContainerProps {
  children: React.ReactNode
}

function FileActionsContainer({ children }: FileActionsContainerProps) {
  const { handleShowActions } = useFileActions()
  return (
    <FileActionsContext.Provider value={handleShowActions}>{children}</FileActionsContext.Provider>
  )
}

export default React.memo(FileActionsContainer)
