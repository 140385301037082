import React from 'react'
import { Animated, StyleSheet } from 'react-native'
import { getColor } from '@/core/constants/colors'
import Touchable from './Touchable'

interface LocalModalProps {
  visible: boolean
  children: React.ReactNode
  onRequestClose?: () => void
}

function LocalModal({ visible, children, onRequestClose }: LocalModalProps) {
  const [show, setShow] = React.useState(visible)
  const opacityAnimationRef = React.useRef(new Animated.Value(0))

  React.useEffect(() => {
    const toValue = visible ? 1 : 0

    if (visible) {
      setShow(true)
    }

    Animated.timing(opacityAnimationRef.current, {
      duration: 300,
      toValue,
      useNativeDriver: true,
    }).start(({ finished }) => finished && !visible && setShow(false))
  }, [visible, setShow])

  if (!show) {
    return null
  }

  const overlayOpacityStyle = {
    opacity: opacityAnimationRef.current,
  }

  return (
    <Animated.View style={[styles.overlay, overlayOpacityStyle]}>
      <Touchable feedback="none" onPress={onRequestClose} style={styles.touchable} />
      {children}
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  overlay: {
    alignItems: 'center',
    backgroundColor: getColor('ModalBG'),
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    paddingBottom: 24,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 24,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1000,
  },
  touchable: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

export default React.memo(LocalModal)
