import version from 'assets/version.json'
import { SemVer } from '@/types/semver'

export const currentVersion = () => {
  const { build } = version
  return tryParse(build)
}

export const compare = (left: SemVer, right: SemVer): number => {
  const compMajor = left.major - right.major

  if (compMajor !== 0) {
    return compMajor
  }

  const compMinor = left.minor - right.minor

  if (compMinor !== 0) {
    return compMinor
  }

  return left.patch - right.patch
}

export const tryParse = (val: string): SemVer | null => {
  try {
    return parse(val)
  } catch {
    // pass
  }

  return null
}

const parse = (val: string): SemVer => {
  const [major, minor, patch] = val.split('.')
  return {
    major: parseInt(major, 10) || 0,
    minor: parseInt(minor, 10) || 0,
    patch: parseInt(patch, 10) || 0,
  }
}
