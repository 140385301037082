import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'
import { config } from '@/core'
import { User } from '@/types/entities'
import { LogOptions } from '@/types/logging'
import { SentryProxyInterface } from './types'

const isWeb = Platform.OS === 'web'

const isConfigured = {
  value: false,
}

const initialize = async () => {
  if (!config.sentryDSN) {
    return
  }

  isConfigured.value = true

  const sentryConfig = {
    debug: config.sentryDebug,
    dsn: config.sentryDSN,
    enableInExpoDevelopment: false,
  }

  Sentry.init(sentryConfig)
}

const setUser = async (user: User) => {
  if (isWeb) {
    Sentry.Browser.setUser({ id: user.id.toString() })
  } else {
    Sentry.Native.setUser({ id: user.id.toString() })
  }
}

const clearUser = async () => {
  if (isWeb) {
    Sentry.Browser.configureScope(scope => scope.setUser(null))
  } else {
    Sentry.Native.configureScope(scope => scope.setUser(null))
  }
}
const captureException = async (err: Error) => {
  if (!isConfigured.value) {
    return
  }

  if (isWeb) {
    Sentry.Browser.captureException(err)
  } else {
    Sentry.Native.captureException(err)
  }
}

const captureMessage = async (message: string, options?: LogOptions) => {
  if (!isConfigured.value) {
    return
  }

  if (isWeb) {
    Sentry.Browser.captureMessage(message, { extra: options })
  } else {
    Sentry.Native.captureMessage(message, { extra: options })
  }
}

const sentryProxy: SentryProxyInterface = {
  captureException,
  captureMessage,
  clearUser,
  initialize,
  setUser,
}

export default sentryProxy
