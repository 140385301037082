import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { personalDMName } from '@/core/constants/channels'
import { useCachedSelector } from '@/hooks'
import { entitySelectors } from '@/store/selectors'
import { fieldNames } from './constants'
import { useSetTaskChannel, useTaskChannels, useTaskFormValue } from './hooks'

function TaskChannel() {
  const spaceId: number = useTaskFormValue(fieldNames.spaceId)
  const channelId: number = useTaskFormValue(fieldNames.channelId)
  const channel = useCachedSelector(entitySelectors.resolvedChannelSelector, [
    spaceId,
    channelId || 0,
  ])
  const { setTaskChannel } = useSetTaskChannel()
  const channels = useTaskChannels()
  const { showDetail } = useTaskFormContext()

  const showChannelSelector = React.useCallback(() => {
    if (channels.length === 2) {
      const [first, second] = channels

      if (first.id === channelId) {
        setTaskChannel(second.spaceId, second.id)
      } else {
        setTaskChannel(first.spaceId, first.id)
      }
    } else {
      showDetail('CHANNEL')
    }
  }, [showDetail, channels, setTaskChannel, channelId])
  const isDisabled = channels.length <= 1

  return (
    <Touchable disabled={isDisabled} feedback="opacity" onPress={showChannelSelector}>
      <Box gapVertical="medium">
        <Text color="anchor" size="h5">
          {channel?.name || personalDMName}
        </Text>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskChannel)
