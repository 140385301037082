import React from 'react'
import { noOpWarn } from '@/core/logging'
import type { ModalPresenterContextType } from './types'

export { useModalPresenterState } from './hooks'

const warningMessage = 'modal presenter context not present'
const noOp = noOpWarn(warningMessage)

const defaultValue: ModalPresenterContextType = {
  pop: noOp,
  push: noOp,
}

const ModalPresenterContext = React.createContext<ModalPresenterContextType>(defaultValue)

const useModalPresenterContext = () => React.useContext(ModalPresenterContext)

export default ModalPresenterContext

export type { ModalPresenterContextType }
export { useModalPresenterContext }
