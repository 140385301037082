import React from 'react'
import { StyleSheet } from 'react-native'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import Background from '@/components/core/Background'
import BaseIconButton from '@/components/core/BaseIconButton'
import ErrorBoundary from '@/components/core/ErrorBoundary'
import VerticalProgressOverlay from '@/components/core/VerticalProgressOverlay'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import ActionableImage from '@/components/media/ActionableImage'
import ActionableVideo from '@/components/media/ActionableVideo'
import { getColor } from '@/core/constants/colors'
import { FILE_UPLOAD_PROGRESS } from '@/core/constants/eventTypes'
import { fileUtils } from '@/core/utils'
import { useSubscription } from '@/hooks/pubsub'
import { Attachment, ProgressEvent } from '@/types/api'
import { Measurement, RenderListItemContainer } from '@/types/components'
import GenericAttachmentListItem from './GenericAttachmentListItem'

interface AttachmentListItemProps {
  attachment: Attachment
  index: number
  onRemove: (attachment: Attachment) => void
  renderContainer?: RenderListItemContainer
  size: Measurement
}

function AttachmentListItem({
  attachment,
  onRemove,
  renderContainer,
  size,
  index,
}: AttachmentListItemProps) {
  const [uploadPercentage, setUploadPercentage] = React.useState<number>(0)
  const handleRemove = React.useCallback(() => onRemove(attachment), [attachment, onRemove])
  const contentGroup = fileUtils.getContentGroup(attachment.uri)

  useSubscription(
    FILE_UPLOAD_PROGRESS,
    React.useCallback(
      ({
        attachment: { temporaryId },
        event,
      }: {
        attachment: Attachment
        event: ProgressEvent
      }) => {
        if (attachment.temporaryId === temporaryId && event.lengthComputable) {
          setUploadPercentage(event.loaded / event.total)
        }
      },
      [attachment.temporaryId],
    ),
  )

  let item: React.ReactNode

  if (contentGroup === 'image') {
    item = (
      <ActionableImage
        duration="short"
        fit="SMALLEST"
        size={size}
        style={styles.image}
        uri={attachment.uri}
      />
    )
  } else if (contentGroup === 'video') {
    item = (
      <ErrorBoundary fallback={<GenericAttachmentListItem attachment={attachment} size={size} />}>
        <ActionableVideo
          fit="SMALLEST"
          playVideoInline={false}
          size={size}
          style={styles.image}
          uri={attachment.uri}
        />
      </ErrorBoundary>
    )
  } else {
    item = <GenericAttachmentListItem attachment={attachment} size={size} />
  }

  const itemOverlay = React.useMemo(() => {
    if (attachment.status === 'SUCCESS') {
      return (
        <FloatContainer right={0} top={0}>
          <BaseIconButton
            backgroundColor="ModalBG"
            color="ghost"
            name="xmark"
            onPress={handleRemove}
            padding={4}
            shape="circle"
            size="h6"
          />
        </FloatContainer>
      )
    }

    if (uploadPercentage > 0) {
      return <VerticalProgressOverlay percentage={uploadPercentage} />
    }

    return (
      <FloatContainer right={size.width / 2 - 10} top={size.height / 2 - 10}>
        <ActivityIndicator color="ghost" size="small" style={styles.loader} />
      </FloatContainer>
    )
  }, [attachment.status, handleRemove, size, uploadPercentage])

  const content = (
    <Background borderColor="iron" borderRadius={4} borderWidth={1}>
      <Box alignItems="center" height={size.height} justifyContent="center" width={size.width}>
        {item}
        {itemOverlay}
      </Box>
    </Background>
  )

  return renderContainer ? (
    renderContainer(content, index, size)
  ) : (
    <Box gapLeft="medium">{content}</Box>
  )
}

const styles = StyleSheet.create({
  image: {
    borderRadius: 5,
  },
  loader: {
    backgroundColor: getColor('ModalBG'),
    borderRadius: 10,
    height: 20,
    width: 20,
  },
})

export default React.memo(AttachmentListItem)
