import React from 'react'
import Button from '@/components/core/Button'
import Box from '@/components/layouts/Box'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'
import { taskUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'
import OpenEndedResponse from './OpenEndedResponse'
import OpenEndedResponseDisplay from './OpenEndedResponseDisplay'
import { useSubmitResponse } from './hooks'
import { QuestionDisabled } from './types'

interface OpenEndedResponseContainerProps {
  disabled: QuestionDisabled
  task: QuestionTask
  onAnswered?: () => void
}

function OpenEndedResponseContainer({
  task,
  onAnswered,
  disabled,
}: OpenEndedResponseContainerProps) {
  const { responseForm, isChanged, isComplete } = useQuestionResponseContext()
  const { handleSubmitResponse, isSubmitting } = useSubmitResponse(task, onAnswered)
  const isAnswered = taskUtils.isAnswered(task)
  const isDisabled =
    disabled === true || isSubmitting || (isAnswered && disabled === 'WHEN_ANSWERED')
  const showButton = !isDisabled && (!isAnswered || isChanged)

  if (disabled === 'WHEN_ANSWERED' && isAnswered) {
    return <OpenEndedResponseDisplay>{responseForm.openEndedResponse}</OpenEndedResponseDisplay>
  }

  return (
    <>
      <OpenEndedResponse onSubmitAnswer={handleSubmitResponse} />
      {showButton ? (
        <Box direction="row" gapTop="small" justifyContent="flex-end">
          <Button
            disabled={!isComplete}
            kind="primary"
            label={isAnswered ? 'Change Answer' : 'Answer'}
            onPress={handleSubmitResponse}
          />
        </Box>
      ) : null}
    </>
  )
}

export default React.memo(OpenEndedResponseContainer)
