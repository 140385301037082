import React from 'react'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { taskUtils } from '@/core/utils'
import { useToggle } from '@/hooks'
import { useCurrentUserId } from '@/hooks/auth'
import { QuestionTask } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'
import AnswerQuestionBody from './AnswerQuestionBody'
import EditQuestionBody from './EditQuestionBody'

interface QuestionFormBodyProps {
  initialQuestionResponse?: QuestionResponseForm
}

function QuestionFormBody({ initialQuestionResponse }: QuestionFormBodyProps) {
  const myId = useCurrentUserId()
  const { task } = useTaskFormContext()
  const canEdit = !task || (task.creatorId === myId && !taskUtils.isAnswered(task))
  const [editQuestion, toggleEditQuestion] = useToggle(canEdit)

  return editQuestion ? (
    <EditQuestionBody />
  ) : (
    <AnswerQuestionBody
      initialQuestionResponse={initialQuestionResponse}
      onEditQuestion={toggleEditQuestion}
      task={task as QuestionTask}
    />
  )
}

export default React.memo(QuestionFormBody)
