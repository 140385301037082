import { formActions, notificationActions } from '@/store/actions'
import { Errors } from '@/types/api'
import { SyncThunk } from '@/types/store'

export const setErrors =
  (formName: string, errors: Errors, notificationTitle?: string): SyncThunk<void> =>
  dispatch => {
    dispatch(formActions.setErrors({ errors, formName }))
    if (notificationTitle) {
      dispatch(notifyErrors(notificationTitle, errors))
    }
  }

export const notifyErrors =
  (notificationTitle: string, errors: Errors, duration?: number): SyncThunk<void> =>
  dispatch => {
    const global = errors['']

    if (notificationTitle && global) {
      global.forEach(message =>
        dispatch(
          notificationActions.push({
            body: message,
            duration,
            title: notificationTitle,
            type: 'error',
          }),
        ),
      )
    }
  }
