import * as MediaLibrary from 'expo-media-library'
import * as Sharing from 'expo-sharing'
import { requestMediaLibraryPermissions } from '../permissions'
import { isIOS, isWeb } from '../platform'
import { cacheFile, cacheFiles, cleanupCache, clearCache, getLocalURI } from './cache'
import {
  buildAttachment,
  dataURIToFile,
  fileToDataURI,
  getContentGroup,
  getContentType,
  getFilename,
  isDataURI,
  isLocalFile,
  isMedia,
  mapURIForUploading,
} from './utils'

export const shareFile = async (uri: string) => {
  const isAvailable = await Sharing.isAvailableAsync()

  if (!isAvailable) {
    return
  }

  const localURI = await cacheFile(uri, 'short')

  if (!localURI) {
    // TODO: Report error?
    return
  }

  if (isIOS && !isMedia(localURI)) {
    const UTI = 'public.item'
    Sharing.shareAsync(localURI, { UTI })
  } else {
    Sharing.shareAsync(localURI)
  }
}

const downloadFile = (uri: string) => {
  window.location.href = uri
}

export const shareOrDownloadFile = async (uri: string) => {
  if (isWeb) {
    downloadFile(uri)
  } else {
    await shareFile(uri)
  }
}

export const canSaveMedia = (uri: string) => isWeb || isMedia(uri)

export const saveMedia = async (uri: string) => {
  if (canSaveMedia(uri)) {
    return
  }

  // TODO: What to do about web?

  const granted = await requestMediaLibraryPermissions('save a photo or video', true)

  if (!granted) {
    return
  }

  const localURI = await cacheFile(uri, 'short')

  if (!localURI) {
    // TODO: Report error?
    return
  }

  const downloadAlbumName = 'Download'
  const asset = await MediaLibrary.createAssetAsync(localURI)
  const album = await MediaLibrary.getAlbumAsync(downloadAlbumName)

  if (album === null) {
    await MediaLibrary.createAlbumAsync(downloadAlbumName, asset, false)
  } else {
    await MediaLibrary.addAssetsToAlbumAsync([asset], album, false)
  }
}

export {
  fileToDataURI,
  buildAttachment,
  cacheFile,
  cacheFiles,
  cleanupCache,
  clearCache,
  dataURIToFile,
  getContentGroup,
  getContentType,
  getFilename,
  getLocalURI,
  isDataURI,
  isLocalFile,
  isMedia,
  mapURIForUploading,
}
