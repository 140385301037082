import React from 'react'
import { Measurement, RenderListItemContainer } from '@/types/components'
import { File, Message } from '@/types/entities'
import FileListItem from './FileListItem'
import { GenericFileMode } from './types'

interface FilesListProps {
  files: File[]
  message?: Message
  size: Measurement
  renderItemContainer?: RenderListItemContainer
  genericFileMode?: GenericFileMode
  playVideoInline: boolean
}

function FilesList({
  files,
  size,
  message,
  playVideoInline,
  renderItemContainer,
  genericFileMode = 'COMPACT',
}: FilesListProps) {
  if (files.length === 0) {
    return null
  }

  return (
    <>
      {files.map((file, index) => (
        <FileListItem
          key={file.id.toString()}
          file={file}
          genericFileMode={genericFileMode}
          index={index}
          message={message}
          playVideoInline={playVideoInline}
          renderContainer={renderItemContainer}
          size={size}
        />
      ))}
    </>
  )
}

export default React.memo(FilesList)
