import React from 'react'
import Box from '@/components/layouts/Box'
import { dateUtils as D } from '@/core/utils'
import { TaskDueDateType } from '@/types/entities'
import QuickDateButton from './QuickDateButton'

interface QuickDateButtonsProps {
  dueDateType: TaskDueDateType
  onSelectDate: (date: Date | null, dueDateType: TaskDueDateType) => void
  selectedDate: Date | null
}

const matchTime = (newDate: Date, currentDate: Date | null) => {
  if (!currentDate) {
    return D.setTime(newDate, 9)
  }

  return D.matchTime(currentDate, newDate)
}

function QuickDateButtons({ dueDateType, onSelectDate, selectedDate }: QuickDateButtonsProps) {
  const today = D.now()
  const tomorrow = D.add(D.now(), 1, 'day')
  const nextMonday = D.add(D.ceil(D.now(), 'week'), 2, 'days')

  const selectToday = React.useCallback(() => {
    const matched = matchTime(today, selectedDate)
    onSelectDate(matched, 'DATE')
  }, [selectedDate, onSelectDate, today])
  const selectTomorrow = React.useCallback(() => {
    const matched = matchTime(tomorrow, selectedDate)
    onSelectDate(matched, 'DATE')
  }, [selectedDate, onSelectDate, tomorrow])
  const selectNextMonday = React.useCallback(() => {
    const matched = matchTime(nextMonday, selectedDate)
    onSelectDate(matched, 'DATE')
  }, [selectedDate, onSelectDate, nextMonday])
  const selectSomeday = React.useCallback(() => onSelectDate(null, 'SOMEDAY'), [onSelectDate])

  return (
    <Box direction="row" gapVertical="medium" justifyContent="space-around">
      <QuickDateButton
        icon="exclamation"
        isSelected={!!selectedDate && D.isSameDay(selectedDate, today)}
        onPress={selectToday}
        title="Today"
      />
      <QuickDateButton
        icon="chevron-right"
        isSelected={!!selectedDate && D.isSameDay(selectedDate, tomorrow)}
        onPress={selectTomorrow}
        title="Tomorrow"
      />
      <QuickDateButton
        icon="chevron-double-right"
        isSelected={!!selectedDate && D.isSameDay(selectedDate, nextMonday)}
        onPress={selectNextMonday}
        title="Next Monday"
      />
      <QuickDateButton
        icon="archive"
        isSelected={dueDateType === 'SOMEDAY'}
        onPress={selectSomeday}
        title="Someday"
      />
    </Box>
  )
}

export default React.memo(QuickDateButtons)
