import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { isString } from '@/core/utils/types'
import { RootState } from '../index'

interface FieldState {
  value: any
  error: string | null
  changed: boolean
}

interface FormState {
  [key: string]: FieldState
}

interface FormsState {
  [key: string]: FormState
}

const initialState: FormsState = {}
const fieldValue = (value: any = null, changed = false) => ({ changed, error: null, value })

const formsSlice = createSlice({
  initialState,
  name: 'forms',
  /* eslint-disable no-param-reassign */
  reducers: {
    addMember: (
      state,
      action: PayloadAction<{ formName: string; fieldName: string; value: any }>,
    ) => {
      const { formName, fieldName, value } = action.payload

      if (!(formName in state)) {
        state[formName] = {}
      }

      const form = state[formName]

      if (!(fieldName in form)) {
        form[fieldName] = fieldValue()
      }

      const currentValues = form[fieldName].value

      if (currentValues === null) {
        form[fieldName].value = [value]
      } else {
        if (!Array.isArray(currentValues)) {
          throw new Error('You cannot add members to non-array form fields.')
        }

        form[fieldName].value = [...currentValues, value]
      }

      form[fieldName].error = null
      form[fieldName].changed = true
    },

    appendValue: (
      state,
      action: PayloadAction<{ formName: string; fieldName: string; value: string }>,
    ) => {
      const { formName, fieldName, value } = action.payload

      if (!(formName in state)) {
        state[formName] = {}
      }

      const form = state[formName]

      if (!(fieldName in form)) {
        form[fieldName] = fieldValue(value)
        return
      }

      const field = form[fieldName]

      if (field.value === null) {
        field.value = value
      } else if (isString(field.value)) {
        field.value += value
      } else {
        throw new Error('Cannot append to non-string form field')
      }

      field.changed = true
      field.error = null
    },

    clear: (state, action: PayloadAction<string>) => {
      const { payload: formName } = action

      if (!(formName in state)) {
        return
      }

      delete state[formName]
    },
    initialize: (
      state,
      action: PayloadAction<{ formName: string; fields: Record<string, any> }>,
    ) => {
      const { formName, fields } = action.payload

      state[formName] = Object.fromEntries(
        Object.entries(fields).map(([fieldName, value]) => [fieldName, fieldValue(value)]),
      )
    },
    removeMember: (
      state,
      action: PayloadAction<{ formName: string; fieldName: string; value: any }>,
    ) => {
      const { formName, fieldName, value } = action.payload

      if (!(formName in state)) {
        state[formName] = {}
      }

      const form = state[formName]

      if (!(fieldName in form)) {
        form[fieldName] = fieldValue()
      }

      const currentValues = form[fieldName].value

      if (currentValues === null) {
        form[fieldName].value = []
      } else {
        if (!Array.isArray(currentValues)) {
          throw new Error('You cannot remove members from non-array form fields.')
        }

        // This will not work correctly for references values such as objects. You have to use the
        // immer.original function to get the raw value in that case.
        form[fieldName].value = currentValues?.filter(item => item !== value)
      }

      form[fieldName].error = null
      form[fieldName].changed = true
    },
    setErrors: (
      state,
      action: PayloadAction<{ formName: string; errors: Record<string, string[]> }>,
    ) => {
      const { formName, errors: errorsMap } = action.payload

      if (!(formName in state)) {
        state[formName] = {}
      }

      const form = state[formName]

      Object.entries(errorsMap || {}).forEach(([fieldName, errors]) => {
        if (!(fieldName in form)) {
          form[fieldName] = fieldValue()
        }
        const [error] = errors
        form[fieldName].error = error
      })
      Object.entries(form || {}).forEach(([fieldName, field]) => {
        if (!(fieldName in errorsMap)) {
          field.error = null
        }
      })
    },
    setValue: (
      state,
      action: PayloadAction<{ formName: string; fieldName: string; value: any }>,
    ) => {
      const { formName, fieldName, value } = action.payload

      if (!(formName in state)) {
        state[formName] = {}
      }

      const form = state[formName]

      if (!(fieldName in form)) {
        form[fieldName] = fieldValue()
      }

      const field = form[fieldName]

      field.changed = value !== field.value
      field.value = value
      field.error = null
    },
    setValues: (
      state,
      action: PayloadAction<{
        formName: string
        markChanged: boolean
        fields: Record<string, any>
      }>,
    ) => {
      const { formName, fields, markChanged } = action.payload

      if (!(formName in state)) {
        state[formName] = {}
      }

      state[formName] = {
        ...state[formName],
        ...Object.fromEntries(
          Object.entries(fields).map(([fieldName, value]) => {
            const field = state[formName][fieldName]
            const wasChanged = field ? field.changed : false
            const isChanged = value !== field?.value

            return [fieldName, fieldValue(value, markChanged ? isChanged : wasChanged)]
          }),
        ),
      }
    },
  },
  /* eslint-enable */
})

const { actions, reducer } = formsSlice

const selectFormField = (state: RootState, formName: string, fieldName: string): FieldState => {
  const form = state.forms[formName]
  if (form) {
    const field = form[fieldName]
    if (field) {
      return field
    }
  }
  return fieldValue()
}
const selectFormValue = (state: RootState, formName: string, fieldName: string): any =>
  selectFormField(state, formName, fieldName).value

const getFormValues = (form: FormState): Record<string, any> => {
  if (form) {
    return Object.fromEntries(Object.entries(form).map(([name, field]) => [name, field.value]))
  }
  return {}
}

const selectFormValues = (state: RootState, formName: string): Record<string, any> =>
  getFormValues(state.forms[formName])

const formSelector = (formName: string) =>
  createSelector(
    (state: RootState) => state.forms,
    (forms): FormState => forms[formName] || {},
  )
const hasChangesSelector = (formName: string) =>
  createSelector(formSelector(formName), entries =>
    Object.values(entries).some(field => field.changed),
  )

const selectFormExists = (state: RootState, formName: string) => formName in state.forms

const valuesSelector = (formName: string) => createSelector(formSelector(formName), getFormValues)
const fieldSelector = (formName: string, fieldName: string) =>
  createSelector(formSelector(formName), (fields): FieldState => fields[fieldName] || fieldValue())
const valueSelector = (formName: string, fieldName: string) =>
  createSelector(fieldSelector(formName, fieldName), field => field.value)

const selectors = {
  exists: selectFormExists,
  field: selectFormField,
  fieldSelector,
  formSelector,
  hasChangesSelector,
  value: selectFormValue,
  valueSelector,
  values: selectFormValues,
  valuesSelector,
}

export { actions, selectors }
export default reducer
