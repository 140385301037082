import { Request } from '@/api/call'
import { ReceivedMessage, ReceivedTask } from '@/types/apiEntities'
import { ChannelUser } from '@/types/entities'
import { QuestionResponseForm } from '@/types/tasks'

interface AnswerResponse {
  task: ReceivedTask
  activityMessages?: ReceivedMessage[] | null
  channelUser?: ChannelUser
}

export type AnswerQuestion = Pick<QuestionResponseForm, 'openEndedResponse' | 'selectedResponseIds'>

export const answerQuestion = (spaceId: number, questionId: number, answer: AnswerQuestion) =>
  new Request<AnswerResponse>({
    authentication: 'PRIVATE',
    body: answer,
    method: 'POST',
    url: `v1/spaces/${spaceId}/questions/${questionId}/answers`,
  })
