import * as Notifications from 'expo-notifications'
import { commAPI } from '@/api/requests'
import { platformUtils } from '@/core/utils'
import { authActions } from '@/store/actions'
import { authSelectors } from '@/store/selectors'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

export const registerPushNotifications: Thunk<boolean> = async (dispatch, getState) => {
  // Get the device token and register it with the current user unless we've
  // already marked on the device's local storage that we have a token.
  // Requesting the token multiple times from expo is idempotent, but we may
  // be logged in with a new user so we need to update the token to be
  // tied to a new user account

  // https://docs.expo.io/push-notifications/overview/
  if (platformUtils.isAndroid) {
    Notifications.setNotificationChannelAsync('default', {
      importance: Notifications.AndroidImportance.MAX,
      lightColor: '#FF231F7C',
      name: 'default',
      vibrationPattern: [0, 250, 250, 250],
    })
  }
  const hasExpoDeviceToken = authSelectors.hasExpoDeviceToken(getState())

  if (hasExpoDeviceToken) {
    return true
  }

  const success = await dispatch(saveExpoDeviceToken)
  dispatch(authActions.setHasExpoDeviceToken(success))

  return success
}

const saveExpoDeviceToken: Thunk<boolean> = async dispatch => {
  const { data: token } = await Notifications.getExpoPushTokenAsync()

  const request = commAPI.createExpoDeviceToken(token)
  const response = await dispatch(makeEnhancedRequest(request))

  return response.ok
}
