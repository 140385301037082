import React from 'react'
import VerticalSwipeContainer from '@/components/gestures/VerticalSwipeContainer'
import { useTaskFormSizeSwipe } from './hooks'

interface TaskFormSizeSwipeContainerProps {
  children: React.ReactNode
}

const activeOffset: [number, number] = [-1, 1]

function TaskFormSizeSwipeContainer({ children }: TaskFormSizeSwipeContainerProps) {
  const { handleSwipeBegan, handleSwipe } = useTaskFormSizeSwipe()

  return (
    <VerticalSwipeContainer
      activeOffset={activeOffset}
      onSwipe={handleSwipe}
      onSwipeBegan={handleSwipeBegan}
    >
      {children}
    </VerticalSwipeContainer>
  )
}

export default React.memo(TaskFormSizeSwipeContainer)
