import { config } from '@/core'
import * as httpStatus from '@/core/constants/httpStatus'
import { withTiming } from '@/core/utils/dates'
import { FetchParams, MappedResponse, RequestDescriptor, ResolvedResponse } from '@/types/api'
import { mapRequest } from './mapRequest'
import { mapResponse, mapXHRResponse } from './mapResponse'
import { logLongRunningRequest, logRequestError } from './utils'

const ignoreStatuses = new Set([httpStatus.UNPROCESSABLE_ENTITY, httpStatus.NOT_AUTHENTICATED])

export const makeRequest = async <T>(request: RequestDescriptor): Promise<MappedResponse<T>> =>
  getResponse(mapRequest(request))

async function getResponse<T>({
  url,
  onUploadProgress,
  ...opts
}: FetchParams): Promise<MappedResponse<T>> {
  try {
    let wrapper: ResolvedResponse<T>
    let durationMS: number

    if (onUploadProgress) {
      const result = await withTiming(() => fetchWithXHR<T>({ onUploadProgress, url, ...opts }))
      wrapper = result.result
      durationMS = result.durationMS
    } else {
      const result = await withTiming(() => fetch(url, opts))
      wrapper = await mapResponse<T>(result.result.clone())
      durationMS = result.durationMS
    }

    if (!wrapper.ok && !ignoreStatuses.has(wrapper.status)) {
      logRequestError({
        method: opts.method,
        responseBody: wrapper.rawBody,
        status: wrapper.status,
        url,
      })
    }

    if (durationMS > config.longRequestThresholdMS) {
      logLongRunningRequest({ durationMS, method: opts.method, url })
    }

    return wrapper
  } catch (ex) {
    return {
      exception: ex,
      type: 'EXCEPTION',
    }
  }
}

const fetchWithXHR = <T>({
  url,
  onUploadProgress,
  ...opts
}: FetchParams): Promise<ResolvedResponse<T>> =>
  new Promise(resolve => {
    const request = new XMLHttpRequest()

    if (onUploadProgress) {
      request.upload.addEventListener('progress', onUploadProgress)
    }

    request.addEventListener('readystatechange', () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        resolve(mapXHRResponse(request))
      }
    })

    request.open(opts.method, url)

    Object.entries(opts.headers).forEach(([key, value]) => request.setRequestHeader(key, value))

    if (opts.body) {
      request.send(opts.body)
    }
  })
