import { channelUtils } from '@/core/utils'
import { ResolvedChannel } from '@/types/entities'

// Compare channels by name, but putting the seleted channel (if any) always first
export const getChannelComparer =
  (selectedChannelId: number | null) => (left: ResolvedChannel, right: ResolvedChannel) => {
    if (left.id === selectedChannelId) {
      return -1
    }
    if (right.id === selectedChannelId) {
      return 1
    }
    if (left.channelType !== right.channelType) {
      if (channelUtils.isHub(left)) {
        return -1
      }
      if (channelUtils.isHub(right)) {
        return 1
      }
      if (channelUtils.isDM(left)) {
        return -1
      }
      if (channelUtils.isDM(right)) {
        return 1
      }
    }
    return left.name.localeCompare(right.name)
  }
