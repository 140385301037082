import React from 'react'
import ModalLayout from '@/components/layouts/ModalLayout'
import TasksListView from '@/components/tasks/TasksListView'
import { collectionUtils as C } from '@/core/utils'
import { SelectTasksArgs, TaskType } from '@/types/tasks'

interface SelectTasksModalProps extends SelectTasksArgs {
  onClose: () => void
}

function SelectTasksModal({
  onClose,
  mode,
  onSelectTasks,
  ...tasksListProps
}: SelectTasksModalProps) {
  const { taskType } = tasksListProps
  const [selectedTaskIds, setSelectedTaskIds] = React.useState<number[]>([])
  const hasSelection = selectedTaskIds.length !== 0

  const handleSelectTask = React.useCallback(
    (taskId: number, selected: boolean) => {
      if (selected) {
        if (mode === 'MULTIPLE') {
          setSelectedTaskIds(val => [...val, taskId])
        } else {
          setSelectedTaskIds([taskId])
        }
      } else if (mode === 'MULTIPLE') {
        setSelectedTaskIds(val => C.without(taskId, val))
      } else {
        setSelectedTaskIds([])
      }
    },
    [setSelectedTaskIds, mode],
  )

  const handleDone = React.useCallback(() => {
    onSelectTasks(selectedTaskIds)
    onClose()
  }, [onSelectTasks, selectedTaskIds, onClose])

  const handleCancel = React.useCallback(() => {
    setSelectedTaskIds([])
    onSelectTasks([])
    onClose()
  }, [onSelectTasks, setSelectedTaskIds, onClose])

  const shouldAutoSelect = mode === 'SINGLE_QUICK' && selectedTaskIds.length !== 0
  React.useEffect(() => {
    if (shouldAutoSelect) {
      handleDone()
    }
  }, [shouldAutoSelect, handleDone])

  return (
    <ModalLayout
      fullHeight
      insetBottom={false}
      leftAction={{
        label: 'Cancel',
        onAction: handleCancel,
        type: 'linkPrimary',
      }}
      onCancel={handleCancel}
      rightAction={{
        disabled: !hasSelection,
        label: 'Add',
        onAction: handleDone,
        type: 'linkPrimary',
      }}
      title={getModalTitle(taskType, mode === 'MULTIPLE')}
    >
      <TasksListView
        {...tasksListProps}
        initialTaskRelationship={taskType === 'QUESTION' ? 'WATCHING' : 'MINE'}
        onSelectTask={handleSelectTask}
        selectedTaskIds={selectedTaskIds}
        showFilters={taskType !== 'QUESTION'}
        showRelationshipToggle
      />
    </ModalLayout>
  )
}

const getModalTitle = (taskType: TaskType, multiple: boolean) => {
  if (taskType === 'TASK') {
    if (multiple) {
      return 'Select Tasks'
    }
    return 'Select a Task'
  }
  if (multiple) {
    return 'Select Questions'
  }
  return 'Select a Question'
}

export default React.memo(SelectTasksModal)
