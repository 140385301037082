import React from 'react'
import Action from '@/components/core/Action'
import Box from '@/components/layouts/Box'
import { useSubmitResponse } from '@/components/questions/QuestionResponseForm/responses'
import { useQuestionConfirmCancel } from '@/components/tasks/TaskForm/hooks'
import { useQuestionResponseContext } from '@/contexts/QuestionResponseContext'
import { taskUtils } from '@/core/utils'
import { QuestionTask } from '@/types/entities'

interface AnswerQuestionActionsProps {
  onClose: () => void
  task: QuestionTask
}

function AnswerQuestionActions({ onClose, task }: AnswerQuestionActionsProps) {
  const { isChanged, isComplete } = useQuestionResponseContext()
  const { handleSubmitResponse, isSubmitting } = useSubmitResponse(task, onClose)
  const isAnswered = task && taskUtils.isAnswered(task)
  const isDisabled = isSubmitting
  const canAnswer = !isDisabled && isComplete && (!isAnswered || isChanged)

  return (
    <Box alignItems="center" direction="row">
      <Action
        label={isChanged ? 'Cancel' : 'Close'}
        onAction={useQuestionConfirmCancel(isChanged, handleSubmitResponse, onClose)}
        type="linkSecondary"
      />
      <Box flex={1} />
      <Action
        amplitudeEventName="answer question"
        disabled={!canAnswer}
        label={isAnswered ? 'Change Answer' : 'Answer'}
        onAction={handleSubmitResponse}
        type={isAnswered ? 'linkSecondary' : 'buttonSecondary'}
      />
    </Box>
  )
}

export default React.memo(AnswerQuestionActions)
