import React from 'react'
import Button from '@/components/core/Button'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import QuestionEditor from '@/components/questions/QuestionEditor'
import QuestionModeChooser from '@/components/questions/QuestionModeChooser'
import EditQuestionActions from '@/components/tasks/TaskForm/QuestionActions/EditQuestionActions'
import { useDeleteTask } from '@/components/tasks/TaskForm/hooks'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { fieldNames } from '../constants'

function EditQuestionBody() {
  const { formName, questionMode, interactionMode, task, close } = useTaskFormContext()
  const { isDeleting, handleDelete } = useDeleteTask(task, close)

  return (
    <Box gapHorizontal="large">
      <Box gapBottom="medium">
        <EditQuestionActions />
      </Box>
      <FormTextInput
        expandable
        fieldName={fieldNames.content}
        formName={formName}
        kind="standard"
        label="Question"
        maxHeight={100}
        multiline
        placeholder="Write Question"
      />
      <Box gapTop="medium">
        <QuestionEditor questionMode={questionMode} />
      </Box>
      <Box direction="row" gapTop="medium" justifyContent="space-around">
        <QuestionModeChooser />
      </Box>
      {interactionMode === 'edit' ? (
        <Box direction="row" gapTop="large" justifyContent="center">
          <Button disabled={isDeleting} kind="error" label="Delete" onPress={handleDelete} />
        </Box>
      ) : null}
    </Box>
  )
}

export default React.memo(EditQuestionBody)
