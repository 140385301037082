import React from 'react'
import { binaryFilter, countIncludedTasks, mergeFilters } from '@/core/utils/tasks/filtering'
import { entitySelectors, taskSelectors } from '@/store/selectors'
import { Task } from '@/types/entities'
import {
  FilterOption,
  TaskFilter,
  TaskRelationshipFields,
  TaskSort,
  UnaryTaskFilter,
} from '@/types/tasks'
import { useAppSelector, useCachedSelector } from '../redux'

export const useFilteredTasksCount = (filter: TaskFilter): number => {
  const tasksCount = useCachedSelector(entitySelectors.countTasksByFilterSelector, [filter])
  return tasksCount
}

export const useFilteredTasks = (filter: TaskFilter, sorts?: TaskSort[]): Task[] =>
  useCachedSelector(entitySelectors.tasksByFilterSelector, [filter, sorts])

export const useMapFilterOption = (
  baseFilter: UnaryTaskFilter,
  relationshipFields: TaskRelationshipFields,
): ((option: FilterOption) => FilterOption) => {
  const tasks = useAppSelector(taskSelectors.all)
  const relatedData = useAppSelector(entitySelectors.relatedTaskFilterDataSelector)

  return React.useCallback(
    (option: FilterOption) => {
      const filter = binaryFilter(
        mergeFilters(baseFilter, relationshipFields),
        option.filter,
        'AND',
      )

      const count = countIncludedTasks(filter, relatedData, tasks)

      return { ...option, count, filter }
    },
    [relationshipFields, baseFilter, tasks, relatedData],
  )
}
