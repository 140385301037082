import React from 'react'
import Button from '@/components/core/Button'
import Box from '@/components/layouts/Box'
import { noOp } from '@/core/utils/fns'

function YesNoQuestionEditor() {
  return (
    <Box direction="row" justifyContent="center">
      <Box gapHorizontal="small">
        <Button disabled kind="primary" label="Yes" onPress={noOp} />
      </Box>
      <Box gapHorizontal="small">
        <Button disabled kind="primary" label="No" onPress={noOp} />
      </Box>
    </Box>
  )
}

export default React.memo(YesNoQuestionEditor)
