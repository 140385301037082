import React from 'react'
import {
  Meridiem,
  dateToTimeNumber,
  timeNumberToDate,
  toMeridiem,
  toValidTimeNumber,
} from './utils'

export const useTimeInput = (value: Date, onChange: (date: Date) => void) => {
  const [timeValue, setTimeValue] = React.useState(dateToTimeNumber(value))
  const [meridiem, setMeridiem] = React.useState(toMeridiem(value))

  const handleChangeTimeValue = React.useCallback(
    (time: string) => {
      if (!time) {
        setTimeValue(0)
        return
      }

      const timeNumber = parseInt(time.replace(/\D/g, ''), 10)

      if (Number.isNaN(timeNumber)) {
        return
      }

      const nextTimeNumber = toValidTimeNumber(timeNumber)
      setTimeValue(nextTimeNumber)

      onChange(timeNumberToDate(value, nextTimeNumber, meridiem))
    },
    [setTimeValue, onChange, value, meridiem],
  )

  const handleChangeMeridiem = React.useCallback(
    (nextMeridiem: string) => {
      setMeridiem(nextMeridiem as Meridiem)
      onChange(timeNumberToDate(value, timeValue, nextMeridiem as Meridiem))
    },
    [setMeridiem, onChange, value, timeValue],
  )

  React.useEffect(() => {
    setTimeValue(dateToTimeNumber(value))
    setMeridiem(toMeridiem(value))
  }, [value, setTimeValue, setMeridiem])

  return {
    handleChangeMeridiem,
    handleChangeTimeValue,
    meridiem,
    timeValue,
  }
}
