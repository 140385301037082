import React from 'react'
import { UpdateSummary, UpdateSummaryItem } from '@/api/requests/summaries'
import { alwaysAsyncWarn, noOpWarn } from '@/core/logging'
import { AddItemArgs, EditItemId, SelectTasksArgs } from './types'

export type EditSummaryContextType = {
  addItem: (arg: AddItemArgs) => void
  deleteSummaryItem: (summaryItemId: number) => Promise<boolean>
  dragBegin: (index: number) => void
  dragEnd: (params: { from: number; to: number }) => void
  draggingHeaderId: number | null
  editingItemId: EditItemId | null
  editingItemIndex: number | null
  selectTasks: (arg: SelectTasksArgs) => PromiseLike<number[]>
  setEditingItemId: (arg: null | { itemId: EditItemId; index: number }) => void
  updateSummary: (updates: UpdateSummary) => Promise<boolean>
  updateSummaryItem: (summaryItemId: number, updates: UpdateSummaryItem) => Promise<boolean>
}

const message = 'edit summary context not present'

const defaultContextValue: EditSummaryContextType = {
  addItem: noOpWarn(message),
  deleteSummaryItem: alwaysAsyncWarn(false, message),
  dragBegin: noOpWarn(message),
  dragEnd: noOpWarn(message),
  draggingHeaderId: null,
  editingItemId: null,
  editingItemIndex: null,
  selectTasks: alwaysAsyncWarn([], message),
  setEditingItemId: noOpWarn(message),
  updateSummary: alwaysAsyncWarn(false, message),
  updateSummaryItem: alwaysAsyncWarn(false, message),
}

const EditSummaryContext = React.createContext<EditSummaryContextType>(defaultContextValue)

export const useEditSummaryContext = () => React.useContext(EditSummaryContext)

export default EditSummaryContext
