import React from 'react'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { ColorName } from '@/core/constants/colors'
import { taskUtils } from '@/core/utils'
import { Task } from '@/types/entities'

interface TaskChecklistDetailItemProps {
  color: ColorName
  size: number
  task: Task
}

function TaskChecklistDetailItem({ color, size, task }: TaskChecklistDetailItemProps) {
  const [completedCount, totalCount] = React.useMemo(
    () => taskUtils.getCompletedItemsRatio(task.checklistItems),
    [task.checklistItems],
  )

  return (
    <Box alignItems="center" direction="row">
      <Icon color={color} name="list-ul" size={size} />
      <Box gapLeft="xsmall">
        <Text color={color} size="h6" weight="medium">
          {completedCount}/{totalCount}
        </Text>
      </Box>
    </Box>
  )
}

export default React.memo(TaskChecklistDetailItem)
