// Symbols and pictographs may come in as just the code point, or the code point followed by the
// emoji variant modifier (\ufe0f). Without the variant modifier it's not a fully-qualified emoji
// but some systems (like iOS) will still display as an emoji
const symbolsAndPictographs = '[\\u{1f300}-\\u{1f5ff}]\ufe0f?'

// Consider any byte followed by the emoji variant modifier to be an emoji
const emojiVariants = '.\ufe0f'

const basicEmoji = `(?:[\\p{Emoji_Presentation}]|${symbolsAndPictographs}|${emojiVariants})`

// a basic emoji followed by zero or more modifiers
const modifiedEmoji = `${basicEmoji}[\\p{Emoji_Modifier}]*`

// a modified emoji followed by zeor or more zero-width-joiner + modified emoji
const emoji = `(?:${modifiedEmoji})(?:\u200D(?:${modifiedEmoji}))*`

const onlyEmojiRegex = new RegExp(`^(${emoji})+$`, 'u')
const emojiRegex = new RegExp(emoji, 'gu')

export const isOnlyEmoji = (value: string) => onlyEmojiRegex.test(value)

export const findEmoji = (value: string) => value.match(emojiRegex)

export const countEmoji = (value: string) => {
  const emojiInstances = findEmoji(value)
  return emojiInstances ? emojiInstances.length : 0
}
