import type {
  ActionArgs,
  ActionOption,
  ActionOptionAsync,
  AlertButton,
  ShowAlert,
} from '@/types/actions'
import { noOp } from '../fns'
import { filterActions } from './utils'

export const showActionAlert = <S = void>(
  showAlert: ShowAlert,
  options: ActionOption<S>[],
  args: ActionArgs,
  subject: S,
) => {
  const activeOptions = filterActions(options, subject).map(option =>
    mapActionOptionToAlertButton(subject, option),
  )

  if (activeOptions.length) {
    showAlert(args.title, args.message, activeOptions)
  }
}

export const showActionAlertAsync = <V, S = void>(
  showAlert: ShowAlert,
  options: ActionOptionAsync<V, S>[],
  args: ActionArgs,
  subject: S,
): Promise<V> => {
  const activeOptions = filterActions(options, subject)

  if (activeOptions.length) {
    return new Promise(resolve => {
      showAlert(
        args.title,
        args.message,
        activeOptions.map(option => ({
          onPress: () => resolve(option.value),
          style: option.type || 'default',
          text: option.label,
        })),
      )
    })
  }

  return new Promise((resolve, reject) => {
    reject(new Error('No valid options'))
  })
}

export const showDestructiveAlert = (
  showAlert: ShowAlert,
  title: string,
  label: string,
  handler: () => void,
  message?: string,
) =>
  showActionAlert(
    showAlert,
    [
      { handler, label, type: 'destructive' },
      { handler: noOp, label: 'Cancel', type: 'cancel' },
    ],
    { message, title },
    null,
  )

export const showDestructiveAlertAsync = (
  showAlert: ShowAlert,
  title: string,
  label: string,
  message?: string,
) =>
  showActionAlertAsync(
    showAlert,
    [
      { label, type: 'destructive', value: true },
      { label: 'Cancel', type: 'cancel', value: false },
    ],
    { message, title },
    null,
  )

export const showNoActionAlert = (
  showAlert: ShowAlert,
  title: string,
  message?: string,
  onOk: () => void = noOp,
) => showActionAlert(showAlert, [{ handler: onOk, label: 'OK' }], { message, title }, null)

const mapActionOptionToAlertButton = <S>(subject: S, option: ActionOption<S>): AlertButton => ({
  onPress: () => option.handler(subject),
  style: option.type || 'default',
  text: option.label,
})
