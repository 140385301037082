import React from 'react'
import Link from '@/components/core/Link'
import Box from '@/components/layouts/Box'
import SpaceUserListItem from '@/components/spaces/SpaceUsersList/SpaceUserListItem'
import { noOp } from '@/core/utils/fns'
import { SpaceUser } from '@/types/entities'

interface HubUsersBodyProps {
  users: SpaceUser[]
  onInvited?: () => void
  onGoToNextStep: () => void
  compact?: boolean
}

function HubUsersBody({ compact, users, onInvited, onGoToNextStep }: HubUsersBodyProps) {
  return (
    <Box>
      {users.map(user => (
        <SpaceUserListItem
          key={user.id}
          canCancel={false}
          canEdit={false}
          compact={compact}
          onInvited={onInvited}
          onSelect={noOp}
          user={user}
        />
      ))}
      <Box alignItems="center" direction="row" gapAll="large" justifyContent="center">
        <Link label="Create New Hub & Invite" onPress={onGoToNextStep} />
      </Box>
    </Box>
  )
}

export default React.memo(HubUsersBody)
