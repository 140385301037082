import React from 'react'
import HubUserPictures from '@/components/channels/HubUserPictures'
import { channelUtils } from '@/core/utils'
import { ResolvedChannel } from '@/types/entities'
import BaseContent from './BaseContent'

interface HubContentProps {
  channel: ResolvedChannel
}

function HubContent({ channel }: HubContentProps) {
  return (
    <BaseContent
      icon={<HubUserPictures hub={channel} size="SMALL" />}
      subtitle={channelUtils.isJustYouHub(channel) ? 'Just you' : channel.name}
      title={channelUtils.channelToLabel(channel)}
    />
  )
}

export default React.memo(HubContent)
