import { createDrawerNavigator } from '@react-navigation/drawer'
import { createStackNavigator } from '@react-navigation/stack'
import {
  AccountManagementRouteParams,
  AuthRouteParams,
  IntegrationsManagementRouteParams,
  RootRouteParams,
  SignUpRouteParams,
  SpaceRouteParams,
} from './params'

export type {
  AccountManagementNavigationProp,
  AccountManagementRouteProp,
  AuthNavigationProp,
  AuthRouteProp,
  IntegrationsManagementNavigationProp,
  RootNavigationProp,
  RootRouteProp,
  SignUpNavigationProp,
  SignUpRouteProp,
  SpaceNavigationProp,
  SpaceRouteProp,
} from './types'

export {
  accountManagementScreenOptions,
  authScreenOptions,
  integrationsManagementScreenOptions,
  rootScreenOptions,
  signUpScreenOptions,
  spaceScreenOptions,
} from './screenOptions'

export {
  goToNoSpaces,
  goToSignUp,
  goToSignIn,
  goToSpace,
  getParentNavigation,
  redirect,
} from './utils'
export { linking } from './linking'

export const RootDrawer = createDrawerNavigator<RootRouteParams>()
export const AuthStack = createStackNavigator<AuthRouteParams>()
export const AccountManagementStack = createStackNavigator<AccountManagementRouteParams>()
export const SignUpStack = createStackNavigator<SignUpRouteParams>()
export const SpaceStack = createStackNavigator<SpaceRouteParams>()
export const IntegrationsManagementStack = createStackNavigator<IntegrationsManagementRouteParams>()
