import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { hasFeature } from '@/core/config'
import { channelUtils } from '@/core/utils'
import { RootState } from '@/store'
import { Channel, ChannelType } from '@/types/entities'
import { Predicate } from '@/types/generics'
import { createEntityReducers } from './entityReducers'

const buildId = (spaceId: number, channelId: number): string => `${spaceId}:${channelId}`

const adapter = createEntityAdapter<Channel>({
  selectId: channel => buildId(channel.spaceId, channel.id),
  sortComparer: (a, b) => {
    const nameCompare = (a.name || '').localeCompare(b.name || '')
    return nameCompare === 0 ? a.id - b.id : nameCompare
  },
})
const entityReducers = createEntityReducers(adapter)

const slice = createSlice({
  initialState: adapter.getInitialState(),
  name: 'entities/channels',
  reducers: {
    removeAll: adapter.removeAll,
    removeOne: adapter.removeOne,
    removeWhere: entityReducers.removeWhere,
    replaceWhere: entityReducers.replaceWhere,
    setAll: adapter.setAll,
    upsertMany: adapter.upsertMany,
    upsertOne: adapter.upsertOne,
  },
})

const { actions, reducer } = slice
const getId = adapter.selectId
const sliceSelectors = adapter.getSelectors((state: RootState) => state.entities.channels)

const selectById = (state: RootState, spaceId: number, channelId: number) =>
  state.entities.channels.entities[buildId(spaceId, channelId)]

const byIdSelector = (spaceId: number, channelId: number) =>
  createSelector(sliceSelectors.selectEntities, channels => channels[buildId(spaceId, channelId)])

const bySpaceIdSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, channels =>
    channels.filter(channel => channel.spaceId === spaceId),
  )

const shouldShowSummaryHubSelector = (spaceId: number) =>
  createSelector(bySpaceIdSelector(spaceId), channels => {
    if (hasFeature('channels')) {
      return true
    }

    const counts = {
      dms: 0,
      groups: 0,
      hubs: 0,
      justYouHubs: 0,
      personalDMs: 0,
      privateHubs: 0,
    }

    channels.forEach((channel: Channel) => {
      if (channelUtils.isPersonalDM(channel)) {
        counts.personalDMs += 1
      } else if (channelUtils.isPrivateHub(channel)) {
        counts.privateHubs += 1
      } else if (channelUtils.isJustYouHub(channel)) {
        counts.justYouHubs += 1
      } else if (channelUtils.isHub(channel)) {
        counts.hubs += 1
      } else if (channelUtils.isDM(channel)) {
        counts.dms += 1
      } else {
        counts.groups += 1
      }
    })

    // We only show summary hub when
    return counts.groups + counts.dms > 0
  })

export interface ChannelFilterConfig {
  spaceId: number
  channelTypes?: ChannelType | ChannelType[]
  otherUserIds?: number[]
}

const buildChannelFilter = (filterConfig: ChannelFilterConfig): Predicate<Channel> => {
  let typeFilter: undefined | Predicate<Channel>

  if (filterConfig.channelTypes) {
    typeFilter = channelUtils.isChannelType(filterConfig.channelTypes)
  }

  let userIdsFilter: undefined | Predicate<Channel>

  if (filterConfig.otherUserIds) {
    const otherUserIds = new Set(filterConfig.otherUserIds)
    userIdsFilter = channel => channelUtils.membershipMatches(otherUserIds, channel)
  }

  return channel =>
    channel.spaceId === filterConfig.spaceId &&
    (!typeFilter || typeFilter(channel)) &&
    (!userIdsFilter || userIdsFilter(channel))
}

const byFilterSelector = (filterConfig: ChannelFilterConfig) =>
  createSelector(sliceSelectors.selectAll, channels =>
    channels.filter(buildChannelFilter(filterConfig)),
  )

const idsByFilterSelector = (filterConfig: ChannelFilterConfig) =>
  createSelector(byFilterSelector(filterConfig), channels => channels.map(channel => channel.id))

const firstByFilterSelector = (filterConfig: ChannelFilterConfig) =>
  createSelector(byFilterSelector(filterConfig), channels =>
    channels.length === 0 ? undefined : channels[0],
  )

const defaultChannelWithUserSelector = (spaceId: number, otherUserId: number) =>
  createSelector(
    byFilterSelector({
      channelTypes: ['DIRECT_MESSAGE', 'HUB'],
      otherUserIds: otherUserId ? [otherUserId] : [],
      spaceId,
    }),
    channels => {
      // There should be at most two channels: 1 personal hub and 1 personal DM. However we can't
      // guarantee that in the database, so code defensively around the circumstance where there's
      // more than one of each.
      const hubs = channels.filter(channelUtils.isHub)

      if (hubs.length >= 1) {
        return hubs[0]
      }

      if (channels.length >= 1) {
        return channels[0]
      }

      return undefined
    },
  )

const personalDMSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, channels =>
    channels.find(channel => channel.spaceId === spaceId && channelUtils.isPersonalDM(channel)),
  )

const justYouHubSelector = (spaceId: number) =>
  createSelector(sliceSelectors.selectAll, channels =>
    channels.find(channel => channel.spaceId === spaceId && channelUtils.isJustYouHub(channel)),
  )
const selectIsHub = (state: RootState, spaceId: number, channelId: number) => {
  const channel = state.entities.channels.entities[buildId(spaceId, channelId)]
  return !!channel && channelUtils.isHub(channel)
}

const selectors = {
  all: sliceSelectors.selectAll,
  byFilterSelector,
  byId: selectById,
  byIdSelector,
  bySpaceIdSelector,
  defaultChannelWithUserSelector,
  entities: sliceSelectors.selectEntities,
  firstByFilterSelector,
  idsByFilterSelector,
  isHub: selectIsHub,
  justYouHubSelector,
  personalDMSelector,
  shouldShowSummaryHubSelector,
}

export { actions, selectors, getId, buildId }
export default reducer
