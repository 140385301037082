import { Optional } from '@/types/generics'

const getTypeName = (val: any): string => Object.prototype.toString.call(val).slice(8, -1)

export const isString = (val: any): val is string => typeof val === 'string'

export const isType = (typeName: string, val: any): boolean => typeName === getTypeName(val)

export function assertIsDefined<T>(
  value: Optional<T>,
  errorMessage?: string,
): asserts value is NonNullable<T> {
  if (value === undefined) {
    throw new Error(errorMessage || 'Expected value not to be undefined')
  }
  if (value === null) {
    throw new Error(errorMessage || 'Expected value not to be null')
  }
}
