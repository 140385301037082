import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import { config } from '@/core'
import { persistConfig } from './persist'
import rootReducer from './reducer'

const reducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  devTools: config.reduxDevTools,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      ignoredActionPaths: ['payload.predicate'],
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
      immutableCheck: false,
    },
  }),
  reducer,
})
const persistor = persistStore(store)

export { store, persistor }
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
