import { createSelector } from 'reselect'
import { channelUtils } from '@/core/utils'
import {
  Channel,
  ChannelStats,
  ChannelStatus,
  ChannelUser,
  ResolvedChannel,
  SpaceUser,
} from '@/types/entities'
import { Dictionary } from '@/types/generics'
import { selectors as authSelectors } from '../../auth'
import { buildId as buildChannelStatId, selectors as channelStatSelectors } from '../channelStats'
import {
  ChannelFilterConfig as BaseFilterConfig,
  buildId as buildChannelId,
  selectors as channelSelectors,
} from '../channels'
import {
  buildId as buildChannelUserId,
  selectors as channelsUsersSelectors,
} from '../channelsUsers'
import { selectors as userSelectors } from '../users'

export interface ChannelFilterConfig extends BaseFilterConfig {
  searchText?: string
  status?: ChannelStatus | ChannelStatus[]
  alwaysShowUnread?: boolean
}

interface ChannelFilterArgs {
  channelUsersIndex: Dictionary<ChannelUser | undefined>
  loweredSearchText?: string
  myId: number
}

type ResolveChannelDependencies = {
  channelStatsIndex: Dictionary<ChannelStats | undefined>
  channelUsersIndex: Dictionary<ChannelUser | undefined>
  usersIndex: Dictionary<SpaceUser | undefined>
  myId: number
}

export const resolvedChannelsSelector = (filterConfig: ChannelFilterConfig) =>
  createSelector(
    channelSelectors.byFilterSelector(filterConfig),
    resolveChannelDependenciesSelector(),
    (channels, dependencies): ResolvedChannel[] => {
      const resolvedChannels = resolveChannels(channels, dependencies)

      if (!(filterConfig.status || filterConfig.searchText)) {
        return resolvedChannels
      }

      const { channelUsersIndex, myId } = dependencies
      return resolvedChannels.filter(
        buildResolvedChannelFilter(filterConfig, {
          channelUsersIndex,
          loweredSearchText: filterConfig.searchText
            ? filterConfig.searchText.toLowerCase()
            : undefined,
          myId,
        }),
      )
    },
  )

export const resolvedChannelsByIdsSelector = (spaceId: number, channelIds: number[]) =>
  createSelector(
    channelSelectors.entities,
    resolveChannelDependenciesSelector(),
    (channelsIndex, dependencies) => {
      const channels = channelIds
        .map(channelId => channelsIndex[buildChannelId(spaceId, channelId)])
        .filter(channel => !!channel) as Channel[]
      return channels.map(channel => resolveChannel(channel, dependencies))
    },
  )

export const resolvedChannelSelector = (spaceId: number, channelId: number) =>
  createSelector(
    channelSelectors.byIdSelector(spaceId, channelId),
    resolveChannelDependenciesSelector(),
    (channel, dependencies) => {
      if (!channel) {
        return undefined
      }
      return resolveChannel(channel, dependencies)
    },
  )

export const defaultChannelSelector = (spaceId: number) =>
  createSelector(
    channelSelectors.entities,
    resolveChannelDependenciesSelector(),
    (channelsIndex, dependencies) => {
      const { channelUsersIndex, myId } = dependencies

      const defaultChannelUser = Object.values(channelUsersIndex).filter(
        cu => cu && cu.spaceId === spaceId && cu.userId === myId && cu.isDefaultChannel,
      )[0]

      let defaultChannel: Channel | undefined

      if (defaultChannelUser) {
        defaultChannel =
          channelsIndex[buildChannelId(defaultChannelUser.spaceId, defaultChannelUser.channelId)]
      } else {
        ;[defaultChannel] = Object.values(channelsIndex).filter(
          c => c && c.spaceId === spaceId && channelUtils.isPersonalDM(c),
        )
      }

      if (!defaultChannel) {
        return undefined
      }

      return resolveChannel(defaultChannel, dependencies)
    },
  )

const resolveChannelDependenciesSelector = () =>
  createSelector(
    channelsUsersSelectors.entities,
    channelStatSelectors.entities,
    userSelectors.entities,
    authSelectors.myId,
    (channelUsersIndex, channelStatsIndex, usersIndex, myId): ResolveChannelDependencies => ({
      channelStatsIndex,
      channelUsersIndex,
      myId: myId || 0,
      usersIndex,
    }),
  )
const buildResolvedChannelFilter = (
  { spaceId, status, alwaysShowUnread = false }: ChannelFilterConfig,
  { channelUsersIndex, myId, loweredSearchText }: ChannelFilterArgs,
) => {
  const statusSet = typeof status === 'string' ? new Set([status]) : new Set(status)

  return (channel: ResolvedChannel) => {
    const channelUserKey = buildChannelUserId(spaceId, channel.id, myId)
    const channelUser = channelUsersIndex[channelUserKey]
    const isUnread = channelUser?.unreadMessageCount !== 0

    if (alwaysShowUnread && isUnread) {
      return true
    }

    if (status) {
      if (!statusSet.has(channel.status)) {
        return false
      }
    }

    if (loweredSearchText) {
      if (!channel.name) {
        return false
      }
      if (!channel.name.toLowerCase().includes(loweredSearchText)) {
        return false
      }
    }

    return true
  }
}

const resolveChannels = (
  channels: Channel[],
  dependencies: ResolveChannelDependencies,
): ResolvedChannel[] => channels.map(channel => resolveChannel(channel, dependencies))

const resolveChannel = (
  channel: Channel,
  { channelStatsIndex, channelUsersIndex, usersIndex, myId }: ResolveChannelDependencies,
): ResolvedChannel => {
  const channelStats = channelStatsIndex[buildChannelStatId(channel.spaceId, channel.id)]
  const name = channelUtils.getChannelName(channel, myId, usersIndex)

  return {
    ...channel,
    channelStats,
    name,
    status: channelUtils.getChannelStatus(
      channel,
      channelUsersIndex[buildChannelUserId(channel.spaceId, channel.id, myId)],
    ),
  }
}
