import React from 'react'
import { userUtils } from '@/core/utils'
import { userSelectors } from '@/store/selectors'
import { Channel, NewSpaceMemberRole } from '@/types/entities'
import { useCurrentUserId } from './auth'
import { useAppSelector } from './redux'

export const useUser = (spaceId: number, userId: number) =>
  useAppSelector(userSelectors.byId, spaceId, userId)

const useRole = (spaceId: number, userId: number): NewSpaceMemberRole | null => {
  const user = useUser(spaceId, userId)
  return user ? userUtils.normalizeRole(user.role) : null
}

export const useMyRole = (spaceId: number): NewSpaceMemberRole | null => {
  const myId = useCurrentUserId()
  return useRole(spaceId, myId || 0)
}

type UseUserNameOptions = {
  meSubstitute?: string
  userNotFound?: string
}

export const useUserName = (spaceId: number, userId: number, opts?: UseUserNameOptions) => {
  const myId = useCurrentUserId()
  const user = useUser(spaceId, userId)

  if (!user) {
    return opts?.userNotFound || 'Someone'
  }

  if (opts?.meSubstitute && userId === myId) {
    return opts.meSubstitute
  }

  return userUtils.getFirstName(user)
}

export const useChannelPartner = (channel?: Channel) => {
  const myId = useCurrentUserId()
  const userIds = React.useMemo(() => channel?.userIds ?? [], [channel?.userIds])
  const partnerId = React.useMemo(() => userIds.find(id => id !== myId), [myId, userIds])
  const partner = useUser(channel?.spaceId ?? 0, partnerId ?? 0)
  return userIds.length > 2 ? undefined : partner
}
