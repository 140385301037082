import React from 'react'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { IconName } from '@/core/constants/icons'

interface AppDrawerLinkProps {
  iconName: IconName
  label: string
  onPress: () => void
}

function AppDrawerLink({ iconName, label, onPress }: AppDrawerLinkProps) {
  return (
    <Touchable feedback="highlight" onPress={onPress}>
      <Box direction="row" gapHorizontal="large" gapVertical="medium">
        <Icon color="shadow" name={iconName} size="h4" />
        <Box gapLeft="medium">
          <Text color="anchor">{label}</Text>
        </Box>
      </Box>
    </Touchable>
  )
}

export default React.memo(AppDrawerLink)
