import { createSelector } from 'reselect'
import { channelUtils } from '@/core/utils'
import { selectors as authSelectors } from '../../auth'
import { selectors as channelSelectors } from '../channels'
import {
  buildId as buildChannelUserId,
  selectors as channelsUsersSelectors,
} from '../channelsUsers'

export const myChannelUserSelector = (spaceId: number, channelId: number) =>
  createSelector(
    authSelectors.myId,
    channelsUsersSelectors.entities,
    (myId, channelsUsersIndex) => {
      if (!myId) {
        return undefined
      }
      const channelUserId = buildChannelUserId(spaceId, channelId, myId)
      return channelsUsersIndex[channelUserId]
    },
  )

export const isHubOnlyUserSelector = (spaceId: number) =>
  createSelector(channelSelectors.bySpaceIdSelector(spaceId), channels => {
    if (channels.length === 0) {
      return true
    }

    return channels.every(
      channel => channelUtils.isPersonalDM(channel) || channelUtils.isHub(channel),
    )
  })
