import React from 'react'
import { useSetTaskChannel, useTaskFormValues } from '@/components/tasks/TaskForm/hooks'

interface ChannelState {
  spaceId: number
  channelId: number | null
}

type ChannelAction =
  | { type: 'setSpace'; payload: { spaceId: number } }
  | { type: 'setChannel'; payload: { channelId: number | null } }
  | { type: 'setSpaceAndChannel'; payload: { spaceId: number; channelId: number | null } }

const channelStateReducer = (state: ChannelState, action: ChannelAction): ChannelState => {
  const { type } = action

  if (type === 'setSpace') {
    const { spaceId } = action.payload
    return { ...state, channelId: null, spaceId }
  }

  if (type === 'setChannel') {
    const { channelId } = action.payload
    return { ...state, channelId }
  }

  if (type === 'setSpaceAndChannel') {
    const { spaceId, channelId } = action.payload
    return { ...state, channelId, spaceId }
  }
  return state
}

export const useChannelSelector = (onClose: (cancelled: boolean) => void) => {
  const { spaceId: currentSpaceId, channelId: currentChannelId } = useTaskFormValues()
  const { setTaskChannel } = useSetTaskChannel()

  const [{ spaceId, channelId }, dispatch] = React.useReducer(channelStateReducer, {
    channelId: currentChannelId,
    spaceId: currentSpaceId,
  })

  const handleCancel = React.useCallback(() => {
    dispatch({
      payload: { channelId: currentChannelId, spaceId: currentSpaceId },
      type: 'setSpaceAndChannel',
    })
    onClose(true)
  }, [dispatch, currentSpaceId, currentChannelId, onClose])

  const handleDone = React.useCallback(() => {
    setTaskChannel(spaceId, channelId)
    onClose(false)
  }, [channelId, onClose, spaceId, setTaskChannel])

  const setSpaceId = React.useCallback(
    (value: number) => dispatch({ payload: { spaceId: value }, type: 'setSpace' }),
    [dispatch],
  )
  const setChannelId = React.useCallback(
    (value: number | null) => dispatch({ payload: { channelId: value }, type: 'setChannel' }),
    [dispatch],
  )

  React.useEffect(() => setSpaceId(currentSpaceId), [setSpaceId, currentSpaceId])
  React.useEffect(() => setChannelId(currentChannelId), [setChannelId, currentChannelId])

  return {
    channelId,
    handleCancel,
    handleDone,
    setChannelId,
    setSpaceId,
    spaceId,
  }
}
