import AsyncStorage from '@react-native-async-storage/async-storage'
import { nowMilliseconds } from '@/core/utils/dates'
import { StonlyGuideInfo, StonlyGuideKey } from './types'

const getFullKey = (key: StonlyGuideKey) => `stonlyGuides/${key}`

const getGuideInfo = async (key: StonlyGuideKey): Promise<StonlyGuideInfo | null> => {
  const value = await AsyncStorage.getItem(getFullKey(key))

  if (!value) {
    return null
  }

  return JSON.parse(value) as StonlyGuideInfo
}

export const hasBeenShown = async (key: StonlyGuideKey): Promise<boolean> => {
  const info = await getGuideInfo(key)

  return !!(info && info.shownAt)
}

export const setShown = async (key: StonlyGuideKey) => {
  await AsyncStorage.setItem(getFullKey(key), JSON.stringify({ shownAt: nowMilliseconds() }))
}

export const resetGuide = async (key: StonlyGuideKey): Promise<void> => {
  await AsyncStorage.removeItem(getFullKey(key))
}
