import { RequestThunk } from '@/api/call'
import { tagAPI } from '@/api/requests'
import { tagActions } from '@/store/actions'
import { buildTagId } from '@/store/entityIds'
import { Tag } from '@/types/entities'
import { Thunk } from '@/types/store'
import { makeEnhancedRequest } from './utils'

export const searchTags =
  (spaceId: number, name: string, page = 1): RequestThunk<typeof tagAPI.getTagsList> =>
  async dispatch => {
    const request = tagAPI.getTagsList(spaceId, { name }, { page })
    const response = await dispatch(makeEnhancedRequest(request))
    return response
  }

export const createTag =
  (spaceId: number, tag: tagAPI.CreateTag): Thunk<Tag | null> =>
  async dispatch => {
    const request = tagAPI.createTag(spaceId, tag)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(tagActions.upsertOne(response.data.tag))
      return response.data.tag
    }

    return null
  }

export const updateTag =
  (spaceId: number, tagId: number, tag: tagAPI.UpdateTag): RequestThunk<typeof tagAPI.updateTag> =>
  async dispatch => {
    const request = tagAPI.updateTag(spaceId, tagId, tag)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(tagActions.upsertOne(response.data.tag))
    }

    return response
  }

export const deleteTag =
  (spaceId: number, tagId: number): RequestThunk<typeof tagAPI.deleteTag> =>
  async dispatch => {
    const request = tagAPI.deleteTag(spaceId, tagId)
    const response = await dispatch(makeEnhancedRequest(request))

    if (response.ok) {
      dispatch(tagActions.removeOne(buildTagId(spaceId, tagId)))
    }

    return response
  }
