import { LayoutAnimation } from 'react-native'

// Animations (like LayoutAnimation.easeInEaseOut) are causing issues with modals in RN 0.68.2:
// https://github.com/facebook/react-native/issues/33733
//
// The suggested workarounds aren't working for all my cases, but removing easein/out does
const ALLOW_ANIMATION = false

export const easeInOut = () => {
  if (ALLOW_ANIMATION) {
    LayoutAnimation.easeInEaseOut()
  }
}
