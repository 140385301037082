import React from 'react'
import Button from '@/components/core/Button'
import Text from '@/components/core/Text'
import FormTextInput from '@/components/form/FormTextInput'
import Box from '@/components/layouts/Box'
import { addUserWarning } from '@/core/constants/billing'
import { disableChannels } from '@/core/constants/channels'
import { useSpace } from '@/hooks/spaces'
import { formName } from './constants'

interface UserInfoBodyProps {
  spaceId: number
  canSubmit: boolean
  isSubmitting: boolean
  onCreateHub: () => void
  onInvite: () => void
}

function UserInfoBody({
  canSubmit,
  onInvite,
  onCreateHub,
  isSubmitting,
  spaceId,
}: UserInfoBodyProps) {
  const space = useSpace(spaceId)
  const disabled = !canSubmit || isSubmitting
  const isTrial = !space || space.accountType === 'FREE_TRIAL'

  return (
    <>
      {isTrial ? null : (
        <Box gapBottom="medium" gapHorizontal="large">
          <Text align="center" color="emmre-blue" size="h5">
            {addUserWarning}
          </Text>
        </Box>
      )}
      <Box gapAll="large">
        <Box direction="row">
          <Box flex={1}>
            <FormTextInput fieldName="firstName" formName={formName} label="Their First Name" />
          </Box>
          <Box flex={1} gapLeft="medium">
            <FormTextInput fieldName="lastName" formName={formName} label="Their Last Name" />
          </Box>
        </Box>
        <Box gapTop="medium">
          <FormTextInput fieldName="emailAddress" formName={formName} label="Their Work Email" />
        </Box>
        <Box gapTop="medium">
          <FormTextInput
            fieldName="inviteNote"
            formName={formName}
            label="Invite Message"
            maxHeight={150}
            multiline
            numberOfLines={3}
          />
        </Box>
        <Box direction="row" gapTop="medium">
          {disableChannels ? null : (
            <Box flex={1}>
              <Box alignItems="center">
                <Button disabled={disabled} kind="primary" label="Invite" onPress={onInvite} />
                <Box gapTop="small">
                  <Text align="center" color="anchor" size="h5">
                    This will invite them into this workspace and into a channel with you.
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
          <Box flex={1} gapLeft="medium">
            <Box alignItems="center">
              <Button
                disabled={disabled}
                kind="warning"
                label="Create New Executive Hub"
                onPress={onCreateHub}
              />
              {disableChannels ? null : (
                <Box gapTop="small">
                  <Text align="center" color="anchor" size="h5">
                    This will invite them into this workspace and into an execute hub with you.
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default React.memo(UserInfoBody)
