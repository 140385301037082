import React from 'react'
import TaskAcceptButton from '@/components/tasks/TaskAcceptButton'
import { taskUtils } from '@/core/utils'
import { useCurrentUserId } from '@/hooks'
import { Task } from '@/types/entities'

interface TaskActionButtonProps {
  task: Task
}

function TaskActionButton({ task }: TaskActionButtonProps) {
  const myId = useCurrentUserId() || 0
  const canAccept = taskUtils.canAcceptTask(task, myId)

  return <TaskAcceptButton disabled={!canAccept} size="large" task={task} />
}

export default React.memo(TaskActionButton)
