import React from 'react'
import Selector, { SelectorProps } from '@/components/core/Selector'
import { useFormField } from '@/hooks'

interface FormSelectorProps<T extends string> extends Omit<SelectorProps<T>, 'value' | 'onChange'> {
  formName: string
  fieldName: string
}

function FormSelector<T extends string = string>({
  formName,
  fieldName,
  ...selectorProps
}: FormSelectorProps<T>) {
  const { value, setValue } = useFormField(formName, fieldName)

  return <Selector onChange={setValue} value={value} {...selectorProps} />
}

export default React.memo(FormSelector)
