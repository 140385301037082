import React from 'react'
import Box from '@/components/layouts/Box'
import { QuestionResponseForm, TaskFormBody as TaskFormBodyType } from '@/types/tasks'
import QuestionFormBody from '../QuestionFormBody'
import TaskFormChat from '../TaskFormChat'
import { useTaskFormChat } from '../hooks'

interface QuestionFormContentProps {
  initialTaskFormBody: TaskFormBodyType
  initialQuestionResponse?: QuestionResponseForm
}

function QuestionFormContent({
  initialTaskFormBody,
  initialQuestionResponse,
}: QuestionFormContentProps) {
  const { handleFocus, handleToggleChat, showChat } = useTaskFormChat(initialTaskFormBody)

  return (
    <Box flex={1} gapTop="medium">
      <QuestionFormBody initialQuestionResponse={initialQuestionResponse} />
      <Box flex={showChat ? undefined : 1} />
      <TaskFormChat
        autoFocus={initialTaskFormBody === 'COMMENTS'}
        isExpanded={showChat}
        onFocus={handleFocus}
        onToggleIsExpanded={handleToggleChat}
      />
    </Box>
  )
}

export default React.memo(QuestionFormContent)
