import React from 'react'
import StandardModal from '@/components/layouts/StandardModal'
import ChannelsList from './ChannelsList'
import { useChannelSelector } from './hooks'

interface TaskChannelModalProps {
  isOpen: boolean
  onClose: (cancelled: boolean) => void
  localModal?: boolean
}

function TaskChannelModal({ isOpen, onClose, localModal = false }: TaskChannelModalProps) {
  const { handleCancel, handleDone, channelId, setChannelId } = useChannelSelector(onClose)

  return (
    <StandardModal
      isOpen={isOpen}
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      localModal={localModal}
      minHeight={400}
      rightAction={{ label: 'Done', onAction: handleDone, type: 'linkPrimary' }}
      title="Move To..."
    >
      <ChannelsList channelId={channelId} onChannelIdChange={setChannelId} />
    </StandardModal>
  )
}

export default React.memo(TaskChannelModal)
