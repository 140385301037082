import React from 'react'
import { FlatList, ListRenderItemInfo } from 'react-native'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskChannels, useTaskFormValue } from '@/components/tasks/TaskForm/hooks'
import { collectionUtils as C, channelUtils } from '@/core/utils'
import { idKey } from '@/core/utils/components'
import { Channel, ResolvedChannel } from '@/types/entities'
import ChannelListItem from './ChannelListItem'
import { getChannelComparer } from './utils'

type ListItemData = ListRenderItemInfo<ResolvedChannel>

interface ChannelsListProps {
  channelId: number | null
  onChannelIdChange: (channelId: number | null) => void
}

function ChannelsList({ channelId, onChannelIdChange }: ChannelsListProps) {
  const assignedUserId = useTaskFormValue(fieldNames.assignedUserId)
  const channels = useTaskChannels()
  const [sortedChannels, partitionIndex] = React.useMemo(() => {
    const sorted = C.sort(getChannelComparer(channelId), channels)

    if (!assignedUserId) {
      return [sorted, -1]
    }

    const [includesAssignee, doesNotIncludeAssignee] = C.partition(
      (channel: ResolvedChannel) => channelUtils.isUserInChannel(channel, assignedUserId),
      sorted,
    )

    if (doesNotIncludeAssignee.length === 0) {
      return [includesAssignee, -1]
    }

    return [[...includesAssignee, ...doesNotIncludeAssignee], includesAssignee.length - 1]
  }, [channels, assignedUserId, channelId])
  const handleSelectChannel = React.useCallback(
    (channel: Channel) => onChannelIdChange(channel.id),
    [onChannelIdChange],
  )

  const renderChannel = React.useCallback(
    (data: ListItemData) => (
      <ChannelListItem
        channel={data.item}
        isPartition={data.index === partitionIndex}
        isSelected={data.item.id === channelId}
        onSelect={handleSelectChannel}
      />
    ),
    [handleSelectChannel, channelId, partitionIndex],
  )

  return (
    <FlatList
      data={sortedChannels}
      extraData={React.useMemo(() => ({ channelId, partitionIndex }), [channelId, partitionIndex])}
      keyExtractor={idKey}
      keyboardShouldPersistTaps="handled"
      renderItem={renderChannel}
    />
  )
}

export default React.memo(ChannelsList)
