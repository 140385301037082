import React from 'react'
import Background from '@/components/core/Background'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import { collectionUtils as C, channelUtils } from '@/core/utils'
import { useCachedSelector } from '@/hooks'
import { useChannel } from '@/hooks/channels'
import { entitySelectors } from '@/store/selectors'
import { SpaceUser } from '@/types/entities'
import BaseUsersSelector, { BaseUsersSelectorProps } from './BaseUsersSelector'

export interface UsersSelectorProps
  extends Omit<BaseUsersSelectorProps, 'users' | 'partitionIndex'> {
  channelId?: number | null
  channelMode?: 'FILTER' | 'SORT'
  excludeMe?: boolean
  onChangeSearchText?: (newSearchText: string) => void
  searchText?: string
  spaceId: number
}

function UsersSelector({
  channelId,
  channelMode = 'FILTER',
  excludeMe = false,
  onChangeSearchText,
  searchText,
  spaceId,
  ...baseProps
}: UsersSelectorProps) {
  const users = useCachedSelector(entitySelectors.filteredUsersSelector, [
    React.useMemo(
      () => ({
        channelId: channelMode === 'FILTER' ? channelId : undefined,
        excludeMe,
        searchText,
        spaceId,
      }),
      [spaceId, channelId, searchText, excludeMe, channelMode],
    ),
  ])
  const channel = useChannel(spaceId, channelId || 0)
  const [sortedUsers, partitionIndex] = React.useMemo(() => {
    if (!channel || channelMode === 'FILTER') {
      return [users, -1]
    }
    // If given a channel and the mode is sort, we will partition the users into "in the channel"
    // and "not in the channel" and then return that list
    const [inChannel, notInChannel] = C.partition(
      (user: SpaceUser) => channelUtils.isUserInChannel(channel, user.id),
      users,
    )

    if (notInChannel.length === 0) {
      return [inChannel, -1]
    }

    return [[...inChannel, ...notInChannel], inChannel.length - 1]
  }, [users, channel, channelMode])

  const usersList = (
    <BaseUsersSelector partitionIndex={partitionIndex} users={sortedUsers} {...baseProps} />
  )

  if (onChangeSearchText) {
    return (
      <Box direction="column" flex={1}>
        <Background
          borderBottomColor="heather"
          borderBottomWidth={1}
          borderTopColor="heather"
          borderTopWidth={1}
        >
          <Box gapAll="medium">
            <TextInput
              kind="bare"
              onChangeText={onChangeSearchText}
              placeholder="Search Users"
              value={searchText || undefined}
            />
          </Box>
        </Background>
        <Box flex={1}>{usersList}</Box>
      </Box>
    )
  }

  return <Box flex={1}>{usersList}</Box>
}

export default React.memo(UsersSelector)
