import React from 'react'
import { StyleSheet } from 'react-native'
import DragView from '@/components/core/DragView'
import Box from '@/components/layouts/Box'
import { SummaryItem } from '@/types/entities'
import AddSummaryItemButton from '../AddSummaryItemButton'
import { useHideDecorations } from '../hooks'
import EditItem from './EditItem'
import ViewItem from './ViewItem'
import { useSummaryItem } from './hooks'

interface EditSummaryItemProps {
  headerId: number | null
  index: number
  isDragging: boolean
  onInitiateDrag?: () => void
  showAdd?: boolean
  summaryItem: SummaryItem
}

function EditSummaryItem({
  headerId,
  index,
  isDragging,
  onInitiateDrag,
  showAdd = false,
  summaryItem,
}: EditSummaryItemProps) {
  const { isEditing, handleStartEditing, headerIsDragging } = useSummaryItem(
    summaryItem,
    headerId,
    index,
  )
  const { hideDecorations } = useHideDecorations(isDragging)

  if (headerIsDragging) {
    return null
  }

  return (
    <>
      {hideDecorations || !showAdd ? null : (
        <Box gapBottom="medium">
          <AddSummaryItemButton
            beforeItemId={summaryItem.id}
            index={index}
            showAddHeader
            showAddItem
          />
        </Box>
      )}
      <DragView
        onDragStart={isEditing ? undefined : onInitiateDrag}
        onPress={isEditing ? undefined : handleStartEditing}
        style={headerId ? styles.inHeader : undefined}
      >
        {isEditing ? (
          <EditItem summaryItem={summaryItem} />
        ) : (
          <ViewItem summaryItem={summaryItem} />
        )}
      </DragView>
    </>
  )
}

const styles = StyleSheet.create({
  inHeader: {
    paddingLeft: 16,
  },
})

export default React.memo(EditSummaryItem)
