import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { channelUtils } from '@/core/utils'
import { Channel, ResolvedChannel } from '@/types/entities'
import HubContent from './HubContent'
import NonHubContent from './NonHubContent'

interface ChannelListItemProps {
  channel: ResolvedChannel
  isSelected: boolean
  onSelect: (channel: Channel) => void
  isPartition: boolean
}

function ChannelListItem({ channel, isSelected, onSelect, isPartition }: ChannelListItemProps) {
  const handleSelect = React.useCallback(() => onSelect(channel), [onSelect, channel])

  const content = channelUtils.isHub(channel) ? (
    <HubContent channel={channel} />
  ) : (
    <NonHubContent channel={channel} />
  )

  return (
    <Background borderBottomWidth={1} borderColor={isPartition ? 'anchor' : 'transparent'}>
      <Touchable feedback="opacity" onPress={handleSelect}>
        <Box alignItems="center" direction="row" gapHorizontal="large" gapVertical="medium">
          <Box flex={1}>{content}</Box>
          {isSelected ? (
            <Box gapLeft="medium">
              <Icon name="check" size="h4" />
            </Box>
          ) : null}
        </Box>
      </Touchable>
    </Background>
  )
}

export default React.memo(ChannelListItem)
