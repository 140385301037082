import React from 'react'
import { Animated, StyleSheet, View, useWindowDimensions } from 'react-native'
import { ExtractProps } from '@/types/components'

interface RevealContainerProps {
  children: React.ReactNode
  open: boolean
  duration?: number
  height?: number | 'dynamic'
}

function RevealContainer({
  children,
  open,
  duration = 300,
  height = 'dynamic',
}: RevealContainerProps) {
  const { height: windowHeight } = useWindowDimensions()

  const [fullyOpen, setFullyOpen] = React.useState(open)

  // This container will animate max height from 0 -> window size | last measured child size
  // Once the animation is over the max height is set to `undefined` so that changes in the child
  // height will automatically occur. Those transitions (hopefully rare) won't animate, but
  // polling the child size to animate is too expensive on slower devices. (Check git history
  // to see an example of this component polling for child height to animate open changes)
  const isFirstRender = React.useRef(true)
  const lastHeight = React.useRef(windowHeight)
  const maxHeightAnimation = React.useRef(new Animated.Value(open ? lastHeight.current : 0))
  const innerContainerRef = React.useRef<View | null>(null)

  const containerAnimatedStyle = React.useMemo<ExtractProps<typeof Animated.View>['style']>(
    () => ({ maxHeight: maxHeightAnimation.current, overflow: 'hidden' }),
    [],
  )

  const openDone = React.useCallback(() => {
    setFullyOpen(true)

    // Track the last fully open height of the children so we can make animations smoother. This
    // will not work exactly right when the height of the children changes, but maybe that's
    // rare enough that the smooth animation is worth the occassionaly weirdness?
    //
    // Also, if a RevealContainer starts closed it will appear to animate open very fast the first
    // time it opens, because it will animate to the fully screen height
    innerContainerRef.current?.measure((x, y, w, h) => {
      if (h) {
        lastHeight.current = h
        maxHeightAnimation.current.setValue(h)
      }
    })
  }, [setFullyOpen])

  const animateOpen = React.useCallback(
    () =>
      Animated.timing(maxHeightAnimation.current, {
        duration,
        toValue: height === 'dynamic' ? lastHeight.current : height,
        useNativeDriver: false,
      }).start(openDone),
    [height, lastHeight, duration, openDone],
  )

  const animateClose = React.useCallback(
    () =>
      Animated.timing(maxHeightAnimation.current, {
        duration,
        toValue: 0,
        useNativeDriver: false,
      }).start(),
    [duration],
  )

  React.useEffect(() => {
    // On the first render don't do anything, the fully open value will already be set correctly
    // for the open prop. However, if it is open we will measure the children so we can provide
    // a smoother animation
    if (isFirstRender.current) {
      isFirstRender.current = false

      if (open) {
        openDone()
      }
    }

    if (open) {
      // Only animate opening when fully open is false. If it's true then it means that the open
      // prop wasn't what changed, but rather one of the other dependencies
      if (!fullyOpen) {
        animateOpen()
      }
    } else if (fullyOpen) {
      setFullyOpen(false)
    }
  }, [fullyOpen, open, animateOpen, setFullyOpen, openDone])

  React.useEffect(() => {
    // When fully open changes from true to false we need to trigger the close animation
    if (fullyOpen) {
      return
    }

    animateClose()
  }, [fullyOpen, animateClose])

  return (
    <Animated.View style={fullyOpen ? styles.open : containerAnimatedStyle}>
      <View ref={innerContainerRef} renderToHardwareTextureAndroid>
        {children}
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  open: {
    maxHeight: undefined,
  },
})

export default React.memo(RevealContainer)
