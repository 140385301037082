import { Request } from '@/api/call'

export interface VersionResponse {
  build: string
}

export const getAvailableVersion = (ts: number) =>
  new Request<VersionResponse>({
    authentication: 'PUBLIC',
    method: 'GET',
    // Timestamp is just for cache busting
    query: [['ts', ts]],
    url: '/public/version.json',
  })
