import React from 'react'
import { config } from '@/core'
import { dateUtils } from '@/core/utils'
import { useInterval } from '@/hooks/timeout'

const getTypingTimestamp = () => dateUtils.nowMilliseconds() - config.typingReportInterval * 1.5

// Check redux for typing users at a regular interval
export const useTypingTimestamp = () => {
  const [timestamp, setTimestamp] = React.useState(getTypingTimestamp())
  const { interval, clear } = useInterval(config.typingReportInterval)
  React.useEffect(() => {
    interval(() => setTimestamp(getTypingTimestamp()))
    return () => clear()
  }, [interval, clear, setTimestamp])
  return { timestamp }
}
