import React from 'react'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import { useTasksListViewContext } from '@/contexts/TasksListViewContext'
import { ColorName } from '@/core/constants/colors'
import { dateUtils as D, taskUtils } from '@/core/utils'
import { Task } from '@/types/entities'

interface TaskListItemDueDateFocusProps {
  task: Task
  hideIfNone?: boolean
}

function TaskListItemDueDateFocus({ task, hideIfNone = false }: TaskListItemDueDateFocusProps) {
  const { editTask } = useTasksListViewContext()
  const handlePress = React.useCallback(() => editTask(task, 'DUE_DATE'), [editTask, task])
  const isCompleted = taskUtils.isCompleted(task)

  let label: string | null = null
  let color: ColorName = 'obsidian'

  if (task.dueDateType === 'NONE') {
    if (hideIfNone) {
      return null
    }

    label = 'No Due Date'
  } else if (task.dueDateType === 'SOMEDAY') {
    label = 'Someday'
  } else if (task.dueDate) {
    const dueDate = D.parse(task.dueDate)
    label = D.formatShort(dueDate, false)

    if (isCompleted) {
      color = 'anchor'
    } else {
      color = dueDate.getTime() <= D.now().getTime() ? 'red' : 'obsidian'
    }
  } else {
    label = null
  }

  if (!label) {
    return null
  }

  return (
    <Touchable feedback="opacity" hitSlop="large" onPress={handlePress}>
      <Text color={color} size="h6">
        {label}
      </Text>
    </Touchable>
  )
}

export default React.memo(TaskListItemDueDateFocus)
