import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSwipeableItemParams } from 'react-native-swipeable-item'
import ListItemUnderlayAction from './ListItemUnderlayAction'
import { ListItemAction, UnderlayButtonType } from './types'

interface ListItemUnderlayProps<S> {
  buttonType: UnderlayButtonType
  buttonWidth: number
  actions: ListItemAction<S>[]
  subject: S
}

function ListItemUnderlay<S>({
  actions,
  subject,
  buttonType,
  buttonWidth,
}: ListItemUnderlayProps<S>) {
  const { close } = useSwipeableItemParams()

  return (
    <View style={styles.container}>
      {actions.map(action => (
        <ListItemUnderlayAction
          key={action.label}
          {...action}
          buttonType={buttonType}
          buttonWidth={buttonWidth}
          onClose={close}
          subject={subject}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})

export default React.memo(ListItemUnderlay) as typeof ListItemUnderlay
