import React from 'react'
import { SummaryItem } from '@/types/entities'
import HeaderItem from './HeaderItem'
import MessageItem from './MessageItem'

interface ViewSummaryListItemProps {
  summaryItem: SummaryItem
}

function ViewSummaryListItem({ summaryItem }: ViewSummaryListItemProps) {
  const { itemType } = summaryItem

  if (itemType === 'HEADER') {
    return <HeaderItem summaryItem={summaryItem} />
  }
  if (itemType === 'ITEM') {
    return <MessageItem summaryItem={summaryItem} />
  }

  throw new Error(`Unhandled item type: ${itemType}`)
}

export default React.memo(ViewSummaryListItem)
