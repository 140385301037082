import React from 'react'
import { InputMode } from '@/types/messaging'

export const useInputFocus = (inputMode: InputMode) => {
  const inputRef = React.useRef<any | undefined>(undefined)
  const firstRenderRef = React.useRef<boolean>(true)

  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
    } else {
      inputRef.current?.focus()
    }
  }, [inputMode])

  return { inputRef }
}
