import React from 'react'
import {
  useTaskFormActions,
  useTaskForm as useTaskFormBase,
} from '@/components/tasks/TaskForm/hooks'
import { useShowTaskFormContext } from '@/contexts/ShowTaskFormContext'
import { Channel } from '@/types/entities'
import { TaskType } from '@/types/tasks'

export const useTaskForm = (
  taskType: TaskType,
  channel: Channel,
  onClose: () => void,
  onTaskCreated: () => void,
) => {
  const taskDefaults = React.useMemo(
    () => ({ channelId: channel.id, spaceId: channel.spaceId }),
    [channel.spaceId, channel.id],
  )

  const { resetForm, formName, questionMode, setQuestionMode } = useTaskFormBase({
    formContext: 'messages',
    shouldInitializeForm: false,
    spaceId: channel.spaceId,
    taskDefaults,
    taskId: undefined,
  })

  const {
    canSubmit: canSubmitTask,
    handleSubmit: handleSubmitTask,
    handleSubmitDisabled: handleSubmitTaskDisabled,
    handleCancel,
  } = useTaskFormActions({
    checkNotifyOnCreate: false,
    formName,
    interactionMode: 'create',
    messageId: undefined,
    onClose,
    taskId: undefined,
    taskType,
  })

  const onSuccess = React.useCallback(() => {
    onTaskCreated()
    resetForm()
  }, [onTaskCreated, resetForm])
  const { showTaskForm } = useShowTaskFormContext()
  const showTaskModal = React.useCallback(
    () =>
      showTaskForm({
        checkNotifyOnCreate: false,
        createTaskType: taskType,
        defaultSpaceId: channel.spaceId,
        formContext: 'messages',
        initialQuestionMode: questionMode,
        initialSize: 'LARGE',
        onSuccess,
        shouldInitializeForm: false,
        taskDefaults,
      }),
    [showTaskForm, channel.spaceId, taskDefaults, onSuccess, taskType, questionMode],
  )

  return {
    canSubmitTask,
    formName,
    handleCancel,
    handleSubmitTask,
    handleSubmitTaskDisabled,
    questionMode,
    resetForm,
    setQuestionMode,
    showTaskModal,
  }
}
