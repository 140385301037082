import React from 'react'
import { Image, LayoutChangeEvent, StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Box from '@/components/layouts/Box'
import { collectionUtils as C } from '@/core/utils'
import { fetchGiphyResults } from '@/thunks/giphy'
import { GiphyGif } from '@/types/giphy'
import TextInput from '../TextInput'
import GiphyItem from './GiphyItem'

interface GiphyPickerProps {
  onPick: (url: string) => void
}
const COLUMN_COUNT = 3

function GiphyPicker({ onPick }: GiphyPickerProps) {
  const [searchText, setSearchText] = React.useState('')
  const [giphyResults, setGiphyResults] = React.useState<GiphyGif[]>([])
  const [gifWidth, setGifWidth] = React.useState(0)

  const fetchResults = React.useCallback(async (text: string) => {
    setGiphyResults(await fetchGiphyResults(text, 24))
  }, [])

  // This "should" be `NodeJS.Timeout | undefined`
  // instead of `any` but clearTimeout is coming from
  // the react native types so it wants a number :shrug:
  const searchTimeout = React.useRef<any>()

  React.useEffect(() => {
    clearTimeout(searchTimeout.current)
    if (searchText.length < 2) {
      setGiphyResults([])
      return () => {
        // nothing to clear
      }
    }

    searchTimeout.current = setTimeout(() => fetchResults(searchText), 200)
    return () => clearTimeout(searchTimeout.current)
  }, [searchText, fetchResults])

  const handleOnPick = React.useCallback(
    (gif: GiphyGif) => {
      onPick(gif.images.original.url)
    },
    [onPick],
  )

  const updateContainerSize = React.useCallback((event: LayoutChangeEvent) => {
    setGifWidth(event.nativeEvent.layout.width / COLUMN_COUNT)
  }, [])

  return (
    <View onLayout={updateContainerSize} style={styles.container}>
      <Box gapBottom="medium">
        <TextInput autoFocus onChangeText={setSearchText} value={searchText} />
      </Box>
      <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
        {C.chunk(COLUMN_COUNT, giphyResults).map(row => (
          <Box key={row[0].images.preview_gif.url} direction="row" justifyContent="center">
            {row.map(gif => (
              <GiphyItem
                key={gif.images.preview_gif.url}
                gif={gif}
                onPress={handleOnPick}
                width={gifWidth}
              />
            ))}
          </Box>
        ))}
      </KeyboardAwareScrollView>
      <Box direction="row" gapTop="medium" justifyContent="flex-end">
        <Image source={require('assets/powered-by-giphy-dark.png')} style={styles.image} />
      </Box>
    </View>
  )
}

const styles = StyleSheet.create({
  attribution: {
    backgroundColor: '#fffa',
    bottom: 0,
    padding: 5,
    position: 'absolute',
    right: 0,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    height: 42,
    width: 200,
  },
})

export default React.memo(GiphyPicker)
