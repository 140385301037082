export const authScreenOptions = {
  requestPasswordReset: { headerShown: false, title: 'Request Passsord Reset' },
  resetPassword: { headerShown: false, title: 'Reset Password' },
  signIn: { headerShown: false, title: 'Sign In' },
  tokenIn: { headerShown: false, title: 'Token In' },
}

export const integrationsManagementScreenOptions = {
  destination: { headerShown: false, title: 'Integrations Destination' },
  email: { headerShown: false, title: 'Email' },
  index: { headerShown: false, title: 'Integrations' },
  slack: { headerShown: false, title: 'Slack' },
  sms: { headerShown: false, title: 'SMS' },
}

export const accountManagementScreenOptions = {
  about: { headerShown: false, title: 'About' },
  email: { headerShown: false, title: 'Email' },
  finances: { headerShown: false, title: 'Finances' },
  help: { headerShown: false, title: 'Help' },
  index: { headerShown: false, title: 'Account' },
  integrations: { headerShown: false, title: 'Integrations' },
  members: { headerShown: false, title: 'Members' },
  notifications: { headerShown: false, title: 'Notifications' },
  password: { headerShown: false, title: 'Password' },
  preferences: { headerShown: false, title: 'Preferences' },
  profile: { headerShown: false, title: 'Edit Profile' },
  space: { headerShown: false, title: 'Edit Space' },
  viewProfile: { headerShown: false, title: 'View Profile' },
}

export const signUpScreenOptions = {
  'email-step': { headerShown: false, title: 'Email Address' },
  'password-step': { headerShown: false, title: 'Password' },
  'payment-step': { headerShown: false, title: 'Payment' },
  'role-choice-step': { headerShown: false, title: 'Profile' },
  'teams-channel-setup': { headerShown: false, title: 'Channel' },
  'teams-password-step': { headerShown: false, title: 'Password' },
  'teams-tasks-setup': { headerShown: false, title: 'Channel' },
  'teams-team-members-setup': { headerShown: false, title: 'Channel' },
  'teams-workspace-setup': { headerShown: false, title: 'Workspace' },
}

export const rootScreenOptions = {
  accountManagement: { headerShown: false, title: 'Account' },
  archivedItems: { headerShown: false, title: 'Archived Items' },
  auth: { headerShown: false, swipeEnabled: false, title: 'Emmre' },
  create: { headerShown: false, title: 'Create' },
  createSpace: { headerShown: false, swipeEnabled: false, title: 'Create Space' },
  joinSpace: { headerShown: false, title: 'Join Space' },
  myDay: { headerShown: false, title: 'My Day', unmountOnBlur: true },
  noSpaces: { headerShown: false, title: 'No Spaces' },
  signUp: { headerShown: false, title: 'Sign up' },
  spaces: { headerShown: false, title: 'Spaces' },
}

export const spaceScreenOptions = {
  channel: { headerShown: false, title: 'Space' },
  editSummary: { headerShown: false, title: 'Edit Summary' },
  main: { headerShown: false, title: 'Spaces' },
  privateTasks: { headerShown: false, title: 'Private To-dos' },
  summaries: { headerShown: false, title: 'Summaries' },
  task: { headerShown: false, title: 'Task' },
  tasks: { headerShown: false, title: 'Tasks' },
  thread: { headerShown: false, title: 'Thread' },
  threads: { headerShown: false, title: 'Threads' },
  viewSummary: { headerShown: false, title: 'Summary' },
}
