import React from 'react'
import { stopPropagation } from '@/core/utils/components'
import MouseView from '../MouseView'

function PreventDragView({ children }: { children: React.ReactElement }) {
  return (
    <MouseView onMouseDown={stopPropagation} onMouseUp={stopPropagation}>
      {children}
    </MouseView>
  )
}

export default React.memo(PreventDragView)
