import React from 'react'
import Link from '@/components/core/Link'
import Box from '@/components/layouts/Box'

interface MessageListLoadMoreButtonProps {
  hasMore: boolean
  isLoading: boolean
  onLoadMore: () => void
}

function MessageListLoadMoreButton({
  hasMore,
  isLoading,
  onLoadMore,
}: MessageListLoadMoreButtonProps) {
  if (!hasMore || isLoading) {
    return null
  }
  return (
    <Box direction="row" gapAll="small" gapBottom="none" justifyContent="center">
      <Link label="Load More" onPress={onLoadMore} />
    </Box>
  )
}

export default React.memo(MessageListLoadMoreButton)
