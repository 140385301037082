import { buildBoardId } from '@/store/entityIds'
import { Board, Channel, Space, SpaceUser, Tag } from '@/types/entities'
import {
  GroupedTask,
  IncludedTask,
  TaskGroupKey,
  TaskGrouping,
  TaskGroupingContext,
  TaskGroupingType,
  TaskToKey,
  TaskToKeys,
} from '@/types/tasks'
import { getFutureDate, getPastDate } from './dates'
import { isAccepted } from './status'

const assignedUserToTaskKey = (user: SpaceUser): TaskGroupKey => user.id.toString()
const channelToTaskKey = (channel: Channel): TaskGroupKey => `${channel.spaceId}:${channel.id}`
const creatorToTaskKey = (user: SpaceUser): TaskGroupKey => user.id.toString()
const boardToTaskKey = (board: Board): TaskGroupKey => board.name
const spaceToTaskKey = (space: Space) => space.id.toString()
const tagToTaskKey = (tag: Tag): TaskGroupKey => `${tag.spaceId}:${tag.id}`

export const taskKeys = {
  assignedUser: assignedUserToTaskKey,
  board: boardToTaskKey,
  channel: channelToTaskKey,
  creator: creatorToTaskKey,
  space: spaceToTaskKey,
  tag: tagToTaskKey,
}
const taskToAssignedUserKey: TaskToKey = task => task.assignedUserId.toString()
const taskToChannelKey: TaskToKey = task => `${task.spaceId}:${task.channelId}`
const taskToCreatorKey: TaskToKey = task => task.creatorId.toString()
const taskToBoardKey: TaskToKey = (task, groupingContext) =>
  groupingContext.boards[buildBoardId(task.spaceId, task.boardId || 0)]?.name || 'No Project'
const taskToCreatedDateKey: TaskToKey = task => getPastDate(task.createdAt)
const taskToDueDateKey: TaskToKey = task => {
  if (task.dueDateType === 'DATE') {
    return getFutureDate(task.dueDate as string)
  }
  return task.dueDateType
}
const taskToUrgencyKey: TaskToKey = task => task.urgency
const taskToSpaceKey: TaskToKey = task => task.spaceId.toString()
const taskToStatusKey: TaskToKey = task => (isAccepted(task) ? 'ACCEPTED' : 'UNACCEPTED')

const taskToTagKeys: TaskToKeys = task =>
  task.tagIds ? task.tagIds.map(tagId => `${task.spaceId}:${tagId}`) : []

const getTaskToKey = (groupingType: TaskGroupingType): TaskToKey => {
  switch (groupingType) {
    case 'ASSIGNED_USER':
      return taskToAssignedUserKey
    case 'CHANNEL':
      return taskToChannelKey
    case 'CREATOR':
      return taskToCreatorKey
    case 'BOARD':
      return taskToBoardKey
    case 'CREATED_DATE':
      return taskToCreatedDateKey
    case 'DUE_DATE':
      return taskToDueDateKey
    case 'URGENCY':
      return taskToUrgencyKey
    case 'STATUS':
      return taskToStatusKey
    case 'SPACE':
      return taskToSpaceKey
    default:
      throw new Error(`Invalid task grouping type: ${groupingType}`)
  }
}

const getTaskToKeys = (groupingType: TaskGroupingType): TaskToKeys => {
  switch (groupingType) {
    case 'TAG':
      return taskToTagKeys
    default:
      throw new Error(`Invalid multi task grouping type: ${groupingType}`)
  }
}

const getNoGroupLabel = (groupingType: TaskGroupingType): string => {
  switch (groupingType) {
    case 'ASSIGNED_USER':
      return 'No Assignee'
    case 'CHANNEL':
      return 'No Channel'
    case 'CREATOR':
      return 'No Creator'
    case 'BOARD':
      return 'No Project'
    case 'CREATED_DATE':
      return 'No Created Date'
    case 'DUE_DATE':
      return 'No Due Date'
    case 'URGENCY':
      return 'No Priority'
    case 'STATUS':
      return 'No Status'
    case 'TAG':
      return 'No Tag'
    default:
      throw new Error(`Invalid label task grouping type: ${groupingType}`)
  }
}

export const toGroupedTask =
  (label: string | null) =>
  (item: IncludedTask): GroupedTask => ({
    ...item,
    key: `${label}:${item.key}`,
    label,
  })

export const groupTasks = (
  grouping: TaskGrouping,
  tasks: IncludedTask[],
  groupingContext: TaskGroupingContext,
): GroupedTask[] => {
  const { groups, groupingType } = grouping
  const groupIndex: Record<TaskGroupKey, IncludedTask[]> = Object.fromEntries(
    groups.map(group => [group.key, []]),
  )
  const ungrouped: IncludedTask[] = []

  const multiMatch = groupingType === 'TAG'

  if (multiMatch) {
    // For multimatch put each task in one or more groups. If it matches no groups
    // then put it in unmatched
    const getKeys = getTaskToKeys(groupingType)
    tasks.forEach(item => {
      const keys = getKeys(item.task, groupingContext)
      let matched = false

      keys.forEach(key => {
        if (key in groupIndex) {
          matched = true
          groupIndex[key].push(item)
        }
      })

      if (!matched) {
        ungrouped.push(item)
      }
    })
  } else {
    // For single match put each task in one group or in unmatched
    const getKey = getTaskToKey(groupingType)
    tasks.forEach(item => {
      const key = getKey(item.task, groupingContext)
      if (key in groupIndex) {
        groupIndex[key].push(item)
      } else {
        ungrouped.push(item)
      }
    })
  }

  const output: GroupedTask[] = []

  groups.forEach(group => {
    const items = groupIndex[group.key]

    if (!items) {
      throw new Error(`Grouping key ${group.key} not found in index. This should never happen`)
    }

    output.push(...items.map(toGroupedTask(group.label)))
  })

  if (ungrouped.length !== 0) {
    output.push(...ungrouped.map(toGroupedTask(getNoGroupLabel(grouping.groupingType))))
  }

  return output
}
