import React from 'react'
import { ScrollView } from 'react-native'
import StackHeader from '@/components/headers/StackHeader'
import Box from '@/components/layouts/Box'
import CenterWideContainer from '@/components/layouts/CenterWideContainer'
import ContentContainer from '@/components/layouts/ContentContainer'
import CreateHubModal from '@/components/modals/CreateHubModal'
import { sizes } from '@/core/constants'
import { disableChannels } from '@/core/constants/channels'
import { useCreateChannel } from '@/hooks/restrictedActions'
import {
  RootNavigationProp as NavigationProp,
  RootRouteProp as RouteProp,
} from '@/screens/NarrowScreen/routes'
import AccountManagementItem from '../AccountManagement/AccountManagementItem'
import { useCreateHubModal } from './hooks'

interface GeneralCreateProps {
  navigation: NavigationProp<'create'>
  route: RouteProp<'create'>
}

function GeneralCreateScreen({ navigation, route }: GeneralCreateProps) {
  const { spaceId } = route.params
  const createHubModal = useCreateHubModal()
  const handleBack = React.useCallback(() => navigation.goBack(), [navigation])
  const createSpace = React.useCallback(() => navigation.navigate('create-space'), [navigation])
  const handleAddChannel = useCreateChannel({ onCreate: navigation.goBack, spaceId })

  return (
    <ContentContainer avoidKeyboard context="SCREEN" insetBottom>
      <StackHeader
        maxContentWidth={sizes.maxFormWidth}
        onBack={handleBack}
        theme="blue"
        title="Create"
      />
      <CenterWideContainer highlightContent maxWidth={sizes.maxFormWidth}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <Box gapAll="medium">
            <AccountManagementItem label="Executive Hub" onPress={createHubModal.open} />
            {disableChannels ? null : (
              <AccountManagementItem label="Channel" onPress={handleAddChannel} />
            )}
            <AccountManagementItem label="Workspace" onPress={createSpace} />
          </Box>
        </ScrollView>
      </CenterWideContainer>
      <CreateHubModal
        isOpen={createHubModal.isOpen}
        onClose={createHubModal.close}
        onCreated={navigation.goBack}
        spaceId={spaceId}
      />
    </ContentContainer>
  )
}

export default React.memo(GeneralCreateScreen)
