import React from 'react'
import Badge, { BadgeProps } from '@/components/core/Badge'
import { useUnreadMessagesCountInOtherChannels } from '@/hooks/messages/unreadMessages'

interface UnreadMessagesInOtherChannelsBadgeProps extends Omit<BadgeProps, 'count'> {
  spaceId: number
  channelId: number
}

function UnreadMessagesInOtherChannelsBadge({
  spaceId,
  channelId,
  ...badgeProps
}: UnreadMessagesInOtherChannelsBadgeProps) {
  const unreadMessagesCount = useUnreadMessagesCountInOtherChannels(spaceId, channelId)
  return <Badge {...badgeProps} count={unreadMessagesCount} />
}

export default React.memo(UnreadMessagesInOtherChannelsBadge)
