import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import { CreateSpaceScreenProps } from './types'

const LazyCreateSpaceScreen = React.lazy(() => import('./index'))

function CreateSpaceScreen(props: CreateSpaceScreenProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyCreateSpaceScreen {...props} />
    </Suspense>
  )
}

export default CreateSpaceScreen
