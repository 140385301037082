import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'

const LazyAuthScreen = React.lazy(() => import('./index'))

function AuthScreen() {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyAuthScreen />
    </Suspense>
  )
}

export default AuthScreen
