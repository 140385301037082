import { collectionUtils } from '@/core/utils'
import rootReducer from '../reducer'

const copyPaths = [
  ['*'], // all top level keys
  ['entities', '*'], // all keys directly under entities
]

export const migrate = (state: any) => {
  const defaultState = rootReducer(undefined, { type: '@@EMMRE_INIT' })
  const migratedState = { ...state }
  // Make sure we pick up new state slices after loading persisted state
  collectionUtils.copyMissingPaths(defaultState, migratedState, copyPaths)
  return Promise.resolve(migratedState)
}
