import React from 'react'
import { ScrollView } from 'react-native'
import AttachmentsList, { AttachmentsListProps } from './AttachmentsList'

function ScrollableAttachmentsList(attachmentsListProps: AttachmentsListProps) {
  return (
    <ScrollView
      horizontal
      keyboardShouldPersistTaps="handled"
      showsHorizontalScrollIndicator={false}
    >
      <AttachmentsList {...attachmentsListProps} />
    </ScrollView>
  )
}

export default React.memo(ScrollableAttachmentsList)
