import React from 'react'
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native'
import { Measure } from '@/types/components'

interface LayoutWrapperProps<S> {
  callbackState: S
  children: React.ReactNode
  onLayout: (state: S, ref: React.RefObject<View | null>, measure: Measure) => void
  style?: StyleProp<ViewStyle>
}

function LayoutWrapper<S>({ children, onLayout, callbackState, style }: LayoutWrapperProps<S>) {
  const viewRef = React.useRef<View | null>(null)

  const handleLayout = React.useCallback(
    ({ nativeEvent }: LayoutChangeEvent) => {
      onLayout(callbackState, viewRef, nativeEvent.layout)
    },
    [callbackState, onLayout],
  )

  return (
    <View ref={viewRef} onLayout={handleLayout} style={style}>
      {children}
    </View>
  )
}

export default React.memo(LayoutWrapper) as typeof LayoutWrapper
