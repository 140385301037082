import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import {
  Channel,
  SentMessage,
  SpaceUser,
  Summary,
  SummaryItem,
  SummaryStatus,
  Task,
} from '@/types/entities'

export interface SummaryResponse {
  summary: Summary
}

export interface SummariesResponse {
  summaries: Summary[]
}

export interface SummaryItemResponse {
  summaryItem: SummaryItem
}

export interface SummaryItemsResponse {
  summaryItems: SummaryItem[]
}

export interface SummaryItemCommentResponse {
  summaryItem: SummaryItem
  messages: SentMessage[]
}

export interface GetSummaryResponse {
  channel: Channel
  messages: SentMessage[]
  summary: Summary
  summaryItems: SummaryItem[]
  tasks: Task[]
  users: SpaceUser[]
}

export interface SummaryShareResponse {
  url: string
}

export type CreateSummary = Pick<Summary, 'title'> &
  Partial<Pick<Summary, 'greeting' | 'closing' | 'viewerCanAddTasks'>> &
  Partial<{
    copyHeaders: boolean
    copyUnacknowledged: boolean
    copyUnansweredQuestions: boolean
    copyUncompletedTasks: boolean
  }>

export type UpdateSummary = Partial<
  Pick<Summary, 'title' | 'greeting' | 'closing' | 'viewerCanAddTasks'>
>
export type CreateSummaryShare = { withUserId: number }
export type CreateSummaryItem = Pick<SummaryItem, 'itemType'> &
  Partial<
    Pick<SummaryItem, 'content' | 'description' | 'taskId' | 'canAcknowledge' | 'canComment'>
  > & {
    beforeItemId?: number | null
  }
export type UpdateSummaryItem = Partial<
  Pick<SummaryItem, 'content' | 'description' | 'canAcknowledge' | 'canComment' | 'taskId'>
>
export type MoveSummaryItems = {
  summaryItemIds: number[]
  beforeItemId?: number | null
}
export type SubmitSummary = {
  notify: boolean
}
type ReviewSummaryStatus = 'REVIEWED' | 'COMPLETED'
export type ReviewSummary = {
  status: ReviewSummaryStatus
  notify: boolean
}

export const acknowledgeSummaryItem = (spaceId: number, summaryItemId: number) =>
  new Request<SummaryItemResponse>({
    authentication: 'PRIVATE',
    method: 'PUT',
    url: `v1/spaces/${spaceId}/summary-items/${summaryItemId}/acknowledge`,
  })

export const createSummaryItem = (spaceId: number, summaryId: number, body: CreateSummaryItem) =>
  new Request<SummaryItemResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}/summary-items`,
  })

export const createSummaryItemComment = (spaceId: number, summaryItemId: number, comment: string) =>
  new Request<SummaryItemCommentResponse>({
    authentication: 'PRIVATE',
    body: { comment },
    method: 'POST',
    url: `v1/spaces/${spaceId}/summary-items/${summaryItemId}/comments`,
  })

export const createSummaryShare = (spaceId: number, summaryId: number, body: CreateSummaryShare) =>
  new Request<SummaryShareResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}/share`,
  })

export const createSummary = (spaceId: number, channelId: number, body: CreateSummary) =>
  new Request<SummaryResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'POST',
    url: `v1/spaces/${spaceId}/channels/${channelId}/summaries`,
  })

export const deleteSummaryItem = (spaceId: number, summaryItemId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/spaces/${spaceId}/summary-items/${summaryItemId}`,
  })

export const getSummariesList = (
  spaceId: number,
  channelId: number,
  statuses: null | SummaryStatus[],
  paging?: PagingRequest,
) =>
  new Request<SummariesResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    query: [['statuses', statuses]],
    url: `v1/spaces/${spaceId}/channels/${channelId}/summaries`,
  })

export const getSummary = (spaceId: number, summaryId: number) =>
  new Request<GetSummaryResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}`,
  })

export const moveSummaryItems = (spaceId: number, summaryId: number, body: MoveSummaryItems) =>
  new Request<SummaryItemsResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}/move-summary-items`,
  })

export const reviewSummary = (spaceId: number, summaryId: number, body: ReviewSummary) =>
  new Request<SummaryResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}/review`,
  })

export const submitSummary = (spaceId: number, summaryId: number, body: SubmitSummary) =>
  new Request<SummaryResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}/submit`,
  })

export const updateSummaryItem = (
  spaceId: number,
  summaryItemId: number,
  body: UpdateSummaryItem,
) =>
  new Request<SummaryItemResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/summary-items/${summaryItemId}`,
  })

export const updateSummary = (spaceId: number, summaryId: number, body: UpdateSummary) =>
  new Request<SummaryResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'PUT',
    url: `v1/spaces/${spaceId}/summaries/${summaryId}`,
  })
