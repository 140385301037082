import React from 'react'
import { fieldNames } from '@/components/tasks/TaskForm/constants'
import { useTaskFormField, useTaskFormValues } from '@/components/tasks/TaskForm/hooks'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { channelUtils } from '@/core/utils'
import { useAppDispatch } from '@/hooks'
import { useAlertActionsAsync } from '@/hooks/actions'
import { useChannel } from '@/hooks/channels'
import { channelThunks } from '@/thunks'
import { User } from '@/types/entities'

export const useUserSelector = (onClose: (cancelled: boolean) => void) => {
  const dispatch = useAppDispatch()
  const { interactionMode } = useTaskFormContext()
  const { spaceId } = useTaskFormValues()
  const { value: currentUserId, setValue: setCurrentUserId } = useTaskFormField<number | null>(
    fieldNames.assignedUserId,
  )
  const { value: currentChannelId, setValue: setCurrentChannelId } = useTaskFormField<
    number | null
  >(fieldNames.channelId)
  const [userId, setUserId] = React.useState(currentUserId)
  const [searchText, setSearchText] = React.useState('')
  const channel = useChannel(spaceId, currentChannelId || 0)

  const handleCancel = React.useCallback(() => {
    onClose(true)
  }, [onClose])

  const getMoveConfirmation = useAlertActionsAsync<boolean, void>(
    React.useMemo(
      () => [
        { label: 'Assign and Move', value: true },
        { label: 'Cancel', type: 'cancel', value: false },
      ],
      [],
    ),
    {
      message: `You are assigning this task to someone that is not in this Channel.

This will move it from this Channel to the Direct Channel you share with the person you are assigning it to.

The members of the Channel it is being moved from will no longer have access to this task.`,
      title: 'Assign and move?',
    },
  )

  const handleDone = React.useCallback(async () => {
    if (channel && !channelUtils.isUserInChannel(channel, userId || 0)) {
      if (interactionMode === 'edit') {
        const move = await getMoveConfirmation()

        if (!move) {
          return
        }
      }
      const defaultChannel = await dispatch(
        channelThunks.getOrCreateDefaultChannelWithUser(spaceId, userId),
      )
      setCurrentChannelId(defaultChannel.id)
    }

    setCurrentUserId(userId)
    onClose(false)
  }, [
    setCurrentUserId,
    channel,
    userId,
    setCurrentChannelId,
    onClose,
    spaceId,
    dispatch,
    interactionMode,
    getMoveConfirmation,
  ])

  const selectUser = React.useCallback(
    (user: User) => {
      setUserId(user.id)
    },
    [setUserId],
  )

  React.useEffect(() => setUserId(currentUserId), [setUserId, currentUserId])

  return {
    channelId: currentChannelId,
    handleCancel,
    handleDone,
    searchText,
    selectUser,
    setSearchText,
    spaceId,
    userId,
  }
}
