import { Picker } from '@react-native-picker/picker'
import React from 'react'
import Box from '@/components/layouts/Box'
import { ReminderOffset } from '@/types/entities'
import CustomOffsetTime from './CustomOffsetTime'
import { useCustomOffset } from './hooks'

interface CustomOffsetProps {
  onOffsetChange: (remindAtOffset: ReminderOffset | null) => void
  selectedOffset: ReminderOffset | null
  selectedRemindAt: Date | null
  dueDate: Date | null
}

function CustomOffset({
  onOffsetChange,
  selectedOffset,
  dueDate,
  selectedRemindAt,
}: CustomOffsetProps) {
  const { unitOptions, amountOptions, unit, amount, setUnit, setAmount, handleOffsetTimeChange } =
    useCustomOffset(selectedOffset, onOffsetChange)

  return (
    <>
      <Box direction="row">
        <Box flex={1}>
          <Picker onValueChange={setAmount} selectedValue={amount.toString()}>
            {amountOptions.map(opt => (
              <Picker.Item key={opt.value.toString()} label={opt.label} value={opt.value} />
            ))}
          </Picker>
        </Box>
        <Box flex={2}>
          <Picker onValueChange={setUnit} selectedValue={unit}>
            {unitOptions.map(opt => (
              <Picker.Item
                key={opt.value.toString()}
                label={`${opt.label} before`}
                value={opt.value}
              />
            ))}
          </Picker>
        </Box>
      </Box>
      <CustomOffsetTime
        dueDate={dueDate}
        onChange={handleOffsetTimeChange}
        selectedOffset={selectedOffset}
        selectedRemindAt={selectedRemindAt}
      />
    </>
  )
}

export default React.memo(CustomOffset)
