import React from 'react'
import { taskUtils } from '@/core/utils'
import { MessageListItemData } from '@/types/messaging'
import MessageContent from './MessageContent'
import QuestionContent from './QuestionContent'
import TaskContent from './TaskContent'

interface TaskActivityContentProps {
  disabled?: boolean
  isFromMe: boolean
  item: MessageListItemData
  simplified: boolean
  isDisplayedInThread: boolean
}

function TaskActivityContent({
  disabled = false,
  isFromMe,
  item,
  simplified,
  isDisplayedInThread,
}: TaskActivityContentProps) {
  const { parentTask } = item

  if (parentTask) {
    if (taskUtils.isQuestion(parentTask)) {
      return (
        <QuestionContent
          disabled={disabled}
          isFromMe={isFromMe}
          item={item}
          simplified={simplified}
          task={parentTask}
        />
      )
    }
    return (
      <TaskContent
        disabled={disabled}
        isFromMe={isFromMe}
        item={item}
        simplified={simplified}
        task={parentTask}
      />
    )
  }

  return (
    <MessageContent
      disabled={disabled}
      isDisplayedInThread={isDisplayedInThread}
      isFromMe={isFromMe}
      item={item}
    />
  )
}

export default React.memo(TaskActivityContent)
