import React from 'react'
import TimeInput from '@/components/core/TimeInput'
import Box from '@/components/layouts/Box'
import { dateUtils as D, offsetUtils as O } from '@/core/utils'
import { ReminderOffset } from '@/types/entities'

interface CustomOffsetTimeProps {
  selectedOffset: ReminderOffset | null
  dueDate: Date | null
  onChange: (date: Date | null) => void
  selectedRemindAt: Date | null
}

function CustomOffsetTime({
  selectedOffset,
  selectedRemindAt,
  dueDate,
  onChange,
}: CustomOffsetTimeProps) {
  const date = React.useMemo(() => {
    const today = D.floor(D.now(), 'day')

    if (selectedOffset && O.hasTime(selectedOffset)) {
      const offsetDate = D.setTime(
        today,
        selectedOffset.atHour as number,
        selectedOffset.atMinute as number,
      )
      return offsetDate
    }

    if (selectedRemindAt) {
      return selectedRemindAt
    }

    if (dueDate) {
      return D.matchTime(dueDate, today)
    }

    return today
  }, [selectedOffset, selectedRemindAt, dueDate])

  if (!dueDate) {
    return null
  }

  const enabled = selectedOffset && O.allowTime(selectedOffset)

  return (
    <Box direction="row" gapTop="medium" justifyContent="center">
      <TimeInput disabled={!enabled} onChange={onChange} value={date} />
    </Box>
  )
}

export default React.memo(CustomOffsetTime)
