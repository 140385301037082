import React from 'react'
import Action from '@/components/core/Action'
import { useTaskFormContext } from '@/contexts/TaskFormContext'

function TaskFormCancelButton() {
  const { close, canClose, cancel, interactionMode } = useTaskFormContext()
  if (interactionMode === 'view') {
    return null
  }
  if (canClose) {
    return <Action label="Close" onAction={close} type="linkPrimary" />
  }
  return <Action label="Cancel" onAction={cancel} type="linkPrimary" />
}

export default React.memo(TaskFormCancelButton)
