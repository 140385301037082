import React from 'react'
import { View } from 'react-native'
import { ColorName, getColor } from '@/core/constants/colors'
import Touchable from './Touchable'

interface HandleProps {
  color: ColorName
  onPress?: () => void
}

function Handle({ color, onPress }: HandleProps) {
  const style = React.useMemo(
    () => ({
      backgroundColor: getColor(color),
      borderRadius: 2.5,
      height: 5,
      width: 30,
    }),
    [color],
  )

  if (!onPress) {
    return <View style={style} />
  }
  return (
    <Touchable feedback="none" onPress={onPress}>
      <View style={style} />
    </Touchable>
  )
}

export default React.memo(Handle)
