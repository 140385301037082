import React from 'react'
import RevealContainer from '@/components/animated/RevealContainer'
import Box from '@/components/layouts/Box'
import StandardModal from '@/components/layouts/StandardModal'
import { ReminderOffset } from '@/types/entities'
import { Action } from '@/types/generics'
import CustomOffset from './CustomOffset'
import CustomToggle from './CustomToggle'
import DueDateLabel from './DueDateLabel'
import OffsetsList from './OffsetsList'
import RemindAtIndicator from './RemindAtIndicator'
import ReminderPastWarning from './ReminderPastWarning'
import { useReminderSelector } from './hooks'

interface TaskDatesModalProps {
  isOpen: boolean
  onClose: () => void
  onRemindAtChange: Action<ReminderOffset | null>
  selectedRemindAt: Date | null
  selectedRemindAtOffset: ReminderOffset | null
  selectedDate: Date | null
  localModal: boolean
}

function TaskReminderModal({
  isOpen,
  onClose,
  onRemindAtChange,
  selectedRemindAt,
  selectedRemindAtOffset,
  selectedDate,
  localModal,
}: TaskDatesModalProps) {
  const {
    handleDone,
    handleClear,
    showCustom,
    toggleShowCustom,
    handleSetRemindAtAndClose,
    isValidRemindAt,
  } = useReminderSelector(onClose, onRemindAtChange, selectedDate, selectedRemindAt)
  return (
    <StandardModal
      depth={2}
      isOpen={isOpen}
      localModal={localModal}
      minHeight={0}
      rightAction={{
        disabled: !isValidRemindAt,
        label: 'Done',
        onAction: handleDone,
        type: 'linkPrimary',
      }}
      title="Reminder"
    >
      <Box gapAll="medium">
        <DueDateLabel dueDate={selectedDate} />
        <RemindAtIndicator onRemove={handleClear} remindAt={selectedRemindAt} />
        <RevealContainer open={!showCustom}>
          <OffsetsList
            onOffsetChange={handleSetRemindAtAndClose}
            selectedOffset={selectedRemindAtOffset}
          />
        </RevealContainer>
        <CustomToggle isOpen={showCustom} onToggle={toggleShowCustom} />
        <RevealContainer open={showCustom}>
          <CustomOffset
            dueDate={selectedDate}
            onOffsetChange={onRemindAtChange}
            selectedOffset={selectedRemindAtOffset}
            selectedRemindAt={selectedRemindAt}
          />
        </RevealContainer>
        <ReminderPastWarning remindAt={selectedRemindAt} />
      </Box>
    </StandardModal>
  )
}

export default React.memo(TaskReminderModal)
