import { ReminderOffset } from '@/types/entities'
import { OffsetUnit } from './constants'

export const offsetToCustom = (
  offset: ReminderOffset | null,
): { unit: OffsetUnit; amount: number } => {
  if (!offset) {
    return { amount: 0, unit: 'minutes' }
  }

  if (offset.years) {
    return { amount: offset.years, unit: 'years' }
  }
  if (offset.months) {
    return { amount: offset.months, unit: 'months' }
  }
  if (offset.weeks) {
    return { amount: offset.weeks, unit: 'weeks' }
  }
  if (offset.days) {
    return { amount: offset.days, unit: 'days' }
  }
  if (offset.hours) {
    return { amount: offset.hours, unit: 'hours' }
  }

  return { amount: offset.minutes || 0, unit: 'minutes' }
}
