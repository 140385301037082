import React from 'react'
import { StyleSheet, View } from 'react-native'
import { getColor } from '@/core/constants/colors'
import { ContentContainerProps } from './types'

// Web version of content container. It doesn't need the keyboard avoiding view and safe area stuff
// so it just renders out the content with the given style
function ContentContainer({
  backgroundStyle,
  children,
  foregroundStyle = styles.foreground,
  context,
}: ContentContainerProps) {
  const foreground = (
    <View style={[foregroundStyle, context === 'SCREEN' ? styles.screen : styles.modal]}>
      {children}
    </View>
  )
  return backgroundStyle ? <View style={backgroundStyle}>{foreground}</View> : foreground
}

const styles = StyleSheet.create({
  foreground: {
    backgroundColor: getColor('ghost'),
    flex: 1,
  },
  modal: {},
  screen: {
    // scrolling wasn't working on mobile small scren anymore so I added this position bit
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

// ts-unused-exports:disable-next-line
export default React.memo(ContentContainer)
