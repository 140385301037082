import React from 'react'
import { NativeSyntheticEvent, TextInput, TextInputContentSizeChangeEventData } from 'react-native'
import { isWeb } from '@/core/utils/platform'

export const useTextContentSizeChange = (value: string | undefined) => {
  const heightRef = React.useRef<number | undefined>(undefined)
  const [height, setMinHeight] = React.useState<number | undefined>(undefined)

  const handleContentSizeChange = React.useCallback(
    (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
      const { height: contentHeight } = e.nativeEvent.contentSize
      setMinHeight(contentHeight)
      if (heightRef.current === undefined) {
        heightRef.current = contentHeight
      }
    },
    [setMinHeight],
  )

  React.useEffect(() => {
    if (!value) {
      setMinHeight(heightRef.current)
    }
  }, [value, setMinHeight])

  return {
    handleContentSizeChange,
    height,
  }
}

export const useFocusTextInput = (
  ref: React.RefObject<TextInput | HTMLInputElement | null> | null,
) =>
  React.useCallback(() => {
    if (!ref?.current || !isWeb) {
      return
    }

    /**
     * not entirely sure why but delay
     * was needed before grabbing focus
     * otherwise it wouldnt focus
     */
    setTimeout(() => {
      const input = ref.current as HTMLInputElement
      /**
       * Fix for safari not puting cursor at end
       */
      const textLength = input.value.length
      input.selectionStart = textLength
      input.selectionEnd = textLength

      input.focus()
    }, 500)
  }, [ref])
