import React, { Suspense } from 'react'
import ActivityIndicator from '@/components/core/ActivityIndicator'
import { SignUpScreenProps } from './types'

const LazyTeamsSignUpScreen = React.lazy(() => import('./TeamsSignUpScreen'))
const LazyPartnerSignUpScreen = React.lazy(() => import('./PartnerSignUpScreen'))

export function PartnerSignUpScreen(props: SignUpScreenProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyPartnerSignUpScreen {...props} />
    </Suspense>
  )
}

export function TeamsSignUpScreen(props: SignUpScreenProps) {
  return (
    <Suspense fallback={<ActivityIndicator color="obsidian" size="large" />}>
      <LazyTeamsSignUpScreen {...props} />
    </Suspense>
  )
}
