import React from 'react'
import BaseButton from '@/components/core/BaseButton'
import { Colors } from '@/types/components'
import { QuestionResponse } from '@/types/entities'

interface SelectQuestionResponseButtonProps {
  answered: boolean
  isTemporaryAnswered: boolean
  isSelected: boolean
  disabled: boolean
  onPress: (response: QuestionResponse) => void
  response: QuestionResponse
}

function SelectQuestionResponseButton({
  answered,
  isSelected,
  disabled,
  response,
  onPress,
  isTemporaryAnswered,
}: SelectQuestionResponseButtonProps) {
  const handlePress = React.useCallback(() => {
    if (!disabled) {
      onPress(response)
    }
  }, [onPress, response, disabled])
  const { foreground, background, border } = getButtonColors(
    isSelected,
    answered,
    disabled,
    isTemporaryAnswered,
  )

  return (
    <BaseButton
      backgroundColor={background}
      borderColor={border}
      disabled={disabled}
      foregroundColor={foreground}
      label={response.content}
      minWidth={60}
      onPress={handlePress}
      padding={2}
    />
  )
}

const getButtonColors = (
  isSelected: boolean,
  answered: boolean,
  disabled: boolean,
  isTemporaryAnswered: boolean,
): Colors => {
  if (isTemporaryAnswered) {
    return isSelected ? readyColors : disabledColors
  }

  if (answered) {
    return isSelected ? selectedColors : notSelectedColors
  }

  return disabled ? disabledColors : readyColors
}

const selectedColors: Colors = { background: 'emmre-purple', foreground: 'ghost' }
const notSelectedColors: Colors = { background: 'ghost', border: 'iron', foreground: 'iron' }
const disabledColors: Colors = { background: 'koala', border: 'shadow', foreground: 'shadow' }
const readyColors: Colors = {
  background: 'ghost',
  border: 'emmre-purple',
  foreground: 'emmre-purple',
}

export default React.memo(SelectQuestionResponseButton)
