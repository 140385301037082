import React from 'react'
import { TextInput } from 'react-native'
import Box from '@/components/layouts/Box'
import MessagesList from '@/components/messages/MessagesList'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { channelUtils } from '@/core/utils'
import TaskChatInput from './TaskChatInput'
import TaskCommentsToggle from './TaskCommentsToggle'
import { useTaskChat } from './hooks'

interface TaskFormChatProps {
  isExpanded: boolean
  onFocus: () => void
  onToggleIsExpanded: () => void
  autoFocus?: boolean
}

const showThreadStart = false

function TaskFormChat({
  isExpanded,
  onFocus,
  onToggleIsExpanded,
  autoFocus = false,
}: TaskFormChatProps) {
  const { task, formName: taskFormName, interactionMode } = useTaskFormContext()
  const formName = interactionMode === 'create' ? taskFormName : `${taskFormName}/chat`
  const { canSubmit, canComment, channel, channelUser, handleSubmit, messagesCount } = useTaskChat(
    task,
    formName,
    showThreadStart,
  )

  const placeholder =
    !channel || channelUtils.isPersonalDM(channel) ? 'Comment on this task' : 'Chat about this task'
  const inputRef = React.useRef<TextInput | null>(null)

  React.useEffect(() => {
    if (!isExpanded) {
      inputRef.current?.blur()
    }
  }, [isExpanded])

  let disabled: boolean
  let disabledReason: string | undefined

  if (!canComment) {
    disabled = true
    disabledReason = 'You are not allowed to comment on this task'
  } else if (!task) {
    disabled = true
    if (canSubmit) {
      disabledReason = 'Comment will be added upon creation'
    }
  } else if (!channel || !channelUser) {
    disabled = true
  } else {
    disabled = !canSubmit
  }

  const showChat = task && channel && channelUser

  return (
    <>
      {showChat ? (
        <Box direction="column" flex={isExpanded ? 1 : undefined}>
          <TaskCommentsToggle
            isOpen={isExpanded}
            messagesCount={messagesCount}
            onToggle={onToggleIsExpanded}
          />
          <Box flex={isExpanded ? 1 : undefined} height={isExpanded ? undefined : 0}>
            <MessagesList
              channel={channel}
              channelUser={channelUser}
              showOtherAvatar
              showThreadStart={showThreadStart}
              threadId={task.id}
            />
          </Box>
        </Box>
      ) : null}
      <TaskChatInput
        ref={inputRef}
        autoFocus={autoFocus}
        channel={channel}
        disabled={disabled}
        disabledReason={disabledReason}
        formName={formName}
        handleSubmit={handleSubmit}
        onFocus={onFocus}
        placeholder={placeholder}
      />
    </>
  )
}

export default React.memo(TaskFormChat)
