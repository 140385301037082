import React from 'react'
import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { useAppDispatch, useCachedSelector, useCurrentUserId } from '@/hooks'
import { useIsHubOnlyUser } from '@/hooks/channels'
import { formActions } from '@/store/actions'
import { channelSelectors, entitySelectors } from '@/store/selectors'
import { entityThunks } from '@/thunks'
import { fieldNames } from '../constants'
import { useTaskFormValue, useTaskFormValues } from './formFields'

export const useTaskChannels = () => {
  const spaceId: number = useTaskFormValue(fieldNames.spaceId)
  const isHubOnlyUser = useIsHubOnlyUser(spaceId)

  // If a user is a hub-only user only show hubs to select from
  const config = React.useMemo<entitySelectors.ChannelFilterConfig>(
    () => ({
      channelTypes: isHubOnlyUser ? 'HUB' : undefined,
      spaceId,
      status: 'ACTIVE',
    }),
    [spaceId, isHubOnlyUser],
  )
  const channels = useCachedSelector(entitySelectors.resolvedChannelsSelector, [config])
  return channels
}

export const useTaskChannelsCount = () => useTaskChannels().length

export const useSetTaskChannel = () => {
  const dispatch = useAppDispatch()
  const { formName } = useTaskFormContext()
  const {
    spaceId: currentSpaceId,
    channelId: currentChannelId,
    assignedUserId: currentAssignedUserId,
  } = useTaskFormValues()
  const currentUserId = useCurrentUserId()

  // When the space or channel changes we have to update some other fields
  const getNextFields = React.useCallback(
    (spaceId: number, channelId: number | null): Record<string, any> => {
      const channel = channelId
        ? dispatch(entityThunks.select(channelSelectors.byId, spaceId, channelId))
        : undefined

      if (spaceId !== currentSpaceId) {
        // If the space is being changed we have to clear out all the space-specific stuff
        return {
          assignedUserId: channelId === null ? currentUserId : null,
          boardId: null,
          channelId,
          spaceId,
          tagIds: [],
        }
      }

      if (channelId !== currentChannelId) {
        const common = { boardId: null }
        // If the channel changes we may need to change the assigned user
        if (channelId === null) {
          return { assignedUserId: currentUserId, channelId, ...common }
        }
        if (currentAssignedUserId === currentUserId) {
          return { channelId, ...common }
        }
        if (!channel?.userIds?.includes(currentAssignedUserId)) {
          return { assignedUserId: currentUserId, channelId, ...common }
        }
        return { channelId, ...common }
      }

      return {}
    },
    [dispatch, currentSpaceId, currentChannelId, currentAssignedUserId, currentUserId],
  )

  const setTaskChannel = React.useCallback(
    (spaceId: number, channelId: number | null) =>
      dispatch(
        formActions.setValues({
          fields: getNextFields(spaceId, channelId),
          formName,
          markChanged: true,
        }),
      ),
    [dispatch, getNextFields, formName],
  )

  return {
    setTaskChannel,
  }
}
