import { RRule } from 'rrule'

type DefaultRRule = {
  rrule: RRule | null
  key: string | null
}

const defaultRRule = (rrule: RRule): DefaultRRule => {
  const key = rrule.toString()

  return {
    key,
    rrule,
  }
}

export const defaultRRules: DefaultRRule[] = [
  { key: null, rrule: null },
  defaultRRule(new RRule({ freq: RRule.DAILY })),
  defaultRRule(
    new RRule({
      byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
      freq: RRule.WEEKLY,
    }),
  ),
  defaultRRule(new RRule({ freq: RRule.WEEKLY })),
  defaultRRule(new RRule({ freq: RRule.WEEKLY, interval: 2 })),
  defaultRRule(new RRule({ freq: RRule.MONTHLY })),
  defaultRRule(new RRule({ freq: RRule.MONTHLY, interval: 3 })),
  defaultRRule(new RRule({ freq: RRule.MONTHLY, interval: 6 })),
  defaultRRule(new RRule({ freq: RRule.YEARLY })),
]
