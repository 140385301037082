import React from 'react'
import Background from '@/components/core/Background'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { Setter } from '@/types/generics'
import { QuestionMode } from '@/types/tasks'

interface QuestionModeButtonProps {
  mode: QuestionMode
  isSelected: boolean
  onSelect: Setter<QuestionMode>
}

function QuestionModeButton({ mode, isSelected, onSelect }: QuestionModeButtonProps) {
  const handleSelect = React.useCallback(() => {
    if (isSelected) {
      return
    }
    onSelect(mode)
  }, [mode, isSelected, onSelect])

  return (
    <Touchable feedback="opacity" onPress={handleSelect}>
      <Background borderRadius={10} color={isSelected ? 'anchor' : 'heather'}>
        <Box gapHorizontal="medium" gapVertical="small">
          <Text align="center" color={isSelected ? 'ghost' : 'obsidian'} size="h5">
            {getLabel(mode)}
          </Text>
        </Box>
      </Background>
    </Touchable>
  )
}

const getLabel = (mode: QuestionMode): string => {
  if (mode === 'YES_NO') {
    return 'Yes /\nNo'
  }
  if (mode === 'MULTIPLE_CHOICE') {
    return 'Multiple\nChoice'
  }
  if (mode === 'OPEN_ENDED') {
    return 'Open\nEnded'
  }
  throw new Error(`Invalid question type: ${mode}`)
}

export default React.memo(QuestionModeButton)
