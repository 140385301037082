import { ReminderOffset } from '@/types/entities'
import { add, setTime } from './dates'
import { pluralize, toList } from './strings'

export const noOffset: ReminderOffset = {
  days: 0,
  hours: 0,
  minutes: 0,
  months: 0,
  weeks: 0,
  years: 0,
}

export const build = (offset: Partial<ReminderOffset>): ReminderOffset => ({
  ...noOffset,
  ...offset,
})

export const isZero = (offset: ReminderOffset): boolean => equals(noOffset, offset)

const isNil = (v: any) => v === undefined || v === null

export const hasTime = (offset: ReminderOffset): boolean =>
  !(isNil(offset.atHour) || isNil(offset.atMinute))

export const allowTime = (offset: ReminderOffset): boolean =>
  !!(
    !offset.minutes &&
    !offset.hours &&
    (offset.days || offset.weeks || offset.months || offset.years)
  )

export const mergeOffsets = (left: ReminderOffset, right: ReminderOffset): ReminderOffset => {
  if (hasTime(right)) {
    return right
  }

  if (hasTime(left) && allowTime(right)) {
    return {
      ...right,
      atHour: left.atHour,
      atMinute: left.atMinute,
    }
  }

  return right
}

export const withoutTime = (offset: ReminderOffset): ReminderOffset => ({
  ...offset,
  atHour: undefined,
  atMinute: undefined,
})

export const applyOffset = (offset: ReminderOffset, value: Date): Date => {
  let applied = value

  if (offset.minutes) {
    applied = add(applied, offset.minutes, 'minutes')
  }

  if (offset.hours) {
    applied = add(applied, offset.hours, 'hours')
  }

  if (offset.days) {
    applied = add(applied, offset.days, 'days')
  }

  if (offset.weeks) {
    applied = add(applied, offset.weeks, 'weeks')
  }

  if (offset.months) {
    applied = add(applied, offset.months, 'months')
  }

  if (offset.years) {
    applied = add(applied, offset.years, 'years')
  }

  if (hasTime(offset)) {
    applied = setTime(applied, offset.atHour as number, offset.atMinute as number)
  }

  return applied
}

export const toString = (offset: ReminderOffset): string | null => {
  const output: string[] = []

  if (offset.years) {
    output.push(pluralize(Math.abs(offset.years), 'year'))
  }

  if (offset.months) {
    output.push(pluralize(Math.abs(offset.months), 'month'))
  }

  if (offset.weeks) {
    output.push(pluralize(Math.abs(offset.weeks), 'week'))
  }

  if (offset.days) {
    output.push(pluralize(Math.abs(offset.days), 'day'))
  }

  if (offset.hours) {
    output.push(pluralize(Math.abs(offset.hours), 'hour'))
  }

  if (offset.minutes) {
    output.push(pluralize(Math.abs(offset.minutes), 'minute'))
  }

  if (output.length === 0) {
    return null
  }

  return toList(output)
}

export const equals = (left: ReminderOffset, right: ReminderOffset): boolean =>
  (left.minutes || 0) === (right.minutes || 0) &&
  (left.hours || 0) === (right.hours || 0) &&
  (left.days || 0) === (right.days || 0) &&
  (left.weeks || 0) === (right.weeks || 0) &&
  (left.months || 0) === (right.months || 0) &&
  (left.years || 0) === (right.years || 0)
