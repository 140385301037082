import React from 'react'
import { messageUtils, taskUtils } from '@/core/utils'
import { useIsLastTaskActivityInThread } from '@/hooks/threads'
import { MessageListItemData } from '@/types/messaging'
import MessageContent from './MessageContent'
import QuestionContent from './QuestionContent'
import SummaryActivityContent from './SummaryActivityContent'
import TaskActivityContent from './TaskActivityContent'
import TaskContent from './TaskContent'

interface MessageListItemBodyProps {
  isFromMe: boolean
  item: MessageListItemData
  isDisplayedInThread: boolean
  disabled?: boolean
}

function MessageListItemBody({
  isFromMe,
  item,
  isDisplayedInThread,
  disabled = false,
}: MessageListItemBodyProps) {
  const { message, task, comments, thread } = item
  const isTaskActivity = messageUtils.isTaskActivity(message)
  const isSummaryActivity = messageUtils.isSummaryActivity(message)
  const isTask = !!task && !taskUtils.isQuestion(task)
  const isQuestion = !!task && taskUtils.isQuestion(task)
  const isLastMessageInThread = useIsLastTaskActivityInThread(item.message)

  // When the activity is the last thread in the message, show the full task
  // If the thread is ignored and this is being rendered then it is the task
  // at the thread head and it should display full (since it is the only one)
  const showFull = isLastMessageInThread || !!thread?.isIgnored
  const simplified = !showFull

  if (isTaskActivity) {
    if (isDisplayedInThread) {
      return (
        <MessageContent
          disabled={disabled}
          isDisplayedInThread={isDisplayedInThread}
          isFromMe={isFromMe}
          item={item}
        />
      )
    }
    return (
      <TaskActivityContent
        disabled={disabled}
        isDisplayedInThread={isDisplayedInThread}
        isFromMe={isFromMe}
        item={item}
        simplified={simplified}
      />
    )
  }

  if (isTask) {
    return (
      <TaskContent
        comments={comments}
        disabled={disabled}
        isFromMe={isFromMe}
        item={item}
        simplified={simplified}
        task={task}
      />
    )
  }

  if (isQuestion) {
    return (
      <QuestionContent
        comments={comments}
        disabled={disabled}
        isFromMe={isFromMe}
        item={item}
        task={task}
      />
    )
  }

  if (isSummaryActivity) {
    if (isDisplayedInThread || message.summaryActivityType === 'COMMENT') {
      return (
        <MessageContent
          disabled={disabled}
          isDisplayedInThread={isDisplayedInThread}
          isFromMe={isFromMe}
          item={item}
        />
      )
    }
    return <SummaryActivityContent disabled={disabled} isFromMe={isFromMe} item={item} />
  }

  return (
    <MessageContent
      disabled={disabled}
      isDisplayedInThread={isDisplayedInThread}
      isFromMe={isFromMe}
      item={item}
    />
  )
}

export default React.memo(MessageListItemBody)
