import { jobQueue } from '@/core/jobQueue'
import { appProfileSelectors, spaceSelectors } from '@/store/selectors'
import { Space } from '@/types/entities'
import { QueuedJob } from '@/types/jobScheduling'
import { Dispatch, SyncThunk, Thunk } from '@/types/store'
import { catchUpAll, catchUpRecent } from './catchUp'
import { getAllSpacesList, getDefaultSpace } from './spaces'
import { getUpdatedMyDayTaskList } from './taskLists'
import { getAllUpdatedTasks } from './tasks'

export const initializeStore = (): Thunk<Space | null> => async dispatch => {
  const defaultSpace = await dispatch(getDefaultSpace())

  if (!defaultSpace) {
    return null
  }

  dispatch(queueInitializationJobs(defaultSpace.id))

  return defaultSpace
}

export const initializeSpace =
  (spaceId: number): SyncThunk<void> =>
  dispatch => {
    const jobs = getSpaceJobs(dispatch, { isCurrentSpace: true, isInitialLoad: true, spaceId })
    jobQueue.queueJobs(jobs)
  }

export const queueRefreshJobs =
  (excludeDeferrable: boolean): Thunk<void> =>
  async (dispatch, getState) => {
    const focusedSpaceId = appProfileSelectors.focusedSpaceId(getState())

    const jobs = focusedSpaceId ? await dispatch(getJobs(focusedSpaceId, false)) : []

    if (excludeDeferrable) {
      jobQueue.queueJobs(jobs.filter(job => !job.isDeferrable))
    } else {
      jobQueue.queueJobs(jobs)
    }
  }

const queueInitializationJobs =
  (currentSpaceId: number): Thunk<void> =>
  async dispatch => {
    const jobs = await dispatch(getJobs(currentSpaceId, true))
    jobQueue.queueJobs(jobs)
  }

const getJobs =
  (currentSpaceId: number, isInitialLoad: boolean): SyncThunk<QueuedJob[]> =>
  (dispatch, getState) => {
    const jobs = getSpaceJobs(dispatch, {
      isCurrentSpace: true,
      isInitialLoad,
      spaceId: currentSpaceId,
    })
    jobs.push(...getAppJobs(dispatch, { isInitialLoad }))

    const spaces = spaceSelectors.all(getState())

    spaces.forEach(space => {
      if (space.id === currentSpaceId) {
        return
      }

      jobs.push(
        ...getSpaceJobs(dispatch, { isCurrentSpace: false, isInitialLoad, spaceId: space.id }),
      )
    })

    return jobs
  }

type SpaceDataJobArgs = {
  spaceId: number
  isCurrentSpace: boolean
  isInitialLoad: boolean
}

const getSpaceJobs = (
  dispatch: Dispatch,
  { spaceId, isCurrentSpace, isInitialLoad }: SpaceDataJobArgs,
): QueuedJob[] => {
  const primaryCatchUp = () =>
    dispatch(catchUpRecent(spaceId, ['channels', 'channel_stats', 'channels_users', 'users']))
  const getTasks = () => dispatch(getAllUpdatedTasks(spaceId))
  const secondaryCatchUp = () =>
    dispatch(catchUpAll(spaceId, ['summaries', 'boards', 'tags'], null))

  // On initial load we get all tasks which gets all active tags
  if (isInitialLoad) {
    if (isCurrentSpace) {
      return [
        { isDeferrable: false, priority: 'IMMEDIATE', task: primaryCatchUp },
        { isDeferrable: false, priority: 'IMMEDIATE', task: getTasks },
      ]
    }
    return [
      { isDeferrable: false, priority: 'HIGH', task: primaryCatchUp },
      { isDeferrable: false, priority: 'HIGH', task: getTasks },
    ]
  }

  if (isCurrentSpace) {
    return [
      { isDeferrable: false, priority: 'IMMEDIATE', task: primaryCatchUp },
      { isDeferrable: false, priority: 'HIGH', task: getTasks },
      { isDeferrable: true, priority: 'LOW', task: secondaryCatchUp },
    ]
  }

  return [
    { isDeferrable: false, priority: 'HIGH', task: primaryCatchUp },
    { isDeferrable: false, priority: 'MEDIUM', task: getTasks },
    { isDeferrable: true, priority: 'LOW', task: secondaryCatchUp },
  ]
}

type AppDataJobArgs = {
  isInitialLoad: boolean
}

const getAppJobs = (dispatch: Dispatch, { isInitialLoad }: AppDataJobArgs): QueuedJob[] => {
  const getMyDay = () => dispatch(getUpdatedMyDayTaskList())

  // On the initial load spaces handled already
  if (isInitialLoad) {
    return [{ isDeferrable: false, priority: 'IMMEDIATE', task: getMyDay }]
  }

  return [
    {
      isDeferrable: false,
      priority: 'HIGH',
      task: getMyDay,
    },
    {
      isDeferrable: true,
      priority: 'LOW',
      task: () => dispatch(getAllSpacesList()),
    },
  ]
}
