import * as Haptics from 'expo-haptics'
import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Background from '@/components/core/Background'
import Box from '@/components/layouts/Box'
import ModalBackdrop from '@/components/modals/ModalBackdrop'
import { useAppLayoutContext } from '@/contexts/AppLayoutContext'
import TaskFormContext, { TaskFormContextType } from '@/contexts/TaskFormContext'
import { sizes } from '@/core/constants'
import { platformUtils, taskUtils } from '@/core/utils'
import { useIsKeyboardVisible } from '@/hooks/keyboard'
import { TaskFormArgs, TaskType } from '@/types/tasks'
import { TaskModalContainer } from '../Modals'
import * as hooks from '../hooks'
import { TaskFormMode } from '../types'
import QuestionFormContent from './QuestionFormContent'
import TaskFormContent from './TaskFormContent'

interface TaskFormProps extends TaskFormArgs {
  onClose: () => void
  mode?: TaskFormMode
}

const borderRadius = 16
const giveHapticFeedback = !platformUtils.isWeb

function TaskForm({
  checkNotifyOnCreate = true,
  createTaskType,
  defaultSpaceId,
  fastCreate = false,
  formContext,
  initialQuestionMode = 'YES_NO',
  initialQuestionResponse,
  initialSize = 'LARGE',
  initialTaskFormBody = 'TASK_DETAILS',
  mode = 'MODAL',
  onClose,
  onSuccess,
  shouldInitializeForm = true,
  taskDefaults,
  taskId,
}: TaskFormProps) {
  // Only full screen modal can change size, and only when the initial size requsted is not large
  const defaultSize = mode !== 'SCREEN' ? initialSize : 'LARGE'
  const canChangeSize = defaultSize !== 'LARGE'
  const appLayout = useAppLayoutContext()

  const {
    interactionMode,
    task,
    formInitialized,
    formName,
    resetForm,
    questionMode,
    setQuestionMode,
  } = hooks.useTaskForm({
    formContext,
    initialQuestionMode,
    shouldInitializeForm,
    spaceId: defaultSpaceId,
    taskDefaults,
    taskId,
  })

  const taskType: TaskType = (task && taskUtils.getTaskType(task)) || createTaskType || 'TASK'
  const { taskFormSize, setTaskFormSize } = hooks.useTaskFormSize(initialSize, canChangeSize)

  const handleFastCreate = React.useCallback(() => {
    if (giveHapticFeedback) {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
    }
    resetForm()
    if (taskFormSize === 'LARGE') {
      setTaskFormSize?.('MEDIUM')
    }
  }, [resetForm, setTaskFormSize, taskFormSize])

  const { handleCancel, handleClose, handleSubmit, handleSubmitDisabled, canSubmit, hasChanges } =
    hooks.useTaskFormActions({
      checkNotifyOnCreate,
      formName,
      interactionMode,
      onClose,
      onFastCreate: fastCreate ? handleFastCreate : undefined,
      onSuccess,
      taskId,
      taskType,
    })

  const { taskDetail, showTaskDetail, closeTaskDetail } = hooks.useTaskModals()
  const isKeyboardVisible = useIsKeyboardVisible()

  const taskFormContextValue = React.useMemo<TaskFormContextType>(
    () => ({
      // When we open the modal from task creation in messages we don't initialize the form, and
      // we never want to clear it out when we close (cancel). Also, if there have been no
      // changes we want to be able to just close the modal, now show the cancel text.
      canClose: (interactionMode !== 'create' && !hasChanges) || !shouldInitializeForm,
      canSubmit,
      cancel: handleCancel,
      close: handleClose,
      formName,
      interactionMode,
      questionMode,
      setQuestionMode,
      setTaskFormSize,
      showDetail: showTaskDetail,
      submit: handleSubmit,
      submitDisabled: handleSubmitDisabled,
      task,
      taskFormSize,
      taskType,
    }),
    [
      questionMode,
      setQuestionMode,
      canSubmit,
      taskType,
      formName,
      handleCancel,
      handleClose,
      handleSubmit,
      handleSubmitDisabled,
      hasChanges,
      interactionMode,
      setTaskFormSize,
      shouldInitializeForm,
      showTaskDetail,
      task,
      taskFormSize,
    ],
  )

  const insets = useSafeAreaInsets()

  if (!formInitialized) {
    return null
  }

  const bottomBorderRadius = mode === 'MODAL' && appLayout === 'WIDE' ? 16 : undefined
  const insetBottom =
    (isKeyboardVisible || mode === 'SCREEN' ? 0 : insets.bottom) +
    (taskFormSize !== 'LARGE' ? 10 : 0)

  return (
    <TaskFormContext.Provider value={taskFormContextValue}>
      <ModalBackdrop
        justifyContent={taskFormSize === 'LARGE' ? 'center' : 'flex-end'}
        onPress={handleCancel}
      >
        <Box
          direction="column"
          flex={taskFormSize === 'LARGE' ? 1 : undefined}
          gapType="margin"
          gapVertical={appLayout === 'WIDE' ? 50 : 0}
          maxWidth="100%"
          width={sizes.maxModalWidth}
        >
          <Background
            borderBottomLeftRadius={bottomBorderRadius}
            borderBottomRightRadius={bottomBorderRadius}
            borderTopLeftRadius={borderRadius}
            borderTopRightRadius={borderRadius}
            color="ghost"
            flex={taskFormSize === 'LARGE' ? 1 : undefined}
          >
            {taskType === 'TASK' ? (
              <TaskFormContent
                initialTaskFormBody={initialTaskFormBody}
                interactionMode={interactionMode}
                mode={mode}
                taskFormSize={taskFormSize}
              />
            ) : (
              <QuestionFormContent
                initialQuestionResponse={initialQuestionResponse}
                initialTaskFormBody={initialTaskFormBody}
              />
            )}
            <Box height={insetBottom} />
          </Background>
          <TaskModalContainer onClose={closeTaskDetail} taskDetail={taskDetail} />
        </Box>
      </ModalBackdrop>
    </TaskFormContext.Provider>
  )
}

export default React.memo(TaskForm)
