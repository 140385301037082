import { Request } from '@/api/call'
import { ChargebeeEstimate, ChargebeePaymentIntent } from '@/types/chargebee'
import { PlanType, ProductVersion, Space, TimezoneInfo } from '@/types/entities'

export interface TimezonesResponse {
  timezones: TimezoneInfo[]
}

export interface CreateSignUpPaymentIntentResponse {
  paymentIntent: ChargebeePaymentIntent
  estimate: ChargebeeEstimate
}

export interface GetChargebeeEstimatesResponse {
  estimates: {
    planType: PlanType
    estimate: ChargebeeEstimate | null
  }[]
}

export interface GetChargebeeEstimateResponse {
  estimate: ChargebeeEstimate
}

export interface CreatePaymentIntentResponse {
  paymentIntent: ChargebeePaymentIntent
  amount: number
}

export interface CreateSignUpPaymentIntentBody {
  partnerInvited: boolean
  signUpCode?: string | null
}

export interface CreateChargebeePaymentSourceResponse {
  space: Space
}

export interface SelectProductVersionBody {
  paymentIntentId: string | null
  productVersion: ProductVersion
}

export interface SelectProductVersionResponse {
  space: Space
}

export const getTimezonesList = () =>
  new Request<TimezonesResponse>({
    authentication: 'PUBLIC',
    method: 'GET',
    url: 'v1/internal/timezones',
  })

export const createSignUpChargebeePaymentIntent = (body: CreateSignUpPaymentIntentBody) =>
  new Request<CreateSignUpPaymentIntentResponse>({
    authentication: 'PUBLIC',
    body,
    method: 'POST',
    url: 'v1/internal/chargebee-payment-intents',
  })

export const getChargebeeEstimates = (spaceId: number) =>
  new Request<GetChargebeeEstimatesResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    url: `v1/internal/spaces/${spaceId}/estimates`,
  })

export const getChargebeeEstimate = (spaceId: number) =>
  new Request<GetChargebeeEstimateResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    url: `v1/internal/spaces/${spaceId}/estimate`,
  })

export const createChargebeePaymentIntent = (spaceId: number) =>
  new Request<CreatePaymentIntentResponse>({
    authentication: 'PRIVATE',
    method: 'POST',
    url: `v1/internal/spaces/${spaceId}/chargebee-payment-intents`,
  })

export const createChargebeePaymentSource = (spaceId: number, paymentIntentId: string) =>
  new Request<CreateChargebeePaymentSourceResponse>({
    authentication: 'PRIVATE',
    body: { paymentIntentId },
    method: 'POST',
    url: `v1/internal/spaces/${spaceId}/chargebee-payment-sources`,
  })

export const selectProductVersion = (spaceId: number, body: SelectProductVersionBody) =>
  new Request<SelectProductVersionResponse>({
    authentication: 'PRIVATE',
    body,
    method: 'PUT',
    url: `v1/internal/spaces/${spaceId}/product-version`,
  })
