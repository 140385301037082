import React from 'react'
import Background from '@/components/core/Background'
import Button from '@/components/core/Button'
import TextInput from '@/components/core/TextInput'
import Box from '@/components/layouts/Box'
import EmojiPickerButton from '@/components/messages/EmojiPickerButton'
import { useAppDispatch } from '@/hooks'
import { useTextInputAutoFocus } from '@/hooks/components'
import { useFocusTextInput } from '@/hooks/textInput'
import { summaryThunks } from '@/thunks'
import { Emoji } from '@/types/emoji'
import { SummaryItem } from '@/types/entities'

interface CreateSummaryItemCommentProps {
  summaryItem: SummaryItem
  onDone: () => void
}

function CreateSummaryItemComment({ summaryItem, onDone }: CreateSummaryItemCommentProps) {
  const { spaceId, id: summaryItemId } = summaryItem
  const dispatch = useAppDispatch()
  const [comment, setComment] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const handleCancel = React.useCallback(() => {
    setComment('')
    onDone()
  }, [setComment, onDone])
  const handleCreate = React.useCallback(async () => {
    if (!comment) {
      return
    }
    setIsSubmitting(true)
    const response = await dispatch(
      summaryThunks.createSummaryItemComment(spaceId, summaryItemId, comment),
    )
    setIsSubmitting(false)

    if (response.ok) {
      setComment('')
      onDone()
    }
  }, [comment, setComment, dispatch, onDone, spaceId, summaryItemId])

  const textInputRef = useTextInputAutoFocus()
  const refocusInput = useFocusTextInput(textInputRef)
  const handlePickEmoji = React.useCallback(
    ({ symbol }: Emoji) => {
      setComment(value => value + symbol)
      refocusInput()
    },
    [refocusInput],
  )

  const canCreate = !!comment
  const disabled = !canCreate || isSubmitting

  return (
    <>
      <Background borderRadius={8} color="ghost">
        <TextInput
          ref={textInputRef}
          blurOnSubmit
          expandable
          multiline
          onChangeText={setComment}
          onSubmitEditing={handleCreate}
          value={comment}
        />
      </Background>
      <Box alignItems="center" direction="row" gapTop="medium" justifyContent="space-between">
        <Button kind="transparentDark" label="Cancel" onPress={handleCancel} />
        <Box alignItems="center" direction="row">
          <Box gapHorizontal="small">
            <EmojiPickerButton onPick={handlePickEmoji} size="h2" />
          </Box>
          <Button disabled={disabled} kind="primary" label="Send" onPress={handleCreate} />
        </Box>
      </Box>
    </>
  )
}

export default React.memo(CreateSummaryItemComment)
