import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { dateUtils as D, collectionUtils, uuidUtils } from '@/core/utils'
import { Notification } from '@/types/entities'
import { RootState } from '../index'

interface NotificationsState {
  notifications: Notification[]
  ignoreWebNotifications: boolean
}

export interface CreateNotification extends Omit<Notification, 'id' | 'duration' | 'createdAt'> {
  duration?: number
}

const initialState: NotificationsState = {
  ignoreWebNotifications: false,
  notifications: [],
}
const defaultDuration = D.toMilliseconds.seconds(5)

const notificationsSlice = createSlice({
  initialState,
  name: 'notifications',
  /* eslint-disable no-param-reassign */
  reducers: {
    clear: state => {
      state.notifications = []
    },

    pop: state => {
      state.notifications = collectionUtils.init(state.notifications)
    },
    push: (state, action: PayloadAction<CreateNotification>) => {
      state.notifications = [
        ...state.notifications,
        {
          createdAt: D.nowISO(),
          id: uuidUtils.getRandomUUID(),
          ...action.payload,
          duration: action.payload.duration || defaultDuration,
        },
      ]
    },
    remove: (state, action: PayloadAction<string>) => {
      const { payload: notificationId } = action
      state.notifications = state.notifications.filter(
        notification => notification.id !== notificationId,
      )
    },
    setIgnoreWebNotifications: state => {
      state.ignoreWebNotifications = true
    },
    unshift: state => {
      state.notifications = collectionUtils.tail(state.notifications)
    },
  },
  /* eslint-enable */
})

const { actions, reducer } = notificationsSlice

const selectNotifications = (state: RootState) => state.notifications.notifications
const selectFirstNotification = (state: RootState) =>
  collectionUtils.headOrDefault(state.notifications.notifications, undefined)
const selectLastNotification = (state: RootState) =>
  collectionUtils.lastOrDefault(state.notifications.notifications, undefined)
const selectIgnoreWebNotifications = (state: RootState) =>
  state.notifications.ignoreWebNotifications

const selectors = {
  all: selectNotifications,
  first: selectFirstNotification,
  ignoreWebNotifications: selectIgnoreWebNotifications,
  last: selectLastNotification,
}

export { actions, selectors }
export default reducer
