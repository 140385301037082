import React from 'react'
import { useAlertContext } from '@/contexts/AlertContext'
import * as actionAlert from '@/core/utils/actions/actionAlert'
import { ActionArgs, ActionOption, ActionOptionAsync } from '@/types/actions'

export const useAlertActions = <S>(options: ActionOption<S>[], args: ActionArgs) => {
  const showAlert = useAlertContext()
  return React.useCallback(
    (subject: S) => actionAlert.showActionAlert(showAlert, options, args, subject),
    [showAlert, options, args],
  )
}

export const useAlertActionsAsync = <V, S>(
  options: ActionOptionAsync<V, S>[],
  args: ActionArgs,
) => {
  const showAlert = useAlertContext()
  return React.useCallback(
    (subject: S) => actionAlert.showActionAlertAsync(showAlert, options, args, subject),
    [showAlert, options, args],
  )
}

export const useDestructiveAlert = <S>(
  title: string,
  label: string,
  handler: (subject: S) => void,
  message?: string,
) => {
  const showAlert = useAlertContext()
  return React.useCallback(
    (subject: S) =>
      actionAlert.showDestructiveAlert(showAlert, title, label, () => handler(subject), message),
    [showAlert, title, label, handler, message],
  )
}

export const useDestructiveAlertAsync = (title: string, label: string, message?: string) => {
  const showAlert = useAlertContext()
  return React.useCallback(
    () => actionAlert.showDestructiveAlertAsync(showAlert, title, label, message),
    [showAlert, title, label, message],
  )
}
