import React from 'react'
import * as rrule from 'rrule'
import TaskRecurrenceModal from '@/components/tasks/TaskForm/Modals/TaskRecurrenceModal'
import { useToggle } from '@/hooks'
import DateOptionItem from './DateOptionItem'

interface RecurrenceOptionItemProps {
  onRecurrenceRuleChange: (newRecurrenceRule: string | null) => void
  recurrenceRule: string | null
  selectedDate: Date | null
  localModal: boolean
}

function RecurrenceOptionItem({
  selectedDate,
  recurrenceRule,
  onRecurrenceRuleChange,
  localModal,
}: RecurrenceOptionItemProps) {
  const [open, toggleOpen] = useToggle(false)

  return (
    <>
      <DateOptionItem
        disabled={!selectedDate}
        icon="repeat"
        onPress={toggleOpen}
        title="Recurs"
        value={recurrenceRule ? rrule.rrulestr(recurrenceRule).toText() : 'Does Not Recur'}
      />
      <TaskRecurrenceModal
        isOpen={open}
        localModal={localModal}
        onChange={onRecurrenceRuleChange}
        onClose={toggleOpen}
        recurrenceRule={recurrenceRule}
      />
    </>
  )
}

export default React.memo(RecurrenceOptionItem)
