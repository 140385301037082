import React from 'react'
import { StyleSheet, View } from 'react-native'
import Background from '@/components/core/Background'
import Hoverable from '@/components/core/Hoverable'
import Icon from '@/components/core/Icon'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { getColor } from '@/core/constants/colors'
import { useEditSummaryContext } from '../EditSummaryContext'
import SummaryItemButton from './SummaryItemButton'

interface AddSummaryItemButtonProps {
  beforeItemId?: number | null
  index: number
  showAddHeader?: boolean
  showAddItem?: boolean
}

function AddSummaryItemButton({
  beforeItemId = null,
  index,
  showAddHeader = false,
  showAddItem = false,
}: AddSummaryItemButtonProps) {
  const { addItem } = useEditSummaryContext()
  const handlePrompt = React.useCallback(
    () => addItem({ beforeItemId, index, itemType: 'PROMPT' }),
    [addItem, beforeItemId, index],
  )
  const handleAddHeader = React.useCallback(
    () => addItem({ beforeItemId, index, itemType: 'HEADER' }),
    [addItem, beforeItemId, index],
  )
  const handleAddMessage = React.useCallback(
    () => addItem({ beforeItemId, index, itemType: 'MESSAGE' }),
    [addItem, beforeItemId, index],
  )
  const handleAddTask = React.useCallback(
    () => addItem({ beforeItemId, index, itemType: 'TASK' }),
    [addItem, beforeItemId, index],
  )
  const handleAddQuestion = React.useCallback(
    () => addItem({ beforeItemId, index, itemType: 'QUESTION' }),
    [addItem, beforeItemId, index],
  )

  const detailIcons = React.useMemo(
    () => (
      <View style={styles.detailIconsStyle}>
        <SummaryItemButton description="Add An Item" iconName="plus" onPress={handlePrompt} />
        {showAddHeader ? (
          <SummaryItemButton
            description="Add Section Header"
            iconName="h1"
            onPress={handleAddHeader}
          />
        ) : null}
        {showAddItem ? (
          <>
            <SummaryItemButton
              description="Add Message"
              iconName="align-left"
              onPress={handleAddMessage}
            />
            <SummaryItemButton
              description="Add Task"
              iconName="square-check"
              onPress={handleAddTask}
            />
            <SummaryItemButton
              description="Add Question"
              iconName="message-question"
              onPress={handleAddQuestion}
            />
          </>
        ) : null}
      </View>
    ),
    [
      handleAddHeader,
      handleAddMessage,
      handleAddQuestion,
      handleAddTask,
      handlePrompt,
      showAddHeader,
      showAddItem,
    ],
  )

  if (!(showAddItem || showAddHeader)) {
    return null
  }

  return (
    <Touchable feedback="opacity" onPress={handlePrompt}>
      <Hoverable>
        {isHovered => (
          <Box gapVertical="large">
            <View style={styles.container}>
              <View style={styles.lineStyle} />
              <View style={styles.float}>
                <Box gapHorizontal="medium">
                  <Background
                    borderRadius={12}
                    color={isHovered ? 'emmre-blue' : 'transparent'}
                    height={24}
                    width={24}
                  >
                    <View style={styles.iconContainer}>
                      <Icon color={isHovered ? 'white' : 'emmre-blue-4'} kind="light" name="plus" />
                    </View>
                  </Background>
                </Box>
                {isHovered ? detailIcons : null}
              </View>
            </View>
          </Box>
        )}
      </Hoverable>
    </Touchable>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    position: 'relative',
  },
  detailIconsStyle: {
    backgroundColor: getColor('white'),
    borderColor: getColor('emmre-blue-4'),
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: 'row',
    left: 3,
    position: 'absolute',
    top: -6,
  },
  float: {
    backgroundColor: getColor('white'),
    flexDirection: 'row',
    position: 'absolute',
    top: -12,
  },
  iconContainer: {
    left: 5,
    position: 'absolute',
    top: 4,
  },
  lineStyle: {
    borderBottomWidth: 1,
    borderColor: getColor('emmre-blue-4'),
    borderStyle: 'dashed',
    width: '66%',
  },
})

export default React.memo(AddSummaryItemButton)
