import React from 'react'
import TaskAcceptButton from '@/components/tasks/TaskAcceptButton'
import { Task } from '@/types/entities'

interface TaskListItemStatusFocusProps {
  task: Task
}

function TaskListItemStatusFocus({ task }: TaskListItemStatusFocusProps) {
  // TODO: Need to check if user can accept task?
  return <TaskAcceptButton task={task} />
}

export default React.memo(TaskListItemStatusFocus)
