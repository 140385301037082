import React from 'react'
import Tabs, { TabOption } from '@/components/core/Tabs'
import { useChannel } from '@/hooks/channels'
import { TaskRelationship, TaskType } from '@/types/tasks'
import { useWatchingTabLabel } from './hooks'

interface TaskRelationshipTabsProps {
  channelId?: number | null
  onChange: (value: TaskRelationship) => void
  spaceId: number
  taskType: TaskType
  value: TaskRelationship
}

function TaskRelationshipTabs({
  value,
  onChange,
  spaceId,
  channelId,
  taskType,
}: TaskRelationshipTabsProps) {
  const handleChange = React.useCallback(
    (val: string) => onChange(val as TaskRelationship),
    [onChange],
  )
  const taskTypeLabel = taskType === 'TASK' ? 'Tasks' : 'Questions'
  const watchingLabel = useWatchingTabLabel({ channelId, defaultLabel: 'Watching', spaceId })
  const options = React.useMemo<TabOption[]>(
    () => [
      {
        label: `All ${taskTypeLabel}`,
        value: 'ALL',
      },
      {
        label: `My ${taskTypeLabel}`,
        value: 'MINE',
      },
      {
        label: watchingLabel,
        value: 'WATCHING',
      },
    ],
    [watchingLabel, taskTypeLabel],
  )
  const channel = useChannel(spaceId, channelId || 0)

  if (channel && channel.userIds.length === 1) {
    return null
  }

  return (
    <Tabs
      backgroundColor="heather"
      foregroundColor="shadow"
      onChange={handleChange}
      options={options}
      selectedBackgroundColor="ghost"
      selectedBorderColor="anchor"
      selectedForegroundColor="shadow"
      value={value}
    />
  )
}

export default React.memo(TaskRelationshipTabs)
