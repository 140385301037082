import React from 'react'
import Background from '@/components/core/Background'
import Box from '@/components/layouts/Box'
import AddChoiceButton from './AddChoiceButton'
import ChoiceItem from './ChoiceItem'
import OpenEndedResponseToggle from './OpenEndedResponseToggle'
import QuestionResponseTypeSelector from './QuestionResponseTypeSelector'
import { useForm } from './hooks'

function MultipleChoiceQuestionEditor() {
  const {
    toggleQuestionIsOpenEnded,
    questionIsOpenEnded,
    addNewResponse,
    removeResponse,
    updateResponseValue,
    questionResponses,
    questionResponseType,
    setQuestionResponseType,
    canAddResponse,
  } = useForm()

  return (
    <Box>
      {questionResponses.map((value, index) => (
        <ChoiceItem
          key={index.toString()}
          index={index}
          isLast={index === questionResponses.length - 1}
          onRemove={removeResponse}
          onSubmitEditing={addNewResponse}
          onUpdate={updateResponseValue}
          questionResponseType={questionResponseType}
          value={value}
        />
      ))}
      {canAddResponse ? <AddChoiceButton onAdd={addNewResponse} /> : null}
      <OpenEndedResponseToggle
        isOpenEnded={questionIsOpenEnded}
        responseType={questionResponseType}
        toggleIsOpenEnded={toggleQuestionIsOpenEnded}
      />
      <Background borderTopColor="iron" borderTopWidth={1} />
      <QuestionResponseTypeSelector
        responseType={questionResponseType}
        setResponseType={setQuestionResponseType}
      />
    </Box>
  )
}

export default React.memo(MultipleChoiceQuestionEditor)
