import * as Crypto from 'expo-crypto'
import * as FileSystem from 'expo-file-system'
import { config } from '@/core'
import { log } from '@/core/logging'
import { sleepWhile } from '@/core/utils/asynchronous'
import { chunk } from '@/core/utils/collections'
import { nowSeconds } from '@/core/utils/dates'
import { CacheDuration } from '@/types/files'
import { getExtension, isLocalFile, joinPath, walk } from '../utils'
import { FileCacheInterface, LocalFile } from './types'

export class FileCache implements FileCacheInterface {
  cache: Record<string, LocalFile>

  constructor() {
    this.cache = {}
  }

  getLocalURI(remoteURI: string): string | null {
    if (isLocalFile(remoteURI)) {
      return remoteURI
    }
    return this.get(getCacheKey(remoteURI))?.uri || null
  }

  async cacheFile(
    remoteURI: string,
    duration: CacheDuration,
    traceId?: string,
  ): Promise<string | null> {
    // Cache a file to the file system and to the in-memory cache
    // Due to the single-threaded nature of JavaScript this function is
    // set up to keep two callers from attempting to download the same
    // file simultaneously
    const logOpts = { traceId }

    if (isLocalFile(remoteURI)) {
      log('debug', `LOCAL ${remoteURI}`, logOpts)
      return remoteURI
    }

    const key = getCacheKey(remoteURI)
    const status = this.getStatus(key)

    if (status === 'ERROR') {
      log('debug', `ERROR ${key}`, logOpts)
      return null
    }

    if (status === 'LOADING') {
      log('debug', `WAITING ${key}`, logOpts)
      await sleepWhile(() => this.isStatus(key, 'LOADING'), 100)
      log('debug', `DONE WAITING ${key}`, logOpts)

      return this.get(key)?.uri || null
    }

    if (status === 'READY') {
      return this.get(key)?.uri || null
    }

    // DON'T CHANGE THIS!
    // Setting the localFile before calling any awaits to avoid multiple download attempts
    const localFile = this.set(key, { status: 'LOADING', uri: null })

    localFile.uri = await buildLocalURI(key, duration)
    const metadata = await FileSystem.getInfoAsync(localFile.uri)

    if (metadata.exists) {
      log('debug', `STORED ${key}`, logOpts)
      localFile.status = 'READY'
      return localFile.uri
    }

    log('debug', `DOWNLOADING ${key}`, logOpts)
    const downloaded = await downloadFile(remoteURI, localFile.uri)

    if (downloaded) {
      log('debug', `DOWNLOADED ${key}`, logOpts)
      localFile.status = 'READY'
      return localFile.uri
    }

    log('debug', `FAILED TO DOWNLOAD ${key}`, logOpts)

    this.set(key, { status: 'ERROR', uri: null })
    return null
  }

  async warmupCache(remoteURIs: string[], duration: CacheDuration, parallelism = 3) {
    const chunks = chunk(parallelism, remoteURIs)

    for (const uriChunk of chunks) {
      await Promise.all(uriChunk.map(remoteURI => this.cacheFile(remoteURI, duration)))
    }
  }

  async clearCache() {
    await FileSystem.deleteAsync(getCacheDirectory(), { idempotent: true })
    this.cache = {}
  }

  async cleanupCaches(maxAges: Record<string, number>) {
    const cacheDir = getCacheDirectory()
    const cacheMeta = await FileSystem.getInfoAsync(cacheDir)

    if (!cacheMeta.exists) {
      return
    }

    const entries = await FileSystem.readDirectoryAsync(getCacheDirectory())

    for (const entry of entries) {
      const path = joinPath(cacheDir, entry)
      const maxAge = maxAges[entry]

      if (maxAge) {
        await this.cleanupFiles(path, maxAge)
      } else {
        log('debug', `DELETING ${path}`)
        await FileSystem.deleteAsync(path, { idempotent: true })
      }
    }
  }

  private has(key: string): boolean {
    return key in this.cache
  }

  private set(key: string, localFile: LocalFile): LocalFile {
    this.cache[key] = localFile
    return localFile
  }

  private get(key: string): LocalFile | undefined {
    return this.cache[key]
  }

  private getStatus(key: string) {
    const localFile = this.get(key)
    return localFile?.status || null
  }

  private isStatus(key: string, status: 'LOADING' | 'READY' | 'ERROR') {
    return this.getStatus(key) === status
  }

  private async getMetadata(key: string) {
    const localFile = this.get(key)
    if (!(localFile && localFile.uri)) {
      return null
    }
    const metadata = await FileSystem.getInfoAsync(localFile.uri)
    return metadata
  }

  private async cleanupFiles(root: string, maxAgeSeconds: number) {
    const currentTimeSeconds = nowSeconds()

    await walk(root, async meta => {
      if (!meta.exists) {
        return
      }

      const modTimeSeconds = meta.modificationTime
      const age = currentTimeSeconds - modTimeSeconds

      if (age >= maxAgeSeconds) {
        log('debug', `CLEANING UP ${meta.uri}`)
        await FileSystem.deleteAsync(meta.uri, { idempotent: true })
        this.remove(meta.uri)
      }
    })
  }

  private remove(localURI: string) {
    const entry = Object.entries(this.cache).find(e => e[1].uri === localURI)
    if (entry) {
      const [remoteURI] = entry
      delete this.cache[remoteURI]
    }
  }
}

const uriWithoutQueryString = (uri: string): string => {
  const qmIndex = uri.indexOf('?')
  if (qmIndex === -1) {
    return uri
  }
  return uri.slice(0, qmIndex)
}

const getCacheKey = uriWithoutQueryString

const buildLocalURI = async (remoteURI: string, group = '') => {
  const cacheKey = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, remoteURI)
  const filesCacheDir = getCacheDirectory(group)
  await FileSystem.makeDirectoryAsync(filesCacheDir, { intermediates: true })

  // Saving files with the extension allows video thumbnail to work.
  const extension = getExtension(remoteURI)
  const ext = extension ? `.${extension}` : ''
  return joinPath(filesCacheDir, `${cacheKey}${ext}`)
}

const mapRemoteURI = (remoteURI: string) =>
  remoteURI.replace(/^http:\/\/localhost:.*?\//, `${config.apiURLRoot}/`)

const downloadFile = async (remoteURI: string, localURI: string): Promise<boolean> => {
  const mappedRemoteURI = mapRemoteURI(remoteURI)

  try {
    const response = await FileSystem.downloadAsync(mappedRemoteURI, localURI)
    if (response.status !== 200) {
      throw new Error(
        `Downloading file ${mappedRemoteURI} failed with status code ${response.status}`,
      )
    }
  } catch (err) {
    log('debug', `ERROR DOWNLOADING FILE: ${err}`)
    await FileSystem.deleteAsync(localURI, { idempotent: true })
    return false
  }

  return true
}

const getCacheDirectory = (subdir = '') => {
  const appCache = joinPath(FileSystem.cacheDirectory || '', 'app-cache')
  return joinPath(appCache, subdir)
}
