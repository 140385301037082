import React from 'react'
import Link from '@/components/core/Link'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'

interface MessagesListLoadErrorProps {
  onLoadMore: () => void
  isLoading: boolean
}

function MessagesListLoadError({ onLoadMore, isLoading }: MessagesListLoadErrorProps) {
  return (
    <Box direction="row" gapAll="large" justifyContent="center">
      <Text color="red">Unable to load messages. </Text>
      <Link disabled={isLoading} label="Try Again" onPress={onLoadMore} />
    </Box>
  )
}

export default React.memo(MessagesListLoadError)
