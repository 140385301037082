import { useTaskFormContext } from '@/contexts/TaskFormContext'
import { taskUtils } from '@/core/utils'
import {
  useAppSelector,
  useCachedSelector,
  useCurrentUserId,
  useFormField,
  useFormValue,
  useFormValues,
} from '@/hooks'
import { attachmentSelectors, formSelectors } from '@/store/selectors'
import { TaskDetail, TaskType } from '@/types/tasks'
import { fieldNames } from '../constants'

export const useTaskFormValue = (fieldName: string): any => {
  const { formName } = useTaskFormContext()
  return useAppSelector(formSelectors.value, formName, fieldName)
}

export const useTaskFormValues = (): Record<keyof typeof fieldNames, any> => {
  const { formName } = useTaskFormContext()
  return useAppSelector(formSelectors.values, formName)
}

export const useTaskFormField = <T = string | null>(fieldName: string) => {
  const { formName } = useTaskFormContext()
  return useFormField<T>(formName, fieldName)
}

export const useTaskAssignee = () => {
  const currentUserId = useCurrentUserId()
  const { value, error, changed, setValue } = useTaskFormField<number | null>(
    fieldNames.assignedUserId,
  )
  return { changed, error, isAssignedToMe: currentUserId === value, setValue, value }
}

export const useIsDetailActive = (taskDetail: TaskDetail): boolean => {
  const values = useTaskFormValues()
  return taskUtils.isEditDetailActive(taskDetail, values)
}

export const useActiveDetails = (taskDetails: TaskDetail[]): TaskDetail[] => {
  const values = useTaskFormValues()
  return taskDetails.filter(detail => taskUtils.isEditDetailActive(detail, values))
}

export const useHasChanges = (formName: string) => {
  const hasChanges = useCachedSelector(formSelectors.hasChangesSelector, [formName])
  return hasChanges
}

export const usePendingAttachments = (formName: string) => {
  const attachmentIds = useFormValue(formName, fieldNames.attachmentIds)
  const pending = useCachedSelector(attachmentSelectors.pendingSelector, [attachmentIds || []])
  return pending
}

export const useFormMissingFields = (formName: string, taskType: TaskType) => {
  const values = useFormValues(formName)
  const missingFields: string[] = []

  if (!values.spaceId) {
    missingFields.push('spaceId')
  }
  if (!values.assignedUserId) {
    missingFields.push('assignedUserId')
  }
  if (!values.content) {
    missingFields.push('content')
  }

  if (taskType === 'QUESTION') {
    if (!values.questionIsOpenEnded && (values.questionResponses?.length || 0) === 0) {
      missingFields.push('questionResponses')
    }
  }

  return missingFields
}
