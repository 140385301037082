import React from 'react'
import { ColorName } from '@/core/constants/colors'
import { useOptionalAmplitude } from '@/hooks/amplitude'
import { AmplitudeProps } from '@/types/amplitude'
import { HitSlop } from '@/types/components'
import Background from './Background'
import ContainedIcon, { ContainedIconProps, ContainedIconShape } from './ContainedIcon'
import Hoverable from './Hoverable'
import Touchable from './Touchable'

export interface BaseIconButtonProps extends Omit<ContainedIconProps, 'shape'>, AmplitudeProps {
  disabled?: boolean
  onPressDisabled?: () => void
  onPress?: () => void
  hitSlop?: HitSlop
  // Short square gives padding to the horizontal but not the to vertical
  shape?: ContainedIconShape | 'short-square'
  testID?: string
  dropShadow?: boolean
  hoverBackgroundColor?: ColorName
}

function BaseIconButton({
  amplitudeEventName,
  amplitudeEventProperties,
  disabled = false,
  onPress,
  onPressDisabled,
  hitSlop = 'medium',
  padding,
  shape = 'circle',
  testID,
  dropShadow,
  hoverBackgroundColor = 'transparent',
  borderRadius,
  ...iconProps
}: BaseIconButtonProps) {
  const wrappedOnPress = useOptionalAmplitude(onPress, amplitudeEventName, amplitudeEventProperties)
  const touchableStyle = React.useMemo(
    () => (shape === 'short-square' ? { paddingLeft: padding, paddingRight: padding } : undefined),
    [shape, padding],
  )
  return (
    <Hoverable>
      {hovering => (
        <Background borderRadius={4} color={hovering ? hoverBackgroundColor : 'transparent'}>
          <Touchable
            disabled={disabled}
            feedback="opacity"
            hitSlop={hitSlop}
            onPress={wrappedOnPress}
            onPressDisabled={onPressDisabled}
            style={touchableStyle}
            testID={testID}
          >
            <ContainedIcon
              borderRadius={shape === 'circle' ? undefined : borderRadius}
              dropShadow={dropShadow}
              padding={shape === 'short-square' ? 0 : padding}
              shape={shape === 'short-square' ? 'square' : shape}
              {...iconProps}
            />
          </Touchable>
        </Background>
      )}
    </Hoverable>
  )
}

export default React.memo(BaseIconButton)
