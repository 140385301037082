import { zeroPad } from './numbers'

type DateOrTimestamp = Date | number

export type DateUnit =
  | 'year'
  | 'years'
  | 'month'
  | 'months'
  | 'week'
  | 'weeks'
  | 'day'
  | 'days'
  | 'hour'
  | 'hours'
  | 'minute'
  | 'minutes'
  | 'second'
  | 'seconds'

type TimeInterval = {
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

const normalize = (date: DateOrTimestamp): Date =>
  typeof date === 'number' ? new Date(date) : date

export const nowMilliseconds = () => now().getTime()

export const millisecondsToSeconds = (milliseconds: number) => Math.floor(milliseconds / 1000)

export const nowSeconds = () => millisecondsToSeconds(nowMilliseconds())

const minutesToSeconds = (minutes: number) => minutes * 60
const hoursToSeconds = (hours: number) => minutesToSeconds(hours * 60)
const daysToSeconds = (days: number) => hoursToSeconds(days * 24)
const weeksToSeconds = (weeks: number) => daysToSeconds(weeks * 7)

export const toSeconds = {
  days: daysToSeconds,
  hours: hoursToSeconds,
  minutes: minutesToSeconds,
  weeks: weeksToSeconds,
}

const secondsToMilliseconds = (seconds: number) => seconds * 1000
const minutesToMilliseconds = (minutes: number) => secondsToMilliseconds(minutesToSeconds(minutes))
const hoursToMilliseconds = (hours: number) => secondsToMilliseconds(hoursToSeconds(hours))
const daysToMilliseconds = (days: number) => secondsToMilliseconds(daysToSeconds(days))
const weeksToMilliseconds = (weeks: number) => secondsToMilliseconds(weeksToSeconds(weeks))

export const toMilliseconds = {
  days: daysToMilliseconds,
  hours: hoursToMilliseconds,
  minutes: minutesToMilliseconds,
  seconds: secondsToMilliseconds,
  weeks: weeksToMilliseconds,
}

export const now = (): Date => new Date()

export const copy = (date: DateOrTimestamp): Date => new Date(date)

export const add = (date: Date, offset: number, unit: DateUnit): Date => {
  const dt = copy(date)

  switch (unit) {
    case 'year':
    case 'years':
      dt.setFullYear(dt.getFullYear() + offset)
      if (dt.getDate() !== date.getDate()) {
        dt.setDate(0)
      }
      break
    case 'month':
    case 'months':
      dt.setMonth(dt.getMonth() + offset)
      if (dt.getDate() !== date.getDate()) {
        dt.setDate(0)
      }
      break
    case 'week':
    case 'weeks':
      dt.setDate(dt.getDate() + 7 * offset)
      break
    case 'day':
    case 'days':
      dt.setDate(dt.getDate() + offset)
      break
    case 'hour':
    case 'hours':
      dt.setHours(dt.getHours() + offset)
      break
    case 'minute':
    case 'minutes':
      dt.setMinutes(dt.getMinutes() + offset)
      break
    case 'second':
    case 'seconds':
      dt.setSeconds(dt.getSeconds() + offset)
      break
    default:
      throw new Error(`Invalid date unit: ${unit}`)
  }

  return dt
}

export const setTime = (
  date: Date,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
): Date => {
  const dt = copy(date)
  dt.setHours(hours, minutes, seconds, milliseconds)
  return dt
}

export const matchTime = (fromDate: Date, toDate: Date): Date =>
  setTime(
    toDate,
    fromDate.getHours(),
    fromDate.getMinutes(),
    fromDate.getSeconds(),
    fromDate.getMilliseconds(),
  )

export const getTimeInterval = (fromDate: Date, toDate: Date): TimeInterval => {
  let delta = toDate.getTime() - fromDate.getTime()

  const milliseconds = delta % 1000
  delta = Math.floor(delta / 1000)

  const seconds = delta % 60
  delta = Math.floor(delta / 60)

  const minutes = delta % 60
  delta = Math.floor(delta / 60)

  const hours = delta % 24
  const days = Math.floor(delta / 24)

  return { days, hours, milliseconds, minutes, seconds }
}

export const floor = (date: Date, unit: DateUnit): Date => {
  const dt = copy(date)

  switch (unit) {
    case 'year':
    case 'years':
      dt.setMonth(0, 1)
      dt.setHours(0, 0, 0, 0)
      break
    case 'month':
    case 'months':
      dt.setDate(1)
      dt.setHours(0, 0, 0, 0)
      break
    case 'week':
    case 'weeks':
      dt.setDate(dt.getDate() - dt.getDay())
      dt.setHours(0, 0, 0, 0)
      break
    case 'day':
    case 'days':
      dt.setHours(0, 0, 0, 0)
      break
    case 'hour':
    case 'hours':
      dt.setMinutes(0, 0, 0)
      break
    case 'minute':
    case 'minutes':
      dt.setSeconds(0, 0)
      break
    case 'second':
    case 'seconds':
      dt.setMilliseconds(0)
      break
    default:
      throw new Error(`Invalid date unit: ${unit}`)
  }

  return dt
}

export const ceil = (date: Date, unit: DateUnit): Date => {
  const dt = copy(date)

  switch (unit) {
    case 'year':
    case 'years':
      dt.setMonth(11, 31)
      dt.setHours(23, 59, 59, 999)
      break
    case 'month':
    case 'months':
      dt.setMonth(dt.getMonth() + 1, 0)
      dt.setHours(23, 59, 59, 999)
      break
    case 'week':
    case 'weeks':
      dt.setDate(dt.getDate() + (6 - dt.getDay()))
      dt.setHours(23, 59, 59, 999)
      break
    case 'day':
    case 'days':
      dt.setHours(23, 59, 59, 999)
      break
    case 'hour':
    case 'hours':
      dt.setMinutes(59, 59, 999)
      break
    case 'minute':
    case 'minutes':
      dt.setSeconds(59, 999)
      break
    case 'second':
    case 'seconds':
      dt.setMilliseconds(999)
      break
    default:
      throw new Error(`Invalid date unit: ${unit}`)
  }

  return dt
}

export const startOfNext = (date: Date, unit: DateUnit): Date => floor(add(date, 1, unit), unit)
export const endOfPrevious = (date: Date, unit: DateUnit): Date => ceil(add(date, -1, unit), unit)

const offsetRegex = /Z$/
// NOTE: JavaScript has milliseconds and Z for ISO String, the server is sending back microseconds
//       and a +00:00 for the offset, so we need to get our strings to match. Another option would
//       be to use unix epoch (integer) values
export const formatISO = (date: DateOrTimestamp): string =>
  normalize(date).toISOString().replace(offsetRegex, '000+00:00')

export const nowISO = () => formatISO(now())

export const formatLocalISO = (date: Date): string => {
  const year = date.getFullYear()
  const month = zeroPad(date.getMonth() + 1)
  const day = zeroPad(date.getDate())
  const hours = zeroPad(date.getHours())
  const minutes = zeroPad(date.getMinutes())
  const seconds = zeroPad(date.getSeconds())
  const milliseconds = zeroPad(date.getMilliseconds(), 3)
  const offset = offsetMinutesToString(-date.getTimezoneOffset())

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}000${offset}`
}

export const getTimeZoneName = (): string | null =>
  Intl.DateTimeFormat().resolvedOptions().timeZone || null

export const offsetMinutesToString = (offsetMinutes: number): string => {
  const hours = Math.floor(Math.abs(offsetMinutes / 60))
  const minutes = Math.abs(offsetMinutes % 60)
  const sign = offsetMinutes <= 0 ? '-' : '+'
  return `${sign}${zeroPad(hours)}:${zeroPad(minutes)}`
}

export const maybeFormatISO = (value: Date | string | null): string | null => {
  if (!value) {
    return null
  }
  if (value instanceof Date) {
    return formatISO(value)
  }
  return value
}

type ParseInput = string | null | undefined | number | Date

export const tryParse = (value: ParseInput): Date | null => {
  if (value === undefined || value === null) {
    return null
  }
  const date = new Date(value)
  if (Number.isNaN(date.valueOf())) {
    return null
  }
  return date
}

export const parse = (value: ParseInput): Date => {
  const date = tryParse(value)
  if (date === null) {
    throw new Error(`Unable to parse date: ${value}`)
  }
  return date
}

export const isSameDay = (left: Date, right: Date): boolean =>
  left.getFullYear() === right.getFullYear() &&
  left.getMonth() === right.getMonth() &&
  left.getDate() === right.getDate()

export const formatDate = (date: Date): string => format(date, commonFormats.date)

export const formatDateTime = (date: Date): string => format(date, commonFormats.dateTime)

export const formatTime = (date: Date): string => format(date, commonFormats.time)

export const format = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  const formatter = new Intl.DateTimeFormat(undefined, options)
  return formatter.format(date)
}

const toLocalComparable = (date: Date): string => {
  const year = date.getFullYear()
  const month = zeroPad(date.getMonth())
  const day = zeroPad(date.getDate())
  const hour = zeroPad(date.getHours())
  const minute = zeroPad(date.getMinutes())
  const seconds = zeroPad(date.getSeconds())

  return `${year}${month}${day}${hour}${minute}${seconds}`
}

const localCompare = (left: Date, right: Date) => {
  const leftComp = toLocalComparable(left)
  const rightComp = toLocalComparable(right)

  if (leftComp < rightComp) {
    return -1
  }
  if (leftComp === rightComp) {
    return 0
  }
  return 1
}

const localGreater = (left: Date, right: Date) => localCompare(left, right) === 1

export const formatRelative = (date: Date, includeTime: boolean): string => {
  const today = floor(now(), 'day')
  const time = formatTime(date)

  if (localGreater(date, today)) {
    return `Today at ${time}`
  }

  const yesterday = floor(add(today, -1, 'day'), 'day')

  if (localGreater(date, yesterday)) {
    return `Yesterday at ${time}`
  }

  const includeYear = date.getFullYear() !== today.getFullYear()

  if (includeTime) {
    return format(date, includeYear ? commonFormats.dateTime : commonFormats.dateTimeNoYear)
  }
  return format(date, includeYear ? commonFormats.date : commonFormats.dateNoYear)
}

export const formatDateRelative = (date: Date): string => {
  const today = floor(now(), 'day')

  if (localGreater(date, today)) {
    return 'Today'
  }

  const yesterday = floor(add(now(), -1, 'days'), 'day')

  if (localGreater(date, yesterday)) {
    return 'Yesterday'
  }

  const lastWeek = floor(add(now(), -6, 'days'), 'day')

  if (localGreater(date, lastWeek)) {
    return format(date, { weekday: 'long' })
  }

  const startOfYear = floor(now(), 'year')

  if (localGreater(date, startOfYear)) {
    return format(date, { day: 'numeric', month: 'long', weekday: 'long' })
  }

  return format(date, { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' })
}

export const formatShort = (date: Date, includeTime = true): string => {
  const includeYear = date.getFullYear() !== now().getFullYear()

  if (includeTime) {
    return format(
      date,
      includeYear ? commonFormats.shortDateTime : commonFormats.shortDateTimeNoYear,
    )
  }
  return format(date, includeYear ? commonFormats.shortDate : commonFormats.shortDateNoYear)
}

export const commonFormats: Record<string, Intl.DateTimeFormatOptions> = {
  date: { day: 'numeric', month: 'numeric', year: 'numeric' },
  dateNoYear: { day: 'numeric', month: 'numeric' },
  dateTime: {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  dateTimeNoYear: { day: 'numeric', hour: 'numeric', minute: 'numeric', month: 'numeric' },
  shortDate: {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  },
  shortDateNoYear: {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  },
  shortDateTime: {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  },
  shortDateTimeNoYear: {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    weekday: 'short',
  },
  time: { hour: 'numeric', minute: 'numeric' },
}

type TimingResult<T> = { result: T; durationMS: number }

export const withTiming = async <T>(callback: () => Promise<T>): Promise<TimingResult<T>> => {
  const start = nowMilliseconds()
  const result = await callback()
  const durationMS = nowMilliseconds() - start
  return { durationMS, result }
}
