import React from 'react'
import { StyleSheet } from 'react-native'
import ContentContainer from '@/components/layouts/ContentContainer'
import TaskForm from '@/components/tasks/TaskForm'
import { getColor } from '@/core/constants/colors'
import { TaskFormArgs } from '@/types/tasks'

interface TaskModalProps extends TaskFormArgs {
  onClose: () => void
}

function TaskModal(taskFormArgs: TaskModalProps) {
  const { taskId } = taskFormArgs
  // NOTE: The key on the TaskForm component makes it re-render properly on web when duplicating
  return (
    <ContentContainer avoidKeyboard context="MODAL" foregroundStyle={styles.foreground} insetTop>
      <TaskForm {...taskFormArgs} key={taskId} />
    </ContentContainer>
  )
}

const styles = StyleSheet.create({
  foreground: {
    backgroundColor: getColor('transparent'),
    flex: 1,
  },
})

export default React.memo(TaskModal)
