import React from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import Text from '@/components/core/Text'
import TextInput from '@/components/core/TextInput'
import Touchable from '@/components/core/Touchable'
import ContentContainer from '@/components/layouts/ContentContainer'
import { getColor } from '@/core/constants/colors'
import { OnKeyPress } from '@/types/components'
import { Action } from '@/types/generics'

interface PromptModalProps {
  initialValue?: string
  isOpen: boolean
  message?: string
  onCancel?: () => void
  onClose: () => void
  onSubmit: Action<string>
  submitText?: string
  title: string
}

function PromptModal({
  initialValue,
  isOpen,
  message,
  onCancel,
  onClose,
  onSubmit,
  submitText = 'Submit',
  title,
}: PromptModalProps) {
  const [value, setValue] = React.useState<string>(initialValue || '')

  const isSubmitDisabled = !value
  const handleSubmit = React.useCallback(async () => {
    if (value) {
      onSubmit(value)
      onClose()
    }
  }, [onSubmit, onClose, value])

  const handleCancel = React.useCallback(() => {
    if (onCancel) {
      onCancel()
    }
    onClose()
  }, [onCancel, onClose])

  const wasOpen = React.useRef(isOpen)

  React.useEffect(() => {
    if (isOpen && !wasOpen.current) {
      setValue(initialValue || '')
    }
    wasOpen.current = isOpen
  }, [isOpen, initialValue, setValue])

  const handleEscape = React.useCallback<OnKeyPress>(
    ({ nativeEvent }) => {
      if (nativeEvent.key === 'Escape') {
        handleCancel()
      }
    },
    [handleCancel],
  )

  return (
    <Modal animationType="fade" transparent visible={isOpen}>
      <ContentContainer
        avoidKeyboard
        backgroundStyle={styles.overlay}
        context="MODAL"
        foregroundStyle={styles.container}
      >
        <View style={styles.title}>
          <Text align="center" weight="medium">
            {title}
          </Text>
          {message ? (
            <View style={styles.message}>
              <Text align="center" size="h5">
                {message}
              </Text>
            </View>
          ) : null}
        </View>
        <View style={styles.input}>
          <TextInput
            autoCapitalize="none"
            autoFocus
            kind="inset"
            onChangeText={setValue}
            onKeyPress={handleEscape}
            onSubmitEditing={handleSubmit}
            value={value}
          />
        </View>
        <Touchable feedback="opacity" onPress={handleCancel} style={styles.button}>
          <Text color="emmre-blue">Cancel</Text>
        </Touchable>
        <Touchable
          disabled={isSubmitDisabled}
          feedback="opacity"
          onPress={handleSubmit}
          style={styles.button}
        >
          <Text color={isSubmitDisabled ? 'iron' : 'emmre-blue'}>{submitText}</Text>
        </Touchable>
      </ContentContainer>
    </Modal>
  )
}

// These styles match AlertModal. Should I make something that brings it all together?
const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderTopColor: getColor('heather'),
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 15,
  },
  container: {
    backgroundColor: getColor('ghost'),
    borderRadius: 20,
    width: 300,
  },
  input: {
    padding: 10,
  },
  message: {
    marginTop: 10,
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: getColor('ModalBG'),
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  title: {
    margin: 20,
    marginBottom: 10,
  },
})

export default React.memo(PromptModal)
