import React from 'react'

export const useHideDecorations = (isDragging: boolean) => {
  const [hideDecorations, setHideDecorations] = React.useState(false)

  React.useEffect(() => {
    // Dragdrop fails on chrome if the DOM of the dragged element changes on drag start. Changing
    // it with a timeout avoids this
    setTimeout(() => setHideDecorations(isDragging))
  }, [isDragging, setHideDecorations])

  return { hideDecorations }
}
