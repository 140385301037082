import { hasAction } from '@/core/utils/entities'
import { useAppSelector } from '@/hooks/redux'
import { spaceSelectors } from '@/store/selectors'
import { SpaceModule } from '@/types/entities'

export const useSpace = (spaceId: number) => {
  const space = useAppSelector(spaceSelectors.byId, spaceId)
  return space
}

export const useSpaces = () => useAppSelector(spaceSelectors.all)

// export const useHasFeature = (spaceId: number, featureName: string): boolean => {
//   const space = useAppSelector(spaceSelectors.byId, spaceId)
//   return space?.features?.includes(featureName)  === true
// }

export const useHasModule = (spaceId: number, moduleName: SpaceModule): boolean => {
  const space = useAppSelector(spaceSelectors.byId, spaceId)
  return space?.modules?.includes(moduleName) === true
}

export const useHasAction = (spaceId: number, actionName: string): boolean => {
  const space = useAppSelector(spaceSelectors.byId, spaceId)
  return !!space && hasAction(actionName, space)
}
