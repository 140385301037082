import { AVPlaybackStatus, Video, VideoProps } from 'expo-av'
import React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import { componentUtils, fileUtils, fnUtils } from '@/core/utils'
import { ComponentProp, Measurement, MeasurementFit } from '@/types/components'
import ActivityIndicator from './ActivityIndicator'

export interface CachedVideoProps extends Omit<VideoProps, 'source'> {
  ErrorComponent?: ComponentProp | null | undefined
  fit?: MeasurementFit
  size: Measurement
  style?: ViewStyle
  uri: string
}

function CachedVideo({
  ErrorComponent,
  fit = 'LARGEST',
  onReadyForDisplay,
  size,
  style = {},
  uri,
  ...videoProps
}: CachedVideoProps) {
  // This needs to start as null for caching to work properly
  const [videoURI, setVideoURI] = React.useState<string | null>(null)
  const [isError, setIsError] = React.useState(false)
  const [scaledMeasurement, setScaledMeasurement] = React.useState<Measurement>(size)
  const videoStyle = React.useMemo(
    () => ({ ...style, ...scaledMeasurement }),
    [style, scaledMeasurement],
  )
  const videoRef = React.useRef<Video>(null)

  const handleReadyForDisplay = React.useCallback(
    args => {
      const { naturalSize } = args
      if (naturalSize) {
        const scaled = componentUtils.fitSize(fit, size, naturalSize)
        setScaledMeasurement(scaled)
      }

      if (onReadyForDisplay) {
        onReadyForDisplay(args)
      }
    },
    [fit, size, setScaledMeasurement, onReadyForDisplay],
  )
  const handleError = React.useCallback(() => setIsError(true), [setIsError])

  React.useEffect(
    () =>
      fnUtils.discard(async () => {
        const localURI = await fileUtils.cacheFile(uri, 'short')
        if (localURI) {
          setVideoURI(localURI)
        }
      }),
    [uri],
  )

  const handlePlaybackStatusUpdate = React.useCallback((status: AVPlaybackStatus) => {
    if (status.isLoaded && status.didJustFinish && videoRef.current) {
      videoRef.current.setPositionAsync(0)
    }
  }, [])

  if (isError) {
    return ErrorComponent ? componentUtils.renderComponent(ErrorComponent) : null
  }

  if (!videoURI) {
    return <ActivityIndicator color="obsidian" delay={300} style={styles.loader} />
  }

  return (
    <Video
      {...videoProps}
      ref={videoRef}
      onError={handleError}
      onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
      onReadyForDisplay={handleReadyForDisplay}
      source={{ uri: videoURI }}
      style={videoStyle}
    />
  )
}

const styles = StyleSheet.create({
  loader: {
    height: 40,
    width: 40,
  },
})
export default React.memo(CachedVideo)
