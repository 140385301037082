import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useAppSelector } from '@/hooks'
import { tagSelectors } from '@/store/selectors'

interface TaskTagItemProps {
  spaceId: number
  tagId: number
  onPress: () => void
}

function TaskTagItem({ tagId, spaceId, onPress }: TaskTagItemProps) {
  const tag = useAppSelector(tagSelectors.byId, spaceId, tagId)

  if (!tag) {
    // This should never happen
    return null
  }

  return (
    <Touchable feedback="opacity" onPress={onPress}>
      <Box gapRight="small">
        <Background borderRadius={12} color="cinnamon-2">
          <Box alignItems="center" direction="row" gapAll="small">
            <Icon color="cinnamon" kind="solid" name="hashtag" size="h6" />
            <Box gapLeft="xsmall">
              <Text color="shadow" size="h6">
                {tag.name}
              </Text>
            </Box>
          </Box>
        </Background>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskTagItem)
