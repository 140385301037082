import React from 'react'
import Label from '@/components/core/Label'
import Box from '@/components/layouts/Box'
import { AmplitudeInviteContext } from '@/types/amplitude'
import RoleButton from './RoleButton'

interface RoleSelectorProps {
  amplitudeInviteContext: AmplitudeInviteContext
  onSelect: (value: string) => void
  selectedValue: string | null
  errorMessage?: string | null
  orientation?: 'vertical' | 'horizontal'
  roleFor: 'myself' | 'partner'
}

function RoleSelector({
  amplitudeInviteContext,
  onSelect,
  selectedValue,
  errorMessage,
  orientation = 'vertical',
  roleFor,
}: RoleSelectorProps) {
  const prefix = roleFor === 'myself' ? "I'm" : 'They are'
  return (
    <Box alignItems="center">
      <Label kind="error" position="top">
        {errorMessage}
      </Label>
      <Box direction={orientation === 'horizontal' ? 'row' : 'column'}>
        <Box gapRight={orientation === 'horizontal' ? 'small' : undefined}>
          <RoleButton
            amplitudeEventName={`select executive - ${amplitudeInviteContext}`}
            label={`${prefix} an Executive`}
            likely
            onSelect={onSelect}
            selectedValue={selectedValue}
            value="LEADER"
          />
        </Box>
        <Box
          gapLeft={orientation === 'horizontal' ? 'small' : undefined}
          gapTop={orientation === 'vertical' ? 'medium' : undefined}
        >
          <RoleButton
            amplitudeEventName={`select executive assistant - ${amplitudeInviteContext}`}
            label={`${prefix} an Exec Assistant`}
            onSelect={onSelect}
            selectedValue={selectedValue}
            value="ASSISTANT"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(RoleSelector)
