import {
  DefaultTheme,
  DocumentTitleOptions,
  NavigationContainer,
  Theme,
} from '@react-navigation/native'
import React from 'react'
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import AppUpdater from '@/components/data/AppUpdater'
import PushNotificationManager from '@/components/data/PushNotificationManager'
import RTMContainer from '@/components/data/RTMContainer'
import FileActionsContainer from '@/components/files/FileActionsContainer'
import ModalPresenterContainer from '@/components/modals/ModalPresenter'
import StackContextContainer from '@/components/screens/StackContextContainer'
import { getColor } from '@/core/constants/colors'
import { linking } from '@/screens/NarrowScreen/routes'
import { persistor, store } from '@/store'

interface ProvidersProps {
  children: React.ReactNode
}

const myTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: getColor('ghost') || '#fff',
  },
}

const documentTitle: DocumentTitleOptions = {
  enabled: false,
}

function Providers({ children }: ProvidersProps) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <StackContextContainer>
            <RTMContainer>
              <ModalPresenterContainer>
                <FileActionsContainer>
                  <NavigationContainer
                    documentTitle={documentTitle}
                    linking={linking}
                    theme={myTheme}
                  >
                    {children}
                  </NavigationContainer>
                  <PushNotificationManager />
                </FileActionsContainer>
              </ModalPresenterContainer>
            </RTMContainer>
          </StackContextContainer>
        </SafeAreaProvider>
        <AppUpdater />
      </PersistGate>
    </Provider>
  )
}

export default React.memo(Providers)
