import React from 'react'
import { futureDates, pastDates } from '@/core/constants/tasks'
import { collectionUtils as C, channelUtils, userUtils } from '@/core/utils'
import { includeList, unaryFilter } from '@/core/utils/tasks/filtering'
import { taskKeys } from '@/core/utils/tasks/grouping'
import { useAppSelector, useCachedSelector } from '@/hooks/redux'
import { boardSelectors, entitySelectors, spaceSelectors, tagSelectors } from '@/store/selectors'
import { FilterGroup, FilterOption, SpaceFilterOption, TaskSort } from '@/types/tasks'
import { useMyDayFilter } from './filters'

export const useAllTasksFilterGroup = (groupSort: TaskSort[]) =>
  React.useMemo<FilterGroup>(
    () => ({
      focus: 'NONE',
      key: 'ALL',
      label: 'All',
      options: [
        {
          filter: unaryFilter({}),
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'ALL',
        },
      ],
    }),
    [groupSort],
  )

export const usePriorityFilterGroup = (groupSort: TaskSort[]) =>
  React.useMemo<FilterGroup>(
    () => ({
      focus: 'URGENCY',
      key: 'URGENCY',
      label: 'Priority',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'URGENCY',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        {
          filter: unaryFilter({ urgencies: includeList(['HIGH']) }),
          groupKey: 'HIGH',
          key: 'HIGH',
          label: 'High',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
        {
          filter: unaryFilter({ urgencies: includeList(['MEDIUM']) }),
          groupKey: 'MEDIUM',
          key: 'MEDIUM',
          label: 'Medium',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
        {
          filter: unaryFilter({ urgencies: includeList(['LOW']) }),
          groupKey: 'LOW',
          key: 'LOW',
          label: 'Low',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
      ],
    }),
    [groupSort],
  )

export const useDueDateFilterGroup = (groupSort: TaskSort[]) =>
  React.useMemo<FilterGroup>(
    () => ({
      focus: 'DUE_DATE',
      key: 'DUE_DATE',
      label: 'Due',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'DUE_DATE',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...futureDates.map(
          ({ value, label, allListLabel }) =>
            ({
              allListLabel,
              filter: unaryFilter({
                dueDateRange: value,
                dueDateTypes: includeList(['DATE']),
              }),
              groupKey: value,
              key: value,
              label,
              sorts: groupSort,
              type: 'STATIC_GROUP',
            } as FilterOption),
        ),
        {
          filter: unaryFilter({ dueDateTypes: includeList(['SOMEDAY']) }),
          groupKey: 'SOMEDAY',
          key: 'SOMEDAY',
          label: 'Someday',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
        {
          filter: unaryFilter({ dueDateTypes: includeList(['NONE']) }),
          groupKey: 'NONE',
          key: 'NONE',
          label: 'No Due Date',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
      ],
    }),
    [groupSort],
  )

export const useBoardFilterGroup = (
  spaceId: number,
  channelId: number | null,
  groupSort: TaskSort[],
) => {
  const boardsByName = useCachedSelector(boardSelectors.groupedByNameSelector, [spaceId, channelId])

  return React.useMemo<FilterGroup>(
    () => ({
      focus: 'BOARD',
      key: 'BOARD',
      label: 'Projects',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'BOARD',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...[...boardsByName.entries()].map(
          ([boardName, boards]) =>
            ({
              filter: unaryFilter({
                boardIds: includeList(boards.map(board => [board.spaceId, board.id])),
              }),
              groupKey: boardName,
              key: boardName,
              label: boardName,
              sorts: groupSort,
              type: 'DYNAMIC_GROUP',
            } as FilterOption),
        ),
        {
          filter: unaryFilter({ boardIds: { mode: 'none' } }),
          key: 'NONE',
          label: 'No Project',
          sorts: groupSort,
          type: 'NOT_GROUPED',
        },
      ],
    }),
    [boardsByName, groupSort],
  )
}

export const useTagFilterGroup = (spaceId: number, groupSort: TaskSort[]) => {
  const tags = useCachedSelector(tagSelectors.bySpaceIdSelector, [spaceId])
  return React.useMemo<FilterGroup>(
    () => ({
      focus: 'TAGS',
      key: 'TAGS',
      label: 'Tags',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'TAG',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...tags.map(
          tag =>
            ({
              filter: unaryFilter({ tagIds: includeList([[tag.spaceId, tag.id]]) }),
              groupKey: taskKeys.tag(tag),
              key: taskKeys.tag(tag),
              label: tag.name,
              sorts: groupSort,
              type: 'DYNAMIC_GROUP',
            } as FilterOption),
        ),
        {
          filter: unaryFilter({ tagIds: { mode: 'none' } }),
          key: 'NONE',
          label: 'No Tag',
          sorts: groupSort,
          type: 'NOT_GROUPED',
        },
      ],
    }),
    [tags, groupSort],
  )
}

export const useCreatedDateFilterGroup = (groupSort: TaskSort[]) =>
  React.useMemo<FilterGroup>(
    () => ({
      focus: 'CREATED_DATE',
      key: 'CREATED_DATE',
      label: 'Created',
      options: [
        {
          filter: unaryFilter({ createdDateRange: { lbound: null, ubound: null } }),
          groupingType: 'CREATED_DATE',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...pastDates.map(
          ({ value, label, allListLabel }) =>
            ({
              allListLabel,
              filter: unaryFilter({ createdDateRange: value }),
              groupKey: value,
              key: value,
              label,
              sorts: groupSort,
              type: 'STATIC_GROUP',
            } as FilterOption),
        ),
      ],
    }),
    [groupSort],
  )

export const useStatusFilterGroup = (groupSort: TaskSort[]) =>
  React.useMemo<FilterGroup>(
    () => ({
      focus: 'STATUS',
      key: 'STATUS',
      label: 'Status',
      options: [
        {
          filter: unaryFilter({ createdDateRange: { lbound: null, ubound: null } }),
          groupingType: 'STATUS',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        {
          filter: unaryFilter({ accepted: false }),
          groupKey: 'UNACCEPTED',
          key: 'UNACCEPTED',
          label: 'Unaccepted',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
        {
          filter: unaryFilter({ accepted: true }),
          groupKey: 'ACCEPTED',
          key: 'ACCEPTED',
          label: 'Accepted',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
      ],
    }),
    [groupSort],
  )

export const useSpaceFilterOptions = (
  excludeSpaceIds: Set<number>,
  groupSort: TaskSort[],
): SpaceFilterOption[] => {
  const spaces = useAppSelector(spaceSelectors.all)

  return React.useMemo<SpaceFilterOption[]>(
    () =>
      spaces
        .filter(space => !excludeSpaceIds.has(space.id))
        .map(
          space =>
            ({
              option: {
                filter: unaryFilter({
                  spaceIds: includeList([space.id]),
                }),
                groupingType: 'SPACE',
                key: `SPACE:${taskKeys.space(space)}`,
                label: space.name,
                sorts: groupSort,
              },
              spaceId: space.id,
            } as SpaceFilterOption),
        ),
    [spaces, excludeSpaceIds, groupSort],
  )
}

export const useSharedWithFilterGroup = (spaceId: number, groupSort: TaskSort[]) => {
  const channels = useCachedSelector(entitySelectors.resolvedChannelsSelector, [
    React.useMemo(() => ({ spaceId }), [spaceId]),
  ])
  const sortedChannels = React.useMemo(
    () => C.sort(channelUtils.compareByChannelType, channels),
    [channels],
  )
  return React.useMemo<FilterGroup>(
    () => ({
      focus: 'SHARE',
      key: 'SHARE',
      label: 'Shared With',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'CHANNEL',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...sortedChannels.map(
          channel =>
            ({
              filter: unaryFilter({ channelIds: includeList([[channel.spaceId, channel.id]]) }),
              groupKey: taskKeys.channel(channel),
              key: taskKeys.channel(channel),
              label: channel.name || '',
              sorts: groupSort,
              type: 'DYNAMIC_GROUP',
            } as FilterOption),
        ),
      ],
    }),
    [sortedChannels, groupSort],
  )
}

export const useCreatedByFilterGroup = (
  spaceId: number,
  channelId: number | null,
  groupSort: TaskSort[],
) => {
  const users = useCachedSelector(entitySelectors.filteredUsersSelector, [
    React.useMemo(
      () => ({
        channelId,
        includeInactive: true,
        spaceId,
      }),
      [channelId, spaceId],
    ),
  ])

  return React.useMemo<FilterGroup>(
    () => ({
      focus: 'CREATOR',
      key: 'CREATOR',
      label: 'Created By',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'CREATOR',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...users.map(
          user =>
            ({
              filter: unaryFilter({ creatorUserIds: includeList([user.id]) }),
              groupKey: taskKeys.creator(user),
              key: taskKeys.creator(user),
              label: userUtils.getFullName(user),
              sorts: groupSort,
              type: 'DYNAMIC_GROUP',
            } as FilterOption),
        ),
      ],
    }),
    [users, groupSort],
  )
}

export const useAssignedToFilterGroup = (
  spaceId: number,
  channelId: number | null,
  groupSort: TaskSort[],
) => {
  const users = useCachedSelector(entitySelectors.filteredUsersSelector, [
    React.useMemo(
      () => ({
        channelId,
        includeInactive: true,
        spaceId,
      }),
      [channelId, spaceId],
    ),
  ])

  return React.useMemo<FilterGroup>(
    () => ({
      focus: 'ASSIGNED_USER',
      key: 'ASSIGNED_USER',
      label: 'Assigned To',
      options: [
        {
          filter: unaryFilter({}),
          groupingType: 'ASSIGNED_USER',
          key: 'ALL',
          label: 'All',
          sorts: groupSort,
          type: 'GROUPING',
        },
        ...users.map(
          user =>
            ({
              filter: unaryFilter({ assignedUserIds: includeList([user.id]) }),
              groupKey: taskKeys.assignedUser(user),
              key: taskKeys.assignedUser(user),
              label: userUtils.getFullName(user),
              sorts: groupSort,
              type: 'DYNAMIC_GROUP',
            } as FilterOption),
        ),
      ],
    }),
    [users, groupSort],
  )
}

export const useSourceFilterGroup = (groupSort: TaskSort[]) =>
  React.useMemo<FilterGroup>(
    () => ({
      focus: 'SOURCE',
      key: 'SOURCE',
      label: 'Source',
      options: [
        {
          filter: unaryFilter({ creationSources: includeList(['SLACK']) }),
          groupKey: 'SOURCE',
          key: 'SLACK',
          label: 'Slack',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
        {
          filter: unaryFilter({ creationSources: includeList(['SMS']) }),
          groupKey: 'SOURCE',
          key: 'SMS',
          label: 'SMS',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
        {
          filter: unaryFilter({ creationSources: includeList(['EMAIL']) }),
          groupKey: 'SOURCE',
          key: 'EMAIL',
          label: 'Email',
          sorts: groupSort,
          type: 'STATIC_GROUP',
        },
      ],
    }),
    [groupSort],
  )

export const useMyDayFilterOption = (label: string) => {
  const myDayFilter = useMyDayFilter()

  return React.useMemo<FilterOption | null>(
    () =>
      myDayFilter
        ? {
            filter: myDayFilter,
            key: 'MY_DAY',
            label,
            sorts: [
              {
                direction: 'asc',
                nullsFirst: false,
                // The my day filter should always have taskListId set (that's the whole point)
                // however it may be better to not rely on the internal representatin of the
                // returned filter and rather have the `useMyDayFilter` also return the taskListId
                // explicitly
                taskListId: myDayFilter.taskList?.taskListId || 0,

                type: 'list',
              },
            ],
            type: 'ALL',
          }
        : null,
    [myDayFilter, label],
  )
}
