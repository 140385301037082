import React from 'react'
import Background from '@/components/core/Background'
import Icon from '@/components/core/Icon'
import Text from '@/components/core/Text'
import Touchable from '@/components/core/Touchable'
import Box from '@/components/layouts/Box'
import { useAppSelector } from '@/hooks'
import { boardSelectors } from '@/store/selectors'

interface TaskBoardItemProps {
  spaceId: number
  boardId: number
  onPress: () => void
}

function TaskBoardItem({ boardId, spaceId, onPress }: TaskBoardItemProps) {
  const board = useAppSelector(boardSelectors.byId, spaceId, boardId)

  if (!board) {
    // This should never happen
    return null
  }

  return (
    <Touchable feedback="opacity" onPress={onPress}>
      <Box gapRight="small">
        <Background borderRadius={2} color="cinnamon-1">
          <Box alignItems="center" direction="row" gapAll="small">
            <Icon color="cinnamon" kind="solid" name="th-list" size="h6" />
            <Box gapLeft="xsmall">
              <Text color="shadow" size="h6">
                {board.name}
              </Text>
            </Box>
          </Box>
        </Background>
      </Box>
    </Touchable>
  )
}

export default React.memo(TaskBoardItem)
