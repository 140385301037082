import { Request } from '@/api/call'
import { PagingRequest } from '@/types/api'
import { SlackConnection } from '@/types/entities'

export interface SlackConnectionsResponse {
  slackConnections: SlackConnection[]
}

export const getSlackConnectionsList = (spaceId: number, paging: PagingRequest) =>
  new Request<SlackConnectionsResponse>({
    authentication: 'PRIVATE',
    method: 'GET',
    paging,
    url: `v1/spaces/${spaceId}/slack-connections`,
  })

export const deleteSlackConnection = (slackConnectionId: number) =>
  new Request<void>({
    authentication: 'PRIVATE',
    method: 'DELETE',
    url: `v1/slack-connections/${slackConnectionId}`,
  })
