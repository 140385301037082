import React from 'react'
import Background from '@/components/core/Background'
import IconButton from '@/components/core/IconButton'
import Box from '@/components/layouts/Box'
import { fnUtils } from '@/core/utils'
import { useMessageReactions } from '@/hooks/messages/reactions'
import { Message } from '@/types/entities'
import ReactionMenuItem from './ReactionMenuItem'

interface ReactionMenuProps {
  message: Message
  onAction?: () => void
  quickReactions?: string[]
}

const defaultReactions = ['👍', '👎', '😂', '❤️', '‼️']

function ReactionMenu({
  message,
  onAction = fnUtils.noOp,
  quickReactions = defaultReactions,
}: ReactionMenuProps) {
  const { setMessageReaction, pickMessageReaction } = useMessageReactions()

  const handleSetReaction = React.useCallback(
    (reaction: string) => {
      onAction()
      setMessageReaction(message, reaction)
    },
    [setMessageReaction, message, onAction],
  )

  const handleReactToMessage = React.useCallback(async () => {
    onAction()
    pickMessageReaction(message)
  }, [pickMessageReaction, message, onAction])

  return (
    <Background
      borderColor="heather"
      borderRadius={20}
      borderWidth={1}
      color="ghost"
      flexDirection="row"
    >
      <Box alignItems="center" direction="row" gapHorizontal="small">
        {quickReactions.map(reaction => (
          <ReactionMenuItem key={reaction} emojiSymbol={reaction} onReact={handleSetReaction} />
        ))}
        <IconButton
          kind="transparentDark"
          name="ellipsis-h"
          onPress={handleReactToMessage}
          size="h3"
        />
      </Box>
    </Background>
  )
}

export default React.memo(ReactionMenu)
