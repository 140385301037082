import { combineReducers } from 'redux'
import boardsReducer from './boards'
import channelStatsReducer from './channelStats'
import channelsReducer from './channels'
import channelsUsersReducer from './channelsUsers'
import messagesReducer from './messages'
import phoneNumbersReducer from './phoneNumbers'
import slackConnectionsReducer from './slackConnections'
import spacesReducer from './spaces'
import summariesReducer from './summaries'
import summaryItemsReducer from './summaryItems'
import tagsReducer from './tags'
import taskListItemsReducer from './taskListItems'
import taskListsReducer from './taskLists'
import tasksReducer from './tasks'
import threadsReducer from './threads'
import usersReducer from './users'

export default combineReducers({
  boards: boardsReducer,
  channelStats: channelStatsReducer,
  channels: channelsReducer,
  channelsUsers: channelsUsersReducer,
  messages: messagesReducer,
  phoneNumbers: phoneNumbersReducer,
  slackConnections: slackConnectionsReducer,
  spaces: spacesReducer,
  summaries: summariesReducer,
  summaryItems: summaryItemsReducer,
  tags: tagsReducer,
  taskListItems: taskListItemsReducer,
  taskLists: taskListsReducer,
  tasks: tasksReducer,
  threads: threadsReducer,
  users: usersReducer,
})
