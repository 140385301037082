import React from 'react'
import { StyleSheet } from 'react-native'
import Hoverable from '@/components/core/Hoverable'
import Touchable from '@/components/core/Touchable'
import { AttachmentsList } from '@/components/files/AttachmentsList'
import FilesList from '@/components/files/FilesList'
import Box from '@/components/layouts/Box'
import FloatContainer from '@/components/layouts/FloatContainer'
import { getMessageWidth } from '@/components/messages/MessagesList/constants'
import { useMessagesListContext } from '@/contexts/MessagesListContext'
import { forms } from '@/core/constants'
import { messageUtils, platformUtils } from '@/core/utils'
import { useAttachments } from '@/hooks/messages'
import { Measurement } from '@/types/components'
import { Message } from '@/types/entities'
import MessageReactionsList from '../MessageReactionsList'
import HoverMenu from './HoverMenu'

interface MessageFilesListProps {
  message: Message
  isFromMe: boolean
  canShowThread?: boolean
  onLongPress?: () => void
  disabled?: boolean
}

const { isWeb } = platformUtils
const formName = forms.CREATE_MESSAGE

function MessageFilesList({
  message,
  isFromMe,
  canShowThread,
  onLongPress,
  disabled,
}: MessageFilesListProps) {
  const { handleRemoveAttachment } = useAttachments(message.spaceId, formName, 'attachmentIds')
  const size = React.useMemo<Measurement>(() => {
    const width = getMessageWidth(isFromMe) - 58
    return { height: width, width }
  }, [isFromMe])
  const { spaceId, id: messageId } = message
  const { showThread } = useMessagesListContext()
  const handleShowThread = React.useCallback(
    () => showThread(spaceId, messageId),
    [showThread, spaceId, messageId],
  )
  const hasContent = !!message.content

  const renderContainer = React.useCallback(
    (content: React.ReactElement) => (
      <Hoverable style={isFromMe ? styles.hoverableFromMe : styles.hoverableFromThem}>
        {hovering => (
          <>
            {hovering && isFromMe && !disabled ? (
              <HoverMenu
                isFromMe={isFromMe}
                message={message}
                showConvertToTask={hasContent}
                showMoreActions={hasContent}
              />
            ) : null}
            <Touchable
              disabled={disabled}
              feedback="opacity"
              onLongPress={isWeb ? undefined : onLongPress}
              onPress={isWeb || !canShowThread ? undefined : handleShowThread}
            >
              <Box gapVertical="medium">
                <Box alignItems="center" justifyContent="center">
                  {content}
                </Box>
              </Box>
              {message.reactions && !hasContent ? (
                <FloatContainer left={-6} top={-2}>
                  <MessageReactionsList message={message} />
                </FloatContainer>
              ) : null}
            </Touchable>
            {hovering && !isFromMe && !disabled ? (
              <HoverMenu
                isFromMe={isFromMe}
                message={message}
                showConvertToTask={hasContent}
                showMoreActions={hasContent}
              />
            ) : null}
          </>
        )}
      </Hoverable>
    ),
    [canShowThread, disabled, handleShowThread, isFromMe, onLongPress, message, hasContent],
  )

  if (messageUtils.isUnsentMessage(message)) {
    return (
      <AttachmentsList
        attachmentIds={message.attachmentIds || []}
        onRemove={handleRemoveAttachment}
        size={size}
      />
    )
  }

  if (!messageUtils.messageHasFiles(message)) {
    return null
  }

  return (
    <FilesList
      files={message.files || []}
      genericFileMode="COMPACT"
      message={message}
      playVideoInline
      renderItemContainer={renderContainer}
      size={size}
    />
  )
}

const styles = StyleSheet.create({
  hoverableFromMe: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  hoverableFromThem: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})

export default React.memo(MessageFilesList)
