import { Task } from '@/types/entities'
import { TaskDetail } from '@/types/tasks'

export const isEditDetailActive = (
  taskDetail: TaskDetail,
  values: Record<string, any>,
): boolean => {
  switch (taskDetail) {
    case 'ATTACHMENTS':
      return (
        (values.attachmentIds && values.attachmentIds.length > 0) ||
        (values.files && values.files.length > 0)
      )
    case 'BOARD':
      return !!values.boardId
    case 'CHECKLIST_ITEMS':
      return values.checklistItems && values.checklistItems.length > 0
    case 'DUE_DATE':
      return !!values.dueDate || values.dueDateType !== 'NONE' || !!values.remindAt
    case 'HIGH_PRIORITY':
      return values.urgency === 'HIGH'
    case 'LOW_PRIORITY':
      return values.urgency === 'LOW'
    case 'NOTES':
      return !!values.notes
    case 'RECURRENCE':
      return !!values.recurrenceRule
    case 'REMINDER':
      return !!values.remindAt
    case 'TAGS':
      return values.tagIds && values.tagIds.length > 0
    default:
      throw new Error(`Unable to check active status for task detail: ${taskDetail}`)
  }
}

export const isDetailActive = (taskDetail: TaskDetail, task: Task): boolean => {
  switch (taskDetail) {
    case 'ATTACHMENTS':
      return !!task.files && task.files.length > 0
    case 'BOARD':
      return !!task.boardId
    case 'CHECKLIST_ITEMS':
      return !!task.checklistItems && task.checklistItems.length > 0
    case 'DUE_DATE':
      return !!task.dueDate || task.dueDateType !== 'NONE' || !!task.remindAt
    case 'HIGH_PRIORITY':
      return task.urgency === 'HIGH'
    case 'LOW_PRIORITY':
      return task.urgency === 'LOW'
    case 'NOTES':
      return !!task.notes
    case 'RECURRENCE':
      return !!task.recurrenceRule
    case 'REMINDER':
      return !!task.remindAt
    case 'TAGS':
      return !!task.tagIds && task.tagIds.length > 0
    default:
      throw new Error(`Unable to check active status for task detail: ${taskDetail}`)
  }
}
