import React from 'react'
import Text from '@/components/core/Text'
import { dateUtils as D } from '@/core/utils'

interface TaskDateProps {
  date: Date
}

function TaskDate({ date }: TaskDateProps) {
  const includeYear = D.now().getFullYear() !== date.getFullYear()
  return (
    <>
      <Text color="shadow" size="h6">
        {D.format(date, includeYear ? D.commonFormats.shortDate : D.commonFormats.shortDateNoYear)}
      </Text>
      <Text color="shadow" size="h6">
        {D.formatTime(date)}
      </Text>
    </>
  )
}

export default React.memo(TaskDate)
