import * as Haptics from 'expo-haptics'
import React from 'react'
import {
  GestureResponderEvent,
  Insets,
  Pressable,
  StyleProp,
  StyleSheet,
  TouchableHighlight,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'
import { platformUtils } from '@/core/utils'
import { mapHitSlop } from '@/core/utils/components'
import { HitSlop } from '@/types/components'
import Untouchable from './Untouchable'

type Feedback = 'opacity' | 'highlight' | 'none'

const giveHapticFeedback = !platformUtils.isWeb

export interface TouchableProps {
  children?: React.ReactNode
  disabled?: boolean
  feedback: Feedback
  hitSlop?: HitSlop
  onLongPress?: () => void
  onPress?: (e: GestureResponderEvent) => void
  onPressIn?: (e: GestureResponderEvent) => void
  onPressOut?: (e: GestureResponderEvent) => void
  /* For the circumstances where we want to give some kind of response when the user hits a
   * disabled button. For instance, to give feedback about why it is disabled
   */
  onPressDisabled?: () => void
  style?: StyleProp<ViewStyle>
  delayLongPress?: number
  testID?: string
}

function Touchable({
  children,
  disabled = false,
  feedback,
  hitSlop,
  onLongPress,
  onPress,
  onPressDisabled,
  onPressIn,
  onPressOut,
  delayLongPress,
  testID,
  style = styles.defaultStyle,
}: TouchableProps) {
  const slop = React.useMemo<Insets | undefined>(() => mapHitSlop(hitSlop), [hitSlop])
  let overrideFeedback = feedback

  const handlePress = disabled ? onPressDisabled : onPress
  const handleLongPressWithHaptic = React.useCallback(() => {
    if (onLongPress) {
      if (giveHapticFeedback) {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
      }
      onLongPress()
    }
  }, [onLongPress])
  const handleLongPress = onLongPress ? handleLongPressWithHaptic : undefined
  const hasAction = !!handlePress || !!onLongPress || !!onPressIn || !!onPressOut

  if (!hasAction) {
    return (
      <Untouchable style={style} testID={testID}>
        {children}
      </Untouchable>
    )
  }

  if (!handlePress) {
    overrideFeedback = 'none'
  }

  if (overrideFeedback === 'opacity') {
    return (
      <TouchableOpacity
        delayLongPress={delayLongPress}
        hitSlop={slop}
        onLongPress={handleLongPress}
        onPress={handlePress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        style={style}
        testID={testID}
      >
        {children}
      </TouchableOpacity>
    )
  }

  if (overrideFeedback === 'highlight') {
    return (
      <TouchableHighlight
        delayLongPress={delayLongPress}
        hitSlop={slop}
        onLongPress={handleLongPress}
        onPress={handlePress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        style={style}
        testID={testID}
        underlayColor="#eee"
      >
        {children}
      </TouchableHighlight>
    )
  }

  if (overrideFeedback === 'none') {
    return (
      <Pressable
        delayLongPress={delayLongPress}
        hitSlop={slop}
        onLongPress={handleLongPress}
        onPress={handlePress}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        style={style}
        testID={testID}
      >
        {children}
      </Pressable>
    )
  }

  throw new Error(`Invalid feedback prop: ${feedback}`)
}

const styles = StyleSheet.create({
  defaultStyle: {
    borderRadius: 4,
  },
})

export default React.memo(Touchable)
