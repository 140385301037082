import React from 'react'
import { formActions } from '@/store/actions'
import { formSelectors } from '@/store/selectors'
import { useAppDispatch, useAppSelector } from './redux'

export const useFormField = <T = string | null>(
  formName: string,
  fieldName: string,
): {
  changed: boolean
  value: T
  error: string | null
  setValue: (value: T) => void
} => {
  const dispatch = useAppDispatch()
  const { value, error, changed } = useAppSelector(formSelectors.field, formName, fieldName)
  const setValue = React.useCallback(
    newValue => dispatch(formActions.setValue({ fieldName, formName, value: newValue })),
    [dispatch, formName, fieldName],
  )

  return { changed, error, setValue, value }
}

export const useFormValue = (formName: string, fieldName: string) => {
  const { value } = useAppSelector(formSelectors.field, formName, fieldName)
  return value
}

export const useFormValues = (formName: string) => useAppSelector(formSelectors.values, formName)

export const useFormExists = (formName: string) => useAppSelector(formSelectors.exists, formName)
