import React from 'react'
import Box from '@/components/layouts/Box'
import ModalLayout from '@/components/layouts/ModalLayout'
import { useSpace } from '@/hooks/spaces'
import { PlanMode, SelectPlanProps } from '@/types/plans'
import MakePaymentBody from './MakePaymentBody'
import SelectPlanBody from './SelectPlanBody'
import UpgradeToPartnershipBody from './UpgradeToPartnershipBody'

type PlanModalProps = SelectPlanProps & {
  onCancel?: () => void
  onClose: () => void
  spaceId: number
}

const WIDTHS: Record<PlanMode, number> = {
  MAKE_PAYMENT: 600,
  SELECT_PLAN: 800,
  UPGRADE_TO_PARTNERSHIP: 500,
}

function PlanModal(props: PlanModalProps) {
  const { mode, onCancel, onClose, spaceId } = props
  const space = useSpace(spaceId)

  if (!space) {
    throw new Error('no space')
  }

  const handleCancel = React.useCallback(() => {
    onClose()
    onCancel?.()
  }, [onClose, onCancel])

  return (
    <ModalLayout
      leftAction={{ label: 'Cancel', onAction: handleCancel, type: 'linkPrimary' }}
      maxWidth={WIDTHS[mode]}
      title={(() => {
        if (mode === 'MAKE_PAYMENT') {
          return space.accountType === 'FREE_TRIAL'
            ? 'Ready to Upgrade?'
            : 'Add/Update Payment Information'
        }
        if (mode === 'UPGRADE_TO_PARTNERSHIP') {
          return null
        }
        return 'Emmre Plans'
      })()}
    >
      <Box flex={1} gapAll="large">
        {(() => {
          switch (mode) {
            case 'MAKE_PAYMENT': {
              const { onManageUsers } = props
              return (
                <MakePaymentBody onClose={onClose} onManageUsers={onManageUsers} space={space} />
              )
            }
            case 'UPGRADE_TO_PARTNERSHIP': {
              const { onUpgraded, upgradeAction } = props
              return (
                <UpgradeToPartnershipBody
                  onClose={onClose}
                  onUpgraded={onUpgraded}
                  space={space}
                  upgradeAction={upgradeAction}
                />
              )
            }
            case 'SELECT_PLAN': {
              const { onManageUsers } = props
              return (
                <SelectPlanBody onClose={onClose} onManageUsers={onManageUsers} space={space} />
              )
            }
            default:
              throw new Error(`plan type mode ${mode} not implemented`)
          }
        })()}
      </Box>
    </ModalLayout>
  )
}

export default React.memo(PlanModal)
