import React from 'react'
import { ActionableFlatList, RenderActionableItem } from '@/components/core/ActionableList'
import ActionsButton from '@/components/core/ActionsButton'
import Icon from '@/components/core/Icon'
import SelectListItem from '@/components/core/SelectListItem'
import Text from '@/components/core/Text'
import Box from '@/components/layouts/Box'
import { fnUtils } from '@/core/utils'
import { usePromptAsync } from '@/hooks/prompts'
import { Tag } from '@/types/entities'
import { CreateSegmentAction, CreateSegmentItem } from '../TaskSegmentModal'

interface TagsListProps {
  createTagAction: CreateSegmentAction
  onDelete: (tag: Tag) => void
  onEdit: (tag: Tag) => void
  onSelect: (tag: Tag, selected: boolean) => void
  selectedTagIds: number[]
  tags: Tag[]
}

const getKey = (tag: Tag) => tag.id.toString()
const actionArgs = { title: 'Tag Actions' }
const isSelected = (selectedIds: number[] | null, tag: Tag): boolean =>
  !!selectedIds && selectedIds.includes(tag.id)

function TagsList({
  createTagAction,
  onDelete,
  onEdit,
  onSelect,
  selectedTagIds,
  tags,
}: TagsListProps) {
  const showPrompt = usePromptAsync()
  const handleEdit = React.useCallback(
    async (editTag: Tag) => {
      const newName = await showPrompt({
        initialValue: editTag.name,
        submitText: 'Update',
        title: 'Enter New Tag Name',
      })
      if (newName) {
        await onEdit({ ...editTag, name: newName })
      }
    },
    [showPrompt, onEdit],
  )
  const handleSelect = React.useCallback(
    (tag: Tag) => onSelect(tag, !isSelected(selectedTagIds, tag)),
    [onSelect, selectedTagIds],
  )

  const renderItem = React.useCallback<RenderActionableItem<Tag>>(
    ({ item: tag, actions }) => (
      <SelectListItem isSelected={isSelected(selectedTagIds, tag)} swipeable>
        <Icon name="hashtag" size="h4" />
        <Box flex={1} gapLeft="medium">
          <Text color="shadow">{tag.name}</Text>
        </Box>
        <ActionsButton actionArgs={actionArgs} actions={actions} item={tag} webOnly />
      </SelectListItem>
    ),
    [selectedTagIds],
  )

  return (
    <ActionableFlatList
      ListFooterComponent={
        createTagAction.canCreate ? (
          <CreateSegmentItem createSegmentAction={createTagAction} label="New tag" />
        ) : null
      }
      actions={React.useMemo(
        () => [
          { handler: handleEdit, icon: 'pencil', label: 'Edit' },
          { handler: onDelete, icon: 'trash-alt', label: 'Delete', type: 'destructive' },
          { handler: fnUtils.noOp, icon: 'xmark', label: 'Cancel', type: 'cancel' },
        ],
        [onDelete, handleEdit],
      )}
      data={tags}
      extraData={renderItem}
      keyExtractor={getKey}
      keyboardShouldPersistTaps="handled"
      onItemPress={handleSelect}
      renderItem={renderItem}
    />
  )
}

export default React.memo(TagsList)
