import React from 'react'
import TextWithLinks from '@/components/core/TextWithLinks'
import Box from '@/components/layouts/Box'
import QuestionResponseForm from '@/components/questions/QuestionResponseForm'
import QuestionResponseContext, {
  useQuestionResponseForm,
} from '@/contexts/QuestionResponseContext'
import { QuestionTask } from '@/types/entities'

interface QuestionContentProps {
  task: QuestionTask
}

function QuestionContent({ task }: QuestionContentProps) {
  const questionResponseContextValue = useQuestionResponseForm(task)
  return (
    <Box gapAll="medium">
      <Box gapBottom="small">
        <TextWithLinks size="h5">{task.content}</TextWithLinks>
      </Box>
      <QuestionResponseContext.Provider value={questionResponseContextValue}>
        <QuestionResponseForm disabled="WHEN_ANSWERED" foregroundColor="obsidian" task={task} />
      </QuestionResponseContext.Provider>
    </Box>
  )
}

export default React.memo(QuestionContent)
